import { ModuleService } from 'src/app/services/account/privilege/module.service';
import { ModuleType } from 'src/app/types/general';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

import { GuestService } from 'src/app/services/guest/guest.service';
import { GuestListService } from 'src/app/services/guest/guest-list.service';
import { GuestManageService } from 'src/app/services/guest/guest-manage.service';
import { PopupService } from 'src/app/services/general/popup.service';
import { FunctionService } from 'src/app/services/general/function.service';

import { Guest } from 'src/app/interfaces/guest';
import { Group } from 'src/app/interfaces/group';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

/**
 * Apply changes to group member modal
 */
@Component({
  selector: 'app-group-change',
  templateUrl: './group-change.component.html',
  styleUrls: ['./group-change.component.scss'],
})
export class GroupChangeComponent implements OnInit, OnDestroy {

  @ViewChild(CdkVirtualScrollViewport) cdkVirtualScrollViewport: CdkVirtualScrollViewport;

  module: ModuleType;
  /**
   * New data to be applied
   */
  newData: any;
  /**
   * Guest count
   */
  count: number;
  /**
   * Select all flag
   */
  selectAll: boolean;
  /**
   * Guest list
   */
  guestList: Guest[];
  /**
   * Guest group list
   */
  groupList: Group[];
  /**
   * Guest individual list
   */
  guestIndividualList: Guest[];
  /**
   * Selected guest list
   */
  selectedGuestList: string[];
  /**
   * Selected group list
   */
  selectedGroupList: string[];

  /**
   * Constructor
   * @param modalController modal controller
   * @param translate translate service
   * @param guestService guest service
   * @param guestListService guest list service
   * @param guestManageService guest manage service
   * @param groupListService group list service
   * @param popupService popup service
   * @param functionService function service
   */
  constructor(
    private modalController: ModalController,
    private translate: TranslateService,
    private moduleService: ModuleService,
    private guestService: GuestService,
    private guestListService: GuestListService,
    private guestManageService: GuestManageService,
    private popupService: PopupService,
    private functionService: FunctionService,
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  /**
   * Genreate individual guest list and select all guest.
   */
  ionViewWillEnter() {
    if (!this.module) {
      this.module = this.moduleService.currentModule;
    }
    this.selectAll = false;
    this.setupGuestList();
  }

  async setupGuestList() {
    const guestIdList = this.selectedGuestList;
    this.groupList?.forEach((group: Group) => {
      if (group?.memberList?.length) {
        group.memberList?.forEach((guestId: string) => {
          if (guestIdList?.indexOf(guestId) === -1) {
            guestIdList.push(guestId);
          }
        });
      }
    });
    if (guestIdList?.length) {
      const guestList = this.guestService.getGuestListById(guestIdList);
      this.guestList = this.guestListService.generateGroupList(guestList);
      this.setupViewport();
      this.count = guestList.length;
    }
  }

  setupViewport() {
    if (this.cdkVirtualScrollViewport) {
      setTimeout(() => {
        this.cdkVirtualScrollViewport?.checkViewportSize();
      }, 200);
    } else {
      setTimeout(() => {
        this.setupViewport();
      }, 200);
    }
  }

  /**
   * Dismiss modal
   * @param dismiss Dismiss previous modal flag
   */
  async dismissModal(dismiss?: boolean) {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { await this.modalController.dismiss({ dismiss }); }
    }
  }

  /**
   * Toggle select all
   */
  toggleSelectAll() {
    this.selectAll = !this.selectAll;

    this.selectedGuestList = [];
    this.selectedGroupList = [];

    if (this.selectAll) {
      this.guestList?.forEach((guest: Guest) => {
        if (guest?.group?.groupId) {
          this.setSelectedGroup(guest.group.groupId, true);
        } else if (guest?.guestId) {
          this.selectedGuestList.push(guest.guestId);
        }
      });
    }
  }

  /**
   * Check select all
   */
  checkSelectAll() {
    if (this.count && this.selectedGuestList?.length === this.count) {
      this.selectAll = true;
    } else {
      this.selectAll = false;
    }
  }

  /**
   * Set selected group
   * @param groupId group id
   * @param selected selected flag
   * @param skipCheck skip check
   */
  setSelectedGroup(groupId: string, selected?: boolean, skipCheck?: boolean) {
    const index = this.selectedGroupList?.indexOf(groupId);
    if (index === -1) {
      if (this.functionService.isUndefined(selected) || selected === true) {
        this.selectedGroupList.push(groupId);
        if (!skipCheck) {
          this.checkSelectedGroup(groupId, true, this.functionService.isUndefined(selected) ? false : true);
        }
      }
    } else {
      if (this.functionService.isUndefined(selected) || selected === false) {
        if (this.selectedGroupList?.length) {
          this.selectedGroupList?.splice(index, 1);
        }
        if (!skipCheck) {
          this.checkSelectedGroup(groupId, false, this.functionService.isUndefined(selected) ? false : true);
        }
      }
    }
  }

  /**
   * Check selected group
   * @param groupId group id
   * @param selected selected flag
   * @param skipCheck skip check flag
   */
  checkSelectedGroup(groupId: string, selected?: boolean, skipCheck?: boolean) {
    const index = this.guestList?.findIndex((x) => x?.group?.groupId === groupId);
    let memberList: string[] = [];
    if (index !== -1 && this.guestList?.[index]?.group?.memberList?.length) {
      memberList = this.guestList[index].group.memberList;
    }

    if (memberList?.length) {
      let flag = true;
      memberList?.forEach((guestId: string) => {
        const selectedIndex = this.selectedGuestList?.indexOf(guestId);
        if (selectedIndex === -1) {
          flag = false;
          if (!this.functionService.isUndefined(selected) && selected === true) {
            this.selectedGuestList.push(guestId);
          }
        } else {
          if (!this.functionService.isUndefined(selected) && selected === false) {
            this.selectedGuestList?.splice(selectedIndex, 1);
          }
        }
      });
      if (this.functionService.isUndefined(selected)) {
        this.setSelectedGroup(groupId, flag, true);
      }
    }

    if (!skipCheck) {
      this.checkSelectAll();
    }
  }

  /**
   * Set selected guest
   * @param guestId Guest id
   */
  setSelectedGuest(guestId: string) {
    const index = this.selectedGuestList?.indexOf(guestId);
    if (index === -1) {
      this.selectedGuestList.push(guestId);
    } else {
      this.selectedGuestList?.splice(index, 1);
    }
  }

  /**
   * Next step
   */
  next() {
    if (this.selectedGuestList?.length) {
      this.apply();
    } else {
      this.popupService.presentToast(this.translate.instant('VALIDATION.select_field',
        { field: this.translate.instant('GUEST.lbl.guest') }));
    }
  }

  /**
   * Apply changes
   */
  async apply() {
    await this.popupService.presentLoading();
    if (this.selectedGuestList?.length && this.newData) {
      await this.guestManageService.saveGuest(this.newData, this.selectedGuestList);
    }
    await this.popupService.dismissLoading();
    await this.dismissModal(true);
  }

  estimateItemSize(index: number, guest: Guest): number {
    return this.itemHeightFn(guest) + 10;
  }

  /**
   * Calculate item height for virtual scroll
   * @param item Item
   */
  itemHeightFn(item: any) {
    const margin = 10;
    const guestItem = 100;
    const groupItem = 80;

    if (item?.group?.memberList?.length) {
      return (item.group.memberList.length * guestItem) + groupItem + margin;
    }
    return guestItem + margin;
  }

  /**
   * Track item by guest id / group id or index for virtual scroll
   * @param index Index
   * @param item item
   */
  trackByFn(index: number, item: Guest) {
    if (item?.guestId) {
      return item.guestId;
    } else if (item?.group?.groupId) {
      return item.group.groupId;
    }
    return index;
  }

}
