<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="dismissModal()">
        <ion-icon color="dark" class="back-icon" src="./assets/wedding/icon/general/chevron-back.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <app-offline-indicator></app-offline-indicator>
      {{ 'LOCATION.lbl.location' | translate }}
    </ion-title>
  </ion-toolbar>
  <ion-toolbar>
    <ion-list class="ion-padding-horizontal ion-padding-bottom background-transparent" lines="none">
      <ng-container *ngIf="!editMode">
        <!-- <ng-container *ngIf="disableCountry">
          <ion-label position="stacked">{{ 'LOCATION.lbl.wedding_country' | translate }}</ion-label>
          <ion-item class="prompt-input-item ion-item-no-padding" lines="none" (click)="dismissLocationModal()">
            <ion-label *ngIf="country?.name">{{ country.name }}</ion-label>
            <ion-button class="ion-no-margin" slot="end" fill="clear">{{ 'CRUD.edit' | translate }}</ion-button>
          </ion-item>
          <div class="error-div"></div>
        </ng-container> -->
        <ng-container *ngIf="!disableCountry">
          <ion-item class="ion-item-no-padding background-transparent" lines="none">
            <ion-label position="stacked">
              {{ countryLbl }}
            </ion-label>
            <ion-item class="prompt-input-item full-width" lines="none" (click)="openCountryModal()" button detail>
              <ion-text class="font-14">{{ country?.name ? country.name : '-' }}</ion-text>
            </ion-item>
            <div class="error-div">
              <div class="error-message" *ngIf="!country">
                <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> 
                {{ getRequiredField('country') }}
              </div>
            </div>    
          </ion-item>
          <ng-container *ngIf="!disableTimezone">
            <ion-item class="ion-item-no-padding background-transparent" lines="none" (click)="openTimezone()" >
              <ion-label position="stacked">
                <ng-container *ngIf="locationType === 'business'">
                  {{ 'BUSINESS.lbl.timezone' | translate }}
                </ng-container>
                <ng-container *ngIf="locationType === 'venue'">
                  {{ replaceEventType('DATE.lbl.wedding_timezone' | translate) }}
                </ng-container>
              </ion-label>
              <ion-item class="prompt-input-item" lines="none">
                <ion-label class="font-14" *ngIf="timezone?.label">{{ timezone.label }}</ion-label>
                <ion-note slot="end"><ion-icon src="./assets/wedding/icon/general/chevron-down.svg" class="font-18"></ion-icon></ion-note>
              </ion-item>
            </ion-item>
            <div class="error-div">
              <div class="error-message" *ngIf="!timezone">
                <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> 
                {{ getRequiredField('timezone') }}
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
      
      <ion-item class="ion-item-no-padding background-transparent" lines="none" *ngIf="country?.code">
        <ion-label position="stacked">
          <ng-container *ngIf="locationType === 'business'">
            {{ 'BUSINESS.lbl.location' | translate }}
          </ng-container>
          <ng-container *ngIf="locationType === 'venue'">
            {{ replaceEventType('LOCATION.lbl.wedding_venue' | translate) }}
          </ng-container>
        </ion-label>
        <!-- <ion-item class="prompt-input ion-item-no-padding" lines="none" *ngIf="!editMode || !online" (click)="toggleEditMode(true)">
          <ion-label *ngIf="location?.name ">{{ location.name }}</ion-label>
        </ion-item> -->
        <ion-item class="ion-item-no-padding prompt-input-item">
          <form class="full-width" (ngSubmit)="searchPlace()">
            <ion-searchbar name="search" class="searchbar font-14" [(ngModel)]="searchTerm" (ionInput)="searchPlace()" (keypress)="closeKeyboard($event)" (ionClear)="clearPlace()" (ionFocus)="toggleEditMode(true)" (ionBlur)="toggleEditMode(false)" debounce="800" enterkeyhint="search" inputmode="search" type="text" autocomplete="on" searchIcon="./assets/wedding/icon/general/search.svg" #searchbar></ion-searchbar>
          </form>
        </ion-item>
        <div class="error-div">
          <div class="error-message" *ngIf="!online">
            <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ 'ONLINE.msg.internet_required' | translate }}
          </div>
          <ng-container *ngIf="online">
            <div class="error-message" *ngIf="places?.length && !notFound">
              <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ 'LOCATION.validation.venue_select' | translate: { location: getLocationName() } }}
            </div>
            <div class="error-message" *ngIf="notFound">
              <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ 'LOCATION.validation.venue_not_found' | translate: { location: getLocationName() } }}
            </div>
          </ng-container>
        </div>
      </ion-item>
    </ion-list>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ng-container *ngIf="country && searchTerm && online">
    <ng-container *ngIf="showLoading">
      <div class="ion-margin ion-padding">
        <ion-spinner class="center"></ion-spinner>
      </div>
    </ng-container>
    <ng-container *ngIf="!notFound && places?.length">
      <ion-list>
        <ion-item class="ion-item-no-padding" *ngFor="let place of places" (click)="selectPlace(place)" lines="full">
            <ion-grid>
                <ion-row *ngIf="place.structured_formatting.main_text">
                    <ion-col class="ion-align-self-center ion-text-center" size="auto">
                        <b><ion-icon class="vertical-middle" color="dark" src="./assets/wedding/icon/general/pin.svg"></ion-icon></b>
                    </ion-col>
                    <ion-col >
                      <div *ngIf="place.structured_formatting.main_text">
                        <ion-text class="font-14 line-130" color="dark"><b>{{place.structured_formatting.main_text}}</b></ion-text>
                      </div>
                      <div *ngIf="place.structured_formatting.secondary_text">
                        <ion-text class="font-10 line-100" color="medium">{{place.structured_formatting.secondary_text}}</ion-text>
                      </div>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </ion-item>
      </ion-list>
    </ng-container>
    <ng-container *ngIf="notFound">
      <ion-item class="full-height vertical-center background-transparent ion-item-no-padding ion-text-center ion-padding" lines="none">
        <ion-label class="ion-no-margin" color="medium">
          <ion-icon class="center icon-cactus" src="./assets/wedding/icon/general/no-record.svg"></ion-icon>
          <div class="ion-margin-top font-14">{{ 'LBL.no_found' | translate }}</div>
        </ion-label>
      </ion-item>
    </ng-container>
  </ng-container>
  <div #map id="map" [class]="online && location?.name && !notFound && !showLoading ? 'full-screen-width' : 'hide-map'">
    <div>
      <ion-spinner class="center"></ion-spinner>
    </div>
  </div>
</ion-content>
<ion-footer *ngIf="!editMode">
  <ion-toolbar>
    <ion-button class="ion-margin" expand="block" (click)="select()" [disabled]="showLoading || places?.length || notFound">
      {{ 'BTN.select' | translate }}
    </ion-button>
  </ion-toolbar>
</ion-footer>