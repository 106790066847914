import { WebsiteThemeColor } from "../interfaces/website";

export const WebsiteThemeColorList: { [ colorId: string ]: WebsiteThemeColor } = {
    '1': {
        colorId: '1',
        background: '#F2F3F0',
        backgroundLine: '#4D4B48',
        font: '#4D4B48',
        button: {
            fill: 'outline',
            color: '#4D4B48',
            font: '#4D4B48',
        },
        countDown: {
            background: '#FFFFFF',
            opacity: 50,
        },
        contentOpacityColor: '#000000',
    },
    '2': {
        colorId: '2',
        background: '#C4A29B',
        backgroundLine: '#FFFFFF',
        font: '#FFFFFF',
        button: {
            fill: 'solid',
            color: '#FFFFFF',
            font: '#C4A29B',
        },
        countDown: {
            background: '#FFFFFF',
            opacity: 15,
        },
        contentOpacityColor: '#FFFFFF',
    },
    '3': {
        colorId: '3',
        background: '#E5E1DD',
        backgroundLine: '#5B595C',
        font: '#5B595C',
        button: {
            fill: 'solid',
            color: '#5B595C',
            font: '#E5E1DD',
        },
        countDown: {
            background: '#FFFFFF',
           opacity: 30,
        },
        contentOpacityColor: '#FFFFFF',
    },
    '4': {
        colorId: '4',
        background: '#D8D9C7',
        backgroundLine: '#53613F',
        font: '#53613F',
        button: {
            fill: 'solid',
            color: '#53613F',
            font: '#D8D9C7',
        },
        countDown: {
            background: '#FFFFFF',
            opacity: 20,
        },
        contentOpacityColor: '#FFFFFF',
    },

    '5': {
        colorId: '5',
        background: '#343F3E',
        backgroundLine: '#E0EDE4',
        font: '#E0EDE4',
        button: {
            fill: 'solid',
            color: '#E0EDE4',
            font: '#343F3E',
        },
        countDown: {
            background: '',
            opacity: 0,
        },
        contentOpacityColor: '#FFFFFF',
    },
    '6': {
        colorId: '6',
        background: '#E0DDD5',
        backgroundLine: '#746356',
        font: '#746356',
        button: {
            fill: 'solid',
            color: '#746356',
            font: '#E0DDD5',
        },
        countDown: {
            background: '#FFFFFF',
            opacity: 30,
        },
        contentOpacityColor: '#FFFFFF',
    },
    '7': {
        colorId: '7',
        background: '#DEE2DC',
        backgroundLine: '#465650',
        font: '#465650',
        button: {
            fill: 'solid',
            color: '#465650',
            font: '#DEE2DC',
        },
        countDown: {
            background: '#FFFFFF',
            opacity: 30,
        },
        contentOpacityColor: '#FFFFFF',
    },
    '8': {
        colorId: '8',
        background: '#E5E4DF',
        backgroundLine: '#6A6B87',
        font: '#6A6B87',
        button: {
            fill: 'solid',
            color: '#6A6B87',
            font: '#E5E4DF',
        },
        countDown: {
            background: '#8F90B0',
            opacity: 15,
        },
        contentOpacityColor: '#FFFFFF',
    },

    '9': {
        colorId: '9',
        background: '#2F435B',
        backgroundLine: '#FFFFFF',
        font: '#FFFFFF',
        button: {
            fill: 'solid',
            color: '#FFFFFF',
            font: '#2F435B',
        },
        countDown: {
            background: '#FFFFFF',
            opacity: 20,
        },
        contentOpacityColor: '#FFFFFF',
    },
    '10': {
        colorId: '10',
        background: '#536F43',
        backgroundLine: '#FFFFFF',
        font: '#FFFFFF',
        button: {
            fill: 'solid',
            color: '#FFFFFF',
            font: '#536F43',
        },
        countDown: {
            background: '#FFFFFF',
            opacity: 20,
        },
        contentOpacityColor: '#000000',
    },
    '11': {
        colorId: '11',
        background: '#C8CFD7',
        backgroundLine: '#4B3E43',
        font: '#4B3E43',
        button: {
            fill: 'solid',
            color: '#4B3E43',
            font: '#C8CFD7',
        },
        countDown: {
            background: '#FFFFFF',
            opacity: 20,
        },
        contentOpacityColor: '#FFFFFF',
    },
    '12': {
        colorId: '12',
        background: '#EDECEA',
        backgroundLine: '#9F5E38',
        font: '#9F5E38',
        button: {
            fill: 'solid',
            color: '#9F5E38',
            font: '#EDECEA',
        },
        countDown: {
            background: '#ECC7BD',
            opacity: 20,
        },
        contentOpacityColor: '#FFFFFF',
    },

    '13': {
        colorId: '13',
        background: '#FFFFFF',
        backgroundLine: '#7A6F52',
        font: '#7A6F52',
        button: {
            fill: 'solid',
            color: '#7A6F52',
            font: '#FFFFFF',
        },
        countDown: {
            background: '#C4CC7A',
            opacity: 20,
        },
        contentOpacityColor: '#FFFFFF',
    },
    '14': {
        colorId: '14',
        background: '#FFFFFF',
        backgroundLine: '#4A5725',
        font: '#4A5725',
        button: {
            fill: 'solid',
            color: '#4A5725',
            font: '#FFFFFF',
        },
        countDown: {
            background: '#C4CC7A',
            opacity: 20,
        },
        contentOpacityColor: '#FFFFFF',
    },
    '15': {
        colorId: '15',
        background: '#FFFFFF',
        backgroundLine: '#E39839',
        font: '#E39839',
        button: {
            fill: 'solid',
            color: '#E39839',
            font: '#FFFFFF',
        },
        countDown: {
            background: '#ECC65C',
            opacity: 20,
        },
        contentOpacityColor: '#FFFFFF',
    },
    '16': {
        colorId: '16',
        background: '#FFFFFF',
        backgroundLine: '#C34A4B',
        font: '#C34A4B',
        button: {
            fill: 'solid',
            color: '#C34A4B',
            font: '#FFFFFF',
        },
        countDown: {
            background: '#BB4142',
            opacity: 20,
        },
        contentOpacityColor: '#FFFFFF',
    },

    '17': {
        colorId: '17',
        background: '#FFFFFF',
        backgroundLine: '#38574C',
        font: '#38574C',
        button: {
            fill: 'solid',
            color: '#38574C',
            font: '#FFFFFF',
        },
        countDown: {
            background: '#999F65',
            opacity: 20,
        },
        contentOpacityColor: '#FFFFFF',
    },
    '18': {
        colorId: '18',
        background: '#FFFFFF',
        backgroundLine: '#CA9848',
        font: '#CA9848',
        button: {
            fill: 'solid',
            color: '#CA9848',
            font: '#FFFFFF',
        },
        countDown: {
            background: '#E2C375',
            opacity: 20,
        },
        contentOpacityColor: '#FFFFFF',
    },
    '19': {
        colorId: '15',
        background: '#FFFFFF',
        backgroundLine: '#9D2C12',
        font: '#9D2C12',
        button: {
            fill: 'solid',
            color: '#9D2C12',
            font: '#FFFFFF',
        },
        countDown: {
            background: '#965A35',
            opacity: 20,
        },
        contentOpacityColor: '#FFFFFF',
    },
    '20': {
        colorId: '20',
        background: '#FFFFFF',
        backgroundLine: '#739683',
        font: '#739683',
        button: {
            fill: 'solid',
            color: '#739683',
            font: '#FFFFFF',
        },
        countDown: {
            background: '#B4CDC9',
            opacity: 20,
        },
        contentOpacityColor: '#FFFFFF',
    },

    '21': {
        colorId: '21',
        background: '#FFFFFF',
        backgroundLine: '#31624D',
        font: '#31624D',
        button: {
            fill: 'solid',
            color: '#31624D',
            font: '#FFFFFF',
        },
        countDown: {
            background: '#B4CDC9',
            opacity: 20,
        },
        contentOpacityColor: '#FFFFFF',
    },
    '22': {
        colorId: '22',
        background: '#FFFFFF',
        backgroundLine: '#C21D10',
        font: '#C21D10',
        button: {
            fill: 'solid',
            color: '#C21D10',
            font: '#FFFFFF',
        },
        countDown: {
            background: '#B4CDC9',
            opacity: 20,
        },
        contentOpacityColor: '#FFFFFF',
    },
    '23': {
        colorId: '23',
        background: '#FFFFFF',
        backgroundLine: '#365561',
        font: '#365561',
        button: {
            fill: 'solid',
            color: '#365561',
            font: '#FFFFFF',
        },
        countDown: {
            background: '#7AA5BF',
            opacity: 20,
        },
        contentOpacityColor: '#FFFFFF',
    },
    '24': {
        colorId: '24',
        background: '#FFFFFF',
        backgroundLine: '#967B7E',
        font: '#967B7E',
        button: {
            fill: 'solid',
            color: '#967B7E',
            font: '#FFFFFF',
        },
        countDown: {
            background: '#B7A5A4',
            opacity: 20,
        },
        contentOpacityColor: '#FFFFFF',
    },
    '25': {
        colorId: '25',
        background: '#FFFFFF',
        backgroundLine: '#D17F5F',
        font: '#D17F5F',
        button: {
            fill: 'solid',
            color: '#D17F5F',
            font: '#FFFFFF',
        },
        countDown: {
            background: '#E2A982',
            opacity: 20,
        },
        contentOpacityColor: '#FFFFFF',
    },

    '26': {
        colorId: '26',
        background: '#FFFFFF',
        backgroundLine: '#667775',
        font: '#667775',
        button: {
            fill: 'solid',
            color: '#667775',
            font: '#FFFFFF',
        },
        countDown: {
            background: '#AFBEC7',
            opacity: 20,
        },
        contentOpacityColor: '#FFFFFF',
    },
    '27': {
        colorId: '27',
        background: '#FFFFFF',
        backgroundLine: '#5C4E67',
        font: '#5C4E67',
        button: {
            fill: 'solid',
            color: '#5C4E67',
            font: '#FFFFFF',
        },
        countDown: {
            background: '#917A7C',
            opacity: 20,
        },
        contentOpacityColor: '#FFFFFF',
    },
    '28': {
        colorId: '28',
        background: '#FFFFFF',
        backgroundLine: '#C89384',
        font: '#C89384',
        button: {
            fill: 'solid',
            color: '#C89384',
            font: '#FFFFFF',
        },
        countDown: {
            background: '#E5D3CE',
            opacity: 20,
        },
        contentOpacityColor: '#FFFFFF',
    },
    '29': {
        colorId: '29',
        background: '#FFFFFF',
        backgroundLine: '#71A70C',
        font: '#71A70C',
        button: {
            fill: 'solid',
            color: '#71A70C',
            font: '#FFFFFF',
        },
        countDown: {
            background: '#CCD37E',
            opacity: 20,
        },
        contentOpacityColor: '#FFFFFF',
    },
};