import { LinkService } from 'src/app/services/general/link.service';
import { Browser } from '@capacitor/browser';
import { TranslateService } from '@ngx-translate/core';
import { PopupService } from 'src/app/services/general/popup.service';
import { NavigationExtras, Router } from '@angular/router';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { BehaviorSubject, Subscription } from 'rxjs';

import { StdAccountTaskList } from 'src/app/commons/task';
import { GiftService } from 'src/app/services/gift/gift.service';
import { GuestSummaryService } from 'src/app/services/guest/guest-summary.service';
import { AccountUserService } from 'src/app/services/account/account-user.service';
import { GuestService } from 'src/app/services/guest/guest.service';
import { VisitorService } from 'src/app/services/visitor/visitor.service';
import { CheckinSettingService } from 'src/app/services/checkin/checkin-setting.service';
import { PrivilegeService } from 'src/app/services/account/privilege/privilege.service';

import { Privilege } from 'src/app/interfaces/privilege';
import { AccountTask } from 'src/app/interfaces/task';
import { AccountTaskType } from 'src/app/types/task';

import { AccountDetailType } from 'src/app/types/account';
import { VisitorSettingType } from 'src/app/types/visitor';

import { GuestListComponent } from 'src/app/components/guest/guest-list/guest-list.component';
import { SettingCheckinComponent } from 'src/app/components/setting/setting-checkin/setting-checkin.component';
import { SettingVisitorComponent } from 'src/app/components/setting/setting-visitor/setting-visitor.component';
import { ModuleType } from 'src/app/types/general';
import { GuestFilterType, QrcodeType } from 'src/app/types/guest';
import { SettingVisitorWizardComponent } from 'src/app/components/setting/setting-visitor-wizard/setting-visitor-wizard.component';

@Injectable({
  providedIn: 'root'
})
export class AccountTaskService implements OnInit, OnDestroy {

  accountTaskList: AccountTask[];
  observableAccountTask: any;
  observablePending: any;
  observableCompleted: any;

  pending: number;
  completed: number;

  
  /**
   * Prompt flag
   */
  private prompt: boolean;
  /**
   * Showing flag
   */
  private showing: boolean;
  /**
   * Account accountTask flag
   */
  // private accountTask: AccountTask;
  /**
   * Account ID
   */
  private accountId: string;
  /**
   * Current user privilege subscription
   */
  private currentUserPrivilegeSubscription: Subscription;

  private accountUserSubscription: Subscription;
  private visitorSettingSubscription: Subscription;
  private guestSubscription: Subscription;
  private giftSubscription: Subscription;
  private checkinSettingSubscription: Subscription;


  /**
   * Constructor
   * @param modalController modal controller
   * @param afs angular firestore
   */
  constructor(
    private router: Router,
    private modalController: ModalController,
    private privilegeService: PrivilegeService,
    private checkinSettingSerivce: CheckinSettingService,
    private visitorService: VisitorService,
    private guestService: GuestService,
    private guestSummaryService: GuestSummaryService,
    private accountUserService: AccountUserService,
    private giftService: GiftService,
    private linkService: LinkService,
  ) {
    this.accountTaskList = [];
    this.observableAccountTask = new BehaviorSubject<AccountTask[]>(this.accountTaskList);
    this.observableCompleted = new BehaviorSubject<Number>(this.completed);
    this.observablePending = new BehaviorSubject<Number>(this.pending);
  }
  
  ngOnInit(): void {
      
  }

  ngOnDestroy() {
    this.unwatch();
  }

  /**
   * Setup account login
   * @param accountId Account ID
   * @param weddingId Wedding ID
   */
  async setupAccountId(accountId: string) {
    this.accountId = accountId;
    if (this.accountId) {
      this.watch();
    } else {
      this.unwatch();
    }
  }

  watch() {
    this.watchCurrentUserPrivilege();
    this.watchAccountUser();
    this.watchCheckinSetting();
    this.watchGuest();
    this.watchGift();
    this.watchVisitorSetting();
  }

  /**
   * Watch current user privilege
   */
  async watchCurrentUserPrivilege() {
    if (!this.currentUserPrivilegeSubscription) {
      this.currentUserPrivilegeSubscription = this.privilegeService.observableCurrentPrivilege.subscribe((privilege: Privilege) => {
        if (privilege) {
          this.setupChecklistList();
        }
      });
    }
  }

  async watchAccountUser() {
    if (!this.accountUserSubscription) {
      this.accountUserSubscription = this.accountUserService.observableAccountUser.subscribe(() => {
        this.getPendingChecklistCount();
      })
    }
  }

  async watchVisitorSetting() {
    if (!this.visitorSettingSubscription) {
      this.visitorSettingSubscription = this.visitorService.observablePageData.subscribe(() => {
        this.getPendingChecklistCount();
      })
    }
  }

  async watchGuest() {
    if (!this.guestSubscription) {
      this.guestSubscription = this.guestService.observableGuestList.subscribe(() => {
        this.getPendingChecklistCount();
      })
    }
  }

  async watchGift() {
    if (!this.giftSubscription) {
      this.giftSubscription = this.giftService.observableGiftList.subscribe(() => {
        this.getPendingChecklistCount();
      })
    }
  }

  async watchCheckinSetting() {
    if (!this.checkinSettingSubscription) {
      this.checkinSettingSubscription = this.checkinSettingSerivce.observableCheckinSetting.subscribe(() => {
        this.getPendingChecklistCount();
      })
    }
  }

  /**
   * Unwatch current user privilege
   */
  async unwatchCurrentUserPrivilege() {
    if (this.currentUserPrivilegeSubscription) {
      this.currentUserPrivilegeSubscription.unsubscribe();
      this.currentUserPrivilegeSubscription = null;
    }
  }

  async unwatchAccountUser() {
    if (this.accountUserSubscription) {
      this.accountUserSubscription.unsubscribe();
      this.accountUserSubscription = null;
    }
  }

  async unwatchVisitorSetting() {
    if (this.visitorSettingSubscription) {
      this.visitorSettingSubscription.unsubscribe();
      this.visitorSettingSubscription = null;
    }
  }

  async unwatchGuest() {
    if (this.guestSubscription) {
      this.guestSubscription.unsubscribe();
      this.guestSubscription = null;
    }
  }

  async unwatchGift() {
    if (this.giftSubscription) {
      this.giftSubscription.unsubscribe();
      this.giftSubscription = null;
    }
  }

  async unwatchCheckinSetting() {
    if (this.checkinSettingSubscription) {
      this.checkinSettingSubscription.unsubscribe();
      this.checkinSettingSubscription = null;
    }
  }

  async unwatch() {
    this.unwatchCurrentUserPrivilege();
    this.unwatchAccountUser();
    this.unwatchVisitorSetting();
    this.unwatchGuest();
    this.unwatchGift();
    this.unwatchCheckinSetting();
  }

  /**
   * Check user privilege
   * @param action Action
   */
  checkPrivilege(module: ModuleType, action: string): boolean {
    return this.privilegeService.checkCurrentUserPrivilege(module, action);
  }

  setupChecklistList() {
    this.accountTaskList = StdAccountTaskList.filter((accountTask: AccountTask) => {
      if (accountTask?.name && this.checkChecklistPrivilege(accountTask.name)) {
        return true;
      }
      return false;
    });
    this.observableAccountTask.next(this.accountTaskList);
    this.getPendingChecklistCount();
  }

  checkChecklistStatus(accountTaskType: AccountTaskType): boolean {
    if (accountTaskType === 'setup_visitor_page') {
      if (this.visitorService?.pageData?.info && this.visitorService?.pageData?.updateBy) {
        return true;
      }
    } else if (accountTaskType === 'add_guest') {
      if (this.guestService.getGuestList('guest')?.length) {
        return true;
      }
    } else if (accountTaskType === 'send_qr') {
      if (this.guestSummaryService.getGuestListCount(null, null, null, null, null, null, null, { qrcode: true }, 'guest')) {
        return true;
      }
    } else if (accountTaskType === 'invite_member') {
      if (this.accountUserService?.accountUserList?.length > 1) {
        return true;
      }
    } else if (accountTaskType === 'assign_seating') {
      if (this.guestSummaryService.getGuestListCount(null, null, null, null, null, 'assigned', '', null, 'guest')) {
        return true;
      }
    } else if (accountTaskType === 'review_setting') {
      if (this.checkinSettingSerivce.checkinSettingFlag) {
        return true;
      }
    } else if (accountTaskType === 'checkin_guest') {
      if (this.guestSummaryService.getGuestListCount(null, null, null, null, null, null, null, { checkin: true }, 'guest')) {
        return true;
      }
    } else if (accountTaskType === 'record_gift') {
      if (this.giftService?.getGiftList('account')?.length) {
        return true;
      }
    }
    return false;
  }

  checkChecklistPrivilege(accountTaskType: AccountTaskType): boolean {
    if (accountTaskType === 'setup_visitor_page') {
      if (this.checkPrivilege('guest', 'qrcode')) {
        return true;
      }
    } else if (accountTaskType === 'add_guest') {
      if (this.checkPrivilege('guest', 'new')) {
        return true;
      }
    } else if (accountTaskType === 'send_qr') {
      if (this.checkPrivilege('guest', 'qrcode')) {
        return true;
      }
    } else if (accountTaskType === 'invite_member') {
      if (this.checkPrivilege('account', 'member')) {
        return true;
      }
    } else if (accountTaskType === 'assign_seating') {
      if (this.checkPrivilege('guest', 'seating')) {
        return true;
      }
    } else if (accountTaskType === 'review_setting') {
      if (this.checkPrivilege('account', 'setting')) {
        return true;
      }
    } else if (accountTaskType === 'checkin_guest') {
      if (this.checkPrivilege('checkin', 'checkin')) {
        return true;
      }
    } else if (accountTaskType === 'record_gift') {
      if (this.checkPrivilege('gift', 'new')) {
        return true;
      }
    }
    return false;
  }

  getCompletedChecklistCount(): number {
    let count = 0;
    this.accountTaskList?.forEach((accountTask: AccountTask) => {
      if (accountTask?.name && this.checkChecklistStatus(accountTask.name)) {
        count++;
      }
    });
    this.completed = count;
    this.observableCompleted.next(this.completed);
    return count;
  }

  getPendingChecklistCount(): number {
    let pending = 0;
    const total = this.accountTaskList?.length;
    const completed = this.getCompletedChecklistCount();
    if (total > completed) {
      pending = total - completed;
    }
    this.pending = pending;
    this.observablePending.next(this.pending);
    return pending;
  }

  goChecklistAction(accountTaskType: AccountTaskType) {
    if (accountTaskType === 'setup_visitor_page') {
      this.presentVisitorWizard();
    } else if (accountTaskType === 'add_guest') {
      this.router.navigate(['/tabs-guest/new']);
    } else if (accountTaskType === 'send_qr') {
      this.presentGuestListModal('not_send');
    } else if (accountTaskType === 'invite_member') {
      this.goAccountDetailPage('accountUser');
    } else if (accountTaskType === 'assign_seating') {
      this.router.navigate(['/tabs-guest/seating']);
    } else if (accountTaskType === 'review_setting') {
      this.presentCheckinSettingModal();
    } else if (accountTaskType === 'checkin_guest') {
      this.router.navigate(['/tabs-checkin/list']);
    } else if (accountTaskType === 'record_gift') {
      this.router.navigate(['/tabs-gift/new']);
    }
  }

  goChecklistHelp(accountTaskType: AccountTaskType) {
    const index = this.accountTaskList?.findIndex((accountTask: AccountTask) => {
      if (accountTask?.name === accountTaskType && accountTask?.help) {
        return true;
      }
      return false;
    });
    if (index !== -1) {
      this.openUrl(this.accountTaskList?.[index]?.help);
      return true;
    }
    return false;
  }

  async openUrl(url: string) {
    this.linkService.openUrl(url);
  }

  async promptLeavePage(url: string) {
    if (url) {
      this.openUrl(url);
    }
  }

  /**
   * Present check-in setting
   */
  // async presentVisitorSetting(type: VisitorSettingType) {
  //   if (type) {
  //     const modal = await this.modalController.create({
  //       component: SettingVisitorComponent,
  //       cssClass: 'modal-full-screen-bk',
  //       componentProps: {
  //         type
  //       }
  //     });
  //     modal.present();
  //   }
  // }

  async presentVisitorWizard() {
    const modal = await this.modalController.create({
      component: SettingVisitorWizardComponent,
      cssClass: 'modal-full-screen-bk',
      componentProps: {
      }
    });
    modal.present();
  }

  /**
   * Present guest list modal
   * @param invitedBy invited by
   * @param category category
   * @param dietaryReq dietary request
   * @param specialReq special request
   * @param groupType group type
   */
  async presentGuestListModal(qrcode?: QrcodeType) {
    const type: GuestFilterType = {
      qrcode
    };
    const modal = await this.modalController.create({
      component: GuestListComponent,
      cssClass: '',
      componentProps: {
        type,
        mode: 'list'
      }
    });
    modal.present();
  }

  /**
   * Present check-in setting
   */
  async presentCheckinSettingModal() {
    const modal = await this.modalController.create({
      component: SettingCheckinComponent,
      cssClass: '',
      componentProps: {}
    });
    modal.present();
    modal.onWillDismiss().then(() => {

    });
  }

  goAccountDetailPage(detailType?: AccountDetailType) {
    const navigationExtras: NavigationExtras = {
      state: {
        detailType
      }
    };
    this.router.navigate(['/account/detail'], navigationExtras);
  }
}
