import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { WebsiteContactDetail, WebsiteDetail } from 'src/app/interfaces/website';
import { FunctionService } from 'src/app/services/general/function.service';
import { LinkService } from 'src/app/services/general/link.service';

@Component({
  selector: 'app-website-contact',
  templateUrl: './website-contact.component.html',
  styleUrls: ['./website-contact.component.scss'],
})
export class WebsiteContactComponent  implements OnInit, OnDestroy {

  @Input() set setReady(ready: boolean) {
    this.ready = ready;
    if (ready) {
      this.setup();
    }
  }
  @Input() previewMode: boolean;
  @Input() pageMode: boolean;
  @Input() set setWebsiteDetail(websiteDetail: WebsiteDetail) {
    this.websiteDetail = websiteDetail;
    this.title = this.getContent(this.websiteDetail?.contact?.title);
    this.msg = this.getContent(this.websiteDetail?.contact?.msg);
    this.setup();
  } 

  @Input() largeMode: boolean;

  @Input() mediaHeight: number;

  @Input() updateTheme: boolean;

  websiteDetail: WebsiteDetail;
  title: string;
  msg: string;

  ready: boolean;

  contactList: WebsiteContactDetail[];

  constructor(
    private linkService: LinkService,
    private functionService: FunctionService,
  ) { }

  ngOnInit() {}

  ngOnDestroy(): void {
      
  }

  setup() {
    // const title = this.websiteDetail?.contact?.title;
    // const msg = this.websiteDetail?.contact?.msg;

    // if (title) {
    //   if (! this.functionService.sanitizeHtml(title).trim()) {
    //     this.websiteDetail.contact.title = '';
    //   }
    // }

    // if (msg) {
    //   if (! this.functionService.sanitizeHtml(msg).trim()) {
    //     this.websiteDetail.contact.msg = '';
    //   }
    // }
    if (this.ready) {
      this.setupList();
    } else {
      setTimeout(() => {
        this.setup();
      }, 500);
    }
  }

  setupList() {
    if (this.websiteDetail?.contact?.contactsList) {
      this.contactList = Object.values(this.websiteDetail.contact.contactsList)
      .filter((x: WebsiteContactDetail) => {
        return x?.contactId && x?.type && x?.order;
      })
      .sort((a: WebsiteContactDetail, b: WebsiteContactDetail) => {
        return this.functionService.compare(a?.order, b?.order);
      });
    } else {
      this.contactList = [];
    }
  }

  getContent(msg: string) {
    if (msg) {
      if (!this.functionService.sanitizeHtml(msg).trim()) {
        return '';
      }
    }
    return msg;
  }

  openContact(contact: WebsiteContactDetail) {
    if (contact?.type === 'whatsapp') {
      if (contact?.phone?.no) {
        this.openUrl('https://wa.me/' + contact.phone.no);
      }
    } else if (contact?.type === 'line') {
      if (contact?.key) {
        this.openUrl('line://ti/p/' + contact.key);
      }
    } else if (contact?.type === 'messenger') {
      if (contact?.username) {
        this.openUrl('https://m.me/' + contact.username);
      }
    } else if (contact?.type === 'telegram') {
      if (contact?.username) {
        this.openUrl('https://t.me/' + contact.username);
      }
    } else if (contact?.type === 'viber') {
      if (contact?.phone?.no) {
        this.openUrl('viber://add?number=' + contact.phone.no);
      }
    } else if (contact?.type === 'snapchat') {
      if (contact?.username) {
        this.openUrl('https://www.snapchat.com/add/' + contact.username);
      }
    } else if (contact?.type === 'instagram') {
      if (contact?.username) {
        this.openUrl('https://instagram.com/' + contact.username);
      }
    } else if (contact?.type === 'facebook') {
      if (contact?.username) {
        this.openUrl('https://facebook.com/' + contact.username);
      }
    } else if (contact?.type === 'skype') {
      if (contact?.username) {
        this.openUrl('skype:' + contact?.username);
      } else if (contact?.phone?.no) {
        this.openUrl('skype:' + contact?.phone.no);
      }
    } else if (contact?.type === 'email') {
      if (contact?.email) {
        this.openEmail(contact.email);
      }
    } else if (contact?.type === 'phone') {
      if (contact?.phone?.no) {
        this.openPhone(contact.phone.no);
      }
    } else if (contact?.type === 'website') {
      if (contact?.url) {
        this.openUrl(contact.url);
      }
    } else {
      if (contact?.url) {
        this.openUrl(contact.url);
      }
    }
  }

  openPhone(no: string) {
    if (no) {
      this.openUrl('tel:' + no);
    }
  }

  openEmail(email: string) {
    if (email) {
      this.openUrl('mailto:' + email);
    }
  }

  openUrl(url: string) {
    this.linkService.openUrl(url);
  }

}
