import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { SettingField } from 'src/app/interfaces/database';
import { FunctionService } from 'src/app/services/general/function.service';

import { PopupService } from 'src/app/services/general/popup.service';
import { CategoryService } from 'src/app/services/setting/category.service';
import { DietaryReqService } from 'src/app/services/setting/dietary-req.service';
import { InvitedByService } from 'src/app/services/setting/invited-by.service';
import { SessionService } from 'src/app/services/setting/session.service';
import { SettingFieldService } from 'src/app/services/setting/setting-field.service';
import { SpecialReqService } from 'src/app/services/setting/special-req.service';
import { WebsiteItineraryService } from 'src/app/services/website/website-itinerary.service';
import { SettingFieldType } from 'src/app/types/general';

@Component({
  selector: 'app-setting-field-new',
  templateUrl: './setting-field-new.component.html',
  styleUrls: ['./setting-field-new.component.scss'],
})
export class SettingFieldNewComponent  implements OnInit, OnDestroy {

  @ViewChild('cdkVirtualScrollViewport') cdkVirtualScrollViewport: CdkVirtualScrollViewport;

  settingFieldType: SettingFieldType;

  settingFieldTitle: string;

  list: SettingField[];

  stdList: SettingField[];

  selected: SettingField[];

  eventMode: boolean;

  newValue: string;

  constructor(
    private modalController: ModalController,
    private translate: TranslateService,
    private settingFieldService: SettingFieldService,
    private invitedByService: InvitedByService,
    private categoryService: CategoryService,
    private dietaryReqService: DietaryReqService,
    private specialReqService: SpecialReqService,
    private sessionService: SessionService,
    private websiteItineraryService: WebsiteItineraryService,
    private popupService: PopupService,
    private functionService: FunctionService,
  ) { }

  ngOnInit() {}

  ngOnDestroy(): void {
      
  }

  ionViewWillEnter() {
    this.setupList();
    this.settingFieldTitle = this.getSettingFieldTitle();
  }

  setupList() {
    if (!this.list?.length) {
      this.list = [];
    }
    this.selected = [];
    this.stdList = [ ...this.getStdList()?.filter((x: SettingField) => {
      const index = this.list.findIndex((y: SettingField) => {
        return x.value === y.value && x.custom === y.custom;
      });
      return index === -1 ? true : false;
    }) ];

    this.setupViewport();
  }

  setupViewport() {
    if (this.cdkVirtualScrollViewport) {
      this.cdkVirtualScrollViewport?.checkViewportSize();
    } else {
      setTimeout(() => {
        this.setupViewport();
      }, 200);
    }
  }

  // async discard() {
  //   if (this.newValue) {
  //     const actionSheet = await this.actionSheetController.create({
  //       header: this.translate.instant('MSG.discard_msg'),
  //       buttons: [{
  //         text: this.translate.instant('BTN.discard'),
  //         role: 'destructive',
  //         handler: () => {
  //           this.dismissModal();
  //         }
  //       }, {
  //         text: this.translate.instant('BTN.save'),
  //         role: 'destructive',
  //         handler: async () => {
  //           await this.checkSettingField(this.newValue);
  //           this.dismissModal();
  //         }
  //       }, {
  //         text: this.translate.instant('BTN.cancel'),
  //         role: 'cancel',
  //         handler: () => {
  //         }
  //       }]
  //     });
  //     actionSheet.present();
  //   } else {
  //     this.dismissModal();
  //   }
  // }

  async deletePrompt(settingField: SettingField, slidingItem?: any, index?: number) {
    if (settingField?.value && settingField?.custom) {
      const stdList = [ ...this.stdList ];
      if (stdList?.[index]?.value === settingField?.value && stdList?.[index]?.custom) {
        stdList.splice(index, 1);
        this.stdList = [ ...stdList ];
        const selectedIndex = this.selected?.findIndex((x: SettingField) => {
          return x?.value === settingField.value && x?.custom === settingField.custom;
        });
        if (selectedIndex !== -1) {
          this.selected.splice(selectedIndex, 1);
        }
        this.setupViewport();
      }
    }
    if (slidingItem) { slidingItem.close(); }
  }

  async dismissModal(selected?: SettingField[], list?: SettingField[]) {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { modal.dismiss({ selected, list }); }
    }
  }

  getSettingFieldTitle(): string {
    return this.settingFieldService.getSettingFieldTitle(this.settingFieldType);
  }

  getOriginalList() {
    if (this.settingFieldType === 'invited_by') {
      return this.invitedByService.getInvitedByList(this.eventMode);
    } else if (this.settingFieldType === 'category') {
      return this.categoryService.getCategoryList(this.eventMode);
    } else if (this.settingFieldType === 'dietary_req') {
      return this.dietaryReqService.getDietaryReqList();
    } else if (this.settingFieldType === 'special_req') {
      return this.specialReqService.getSpecialReqList(this.eventMode);
    } else if (this.settingFieldType === 'session') {
      return this.sessionService.list;
    } else if (this.settingFieldType === 'itinerary') {

    }
  }

  getStdList() {
    if (this.settingFieldType === 'invited_by') {
      return this.invitedByService.getStdInvitedByList(this.eventMode);
    } else if (this.settingFieldType === 'category') {
      return this.categoryService.getStdCategoryList(this.eventMode);
    } else if (this.settingFieldType === 'dietary_req') {
      return this.dietaryReqService.getStdDietaryReqList();
    } else if (this.settingFieldType === 'special_req') {
      return this.specialReqService.getStdSpecialReqList(this.eventMode);
    } else if (this.settingFieldType === 'session') {
      return this.sessionService.list;
    } else if (this.settingFieldType === 'itinerary') {
      return this.websiteItineraryService.getStdItineraryList();
    }
  }

  getValue(settingField: SettingField): string {
    if (settingField?.value) {
      if (settingField?.custom) {
        return settingField.value;
      } else if (this.settingFieldType) {
        return this.translate.instant('LIST.' + this.settingFieldType + '.' + settingField.value);
      }
    }
    return '';
  }

  checkSelected(settingField: SettingField): boolean {
    if (this.selected) {
      const index = this.selected?.findIndex((x: SettingField) => x.custom === settingField.custom && x.value === settingField.value);
      if (index !== -1) {
        return true;
      }
    }
    return false;
  }

  checkSwipe(settingField: SettingField) {
    if (!settingField?.custom) {
      this.popupService.presentToast(this.translate.instant('VALIDATION.reserved', { field: this.getSettingFieldTitle() }), 'warning');
    }
  }

  /**
   * Check settingField for duplicate before save
   * @param value New settingField
   * @param settingField settingField
   */
  async checkSettingField(value: string) {
    if (value) {
      let settingField: SettingField = {
        id: '',
        value,
        custom: true,
      };

      const duplicateIndex: number = this.list?.findIndex((x: SettingField) =>
        x.value?.toString()?.toLowerCase() === value.toString().toLowerCase() || this.getValue(x)?.toString()?.toLowerCase() === value?.toString()?.toLowerCase()
      );
      const duplicateIndex2: number = this.selected?.findIndex((x: SettingField) =>
        x.value?.toString()?.toLowerCase() === value.toString().toLowerCase() && x.custom
      );
      if (duplicateIndex !== -1 || duplicateIndex2 !== -1) {
        this.popupService.presentToast(
          this.translate.instant('VALIDATION.duplicate_field', { field: this.getSettingFieldTitle() }), 'danger');
      } else {
        const duplicateStdIndex: number = this.stdList?.findIndex((x: SettingField) =>
          x.value?.toString().toLowerCase() === value.toString().toLowerCase() || (this.getValue(x)?.toString().toLowerCase() === value.toString().toLowerCase())
        );
        if (duplicateStdIndex !== -1 && this.stdList?.[duplicateStdIndex]) {
          settingField = this.stdList[duplicateStdIndex];
        } else {
          const stdList = [ ...this.stdList ];
          stdList.push(settingField);
          this.stdList = [ ...stdList ];
          this.setupViewport();
        }
        if (!settingField?.id) {
          settingField.id = this.functionService.randomId() + '_' + value;
        }
        this.selected = [ settingField ];
        // this.save(this.list);
        if (this.settingFieldType === 'itinerary') {
          this.dismissModal(this.selected);
        }
      }
    }
  }

  async save() {
    // const originalList = [ ...this.getOriginalList() ];
    // originalList.push(list)
    if (this.selected?.length) {
      const list = this.list.concat(this.selected);
      await this.saveList(list);
      // this.popupService.dismissLoading();

      if (this.settingFieldType === 'itinerary') {
        await this.dismissModal(this.selected);
      } else {
        await this.dismissModal(this.selected, this.selected);
        await this.popupService.saveSuccessToast();
      }
    }
    
  }

  async saveList(list: SettingField[]) {
    if (this.settingFieldType === 'invited_by') {
      await this.invitedByService.saveInvitedBy(list);
    } else if (this.settingFieldType === 'category') {
      await this.categoryService.saveCategory(list);
    } else if (this.settingFieldType === 'dietary_req') {
      await this.dietaryReqService.saveDietaryReq(list);
    } else if (this.settingFieldType === 'special_req') {
      await this.specialReqService.saveSpecialReq(list);
    } else if (this.settingFieldType === 'session') {
      await this.sessionService.save(list);
    } else if (this.settingFieldType === 'itinerary') {

    }
  }

  async select(settingField: SettingField) {
    if (settingField?.value) {
      if (this.settingFieldType === 'itinerary') {
        this.selected = [ settingField ];
        this.dismissModal(this.selected);
      } else {
        const index = this.selected?.findIndex((x: SettingField) => {
          return x.value === settingField.value && x.custom === settingField.custom;
        });
        if (index === -1) {
          this.selected.push(settingField);
        } else {
          this.selected.splice(index, 1);
        }
      }
    }
  }

  /**
   * Track setting field item
   * @param index Index
   * @param item item
   */
  trackByFn(index: number, item: SettingField) {
    if (item?.id) {
      return item.id;
    } else if (item?.value) {
      return item.value;
    }
    return index;
  }

}
