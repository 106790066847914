<ion-header>
  <ion-toolbar color="light">
    <ion-buttons slot="start">
      <ion-button (click)="dismissModal()">
        <ion-icon class="back-icon" src="./assets/wedding/icon/general/chevron-back.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <app-offline-indicator></app-offline-indicator>
      {{ 'BTN.language' | translate }}
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content color="light">
  <div class="ion-padding full-width">
    <ion-card class="ion-no-margin" *ngIf="qrcodeTemplateList && qrcodeTemplateList[currentIndex]" (click)="editMode = true;" button>
      <ion-card-header class="ion-no-padding" >
        <ion-item class="ion-no-padding" lines="none" color="tertiary">
          <ion-icon class="icon-small ion-padding-horizontal" color="dark" src="./assets/wedding/icon/general/msg-quote.svg"></ion-icon>
          <ion-text class="font-10 font-bold" color="dark">{{ 'QRCODE.lbl.msg_template' | translate }}</ion-text>
        </ion-item>
      </ion-card-header>
      <ion-card-content>
        <div class="full-width font-10 ion-padding-top" *ngIf="editMode">
          <ion-textarea [(ngModel)]="qrcodeTemplateList[currentIndex].msg" autoGrow="true" *ngIf="editMode" (ionBlur)="editMode = false;"></ion-textarea>
        </div>
        <div class="font-10 full-width ion-padding-top wrap-newline" *ngIf="!editMode" [innerHtml]="getHtml(qrcodeTemplateList[currentIndex].msg)"></div>
      </ion-card-content>
    </ion-card>

    <div class="ion-padding-top">
      <ion-text class="font-10 font-bold">{{ 'QRCODE.lbl.dynamic_variable' | translate }}</ion-text>
      <ion-segment class="background-transparent" scrollable>
        <ion-segment-button class="dynamic-variable-seg-btn ion-no-padding" *ngFor="let variable of getTemplateVariableList()" (click)="copy(variable)">
          <ion-text class="font-10">{{ replaceEventType('TEMPLATE.variable.' + variable | translate) }}</ion-text>
        </ion-segment-button>
      </ion-segment>
    </div>
  </div>

  <ion-label class="font-10 font-bold ion-margin-horizontal">{{ 'QRCODE.lbl.template_selection' | translate }}</ion-label>
  <ng-container *ngFor="let qrcodeTemplate of qrcodeTemplateList; let i = index;">
    <ion-item-sliding #slidingItem>
      <ion-item [color]="selected?.value === qrcodeTemplate.name.value ? 'primary' : 'light'" lines="full" *ngIf="qrcodeTemplate?.name?.value" (click)="setSelected(qrcodeTemplate)">
        <ion-text slot="start">
          <ng-container *ngIf="qrcodeTemplate.name.custom">
            {{ qrcodeTemplate.name.value }}
          </ng-container>
          <ng-container *ngIf="!qrcodeTemplate.name.custom">
            {{ 'LIST.language.' + qrcodeTemplate.name.value | translate }}
          </ng-container>
        </ion-text>
        <ion-text slot="end" *ngIf="selected?.value === qrcodeTemplate.name.value">
          <ion-icon src="./assets/wedding/icon/general/checkmark.svg"></ion-icon>
        </ion-text>
      </ion-item>
      <ion-item-options side="end">
        <ion-item-option color="medium" (click)="editTemplate(qrcodeTemplate.name, slidingItem)"><ion-icon src="./assets/wedding/icon/general/edit.svg"></ion-icon></ion-item-option>
        <ion-item-option color="danger" (click)="presentConfirmDeleteModal(qrcodeTemplate.name, slidingItem)"><ion-icon src="./assets/wedding/icon/general/trash.svg"></ion-icon></ion-item-option>
      </ion-item-options>
    </ion-item-sliding>
  </ng-container>
  
  <ion-item color="medium" lines="none" (click)="newTemplate()">
    <ion-icon class="icon-large center" src="./assets/wedding/icon/general/add-circle-outline.svg"></ion-icon>
  </ion-item>

</ion-content>
<ion-footer>
  <ion-toolbar>
    <ion-button class="ion-margin" expand="block" color="primary" fill="solid" type="button" (click)="selectTemplate()">
      {{ 'BTN.save' | translate }}
    </ion-button>
  </ion-toolbar>
</ion-footer>