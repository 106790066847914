import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { AccountUserService } from 'src/app/services/account/account-user.service';
import { SettingFieldService } from 'src/app/services/setting/setting-field.service';

import { SettingField } from 'src/app/interfaces/database';
import { SearchType, SettingFieldType } from 'src/app/types/general';
import { SeatingService } from 'src/app/services/seating/seating.service';
import { SeatingSettingService } from 'src/app/services/seating/seating-setting.service';
import { DateTimeService } from 'src/app/services/general/date-time.service';
import { AccountsListService } from 'src/app/services/accounts/accounts-list.service';

/**
 * Filter value component to display fitler criteria.
 */
@Component({
  selector: 'app-filter-value',
  templateUrl: './filter-value.component.html',
  styleUrls: ['./filter-value.component.scss'],
})
export class FilterValueComponent implements OnInit, OnDestroy {

  /**
   * Search type
   */
  @Input() type: SearchType;
  /**
   * Filter criteria
   */
  @Input() filter: any;
  /**
   * Sorting field
   */
  @Input() sorting: string;
  /**
   * Decending flag
   */
  @Input() desc: boolean;

  /**
   * Edit filter modal output
   */
  @Output() editFilterModal = new EventEmitter<boolean>();

  @Output() checkHeight = new EventEmitter<boolean>();

  seatingTypeName: string;

  /**
   * Constructor
   * @param accountUserService Account user service
   * @param settingFieldService Setting field service
   */
  constructor(
    private accountsListService: AccountsListService,
    private accountUserService: AccountUserService,
    private settingFieldService: SettingFieldService,
    private seatingSettingService: SeatingSettingService,
    private dateTimeService: DateTimeService,
  ) {}

  ngOnInit() {}

  ngOnDestroy() {}
  
  /**
   * Get user name by UID
   * @param uid UID
   */
  getUserName(uid: string) {
    if (uid) {
      return this.accountUserService.getUserByUid(uid)?.name;
    }
    return '';
  }

  /**
   * Get list of setting field value
   * @param settingFieldType Setting field type
   * @param settingFieldList Setting field list
   * @returns Join of setting field value
   */
  getSettingField(settingFieldType: SettingFieldType, settingFieldList: SettingField[]): string {
    return this.settingFieldService.joinSettingField(settingFieldType, settingFieldList);
  }

  getSeatingTypeName(): string {
    return this.seatingSettingService.getSeatingTypeName();
  }

  getAccountTitle(accountId: string) {
    return this.accountsListService.getAccountTitle(accountId);
  }

  format(timestamp: number, dateTimeFormat?: string, timezone?: string) {
    return this.dateTimeService.format(timestamp, dateTimeFormat, '', timezone);
  }

  /**
   * Toggle show filter and get parent header height
   */
  toggleFilter() {
    this.filter.show = !this.filter.show;
    this.checkHeight.next(true);
  }

  /**
   * Present filter modal
   * @param editFlag Edit filter flag
   */
  presentFilterModal(editFlag: boolean) {
    this.editFilterModal.next(editFlag);
  }

}
