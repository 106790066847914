import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, NgZone, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';

import { WebsiteContent, WebsiteDetail, WebsiteLink, WebsiteTheme, WebsiteThemeBackground, WebsiteThemeColor } from 'src/app/interfaces/website';
import { WebsiteDetailService } from 'src/app/services/website/website-detail.service';
import { FunctionService } from 'src/app/services/general/function.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { AccountInfo } from 'src/app/interfaces/account';

import { WebsiteType } from 'src/app/types/website';
import { MenuController, Platform } from '@ionic/angular';
import { Group } from 'src/app/interfaces/group';
import { Guest } from 'src/app/interfaces/guest';
import { WebsiteService } from 'src/app/services/website/website.service';
import { SettingField } from 'src/app/interfaces/database';
import { WebsiteThemeList } from 'src/app/commons/websiteTheme';
import { WebsiteThemeColorList } from 'src/app/commons/websiteThemeColor';

import { getTime } from 'date-fns';
import { WebsiteFontService } from 'src/app/services/website/website-font.service';
import { Router } from '@angular/router';

import { LinkService } from 'src/app/services/general/link.service';
import { WebsiteContentsService } from 'src/app/services/website/website-contents.service';
import { WebsiteTypeService } from 'src/app/services/website/website-type.service';
import { Subscription } from 'rxjs';
import { VisitorRealtimeService } from 'src/app/services/visitor/visitor-realtime.service';
import { StatusBar } from '@capacitor/status-bar';
import { DateTimeService } from 'src/app/services/general/date-time.service';
import { Keyboard, KeyboardResize } from '@capacitor/keyboard';

@Component({
  selector: 'app-website-component',
  templateUrl: './website.component.html',
  styleUrls: ['./website.component.scss'],
})
export class WebsiteComponent implements OnInit, OnDestroy {

   /**
   * Watch screen resize change
   */
  @HostListener('window:resize', ['$event']) onResize(event: any) {
    this.frameBottomHeightCount = 0;
    this.frameTopHeightCount = 0;
    this.contentHeightCount = 0;
    this.setupContentHeight();
    this.setupFrameTopHeight();
    this.setupFrameBottomHeight();
    this.checkLandscapeMode();
  }
  /**
   * Watch screen orientation change
   */
  @HostListener('window:orientationchange', ['$event']) onOrientationChange(event: any) {
    this.frameBottomHeightCount = 0;
    this.frameTopHeightCount = 0;
    this.contentHeightCount = 0;
    this.setupContentHeight();
    this.setupFrameTopHeight();
    this.setupFrameBottomHeight();
    this.checkLandscapeMode();
  }

  @ViewChild('mainElement', { read: ElementRef, static: false }) mainElement: ElementRef;

  @ViewChild('frameTopImg', { read: ElementRef, static: false }) frameTopImg: ElementRef;
  @ViewChild('frameBottomImg', { read: ElementRef, static: false }) frameBottomImg: ElementRef;
  @ViewChild('coverPhotoLandscape', { read: ElementRef, static: false }) coverPhotoLandscape!: ElementRef;
  @ViewChild('coverPhotoPortrait', { read: ElementRef, static: false }) coverPhotoPortrait!: ElementRef;

  @Input() set setUpdateTheme(ready: boolean) {
    this.updateTheme = ready;
    if (this.updateTheme) {
      this.setupWebsiteTheme();
    }
  }
  
  @Input() designMode: boolean;
  @Input() previewMode: boolean;

  @Input() pageMode: boolean;

  @Input() pdfMode: boolean;

  @Input() mobileMode: boolean;

  @Input() contentHeight: number;

  @Input() accountId: string;

  @Input() linkId: string;

  @Input() guestId: string;

  @Input() groupId: string;
  
  @Input() set setWebsiteDetail(websiteDetail: WebsiteDetail) {
    let updateTheme = false;
    let updateContents = false;
    if (!this.functionService.isEqual(websiteDetail?.theme, this.websiteDetail?.theme)) {
      updateTheme = true;
    }
    if (!this.functionService.isEqual(websiteDetail?.contents, this.websiteDetail?.contents)) {
      updateContents = true;
    }
    this.websiteDetail = this.functionService.cloneDeep(websiteDetail);

    if (updateContents) {
      this.setupContents();
    }
    if (updateTheme) {
      this.setupWebsiteTheme();
    }
    
    this.setupMsg();
  };

  @Input() websiteLink: WebsiteLink;

  @Input() websiteTheme: WebsiteTheme;

  @Input() accountInfo: AccountInfo;

  // @Input() fontClass: string;

  @Input() set setWebsiteType(websiteType: WebsiteType) {
    this.websiteType = websiteType;
    this.setupWebsiteType(websiteType);
  }

  @Input() set setReady(ready: boolean) {
    if (ready) {
      this.initialize();
    } else {
      this.destroy();
    }
    this.ready = ready;
  }

  @Output() downloadPdf = new EventEmitter<boolean>();

  websiteType: WebsiteType;

  websiteDetail: WebsiteDetail;

  showLoading: boolean;

  btn: {
    rsvpTitle: string,
    rsvpBtn: string,
    qrcode: string,
    checkin: string,
    venue: string,
    contact: string,
    gallery: string,
    profile: string,
  } = {
    rsvpTitle: '',
    rsvpBtn: '',
    qrcode: '',
    checkin: '',
    venue: '',
    contact: '',
    gallery: '',
    profile: '',
  };

  

  guest: Guest;
  group: Group;
  memberList: Guest[];

  // invitedBy: SettingField;
  // category: SettingField;

  invitedByList: SettingField[];
  categoryList: SettingField[];

  dietaryReqList: SettingField[];
  specialReqList: SettingField[];

  height: number = 0;
  width: number = 0;

  frameTopHeight: number = 0;
  frameBottomHeight: number = 0;

  timestamp: number;
  actualDay: boolean;
  afterEvent: boolean;

  private contentHeightCount: number = 0;
  private frameTopHeightCount: number = 0;
  private frameBottomHeightCount: number = 0;

  backgroundUrl: {
    top: string;
    bottom: string;
    full: string;
  } = {
    top: '',
    bottom: '',
    full: '',
  };

  days: number;
  hours: number;
  minutes: number;

  timeout: any;
  counterFlag: boolean;
  
  ready: boolean;

  websiteThemeBackground: WebsiteThemeBackground;
  websiteThemeColor: WebsiteThemeColor;

  contentsList: WebsiteContent[];

  updateTheme: boolean;

  currentYear: number;

  landscapeMode: boolean;

  msg: any;

  hideGallery: boolean = false;

  checkinStatus: boolean;

  private downloadPdfFlag: boolean;

  private fontLink!: HTMLLinkElement;

  private websiteTypeSubscription: Subscription;

  private checkinStatusSubscription: Subscription;

  constructor(
    private platform: Platform,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private ngZone: NgZone,
    private http: HttpClient,
    private renderer: Renderer2,
    private translate: TranslateService,
    private menuController: MenuController,
    private websiteDetailService: WebsiteDetailService,
    private websiteFontService: WebsiteFontService,
    private websiteContentsService: WebsiteContentsService,
    private websiteTypeService: WebsiteTypeService,
    private visitorRealtimeService: VisitorRealtimeService,
    // private photoService: PhotoService,
    private dateTimeService: DateTimeService,
    private websiteService: WebsiteService,
    private linkService: LinkService,
    private functionService: FunctionService,
  ) { }

  ngOnInit() {
    
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  ionViewWillEnter() {
    this.ready = true;
    this.initialize();
  }

  ionViewWillLeave() {
    this.destroy();
  }

  async initialize() {
    this.hideGallery = false;
    this.actualDay = false;
    this.afterEvent = false;
    this.timestamp = new Date().getTime();

    this.checkTime();
    this.showLoading = true;
    if (this.previewMode) {
      if (!this.websiteDetail) {
        this.websiteDetail = this.functionService.cloneDeep(this.websiteDetailService.getWebsiteDetail());
      }
      await this.setupWebsiteTheme();
    } else {
      // if (!this.fontClass) {
      //   this.fontClass = '';
      // }
      this.retrieveWebsiteCall();
    }

    if (!this.pdfMode) {
      if (this.checkinStatus) {
        this.websiteType = 'profile';
      } else if (!this.websiteType) {
        this.websiteType = 'home';
      }
      this.setupWebsiteType(this.websiteType);
      this.watchWebsiteType();
    } else {
      if (this.downloadPdfFlag) {
        this.downloadPdfFlag = false;
      }
    }

    this.currentYear = new Date().getFullYear();
    this.counterFlag = true;
    this.counter();
    this.contentHeightCount = 0;
    this.setupContentHeight();
    this.setupButton();
  }

  checkTime() {
    if (this.timestamp && this.accountInfo?.time?.seconds) {
      if (this.isDateEqualToCurrentDate(this.accountInfo.time.seconds * 1000)) {
        this.actualDay = true;
        this.hideGallery = true;
      } else if (this.accountInfo?.endTime?.seconds && this.isDateEqualToCurrentDate(this.accountInfo.endTime.seconds * 1000)) {
        this.actualDay = true;
        this.hideGallery = true;
      } else if ((this.timestamp / 1000) > this.accountInfo.time.seconds) {
        this.afterEvent = true;
      }
      // const currentDate = this.dateTimeService.format(this.timestamp, 'yyyy-MM-dd', '', this.accountInfo?.timezone?.name, 'en-US');
      // const accountDate = this.dateTimeService.format(this.accountInfo?.time?.seconds * 1000, 'yyyy-MM-dd', '', this.accountInfo?.timezone?.name, 'en-US');
      // if (currentDate === accountDate) {
      //   this.actualDay = true;
      // } else if ((this.timestamp / 1000) > this.accountInfo.time.seconds) {
      //   this.actualDay = true;
      // } else {
      //   this.actualDay = false;
      // }
    }
  }

  destroy() {
    this.ready = false;
    this.counterFlag = false;
    this.removeFont();
    this.unwatchWebsiteType();
    this.visitorRealtimeService.unwatchRtdb();
  }

  async watchRtdb() {
    if (this.accountId && (this.guest?.guestId || this.group?.groupId)) {
      await this.visitorRealtimeService.watchRtdb(this.accountId, this.guest?.guestId, this.group?.groupId);
      await this.watchCheckinStatus();
    }
  }

  async watchCheckinStatus() {
    if (!this.checkinStatusSubscription) {
      this.checkinStatusSubscription = this.visitorRealtimeService.observableCheckinStatus.subscribe((checkinStatus: boolean) => {
        if (this.checkinStatus !== checkinStatus) {
          this.checkinStatus = checkinStatus;
          if (this.checkinStatus) {
            this.setupWebsiteType('profile');
          }
          // this.updateCheckinStatus(checkinStatus);
        }
      });
    }
  }

  async unwatchCheckinStatus() {
    if (this.checkinStatusSubscription) {
      this.checkinStatusSubscription.unsubscribe();
      this.checkinStatusSubscription = null;
    }
  }

  watchWebsiteType() {
    if (!this.websiteTypeSubscription) {
      this.websiteTypeSubscription = this.websiteTypeService.observable.subscribe((websiteType: WebsiteType) => {
        if (websiteType && this.websiteType !== websiteType) {
          this.websiteType = websiteType;
        }
      });
    }
  }

  unwatchWebsiteType() {
    if (this.websiteTypeSubscription) {
      this.websiteTypeSubscription.unsubscribe();
      this.websiteTypeSubscription = null;
    }
  }

  async retrieveWebsiteCall() {
    // await this.popupService.presentLoading();
    const result = await this.websiteService.retrieveWebsiteCall(this.accountId, this.linkId, this.guestId, this.groupId);
    console.log(result);
    if (result?.websiteDetail) {
      this.websiteDetail = result.websiteDetail;
      if (result?.accountInfo) {
        this.accountInfo = result.accountInfo;
        if (this.accountInfo?.time?._seconds) {
          this.accountInfo.time.seconds = this.accountInfo.time._seconds;
        }
        if (this.accountInfo?.endTime?._seconds) {
          this.accountInfo.endTime.seconds = this.accountInfo.endTime._seconds;
        }

        if (result?.dietaryReqList) {
          this.dietaryReqList = result.dietaryReqList;
        }

        if (result?.specialReqList) {
          this.specialReqList = result.specialReqList;
        }
      }
      this.checkTime();
      await this.setupWebsiteTheme();
    }
    if (result?.guest?.guestId) {
      this.guest = result.guest;
      this.memberList = [ this.guest ];
    } else {
      this.guest = null;
      this.guestId = '';
      // if (this.guestId !== 'test') {
      //   this.guestId = '';
      // }
    }
    if (result?.group?.groupId) {
      this.group = result.group;
      this.memberList = result?.memberList;
    } else {
      this.group = null;
      this.groupId = '';
    }

    if (result?.websiteLink) {
      this.websiteLink = result.websiteLink;
      if (!this.linkId && this.websiteLink?.linkId) {
        this.linkId = this.websiteLink.linkId;
      }
      // if (this.websiteLink?.invitedBy?.length) {
      //   this.invitedBy = this.websiteLink.invitedBy[0];
      // }
      // if (this.websiteLink?.category?.length) {
      //   this.category = this.websiteLink.category[0];
      // }
    }
    // await this.popupService.dismissLoading();
  }

  async setupWebsiteTheme() {
    if (this.previewMode) {
      // if (!this.websiteTheme) {
      //   this.websiteTheme = this.websiteDetail.theme;
      // }
      this.websiteTheme = this.websiteDetail.theme;

      if (this.websiteTheme) {
        this.websiteThemeBackground = WebsiteThemeList[this.websiteTheme?.backgroundId];
        this.websiteThemeColor = WebsiteThemeColorList?.[this.websiteTheme?.colorId];
  
        if (!this.websiteTheme?.backgroundSize) {
          this.websiteTheme.backgroundSize = this.websiteThemeBackground?.backgroundSize[0];
        } else {
          const index = this.websiteThemeBackground?.backgroundSize.indexOf(this.websiteTheme?.backgroundSize);
          if (index === -1) {
            this.websiteTheme.backgroundSize = this.websiteThemeBackground?.backgroundSize[0];
          }
        }
  
        await this.readSvg();
        await this.setupFontClass();
      }
    } else if (this.websiteDetail?.theme) {
      this.websiteTheme = this.websiteDetail.theme;
      this.websiteThemeBackground = WebsiteThemeList[this.websiteTheme?.backgroundId];
      this.websiteThemeColor = WebsiteThemeColorList?.[this.websiteTheme?.colorId];

      if (!this.websiteTheme?.backgroundSize) {
        this.websiteTheme.backgroundSize = this.websiteThemeBackground?.backgroundSize[0];
      } else {
        const index = this.websiteThemeBackground?.backgroundSize.indexOf(this.websiteTheme?.backgroundSize);
        if (index === -1) {
          this.websiteTheme.backgroundSize = this.websiteThemeBackground?.backgroundSize[0];
        }
      }

      await this.readSvg();
      await this.setupFontClass();
    } else if (this.websiteTheme) {
      this.websiteThemeBackground = WebsiteThemeList[this.websiteTheme?.backgroundId];
      this.websiteThemeColor = WebsiteThemeColorList?.[this.websiteTheme?.colorId];

      if (!this.websiteTheme?.backgroundSize) {
        this.websiteTheme.backgroundSize = this.websiteThemeBackground?.backgroundSize[0];
      } else {
        const index = this.websiteThemeBackground?.backgroundSize.indexOf(this.websiteTheme?.backgroundSize);
        if (index === -1) {
          this.websiteTheme.backgroundSize = this.websiteThemeBackground?.backgroundSize[0];
        }
      }

      await this.readSvg();
      await this.setupFontClass();
    } else {
      this.websiteThemeBackground = WebsiteThemeList[this.websiteTheme?.backgroundId];
      this.websiteThemeColor = WebsiteThemeColorList?.[this.websiteTheme?.colorId];

      if (this.websiteTheme) {
        if (!this.websiteTheme?.backgroundSize) {
          this.websiteTheme.backgroundSize = this.websiteThemeBackground?.backgroundSize[0];
        } else {
          const index = this.websiteThemeBackground?.backgroundSize.indexOf(this.websiteTheme?.backgroundSize);
          if (index === -1) {
            this.websiteTheme.backgroundSize = this.websiteThemeBackground?.backgroundSize[0];
          }
        }
      }

      await this.readSvg();
      await this.setupFontClass();
    }
    // this.setupHideGallery();
    this.setupContents();
    this.setupMsg();
    this.setupStatusBar();
    // this.setupKeyboardResize();
  }

  setupContents() {
    this.setupButton();
    this.websiteDetail.contents = this.websiteContentsService.getWebsiteContents(this.websiteDetail.contents);
    this.contentsList = Object.values(this.websiteDetail.contents).sort((a: WebsiteContent, b: WebsiteContent) => {
      return this.functionService.compare(a.order, b.order);
    });
  }

  setupMsg() {
    this.msg = {
      title: this.getQuillContent(this.websiteDetail?.info?.title),
      msg: this.getQuillContent(this.websiteDetail?.info?.msg),
    };
    this.contentsList?.forEach((content: WebsiteContent) => {
      if (content?.contentId && content.contentId.indexOf('custom_') !== -1) {
        this.msg[content.contentId] = {
          title: this.getQuillContent(content?.title),
          msg: this.getQuillContent(content?.msg),
        };
      }
    });
  }

  async setupStatusBar() {
    await this.platform.ready();
    if (!this.previewMode) {
      if (this.websiteThemeColor?.background) {
        if (this.platform.is('hybrid')) {
          StatusBar.setBackgroundColor({ color: this.websiteThemeColor.background });
          StatusBar.setOverlaysWebView({ overlay: true });
        } else {
          const metaThemeColor = document.querySelector('meta[name="theme-color"]');
          if (metaThemeColor) {
            metaThemeColor.setAttribute('content', this.websiteThemeColor.background);
          } else {
            const meta = document.createElement('meta');
            meta.name = 'theme-color';
            meta.content = this.websiteThemeColor.background;
            document.head.appendChild(meta);
          }
        }
      }
    }
  }

  // async setupKeyboardResize() {
  //   await this.platform.ready();
  //   if (this.platform.is('ios')) {
  //     await Keyboard.setResizeMode({ mode: KeyboardResize.Ionic });
  //   }
  // }

  isDateEqualToCurrentDate(timestamp: number): boolean {
    const currentDate = new Date();
    const givenDate = new Date(timestamp);
  
    // Extract year, month, and day for comparison
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth(); // 0-based
    const currentDay = currentDate.getDate();
  
    const givenYear = givenDate.getFullYear();
    const givenMonth = givenDate.getMonth(); // 0-based
    const givenDay = givenDate.getDate();
  
    // Compare year, month, and day
    return (
      currentYear === givenYear &&
      currentMonth === givenMonth &&
      currentDay === givenDay
    );
  }

  async setupFontClass() {
    if (this.websiteTheme) {
      if (this.websiteTheme?.font) {
        // this.fontClass = this.getFontClass(this.websiteTheme.font);
      } else {
        this.websiteTheme.font = this.websiteFontService.getDefaultFont(this.accountInfo?.locale);
      }
      this.loadFont(this.websiteTheme?.font);
    }
  }

  setupContentHeight() {
    this.height = this.getContentHeight();
    if (!this.height || this.contentHeightCount < 20) {
      this.contentHeightCount += 1;
      setTimeout(() => {
        this.setupContentHeight();
      }, 200);
    }
  }

  setupButton() {
    this.btn = {
      rsvpTitle: this.getRsvpTitle(),
      rsvpBtn: this.getRsvpBtn(),
      qrcode: this.getQrcodeTitle(),
      checkin: this.getWebCheckinTitle(),
      venue: this.getVenueTitle(),
      contact: this.getContactBtn(),
      gallery: this.getGalleryTitle(),
      profile: this.getProfileTitle(),
    };
  }

  setupWebsiteType(websiteType: WebsiteType) {
    this.websiteTypeService.setupWebsiteType(websiteType);
  }

  calculateWidth(aspectRatio: string, height: number): number {
    const [widthRatio, heightRatio] = aspectRatio.split(':').map(Number);
    if (isNaN(widthRatio) || isNaN(heightRatio) || heightRatio === 0) {
      return 0;
    }
    return (widthRatio / heightRatio) * height;
  }

  setupFrameTopHeight() {
    if (this.backgroundUrl?.top) {
      this.frameTopHeight = this.getFrameTopHeight();
      if (!this.frameTopHeight || !this.frameTopHeightCount || this.frameTopHeightCount < 20) {
        if (this.frameTopHeight) {
          this.frameTopHeightCount += 1;
        }
        setTimeout(() => {
          this.setupFrameTopHeight();
        }, 200);
      }
    } else {
      this.frameTopHeight = 0;
    }
  }

  setupFrameBottomHeight() {
    if (this.backgroundUrl?.bottom) {
      this.frameBottomHeight = this.getFrameBottomHeight();
      if (!this.frameBottomHeight || !this.frameBottomHeightCount || this.frameBottomHeightCount < 20) {
        if (this.frameBottomHeight) {
          this.frameBottomHeightCount += 1;
        }
        setTimeout(() => {
          this.setupFrameBottomHeight();
        }, 200);
      }
    } else {
      this.frameBottomHeight = 0;
    }
  }

  getFrameTopHeight() {
    return this.frameTopImg?.nativeElement?.offsetHeight ? this.frameTopImg.nativeElement.offsetHeight : 0;
  }

  getFrameBottomHeight() {
    return this.frameBottomImg?.nativeElement?.offsetHeight ? this.frameBottomImg.nativeElement.offsetHeight : 0;
  }

  getContentHeight(): number {
    return this.contentHeight ? this.contentHeight : this.mainElement?.nativeElement?.offsetHeight ? this.mainElement.nativeElement.offsetHeight : 0;
  }

  getQuillContent(msg: string) {
    if (msg) {
      if (!this.functionService.sanitizeHtml(msg).trim()) {
        return '';
      }
    }
    return msg;
  }

  getTheme(): WebsiteThemeBackground {
    return WebsiteThemeList?.[this.websiteTheme?.backgroundId];
  }

  getTitle() {
    return this.websiteDetail?.info?.title ? this.websiteDetail.info.title : '';
  }

  getCoverMsg() {
    return this.websiteDetail?.info?.msg ? this.websiteDetail.info.msg : '';
  }

  // getContents() {
  //   return this.websiteContentsService.getWebsiteContents();
  // }

  // getContentsList() {
  //   const contents = this.getContents();
  //   return contents ? Object.values(contents).filter((x: WebsiteContent) => {
  //     return x?.contentId && x?.enable;
  //   }) : [];
  // }

  // getContent(contentId: string) {
  //   const contents = this.getContents();
  //   if (contentId && contents?.[contentId]) {
  //     return contents[contentId];
  //   }
  //   return null;
  // }

  getRsvpTitle() {
    if (this.websiteDetail?.form?.title) {
      return this.functionService.sanitizeHtml(this.websiteDetail.form.title);
    }
    return this.translate.instant('WEBSITE.form.title');
  }

  getRsvpBtn() {
    if (this.websiteDetail?.form?.fields?.btn) {
      return this.websiteDetail.form.fields.btn;
    }
    return this.translate.instant('WEBSITE.form.fields.btn');
  }

  getQrcodeTitle() {
    if (this.websiteDetail?.qrcode?.title) {
      return this.functionService.sanitizeHtml(this.websiteDetail.qrcode.title);
    }
    return this.translate.instant('WEBSITE.qrcode.title');
  }

  getQrcodeBtn() {
    if (this.websiteDetail?.qrcode?.btn) {
      return this.websiteDetail.qrcode.btn;
    }
    return this.translate.instant('WEBSITE.qrcode.btn');
  }

  getWebCheckinTitle() {
    if (this.websiteDetail?.checkin?.title) {
      return this.functionService.sanitizeHtml(this.websiteDetail.checkin.title);
    }
    return this.translate.instant('WEBSITE.checkin.title');
  }

  getWebCheckinBtn() {
    if (this.websiteDetail?.checkin?.btn) {
      return this.websiteDetail.checkin.btn;
    }
    return this.translate.instant('WEBSITE.checkin.btn');
  }

  getVenueTitle() {
    if (this.getQuillContent(this.websiteDetail?.venue?.title)) {
      return this.functionService.sanitizeHtml(this.websiteDetail.venue.title);
    }
    return this.translate.instant('WEBSITE.venue.btn');
  }

  getContactBtn() {
    if (this.websiteDetail?.contact?.btn) {
      return this.websiteDetail.contact.btn;
    }
    return this.translate.instant('WBESITE.contact.btn');
  }

  getGalleryTitle() {
    if (this.getQuillContent(this.websiteDetail?.gallery?.title)) {
      return this.functionService.sanitizeHtml(this.websiteDetail.gallery.title);
    }
    return this.translate.instant('WEBSITE.gallery.title');
  }

  getProfileTitle() {
    return this.translate.instant('WEBSITE.profile.title');
  }

  getWebsiteCover() {
    return this.websiteTheme?.cover?.media?.url ? this.websiteTheme.cover.media.url : this.websiteDetail?.theme?.cover?.media?.url;
  }

  // getFontClass(font: string) {
  //   return font ? this.functionService.replaceAll(font?.toLowerCase(), ' ', '-') : '';
  // }

  checkLoading() {
    if (this.fontLink && this.websiteDetail && this.accountInfo) {
      this.showLoading = false;
      if (this.pdfMode) {
        if (!this.downloadPdfFlag) {
          this.downloadPdfFlag = true;
          setTimeout(()=> {
            this.downloadPdf.next(true);
          }, 2000);
        }
      }
      this.checkLandscapeMode();
      this.watchRtdb();
    } else {
      setTimeout(() => {
        this.checkLoading();
      }, 500);
    }
  }

  private loadFont(fontName: string) {
    if (fontName) {
      this.removeFont();
      setTimeout(() => {
        let id = `font-${fontName}`;
        if (this.pdfMode) {
          id = 'pdf-mode-' + id;
        } else if (this.designMode) {
          id = 'theme-mode-' + id;
        } else if (this.previewMode) {
          id = 'preview-mode-' + id;
        }
        if (!document.getElementById(id)) {
          this.fontLink = this.renderer.createElement('link');
          this.fontLink.id = id;
          this.fontLink.rel = 'stylesheet';
          this.fontLink.href = `https://fonts.googleapis.com/css2?family=${fontName.replace(/ /g, '+')}&display=swap`;
          this.renderer.appendChild(document.head, this.fontLink);
        }
        this.checkLoading();
      }, this.previewMode ? 500 : 0);
    }
  }

  private removeFont() {
    if (this.fontLink) {
      this.renderer.removeChild(document.head, this.fontLink);
    }
  }

  async readSvg() {
    try {
      const backgroundColor = this.websiteThemeColor?.background;
      const lineColor = this.websiteThemeColor?.backgroundLine;

      this.backgroundUrl = {
        top: '',
        bottom: '',
        full: '',
      }

      if (this.websiteTheme?.customTheme?.url) {
        this.backgroundUrl['full'] = this.websiteTheme.customTheme?.url;
        // const result = await this.http.get(this.websiteTheme.customTheme.url, { responseType: 'text' }).toPromise();
        // this.ngZone.run(() => {
        //   this.backgroundUrl['full'] = 'data:image/svg+xml;base64,' + window.btoa(encodeURIComponent(this.functionService.cloneDeep(result)).replace(/%([0-9A-F]{2})/g, function(match, p1) {
        //     return String.fromCharCode(parseInt(p1, 16))
        //   }));
        //   console.log(this.backgroundUrl);
        //   this.cdr.detectChanges();
        // });
      } else if (this.websiteDetail?.theme?.customTheme?.url) {
        this.backgroundUrl['full'] = this.websiteDetail.theme.customTheme.url;
        // const result = await this.http.get(this.websiteDetail.theme.customTheme.url, { responseType: 'text' }).toPromise();
        // this.ngZone.run(() => {
        //   this.backgroundUrl['full'] = 'data:image/svg+xml;base64,' + window.btoa(encodeURIComponent(this.functionService.cloneDeep(result)).replace(/%([0-9A-F]{2})/g, function(match, p1) {
        //     return String.fromCharCode(parseInt(p1, 16))
        //   }));
        //   this.cdr.detectChanges();
        // });
      } else if (this.websiteThemeBackground?.background?.length) {
        for (const x of this.websiteThemeBackground.background) {
          if (x?.url && x?.position && ((this.websiteTheme?.backgroundSize === 'fixed' && (x.position === 'top' || x.position === 'bottom')) || (this.websiteTheme?.backgroundSize !== 'fixed' && x.position === 'full'))) {
            const result = await this.http.get(x.url, { responseType: 'text' }).toPromise();
            if (result) {
              const data = this.functionService.replaceAll(this.functionService.replaceAll(result, 'backgroundColor', backgroundColor), 'lineColor', lineColor);
              this.ngZone.run(() => {
                this.backgroundUrl[x.position] = 'data:image/svg+xml;base64,' + window.btoa(encodeURIComponent(this.functionService.cloneDeep(data)).replace(/%([0-9A-F]{2})/g, function(match, p1) {
                  return String.fromCharCode(parseInt(p1, 16))
                }));
                this.cdr.detectChanges();
              });
            } else {
            }
          }
        }
      }

      this.frameBottomHeightCount = 0;
      this.frameTopHeightCount = 0;

      this.setupFrameTopHeight();
      this.setupFrameBottomHeight();
      
    } catch (error) {
      console.error('Unable to read file', error);

      this.frameBottomHeightCount = 0;
      this.frameTopHeightCount = 0;

      this.setupFrameTopHeight();
      this.setupFrameBottomHeight();
    }


  }

  toggleRsvpMode() {
    if (this.websiteType === 'rsvp') {
      this.websiteType = 'home';
    } else {
      this.websiteType = 'rsvp';
    }
    this.setupWebsiteType(this.websiteType);
  }

  toggleQrcodeMode() {
    if (this.websiteType === 'qrcode') {
      this.websiteType = 'home';
    } else {
      this.websiteType = 'qrcode';
    }
    this.setupWebsiteType(this.websiteType);
  }

  toggleWebCheckinMode() {
    if (this.websiteType === 'checkin') {
      this.websiteType = 'home';
    } else {
      this.websiteType = 'checkin';
    }
    this.setupWebsiteType(this.websiteType);
  }

  async openMenu() {
    if (this.designMode) {
      await this.menuController.enable(true, 'design-website-menu');
      const result = await this.menuController.open('design-website-menu');
    } else if (this.previewMode) {
      await this.menuController.enable(true, 'preview-website-menu');
      const result = await this.menuController.open('preview-website-menu');
    } else {
      await this.menuController.enable(true, 'website-menu');
      const result = await this.menuController.open('website-menu');
    }
  }

  closeMenu() {
    if (this.designMode) {
      this.menuController.close('design-website-menu');
    } else if (this.previewMode) {
      this.menuController.close('preview-website-menu');
    } else {
      this.menuController.close('website-menu');
    }
  }

  setType(websiteType: WebsiteType) {
    this.websiteType = websiteType;
    this.setupWebsiteType(this.websiteType);
    this.closeMenu();

    this.frameBottomHeightCount = 0;
    this.frameTopHeightCount = 0;
    this.contentHeightCount = 0;

    this.setupContentHeight();
    this.setupFrameTopHeight();
    this.setupFrameBottomHeight();
  }

  setGuest(guest: Guest) {
    this.guest = guest;
    this.guestId = guest?.guestId;
    this.watchRtdb();
    // this.router.navigate(['/website'], { queryParams: { guestId: this.guestId } });
  }

  setGroup(group: Group) {
    this.group = group;
    this.groupId = group?.groupId;
    this.watchRtdb();
    // this.router.navigate(['/website'], { queryParams: { groupId: this.groupId } });
  }

  setMemberList(memberList: Guest[]) {
    this.memberList = memberList;
  }

  // goQrCode(url: string) {
  //   if (this.previewMode) {
  //     this.websiteType = 'qrcode';
  //     this.setupWebsiteType(this.websiteType);
  //   } else {
  //     if (url) {
  //       window.history.replaceState(null, '', url);
  //       this.setupWebsiteType('qrcode');
  //       // this.router.navigate([url]);
  //     }
  //   }
  // }

  openWebsite() {
    this.linkService.goWebsite();
  }

  opacityToHex(opacity: number): string {
    if (this.functionService.isUndefined(opacity)) {
      // opacity = 0;
      return '';
    } else {
      opacity = opacity / 100;
    }
    // Ensure the opacity is within the valid range
    if (opacity < 0 || opacity > 1) {
      return '';
    }
  
    // Convert opacity to a number between 0 and 255
    const alpha = Math.round(opacity * 255);
  
    // Convert the number to a hexadecimal string
    const hex = alpha.toString(16);
  
    // Pad with leading zero if necessary to ensure two characters
    return hex.padStart(2, '0').toUpperCase();
  }

  setupCountDown() {
    if (this.accountInfo?.time?.seconds) {
      // + (this.websiteDetail?.form?.cutOff?.time * 60)) * 1000
      let date = (this.accountInfo.time.seconds - (getTime(new Date()) / 1000));
      if (date > 0) {
        this.days = Math.floor(date / 86400);
        date -= this.days * 86400;

        this.hours = Math.floor(date / 3600) % 24;
        date -= this.hours * 3600;

        this.minutes = Math.floor(date / 60) % 60;
        date -= this.minutes * 60;
      } else {
        this.days = 0;
        this.hours = 0;
        this.minutes = 0;
      }
    } else {
      this.days = 0;
      this.hours = 0;
      this.minutes = 0;
    }
  }

  async counter() {
    await this.stopTimeout();
    this.timeout = setTimeout(() => {
      this.setupCountDown();
      if (this.counterFlag) {
        this.counter();
      }
    }, 1000);
  }

  async stopTimeout() {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
  }

  async checkLandscapeMode() {
    await this.functionService.delay(300);
    if (this.coverPhotoLandscape?.nativeElement) {
      const style = window.getComputedStyle(this.coverPhotoLandscape.nativeElement);
      if (style.display === 'none') {
        this.landscapeMode = false;
      } else {
        this.landscapeMode = true;
      }
    }
  }

}
