import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BlastCredit } from 'src/app/interfaces/blast';
import { PaymentRef, RefundTransaction, Transaction } from 'src/app/interfaces/transaction';
import { AccountInfoService } from 'src/app/services/account/account-info.service';
import { AccountUserService } from 'src/app/services/account/account-user.service';
import { FunctionService } from 'src/app/services/general/function.service';
import { LocalityService } from 'src/app/services/location/locality.service';
import { TransactionService } from 'src/app/services/payment/transaction.service';
import { ReceiptService } from 'src/app/services/pdf/receipt.service';
import { BlastPricingService } from 'src/app/services/blast/blast-pricing.service';
import { BlastType } from 'src/app/types/blast';
import { DateTimeService } from 'src/app/services/general/date-time.service';
import { RefundService } from 'src/app/services/payment/refund.service';
import { UserService } from 'src/app/services/user/user.service';
import { PopupService } from 'src/app/services/general/popup.service';
import { AccountUser } from 'src/app/interfaces/account';

@Component({
  selector: 'app-blast-credit-detail',
  templateUrl: './blast-credit-detail.component.html',
  styleUrls: ['./blast-credit-detail.component.scss'],
})
export class BlastCreditDetailComponent  implements OnInit, OnDestroy {
 
  blastCredit: BlastCredit;

  transaction: Transaction;

  refundTransaction: RefundTransaction;

  countryCode: string;

  countryName: string;

  currentUid: string;

  userNameList: { [uid: string]: string };

  smsCount: number;
  whatsappCount: number;
  
  constructor(
    private modalController: ModalController,
    private localityService: LocalityService,
    private accountInfoService: AccountInfoService,
    private accountUserService: AccountUserService,
    private transactionService: TransactionService,
    private refundService: RefundService,
    private blastPricingService: BlastPricingService,
    private userService: UserService,
    private dateTimeService: DateTimeService,
    private receiptService: ReceiptService,
    private functionService: FunctionService,
    private popupService: PopupService,
    
  ) { }

  ngOnInit() {}

  ngOnDestroy(): void {
      
  }

  ionViewWillEnter() {
    this.initial();
  }

  ionViewWillLeave() {
  }

  async initial() {
    await this.popupService.presentLoading();
    if (this.blastCredit?.transactionId) {
      this.transaction = this.getTransaction(this.blastCredit.transactionId);
    }
    if (this.blastCredit?.refundId && this.blastCredit?.type === 'refund') {
      this.refundTransaction = this.refundService.getRefundById(this.blastCredit.refundId);
      if (!this.refundTransaction?.refundId) {
        this.refundTransaction = await this.refundService.getRefundByIdCall(this.blastCredit.refundId);
      }
    }

    this.countryName = this.getCountryName();
    this.smsCount = this.getBlastCount('sms', this.blastCredit.blastCredit);
    this.whatsappCount = this.getBlastCount('whatsapp', this.blastCredit.blastCredit);
    
    this.currentUid = this.getUid();
    this.setupUserNameList();
    this.popupService.dismissLoading();
  }

  setupUserNameList() {
    if (!this.userNameList) {
      this.userNameList = {};
    }
    this.accountUserService.accountUserList?.forEach((x: AccountUser) => {
      if (x?.uid && x?.name) {
        this.userNameList[x.uid] = x.name;
      }
    });
  }

  async dismissModal() {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { await modal.dismiss(); }
    }
  }

  getBlastCount(blastType: BlastType, totalBlastCredit: number, country?: string) {
    return this.blastPricingService.getBlastCount(blastType, totalBlastCredit, country);
  }

  getCountryCode(): string {
    let country: string = '';
    if (!country && this.accountInfoService.accountInfo?.location?.locality?.country) {
      country = this.accountInfoService.accountInfo.location.locality.country;
    }
    return country;
  }

  getCountryName(): string {
    if (!this.countryCode) {
      this.countryCode = this.getCountryCode();
    }
    const country = this.localityService.getCountryByCode(this.countryCode)?.name;
    if (country) {
      return ' (' + country + ')';
    }
    return '';
  }

  getTransaction(transactionId: string) {
    return this.transactionService.getTransactionById(transactionId);
  }

  getFpxDetail(fpx: string): string {
    return this.functionService.replaceAll(fpx, '_', ' ')?.toUpperCase();
  }

  /**
   * Get user name by UID
   * @param uid UID
   */
  getUserNameByUid(uid: string): string {
    if (uid) {
      return this.accountUserService.getUserByUid(uid)?.name;
    } else {
      return null;
    }
  }

  getPaymentMethod(paymentRef: PaymentRef): string {
    return this.transactionService.getPaymentMethod(paymentRef);
  }

  getUid() {
    return this.userService.uid;
  }

  format(timestamp: number, dateTimeFormat?: string, timezone?: string) {
    return this.dateTimeService.format(timestamp, dateTimeFormat, '', timezone);
  }

  presentUserReceiptModal(transactionId: string) {
    const transaction = this.transactionService.getTransactionById(transactionId);
    this.receiptService.presentUserReceiptModal(transaction);
  }

}
