import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { BlastSampleUrl } from 'src/app/commons/blastTemplate';
import { GuestListComponent } from 'src/app/components/guest/guest-list/guest-list.component';
import { BlastMsg } from 'src/app/interfaces/blast';
import { SettingField } from 'src/app/interfaces/database';
import { Group } from 'src/app/interfaces/group';
import { Guest } from 'src/app/interfaces/guest';
import { BlastUsage } from 'src/app/interfaces/blast';
import { AccountUserService } from 'src/app/services/account/account-user.service';
import { PrivilegeService } from 'src/app/services/account/privilege/privilege.service';
import { BlastMsgService } from 'src/app/services/blast/blast-msg.service';
import { BlastUsageService } from 'src/app/services/blast/blast-usage.service';
import { PopupService } from 'src/app/services/general/popup.service';
import { GroupService } from 'src/app/services/group/group.service';
import { GuestListService } from 'src/app/services/guest/guest-list.service';
import { GuestService } from 'src/app/services/guest/guest.service';
import { LocalityService } from 'src/app/services/location/locality.service';
import { BlastTemplateManageComponent } from 'src/app/components/blast/template/blast-template-manage/blast-template-manage.component';
import { BlastType } from 'src/app/types/blast';
import { DateTimeService } from 'src/app/services/general/date-time.service';
import { BlastStatusService } from 'src/app/services/blast/blast-status.service';
import { GuestFilterType, GuestListMode } from 'src/app/types/guest';
import { Subscription } from 'rxjs';
import { UpdateByService } from 'src/app/services/user/update-by.service';
import { channel } from 'diagnostics_channel';
import { method } from 'lodash';
import { ShareRecord } from 'src/app/interfaces/share';
import { UpdateBy } from 'src/app/interfaces/user';
import { GuestManageService } from 'src/app/services/guest/guest-manage.service';
import { GroupManageService } from 'src/app/services/group/group-manage.service';
import { FunctionService } from 'src/app/services/general/function.service';

@Component({
  selector: 'app-blast-usage-detail',
  templateUrl: './blast-usage-detail.component.html',
  styleUrls: ['./blast-usage-detail.component.scss'],
})
export class BlastUsageDetailComponent  implements OnInit, OnDestroy {

  @Input() pageMode: boolean;
  @Input() blastUsage: BlastUsage;
  @Input() blastMsgList: BlastMsg[];

  @Input() disableWatch: boolean;
  
  @Input() set setReady(ready: boolean) {
    if (ready) {
      this.initial();
    } else {
      this.unwatch();
    }
  }
  ready: boolean;
  

  phoneCode: number;
  sampleUrl: string = BlastSampleUrl;

  guestIdList: string[];
  groupIdList: string[];

  guestList: Guest[];
  groupList: Group[];

  
  totalBlastCount: number;

  estDeliveryTime: {
    min: number;
    max: number;
  };

  sentStatus: boolean;
  blastStatus: string;

  username: string;

  private blastUsageSubscription: Subscription;

  private blastMsgSubscription: Subscription;

  constructor(
    private modalController: ModalController,
    private translate: TranslateService,
    private accountUserService: AccountUserService,
    private privilegeService: PrivilegeService,
    private guestService: GuestService,
    private guestListService: GuestListService,
    private groupService: GroupService,
    private blastMsgService: BlastMsgService,
    private blastUsageService: BlastUsageService,
    private blastStatusService: BlastStatusService,
    private dateTimeService: DateTimeService,
    private localityService: LocalityService,
    private popupService: PopupService,
  
  ) { }

  ngOnInit() {}

  ngOnDestroy(): void {
      this.ready = false;
  }

  ionViewWillEnter() {
    this.initial();
  }

  ionViewWillLeave() {
    this.unwatch();
  }

  ionViewDidLeave() {
    this.ready = false;
  }

  watch() {
    this.watchBlastUsage();
    this.watchBlastMsg();
  }

  unwatch() {
    this.unwatchBlastUsage();
    this.unwatchBlastMsg();
  }

  watchBlastUsage() {
    if (this.blastUsage?.usageId && !this.blastUsageSubscription) {
      this.blastUsageSubscription = this.blastUsageService.observableBlastUsage.subscribe(() => {
        this.setupBlastUsage();
        this.setupStatus();
      });
    }
  }

  unwatchBlastUsage() {
    if (this.blastUsageSubscription) {
      this.blastUsageSubscription.unsubscribe();
      this.blastUsageSubscription = null;
    }
  }

  watchBlastMsg() {
    if (this.blastUsage?.usageId && !this.blastMsgSubscription) {
      this.blastMsgSubscription = this.blastMsgService.observableBlastMsgList.subscribe(() => {
        this.getBlastMsgList();
        this.setupStatus();
      });
    }
  }

  unwatchBlastMsg() {
    if (this.blastMsgSubscription) {
      this.blastMsgSubscription.unsubscribe();
      this.blastMsgSubscription = null;
    }
  }

  initial() {
    const country = this.localityService.getAccountCountry();
    if (country?.code === 'MY') {
      this.phoneCode = 60;
    } else {
      this.phoneCode = 0;
    }
    if (!this.disableWatch) {
      this.watch();
    }
    
    this.getBlastMsgList();
    this.getTotalBlastCount();
    this.setupStatus();
    this.estDeliveryTime = this.estimateDeliveryTime();
    this.ready = true;
  }

  setupBlastUsage() {
    this.blastUsage = this.blastUsageService.getBlastUsage(this.blastUsage?.usageId);
    this.username = this.getUserName(this.blastUsage?.createBy?.uid);
  }

  setupStatus() {
    this.sentStatus = this.checkSentStatus();
    this.blastStatus = this.getBlastStatus();
  }

  getBlastMsgList() {
    if (this.blastUsage?.usageId) {
      if (!this.blastMsgList?.length) {
        this.blastMsgList = this.blastMsgService.getBlastMsgListByUsageId(this.blastUsage?.usageId);
      }

      const { guestIdList, groupIdList } = this.blastMsgService.getGuestGroupList(this.blastMsgList);
      this.guestIdList = guestIdList;
      this.groupIdList = groupIdList;
  
      this.guestList = this.guestService.getGuestListById(this.guestIdList);
      this.groupList = this.groupService.getGroupListById(this.groupIdList);
    }
  }

  getTotalBlastCount() {
    this.totalBlastCount = this.blastUsage?.usageDetail?.reduce((a, b) => {
      return a + b.qty;
    }, 0);
    return this.totalBlastCount;
  }

  getUserName(uid: string) {
    if (uid) {
      return this.accountUserService.getUserByUid(uid)?.name;
    } else {
      return null;
    }
  }

  getBlastStatus(): string {
    return this.blastStatusService.getBlastMsgStatusTextByUsageId(this.blastUsage?.usageId);
  }

  estimateDeliveryTime() {
    return this.blastUsageService.estimateDeliveryTime(this.blastUsageService.calculateTotalBlast(this.blastUsage?.usageDetail));
  }

  checkSentStatus() {
    if (this.blastUsage?.completed?.status === 'sent') {
      return true;
    } else {
      return this.blastStatusService.checkUsageIdSentStatus(this.blastUsage?.usageId, this.blastMsgList);
    }
  }

  checkPrivilege(action: string): boolean {
    return this.privilegeService.checkCurrentUserPrivilege('blast', action);
  }

  format(timestamp: number, dateTimeFormat?: string, timezone?: string) {
    return this.dateTimeService.format(timestamp, dateTimeFormat, '', timezone);
  }

  /**
   * Dismiss modal
   */
  async dismissModal(dismiss?: boolean) {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { modal.dismiss({ dismiss }); }
    }
  }

  // Need to generate exact guest and group list depends on selected
  async presentGuestListModal() {
    if (this.guestIdList?.length || this.groupIdList?.length) {
      let mode: GuestListMode = 'preview';
      if (this.blastUsage?.blastType === 'sms') {
        mode = 'sms-result';
      } else if (this.blastUsage?.blastType === 'whatsapp') {
        mode = 'whatsapp-result';
      }
      const type: GuestFilterType = {
        blastDeliveredType: 'all',
        usageId: this.blastUsage?.usageId,
      };
      const previewGuestList = this.guestService.getGuestListById(this.guestIdList);
      const previewGroupList = this.guestService.getGroupListMember(this.groupIdList);
      const list = previewGuestList.concat(previewGroupList).filter((guest: Guest, index: number, guestList: Guest[]) => {
        return index === guestList.findIndex((x: Guest) => (
          x.guestId === guest.guestId
        ));
      });
      const guestList = this.guestListService.generateGroupList(list);
      const modal = await this.modalController.create({
        component: GuestListComponent,
        cssClass: '',
        componentProps: {
          previewGuestList: guestList,
          guestList,
          type,
          mode,
          editMode: false,
          showGuestSeating: false,
          disableClick: true,
          blastUsageId: this.blastUsage?.usageId,
          count: this.guestIdList?.length + this.groupIdList?.length,
        }
      });
      modal.present();
    }
  }

  async presentBlastTemplateManageModal(blastType: BlastType, langCode?: string, templateType?: SettingField, templateNo?: string) {
    if (this.checkPrivilege('send')) {
      const modal = await this.modalController.create({
        component: BlastTemplateManageComponent,
        cssClass: 'modal-full-screen-bk',
        componentProps: {
          blastType,
          langCode,
          templateType,
          templateNo,
          selectedGuestIdList: this.guestIdList,
          selectedGroupIdList: this.groupIdList,
        }
      });
      modal.present();
    } else {
      this.popupService.presentAlert(this.translate.instant('ACCOUNT_PRIVILEGE.msg.no_privilege'));
    }
  }

  resend() {
    this.presentBlastTemplateManageModal(this.blastUsage?.blastType, this.blastUsage?.blastTemplate?.language?.value, this.blastUsage?.blastTemplate?.templateType, this.blastUsage?.blastTemplate?.templateNo)
  }

  // async fix() {
  //   const by: UpdateBy = {
  //     uid: 'GlJIAkBmDtScNMabEg7A3D7V6MF2',
  //     uuid: 'c476ec70-030c-46d5-a018-69d766fd10be',
  //     time: this.functionService.firestoreServerTime,
  //   };

  //   const guestIdList: string[] = [];
  //   const groupIdList: string[] = [];

  //   this.blastMsgList.forEach((x: BlastMsg) => {
  //     if (x?.guestId) {
  //       if (guestIdList?.indexOf(x.guestId) === -1) {
  //         guestIdList.push(x.guestId);
  //       }
  //     }
  //     if (x?.groupId) {
  //       if (groupIdList?.indexOf(x.groupId) === -1) {
  //         groupIdList.push(x.groupId);
  //       }
  //     }
  //   });
  //   const share: ShareRecord = {
  //     channel: 'whatsapp',
  //     method: 'qrcode',
  //     type: 'guest',
  //     by
  //   };
  //   const data: any = {
  //     share,
  //     status: { qrcode: true },
  //     shareBy: by
  //   };
  //   // console.log(data);
  //   // console.log(guestIdList);
  //   // console.log(groupIdList);
  //   // await this.guestManageService.saveGuest(data, guestIdList, 'invite');
  //   // await this.groupManageService.saveGroupList(groupIdList, data);

  //   const memberList = this.groupService.getGroupListMember(groupIdList);
  //   console.log(memberList);
  //   await this.guestManageService.saveGuest(data, memberList, 'invite');

  //   // if (this.guest?.guestId) {
  //   //   await this.guestManageService.saveGuest(data, [ this.guest.guestId ], 'invite', [ this.guest ]);
  //   //   this.guest.status.qrcode = data.status.qrcode;
  //   //   this.guest.share = data.share;
  //   //   this.guest.shareBy = data.shareBy;
  //   // } else if (this.group?.groupId) {
  //   //   const group = { ...this.group };
  //   //   await this.guestManageService.saveGuest(data, group?.memberList, 'invite', [], true);
  //   //   await this.groupManageService.saveGroupList([ group.groupId ], data);
  //   //   this.group.status.qrcode = data.status.qrcode;
  //   //   this.group.share = data.share;
  //   //   this.group.shareBy = data.shareBy;
  //   // }
  // }

}
