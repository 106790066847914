import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AccountInfoService } from '../account/account-info.service';
import { FunctionService } from '../general/function.service';
import { TemplateVariableEvent, TemplateVariableWedding } from 'src/app/commons/template';
import { TemplateVariableType } from 'src/app/types/template';

import { BlastSampleUrl } from 'src/app/commons/blastTemplate';
import { DateTimeService } from '../general/date-time.service';
import { SeatingSettingService } from '../seating/seating-setting.service';
import { VisitorLocationService } from '../visitor/visitor-location.service';
import { BlastType } from 'src/app/types/blast';
import { BlastWhatsappService } from './blast-whatsapp.service';
import { AccountEventModeService } from '../account/account-event-mode.service';

@Injectable({
  providedIn: 'root'
})
export class BlastVariableService implements OnInit, OnDestroy {

  constructor(
    private translate: TranslateService,
    private blastWhatsappService: BlastWhatsappService,
    private accountInfoService: AccountInfoService,
    private accountEventModeService: AccountEventModeService,
    private seatingSettingService: SeatingSettingService,
    private visitorLocationService: VisitorLocationService,
    private dateTimeService: DateTimeService,
    private functionService: FunctionService,
  ) { }

  ngOnInit(): void {
      
  }

  ngOnDestroy(): void {
      
  }

  getTemplateVariableList() {
    return this.accountEventModeService.eventMode ? TemplateVariableEvent : TemplateVariableWedding;
  }

  getHtml(msg: string, title?: boolean, blastType?: BlastType, langCode?: string, skipSpan?: boolean): string {
    const eventMode = this.accountEventModeService.eventMode;
    const variableList = this.getTemplateVariableList();
    variableList?.forEach((variable: TemplateVariableType) => {
      const field = '[' + variable.toUpperCase() + ']';
      let value = '';
      if (variable === 'wedding_title') {
        value = this.accountInfoService.accountTitle;
      } else if (variable === 'wedding_date') {
        if (this.accountInfoService.accountInfo?.time?.seconds) {
          value = this.dateTimeService.format(this.accountInfoService.accountInfo.time.seconds * 1000, '', langCode);
        }
      } else if (variable === 'wedding_start_time') {
        if (this.accountInfoService.accountInfo?.time?.seconds) {
          value = this.dateTimeService.format(this.accountInfoService.accountInfo.time.seconds * 1000, '', langCode);
        }
      } else if (variable === 'wedding_timezone') {
        if (this.accountInfoService.accountInfo?.timezone?.utc) {
          value = this.accountInfoService.accountInfo.timezone.utc;
        }
      } else if (variable === 'guest_url') {
        value = BlastSampleUrl;
      } else if (variable === 'seating_type') {
        value = this.seatingSettingService.getSeatingTypeName(langCode);
      } else if (variable === 'wedding_venue_name' || variable === 'wedding_venue_hall' || variable === 'wedding_venue_address') {
        const venue = this.visitorLocationService.getLocation(langCode);
        if (variable === 'wedding_venue_name') {
          value = venue?.name ? venue.name : '';
        }
        if (variable === 'wedding_venue_hall') {
          value = venue?.hall ? venue.hall : '';
        }
        if (variable === 'wedding_venue_address') {
          value = venue?.address ? venue.address : '';
        }
      } else if (variable === 'couple1' || variable ===  'couple2') {
        if (!eventMode) {
          if (variable === 'couple1') {
            value = this.accountInfoService.getCoupleName(1);
          } else if (variable === 'couple2') {
            value = this.accountInfoService.getCoupleName(2);
          }
        }
      } else if (variable === 'organizer') {
        if (eventMode) {
          value = this.accountInfoService.accountInfo?.organizer ? this.accountInfoService.accountInfo.organizer : '';
        }
      } else if (!title) {
        value = '[' + this.replaceEventType(this.translate.instant('TEMPLATE.variable.' + variable.toLowerCase())) + ']';
      }
      if (!value) {
        value = '-';
      }
      if (value && !skipSpan) {
        if (blastType === 'whatsapp') {
          value = this.blastWhatsappService.getWhatsappStyling(variable, value, true);
        }
        value = '<span class="variable">' + value + '</span>';
      }
      msg = this.functionService.replaceAll(msg, field, value);
    });
    return msg;
  }

  getTwilioFormat(msg: string, title?: boolean, blastType?: BlastType, langCode?: string, skipSpan?: boolean): string {
    const eventMode = this.accountEventModeService.eventMode;
    const variableList = this.getTemplateVariableList();
    variableList?.forEach((variable: TemplateVariableType) => {
      const field = '[' + variable.toUpperCase() + ']';
      msg = this.functionService.replaceAll(msg, field, '{{}}');
    });

    let index = -1;
    let counter = 0;
    do {
      index = msg.indexOf('{{}}');
      if (index !== -1) {
        counter++;
        msg = msg.substring(0, index + 2) + counter.toString() + msg.substring(index + 2);
        // msg = [msg.slice(0, index + 2), counter.toString(), msg.slice(index + 2)].join('')
      }
    } while (index !== -1);
    return msg;
  }

  replaceEventType(text: string) {
    return this.accountEventModeService.replaceEventType(text);
  }
}
