import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Group } from 'src/app/interfaces/group';
import { Guest } from 'src/app/interfaces/guest';
import { WebsiteDetail, WebsiteThemeColor } from 'src/app/interfaces/website';

@Component({
  selector: 'app-website-checkin-success',
  templateUrl: './website-checkin-success.component.html',
  styleUrls: ['./website-checkin-success.component.scss'],
})
export class WebsiteCheckinSuccessComponent  implements OnInit, OnDestroy {

  @Input() websiteDetail: WebsiteDetail;
  @Input() websiteThemeColor: WebsiteThemeColor;

  @Input() set setCheckinStatus(checkinStatus: boolean) {
    this.checkinStatus = checkinStatus
  }

  @Input() set setGuest(guest: Guest) {
    this.guest = guest;
    this.setup();
  }
  @Input() set setGroup(group: Group) {
    this.group = group;
    this.setup();
  }
  @Input() set setMemberList(memberList: Guest[]) {
    this.memberList = memberList;
    this.setup();
  }

  private guest: Guest;
  private group: Group;

  memberList: Guest[];

  checkinStatus: boolean;
  name: string;
  seating: string;

  constructor() { }

  ngOnInit() {}

  ngOnDestroy(): void {
      
  }

  setup() {
    if (this.guest?.guestId) {
      this.name = this.guest.name;
      if (!this.checkinStatus) {
        this.checkinStatus = this.guest?.status?.checkin ? true : false;
      }
      
      this.seating = this.guest?.seating;
    } else if (this.group?.groupId) {
      this.name = this.group.groupName;
      if (!this.checkinStatus) {
        this.checkinStatus = this.group?.status?.checkin ? true : false;
      }
      
      this.seating = Array.from(
        new Set(
          this.memberList?.filter((x: Guest) => x?.seating)
            ?.map((x: Guest) => x?.seating)
        )
      ).join(', ');
    }
  }

}
