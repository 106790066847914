import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { WebsiteAlertComponent } from 'src/app/components/website/layout/prompt/alert/alert.component';
import { WebsiteConfirmComponent } from 'src/app/components/website/layout/prompt/confirm/confirm.component';
import { WebsiteThemeColor } from 'src/app/interfaces/website';

@Injectable({
  providedIn: 'root'
})
export class WebsitePopupService {

  constructor(
    private modalController: ModalController,
  ) { }

  /**
     * Present alert modal
     * @param title Title
     * @param msg Message
     * @param subtitle Subtitle
     * @param btn Btn
     * @returns alert modal
     */
    async presentAlert(msg: string, font: string, websiteThemeColor: WebsiteThemeColor, title?: string, subtitle?: string) {
      const modal = await this.modalController.create({
          component: WebsiteAlertComponent,
          cssClass: 'modal-transparent website-popup',
          componentProps: {
            type: 'warning',
            msg,
            title,
            subtitle,
            font,
            websiteThemeColor
          }
      });
      await modal.present();
      return modal;
    }
  
    /**
     * Present confirm modal
     * @param msg display msg
     * @param title display title
     * @param subtitle display subtitle
     * @param btn button name
     * @returns confirm modal
     */
    async presentConfirm(msg: string, font: string, websiteThemeColor: WebsiteThemeColor, title?: string, subtitle?: string) {
      const modal = await this.modalController.create({
        component: WebsiteConfirmComponent,
        cssClass: 'modal-transparent website-popup',
        componentProps: {
          msg,
          title,
          subtitle,
          font,
          websiteThemeColor
        }
      });
      await modal.present();
      return modal;
    }
}
