import { ModuleType } from '../types/general';

/**
 * System module list
 */
export const StdModuleList: ModuleType[] = [
    'account',
    'website',
    'guest',
    'blast',
    'checkin',
    'gift',
    'trash'
];

/*export const list = [
    'account',
    'budget',
    'expense',
    'task',
    'rsvp',
    'guest',
    'checkin',
    'qrcode',
    'gift',
    'trash'
];*/
