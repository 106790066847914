import { LocaleType, Timezone } from 'src/app/interfaces/database';
import { IonDatetime, ModalController, Platform } from '@ionic/angular';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { addYears, set, subYears } from 'date-fns';
import { TimezoneService } from 'src/app/services/general/timezone.service';
import { LanguageService } from 'src/app/services/general/language.service';
import { FunctionService } from 'src/app/services/general/function.service';
import { DateTimeService } from 'src/app/services/general/date-time.service';
import { PopupService } from 'src/app/services/general/popup.service';
import { DateTimeFormatComponent } from '../date-time-format/date-time-format.component';
import { LocaleService } from 'src/app/services/general/locale.service';
import { SearchSelectListComponent } from '../search-select-list/search-select-list.component';
import { LocaleDateFormatList, LocaleList, LocaleTimeFormatList } from 'src/app/commons/locale';
import { TranslateService } from '@ngx-translate/core';
import { LocalizationService } from 'src/app/services/general/localization.service';

@Component({
  selector: 'app-datetime',
  templateUrl: './datetime.component.html',
  styleUrls: ['./datetime.component.scss'],
})
export class DatetimeComponent implements OnInit, OnDestroy {

  @ViewChild(IonDatetime) datetime: IonDatetime;

  min: string;
  max: string;
  time: string;
  timezone: Timezone;
  presentation: string;
  hourCycle: string;
  result: {
    iso: string,
    display: string,
    firestore: any
  };
  ready: boolean;
  localeType: LocaleType;
  locale: string;
  countryCode: string;
  clearBtn: boolean;

  showDateFormat: boolean;
  showTimeFormat: boolean;
  hideLocale: boolean;
  showTimezone: boolean;

  dateFormat: string;
  timeFormat: string;

  formattedDate: string;
  formattedTime: string;

  constructor(
    private translate: TranslateService,
    private modalController: ModalController,
    private dateTimeService: DateTimeService,
    // private languageService: LanguageService,
    private timezoneService: TimezoneService,
    private localeService: LocaleService,
    private localizationService: LocalizationService,
    private popupService: PopupService,
    private functionService: FunctionService,
  ) { }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.ready = false;
  }

  // getLangCode() {
  //   const locale = this.localeService.getLocale();
  //   return locale;
  //   // return this.languageService.getUserLanguage()?.code;
  // }

  ionViewWillEnter() {
    if (!this.locale) {
      this.locale = this.localizationService.getLocale();
    }

    if (!this.localeType) {
      this.localeType = this.localeService.getLocaleType(this.locale);
    }

    if (!this.countryCode) {
      this.countryCode = this.localizationService.getCountryCode();
    }
  }

  async ionViewDidEnter() {
    await this.popupService.presentLoading();
    this.ready = false;
    if (!this.timezone) {
      this.timezone = this.timezoneService.getAccountTimezone();
    }

    if (this.timezone && !this.time) {
      this.time = this.dateTimeService.format(set(this.time ? new Date(this.time) : new Date(), { hours: this.presentation === 'time' ? 19 : 0, minutes: 0, seconds: 0, milliseconds: 0 }),
        'yyyy-MM-dd\'T\'HH:mm:ss.SSSxxx', 'en', this.timezone.name, 'en-US');
    }

    if (!this.min) {
      this.min = this.dateTimeService.formatISO(set(subYears(new Date(), 10), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }));
    }
    if (!this.max) {
      this.max = this.dateTimeService.formatISO(set(addYears(new Date(), 20), { hours: 23, minutes: 59, seconds: 59, milliseconds: 999 }));
    }

    this.setupFormatDateTime();
    // this.setupHourCycle();

    this.ready = true;
    // this.setupDatetime();
    await this.functionService.delay(200);
    this.popupService.dismissLoading();
  }

  setupFormatDateTime() {
    let timestamp: number = 0;

    if (this.datetime?.value?.toString()) {
      timestamp = new Date(this.datetime.value.toString()).getTime();
    }

    if (!timestamp && this.time) {
      timestamp = new Date(this.time).getTime();
    }
  
    this.formattedDate = this.getFormattedDate(timestamp);
    this.formattedTime = this.getFormattedTime(timestamp);
  }

  setupDatetime() {
    if (this.time) {
      if (this.datetime) {
        this.datetime.value = this.time;
      } else {
        setTimeout(() => {
          this.setupDatetime();
        }, 50);
      }
    }
  }

  // setupHourCycle() {
  //   if (this.localizationService.get12HourTimeFormat(this.countryCode)) {
  //     this.hourCycle = 'h12';
  //   } else {
  //     this.hourCycle = 'h23';
  //   }
  // }

  getFormattedDate(time: number) {
    if (time) {
      const dateFormat = this.dateFormat ? this.dateFormat : this.localizationService.getDateFormat();
      const locale = this.locale ? this.locale : this.localizationService.getLocale();
  
      return this.dateTimeService.format(time, dateFormat, '', '', locale);
    }
    return '';
  }

  getFormattedTime(time: number) {
    if (time) {
      const timeFormat = this.timeFormat ? this.timeFormat : this.localizationService.getTimeFormat();
      const locale = this.locale ? this.locale : this.localizationService.getLocale();

      return this.dateTimeService.format(time, timeFormat, '', '', locale);
    }
    return '';
  }

  /**
   * Dismiss Modal
   * @param select Select flag
   */
  async dismissModal(select?: boolean, clear?: boolean) {
    if (this.datetime) {
      if (select) {
        const nativeElement = (this.datetime as any).el;
        if (nativeElement.classList.contains('show-month-and-year')) {
          nativeElement.shadowRoot.querySelector('.calendar-month-year-toggle').click();
          return;
        }
        await this.datetime.confirm();
      } else {
        await this.datetime.cancel();
      }
    }
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) {
        if (select) {
          const timestamp = this.dateTimeService.generatePickerTime(this.datetime?.value?.toString(), this.timezone);
          const result: any = { timestamp, dateTime: this.datetime?.value, locale: this.locale };
          if (this.showDateFormat) {
            result.dateFormat = this.dateFormat;
          } else if (this.showTimeFormat) {
            result.timeFormat = this.timeFormat;
          }
          modal.dismiss(result);
        } else if (clear) {
          modal.dismiss({ timestamp: '', dateTime: '' });
        } else {
          modal.dismiss();
        }
      }
    }
  }

  async presentDatetimeFormatModal(type: string, selected: string, time?: number) {
    if (type) {
      if (!time) {
        if (this.datetime?.value.toString()) {
          time = new Date(this.datetime?.value.toString()).getTime();
        }
    
        if (!time && this.time) {
          time = new Date(this.time).getTime();
        }
      }
      const modal = await this.modalController.create({
        component: DateTimeFormatComponent,
        cssClass: 'modal-transparent',
        componentProps: {
          locale: this.locale,
          time,
          type,
          selected,
        }
      });
      modal.present();
      modal.onWillDismiss().then((result: any) => {
        if (result?.data?.format) {
          if (type === 'date') {
            this.dateFormat = result.data.format;
            this.setupFormatDateTime();
          } else if (type === 'time') {
            this.timeFormat = result.data.format;
            this.setupFormatDateTime();
            // this.setupHourCycle();
          }
        }
      });
    }
  }

  async openLocaleTypeModal() {
    const selected = this.localeType;
    // LocaleList.forEach((x: LocaleType) => {
    //   x.dateFnsLocale = x.locale.replace('-', '');
    //   x.dateFormat = LocaleDateFormatList?.[x.dateFnsLocale];
    //   x.timeFormat = LocaleTimeFormatList?.[x.dateFnsLocale];
    //   x.is24Hr = true;
    // });
    // console.log(LocaleList);
    const modal = await this.modalController.create({
      component: SearchSelectListComponent,
      componentProps: {
        items: LocaleList,
        preferredItems: this.localeService.getPreferredLocaleList(),
        selected,
        title: 'Locale Type',
        itemTextField: 'summary',
        placeholder: this.translate.instant('BTN.search'),
        closeButtonText: this.translate.instant('BTN.cancel'),
      }
    });
    modal.present();
    modal.onWillDismiss().then((result: any) => {
      if (result?.data?.item) {
        if (!this.functionService.isEqual(this.localeType, result.data.item)) {
          this.localeType = result.data.item;
          this.locale = this.localeType.locale;
          // this.dateFormat = this.localeService.getLocaleDateFormat(this.locale);
          // this.timeFormat = this.localeService.getLocaleTimeFormat(this.locale);
          // this.hourCycle = this.localeService.getLocaleIs24Hr(this.locale) ? 'h23' : 'h12';
          this.setupFormatDateTime();
        }
      }
    });
  }

  setupTime(time?: any) {
    if (time) {
      this.time = time;
    }
  }

}
