<ion-header>
  <ion-toolbar color="light">
    <ion-buttons slot="start">
      <ion-button (click)="dismissModal()">
        <ion-icon class="back-icon" src="./assets/wedding/icon/general/chevron-back.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <app-offline-indicator></app-offline-indicator>
      <ng-container *ngIf="type === 'invite'">
        {{ 'QRCODE.lbl.share_log_title' | translate }} ({{ 'GUEST.lbl.guest' | translate }})
      </ng-container>
      <ng-container *ngIf="type !== 'invite'">
        {{ 'GUEST.lbl.guest_log' | translate }}
      </ng-container>
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content color="light">
  <div class="full-width-height viewport-div" *ngIf="logList?.length">
    <cdk-virtual-scroll-viewport [itemSize]="120" [minBufferPx]="200" [maxBufferPx]="200" autosize>
      <ion-list class="full-width-height background-transparent ion-padding-bottom" lines="none" #list>
        <span *cdkVirtualFor="let log of logList; trackBy: trackByFn;">
          <span *ngIf="log?.after && log?.type?.length">
            <span *ngFor="let logType of log.type">
              <ion-card class="card-margin ion-padding" *ngIf="logType && ((type === 'checkin' && logType === type) || (type === 'invite' && logType === type) || (type !== 'checkin' && type !== 'invite'))">
                <ng-container *ngIf="log.by">
                  <ng-container *ngIf="logType === 'checkin'">
                    <ng-container *ngIf="!log?.action?.checkinBy?.name">
                      <ion-item class="ion-item-no-padding" lines="none" *ngIf="log?.by?.time?.seconds">
                        <ion-icon class="icon-small" src="./assets/wedding/icon/general/time-outline.svg"></ion-icon>
                        {{ format(log.by.time.seconds * 1000) }}
                      </ion-item>
                      <ion-item class="ion-item-no-padding" lines="none" *ngIf="log?.by?.uid && !log?.by?.name && getUserName(log.by.uid) as username">
                        <ion-icon class="icon-small" src="./assets/wedding/icon/general/person-outline.svg"></ion-icon>
                        <ng-container>
                          {{ username }}
                        </ng-container>
                      </ion-item>
                    </ng-container>
                    <ng-container *ngIf="log?.action?.checkinBy?.name">
                      <ion-item class="ion-item-no-padding" lines="none" *ngIf="log.action.checkinBy?.time?.seconds">
                        <ion-icon class="icon-small" src="./assets/wedding/icon/general/time-outline.svg"></ion-icon>
                        {{ format(log.action.checkinBy.time.seconds * 1000) }}
                      </ion-item>
                      <ion-item class="ion-item-no-padding" lines="none">
                        <ion-icon class="icon-small" src="./assets/wedding/icon/general/person-outline.svg"></ion-icon>
                        {{ log.action.checkinBy.name }}
                      </ion-item>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="logType === 'gift'">
                    <ng-container *ngIf="!log?.action?.giftBy?.name">
                      <ion-item class="ion-item-no-padding" lines="none" *ngIf="log?.by?.time?.seconds">
                        <ion-icon class="icon-small" src="./assets/wedding/icon/general/time-outline.svg"></ion-icon>
                        {{ format(log.by.time.seconds * 1000) }}
                      </ion-item>
                      <ion-item class="ion-item-no-padding" lines="none" *ngIf="log?.by?.uid && !log?.by?.name && getUserName(log.by.uid) as username">
                        <ion-icon class="icon-small" src="./assets/wedding/icon/general/person-outline.svg"></ion-icon>
                        <ng-container>
                          {{ username }}
                        </ng-container>
                      </ion-item>
                    </ng-container>
                    <ng-container *ngIf="log?.action?.giftBy?.name">
                      <ion-item class="ion-item-no-padding" lines="none" *ngIf="log?.action?.giftBy?.time?.seconds">
                        <ion-icon class="icon-small" src="./assets/wedding/icon/general/time-outline.svg"></ion-icon>
                        {{ format(log.action.giftBy.time.seconds * 1000) }}
                      </ion-item>
                      <ion-item class="ion-item-no-padding" lines="none">
                        <ion-icon class="icon-small" src="./assets/wedding/icon/general/person-outline.svg"></ion-icon>
                        {{ log?.action?.giftBy.name }}
                      </ion-item>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="logType === 'invite'">
                    <ng-container *ngIf="!log?.action?.shareBy?.name">
                      <ion-item class="ion-item-no-padding" lines="none" *ngIf="log?.by?.time?.seconds">
                        <ion-icon class="icon-small" src="./assets/wedding/icon/general/time-outline.svg"></ion-icon>
                        {{ format(log.by.time.seconds * 1000) }}
                      </ion-item>
                      <ion-item class="ion-item-no-padding" lines="none" *ngIf="log?.by?.uid && !log?.by?.name && getUserName(log.by.uid) as username">
                        <ion-icon class="icon-small" src="./assets/wedding/icon/general/person-outline.svg"></ion-icon>
                        <ng-container>
                          {{ username }}
                        </ng-container>
                      </ion-item>
                    </ng-container>
                    <ng-container *ngIf="log?.action?.shareBy?.name">
                      <ion-item class="ion-item-no-padding" lines="none" *ngIf="log.action.shareBy?.time?.seconds">
                        <ion-icon class="icon-small" src="./assets/wedding/icon/general/time-outline.svg"></ion-icon>
                        {{ format(log.action.shareBy.time.seconds * 1000) }}
                      </ion-item>
                      <ion-item class="ion-item-no-padding" lines="none">
                        <ion-icon class="icon-small" src="./assets/wedding/icon/general/person-outline.svg"></ion-icon>
                        {{ log.action.shareBy.name }}
                      </ion-item>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="logType !== 'gift' && logType !== 'checkin' && logType !== 'invite'">
                    <ng-container *ngIf="!log?.by?.name">
                      <ion-item class="ion-item-no-padding" lines="none" *ngIf="log?.by?.time?.seconds">
                        <ion-icon class="icon-small" src="./assets/wedding/icon/general/time-outline.svg"></ion-icon>
                        {{ format(log.by.time.seconds * 1000) }}
                      </ion-item>
                      <ion-item class="ion-item-no-padding" lines="none" *ngIf="log?.by?.uid && !log?.by?.name && getUserName(log.by.uid) as username">
                        <ion-icon class="icon-small" src="./assets/wedding/icon/general/person-outline.svg"></ion-icon>
                        <ng-container>
                          {{ username }}
                        </ng-container>
                      </ion-item>
                    </ng-container>
                    <ng-container *ngIf="log?.by?.name">
                      <ion-item class="ion-item-no-padding" lines="none" *ngIf="log?.by?.time?.seconds">
                        <ion-icon class="icon-small" src="./assets/wedding/icon/general/time-outline.svg"></ion-icon>
                        {{ format(log.by.time.seconds * 1000) }}
                      </ion-item>
                      <ion-item class="ion-item-no-padding" lines="none">
                        <ion-icon class="icon-small" src="./assets/wedding/icon/general/person-outline.svg"></ion-icon>
                        <ng-container *ngIf="log.by.name !== 'import'">
                          {{ log.by.name }}
                        </ng-container>
                        <ng-container *ngIf="log.by.name === 'import'">
                          {{ 'IMPORT.title' | translate }}
                        </ng-container>
                      </ion-item>
                    </ng-container>
                  </ng-container>
                </ng-container>
  
                <ng-container *ngIf="type !== 'checkin' && type !== 'invite'">
                  <ion-item class="ion-item-no-padding" lines="none" *ngIf="logType === 'new'">
                    <ion-icon class="icon-small" src="./assets/wedding/icon/general/star.svg"></ion-icon>
                    <ion-text [innerHTML]="'LOG.msg.new' | translate: { field: 'GUEST.lbl.guest' | translate }"></ion-text>
                  </ion-item>
                  <ion-item class="ion-item-no-padding" lines="none" *ngIf="logType === 'update'">
                    <ion-icon class="icon-small" src="./assets/wedding/icon/general/star.svg"></ion-icon>
                    <ion-text [innerHTML]="'LOG.msg.update' | translate: { field: 'GUEST.lbl.guest' | translate }"></ion-text>
                  </ion-item>
                </ng-container>
                
                <ng-container *ngIf="type !== 'checkin'">
                  <ion-item class="ion-item-no-padding" lines="none" *ngIf="logType === 'invite'">
                    <ion-icon class="icon-small" src="./assets/wedding/icon/general/star.svg"></ion-icon>
                    <ion-text *ngIf="log?.after?.status?.qrcode" [innerHTML]="'LOG.msg.invite' | translate: { field: 'GUEST.lbl.guest' | translate }">
                    </ion-text>
                    <ion-text *ngIf="!log?.after?.status?.qrcode" [innerHTML]="'LOG.msg.delete_invite' | translate: { field: 'GUEST.lbl.guest' | translate }">
                    </ion-text>
                  </ion-item>
                </ng-container>
                
                <ng-container *ngIf="type !== 'checkin' && type !== 'invite'">
                  <ng-container *ngIf="logType === 'gift'">
                    <ion-item class="ion-item-no-padding" lines="none" *ngIf="log?.after?.status?.gift && !log?.before?.status?.gift">
                      <ion-icon class="icon-small" src="./assets/wedding/icon/general/star.svg"></ion-icon>
                      <ion-text [innerHTML]="'LOG.msg.receive_gift_checkin' | translate">
                      </ion-text>
                    </ion-item>
                    <ion-item class="ion-item-no-padding" lines="none" *ngIf="!log?.after?.status?.gift && log?.before?.status?.gift">
                      <ion-icon class="icon-small" src="./assets/wedding/icon/general/star.svg"></ion-icon>
                      <ion-text  [innerHTML]="'LOG.msg.delete_received_gift_checkin' | translate">
                      </ion-text>
                    </ion-item>
                  </ng-container>
                </ng-container>
                
                <ng-container *ngIf="type !== 'invite'">
                  <ion-item class="ion-item-no-padding" lines="none" *ngIf="logType === 'checkin'">
                    <ion-icon class="icon-small" src="./assets/wedding/icon/general/star.svg"></ion-icon>
                    <ion-text *ngIf="log?.after?.status?.checkin" [innerHTML]="'LOG.msg.checkin' | translate: { field: 'GUEST.lbl.guest' | translate }">
                    </ion-text>
                  </ion-item>
                  <ion-item class="ion-item-no-padding" lines="none" *ngIf="logType === 'uncheck'">
                    <ion-icon class="icon-small" src="./assets/wedding/icon/general/star.svg"></ion-icon>
                    <ion-text *ngIf="!log?.after?.status?.checkin" [innerHTML]="'LOG.msg.uncheck' | translate: { field: 'GUEST.lbl.guest' | translate }">
                    </ion-text>
                  </ion-item>
                </ng-container>
                
                <ng-container *ngIf="type !== 'checkin' && type !== 'invite'">
                  <ion-item class="ion-item-no-padding" lines="none" *ngIf="logType === 'delete'">
                    <ion-icon class="icon-small" src="./assets/wedding/icon/general/star.svg"></ion-icon>
                    <ion-text *ngIf="log?.after?.status?.deleted" [innerHTML]="'LOG.msg.remove' | translate: { field: 'GUEST.lbl.guest' | translate }">
                    </ion-text>
                    <ion-text *ngIf="!log?.after?.status?.deleted" [innerHTML]="'LOG.msg.restore' | translate: { field: 'GUEST.lbl.guest' | translate }">
                    </ion-text>
                  </ion-item>
  
                  <ion-item class="ion-item-no-padding" lines="none" *ngIf="logType === 'gift' && ((log?.after.status === 'new' && log?.after) || log?.after.status === 'update' || log?.after.status === 'remove')">
                    <ion-label class="ion-no-margin">
                      <ion-icon class="icon-small" src="./assets/wedding/icon/general/star.svg"></ion-icon>
                      <ion-text *ngIf="log?.after.status === 'new' && log?.after">
                        <ion-text *ngIf="log?.after?.giftType === 'cash'" [innerHTML]="'LOG.field.new_gift' | translate: { type: ('LIST.gift.' + log.after.giftType | translate), value: log?.after?.amount | currency: log?.after?.currency: 'symbol' }">
                        </ion-text>
                        <ion-text *ngIf="log?.after?.giftType === 'other'" [innerHTML]="'LOG.field.new_gift' | translate: { type: ('LIST.gift.' + log.after.giftType | translate), value: log.after?.name + ' * ' + log?.after?.qty }">
                        </ion-text>
                      </ion-text>
                      <ng-container *ngIf="log?.after.status === 'update'">
                        <ion-text [innerHTML]="'LOG.field.update_gift' | translate"></ion-text>
                        <ng-container class="ion-item-no-padding ion-text-wrap" lines="none" *ngIf="log?.before && log?.after">
                          
                          <div class="ion-item-no-padding" lines="none"  *ngIf="log.after.name !== log?.before?.name">
                            <ion-icon class="icon-small" src="./assets/wedding/icon/general/bell.svg"></ion-icon>
                            <ion-text [innerHTML]="'LOG.field.update' | translate: { field: ('GIFT.lbl.name' | translate), value1: log?.before?.name ? log.before.name : '', value2: log.after.name }">
                            </ion-text>
                          </div>
                
                          <div class="ion-item-no-padding" lines="none"  *ngIf="log?.after?.qty !== log?.before?.qty">
                            <ion-icon class="icon-small" src="./assets/wedding/icon/general/bell.svg"></ion-icon>
                            <ion-text [innerHTML]="'LOG.field.update' | translate: { field: ('LBL.qty' | translate), value1: log?.before?.qty ? log.before.qty : '', value2: log.after.qty }">
                            </ion-text>
                          </div>
                
                          <div class="ion-item-no-padding" lines="none"  *ngIf="log.after.currency !== log?.before?.currency">
                            <ion-icon class="icon-small" src="./assets/wedding/icon/general/bell.svg"></ion-icon>
                            <ion-text [innerHTML]="'LOG.field.update' | translate: { field: ('LBL.currency' | translate), value1: log?.before?.currency ? log.before.currency : '', value2: log.after.currency }">
                            </ion-text>
                          </div>
                
                          <div class="ion-item-no-padding" lines="none"  *ngIf="log.after.amount !== log?.before?.amount">
                            <ion-icon class="icon-small" src="./assets/wedding/icon/general/bell.svg"></ion-icon>
                            <ion-text [innerHTML]="'LOG.field.update' | translate: { field: ('LBL.amount' | translate), value1: log?.before?.amount ? log.before.amount : '0', value2: log.after.amount }">
                            </ion-text>
                          </div>
                        </ng-container>
        
                      </ng-container>
                      <ion-text *ngIf="log?.after.status === 'remove'">
                        <ion-text *ngIf="log?.after?.giftType === 'cash'" [innerHTML]="'LOG.field.delete_gift' | translate: { type: ('LIST.gift.' + log?.after.giftType | translate), value: log?.after?.amount | currency: log?.after?.currency: 'symbol' }">
                        </ion-text>
                        <ion-text *ngIf="log?.after?.giftType === 'other'" [innerHTML]="'LOG.field.delete_gift' | translate: { type: ('LIST.gift.' + log?.after.giftType | translate), value: log?.after?.name + ' * ' + log?.after?.qty }">
                        </ion-text>
                      </ion-text>
                    </ion-label>
                  </ion-item>
  
                  <ng-container *ngIf="logType === 'new' || logType === 'update'">
                    <ion-item class="ion-item-no-padding" lines="none" *ngIf="log?.before?.status?.attending !== log?.after?.status?.attending">
                      <ion-icon class="icon-small" src="./assets/wedding/icon/general/bell.svg"></ion-icon>
                      <ion-text *ngIf="logType === 'new' && log?.after?.status?.attending">
                        {{ 'LBL.status' | translate }}: <strong>{{ 'LIST.attending_status.' + log.after.status.attending | translate }}</strong>
                      </ion-text>
                      <ion-text *ngIf="logType === 'update'" [innerHTML]="'LOG.field.update' | translate: { field: ('LBL.status' | translate),
                      value1: log?.before?.status?.attending ? ('LIST.attending_status.' + log.before.status.attending | translate): '',
                      value2: log?.after?.status?.attending ? ('LIST.attending_status.' + log.after.status.attending | translate): '' }">
                      </ion-text>
                    </ion-item>
        
                    <ion-item class="ion-item-no-padding" lines="none" *ngIf="log.after.name || log?.before?.name">
                      <ion-icon class="icon-small" src="./assets/wedding/icon/general/bell.svg"></ion-icon>
                      <ion-text *ngIf="logType === 'new' && log?.after?.name">
                        {{ 'LBL.name' | translate }}: <strong>{{ log.after.name }}</strong>
                      </ion-text>
                      <ion-text *ngIf="logType === 'update'" [innerHTML]="'LOG.field.update' | translate: 
                      { field: ('LBL.name' | translate), value1: log?.before?.name ? log.before.name : '', value2: log?.after?.name ? log.after.name : '' }">
                      </ion-text>
                    </ion-item>
                    
                    <ion-item class="ion-item-no-padding" lines="none" *ngIf="log.after.nickname || log?.before?.nickname">
                      <ion-icon class="icon-small" src="./assets/wedding/icon/general/bell.svg"></ion-icon>
                      <ion-text *ngIf="logType === 'new' && log?.after?.nickname">
                        {{ 'LBL.nickname' | translate }}: <strong>{{ log.after.nickname }}</strong>
                      </ion-text>
                      <ion-text *ngIf="logType === 'update'" [innerHTML]="'LOG.field.update' | translate: 
                      { field: ('LBL.nickname' | translate), value1: log?.before?.nickname ? log.before.nickname : '', value2: log?.after?.nickname ? log.after.nickname : '' }">
                      </ion-text>
                    </ion-item>
          
                    <ion-item class="ion-item-no-padding" lines="none" *ngIf="log.after.invitedBy">
                      <ion-icon class="icon-small" src="./assets/wedding/icon/general/bell.svg"></ion-icon>
                      <ion-text *ngIf="logType === 'new'">
                        {{ 'GUEST.lbl.invited_by' | translate }}: <strong>{{ getSettingField('invited_by', log.after.invitedBy) }}</strong>
                      </ion-text>
                      <ion-text *ngIf="logType === 'update'" [innerHTML]="'LOG.field.update' | translate: { field: ('GUEST.lbl.invited_by' | translate), value1: getSettingField('invited_by', log?.before?.invitedBy), value2: getSettingField('invited_by', log.after.invitedBy) }">
                      </ion-text>
                    </ion-item>
          
                    <ion-item class="ion-item-no-padding" lines="none" *ngIf="log.after.category">
                      <ion-icon class="icon-small" src="./assets/wedding/icon/general/bell.svg"></ion-icon>
                      <ion-text *ngIf="logType === 'new'">
                        {{ 'LBL.category' | translate }}: <strong>{{ getSettingField('category', log.after.category) }}</strong>
                      </ion-text>
                      <ion-text *ngIf="logType === 'update'" [innerHTML]="'LOG.field.update' | translate: { field: ('LBL.category' | translate), value1: getSettingField('category', log?.before?.category), value2: getSettingField('category', log.after.category) }">
                      </ion-text>
                    </ion-item>
          
                    <ion-item class="ion-item-no-padding" lines="none" *ngIf="log.after.groupId || log?.before?.groupId">
                      <ion-icon class="icon-small" src="./assets/wedding/icon/general/bell.svg"></ion-icon>
                      <ion-text *ngIf="logType === 'new' && log?.after?.groupId">
                        {{ 'LIST.group.group' | translate }}: <strong>{{ getGroupName(log.after.groupId) }}</strong>
                      </ion-text>
                      <ng-container *ngIf="logType === 'update'">
                        <ion-text *ngIf="!log?.before?.groupId && log?.after?.groupId" [innerHTML]="'LOG.field.guest_new_group' | translate: { group: getGroupName(log.after.groupId) }">
                        </ion-text>
                        <ion-text *ngIf="log?.before?.groupId && log?.after?.groupId" [innerHTML]="'LOG.field.guest_change_group' | translate: { group1: getGroupName(log.before.groupId), group2: getGroupName(log.after.groupId) }">
                        </ion-text>
                        <ion-text *ngIf="!log?.after?.groupId && log?.before?.groupId" [innerHTML]="'LOG.field.guest_remove_group' | translate: { group: getGroupName(log.before.groupId) }">
                        </ion-text>
                      </ng-container>
                    </ion-item>
          
                    <ion-item class="ion-item-no-padding" lines="none" *ngIf="log.after.seating || log?.before?.seating">
                      <ion-icon class="icon-small" src="./assets/wedding/icon/general/bell.svg"></ion-icon>
                      <ion-text *ngIf="logType === 'new' && log?.after?.seating">
                        {{ 'MODULE.action.guest.seating' | translate }}: <strong>{{ log.after.seating }}</strong>
                      </ion-text>
                      <ion-text *ngIf="logType === 'update'" [innerHTML]="'LOG.field.update' | translate: { field: ('MODULE.action.guest.seating' | translate),
                      value1: log?.before?.seating ? log.before.seating : 'LIST.assign_status.not_yet_assign' | translate,
                      value2: log.after.seating ? log.after.seating : 'LIST.assign_status.not_yet_assign' | translate }">
                      </ion-text>
                    </ion-item>
          
                    <ion-item class="ion-item-no-padding" lines="none" *ngIf="log.after.dietaryReq">
                      <ion-icon class="icon-small" src="./assets/wedding/icon/general/bell.svg"></ion-icon>
                      <ion-text *ngIf="logType === 'new'">
                        {{ 'GUEST.lbl.dietary_req' | translate }}: <strong>{{ getSettingField('dietary_req', log.after.dietaryReq) }}</strong>
                      </ion-text>
                      <ion-text *ngIf="logType === 'update'" [innerHTML]="'LOG.field.update' | translate: { field: ('GUEST.lbl.dietary_req' | translate), value1: getSettingField('dietary_req', log?.before?.dietaryReq), value2: getSettingField('dietary_req', log.after.dietaryReq) }">
                      </ion-text>
                    </ion-item>
          
                    <ion-item class="ion-item-no-padding" lines="none" *ngIf="log.after.specialReq">
                      <ion-icon class="icon-small" src="./assets/wedding/icon/general/bell.svg"></ion-icon>
                      <ion-text *ngIf="logType === 'new'">
                        {{ 'GUEST.lbl.special_req' | translate }}: <strong>{{ getSettingField('special_req', log.after.specialReq) }}</strong>
                      </ion-text>
                      <ion-text *ngIf="logType === 'update'" [innerHTML]="'LOG.field.update' | translate: { field: ('GUEST.lbl.special_req' | translate), value1: getSettingField('special_req', log?.before?.specialReq), value2: getSettingField('special_req', log.after.specialReq) }">
                      </ion-text>
                    </ion-item>
          
                    <ion-item class="ion-item-no-padding" lines="none" *ngIf="log.after.email || log?.before?.email">
                      <ion-icon class="icon-small" src="./assets/wedding/icon/general/bell.svg"></ion-icon>
                      <ion-text *ngIf="logType === 'new' && log?.after?.email">
                        {{ 'LBL.email' | translate }}: <strong>{{ log.after.email }}</strong>
                      </ion-text>
                      <ion-text *ngIf="logType === 'update'" [innerHTML]="'LOG.field.update' | translate: 
                      { field: ('LBL.email' | translate), value1: log?.before?.email ? log.before.email : '', value2: log?.after?.email ? log.after.email : '' }">
                      </ion-text>
                    </ion-item>
          
                    <ion-item class="ion-item-no-padding" lines="none" *ngIf="log.after.mobile?.no || log?.before?.mobile?.no">
                      <ion-icon class="icon-small" src="./assets/wedding/icon/general/bell.svg"></ion-icon>
                      <ion-text *ngIf="logType === 'new' && log?.after?.email">
                        {{ 'MOBILE.lbl.no' | translate }}: <strong>{{ log.after.mobile?.no }}</strong>
                      </ion-text>
                      <ion-text *ngIf="logType === 'update'" [innerHTML]="'LOG.field.update' | translate: { field: ('MOBILE.lbl.no' | translate), value1: log?.before?.mobile?.no ? log.before.mobile.no : '', value2: log.after?.mobile?.no }">
                      </ion-text>
                    </ion-item>
          
                    <ion-item class="ion-item-no-padding" lines="none" *ngIf="log.after.remark || log?.before?.remark">
                      <ion-icon class="icon-small" src="./assets/wedding/icon/general/bell.svg"></ion-icon>
                      <ion-text *ngIf="logType === 'new' && log?.after?.remark">
                        {{ 'GUEST.lbl.remark' | translate }}: <strong>{{ log.after.remark }}</strong>
                      </ion-text>
                      <ion-text *ngIf="logType === 'update'" [innerHTML]="'LOG.field.update' | translate: 
                      { field: ('GUEST.lbl.remark' | translate), value1: log?.before?.remark ? log.before.remark : '', value2: log?.after?.remark ? log.after.remark : '' }">
                      </ion-text>
                    </ion-item>
                  </ng-container>
                </ng-container>
              </ion-card>
            </span>
          </span>
        </span>
      </ion-list>
    </cdk-virtual-scroll-viewport>
  </div>


  <div class="full-width-height vertical-center ion-padding center" *ngIf="!logList?.length">
    <div class="center">
      <ion-label class="ion-text-center ion-margin ion-padding" color="medium">
        <ion-icon class="center icon-cactus" src="./assets/wedding/icon/general/no-record.svg"></ion-icon>
        <div class="ion-margin-top font-14">{{ 'LBL.no_found' | translate }}</div>
      </ion-label>
    </div>
  </div>
</ion-content>