import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';

import { AccountUserService } from 'src/app/services/account/account-user.service';
import { SettingFieldService } from 'src/app/services/setting/setting-field.service';
import { GuestLogService } from 'src/app/services/guest/guest-log.service';
import { GroupService } from 'src/app/services/group/group.service';
import { GroupLogService } from 'src/app/services/group/group-log.service';
import { GiftService } from 'src/app/services/gift/gift.service';
import { FunctionService } from 'src/app/services/general/function.service';
import { PopupService } from 'src/app/services/general/popup.service';

import { Gift } from 'src/app/interfaces/gift';
import { Log } from 'src/app/interfaces/log';
import { SettingField } from 'src/app/interfaces/database';
import { SettingFieldType } from 'src/app/types/general';
import { LogType } from 'src/app/types/log';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { DateTimeService } from 'src/app/services/general/date-time.service';

/**
 * Guest log component
 */
@Component({
  selector: 'app-guest-log',
  templateUrl: './guest-log.component.html',
  styleUrls: ['./guest-log.component.scss'],
})
export class GuestLogComponent implements OnInit, OnDestroy {

  @ViewChild(CdkVirtualScrollViewport) cdkVirtualScrollViewport: CdkVirtualScrollViewport;
  /**
   * Guest log type
   */
  type: LogType;
  /**
   * Guest log list
   */
  logList: Log[];
  /**
   * Guest ID
   */
  guestId: string;
  /**
   * Group ID
   */
  groupId: string;
  /**
   * Guest log subscription
   */
  private guestLogSubscription: Subscription;

  /**
   * Constructor
   * @param modalController modal controller
   * @param settingFieldService setting field service
   * @param accountUserService account user service
   * @param guestLogService guest log service
   * @param groupLogService group log service
   * @param groupService group service
   * @param giftService gift service
   * @param popupService popup service
   * @param functionService function service
   */
  constructor(
    private modalController: ModalController,
    private settingFieldService: SettingFieldService,
    private accountUserService: AccountUserService,
    private guestLogService: GuestLogService,
    private groupService: GroupService,
    private giftService: GiftService,
    private dateTimeService: DateTimeService,
    private popupService: PopupService,
    private functionService: FunctionService,
  ) { }

  ngOnInit() {}

  ngOnDestroy() {
    this.unwatchGuestLog();
  }

  /**
   * After view enter
   */
  ionViewWillEnter() {
    this.watchGuestLog();
  }

  /**
   * before view leave
   */
  ionViewWillLeave() {
    this.unwatchGuestLog();
  }

  /**
   * Get guest log
   */
  async watchGuestLog() {
    await this.popupService.presentLoading();
    await this.unwatchGuestLog();
    if (this.guestId) {
      this.guestLogSubscription = this.guestLogService.getGuestLog(this.guestId, this.type).subscribe((logList: Log[]) => {
        this.setupLog(logList);
        this.dismissLoading();
      });
    } else {
      this.dismissLoading();
    }
  }

  /**
   * Unwatch guest log subscription
   */
  async unwatchGuestLog() {
    if (this.guestLogSubscription) {
      this.guestLogSubscription.unsubscribe();
      this.guestLogSubscription = null;
    }
  }

  /**
   * Setup  log list
   * @param logList log list
   */
   setupLog(logList: Log[]) {
    const newLogList: Log[] = [];
    if (this.type === 'checkin' || this.type === 'invite') {
      logList = logList.filter((log: Log) => {
        if (log?.type?.length && log?.type?.indexOf(this.type) !== -1) {
          return true;
        }
        return false;
      });
    }

    logList?.forEach((log: Log) => {
      if (log?.type?.length) {
        if (log?.type?.length > 1) {
          log.type?.forEach((logType: LogType) => {
            const newLog = { ...log };
            newLog.type = [ logType ];
            if (logType === 'checkin' && log?.action?.checkinBy?.name) {
              newLog.by = log.action.checkinBy;
            } else if (logType === 'invite' && log?.action?.shareBy?.name) {
              newLog.by = log.action.shareBy;
            } else if (logType === 'gift' && log?.action?.giftBy?.name) {
              newLog.by = log.action.giftBy;
            }
            newLogList.push(newLog);
          });
        } else {
          newLogList.push(log);
        }
      }
    });
    
    this.logList = newLogList.sort((a: Log, b: Log) => {
      return this.functionService.compare(a?.by?.time?.seconds, b?.by?.time?.seconds, true);
    });

    this.setupViewport();
  }

  setupViewport() {
    if (this.cdkVirtualScrollViewport) {
      this.cdkVirtualScrollViewport?.checkViewportSize();
    } else {
      setTimeout(() => {
        this.setupViewport();
      }, 200);
    }
  }

  format(timestamp: number, dateTimeFormat?: string, timezone?: string) {
    return this.dateTimeService.format(timestamp, dateTimeFormat, '', timezone);
  }

  /**
   * dismiss loading
   */
  dismissLoading() {
    setTimeout(() => {
      this.popupService.dismissLoading();
    }, 500);
  }

  /**
   * Get group name
   * @param groupId group id
   * @returns group name
   */
  getGroupName(groupId: string): string {
    return this.groupService.getGroupName(groupId);
  }

  /**
   * Get list of setting field value
   * @param settingFieldType Setting field type
   * @param settingFieldList Setting field list
   * @returns Join of setting field value
   */
  getSettingField(settingFieldType: SettingFieldType, settingFieldList: SettingField[]): string {
    return this.settingFieldService.joinSettingField(settingFieldType, settingFieldList);
  }

  /**
   * Get user name by UID
   * @param uid UID
   */
  getUserName(uid: string) {
    if (uid) {
      return this.accountUserService.getUserNameByUid(uid);
    }
    return '';
  }

  /**
   * Get gift by id
   * @param giftId gift id
   * @returns Gift obj
   */
  getGiftById(giftId: string): Gift {
    return this.giftService.getGiftById(giftId);
  }

  /**
   * Dismiss modal
   */
  async dismissModal() {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { this.modalController.dismiss(); }
    }
  }

  /**
   * Track item
   * @param index Index
   * @param item item
   */
  trackByFn(index: number, item: Log) {
    if (item?.logId) {
      return item.logId;
    }
    return index;
  }

}
