import { ModuleService } from 'src/app/services/account/privilege/module.service';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';

import { CheckinSettingService } from 'src/app/services/checkin/checkin-setting.service';

import { CheckinSetting } from 'src/app/interfaces/setting';
import { Guest } from 'src/app/interfaces/guest';
import { ModuleType } from 'src/app/types/general';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { CheckinMethodType } from 'src/app/types/guest';

@Component({
  selector: 'app-checkin-bulk-gift',
  templateUrl: './checkin-bulk-gift.component.html',
  styleUrls: ['./checkin-bulk-gift.component.scss'],
})
export class CheckinBulkGiftComponent implements OnInit, OnDestroy {

  @ViewChild(CdkVirtualScrollViewport) cdkVirtualScrollViewport: CdkVirtualScrollViewport;

  checkinMethod: CheckinMethodType;
  module: ModuleType;
  /**
   * Check-in setting
   */
  // checkinSetting: CheckinSetting;
  /**
   * Selected guest list
   */
  selectedGuestList: string[];
  /**
   * Preview guest list
   */
  previewGuestList: Guest[];

  /**
   * Constructor
   * @param modalController modal controller
   * @param checkinSettingService checkin setting service
   */
  constructor(
    private modalController: ModalController,
  ) { }

  ngOnInit() {}

  ngOnDestroy() {
  }

  ionViewWillEnter() {
    this.module = 'checkin';
  }

  ionViewDidEnter() {
    this.setupViewport();
  }

  setupViewport() {
    if (this.cdkVirtualScrollViewport) {
      setTimeout(() => {
        this.cdkVirtualScrollViewport?.checkViewportSize();
      }, 200);
    } else {
      setTimeout(() => {
        this.setupViewport();
      }, 200);
    }
  }

  /**
   * Dismiss modal
   * @param dismiss Dismiss previous modal flag
   */
  async dismissModal(dismiss?: boolean, skip?: boolean) {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { await this.modalController.dismiss({ dismiss, skip }); }
    }
  }

  /**
   * Guest click, set selected
   */
  guestClick(guestId: string) {
    this.setSelectedGuest(guestId);
  }

  /**
   * Set selected guest
   * @param guestId Guest id
   */
  setSelectedGuest(guestId: string) {
    const index = this.selectedGuestList?.indexOf(guestId);
    if (index === -1) {
      this.selectedGuestList.push(guestId);
    } else {
      this.selectedGuestList?.splice(index, 1);
    }
  }

  /**
   * Calculate item height for virtual scroll
   * @param item Item
   */
  itemHeightFn(item: any) {
    const margin = 10;
    const guestItem = 100;
    const groupItem = 80;

    if (item?.group?.memberList?.length) {
      return (item.group.memberList.length * guestItem) + groupItem + margin;
    }
    return guestItem + margin;
  }

  /**
   * Track item by guest id / group id or index for virtual scroll
   * @param index Index
   * @param item item
   */
  trackByFn(index: number, item: Guest) {
    if (item?.guestId) {
      return item?.guestId;
    } else if (item?.group?.groupId) {
      return item.group.groupId;
    }
    return index;
  }

}
