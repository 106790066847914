<div class="main-div full-width-height">
  <ion-item class="background-transparent" [ngClass]="footer ? '' : 'ion-item-no-padding'" lines="none" (click)="toggleAdvance()">
    <ion-label>
      <div>
        <ion-icon class="setting-icon" src="./assets/wedding/icon/general/settings-outline.svg"></ion-icon>
        <ion-text class="font-12 font-med" color="dark">{{ 'BTN.advance' | translate }}</ion-text>
      </div>
    </ion-label>
    <ng-container *ngIf="footer">
      <ion-icon class="ion-no-margin" slot="end" src="./assets/wedding/icon/general/chevron-up.svg" *ngIf="!showAdvance"></ion-icon>
      <ion-icon class="ion-no-margin" slot="end" src="./assets/wedding/icon/general/chevron-down.svg" *ngIf="showAdvance"></ion-icon>
    </ng-container>
    <ng-container *ngIf="!footer">
      <ion-icon class="ion-no-margin" slot="end" src="./assets/wedding/icon/general/chevron-up.svg" *ngIf="showAdvance"></ion-icon>
      <ion-icon class="ion-no-margin" slot="end" src="./assets/wedding/icon/general/chevron-down.svg" *ngIf="!showAdvance"></ion-icon>
    </ng-container>
  </ion-item>
  <div *ngIf="showAdvance">
    <ion-item class="background-transparent ion-item-no-padding" lines="none" *ngIf="showEventMode">
      <ion-toggle [(ngModel)]="eventMode" color="success" (ionChange)="setEventMode()">
        <ion-text class="font-12">
          {{ (eventMode ? 'FOR_EVENT.for.event_msg' : 'FOR_EVENT.for.wedding_msg') | translate}}
        </ion-text>
      </ion-toggle>
      <!-- <ion-label position="stacked" color="dark">{{ 'FOR_EVENT.for.type' | translate }}</ion-label>
      <ion-select class="prompt-input-item" [(ngModel)]="eventMode" (ionChange)="setEventMode()">
        <ion-select-option [value]="false">
          {{ 'FOR_EVENT.for.wedding' | translate }}
        </ion-select-option>
        <ion-select-option [value]="true">
          {{ 'FOR_EVENT.for.event' | translate }}
        </ion-select-option>
      </ion-select> -->
    </ion-item>
    <ng-container *ngIf="!eventMode && !hideRole">
      <ng-container *ngFor="let accountCouple of accountCoupleList;">
        <ion-item [ngClass]="footer ? '' : 'ion-item-no-padding'" lines="none" (click)="presentCoupleTypePicker(accountCouple?.coupleId)" detail>
          <ion-label class="label" color="medium">
            <ion-icon color="dark" src="./assets/wedding/icon/general/person.svg"></ion-icon>
            <ion-text class=" font-12">{{ 'LBL.role' | translate }} {{ accountCouple.coupleId }}</ion-text>
          </ion-label>
          <ion-text class="ion-text-right font-14" color="dark" slot="end" *ngIf="accountCouple?.coupleType">
            {{ 'LIST.wedding_couple_type.' + accountCouple.coupleType | translate }}
          </ion-text>
        </ion-item>
      </ng-container>
    </ng-container>
  </div>
</div>
