import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { OfflineComponentsModule } from 'src/app/components/general/offline/offline.components.module';

import { WebsiteVenueComponent } from './layout/website-venue/website-venue.component';
import { WebsiteAttachmentComponent } from './layout/website-attachment/website-attachment.component';
import { QuillModule } from 'ngx-quill';
import { WebsiteInfoComponent } from './setting/website-info-setting/website-info-setting.component';
import { WebsiteTimeComponent } from './layout/website-time/website-time.component';

import { WebsiteContentsComponent } from './setting/contents/website-contents/website-contents.component';
import { WebsiteContentComponent } from './setting/contents/website-content/website-content.component';
import { WebsiteHostInfoComponent } from './setting/host/website-host-info/website-host-info.component';
import { CachedImageComponentsModule } from '../general/cached-image/cached-image.components.module';
import { WebsiteGalleryComponent } from './layout/website-gallery/website-gallery.component';
// import { NgxMasonryModule } from 'ngx-masonry';
// import { Angular2ImageGalleryModule } from 'angular2-image-gallery';
import { GalleryModule } from 'ng-gallery';
import { LightboxModule } from 'ng-gallery/lightbox';

import { WebsiteGallerySettingComponent } from './setting/gallery/website-gallery-setting/website-gallery-setting.component';
import { WebsiteFontsComponent } from './setting/website-fonts/website-fonts.component';
import { WebsiteColorsComponent } from './setting/website-colors/website-colors.component';
import { WebsiteThemeListComponent } from './setting/design/website-theme-list/website-theme-list.component';
import { WebsiteFormComponent } from './layout/website-form/website-form.component';
import { WebsiteLinkComponent } from './setting/website-link/website-link.component';
import { WebsiteFormSettingComponent } from './setting/website-form-setting/website-form-setting.component';
import { WebsiteTimeSettingComponent } from './setting/website-time-setting/website-time-setting.component';
import { GeneralComponentsModule } from '../general/general.components.module';

import { LoginComponentsModule } from '../login/login.components.module';
import { NgxFitTextModule } from '@pikselin/ngx-fittext';

import { WebsiteComponent } from './website.component';
import { WebsiteVenueSettingComponent } from './setting/website-venue-setting/website-venue-setting.component';
import { WebsiteHostSettingComponent } from './setting/host/website-host-setting/website-host-setting.component';
import { WebsiteHostComponent } from './layout/website-host/website-host.component';
import { WebsiteQrcodeComponent } from './layout/website-qrcode/website-qrcode.component';
import { NgxQrcodeStylingModule } from 'ngx-qrcode-styling';
import { WebsiteQrcodeSettingComponent } from './setting/website-qrcode-setting/website-qrcode-setting.component';
import { WebsiteCheckinComponent } from './layout/website-checkin/website-checkin.component';
import { WebsiteCheckinSettingComponent } from './setting/website-checkin-setting/website-checkin-setting.component';
import { WebsiteContactComponent } from './layout/website-contact/website-contact.component';
import { WebsiteContactSettingComponent } from './setting/website-contact-setting/website-contact-setting.component';
import { WebsiteContactDetailComponent } from './setting/website-contact-detail/website-contact-detail.component';
import { WebsiteCoverSettingComponent } from './setting/cover/website-cover-setting/website-cover-setting.component';
import { WebsiteItineraryComponent } from './layout/website-itinerary/website-itinerary.component';
import { WebsiteItinerarySettingComponent } from './setting/website-itinerary-setting/website-itinerary-setting.component';
import { WebsiteFormQuestionsComponent } from './setting/website-form-questions/website-form-questions.component';
import { WebsiteContentMediaSettingComponent } from './setting/contents/website-content-media-setting/website-content-media-setting.component';
import { WebsitePreviewComponent } from './setting/website-preview/website-preview.component';
import { SearchComponentsModule } from '../general/search/search.components.module';
import { WebsiteDetailSettingComponent } from './setting/website-detail-setting/website-detail-setting.component';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { WebsiteDressCodeSettingComponent } from './setting/website-dress-code-setting/website-dress-code-setting.component';
import { WebsiteAttachmentSettingComponent } from './setting/website-attachment-setting/website-attachment-setting.component';
import { WebsiteFontPreviewComponent } from './setting/website-font-preview/website-font-preview.component';
import { WebsiteDesignSettingComponent } from './setting/design/website-design-setting/website-design-setting.component';
import { WebsiteInvitesComponent } from './setting/website-invites/website-invites.component';
import { GuestComponentsModule } from '../guest/guest.components.module';
import { WebsitePdfComponent } from './layout/website-pdf/website-pdf.component';
import { WebsiteCoverListComponent } from './setting/cover/website-cover-list/website-cover-list.component';
import { WebsiteModeUpgradeComponent } from './setting/website-mode-upgrade/website-mode-upgrade.component';
import { WebsiteLinkEditComponent } from './setting/website-link-edit/website-link-edit.component';
import { WebsiteSuccessComponent } from './layout/website-success/website-success.component';
import { SelectAllComponentsModule } from '../general/select-all/select-all.components.module';
import { BulkActionComponentsModule } from '../general/bulk-action/bulk-action.components.module';
import { WebsiteCheckinSuccessComponent } from './layout/website-checkin-success/website-checkin-success.component';
import { WebsiteAlertComponent } from './layout/prompt/alert/alert.component';
import { WebsiteConfirmComponent } from './layout/prompt/confirm/confirm.component';
import { WebsiteGuestProfileComponent } from './layout/website-guest-profile/website-guest-profile.component';
import { WebsiteGuestProfileSettingComponent } from './setting/website-guest-profile-setting/website-guest-profile-setting.component';

@NgModule({
    declarations: [
        WebsiteHostInfoComponent,
        WebsiteHostSettingComponent,
        WebsiteHostComponent,
        WebsiteVenueComponent,
        WebsiteVenueSettingComponent,
        WebsiteDetailSettingComponent,
        WebsiteInfoComponent,
        WebsiteTimeComponent,
        WebsiteItineraryComponent,
        WebsiteTimeSettingComponent,
        WebsiteContentsComponent,
        WebsiteContentComponent,
        WebsiteContentMediaSettingComponent,
        WebsiteDressCodeSettingComponent,
        WebsiteThemeListComponent,
        WebsiteDesignSettingComponent,
        WebsiteFontsComponent,
        WebsiteFontPreviewComponent,
        WebsiteColorsComponent,
        WebsiteAttachmentComponent,
        WebsiteAttachmentSettingComponent,
        WebsiteGalleryComponent,
        WebsiteGallerySettingComponent,
        WebsiteFormComponent,
        WebsiteFormSettingComponent,
        WebsiteFormQuestionsComponent,
        WebsiteInvitesComponent,
        WebsiteLinkComponent,
        WebsiteLinkEditComponent,
        WebsiteQrcodeComponent,
        WebsiteQrcodeSettingComponent,
        WebsiteCheckinComponent,
        WebsiteCheckinSettingComponent,
        WebsiteContactComponent,
        WebsiteContactSettingComponent,
        WebsiteContactDetailComponent,
        WebsiteCoverSettingComponent,
        WebsiteCoverListComponent,
        WebsiteItineraryComponent,
        WebsiteItinerarySettingComponent,
        WebsitePreviewComponent,
        WebsitePdfComponent,
        WebsiteModeUpgradeComponent,
        WebsiteSuccessComponent,
        WebsiteCheckinSuccessComponent,
        WebsiteAlertComponent,
        WebsiteConfirmComponent,
        WebsiteGuestProfileSettingComponent,
        WebsiteGuestProfileComponent,
        WebsiteComponent,
    ],
    imports: [
        IonicModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        GeneralComponentsModule,
        SearchComponentsModule,
        OfflineComponentsModule,
        CachedImageComponentsModule,
        SelectAllComponentsModule,
        BulkActionComponentsModule,
        GuestComponentsModule,
        LoginComponentsModule,
        TranslateModule,
        ScrollingModule,
        QuillModule,
        NgxFitTextModule,
        NgxQrcodeStylingModule,
        // NgxMasonryModule,
        // Angular2ImageGalleryModule,
        GalleryModule,
        LightboxModule,
        DragDropModule,
    ],
    exports: [
        WebsiteHostInfoComponent,
        WebsiteHostSettingComponent,
        WebsiteHostComponent,
        WebsiteVenueComponent,
        WebsiteVenueSettingComponent,
        WebsiteDetailSettingComponent,
        WebsiteInfoComponent,
        WebsiteTimeComponent,
        WebsiteItineraryComponent,
        WebsiteTimeSettingComponent,
        WebsiteContentsComponent,
        WebsiteContentComponent,
        WebsiteContentMediaSettingComponent,
        WebsiteDressCodeSettingComponent,
        WebsiteThemeListComponent,
        WebsiteDesignSettingComponent,
        WebsiteFontsComponent,
        WebsiteFontPreviewComponent,
        WebsiteColorsComponent,
        WebsiteAttachmentComponent,
        WebsiteAttachmentSettingComponent,
        WebsiteGalleryComponent,
        WebsiteGallerySettingComponent,
        WebsiteFormComponent,
        WebsiteFormSettingComponent,
        WebsiteFormQuestionsComponent,
        WebsiteInvitesComponent,
        WebsiteLinkComponent,
        WebsiteLinkEditComponent,
        WebsiteQrcodeComponent,
        WebsiteQrcodeSettingComponent,
        WebsiteCheckinComponent,
        WebsiteCheckinSettingComponent,
        WebsiteContactComponent,
        WebsiteContactSettingComponent,
        WebsiteContactDetailComponent,
        WebsiteCoverSettingComponent,
        WebsiteCoverListComponent,
        WebsiteItineraryComponent,
        WebsiteItinerarySettingComponent,
        WebsitePreviewComponent,
        WebsitePdfComponent,
        WebsiteModeUpgradeComponent,
        WebsiteSuccessComponent,
        WebsiteCheckinSuccessComponent,
        WebsiteAlertComponent,
        WebsiteConfirmComponent,
        WebsiteGuestProfileSettingComponent,
        WebsiteGuestProfileComponent,
        WebsiteComponent,
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ],
})
export class WebsiteComponentsModule { }
