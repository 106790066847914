import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocationDetail } from 'src/app/interfaces/general';
import { PageSetting } from 'src/app/interfaces/visitor';
import { VistorInfoFieldType } from 'src/app/types/visitor';
import { AccountInfoService } from '../account/account-info.service';
import { LanguageService } from '../general/language.service';

@Injectable({
  providedIn: 'root'
})
export class VisitorVariableService implements OnInit, OnDestroy {

  pageSetting: PageSetting;

  constructor(
    private translate: TranslateService,
    private accountInfoService: AccountInfoService,
    private languageService: LanguageService,
  ) { }
  
  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  async getTranslation() {
    return this.languageService.translation;
  }

  getVisitorVariable(langCode: string, type: VistorInfoFieldType) {
    if (type) {
      const pageLocation: LocationDetail = this.accountInfoService.accountInfo?.location;
      const translation = this.getTranslation();
      if (type === 'greeting') {
        if (langCode && translation?.[langCode]?.VISITOR?.variable?.greeting) {
          return translation[langCode].VISITOR.variable.greeting;
        } else {
          return this.translate.instant('VISITOR.variable.greeting');
        }
      } else if (type === 'msg') {
        if (langCode && translation?.[langCode]?.VISITOR?.msg) {
          return translation[langCode].VISITOR.msg;
        } else {
          return this.translate.instant('VISITOR.msg');
        }
      } else if (type === 'pax') {
        if (langCode && translation?.[langCode]?.VISITOR?.variable?.pax) {
          return translation[langCode].VISITOR.variable.pax;
        } else {
          return this.translate.instant('VISITOR.variable.pax');
        }
      } else if (type === 'seating') {
        if (langCode && translation?.[langCode]?.VISITOR?.variable?.seating) {
          return translation[langCode].VISITOR.variable.seating;
        } else {
          return this.translate.instant('VISITOR.variable.seating');
        }
      } else if (type === 'session') {
        if (langCode && translation?.[langCode]?.VISITOR?.variable?.session) {
         return translation[langCode].VISITOR.variable.session;
        } else {
          return this.translate.instant('VISITOR.variable.session');
        }
      } else if (type === 'guestPass') {
        if (langCode && translation?.[langCode]?.VISITOR?.variable?.guestPass) {
          return translation[langCode].VISITOR.variable.guestPass;
        } else {
          return this.translate.instant('VISITOR.variable.guestPass');
        }
      } else if (type === 'groupName') {
        if (langCode && translation?.[langCode]?.VISITOR?.variable?.groupName) {
          return translation[langCode].VISITOR.variable.groupName;
        } else {
          return this.translate.instant('VISITOR.variable.groupName');
        }
      } else if (type === 'floorplan') {
        if (langCode && translation?.[langCode]?.VISITOR?.setting?.attachment?.floorplan?.title) {
          return translation[langCode].VISITOR.setting.attachment.floorplan.title;
        } else {
          return this.translate.instant('VISITOR.setting.attachment.floorplan.title');
        }
      } else if (type === 'menu') {
        if (langCode && translation?.[langCode]?.VISITOR?.setting?.attachment?.menu?.title) {
          return translation[langCode].VISITOR.setting.attachment.menu.title;
        } else {
          return this.translate.instant('VISITOR.setting.attachment.menu.title');
        }
      } else if (type === 'attachment') {
        if (langCode && translation?.[langCode]?.VISITOR?.setting?.attachment?.title) {
          return translation[langCode].VISITOR.setting.attachment.title;
        } else {
          return this.translate.instant('VISITOR.setting.attachment.title');
        }
      } else if (type === 'groupType') {
        if (langCode && translation?.[langCode]?.QRCODE?.lbl?.group_qrcode) {
          return translation[langCode].QRCODE.lbl.group_qrcode;
        } else {
          return this.translate.instant('QRCODE.lbl.group_qrcode');
        }
      } else if (type === 'groupMemberType') {
        if (this.pageSetting?.qrcode) {
          if (translation?.[langCode]?.QRCODE?.lbl?.guest_group_qrcode) {
            return translation[langCode].QRCODE.lbl.guest_group_qrcode;
          } else {
            return this.translate.instant('QRCODE.lbl.guest_group_qrcode');
          }
        } else {
          if (translation?.[langCode]?.QRCODE?.lbl?.guest_group_invitation) {
            return translation[langCode].QRCODE.lbl.guest_group_invitation;
          } else {
            return this.translate.instant('QRCODE.lbl.guest_group_invitation');
          }
        }
      } else if (type === 'title') {
        return '[WEDDING_TITLE]';
      } else if (type === 'date') {
        return '[WEDDING_DATE]';
      } else if (type === 'startTime') {
        return '[WEDDING_START_TIME]';
      } else if (type === 'endTime') {
        return '[WEDDING_END_TIME]';
      } else if (type === 'venue') {
        return pageLocation?.name ? pageLocation.name : '';
      } else if (type === 'address') {
        return pageLocation?.address ? pageLocation.address : '';
      } else if (type === 'timezone') {
        return '[WEDDING_TIMEZONE]';
      }
    }
    return '';
  
  }
}
