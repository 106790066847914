<!-- [class]="websiteThemeColor?.button?.fill === 'outline' ? 'website-status-outline-card' : 'website-status-solid-card'" -->
<!-- class="status-main-div ion-margin" -->
<div>
  <div class="font-20 font-bold ion-text-center ion-margin" *ngIf="name">
    {{ name}}
  </div>
  <div class="font-20 ion-text-center ion-margin" *ngIf="websiteDetail?.checkin?.field?.successMsg">
    {{ websiteDetail.checkin.field.successMsg }}
  </div>
  <app-website-success></app-website-success>
  <div class="font-14 ion-text-center">
    <div class="ion-margin" *ngIf="websiteDetail?.checkin?.field?.seatingMsg">
      {{ websiteDetail.checkin.field.seatingMsg }}
    </div>
    <div class="ion-margin-vertical">
      <ion-icon class="font-40 vertical-middle" src="./assets/wedding/icon/guest/seating-outline.svg"></ion-icon>&nbsp;<ion-text class="font-36 vertical-middle">{{ seating ? seating : '-' }}</ion-text>
    </div>
  </div>
</div>
