import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, Subscription, map } from 'rxjs';

import { UpdateByService } from 'src/app/services/user/update-by.service';
import { FunctionService } from 'src/app/services/general/function.service';
import { ErrorService } from 'src/app/services/general/error.service';

import { AccountUser, AccountCouple } from 'src/app/interfaces/account';
import { CoupleList } from 'src/app/commons/couple';
import { AccountEventModeService } from './account-event-mode.service';
import { AccountInfoService } from './account-info.service';

/**
 * Account couple service
 */
@Injectable({
  providedIn: 'root'
})
export class AccountCoupleService implements OnInit, OnDestroy {

  /**
   * Standard couple type database
   */
  stdCoupleType: string[] = CoupleList;
  /**
   * Couple type list
   */
  accountCoupleList: AccountCouple[];
  /**
   * Couple list of account user
   */
  coupleAccountUserList: AccountUser[];
  /**
   * Observable couple type
   */
  observableCoupleType: any;
  /**
   * Observable couple list
   */
  observableCoupleUserList: any;
  /**
   * Account ID
   */
  accountId: string;
  /**
   * Account couple subscription
   */
  private accountCoupleSubscription: Subscription;

  /**
   * Constructor
   * @param afs angular firestore
   * @param functionService function service
   */
  constructor(
    private afs: AngularFirestore,
    private accountInfoService: AccountInfoService,
    private accountEventModeService: AccountEventModeService,
    private updateByService: UpdateByService,
    private functionService: FunctionService,
    private errorService: ErrorService,
  ) {
    this.accountCoupleList = [];
    this.observableCoupleType = new BehaviorSubject<AccountCouple[]>(this.accountCoupleList);
    this.observableCoupleUserList = new BehaviorSubject<AccountUser[]>(this.coupleAccountUserList);

    this.initialize();
  }

  ngOnInit(): void {
    
  }

  ngOnDestroy() {
    this.unwatchAccountCouple();
    this.accountCoupleList = [];
  }

  /**
   * Initialize
   */
  initialize() {
    this.setupCoupleType();
  }

  /**
   * Setup Account ID
   * @param accountId Account ID
   */
  async setupAccountId(accountId: string) {
    this.accountId = accountId;
    
    if (this.accountId) {
      await this.watchAccountCouple();
    } else {
      await this.unwatchAccountCouple();
      this.accountCoupleList = [];
    }
  }

  /**
   * Watch account couple
   */
  async watchAccountCouple() {
    if (this.accountId && !this.accountCoupleSubscription) {
      this.accountCoupleSubscription = this.afs.doc(`accounts/${ this.accountId }/accountDetail/couple/`)
      .snapshotChanges().pipe(map(changes => {
        const data: any = changes.payload.data();
        return data?.accountCoupleList?.length ? data.accountCoupleList : [];
      })).subscribe({
        next: (accountCoupleList: AccountCouple[]) => {
          this.setupCoupleType(accountCoupleList);
        }, error: (err: any) => {
          this.errorService.logError(err);
        }
      });
    }
  }

  /**
   * Unwatch account couple
   */
  async unwatchAccountCouple() {
    if (this.accountCoupleSubscription) {
      this.accountCoupleSubscription.unsubscribe();
      this.accountCoupleSubscription = null;
    }
  }

  /**
   * Setup couple tye
   * @param accountCoupleList Couple type
   */
  setupCoupleType(accountCoupleList?: AccountCouple[]) {
    if (!this.accountCoupleList?.length || !this.functionService.isEqual(accountCoupleList, this.accountCoupleList)) {
      if (accountCoupleList?.length) {
        this.accountCoupleList = accountCoupleList;
      } else {
        this.accountCoupleList = this.getStdCoupleType();
      }
      this.observableCoupleType.next(this.accountCoupleList);
    }
  }

  getAccountCouple(coupleId: number) {
    if (coupleId) {
      const coupleList = this.accountInfoService.accountInfo?.coupleList;
      const index = coupleList?.findIndex((x) => {
        if (x?.accountCouple?.coupleId === coupleId) {
          return true;
        }
        return false;
      });
      if (index !== -1) {
        return coupleList[index];
      }
    }
    return null;
  }

  getCoupleUser(coupleId: number): AccountUser {
    if (coupleId && this.coupleAccountUserList?.length) {
      const coupleIndex = this.coupleAccountUserList?.findIndex((x: AccountUser) => x?.role?.coupleId === coupleId);
      if (coupleIndex !== -1) {
        return this.coupleAccountUserList[coupleIndex];
      }
    }
    return null;
  }

  /**
   * Gete std couple type
   * @returns account couple list
   */
  getStdCoupleType(): AccountCouple[] {
    const accountCoupleList: AccountCouple[] = [];
    this.stdCoupleType?.forEach((value: string, key: number) => {
      const couple: AccountCouple = {
        coupleId: key + 1,
        coupleType: value
      };
      accountCoupleList.push(couple);
    });
    return accountCoupleList;
  }

  /**
   * Get couple type by couple ID
   * @param coupleId couple id
   */
  getCoupleType(coupleId: number): string {
    if (coupleId && this.accountCoupleList?.length) {
      const index = this.accountCoupleList?.findIndex((x: AccountCouple) => x?.coupleId === coupleId);
      if (index !== -1) {
        return this.accountCoupleList?.[index]?.coupleType;
      }
    }
    if (this.accountEventModeService.eventMode) {
      return 'organizer';
    }
    return null;
  }

  /**
   * Check if same couple type
   */
  checkSameCoupleType(): boolean {
    if (this.getCoupleType(1) === this.getCoupleType(2)) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Setup couple list
   * @param accountUser Account User list
   */
  setupCoupleList(accountUser?: AccountUser[]) {
    const coupleAccountUserList: AccountUser[] = [];
    if (accountUser && accountUser.length) {
      accountUser?.forEach((user: AccountUser) => {
        if (user && user?.enable && user?.role?.coupleId && user?.role?.type &&
          (user.role.type === 'groom' || user.role.type === 'bride')) {
            coupleAccountUserList.push(user);
        }
      });
    }

    if (!this.functionService.isEqual(coupleAccountUserList, this.coupleAccountUserList)) {
      this.coupleAccountUserList = coupleAccountUserList;
      this.observableCoupleUserList.next(this.coupleAccountUserList);
    }
  }

  /**
   * Update account couple
   * @param data Data
   */
  async updateAccountCouple(data: any) {
    if (this.accountId && !this.functionService.isEmpty(data)) {
      data.updateBy = this.updateByService.updateBy;
      const accountsRef = this.afs.firestore.doc(`accounts/${ this.accountId }/accountDetail/couple`);
      accountsRef.set(data, { merge: true }).then(() => {
      }).catch((err: any) => {
        this.errorService.logError(err);
      });
    }
  }

}
