import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { SettingField } from 'src/app/interfaces/database';
import { SettingFieldType } from 'src/app/types/general';
import { FunctionService } from '../general/function.service';

/**
 * Setting field service
 */
@Injectable({
  providedIn: 'root'
})
export class SettingFieldService implements OnInit, OnDestroy {

  /**
   * Constructor
   * @param translate translate service
   */
  constructor(
    private translate: TranslateService,
    private functionService: FunctionService,
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  compare(a: SettingField, b: SettingField) {
    return a?.value === b?.value && a?.custom === b?.custom;
  }

  getSettingFieldTitle(settingFieldType: SettingFieldType): string {
    if (settingFieldType === 'invited_by') {
      return this.translate.instant('GUEST.lbl.invited_by');
    } else if (settingFieldType === 'category') {
      return this.translate.instant('LBL.category');
    } else if (settingFieldType === 'dietary_req') {
      return this.translate.instant('GUEST.lbl.dietary_req');
    } else if (settingFieldType === 'special_req') {
      return this.translate.instant('GUEST.lbl.special_req');
    } else if (settingFieldType === 'session') {
      return this.translate.instant('LBL.session');
    } else if (settingFieldType === 'itinerary') {
      return this.translate.instant('WEBSITE.itinerary.title');
    }
    return '';
  }

  /**
   * Get list of setting field value
   * @param settingFieldType Setting field type
   * @param settingFieldList Setting field list
   * @returns Join of setting field value
   */
  joinSettingField(settingFieldType: SettingFieldType, settingFieldList: SettingField[],
                  hideNone?: boolean, showCount?: boolean, newLine?: boolean): string {
    let result: string[] = [];
    if (settingFieldList?.length && settingFieldType) {
      result = settingFieldList.map((settingField: SettingField) => {
        const count = showCount ? ( settingField?.count ? settingField.count : 1 ) + ' ' : '';
        if (settingField.custom) {
          return count + settingField.value;
        } else {
          if (!hideNone || settingField.value !== 'none') {
            return count + this.translate.instant('LIST.' + settingFieldType + '.' + settingField.value);
          }
        }
      });
    }
    if (newLine) {
      return result.join('<br>');
    }
    return result.join(', ');
  }

  sortList(settingFieldType: SettingFieldType, settingFieldList: SettingField[]) {
    return settingFieldList.sort((a: SettingField, b: SettingField) => {
      return this.functionService.compare(this.getSettingField(settingFieldType, a), this.getSettingField(settingFieldType, b));
    });
  }

  getSettingField(settingFieldType: SettingFieldType, settingField: SettingField) {
    if (settingFieldType && settingField?.value) {
      if (settingField?.custom) {
        return settingField?.value;
      } else {
        if (settingFieldType === 'invited_by') {
          return this.translate.instant('LIST.invited_by.' + settingField.value);
        } else if (settingFieldType === 'category') {
          return this.translate.instant('LIST.category.' + settingField.value);
        } else if (settingFieldType === 'dietary_req') {
          return this.translate.instant('LIST.dietary_req.' + settingField.value);
        } else if (settingFieldType === 'special_req') {
          return this.translate.instant('LIST.special_req.' + settingField.value);
        } else if (settingFieldType === 'itinerary') {
          return this.translate.instant('LIST.itinerary.' + settingField.value);
        }
      }
    }
    return '';
  }

}
