<ion-header>
  <ion-toolbar color="light">
    <ion-buttons slot="start">
      <ion-button (click)="dismissModal()">
        <ion-icon class="back-icon" src="./assets/wedding/icon/general/chevron-back.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <app-offline-indicator></app-offline-indicator>
      <ng-container *ngIf="group?.groupId">{{ 'QRCODE.lbl.group_qrcode' | translate }}</ng-container>
      <ng-container *ngIf="guest?.guestId">{{ 'QRCODE.lbl.guest_qrcode' | translate }}</ng-container>
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding" color="light">
  <ng-container *ngIf="guest?.guestId">
    <ion-card class="ion-no-padding ion-no-margin margin-5">
      <app-guest-item [setGuest]="guest" [index]="0" [setHideField]="{ group: true, qrcodeStatus: true, seatingStatus: true }" [disableQrcode]="true" [disableClick]="true" [disableLines]="true"></app-guest-item>
    </ion-card>
  </ng-container>
  <ng-container *ngIf="group?.groupId">
    <app-guest-card [setGuest]="{ group: group }" [index]="0" [setHideField]="{ seatingStatus: true, qrcodeStatus: true }" [disableQrcode]="true" [disableClick]="true" [disableGroupMemberList]="true"></app-guest-card>
  </ng-container>
  <ion-card class="ion-no-margin margin-5">
    <ion-item-sliding #qrcodeStatusItem lines="none">
      <ion-item class="font-10 ion-item-no-padding qrcode-status-item" lines="none">
        <ion-avatar slot="start">
          <ion-icon class="icon-large" src="./assets/wedding/icon/general/sent.svg"></ion-icon>
        </ion-avatar>
        <ion-grid class="full-width ion-no-padding" fixed>
          <ion-row>
            <ion-col size="5"><ion-text class="font-bold ion-text-uppercase ion-text-wrap" color="dark">{{ 'QRCODE.lbl.sent_by' | translate }}</ion-text></ion-col>
            <ion-col>
              <ion-label class="font-10 ion-text-wrap" *ngIf="shareRecord">
                <ng-container *ngIf="shareByName"><ion-text color="dark">{{ shareByName }}</ion-text></ng-container>
              </ion-label>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="5"><ion-text class="font-bold ion-text-uppercase ion-text-wrap" color="dark">{{ 'QRCODE.lbl.sent_channel' | translate }}</ion-text></ion-col>
            <ion-col>
              <ion-label class="font-10 ion-text-wrap" *ngIf="shareRecord">
                <ng-container *ngIf="shareRecord?.channel"><ion-text color="dark">{{ 'LIST.share_channel.' +  shareRecord.channel | translate }}</ion-text></ng-container>
              </ion-label>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="5"><ion-text class="font-bold ion-text-uppercase ion-text-wrap" color="dark">{{ 'QRCODE.lbl.sent_time' | translate }}</ion-text></ion-col>
            <ion-col>
              <ion-label class="font-10 ion-text-wrap" *ngIf="shareRecord">
                <ng-container *ngIf="shareRecord?.by?.time?.seconds"><ion-text color="dark">{{ format(shareRecord.by.time.seconds * 1000) }}</ion-text></ng-container>
              </ion-label>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-item>
      <ion-item-options style="border: none !important;" side="end">
        <ion-item-option color="success" *ngIf="!qrcodeStatus" (click)="promptUpdateStatus(true, qrcodeStatusItem)"><ion-icon src="./assets/wedding/icon/general/checkmark.svg"></ion-icon></ion-item-option>
        <ion-item-option color="danger" *ngIf="qrcodeStatus" (click)="promptUpdateStatus(false, qrcodeStatusItem)"><ion-icon src="./assets/wedding/icon/general/close.svg"></ion-icon></ion-item-option>
      </ion-item-options>
    </ion-item-sliding>
  </ion-card>
  
  <ion-grid class="ion-text-center ion-margin-vertical" style="padding: 0;" fixed>
    <ion-row>
      <ion-col>
        <ion-card class="ion-padding-vertical ion-no-margin" color="tertiary" (click)="presentQrcodeTemplateModal()" button>
          <div>
            <ion-icon class="icon-large" src="./assets/wedding/icon/general/language.svg"></ion-icon>
          </div>
          <div class="font-10 font-med"><ion-text color="dark">{{ 'BTN.language' | translate }}</ion-text></div>
        </ion-card>
      </ion-col>
      <ion-col>
        <ion-card class="ion-padding-vertical ion-no-margin" color="tertiary" (click)="presentVisitorPageModal()" button>
          <div>
            <ion-icon class="icon-large" src="./assets/wedding/icon/qrcode/scan-qrcode.svg"></ion-icon>
          </div>
          <div class="font-10 font-med"><ion-text color="dark">{{ 'BTN.preview' | translate }}</ion-text></div>
        </ion-card>
      </ion-col>
      <ion-col>
        <ion-card class="ion-padding-vertical ion-no-margin" color="tertiary" (click)="presentLogModal()" button>
          <div>
            <ion-icon class="icon-large" src="./assets/wedding/icon/general/history.svg"></ion-icon>
          </div>
          <div class="font-10 font-med"><ion-text color="dark">{{ 'BTN.history' | translate }}</ion-text></div>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
  <ion-card class="ion-no-margin margin-5">
    <ion-card-header class="ion-no-padding" color="grey">
      <ion-item class="ion-no-padding" lines="none" color="tertiary">
        <ion-icon class="icon-small ion-padding-horizontal" color="dark" src="./assets/wedding/icon/general/msg-quote.svg"></ion-icon>
        <ion-text class="font-10 font-bold" color="dark">{{ 'LBL.msg' | translate }}</ion-text>
      </ion-item>
    </ion-card-header>
    <ion-card-content>
      <ion-item class="font-10 full-width ion-item-no-padding" lines="none" *ngIf="selectedTemplate?.msg">
        <div class="full-width ion-padding-top">
          <ion-textarea color="dark" [(ngModel)]="selectedTemplate.msg" rows="15"></ion-textarea>
        </div>
      </ion-item>
    </ion-card-content>
  </ion-card>
</ion-content>
<ion-footer *ngIf="currentPrivilege?.['guest']?.['qrcode']">
  <ion-toolbar *ngIf="isHybrid">
    <!-- <ion-button class="ion-margin" expand="block" color="danger" fill="solid" type="button" (click)="send()" *ngIf="qrcodeStatus">
      {{ 'QRCODE.btn.resend' | translate }}
    </ion-button> -->
    <ion-button class="ion-margin" expand="block" color="primary" fill="solid" type="button" (click)="send()">
      {{ 'QRCODE.btn.send' | translate }}
    </ion-button>
    <div class="ion-margin ion-text-center" (click)="send(true)" *ngIf="(group?.groupId && group?.mobile?.no) || (guest?.guestId && guest?.mobile?.no)">
      <ion-text class="font-10 font-underline">{{ 'QRCODE.btn.send_via' | translate: { app: 'LIST.share_channel.whatsapp' | translate } }}</ion-text>
    </div>
  </ion-toolbar>
  <ion-toolbar *ngIf="!isHybrid">
    <ion-button class="ion-margin" expand="block" (click)="send(false, true)">
      {{ 'BTN.copy' | translate }}
    </ion-button>
    <div class="ion-margin ion-text-center" (click)="send(true)" *ngIf="(group?.groupId && group?.mobile?.no) || (guest?.guestId && guest?.mobile?.no)">
      <ion-text class="font-10 font-underline">{{ 'QRCODE.btn.send_via' | translate: { app: 'LIST.share_channel.whatsapp' | translate } }}</ion-text>
    </div>
  </ion-toolbar>
</ion-footer>
