<ion-content>
  <div class="full-height vertical-center" *ngIf="ready">
    <ion-card class="center ion-no-margin">
      <ion-datetime
        #popoverDatetime
        name="time"
        [hourCycle]="'h23'"
        [presentation]="presentation ? presentation : 'date-time'"
        [min]="min"
        [max]="max"
        [value]="time ? time : ''"
        [size]="'cover'"
        [locale]="locale"
        (ionChange)="setupFormatDateTime()"
      >
      <!-- <ion-buttons slot="buttons">
        <ion-button slots="buttons" class="btn full-width ion-no-margin" expand="block" color="grey" fill="solid" type="button" (click)="dismissModal()">
          <ion-icon slot="icon-only" src="./assets/wedding/icon/general/close.svg"></ion-icon>
        </ion-button>
        <ion-button slots="buttons" class="btn full-width ion-no-margin" expand="block" [color]="'primary'" fill="solid" type="button" (click)="dismissModal(true)">
          <ion-icon slot="icon-only" src="./assets/wedding/icon/general/checkmark.svg"></ion-icon>
        </ion-button>
      </ion-buttons> -->
      </ion-datetime>
      <ion-item class="setting-item full-width" lines="none" (click)="openLocaleTypeModal()" *ngIf="!hideLocale" detail button>
        <ion-text class="font-12" color="medium" slot="start">
          {{ 'WEBSITE.time.locale' | translate }}
        </ion-text>
        <ion-note class="ion-text-right font-12" color="dark" *ngIf="localeType?.summary" slot="end">
          {{ localeType.summary }}
        </ion-note>
      </ion-item>
      <ion-item lines="none" (click)="presentDatetimeFormatModal('date', dateFormat)" *ngIf="showDateFormat" detail button>
        <ion-text class="font-12" color="medium" slot="start">
          {{ 'DATE.lbl.date_format' | translate }}
        </ion-text>
        <ion-note class="ion-text-right font-12" color="dark" slot="end" *ngIf="formattedDate">
          {{ formattedDate }}
        </ion-note>
      </ion-item>
      <ion-item lines="none" (click)="presentDatetimeFormatModal('time', timeFormat)" *ngIf="showTimeFormat" detail button>
        <ion-text class="font-12" color="medium" slot="start">
          {{ 'DATE.lbl.time_format' | translate }}
        </ion-text>
        <ion-note class="ion-text-right font-12" color="dark" slot="end" *ngIf="formattedTime">
          {{ formattedTime }}
        </ion-note>
      </ion-item>
      <ion-grid fixed>
        <ion-row class="ion-align-items-center ion-justify-content-center">
          <ion-col size-xs="0" size-sm="1"></ion-col>
          <ion-col class="ion-align-self-center ion-text-center">
            <ion-button slots="buttons" class="ion-no-margin" expand="block" color="grey" fill="solid" type="button" (click)="dismissModal()">
              <ion-icon slot="icon-only" src="./assets/wedding/icon/general/close.svg"></ion-icon>
            </ion-button>
          </ion-col>
          <ion-col size-xs="1" size-sm="2"></ion-col>
          <ion-col class="ion-align-self-center center">
            <ion-button slots="buttons" class="ion-no-margin" expand="block" [color]="'primary'" fill="solid" type="button" (click)="dismissModal(true)">
              <ion-icon slot="icon-only" src="./assets/wedding/icon/general/checkmark.svg"></ion-icon>
            </ion-button>
          </ion-col>
          <ion-col size-xs="0" size-sm="1"></ion-col>
        </ion-row>
        <ion-row class="ion-align-items-center ion-justify-content-center" *ngIf="clearBtn">
          <ion-col class="ion-align-self-center ion-text-center">
            <ion-text class="font-12 font-underline" (click)="dismissModal(false, true)">
              {{ 'BTN.clear' | translate }}
            </ion-text>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-card>
  </div>
</ion-content>