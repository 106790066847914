<div class="full-width-height" *ngIf="websiteDetail?.itinerary?.enable">
  <quill-view [content]="title" *ngIf="title"></quill-view>
  <quill-view [content]="msg" *ngIf="msg"></quill-view>

  <div class="ion-margin-bottom" *ngIf="websiteDetail?.itinerary?.media?.url">
    <ng-container *ngIf="websiteDetail?.itinerary?.media.type === 'youtube'">
      <app-youtube [websiteMode]="true" [setCardHeight]="mediaHeight" [setCalculateSize]="updateTheme" [setSrc]="websiteDetail?.itinerary?.media.url"></app-youtube>
    </ng-container>
    <ng-container *ngIf="websiteDetail?.itinerary?.media.type === 'base64' || websiteDetail?.itinerary?.media.type === 'firebase'">
      <ion-img class="full-width center" [src]="websiteDetail?.itinerary?.media.url"></ion-img>
    </ng-container>
  </div>

  <ng-container *ngIf="websiteDetail?.itinerary?.itineraryList && itineraryList?.length">
    <div class="ion-text-center" *ngFor="let itinerary of itineraryList">
      <div><ion-text class="font-14" *ngIf="itinerary?.title">{{ itinerary.title }}</ion-text></div>
      <div *ngIf="itinerary?.startTime?.seconds">
        <ion-text class="font-12">
          <app-website-time [setDesign]="1" [setDateFormat]="accountInfo?.dateFormat" [setTimeFormat]="accountInfo?.timeFormat" [setWebsiteDetail]="websiteDetail" [setStartTime]="itinerary.startTime.seconds" [setEndTime]="itinerary?.endTime?.seconds" [saveCalendar]="false" [setReady]="true"></app-website-time>
        </ion-text>
      </div>
    </div>
  </ng-container>
</div>