import { Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FunctionService } from 'src/app/services/general/function.service';

@Component({
  selector: 'app-youtube',
  templateUrl: './youtube.component.html',
  styleUrls: ['./youtube.component.scss'],
})
export class YoutubeComponent  implements OnInit, OnDestroy {

  /**
   * Watch screen resize change for login slider setup
   */
  @HostListener('window:resize', ['$event'])onResize(event: any) {
    this.calculateYouTubeDimensions();
  }
  /**
   * Watch screen orientation change for login slider setup
   */
  @HostListener('window:orientationchange', ['$event']) onOrientationChange(event: any) {
    this.calculateYouTubeDimensions();
  }

  @ViewChild('mainElement', { read: ElementRef }) mainElement: ElementRef;
  
  @Input() set setSrc(src: string) {
    if (src) {
      this.src = this.getTrustedUrl(this.setupYoutubeUrl(src));
      this.calculateYouTubeDimensions();
    } else {
      this.src = null;
    }
  }
  
  @Input() set setCardWidth(cardWidth: number) {
    this.cardWidth = cardWidth;
    this.calculateYouTubeDimensions();
  }

  @Input() set setCardHeight(cardHeight: number) {
    this.cardHeight = cardHeight;
    this.calculateYouTubeDimensions();
  }

  @Input() set setCalculateSize(flag: boolean) {
    if (flag) {
      this.calculateYouTubeDimensions();
    }
  }

  @Input() websiteMode: boolean;
  
  src: SafeResourceUrl;

  cardWidth: number;
  cardHeight: number;
  width: number;
  height: number;
  
  constructor(
    private domSanitizer: DomSanitizer,
    private functionService: FunctionService,
  ) { }

  ngOnInit() {}

  ngOnDestroy(): void {
      
  }

  async calculateYouTubeDimensions() {
    let cardWidth = this.cardWidth;
    let cardHeight = this.cardHeight;

    await this.functionService.delay(200);

    if (!cardWidth) {
      cardWidth = this.mainElement?.nativeElement?.offsetWidth;
    }
    if (!cardHeight) {
      cardHeight = this.mainElement?.nativeElement?.offsetHeight;
    }
    
    if (cardWidth && cardHeight) {
      const aspectRatio = 16 / 9; // Typical YouTube video aspect ratio
      // Calculate width and height while preserving aspect ratio
      let width = cardWidth - (this.websiteMode ? 0 : 32) ;
      let height = cardHeight;

      if (width > 800) {
        width = width * 0.9;
      } else if (width > 1600) {
        width = width * 0.7;
      } else if (width > 2400) {
        width = width * 0.5;
      }
  
      if (!this.websiteMode && (width / aspectRatio > height)) {
          width = height * aspectRatio;
      } else {
          height = width / aspectRatio;
      }
      // console.log(width);
      // console.log(height);
      this.width = width;
      this.height = height;
    } else {
      setTimeout(() => {
        this.calculateYouTubeDimensions();
      }, 200);
    }
    
  }

  setupYoutubeUrl(url: string) {
    if (url) {
      if (url.indexOf('https://youtu.be/') !== -1) {
        return 'https://www.youtube.com/embed/' + url.replace('https://youtu.be/', '');
      } else if (url.indexOf('https://youtube.com/watch?v=') !== -1) {
        return 'https://www.youtube.com/embed/' + url.replace('https://youtube.com/watch?v=', '');
      } else if (url.indexOf('https://www.youtube.com/watch?v=') !== -1) {
        return 'https://www.youtube.com/embed/' + url.replace('https://www.youtube.com/watch?v=', '');
      } else if (url.indexOf('https://m.youtube.com/watch?v=') !== -1) {
        return 'https://www.youtube.com/embed/' + url.replace('https://m.youtube.com/watch?v=', '');
      } else if (url.indexOf('https://www.youtube.com/embed/') !== -1) {
        return url;
      }
    }
    return '';
  }

  getTrustedUrl(url: string) {
    if (url) {
      if (url?.indexOf('?') !== -1) {
        url = url + '&html5=1';
      } else {
        url = url + '?html5=1';
      }
      return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
    }
    return null;
  }

}
