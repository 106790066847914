import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { GuestListService } from 'src/app/services/guest/guest-list.service';

import { GuestListComponent } from 'src/app/components/guest/guest-list/guest-list.component';
import { Guest } from 'src/app/interfaces/guest';
import { WebsiteThemeColor } from 'src/app/interfaces/website';

/**
 * Alert component
 */
@Component({
  selector: 'app-website-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class WebsiteAlertComponent implements OnInit, OnDestroy {

  font: string;

  websiteThemeColor: WebsiteThemeColor;
  /**
   * Title
   */
  title: any;
  /**
   * Subtitle
   */
  subtitle: any;
  /**
   * Message
   */
  msg: any;

  hideWarning: boolean;


  /**
   * Constructor
   * @param modalController Modal Controller
   */
  constructor(
    private modalController: ModalController,
    private guestListService: GuestListService,
  ) { }

  ngOnInit() {}

  ngOnDestroy() {}

  /**
   * Dismiss alert modal
   */
  async dismissModal() {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { modal.dismiss(); }
    }
  }

}
