import { DeviceService } from 'src/app/services/device/device.service';
import { StorageService } from 'src/app/services/general/storage.service';
import { UrlService } from 'src/app/services/general/url.service';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Platform, PopoverController } from '@ionic/angular';

import { DownloadAppComponent } from 'src/app/components/general/download-app/download-app.component';
import { ChromeComponent } from 'src/app/components/general/chrome/chrome.component';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DeviceDownloadService implements OnInit, OnDestroy {

  /**
   * Popover
   */
  private appPopover: any;

  private chromePopover: any;

  private downloadPromptCount: number;

  private urlSubscription: Subscription;
  /**
   * Constructor
   * @param platform platform
   * @param popoverController popover controller
   */
  constructor(
    private platform: Platform,
    private popoverController: PopoverController,
    private urlService: UrlService,
    private deviceService: DeviceService,
    private storageService: StorageService,
  ) {
    this.initialize();
  }

  ngOnInit(): void {
    
  }

  ngOnDestroy(): void {
    this.unwatchUrl();
  }

  async initialize() {
    await this.getDownloadPromptCount();
    this.checkAppDownload();
  }

  /**
   * Watch URL
   */
  async watchUrl() {
    if (!this.urlSubscription) {
      this.urlSubscription = this.urlService.observableUrl.subscribe((url: string) => {
        if (url) {
          if ((this.urlService.checkUrl(['/website/']) || this.urlService.checkUrl(['/vip/']) || this.urlService.checkUrl(['/subscription/']))) {
            this.dismissAppPopover();
          }
          if (this.urlService.checkUrl(['/website/']) || this.urlService.checkUrl(['/vip/']) || this.urlService.checkUrl(['/subscription/'])) {
            this.dismissChromePopover();
          }
        }
      });
    }
  }

  async unwatchUrl() {
    if (this.urlSubscription) {
      this.urlSubscription.unsubscribe();
      this.urlSubscription = null;
    }
  }

  /**
   * Check app download
   */
  async checkAppDownload() {
    await this.platform.ready();
    let checkChrome = true;
    if (!this.platform.is('hybrid')) {
      if (!this.downloadPromptCount) {
        this.downloadPromptCount = 0;
      }
      const device = this.deviceService.deviceInfo;
      if ((this.platform.is('ios') || device?.operatingSystem === 'mac' || this.platform.is('android')) && this.downloadPromptCount < 3) {
        if (!this.urlService.checkUrl(['/website/']) && !this.urlService.checkUrl(['/vip/']) && !this.urlService.checkUrl(['/subscription/'])) {
          this.presentDownloadAppPopover();
          checkChrome = false;
        }
      }
      if (checkChrome) {
        this.checkChrome();
      }
    }
  }

  async checkChrome() {
    if (!this.platform.is('hybrid')) {
      if (!this.urlService.checkUrl(['/website/']) && !this.urlService.checkUrl(['/vip/']) && !this.urlService.checkUrl(['/subscription/']) && this.deviceService.getDeviceBrowser() !== 'chrome') {
        this.presentChromePopover();
      }
    }
  }

  /**
   * Present popover
   */
  async presentDownloadAppPopover() {
    this.appPopover = await this.popoverController.create({
      component: DownloadAppComponent,
      cssClass: 'popover-download-app',
      showBackdrop: true,
      backdropDismiss: true,
      translucent: true,
      dismissOnSelect: true,
    });
    await this.appPopover.present();
    this.appPopover.onWillDismiss().then(() => {
      this.appPopover = null;
      this.updateDownloadPromptCount();
      this.unwatchUrl();
    });
    this.watchUrl();
  }

  async presentChromePopover() {
    this.chromePopover = await this.popoverController.create({
      component: ChromeComponent,
      cssClass: 'popover-download-app',
      showBackdrop: true,
      backdropDismiss: true,
      translucent: true,
      dismissOnSelect: true,
    });
    await this.chromePopover.present();
    this.chromePopover.onWillDismiss().then(() => {
      this.chromePopover = null;
      this.unwatchUrl();
    });
    this.watchUrl();
  }

  /**
   * Dismiss popover
   */
  async dismissAppPopover() {
    if (this.appPopover) {
      await this.appPopover.dismiss();
    }
  }

  async dismissChromePopover() {
    if (this.chromePopover) {
      await this.chromePopover.dismiss();
    }
  }

  async getDownloadPromptCount() {
    await this.platform.ready();
    if (!this.platform.is('hybrid')) {
      this.downloadPromptCount = await this.storageService.get('download_prompt_count');
      if (!this.downloadPromptCount) {
        this.downloadPromptCount = 0;
      } else {
        this.downloadPromptCount = Number(this.downloadPromptCount);
      }
    }
  }

  updateDownloadPromptCount() {
    if (!this.downloadPromptCount) {
      this.downloadPromptCount = 0;
    }
    this.downloadPromptCount += 1;
    this.storageService.set('download_prompt_count', this.downloadPromptCount);
  }
}
