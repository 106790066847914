
import { GroupEditComponent } from 'src/app/components/group/group-edit/group-edit.component';
import { ReadContactsComponent } from 'src/app/components/general/read-contacts/read-contacts.component';
import { Component, ElementRef, Input, ViewChild, OnDestroy, OnInit, HostListener } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActionSheetController, IonList, ModalController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { UpdateByService } from 'src/app/services/user/update-by.service';
import { ModuleService } from 'src/app/services/account/privilege/module.service';
import { InvitedByService } from 'src/app/services/setting/invited-by.service';
import { SettingFieldService } from 'src/app/services/setting/setting-field.service';
import { GuestService } from 'src/app/services/guest/guest.service';
import { GuestListService } from 'src/app/services/guest/guest-list.service';
import { GuestManageService } from 'src/app/services/guest/guest-manage.service';
import { GuestControlService } from 'src/app/services/guest/guest-control.service';
import { GroupManageService } from 'src/app/services/group/group-manage.service';
import { CheckinSettingService } from 'src/app/services/checkin/checkin-setting.service';
import { PopupService } from 'src/app/services/general/popup.service';
import { TabService } from 'src/app/services/general/tab.service';
import { FunctionService } from 'src/app/services/general/function.service';

import { GuestEditComponent } from 'src/app/components/guest/guest-edit/guest-edit.component';
import { GuestQrcodeComponent } from 'src/app/components/qrcode/guest-qrcode/guest-qrcode.component';
import { SeatingListComponent } from 'src/app/components/seating/seating-list/seating-list.component';

import { SettingField } from 'src/app/interfaces/database';
import { CheckinSetting } from 'src/app/interfaces/setting';
import { Guest } from 'src/app/interfaces/guest';
import { Seating } from 'src/app/interfaces/seating';
import { Group } from 'src/app/interfaces/group';
import { Mobile } from 'src/app/interfaces/general';
import { UpdateBy } from 'src/app/interfaces/user';
import { SettingFieldType, ModuleType } from 'src/app/types/general';
import { GuestImportComponent } from 'src/app/components/guest/guest-import/guest-import.component';
import { SettingFieldComponent } from 'src/app/components/setting/setting-field/setting-field.component';
import { DeviceSafeAreaService } from 'src/app/services/device/device-safe-area.service';
import { KeyboardService } from 'src/app/services/general/keyboard.service';
import { SeatingSettingService } from 'src/app/services/seating/seating-setting.service';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SessionService } from 'src/app/services/setting/session.service';
import { GuestListMode } from 'src/app/types/guest';
import { WebsiteLinkComponent } from '../../website/setting/website-link/website-link.component';
import { GroupService } from 'src/app/services/group/group.service';

/**
 * Guest new component
 */
@Component({
  selector: 'app-guest-new-component',
  templateUrl: './guest-new.component.html',
  styleUrls: ['./guest-new.component.scss'],
})
export class GuestNewComponent implements OnInit, OnDestroy {

  @HostListener('window:resize', ['$event']) onResize(event: any) {
    this.setupContentHeight();
  }
  /**
   * Watch screen orientation change
   */
  @HostListener('window:orientationchange', ['$event']) onOrientationChange(event: any) {
    this.setupContentHeight();
  }
  
  @ViewChild(CdkVirtualScrollViewport) cdkVirtualScrollViewport: CdkVirtualScrollViewport;
  @ViewChild('content', { read: ElementRef, static: false }) contentElement: ElementRef;
  /**
   * Ion list for closing sliding item
   */
  @ViewChild('list') list: IonList;
  /**
   * Header. Calculate content height for page mode.
   */
  @ViewChild('header', { read: ElementRef }) header: ElementRef;
  /**
   * Footer. Calculate content height for page mode.
   */
  @ViewChild('footer', { read: ElementRef }) footer: ElementRef;
  
  @ViewChild('groupDiv', { read: ElementRef }) groupDiv: ElementRef;

  /**
   * Page mode.
   */
  @Input() pageMode: boolean;
  /**
   * Default value
   */
  @Input() defaultValue: any = {};
  /**
   * Seating
   */
  @Input() seating: Seating;
  /**
   * Start / reset component for page mode.
   */
  @Input() set reset(flag: boolean) {
    if (flag) {
      this.initialize(true);
    } else {
      this.unwatch();
    }
  }
  /**
   * Setup guest group for page mode. For adding group member.
   */
  @Input() set setupGroup(group: Group) {
    if (!this.functionService.isEqual(this.group, group)) {
      this.group = { ...group };
      this.defaultGroup = { ...group };
    }
  }

  mode: GuestListMode;

  safeArea: number;

  showTab: boolean;

  showSession: boolean;

  // mobile: Mobile;
  // mobileInvalid: boolean;
  /**
   * Is hybrid platform
   */
   isHybrid: boolean;
  /**
   * Checkin setting
   */
  checkinSetting: CheckinSetting;
  /**
   * Current AccountModule
   */
  module: ModuleType;
  /**
   * Edit mode
   */
  editMode: boolean;
  /**
   * Select mode
   */
  selectMode: boolean;
  /**
   * Show group advance
   */
  showGroupAdvance: boolean;
  /**
   * Guest group
   */
  group: Group;
  /**
   * Default guest group
   */
  defaultGroup: Group;
  /**
   * Show name error
   */
  showNameError: boolean;
  /**
   * Select all
   */
  selectAll: boolean;
  /**
   * Selected guest list
   */
  selectedGuestList: string[];
  /**
   * Selected group list
   */
  selectedGroupList: string[];

  selectedCategory: string;

  /**
   * Add guest step
   */
  step: number;
  /**
   * Setting form
   */
  settingForm: FormGroup;
  /**
   * Detail form
   */
  detailForm: FormGroup;
  /**
   * Validation msg
   */
  validationMsg: any;
  /**
   * New guest list
   */
  newGuestList: Guest[];
  /**
   * Guest list for preview.
   */
  guestListPreview: Guest[];
  /**
   * Invited By list
   */
  invitedByList: SettingField[];

  guestDiffList: {
    [guestId: string]: any;
  };

  seatingTypeName: string;

  guestListHeight: string;
  contentHeight: string;

  dietaryReq: string;
  session: string;

  /**
   * Skip result
   */
  private skipResult: boolean;
  /**
   * Default setting form
   */
  private defaultSettingForm: any;
  /**
   * Default detail form
   */
  private defaultDetailForm: any;
  /**
   * Invited by subscription
   */
  private invitedBySubscription: Subscription;
  /**
   * Checkin setting subscription
   */
  private checkinSettingSubscription: Subscription;

  private guestListSubscription: Subscription;

  /**
   * Constructor
   * @param platform platform
   * @param formBuilder form builder
   * @param actionSheetController action sheet controller
   * @param modalController modal controller
   * @param translate translate service
   * @param moduleService module service
   * @param localityService locality service
   * @param settingFieldService setting field service
   * @param invitedByService invited by service
   * @param guestService guest service
   * @param guestListService guest list service
   * @param guestManageService guest manage service
   * @param checkinSettingService checkin setting service
   * @param groupManageService group maange service
   * @param userService user service
   * @param popupService popup service
   * @param functionService function service
   */
  constructor(
    private platform: Platform,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private actionSheetController: ActionSheetController,
    private modalController: ModalController,
    private translate: TranslateService,
    private moduleService: ModuleService,
    private deviceSafeAreaService: DeviceSafeAreaService,
    private settingFieldService: SettingFieldService,
    private invitedByService: InvitedByService,
    private guestService: GuestService,
    private groupService: GroupService,
    private guestListService: GuestListService,
    private guestManageService: GuestManageService,
    private guestControlService: GuestControlService,
    private seatingSettingService: SeatingSettingService,
    private checkinSettingService: CheckinSettingService,
    private groupManageService: GroupManageService,
    private sessionService: SessionService,
    private updateByService: UpdateByService,
    private popupService: PopupService,
    private functionService: FunctionService,
    private tabService: TabService,
    private keyboardService: KeyboardService,
  ) {
    this.isHybrid = this.platform.is('hybrid');
  }

  ngOnInit() {
    this.watchRoute();
  }

  ngOnDestroy() {
    this.unwatch();
  }

  /**
   * Before view enter
   */
  ionViewWillEnter() {
    this.initialize();
  }

  /**
   * Before view leave
   */
  ionViewWillLeave() {
    this.unwatch();
  }

  /**
   * Watch route
   */
  watchRoute() {
    this.route.params.subscribe((param: Params) => {
      const route = this.router.getCurrentNavigation();
      const state = route?.extras?.state;
      if (param?.type === 'import') {
        this.presentGuestImportModal();
      } else if (state?.type === 'import') {
        this.presentGuestImportModal();
      }
    });
  }

  /**
   * Initailize component
   * @param reset reset flag. reset guest group.
   */
  async initialize(reset?: boolean) {
    this.step = 1;
    this.showTab = true;
    // this.mobile = {
    //   code: 0,
    //   no: ''
    // };
    this.module = this.moduleService.currentModule;
    this.editMode = false;
    this.selectMode = false;
    
    this.showNameError = false;
    this.selectedGuestList = [];
    this.selectedGroupList = [];
    this.newGuestList = [];

    if (reset) {
      this.defaultGroup = null;
      this.group = null;
    }

    if (this.pageMode) {
      this.safeArea = this.deviceSafeAreaService.getSafeArea();
    }

    if (this.sessionService?.list?.length > 1) {
      this.showSession = true;
    } else {
      this.showSession = false;
    }

    this.seatingTypeName = this.getSeatingTypeName();

    this.setupContentHeight();

    this.watchInvitedBy();
    this.watchCheckinSetting();
    this.setupForm();
  }

  setupSettingField() {
    this.dietaryReq = this.getSettingField('dietary_req', this.settingForm?.value?.dietaryReq);
    this.session = this.getSettingField('session', this.settingForm?.value?.session);
  }

  /**
   * Watch invited by list
   */
  async watchInvitedBy() {
    if (!this.invitedBySubscription) {
      this.invitedBySubscription = this.invitedByService.observableInvitedbyList.subscribe(() => {
        this.invitedByList = this.invitedByService.getInvitedByList();
        this.setupInvitedByValidation();
      });
    }
    
  }

  /**
   * Unwatch invited by list
   */
  async unwatchInvitedBy() {
    if (this.invitedBySubscription) {
      this.invitedBySubscription.unsubscribe();
      this.invitedBySubscription = null;
    }
  }

  /**
   * Watch check-in setting
   */
  async watchCheckinSetting() {
    if (!this.checkinSettingSubscription) {
      this.checkinSettingSubscription = this.checkinSettingService.observableCheckinSetting.subscribe((checkinSetting: CheckinSetting) => {
        this.checkinSetting = checkinSetting;
      });
    }
    
  }

  /**
   * Unwatch check-in setting
   */
  async unwatchCheckinSetting() {
    if (this.checkinSettingSubscription) {
      this.checkinSettingSubscription.unsubscribe();
      this.checkinSettingSubscription = null;
    }
  }

  /**
   * Watch guest list
   */
  async watchGuestList() {
    if (!this.guestListSubscription) {
      this.guestListSubscription = this.guestService.observableGuestList.subscribe(() => {
        this.setupGuestListPreview();
      });
    }
    
  }

  /**
   * Unwatch guest list
   */
  async unwatchGuestList() {
    if (this.guestListSubscription) {
      this.guestListSubscription.unsubscribe();
      this.guestListSubscription = null;
    }
  }

  async unwatch() {
    this.unwatchGuestList();
    this.unwatchCheckinSetting();
    this.unwatchInvitedBy();
  }

  setupGuestListPreview() {
    if (this.newGuestList?.length) {
      const guestIdList: string[] = this.newGuestList.map((guest: Guest) => guest?.guestId);
      const guestList = this.guestService.getGuestListById(guestIdList);
      this.guestListPreview = this.guestListService.generateGroupList(guestList);
      this.setupViewport();
    }
  }

  setupViewport() {
    if (this.cdkVirtualScrollViewport) {
      setTimeout(() => {
        this.cdkVirtualScrollViewport?.checkViewportSize();
      }, 200);
    } else {
      setTimeout(() => {
        this.setupViewport();
      }, 200);
    }
  }

  getScreenHeight() {
    return '100vh';
    // if (window?.innerHeight) {
    //   return window.innerHeight.toString() + 'px';
    // } else {
    //   return '100vh';
    // }
  }

  /**
   * Get header height
   */
  getHeaderHeight(safeArea?: boolean) {
    let header = 0;
    if (this.pageMode) {
      if (this.header?.nativeElement?.offsetHeight) {
        header = this.header.nativeElement.offsetHeight;
      }
      if (safeArea) {
        header += this.deviceSafeAreaService.getSafeArea();
      }
    }
    return header;
  }

  /**
   * Get footer height
   */
  getFooterHeight() {
    let footer = 0;
    if (this.pageMode) {
      if (this.footer?.nativeElement?.offsetHeight) {
        footer += this.footer.nativeElement.offsetHeight;
      }
      if (this.tabService.showTab && this.step !== 2) {
        footer += 50;
      }
    }
    return footer;
  }

  getGroupDivHeight() {
    let height = 0;
    if (this.pageMode) {
      if (this.groupDiv?.nativeElement?.offsetHeight) {
        height = this.groupDiv.nativeElement.offsetHeight;
      }
    }
    return height;
  }

  getSeatingTypeName(): string {
    return this.seatingSettingService.getSeatingTypeName();
  }

  /**
   * Setup form
   */
  async setupForm() {
    this.validationMsg = {
      pax: [
        {type: 'required', msg: this.translate.instant('VALIDATION.required', { field: this.translate.instant('GUEST.lbl.pax') }) },
        { type: 'pattern', msg: this.translate.instant('VALIDATION.number_only') },
        {type: 'min', msg: this.translate.instant('VALIDATION.min_number', { number: 1 }) },
        {type: 'max', msg: this.translate.instant('VALIDATION.max_number', { number: 100 }) }
      ],
      name: [{ type: 'required', msg: this.translate.instant('VALIDATION.required', {
        field: this.translate.instant('GUEST.lbl.guest_name') }) }],
      groupName: [{ type: 'required', msg: this.translate.instant('VALIDATION.required', {
        field: this.translate.instant('GROUP.lbl.name') }) }],
      invitedBy: [{ type: 'required', msg: this.translate.instant('VALIDATION.required', {
        field: this.translate.instant('GUEST.lbl.invited_by') }) }],
      category: [{ type: 'required', msg: this.translate.instant('VALIDATION.required', {
        field: this.translate.instant('LBL.category') }) }],
      attendingStatus: [{ type: 'required', msg: this.translate.instant('VALIDATION.required', {
        field: this.translate.instant('LBL.status') }) }],
      dietaryReq: [{ type: 'required', msg: this.translate.instant('VALIDATION.required', {
        field: this.translate.instant('GUEST.lbl.dietary_req') }) }],
      specialReq: [{ type: 'required', msg: this.translate.instant('VALIDATION.required', {
        field: this.translate.instant('GUEST.lbl.special_req') }) }],
      email: [{ type: 'email', msg: this.translate.instant('VALIDATION.invalid_format', {
        field: this.translate.instant('LBL.email') }) }],
    };

    this.setupSettingForm();
    this.setupDetailForm();
    
  }

  /**
   * Setup setting form
   */
  setupSettingForm() {
    // module invites
    this.settingForm = this.formBuilder.group({
      invitedBy: new FormControl('', [ this.invitedByList?.length > 1 ? Validators.required : Validators.nullValidator ]),
      category: new FormControl('', [ Validators.required ]),
      attendingStatus: new FormControl('attending', [ Validators.required ]),
      checkinStatus: new FormControl(this.module === 'checkin' ? true : false),
      giftStatus: new FormControl(false),
      dietaryReq: new FormControl([{ custom: false, value: 'none' }], [ Validators.required ]),
      session: new FormControl([{ custom: false, value: 'none' }], [ Validators.required ])
    });
    this.setupSettingFormDefault(this.defaultValue);
    this.setupInvitedByValidation();
  }

  /**
   * Setup invited by validation
   */
  setupInvitedByValidation() {
    if (this.settingForm?.controls?.invitedBy) {
      if (this.invitedByList?.length > 1) {
        this.settingForm.controls.invitedBy.setValidators([ Validators.required ]);
      } else {
        this.settingForm.controls.invitedBy.clearValidators();
      }
      this.settingForm.controls.invitedBy.updateValueAndValidity();
    }
  }

  /**
   * Setup setting form default value
   */
  setupSettingFormDefault(value: any) {
    if (value) {
      if (value?.invitedBy?.[0]?.value) {
        this.settingForm.controls.invitedBy.setValue(value.invitedBy[0].value);
      }
      if (value.category) {
        this.settingForm.controls.category.setValue(value.category);
      }
      if (value.dietaryReq) {
        this.settingForm.controls.dietaryReq.setValue(value.dietaryReq);
      }
      if (value.session) {
        this.settingForm.controls.session.setValue(value.session);
      }
      if (value.attendingStatus) {
        this.settingForm.controls.attendingStatus.setValue(value.attendingStatus);
      }
      if (value.checkinStatus) {
        this.settingForm.controls.checkinStatus.setValue(value.checkinStatus);
      }
      if (value.giftStatus) {
        this.settingForm.controls.giftStatus.setValue(value.giftStatus);
      }
    }
    this.setupCategory();
    this.setupSettingField();
    
    this.defaultSettingForm = this.settingForm.value;
  }

  setupCategory() {
    if (this.settingForm.value.category?.length) {
      this.selectedCategory = this.getSettingField('category', this.settingForm.value.category);
    } else {
      this.selectedCategory = '';
    }
  }

  /**
   * Setup detail form
   */
  setupDetailForm() {
    this.detailForm = this.formBuilder.group({
      pax: new FormControl(1, [Validators.required, Validators.pattern('^[0-9]*$'), Validators.min(1), Validators.max(100)]),
      group:  new FormControl(this.group ? true : false, [])
    });

    this.setupDetailFormDefault(this.defaultValue);
  }

  /**
   * Setup default value for detail form
   */
  setupDetailFormDefault(value: any) {
    // if (value) {
    //   if (value.email) {
    //     this.detailForm.controls.email.setValue(value.email);
    //   }
    //   if (this.mobile?.no) {
    //     this.detailForm.controls.mobile.setValue(this.mobile.no);
    //   }
    // }
    this.defaultDetailForm = this.detailForm.value;
  }

  // updateEmail() {
  //   if (this.detailForm?.value.email && this.detailForm.controls.email.valid) {
  //     this.updateGuestList();
  //   }
  // }

  // updateMobile(mobile: Mobile) {
  //   this.mobile = mobile;
  //   // if (this.mobile?.no) {
  //   //   this.updateGuestList();
  //   // }
  // }

  // updateMobileInvalid(invalid: boolean) {
  //   this.mobileInvalid = invalid;
  // }

  /**
   * Validate invited by
   * @param invitedBy Invited by
   */
  async validateInvitedBy(invitedBy: string) {
    if (invitedBy && this.invitedByList?.length > 1) {
      const invitedByIndex = this.invitedByList?.findIndex((x: SettingField) => x.value === invitedBy);
      if (invitedByIndex === -1) {
        this.settingForm.controls.invitedBy.setErrors({required: true});
      }
    }
  }

  /**
   * Validate guest name
   */
  validGuestName() {
    if (this.detailForm.value.group) {
      if (!this.group || !this.group.groupName || !this.group.groupName?.toString()?.trim()) {
        return false;
      } else {
        return true;
      }
    } else {
      let flag = true;
      this.newGuestList?.forEach((guest: Guest) => {
        if (!guest.name) {
          flag = false;
          return flag;
        }
      });
      return flag;
    }
  }

  /**
   * Go back to previous step
   */
  goBack() {
    if (this.step === 1) {
      this.discard();
    } else if (this.step === 2) {
      this.step = 1;
      this.updateTab(true);
      this.showNameError = false;
      this.newGuestList = [];
      this.setupContentHeight();
    } else if (this.step === 3) {
      if (this.editMode) {
        this.editMode = false;
      } else if (this.selectMode) {
        this.selectMode = false;
      } else {
        this.close();
      }
    }
  }

  /**
   * Discard changes
   */
  async discard() {
    if (!this.functionService.isEqual(this.settingForm.value, this.defaultSettingForm)
    || (!this.defaultGroup && this.group?.groupName)
    || (this.defaultGroup && !this.functionService.isEqual(this.group, this.defaultGroup))
    || !this.functionService.isEqual(this.detailForm.value, this.defaultDetailForm)
    || this.newGuestList?.length) {
      const actionSheet = await this.actionSheetController.create({
        header: this.translate.instant('MSG.discard_msg'),
        buttons: [{
          text: this.translate.instant('BTN.confirm'),
          role: 'destructive',
          icon: 'trash',
          handler: () => {
            this.close();
          }
        }, {
          text: this.translate.instant('BTN.cancel'),
          icon: 'close',
          role: 'cancel',
          handler: () => {
          }
        }]
      });
      actionSheet.present();
    }  else {
      this.close();
    }
  }

  /**
   * Close
   */
  close() {
    if (this.pageMode) {
      this.initialize(true);
    } else {
      this.dismissModal();
    }
  }

  /**
   * Add pax
   */
  addPax() {
    if (!this.exceedMaxGuest()) {
      if (this.detailForm.value.pax < 100) {
        this.detailForm.controls.pax.setValue(this.detailForm.value.pax + 1);
        this.setupGuestList();
      } else {
        this.popupService.presentToast(this.translate.instant('VALIDATION.max_number', { number: 100 }), 'warning');
      }
    }
  }

  /**
   * Minus pax
   */
  minusPax() {
    if (this.detailForm.value.pax > 1) {
      this.detailForm.controls.pax.setValue(this.detailForm.value.pax - 1);
      this.setupGuestList();
      
    } else {
      this.popupService.presentToast(this.translate.instant('VALIDATION.min_number', { number: 1 }), 'warning');
    }
  }

  /**
   * Validate max pax
   */
  exceedMaxGuest(): boolean {
    if (this.guestControlService.exceedMaxGuest(this.detailForm.value.pax)) {
      return true;
    }
    return false;
  }

  /**
   * Dismiss guest new modal
   */
  async dismissModal(selected?: boolean) {
    this.updateTab(true);
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) {
        const data: any = {};
        if (selected) {
          if (this.detailForm.value.group && this.group?.groupId) {
            data.groupList = [ this.group.groupId ];
          }
          data.guestList = this.newGuestList?.length ? this.newGuestList.map((guest: Guest) => {
            return guest.guestId;
          }) : [];
        }
        this.modalController.dismiss(data);
      }
    }
  }

  /**
   * Present guest import modal
   */
  async presentGuestImportModal() {
    const modal = await this.modalController.create({
      component: GuestImportComponent,
      cssClass: 'modal-full-screen-bk',
      componentProps: {}
    });
    modal.present();
  }

  /**
   * Present setting field cmodal
   * @param settingFieldType Setting field type
   * @param selected Selected setting field
   */
  async presentSettingFieldModal(settingFieldType: SettingFieldType, selected: SettingField[]) {
    const modal = await this.modalController.create({
      component: SettingFieldComponent,
      componentProps: {
        settingFieldType,
        selected
      }
    });
    modal.present();
    modal.onWillDismiss().then((result: any) => {
      if (result?.data?.selected) {
        if (settingFieldType === 'category') {
          if (this.settingForm.value.category !== result.data.selected) {
            this.settingForm.controls.category.setValue(result.data.selected);
            this.setupCategory();
          }
        } else if (settingFieldType === 'dietary_req') {
          if (this.settingForm.value.dietaryReq !== result.data.selected) {
            this.settingForm.controls.dietaryReq.setValue(result.data.selected);
            this.setupSettingField();
          }
        } else if (settingFieldType === 'session') {
          if (this.settingForm.value.session !== result.data.selected) {
            this.settingForm.controls.session.setValue(result.data.selected);
            this.setupSettingField();
          }
        }
      }
    });
  }

  async presentGroupEditModal() {
    const modal = await this.modalController.create({
      component: GroupEditComponent,
      componentProps: {
        newGroup: true,
        group: this.group
      }
    });
    modal.present();
    modal.onWillDismiss().then((result: any) => {
      if (result?.data?.group) {
        this.group = result.data.group;
      }
      this.setupContentHeight();
    });
  }

  /**
   * Present guest edit modal
   */
  async presentGuestEditModal(guestData: Guest, index: number) {
    if (guestData) {
      const guest = { ...guestData };
      if (!guest.name && this.detailForm.value.group && this.group?.groupName) {
        guest.name = this.group.groupName;
      }
      const modal = await this.modalController.create({
        component: GuestEditComponent,
        componentProps: {
          newGuest: true,
          guest
        }
      });
      modal.present();
      modal.onWillDismiss().then((result: any) => {
        if (result?.data?.guest) {
          if (index >= 0 && this.newGuestList[index]) {
            this.newGuestList[index] = result.data.guest;
            this.newGuestList = [ ...this.newGuestList ];
            this.setupGuestDiff();
            this.setupViewport();
          }
        }
      });
    } else {
      this.popupService.presentActionError();
    }
  }

  /**
   * Present guest qrcode modal
   * @param guest guest
   * @param group guest group
   */
  async presentGuestQrcodeModal(guest?: Guest, group?: Group) {
    if (guest || group) {
      const modal = await this.modalController.create({
        component: GuestQrcodeComponent,
        cssClass: '',
        componentProps: {
          guest,
          group
        }
      });
      modal.present();
      modal.onWillDismiss().then((result: any) => {
        if (result?.data?.done) {
          this.selectMode = false;
          this.selectedGuestList = [];
        }
      });
    } else {
      this.popupService.presentActionError();
    }
  }

  async presentWebsiteLinkModal(guest?: Guest, group?: Group) {
    let websiteUrl = '';
    if (group?.groupId) {
      websiteUrl = this.groupService.getGroupUrl(group.groupId, group?.rsvp?.linkId);
    } else if (guest?.guestId) {
      websiteUrl = this.guestService.getGuestUrl(guest?.guestId, guest?.rsvp?.linkId);
    }
    if (websiteUrl) {
      const modal = await this.modalController.create({
        component: WebsiteLinkComponent,
        cssClass: '',
        componentProps: {
          websiteUrl,
        }
      });
      modal.present();
      modal.onWillDismiss().then((result: any) => {
        if (result?.data?.done) {
          this.selectMode = false;
          this.selectedGuestList = [];
        }
      });
    }
  }

  /**
   * Present seating list modal
   * @param newGuestList new guest list
   */
  async presentSeatingListModal(newGuestList: Guest[]) {
    if (newGuestList?.length) {
      const newGroupList: Group[] = this.group?.groupId ? [ this.group ] : [];
      const modal = await this.modalController.create({
        component: SeatingListComponent,
        cssClass: '',
        componentProps: {
          assignMode: true,
          newGuestList,
          newGroupList
        }
      });
      modal.present();
      modal.onWillDismiss().then((result: any) => {
        if (result?.data?.done) {
          this.editMode = false;
          this.selectedGuestList = [];
          this.selectedGroupList = [];
          this.selectAll = false;
        }
      });
    } else {
      this.popupService.presentActionError();
    }
  }

  async presentReadContactsModal(index: number, group?: boolean) {
    const modal = await this.modalController.create({
      component: ReadContactsComponent,
      cssClass: '',
      componentProps: {
      }
    });
    modal.present();
    modal.onWillDismiss().then((result: any) => {
      if (group) {
        if (result?.data?.name) {
          this.group.groupName = result.data.name;
        }
        if (result?.data?.mobile?.no) {
          this.group.mobile = result.data.mobile;
          // this.updateMobile(result.data.mobile);
        }
        if (result?.data?.email && this.functionService.validateEmail(result.data.email)) {
          this.group.email = result.data.email;
          // this.detailForm.controls.email.setValue(result.data.email);
          // this.updateEmail();
        }
      } else {
        if (this.newGuestList?.[index]) {
          if (result?.data?.name) {
            this.newGuestList[index].name = result.data.name;
          }
          if (result?.data?.mobile?.no) {
            this.newGuestList[index].mobile = result.data.mobile;
          }
          if (result?.data?.email && this.functionService.validateEmail(result.data.email)) {
            this.newGuestList[index].email = result.data.email;
          }
        }
      }
    });
  }

  readContactsForGuest(index: number) {
    if (this.newGuestList?.[index]) {
      this.presentReadContactsModal(index);
    }
  }

  readContactsForGroup() {
    if (this.group) {
      this.presentReadContactsModal(null, true);
    }
  }

  /**
   * Prompt assign guest seating
   * @param guest guest
   */
  async promptAssign(guest?: Guest) {
    if (guest) {
      this.presentSeatingListModal([ guest ]);
    } else if (this.editMode) {
      if (this.selectedGuestList?.length) {
        const guestList: Guest[] = [];
        this.selectedGuestList?.forEach((guestId: string) => {
          if (guestId) {
            const data = this.guestService.getGuest(guestId);
            if (data) {
              guestList.push(data);
            }
          }
        });
        this.presentSeatingListModal(guestList);
      } else {
        this.popupService.presentAlert(this.translate.instant('VALIDATION.select_field', {
          field: this.translate.instant('GUEST.lbl.guest') }));
      }
    } else {
      this.popupService.presentAlert(this.translate.instant('VALIDATION.select_field', {
        field: this.translate.instant('GUEST.lbl.guest') }));
    }
  }


  /**
   * Send QR code
   */
  sendQrcode() {
    if (this.newGuestList.length) {
      if (this.newGuestList.length > 1) {
        this.activateSelectMode();
      } else {
        if (this.guestListPreview?.[0]?.group?.groupId) {
          this.presentGuestQrcodeModal(null, this.guestListPreview[0].group);
        } else {
          this.presentGuestQrcodeModal(this.guestListPreview?.[0], null);
        }
      }
    }
  }

  sendPrivateLink() {
    this.presentWebsiteLinkModal(this.newGuestList?.length === 1 ? this.newGuestList[0] : null, this.group);
  }

  /**
   * Assign seating
   */
  assignSeating() {
    if (this.newGuestList.length) {
      if (this.newGuestList.length > 1) {
        this.activateEditMode();
      } else {
        this.presentSeatingListModal(this.newGuestList);
      }
    }
  }

  /**
   * Activate select mode
   */
  activateSelectMode() {
    this.editMode = false;
    this.selectMode = true;
    this.closeSliding();
  }

  /**
   * Activate edit mode
   */
  activateEditMode() {
    this.selectMode = false;
    this.editMode = true;
    this.closeSliding();
  }

  /**
   * Trigger guest advance
   * @param index new guest index
   */
  triggerAdvanceGuest(index: number) {
    if (this.newGuestList?.[index]) {
      this.presentGuestEditModal(this.newGuestList[index], index);
    }
  }

  triggerAdvanceGroup() {
    this.presentGroupEditModal();
  }

  /**
   * Toggle group status
   */
  toggleGroup() {
    if (this.detailForm.value.group) {
      if (!this.group) {
        this.group = {
          groupId: '',
          groupName: '',
          status: {
            checkin: this.settingForm.value.checkinStatus,
            gift: this.settingForm.value.giftStatus,
            deleted: false,
            rsvp: false,
            qrcode: false
          },
          memberList: []
        };
      }
    } else {
      this.group = {
        groupId: '',
        groupName: '',
        memberList: []
      };
    }
    setTimeout(() => {
      this.setupContentHeight();
    }, 100);
    
  }

  /**
   * Toggle select all
   */
  toggleSelectAll() {
    this.selectAll = !this.selectAll;
    this.selectedGuestList = [];
    this.selectedGroupList = [];
    if (this.selectAll) {
      this.guestListPreview?.forEach((guest: Guest) => {
        if (guest?.group?.groupId) {
          this.setSelectedGroup(guest.group.groupId, true);
        } else if (guest?.guestId) {
          this.selectedGuestList.push(guest.guestId);
        }
      });
    }
  }

  /**
   * Check select all
   */
  checkSelectAll() {
    if (this.selectedGuestList.length === this.newGuestList.length) {
      this.selectAll = true;
    } else {
      this.selectAll = false;
    }
  }

  /**
   * Set selected guest
   * @param guestId guest id
   */
  setSelectedGuest(guestId: string) {
    const index = this.selectedGuestList?.indexOf(guestId);
    if (index === -1) {
      this.selectedGuestList.push(guestId);
    } else {
      this.selectedGuestList?.splice(index, 1);
    }
    this.checkSelectAll();
  }

  /**
   * Set selected group
   * @param groupId group id
   * @param selected selected
   * @param skipCheck skip check
   */
  setSelectedGroup(groupId: string, selected?: boolean, skipCheck?: boolean) {
    const index = this.selectedGroupList?.indexOf(groupId);
    if (index === -1) {
      if (this.functionService.isUndefined(selected) || selected === true) {
        this.selectedGroupList.push(groupId);
        if (!skipCheck) {
          this.checkSelectedGroup(groupId, true, this.functionService.isUndefined(selected) ? false : true);
        }
      }
    } else {
      if (this.functionService.isUndefined(selected) || selected === false) {
        if (this.selectedGroupList?.length) {
          this.selectedGroupList?.splice(index, 1);
        }
        if (!skipCheck) {
          this.checkSelectedGroup(groupId, false, this.functionService.isUndefined(selected) ? false : true);
        }
      }
    }
  }

  /**
   * Check selected group
   * @param groupId group id
   * @param selected selected
   * @param skipCheck skip check
   */
  checkSelectedGroup(groupId: string, selected?: boolean, skipCheck?: boolean) {
    const index = this.guestListPreview?.findIndex((x) => x?.group?.groupId === groupId);
    let memberList: string[] = [];
    if (index !== -1 && this.guestListPreview?.[index]?.group?.memberList) {
      memberList = this.guestListPreview[index].group.memberList;
    }

    if (memberList) {
      let flag = true;
      memberList?.forEach((guestId: string) => {
        const memberIndex = this.selectedGuestList?.indexOf(guestId);
        if (memberIndex === -1) {
          flag = false;
          if (!this.functionService.isUndefined(selected) && selected === true) {
            this.selectedGuestList.push(guestId);
          }
        } else {
          if (!this.functionService.isUndefined(selected) && selected === false) {
            this.selectedGuestList?.splice(memberIndex, 1);
          }
        }
      });
      if (this.functionService.isUndefined(selected)) {
        this.setSelectedGroup(groupId, flag, true);
      }
    }

    if (!skipCheck) {
      this.checkSelectAll();
    }
  }

  /**
   * Get guest
   * @param guestId guest id
   */
  getGuest(guestId: string): Guest {
    return this.guestService.getGuest(guestId);
  }

  /**
   * Get list of setting field value
   * @param settingFieldType Setting field type
   * @param settingFieldList Setting field list
   * @returns Join of setting field value
   */
  getSettingField(settingFieldType: SettingFieldType, settingFieldList: SettingField[]): string {
    return this.settingFieldService.joinSettingField(settingFieldType, settingFieldList);
  }

  /**
   * Get guest difference than standard setting
   * @param guest guest
   */
  getGuestDiff(guest: Guest) {
    const result: any = {};
    if (this.settingForm.value.invitedBy && !this.functionService.isEqual(guest?.invitedBy?.[0]?.value, this.settingForm.value.invitedBy)) {
      result.invitedBy = guest.invitedBy;
    }
    if (!this.functionService.isEqual(guest.category, this.settingForm.value.category)) {
      result.category = guest.category;
    }

    if (!this.functionService.isEqual(guest.status.attending, this.settingForm.value.attendingStatus)) {
      result.attendingStatus = guest.status.attending;
    }

    if (!this.functionService.isEqual(guest.status.checkin, this.settingForm.value.checkinStatus)) {
      result.checkinStatus = guest.status.checkin;
    }

    if (!this.functionService.isEqual(guest.status.gift, this.settingForm.value.giftStatus)) {
      result.giftStatus = guest.status.gift;
    }

    if (!this.functionService.isEqual(guest?.dietaryReq, this.settingForm.value?.dietaryReq)) {
      result.dietaryReq = guest.dietaryReq;
    }

    if (!this.functionService.isEqual(guest.session, this.settingForm.value.session)) {
      result.session = guest.session;
    }

    if (!this.functionService.isEqual(guest.specialReq, [{ custom: false, value: 'none' }])) {
      result.specialReq = guest.specialReq;
    }

    if (guest.email && guest.email !== this.group?.email) {
      result.email = guest.email;
    }

    if (guest?.mobile?.no && guest.mobile.no !== this.group?.mobile?.no) {
      result.mobile = guest.mobile;
    }

    if (guest.nickname) {
      result.nickname = guest.nickname;
    }

    return !this.functionService.isEmpty(result) ? result : null;
  }

  getContentHeight(): number {
    return this.contentElement?.nativeElement?.offsetHeight ? this.contentElement.nativeElement.offsetHeight : 0;
  }

  async setupContentHeight() {
    await this.functionService.delay(300);
    if (this.pageMode) {
      if (this.header?.nativeElement?.offsetHeight) {
        this.guestListHeight ='calc(100vh - ' + (this.getHeaderHeight(true) + this.getFooterHeight() + this.getGroupDivHeight()) + 'px)';
        this.contentHeight = 'calc(100vh - ' + (this.getHeaderHeight(true) + this.getFooterHeight()) + 'px)';
      } else {
        this.setupContentHeight();
      }
    } else {
      this.guestListHeight = this.getContentHeight() + 'px';
      this.contentHeight = this.getContentHeight() + 'px';
    }
  }

  setupGuestDiff() {
    if (!this.guestDiffList) {
      this.guestDiffList = {};
    }
    this.newGuestList.forEach((x: Guest) => {
      if (x?.guestId) {
        this.guestDiffList[x.guestId] = this.getGuestDiff(x);
      }
    });
  }

  /**
   * Setup guest list
   */
  setupGuestList() {
    if ((this.detailForm.value.pax <= 1) && !this.defaultGroup) {
      this.detailForm.controls.group.setValue(false);
    }
    if (!this.exceedMaxGuest() && this.detailForm.value.pax <= 100) {
      this.generateGuestList();
    }
  }

  /**
   * Generate guest list
   */
  generateGuestList() {
    if (this.newGuestList.length < this.detailForm.value.pax) {
      const count = this.detailForm.value.pax - this.newGuestList.length;
      for (let i = 0; i < count; i++) {
        const mobile: Mobile = {
          code: 0,
          country: '',
          no: '',
        };

        let invitedBy: SettingField = {
          custom: false,
          value: ''
        };

        if (this.settingForm.value.invitedBy) {
          const invitedByIndex = this.invitedByList?.findIndex((x: SettingField) => x.value === this.settingForm.value.invitedBy);
          if (invitedByIndex !== -1) {
            invitedBy = this.invitedByList[invitedByIndex];
          }
        } else if (this.invitedByList?.length === 1) {
          invitedBy = this.invitedByList[0];
        }

        // if (this.detailForm.value.group && this.mobile?.no && !this.mobileInvalid) {
        //   mobile = this.mobile;
        // } else
        // if (this.defaultValue.mobile && !this.detailForm.value.group) {
        //   mobile = this.defaultValue.mobile;
        // }

        this.newGuestList.push({
          guestId: this.guestManageService.guestId,
          name: '',
          nickname: '',
          invitedBy: [ invitedBy ],
          category: this.settingForm.value.category,
          dietaryReq: this.settingForm.value.dietaryReq,
          session: this.settingForm.value.session,
          specialReq: [{ custom: false, value: 'none' }],
          groupId: '',
          seating: this.defaultValue.seating ? this.defaultValue.seating : '',
          mobile,
          email: '',
          remark: '',
          giftList: [],
          status: {
            attending: this.settingForm.value.attendingStatus,
            checkin: this.settingForm.value.checkinStatus,
            gift: this.settingForm.value.giftStatus,
            deleted: false,
            rsvp: false,
            qrcode: false
          }
        });
      }
    } else if (this.newGuestList.length > this.detailForm.value.pax) {
      const count = this.newGuestList.length - this.detailForm.value.pax;
      for (let i = 0; i < count; i++) {
        this.newGuestList?.splice(this.newGuestList.length - 1, 1);
      }
    }
    this.setupGuestDiff();
    this.newGuestList = [ ...this.newGuestList ];
    this.setupContentHeight();
  }

  // updateGuestList() {
  //   this.newGuestList.forEach((guest: Guest) => {
  //     if (this.mobile?.no && !guest?.mobile?.no && !this.detailForm.value.group) {
  //       guest.mobile = this.mobile;
  //     }
  //     if (this.detailForm?.value?.email && !guest?.email && !this.detailForm.value.group) {
  //       guest.email = this.detailForm.value.email;
  //     }
  //   });
  // }

  /**
   * Setting form submit
   */
  async settingFormSubmit() {
    this.settingForm.markAllAsTouched();
    await this.validateInvitedBy(this.settingForm.value.invitedBy);
    if (this.settingForm.valid && !this.exceedMaxGuest()) {
      this.showNameError = false;
      this.setupGuestList();
      this.step = 2;
      this.updateTab(false);

      this.setupContentHeight();
    }
  }

  /**
   * Detail form submit
   */
  async detailFormSubmit() {
    this.detailForm.markAllAsTouched();
    this.showNameError = true;
    if (this.detailForm.valid && this.validGuestName()) {
      await this.popupService.presentLoading();
      const updateBy: UpdateBy = this.updateByService.updateBy;

      const group: Group = this.functionService.cloneDeep(this.group);
      if (group?.groupName) {
        group.groupName = group.groupName?.toString()?.trim();
        // group.mobile = this.mobile;
        // group.email = this.detailForm.value.email;
        if (!group.groupId) {
          group.groupId = this.guestManageService.groupId;
        }
      }

      if (this.detailForm.value.pax) {
        this.newGuestList?.forEach((guest: Guest) => {
          if (!guest?.guestId) {
            guest.guestId = this.guestManageService.guestId;
          }
          
          if (group?.groupName) {
            if (!guest.name) {
              guest.name = group.groupName;
            }
            if (guest.groupId !== group.groupId) {
              guest.groupId = group.groupId;
            }
          }
          guest.createBy = updateBy;
          guest.updateBy = updateBy;
          if (this.settingForm.value.checkinStatus) {
            guest.checkinBy = updateBy;
          }
          if (!this.detailForm.value.group && this.settingForm.value.giftStatus) {
            guest.giftBy = updateBy;
          }
        });

        const guestIdList: string[] = this.newGuestList.map((guest: Guest) => {
          return guest?.guestId;
        });
        if (this.detailForm.value.group && group?.groupId) {
          if (!group.createBy) { group.createBy = updateBy; }
          group.updateBy = updateBy;
          if (this.settingForm.value.checkinStatus) {
            group.checkinBy = updateBy;
          }
          if (this.settingForm.value.giftStatus) {
            group.giftBy = updateBy;
          }
          await this.groupManageService.saveGroupList([ group.groupId ], group, [ group ]);
          if (!this.defaultGroup?.groupId && this.defaultGroup?.memberList?.length) {
            await this.guestManageService.saveGuest({ groupId: group.groupId }, this.defaultGroup?.memberList, 'edit', [], true);
          }
          this.group.groupId = group.groupId;
        }
        await this.guestManageService.saveGuest(null, guestIdList, 'new', this.newGuestList, true);
      }
      if (this.skipResult) {
        await this.popupService.dismissLoading();
        this.dismissModal(true);
        this.popupService.presentToast(this.translate.instant('MSG.action_done'), 'success');
      } else {
        this.watchGuestList();
        this.setupGuestListPreview();

        this.editMode = false;
        this.selectMode = false;
        this.step = 3;
        
        this.updateTab(true);
        this.setupContentHeight();
   
        this.popupService.dismissLoading();
        this.popupService.presentToast(this.translate.instant('MSG.action_done'), 'success');
      }
    }
  }

  /**
   * Calculate item height for virtual scroll
   * @param item Item
   */
  itemHeightFn(item: any) {
    const margin = 10;
    const guestItem = 100;
    const groupItem = 80;

    if (item?.group?.memberList?.length) {
      return (item.group.memberList.length * guestItem) + groupItem + margin;
    }
    return guestItem + margin;
  }

  /**
   * Track item by guest id / group id or index for virtual scroll
   * @param index Index
   * @param item item
   */
  trackByFn(index: number, item: Guest) {
    if (item?.guestId) {
      return item.guestId;
    } else if (item?.group?.groupId) {
      return item.group.groupId;
    }
    return index;
  }

  /**
   * Close sliding
   */
  closeSliding() {
    if (this.list) {
      this.list?.closeSlidingItems();
    }
  }

  /**
   * Set focus
   * @param event event
   * @param element element
   * @param index index
   */
  setFocus(event: any, index: number) {
    if (event && event.key === 'Enter') {
      // event.preventDefault();
      const element = document.getElementById('input-' + index);
      if (element) {
        element.focus();
      }
    }
  }

  updateTab(showTab: boolean) {
    if (this.pageMode) {
      this.showTab = showTab;
      this.tabService.updateShowTab(showTab);
    }
    
  }
}
