import { TemplateVariableType } from '../types/template';

export const TemplateVariable: TemplateVariableType[] = [
    'guest_name',
    'guest_alternate_name',
    'guest_seating',
    'number_of_guest',
    'guest_session',
    'guest_url',
    'group_name',
    'seating_type',
    'wedding_title',
    'wedding_date',
    'wedding_start_time',
    'wedding_timezone',
    'wedding_venue_name',
    'wedding_venue_hall',
    'wedding_venue_address',
    'couple1',
    'couple2',
    'organizer'
];

export const TemplateVariableWedding: TemplateVariableType[] = [
    'guest_name',
    'guest_alternate_name',
    'guest_seating',
    'number_of_guest',
    'guest_session',
    'guest_url',
    'group_name',
    'seating_type',
    'wedding_title',
    'wedding_date',
    'wedding_start_time',
    'wedding_end_time',
    'wedding_timezone',
    'wedding_venue_name',
    'wedding_venue_hall',
    'wedding_venue_address',
    'couple1',
    'couple2',
];

export const TemplateVariableEvent: TemplateVariableType[] = [
    'guest_name',
    'guest_alternate_name',
    'guest_seating',
    'number_of_guest',
    'guest_session',
    'guest_url',
    'group_name',
    'seating_type',
    'wedding_title',
    'wedding_date',
    'wedding_start_time',
    'wedding_end_time',
    'wedding_timezone',
    'wedding_venue_name',
    'wedding_venue_hall',
    'wedding_venue_address',
    'organizer'
];