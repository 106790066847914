<ion-header>
  <ion-toolbar color="light">
    <ion-buttons slot="start">
      <ion-button (click)="dismissModal()">
        <ion-icon class="back-icon" src="./assets/wedding/icon/general/chevron-back.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <app-offline-indicator></app-offline-indicator>
      {{ (blastCredit?.type === 'refund' ? 'REFUND.lbl.detail' : 'BLAST.credit.topup_detail') | translate }}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content color="light">
  <ion-item lines="none">
    <ion-label>
      <div>
        <ion-item class="full-width ion-item-no-padding" lines="none">
          <ion-label class="ion-no-margin">
            <div class="font-14">{{ blastCredit.blastCredit }} {{ 'BLAST.credit.title' | translate }}</div>
            <ng-container *ngIf="blastCredit?.type !== 'refund'">
              <div class="font-10 ion-text-wrap" [innerHTML]="'BLAST.credit.est_msg_count' | translate: { smsCount: smsCount | number, whatsappCount: whatsappCount | number, country: countryName }"></div>
            </ng-container>
          </ion-label>
        </ion-item>
      </div>
      <div>
        <ion-item class="full-width ion-item-no-padding" lines="none">
          <ion-label class="font-14">
            {{ (blastCredit?.type === 'refund' ? 'REFUND.lbl.refund_amount' : 'PAYMENT.lbl.payment') | translate }}
          </ion-label>
          <ion-note slot="end">
            <ion-text class="font-14 ion-text-wrap" *ngIf="blastCredit?.price?.currency">
              {{ blastCredit.price.amount | currency: blastCredit.price.currency: 'symbol' }}
            </ion-text>
          </ion-note>
        </ion-item>
      </div>
    </ion-label>
  </ion-item>

  <ion-item class="payment-item" lines="none" *ngIf="blastCredit?.type === 'refund' && refundTransaction?.reason?.value">
    <ion-label class="font-14">
      {{ 'REFUND.lbl.refund_reason' | translate }}
    </ion-label>
    <ion-note slot="end">
      <ion-text class="font-14 ion-text-wrap">
        <ng-container *ngIf="refundTransaction?.reason?.custom">
          {{ refundTransaction.reason.value }}
        </ng-container>
        <ng-container *ngIf="!refundTransaction?.reason?.custom">
          {{ 'REFUND.reason.' + refundTransaction.reason.value | translate }}
        </ng-container>
      </ion-text>
    </ion-note>
  </ion-item>

  <ion-item class="payment-item" lines="none">
    <ion-label class="font-14">
      {{ (blastCredit?.type === 'refund' ? 'REFUND.lbl.refund_method' : 'RECEIPT.pdf.lbl.method') | translate }}
    </ion-label>
    <ion-note slot="end">
      <ng-container *ngIf="(blastCredit?.type === 'free' || blastCredit?.type === 'redeem') else transactionPaymentMethod">
        <ng-container *ngIf="blastCredit?.type === 'free'">
          {{ 'PAYMENT.type.free' | translate }}
        </ng-container>
        <ng-container *ngIf="blastCredit?.type === 'redeem'">
          {{ 'PAYMENT.type.redemption' | translate }}
        </ng-container>
      </ng-container>
      <ng-template #transactionPaymentMethod>
        <ion-text class="font-14 ion-text-wrap" *ngIf="transaction?.paymentRef?.method else onlinePayment">
          {{ getPaymentMethod(transaction.paymentRef) }}
        </ion-text>
      </ng-template>
      <ng-template #onlinePayment>
        <ng-container *ngIf="blastCredit?.price?.amount else blank">
          {{ 'PAYMENT.title.payment' | translate }}
        </ng-container>
      </ng-template>
    </ion-note>
  </ion-item>
  

  <ion-item class="payment-item" lines="none">
    <ion-label class="font-14">
      {{ (blastCredit?.type === 'refund' ? 'REFUND.trigger.by' : 'PAYMENT.lbl.paid_by') | translate }}
    </ion-label>
    <ion-note slot="end">
      <ion-text class="font-14 ion-text-wrap">
        <ng-container *ngIf="blastCredit?.type === 'refund' && refundTransaction?.triggerBy else createByBlock">
          <ng-container *ngIf="refundTransaction.triggerBy === 'user'">
            <ng-container *ngIf="userNameList?.[blastCredit?.createBy?.uid] as username else userBlock">
              {{ username }}
            </ng-container>
            <ng-template #userBlock>
              {{ 'REFUND.trigger.' + refundTransaction.triggerBy | translate }}
            </ng-template>
          </ng-container>
          <ng-container *ngIf="refundTransaction.triggerBy !== 'user'">
            {{ 'REFUND.trigger.' + refundTransaction.triggerBy | translate }}
          </ng-container>
        </ng-container>
        <ng-template #createByBlock>
          <ng-container *ngIf="blastCredit?.createBy?.uid && userNameList?.[blastCredit?.createBy?.uid] as username else blank">
            {{ username }}
          </ng-container>
        </ng-template>
      </ion-text>
    </ion-note>
  </ion-item>

  <ion-item class="payment-item" lines="none">
    <ion-label class="font-14">
      {{ (blastCredit?.type === 'refund' ? 'REFUND.lbl.refund_on' : 'PAYMENT.lbl.paid_on') | translate }}
    </ion-label>
    <ion-note slot="end" *ngIf="blastCredit?.createBy?.time">
      <ion-text class="font-14 ion-text-wrap">{{ format(blastCredit?.createBy?.time?.seconds * 1000) }}</ion-text>
    </ion-note>
  </ion-item>
</ion-content>
<ion-footer *ngIf="blastCredit?.transactionId && blastCredit?.createBy?.uid === currentUid && transaction?.transactionId">
  <ion-toolbar>
    <ion-button class="ion-margin" expand="block" (click)="presentUserReceiptModal(blastCredit.transactionId)">
      {{ 'RECEIPT.title' | translate }}
    </ion-button>
  </ion-toolbar>
</ion-footer>

<ng-template #blank>
  -
</ng-template>