<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="back()">
        <ion-icon color="dark" class="back-icon" src="./assets/wedding/icon/general/chevron-back.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      {{ 'WEBSITE.link.btn.predefined.title' | translate }}
      <!-- <ng-container *ngIf="shareMode">
        {{ 'WEBSITE.link.title.share' | translate }}
      </ng-container>
      <ng-container *ngIf="!shareMode">
        {{ 'WEBSITE.link.title.' + (websiteLink?.linkId ? 'edit' : 'new') | translate }}
      </ng-container> -->
    </ion-title>
    <!-- <ion-buttons slot="end" *ngIf="websiteForm?.enable && websiteLink?.linkId">
      <ng-container *ngIf="shareMode">
        <ion-button (click)="shareMode = false;">
          <ion-icon slot="icon-only" color="success" src="./assets/wedding/icon/general/edit.svg"></ion-icon>
        </ion-button>
      </ng-container>
      <ng-container *ngIf="!shareMode">
        <ion-button (click)="save()" [disabled]="!form?.valid">
          <ion-icon slot="icon-only" color="success" src="./assets/wedding/icon/general/checkmark-circle.svg"></ion-icon>
        </ion-button>
      </ng-container>
    </ion-buttons> -->
  </ion-toolbar>
  <ion-toolbar *ngIf="privilege">
    <ion-segment class="full-width" scrollable [(ngModel)]="type">
      <ion-segment-button [value]="'new'">
        <ion-label>{{ 'WEBSITE.link.lbl.new' | translate }}</ion-label>
      </ion-segment-button>
      <ion-segment-button [value]="'generated'">
        <ion-label>{{ 'WEBSITE.link.lbl.generated' | translate }}</ion-label>
      </ion-segment-button>
    </ion-segment>
  </ion-toolbar>
</ion-header>
<ion-content color="light">
  <ng-container *ngIf="type === 'new'">
    <ng-container *ngIf="websiteForm?.enable">
      <form class="ion-padding-horizontal" [formGroup]="form" *ngIf="form">
        

        <ion-item class="ion-item-no-padding background-transparent" lines="none">
          <ion-label position="stacked" color="dark">
            {{ 'WEBSITE.link.lbl.name' | translate }}&nbsp;<ion-text color="danger">*</ion-text>
            <span class="font-underline ion-float-right" (click)="resetName()">{{ 'BTN.reset' | translate }}</span>
          </ion-label>
          
          <ion-item class="website-input-item" lines="none">
            <div class="font-12 full-width">
              <ion-input formControlName="name" type="text" inputmode="text"></ion-input>
            </div>
          </ion-item>
          <div class="error-div">
            <ng-container *ngFor="let validation of validationMsg?.name">
              <div class="error-message" *ngIf="form?.get('name').hasError(validation.type) && (form?.get('name').dirty || form?.get('name').touched)">
                <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ validation.msg }}
              </div>
            </ng-container>
          </div>
        </ion-item>

        <ion-item class="website-input-item" lines="none">
          <ion-toggle class="line-100" formControlName="invitedByEnable" (ionChange)="toggleInvitedBy()" color="success">
            <ion-text class="font-12 line-100 ion-text-wrap" color="dark">
              <span *ngIf="form?.value?.invitedByEnable" [innerHTML]="'WEBSITE.link.invitedBy.on' | translate"></span>
              <span *ngIf="!form?.value?.invitedByEnable" [innerHTML]="'WEBSITE.link.invitedBy.off' | translate: { settingField: ('LIST.invited_by.' + settingFieldOnlineReg.value) | translate}"></span>
            </ion-text>
          </ion-toggle>
        </ion-item>
        <div class="error-div">
        </div>

        <ion-item class="ion-item-no-padding background-transparent" lines="none" (click)="presentSettingFieldModal('invited_by', form?.value?.invitedBy)" *ngIf="form?.value?.invitedByEnable">
          <ion-label position="stacked" color="dark">{{ 'GUEST.lbl.invited_by' | translate }}&nbsp; <ion-text color="danger">*</ion-text></ion-label>
          <ion-item class="website-input-item" lines="none">
            <ion-label class="font-12 ion-no-margin" >{{ getSettingField('invited_by', form.value.invitedBy) }}</ion-label>
            <ion-note slot="end"><ion-icon src="./assets/wedding/icon/general/chevron-down.svg" class="font-18"></ion-icon></ion-note>
          </ion-item>
          <div class="error-div">
            <ng-container *ngFor="let validation of validationMsg?.invitedBy">
              <div class="error-message" *ngIf="form?.get('invitedBy').hasError(validation.type) && (form?.get('invitedBy').dirty || form?.get('invitedBy').touched)">
                <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ validation.msg }}
              </div>
            </ng-container>
          </div>
        </ion-item>

        <ion-item class="website-input-item" lines="none">
          <ion-toggle class="line-100" formControlName="categoryEnable" (ionChange)="toggleCategory()" color="success">
            <ion-text class="font-12 line-100 ion-text-wrap" color="dark">
              <span *ngIf="form?.value?.categoryEnable" [innerHTML]="'WEBSITE.link.category.on' | translate"></span>
              <span *ngIf="!form?.value?.categoryEnable" [innerHTML]="'WEBSITE.link.category.off' | translate: { settingField: ('LIST.category.' + settingFieldOnlineReg.value) | translate}"></span>
            </ion-text>
          </ion-toggle>
        </ion-item>
        <div class="error-div">
        </div>
      
        <ion-item class="ion-item-no-padding background-transparent" lines="none" (click)="presentSettingFieldModal('category', form?.value?.category)" *ngIf="form?.value?.categoryEnable">
          <ion-label position="stacked" color="dark">{{ 'LBL.category' | translate }}&nbsp;<ion-text color="danger">*</ion-text></ion-label>
          <ion-item class="website-input-item" lines="none">
            <ion-label class="font-12 ion-no-margin" >{{ getSettingField('category', form.value.category) }}</ion-label>
            <ion-note slot="end"><ion-icon src="./assets/wedding/icon/general/chevron-down.svg" class="font-18"></ion-icon></ion-note>
          </ion-item>
          <div class="error-div">
            <ng-container *ngFor="let validation of validationMsg?.category">
              <div class="error-message" *ngIf="form?.get('category').hasError(validation.type) && (form?.get('category').dirty || form?.get('category').touched)">
                <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ validation.msg }}
              </div>
            </ng-container>
          </div>
        </ion-item>
    
        <!-- <ion-item class="website-input-item plus-one-item" lines="none">
          <div class="full-width">
            <ion-toggle color="success" formControlName="group" (ionChange)="setupMaxGuest()">
              <ion-label>
                &nbsp;
              </ion-label>
              <div>
                <ion-text class="font-12 ion-text-wrap" *ngIf="form.value.group">Allow guest to plus additional guest.</ion-text>
                <ion-text class="font-12 ion-text-wrap" *ngIf="!form.value.group">Not allow guest to plus additional guest.</ion-text>
              </div>
            </ion-toggle>
          </div>
        </ion-item>
        <div class="error-div">
          <ng-container *ngFor="let validation of validationMsg?.group">
            <div class="error-message" *ngIf="form?.get('group').hasError(validation.type) && (form?.get('group').dirty || form?.get('group').touched)">
              <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ validation.msg }}
            </div>
          </ng-container>
        </div> -->
    
        <!-- <ion-item class="website-input-item" lines="none">
          <ion-toggle class="line-100" formControlName="group" (ionChange)="toggleGroup()" color="success">
            <ion-text class="font-12 line-100 ion-text-wrap" color="dark">
              <span *ngIf="form?.value?.group" [innerHTML]="'WEBSITE.link.group.on' | translate"></span>
              <span *ngIf="!form?.value?.group" [innerHTML]="'WEBSITE.link.group.off' | translate"></span>
            </ion-text>
          </ion-toggle>
        </ion-item>
        <div class="error-div">
        </div> -->

        <!-- <ng-container *ngIf="form?.value?.group">
          <ion-item class="ion-item-no-padding background-transparent" lines="none">
            <ion-label position="stacked" color="dark">{{ 'WEBSITE.link.lbl.max_guest' | translate }}&nbsp;<ion-text color="danger">*</ion-text></ion-label>
            <ion-item class="website-input-item full-width ion-item-no-padding" lines="none">
              <ion-input class="ion-text-center" formControlName="maxGuest" [min]="1" type="number" inputmode="numeric" tabindex="1">
                <ion-button class="qty-btn" slot="start" (click)="minusPax()" fill="clear">
                  <ion-icon color="medium" src="./assets/wedding/icon/general/remove.svg"></ion-icon>
                </ion-button>
                <ion-button class="qty-btn" slot="end" (click)="addPax()" fill="clear">
                  <ion-icon color="medium" src="./assets/wedding/icon/general/add.svg"></ion-icon>
                </ion-button>
              </ion-input>
            </ion-item>
          </ion-item>
          <div class="error-div">
            <ng-container *ngFor="let validation of validationMsg?.maxGuest">
              <div class="error-message" *ngIf="form?.get('maxGuest').hasError(validation.type) && (form?.get('maxGuest').dirty || form?.get('maxGuest').touched)">
                <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ validation.msg }}
              </div>
            </ng-container>
          </div>
        </ng-container> -->
      </form>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="type === 'generated'">
    <div class="full-width-height center vertical-center" *ngIf="!websiteLinks?.length">
      <ion-label class="ion-text-center ion-margin" color="medium">
        <ion-icon class="center icon-cactus" color="medium" src="./assets/wedding/icon/general/no-record.svg"></ion-icon>
        <div class="ion-margin-top font-12"><ion-text color="medium">{{ 'LBL.no_found' | translate }}</ion-text></div>
      </ion-label>
    </div>
    <div class="full-width-height viewport-div ion-content-scroll-host" *ngIf="websiteLinks?.length">
      <ion-list class="full-width-height ion-padding-horizontal">
        <cdk-virtual-scroll-viewport [itemSize]="132">
          <span *cdkVirtualFor="let websiteLink of websiteLinks; trackBy: trackByFn; let i = index;">
            <ion-item-sliding class="website-input-item link-item" *ngIf="websiteLink?.linkId" #slidingItem>
              <ion-item class="full-width" lines="none">
                <ion-avatar slot="start" class="small-avatar" *ngIf="websiteLink?.createBy?.uid && getUserByUid(websiteLink.createBy.uid) as user">
                  <ng-container *ngIf="user?.avatar?.url">
                    <ion-img [src]="user.avatar.url" *ngIf="user.avatar?.type === 'local'"></ion-img>
                    <app-cached-image [src]="user.avatar.url" [spinner]="true" *ngIf="user?.avatar?.type !== 'local'"></app-cached-image>
                  </ng-container>
                  <ion-icon color="medium" src="./assets/wedding/img/avatar/blank.svg" *ngIf="!user?.avatar?.url"></ion-icon>
                </ion-avatar>
                <ion-label>
                  <div class="font-14">
                    <ion-icon class="vertical-middle" src="./assets/wedding/icon/general/link.svg"></ion-icon>&nbsp;<ion-text class="font-12">{{ websiteLink?.name ? websiteLink.name : getNameValue(i + 1) }}</ion-text>
                  </div>
                  <div class="font-10" *ngIf="websiteLink?.createBy.time?.seconds">
                    {{ format(websiteLink.createBy.time.seconds * 1000) }}
                  </div>
                  <div class="font-10" *ngIf="websiteLink?.invitedBy?.length">
                    {{ 'GUEST.lbl.invited_by' | translate }}:&nbsp;{{ getSettingField('invited_by', websiteLink.invitedBy) }}
                  </div>
                  <div class="font-10" *ngIf="websiteLink?.category?.length">
                    {{ 'LBL.category' | translate }}:{{ getSettingField('category', websiteLink.category) }}
                  </div>
                  <!-- <div class="font-10" *ngIf="websiteLink.maxGuest">
                    <span [innerHTML]="'WEBSITE.link.lbl.max_guest_count' | translate: { count: websiteLink.maxGuest }"></span>
                  </div> -->
                  <div class="font-10">
                    {{ 'GUEST.lbl.guest' | translate }}:&nbsp;{{ guestCount?.[websiteLink?.linkId] ? guestCount[websiteLink.linkId] : 0 }}
                  </div>
                  <div class="font-10">
                    {{ 'LIST.group.group' | translate }}:&nbsp;{{ groupCount?.[websiteLink?.linkId] ? groupCount[websiteLink.linkId] : 0 }}
                  </div>
                </ion-label>
                
  
                <ion-buttons slot="end">
                  <ion-button (click)="share(websiteLink)" *ngIf="websiteLink?.enable">
                    <ion-icon class="font-20" slot="icon-only" src="./assets/wedding/icon/general/share-outline.svg"></ion-icon>
                  </ion-button>
                  <ion-button (click)="toggleEnable(websiteLink)" *ngIf="!websiteLink?.enable" [disabled]="!privilege">
                    <ion-icon class="font-20" slot="icon-only" color="medium" name="eye-off-outline"></ion-icon>
                  </ion-button>
                  <!-- <ion-button class="icon-only" (click)="presentWebsiteLinkModal(websiteLink)">
                    <ion-icon src="./assets/wedding/icon/general/edit.svg"></ion-icon>
                  </ion-button> -->
                  <!-- <ion-button class="icon-only" (click)="toggleEnable(websiteLink)">
                    <ion-icon name="eye" *ngIf="websiteLink?.enable"></ion-icon>
                    <ion-icon name="eye-off" color="danger" *ngIf="!websiteLink?.enable"></ion-icon>
                  </ion-button> -->
                </ion-buttons>
              </ion-item>
              <ion-item-options side="end" lines="none" *ngIf="privilege">
                <ion-item-option color="success" (click)="presentWebsiteLinkEditModal(websiteLink)" *ngIf="websiteLink?.enable" lines="none">
                  <ion-icon src="./assets/wedding/icon/general/edit.svg"></ion-icon>
                </ion-item-option>
                <ion-item-option [color]="websiteLink?.enable ? 'danger' : 'success'" (click)="toggleEnable(websiteLink, slidingItem)" lines="none">
                  <ion-icon name="eye" *ngIf="!websiteLink?.enable"></ion-icon>
                  <ion-icon name="eye-off" *ngIf="websiteLink?.enable"></ion-icon>
                </ion-item-option>
              </ion-item-options>
            </ion-item-sliding>
          </span>
        </cdk-virtual-scroll-viewport>
      </ion-list>
    </div>
  </ng-container>
                                                                                      
  <!-- <ng-container *ngIf="shareMode">
    <div class="ion-margin">
      <ion-item class="background-transparent ion-item-no-padding" lines="none">
        <ion-label position="stacked" color="dark">{{ 'LBL.url' | translate }}</ion-label>
        <ion-item class="website-input-item" lines="none" [detail]="true" [button]="true" (click)="copyUrl()">
          <ion-label class="full-width text-ellipsis ion-no-margin">
            <ng-container *ngIf="websiteUrl">{{ websiteUrl }}</ng-container>
            <ng-container *ngIf="websiteLink?.link?.short">{{ websiteLink.link.short }}</ng-container>
            <ng-container *ngIf="!websiteLink?.link?.short && websiteLink?.link?.url">{{ websiteLink.link.url }}</ng-container>
          </ion-label>
        </ion-item>
      </ion-item>
    </div>
  </ng-container> -->
</ion-content>
<ion-footer *ngIf="type === 'new'">
  <ion-toolbar>
    <!-- <ng-container *ngIf="shareMode">
      <ng-container *ngIf="hybrid">
        <ion-button expand="block" (click)="share()">
          {{ 'BTN.send' | translate }}
        </ion-button>
      </ng-container>
      <ng-container *ngIf="!hybrid">
        <ion-button expand="block" (click)="copyMsg()">
          {{ 'BTN.copy' | translate }}
        </ion-button>
      </ng-container>
    </ng-container> -->
    <!-- <ion-grid>
      <ion-row>
        <ion-col *ngIf="websiteLink?.linkId && websiteLink?.link?.url">
          <ion-button expand="block" color="tertiary" (click)="share()">
            {{ 'BTN.send' | translate }}
          </ion-button>
        </ion-col>
        <ion-col *ngIf="websiteForm?.enable">
          <ion-button expand="block" (click)="save()">
            {{ (websiteLink?.linkId ? 'WEBSITE.link.btn.edit' : 'WEBSITE.link.btn.new') | translate }}
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>   -->
    <ion-button expand="block" (click)="saveNewWebsiteLink()">
      {{ 'WEBSITE.link.btn.new' | translate }}
    </ion-button>
  </ion-toolbar>
</ion-footer>
