import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';

import { FunctionService } from 'src/app/services/general/function.service';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { KeyboardService } from 'src/app/services/general/keyboard.service';

@Component({
  selector: 'app-search-select-list',
  templateUrl: './search-select-list.component.html',
  styleUrls: ['./search-select-list.component.scss'],
})
export class SearchSelectListComponent  implements OnInit, OnDestroy {

  @ViewChild(CdkVirtualScrollViewport) cdkVirtualScrollViewport: CdkVirtualScrollViewport;
  @Input() title: string;
  @Input() placeholder:  string;
  @Input() closeButtonText: string;
  @Input() items: any[];
  @Input() selected: any;
  @Input() itemTextField: string;
  @Input() preferredItems: any[];

  @Output() output = new EventEmitter<any>();
  
  keyword: string;

  itemsList: any[] = [];

  constructor(
    private platform: Platform,
    private modalController: ModalController,
    private functionService: FunctionService,
    private keyboardService: KeyboardService,
  ) { }

  ngOnInit() {}

  ngOnDestroy(): void {
      
  }

  ionViewWillEnter() {
    this.setupPreferredItems();
    this.setupItems();
  }

  ionViewDidEnter() {
    
  }

  setupPreferredItems() {
    if (this.preferredItems?.length) {
      this.preferredItems = this.preferredItems.reverse();
      this.preferredItems.forEach((x: any) => {
        const index = this.items.findIndex((y: any) => {
          return this.functionService.isEqual(x, y);
        });
        if (index !== -1) {
          this.items.splice(index, 1);
        }
        this.items.unshift(x);
      });
    }
  }

  setupViewport() {
    if (this.cdkVirtualScrollViewport) {
      if (this.selected) {
        const index = this.items.findIndex((x: any) => {
          return this.itemTextField && x?.[this.itemTextField] && x?.[this.itemTextField] === this.selected?.[this.itemTextField];
        })
        if (index !== -1) {
          this.scrollTo(index);
        }
      }
      setTimeout(() => {
        this.cdkVirtualScrollViewport?.checkViewportSize();
      }, 200);
    } else {
      setTimeout(() => {
        this.setupViewport();
      }, 200);
    }
  }

  /**
   * Dismiss modal
   */
  async dismissModal(item?: any) {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { await modal.dismiss({ item }); }
    }
  }

  checkSelected(item: any) {
    if (this.selected && !this.functionService.isEmpty(this.selected) && this.functionService.isEqual(item, this.selected)) {
      return true;
    }
    return false;
  }

  setupItems() {
    this.itemsList= this.getItems();
    this.setupViewport();
  }

  getItems(): any[] {
    return this.items?.filter((item: any) => {
      if (this.keyword) {
        return this.itemTextField && item && item?.[this.itemTextField] && this.functionService.search(item[this.itemTextField], this.keyword);
      }
      return true;
    });
  }

  scrollTo(index: number) {
    this.cdkVirtualScrollViewport?.scrollToIndex(index, 'smooth');
  }

  select(item: any) {
    this.output.next(item);
    this.dismissModal(item);
  }

  /**
   * Close keyboard after enter
   * @param event Key enter event
   */
  closeKeyboard(event: any) {
    if (this.platform.is('hybrid')) {
      if (event?.key === 'Enter') {
        this.keyboardService.hide();
      }
    }
  }

  /**
   * Track item by account login
   * @param index Index
   * @param item item
   */
  trackByFn(index: number, item: any) {
    if (item) {
      if (item?.id) {
        return item?.id;
      } else if (item?.name) {
        return item.name;
      } else if (item?.code) {
        return item.code;
      }
    }
    
    return index;
  }

}
