import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { WebsiteCheckin } from 'src/app/interfaces/website';
import { FunctionService } from '../general/function.service';
import { UpdateByService } from '../user/update-by.service';
import { SeatingSettingService } from '../seating/seating-setting.service';

@Injectable({
  providedIn: 'root'
})
export class WebsiteCheckinService implements OnInit, OnDestroy {

  accountId: string;

  websiteCheckin: WebsiteCheckin;

  observable: any;

  constructor(
    private afs: AngularFirestore,
    private translate: TranslateService,
    private seatingSettingService: SeatingSettingService,
    private updateByService: UpdateByService,
    private functionService: FunctionService,
  ) {
    this.observable = new BehaviorSubject<WebsiteCheckin>(this.websiteCheckin);
  }

  ngOnInit(): void {
      
  }

  ngOnDestroy(): void {
      
  }

  setup(websiteCheckin: WebsiteCheckin) {
    if (!this.functionService.isEqual(this.websiteCheckin, websiteCheckin)) {
      this.websiteCheckin = websiteCheckin;
      this.observable.next(this.websiteCheckin);
    }
  }

  getWebsiteCheckin(): WebsiteCheckin {
    const websiteCheckin = this.functionService.cloneDeep(this.websiteCheckin);
    if (websiteCheckin) {
      if (!websiteCheckin?.field) {
        websiteCheckin.field = this.getField();
      }
      if (websiteCheckin?.field && !websiteCheckin?.field?.disabledMsg) {
        websiteCheckin.field.disabledMsg = this.getCheckinDisabledMsg();
      }
      return websiteCheckin;
    } else {
      return {
        title: this.getTitle(),
        msg: this.getMsg(),
        btn: this.getButton(),
        remark: '',
        enable: true,
        start: {
          enable: true,
          // msg: this.getStartMsg(),
          mins: -120,
        },
        end: {
          enable: true,
          // msg: this.getEndMsg(),
          mins: 120,
        },
        field: this.getField(),
        media: {
          url: '',
          type: '',
          mimeType: '',
        },
      };
    }
  }

  getField() {
    return {
      seatingMsg: this.getSeatingMsg(),
      startMsg: this.getStartMsg(),
      endMsg: this.getEndMsg(),
      successMsg: this.getCheckinSuccessMsg(),
      failMsg: this.getCheckinFailMsg(),
      confirmMsg: this.getCheckinConfirmMsg(),
      disabledMsg: this.getCheckinDisabledMsg(),
    };
  }

  getTitle(text?: boolean) {
    const title = this.translate.instant('WEBSITE.checkin.title');
    if (text) {
      return title;
    }
    return '<p class="ql-align-center"><strong class="ql-size-large">' + title + '</strong></p>';
  }

  getButton() {
    return this.translate.instant('WEBSITE.checkin.btn');
  }

  getMsg() {
    return this.translate.instant('WEBSITE.checkin.msg');
  }

  getStartMsg() {
    return this.translate.instant('WEBSITE.checkin.field.start.msg');
  }

  getEndMsg() {
    return this.translate.instant('WEBSITE.checkin.field.end.msg');
  }

  getSeatingMsg() {
    return this.translate.instant('WEBSITE.checkin.field.seating.msg', { seating: this.seatingSettingService.getSeatingTypeName() });
  }

  getCheckinSuccessMsg() {
    return this.translate.instant('WEBSITE.checkin.field.success.msg');
  }

  getCheckinFailMsg() {
    return this.translate.instant('WEBSITE.checkin.field.fail.msg');
  }

  getCheckinConfirmMsg() {
    return this.translate.instant('WEBSITE.checkin.field.confirm.msg');
  }

  getCheckinDisabledMsg() {
    return this.translate.instant('WEBSITE.checkin.field.disabled.msg');
  }

  async save(checkin: WebsiteCheckin) {
    if (this.accountId && !this.functionService.isEqual(checkin, this.websiteCheckin)) {
      checkin.updateBy = this.updateByService.updateBy;
      const ref = this.afs.firestore.doc(`accounts/${ this.accountId }/accountSetting/website`);
      ref.set({ checkin }, { merge: true });
    }
  }
  
}
