<ion-header class="header-light" *ngIf="!componentMode">
  <ion-toolbar color="light">
    <ion-buttons slot="start">
      <ion-button (click)="discard()">
        <ion-icon color="dark" class="back-icon" src="./assets/wedding/icon/general/chevron-back.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <app-offline-indicator></app-offline-indicator>
      <ng-container *ngIf="newGiftMode">{{ 'MODULE.action.gift.new' | translate }}</ng-container>
      <ng-container *ngIf="bulkEditMode">{{ 'GIFT.title.bulk_edit' | translate }}</ng-container>
      <ng-container *ngIf="!newGiftMode && !bulkEditMode">{{ 'MODULE.action.gift.edit' | translate }}</ng-container>
    </ion-title>
  </ion-toolbar>
  <ion-toolbar class="ion-padding-horizontal background-transparent">
    <ng-container *ngIf="true then segBar"></ng-container>
  </ion-toolbar>
</ion-header>

<ion-content [style.height]="componentMode ? 'calc(100% - ' + footerHeight + 'px)' : '100%'" color="light">
  <div class="full-width ion-padding-horizontal" *ngIf="componentMode">
    <ng-container  *ngIf="true then segBar"></ng-container>
  </div>
  <ng-container *ngIf="form">
    <div class="ion-padding">
      <form [formGroup]="form" (ngSubmit)="formSubmit()">
        <ng-container *ngIf="giftType === 'cash'">
          <ion-item class="ion-item-no-padding full-width background-transparent" lines="none">
            <ion-label position="stacked" color="dark">{{ 'LBL.amount' | translate }}</ion-label>
            <ion-item class="prompt-input-item" lines="none">
              <ion-text class="currency-text" (click)="openCurrency()" slot="start">{{ form?.value?.currency }} <ion-icon class="vertical-middle ion-float-right" src="./assets/wedding/icon/general/chevron-down.svg"></ion-icon></ion-text>
              <ion-input class="ion-text-right" formControlName="amount" [placeholder]="" type="number" inputmode="decimal" enterkeyhint="done" (ionChange)="checkAmount()" tabindex="1" clearOnEdit></ion-input>
            </ion-item>
            <div class="error-div">
              <ng-container *ngFor="let validation of validationMsg?.amount">
                <div class="error-message" *ngIf="form?.get('amount').hasError(validation.type) && (form?.get('amount').dirty || form?.get('amount').touched)">
                  <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ validation.msg }}
                </div>
              </ng-container>
            </div>
          </ion-item>
          <ion-item class="ion-item-no-padding background-transparent" lines="none">
            <ion-label position="stacked" color="dark">{{ 'LBL.description' | translate }}</ion-label>
            <ion-input formControlName="name" class="prompt-input" [placeholder]="" type="text" inputmode="text" autocapitalize="words" enterkeyhint="done" tabindex="2" clearInput></ion-input>
            <div class="error-div">
              <ng-container *ngFor="let validation of validationMsg?.name">
                <div class="error-message" *ngIf="form?.get('name').hasError(validation.type) && (form?.get('name').dirty || form?.get('name').touched)">
                  <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ validation.msg }}
                </div>
              </ng-container>
            </div>
          </ion-item>
        </ng-container>
        <ng-container *ngIf="giftType === 'other'">
          <ion-item class="ion-item-no-padding background-transparent" lines="none">
            <ion-label position="stacked" color="dark">{{ 'GIFT.lbl.name' | translate }}</ion-label>
            <ion-input formControlName="name" class="prompt-input" [placeholder]="" type="text" inputmode="text" autocapitalize="words" enterkeyhint="next" tabindex="3" clearInput></ion-input>
            <div class="error-div">
              <ng-container *ngFor="let validation of validationMsg?.name">
                <div class="error-message" *ngIf="form?.get('name').hasError(validation.type) && (form?.get('name').dirty || form?.get('name').touched)">
                  <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ validation.msg }}
                </div>
              </ng-container>
            </div>
          </ion-item>
          <ion-item class="ion-item-no-padding background-transparent" lines="none">
            <ion-label position="stacked" color="dark">{{ 'LBL.qty' | translate }}</ion-label>
            <ion-item class="background-transparent ion-item-no-padding full-width" lines="none">
              <ion-input class="prompt-input ion-text-center" formControlName="qty" min="1" type="number" inputmode="numeric" tabindex="4">
                <ion-button class="qty-btn" slot="start" (click)="minusQty()" fill="clear">
                  <ion-icon src="./assets/wedding/icon/general/remove.svg"></ion-icon>
                </ion-button>
                <ion-button class="qty-btn" slot="end" (click)="addQty()" fill="clear">
                  <ion-icon src="./assets/wedding/icon/general/add.svg"></ion-icon>
                </ion-button>
              </ion-input>
              
            </ion-item>
            <div class="error-div">
              <ng-container *ngFor="let validation of validationMsg?.qty">
                <div class="error-message" *ngIf="form?.get('qty').hasError(validation.type) && (form?.get('qty').dirty || form?.get('qty').touched)">
                  <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ validation.msg }}
                </div>
              </ng-container>
            </div>
          </ion-item>
        </ng-container>

        <ion-item class="ion-item-no-padding background-transparent" lines="none">
          <ion-label position="stacked" color="dark">{{ 'GIFT.lbl.from' | translate }}</ion-label>
          <ion-item class="prompt-input-item" lines="none" (click)="presentGuestListModal()">
            {{ from }}
          </ion-item>
          <div class="error-div">
            <div class="error-message" *ngIf="form.valid && form.touched && !from">
              <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ 'VALIDATION.required' | translate: { field: 'GIFT.lbl.from' | translate } }}
            </div>
          </div>
        </ion-item>
      </form>
      
      <ion-grid class="ion-no-padding" *ngIf="componentMode" fixed>
        <ion-row>
          <ion-col *ngIf="total > 1 && index > 0">
            <ion-button class="ion-margin" expand="block" color="grey" (click)="setupOutput(false)">{{ 'BTN.previous' | translate }}</ion-button>
          </ion-col>
          <ion-col>
            <ion-button class="ion-margin" expand="block" (click)="formSubmit()" *ngIf="total > index + 1">{{ 'BTN.next' | translate }}</ion-button>
            <ion-button class="ion-margin" expand="block" (click)="formSubmit()" *ngIf="total === index + 1">{{ 'BTN.confirm' | translate }}</ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
    
    <div *ngIf="!componentMode && !newGiftMode && !bulkEditMode && currentPrivilege?.['gift']?.['delete']">
      <ion-list class="ion-margin-vertical background-transparent">
        <ion-item-sliding #slidingItem>
          <ion-item-options side="start" (ionSwipe)="promptRemove(slidingItem)" lines="none">
            <ion-item-option color="danger" (click)="promptRemove(slidingItem)" lines="none">
              <ion-icon src="./assets/wedding/icon/general/checkmark.svg"></ion-icon>
            </ion-item-option>
          </ion-item-options>
          <ion-item color="danger" lines="none">
            <ion-icon slot="start" class="icon-small" src="./assets/wedding/icon/general/trash.svg"></ion-icon> 
            <ion-text slot="start">{{ 'CRUD.swipe_field' | translate: { field: 'CRUD.delete' | translate } }}</ion-text> 
            <ion-icon slot="start" class="icon-small" src="./assets/wedding/icon/general/arrow-forward.svg"></ion-icon>
          </ion-item>
          <ion-item-options side="end" (ionSwipe)="promptRemove(slidingItem)" lines="none">
            <ion-item-option color="danger" (click)="promptRemove(slidingItem)" lines="none">
              <ion-icon src="./assets/wedding/icon/general/checkmark.svg"></ion-icon>
            </ion-item-option>
          </ion-item-options>
        </ion-item-sliding>
      </ion-list>
    </div>

  </ng-container>
</ion-content>
<ion-footer *ngIf="!componentMode">
  <ion-toolbar>
    <ion-button class="ion-margin" expand="block" (click)="formSubmit()">{{ 'BTN.save' | translate }}</ion-button>
  </ion-toolbar>
</ion-footer>

<ng-template #segBar>
  <ion-segment class="full-width" [(ngModel)]="giftType" (ionChange)="giftTypeChanged()" scrollable>
    <ion-segment-button value="cash">
      <ion-item class="background-transparent ion-item-no-padding" lines="none">
        <ion-icon class="segment-icon icon-small" src="./assets/wedding/icon/gift/cash.svg"></ion-icon>
        <ion-text class="font-10">{{ 'LIST.gift.cash' | translate }}</ion-text>
      </ion-item>
    </ion-segment-button>
    <ion-segment-button value="other">
      <ion-item class="background-transparent ion-item-no-padding" lines="none">
        <ion-icon class="segment-icon icon-small" src="./assets/wedding/icon/gift/gift.svg"></ion-icon>
        <ion-text class="font-10">{{ 'LIST.gift.other' | translate }}</ion-text>
      </ion-item>
    </ion-segment-button>
  </ion-segment>
</ng-template>