import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { WebsiteType } from 'src/app/types/website';

@Injectable({
  providedIn: 'root'
})
export class WebsiteTypeService {

  websiteType: WebsiteType;

  observable: any;

  constructor() {
    this.observable = new BehaviorSubject<WebsiteType>(this.websiteType);
  }

  setupWebsiteType(websiteType: WebsiteType) {
    if (websiteType && websiteType !== this.websiteType) {
      this.websiteType = websiteType;
      this.observable.next(this.websiteType);
    }
  }
}
