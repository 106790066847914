import { Injectable } from '@angular/core';
import { SettingField } from 'src/app/interfaces/database';
import { InvitedByService } from '../setting/invited-by.service';
import { CategoryService } from '../setting/category.service';
import { FunctionService } from '../general/function.service';

@Injectable({
  providedIn: 'root'
})
export class WebsiteSettingFieldService {

  settingFieldOnlineReg: SettingField = {
    id: 'online_reg',
    custom: false,
    value: 'online_reg',
  };

  constructor(
    private invitedByService: InvitedByService,
    private categoryService: CategoryService,
    private functionService: FunctionService,
  ) { }

  setupSettingField(invitedBy: boolean, category: boolean) {
    if (invitedBy) {
      this.setupInvitedBy();
    }
    if (category) {
      this.setupCategory();
    }
  }

  setupInvitedBy() {
    if (this.invitedByService.invitedByList?.length) {
      const list = this.functionService.cloneDeep(this.invitedByService.invitedByList);
      const index = list.findIndex((x: SettingField) => {
        return x.value === this.settingFieldOnlineReg.value && x.custom === this.settingFieldOnlineReg.custom;
      });
      if (index === -1) {
        let order = 0;
        list?.forEach((x: SettingField) => {
          if (x?.order && x.order > order) {
            order = x.order;
          }
        });
        const settingField = this.settingFieldOnlineReg;
        settingField.order = order + 1;
        list.push(settingField);
        this.invitedByService.saveInvitedBy(list);
      }
    } else {
      setTimeout(() => {
        this.setupInvitedBy();
      }, 500);
    }
  }

  setupCategory() {
    if (this.categoryService.categoryList?.length) {
      const list = this.functionService.cloneDeep(this.categoryService.categoryList);
      const index = list.findIndex((x: SettingField) => {
        return x.value === this.settingFieldOnlineReg.value && x.custom === this.settingFieldOnlineReg.custom;
      });
      if (index === -1) {
        let order = 0;
        list?.forEach((x: SettingField) => {
          if (x?.order && x.order > order) {
            order = x.order;
          }
        });
        const settingField = this.settingFieldOnlineReg;
        settingField.order = order + 1;
        list.push(settingField);
        this.categoryService.saveCategory(list);
      }
    } else {
      setTimeout(() => {
        this.setupCategory();
      }, 500);
    }
  }
}
