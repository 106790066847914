import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Role } from 'src/app/interfaces/account';
import { AccountRoleService } from 'src/app/services/account/account-role.service';
// import { Role } from 'src/app/interfaces/database';
import { FunctionService } from 'src/app/services/general/function.service';
import { PopupService } from 'src/app/services/general/popup.service';

@Component({
  selector: 'app-account-role-new',
  templateUrl: './account-role-new.component.html',
  styleUrls: ['./account-role-new.component.scss'],
})
export class AccountRoleNewComponent  implements OnInit, OnDestroy {

  @ViewChild('cdkVirtualScrollViewport') cdkVirtualScrollViewport: CdkVirtualScrollViewport;

  roleList: Role[];

  stdList: Role[];

  selected: Role[];

  eventMode: boolean;

  newValue: string;

  newAccount: boolean;

  constructor(
    private translate: TranslateService,
    private modalController: ModalController,
    private accountRoleService: AccountRoleService,
    private popupService: PopupService,
  ) { }

  ngOnInit() {}

  ngOnDestroy(): void {
      
  }

  ionViewWillEnter() {
    this.setupList();
  }

  setupList() {
    if (!this.roleList?.length) {
      this.roleList = [];
    }
    this.selected = [];
    this.stdList = [ ...this.getStdList().filter((x: Role) => {
      const index = this.roleList.findIndex((y: Role) => {
        return x?.role?.type === y?.role?.type && x?.role?.custom === y?.role?.custom;
      });
      return index === -1 ? true : false;
    }) ];
    this.setupViewport();
  }

  setupViewport() {
    if (this.cdkVirtualScrollViewport) {
      setTimeout(() => {
        this.cdkVirtualScrollViewport?.checkViewportSize();
      }, 200);
    } else {
      setTimeout(() => {
        this.setupViewport();
      }, 200);
    }
  }

  async dismissModal(selected?: Role[]) {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { modal.dismiss({ selected }); }
    }
  }

  getStdList() {
    return this.accountRoleService.getStdRoleList();
  }

  getValue(role: Role): string {
    if (role?.role?.type) {
      if (role?.role?.custom) {
        return role.role.type;
      } else {
        return this.translate.instant('LIST.role.' + role.role.type);
      }
    }
    return '';
  }

  checkSelected(role: Role): boolean {
    if (this.selected) {
      const index = this.selected?.findIndex((x: Role) => x?.role?.custom === role?.role?.custom && x?.role?.type === role?.role?.type);
      if (index !== -1) {
        return true;
      }
    }
    return false;
  }

  checkSwipe(role: Role) {
    if (!role?.role?.custom) {
      this.popupService.presentToast(this.translate.instant('VALIDATION.reserved', { field: this.translate.instant('LBL.role') }), 'warning');
    }
  }

  /**
   * Check settingField for duplicate before save
   * @param value New settingField
   * @param settingField settingField
   */
  async checkRole(newRole: string, index: number) {
    if (newRole) {
      let role: Role = {
        role: {
          custom: true,
          type: newRole,
          coupleId: 0
        },
        privilege: null
      };

      const duplicateIndex: number = this.roleList?.findIndex((x: Role) =>
        x?.role?.type?.toString()?.toLowerCase() === newRole.toString().toLowerCase() || this.getValue(x)?.toString()?.toLowerCase() === newRole?.toString()?.toLowerCase()
      );
      const duplicateIndex2: number = this.selected?.findIndex((x: Role) =>
        x?.role?.type?.toString()?.toLowerCase() === newRole.toString().toLowerCase() && x?.role?.custom
      );
      if (duplicateIndex !== -1 || duplicateIndex2 !== -1) {
        this.popupService.presentToast(
          this.translate.instant('VALIDATION.duplicate_field', { field: this.translate.instant('LBL.role') }), 'danger');
      } else {
        const duplicateStdIndex: number = this.stdList?.findIndex((x: Role) =>
          x?.role?.type?.toString().toLowerCase() === newRole.toString().toLowerCase() || (this.getValue(x)?.toString().toLowerCase() === newRole.toString().toLowerCase())
        );
        if (duplicateStdIndex !== -1 && this.stdList?.[duplicateStdIndex]) {
          role = this.stdList[duplicateStdIndex];
        } else {
          const stdList = [ ...this.stdList ];
          stdList.push(role);
          this.stdList = [ ...stdList ];
          this.setupViewport();
        }
        
        this.selected.push(role);
        
        // this.save(this.list);
      }
      // const duplicateIndex = this.roleList?.findIndex((x: Role) => x?.role?.type?.toString()?.toLowerCase() === newRole?.toString()?.toLowerCase());
      // if (duplicateIndex !== -1 && duplicateIndex !== index) {
      //   this.popupService.presentToast(this.translate.instant('VALIDATION.duplicate_field', { field: this.translate.instant('LBL.role') }), 'danger');
      // } else {
      //   if (index === -1) {
      //     const obj: Role = {
      //       role: {
      //         custom: true,
      //         type: newRole,
      //         coupleId: 0
      //       },
      //       privilege: null
      //     };
      //     if (!this.roleList?.length) {
      //       this.roleList = this.functionService.cloneDeep(this.accountRoleService.searchRoleList('', this.eventMode));;
      //     }
      //     this.selected.push(obj);
      //   } else {
      //     if (this.roleList[index]?.role?.custom || newRole?.toLocaleLowerCase() !==
      //     this.translate.instant('LIST.role.' + this.roleList[index]?.role?.type).toLocaleLowerCase()) {
      //       this.roleList[index].role.type = newRole;
      //       this.roleList[index].role.custom = true;
      //     }
      //     this.selected.push(this.roleList[index]);
      //   }
      // }
      // if (!this.newAccount) {
      //   await this.save();
      // }
    }
  }

  async select(role: Role) {
    if (role?.role?.type) {
      const index = this.selected?.findIndex((x: Role) => {
        return x?.role?.type === role.role.type && x?.role?.custom === role.role?.custom;
      });
      if (index === -1) {
        this.selected.push(role);
      } else {
        this.selected.splice(index, 1);
      }
    }
  }

  async save() {
    if (this.selected?.length) {
      const list = this.roleList.concat(this.selected);
      await this.accountRoleService.updateAccountRole(list);
      // this.popupService.dismissLoading();
      await this.popupService.saveSuccessToast();
      await this.dismissModal();
    }

    // await this.popupService.presentLoading();
    // if (this.roleList?.length && !this.functionService.isEqual(this.roleList, this.accountRoleService.roleList)) {
    //   await this.accountRoleService.updateAccountRole(this.roleList);
    // }
    // this.popupService.dismissLoading();
    // this.popupService.saveSuccessToast();
  }

}
