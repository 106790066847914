import { TemplateVariableType } from 'src/app/types/template';
import { PhotoService } from 'src/app/services/general/photo.service';
import { Component, ElementRef, HostListener, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { PickerController, ModalController, Platform, IonTextarea, IonInput, ActionSheetController, PickerColumnOption } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

import { Subscription } from 'rxjs';
import { Clipboard } from '@capacitor/clipboard';

import { AccountInfoService } from 'src/app/services/account/account-info.service';
import { LanguageService } from 'src/app/services/general/language.service';
import { LocalityService } from 'src/app/services/location/locality.service';
import { PopupService } from 'src/app/services/general/popup.service';
import { FunctionService } from 'src/app/services/general/function.service';

import { VisitorPageComponent } from 'src/app/components/visitor/visitor-page/visitor-page.component';
import { LocationComponent } from 'src/app/components/general/location/location.component';

import { AccountInfo } from 'src/app/interfaces/account';
import { Country, Language } from 'src/app/interfaces/database';
import { LocationDetail, Theme } from 'src/app/interfaces/general';
import { PageSetting, VisitorAttachment } from 'src/app/interfaces/visitor';
import { ThemeList } from 'src/app/commons/theme';
import { GpsType, VisitorSettingType } from 'src/app/types/visitor';
import { LinkService } from 'src/app/services/general/link.service';
// import SwiperCore, { SwiperOptions } from 'swiper';
import { TemplateVariableEvent, TemplateVariableWedding } from 'src/app/commons/template';

import { FileService } from 'src/app/services/general/file.service';
import { AttachmentType } from 'src/app/types/general';
import { SeatingSettingService } from 'src/app/services/seating/seating-setting.service';
import { VisitorSettingService } from 'src/app/services/visitor/visitor-setting.service';
import { VisitorThemeService } from 'src/app/services/visitor/visitor-theme.service';
import { VisitorInfoService } from 'src/app/services/visitor/visitor-info.service';
import { VisitorLanguageService } from 'src/app/services/visitor/visitor-language.service';
import { VisitorManageService } from 'src/app/services/visitor/visitor-manage.service';
import { GpsService } from 'src/app/services/general/gps.service';
import { AccountEventModeService } from 'src/app/services/account/account-event-mode.service';
import { WebCheckinHourList, WebCheckinMinuteList } from 'src/app/commons/webCheckin';
import { FormGroupList } from 'src/app/interfaces/form';

// SwiperCore.use([ ]);

/**
 * Visitor page setting component
 */
@Component({
  selector: 'app-setting-visitor',
  templateUrl: './setting-visitor.component.html',
  styleUrls: ['./setting-visitor.component.scss'],
})
export class SettingVisitorComponent implements OnInit, OnDestroy {
  @ViewChild('swiperGrid', { read: ElementRef, static: false }) swiperGrid: ElementRef;
  @ViewChild('content', { read: ElementRef }) content: ElementRef;
  /**
   * Ion slides viewchild
   */
  @ViewChild('greetingInput') greetingInput: IonTextarea;
  @ViewChild('msgInput') msgInput: IonTextarea;
  @ViewChild('paxInput') paxInput: IonTextarea;
  @ViewChild('seatingInput') seatingInput: IonTextarea;
  @ViewChild('sessionInput') sessionInput: IonTextarea;
  @ViewChild('itineraryInput') itineraryInput: IonTextarea;
  @ViewChild('groupNameInput') groupNameInput: IonTextarea;
  @ViewChild('titleInput') titleInput: IonTextarea;
  @ViewChild('dateInput') dateInput: IonTextarea;
  @ViewChild('startTimeInput') startTimeInput: IonInput;
  @ViewChild('endTimeInput') endTimeInput: IonInput;
  @ViewChild('timezoneInput') timezoneInput: IonInput;

  @ViewChild('floorplanInput') floorplanInput: IonInput;
  @ViewChild('menuInput') menuInput: IonInput;
  @ViewChild('attachmentInput') attachmentInput: IonInput;

  // swiperConfig: SwiperOptions = {
  //   slidesPerView: 3,
  //   // lazy: {
  //   //   loadPrevNext: true,
  //   //   loadPrevNextAmount: 1,
  //   // },
  //   updateOnWindowResize: true,
  //   modules: [ ]
  // };

  /**
   * Disable slider previous btn
   */
  disablePrevBtn: boolean;
  /**
   * Disable slider next btn
   */
  disableNextBtn: boolean;

  listLimit = 4;
  /**
   * Setting type
   */
  type: VisitorSettingType;
  /**
   * Page theme
   */
  pageTheme: string;
  /**
   * Page location
   */
  pageLocation: LocationDetail;
  /**
   * Page language list
   */
  pageLangList: Language[];
  translation: any;
  /**
   * Language
   */
  // language: Language;
  langCode: string;
  /**
   * Language list
   */
  languageList: Language[] = this.languageService.languageList;
  /**
   * Theme list
   */
  themeList: Theme[] = ThemeList;
  /**
   * Page setting form
   */
  pageSettingForm: FormGroup;
  /**
   * Validation msg
   */
  validationMsg: any;

  greetingEditMode: boolean;
  msgEditMode: boolean;
  editMode = {
    title: false,
    date: false,
    startTime: false,
    endTime: false,
    timezone: false,
    greeting: false,
    msg: false,
    pax: false,
    seating: false,
    session: false,
    itinerary: false,
    groupName: false,
    floorplan: false,
    menu: false,
    attachment: false,
  };

  googleMapCountry: boolean;
  wazeCountry: boolean;
  grabCountry: boolean;
  uberCountry: boolean;

  private enableSession: boolean;
  /**
   * Page info
   */
  private pageInfo: any;
  /**
   * Page setting
   */
  pageSetting: PageSetting;
  /**
   * Account info
   */
  private accountInfo: AccountInfo;
  /**
   * Page info form list
   */
  private pageInfoFormList: FormGroupList[];
  /**
   * Page setting subscription
   */
  private pageSettingSubscription: Subscription;
  /**
   * Page theme subscription
   */
  private pageThemeSubscription: Subscription;
  /**
   * Page theme subscription
   */
  // private pageTypeSubscription: Subscription;
  /**
   * Page info subscription
   */
  private pageInfoSubscription: Subscription;
  /**
   * Page language list subsription
   */
  private pageLangListSubscription: Subscription;
  /**
   * Account info subscription
   */
  private accountInfoSubscription: Subscription;

  private translationSubscription: Subscription;


  /**
   * Constructor
   * @param ngZone Ng zone
   * @param formBuilder Form builder
   * @param pickerController PIcker contoller
   * @param modalController Modal Controller
   * @param translate Translate service
   * @param languageService Device language servivce
   * @param localityService Locality service
   * @param popupService Popup service
   * @param functionService Function service
   */
  constructor(
    private ngZone: NgZone,
    private platform: Platform,
    private formBuilder: FormBuilder,
    private actionSheetController: ActionSheetController,
    private pickerController: PickerController,
    private modalController: ModalController,
    private translate: TranslateService,
    private languageService: LanguageService,
    private accountInfoService: AccountInfoService,
    private accountEventModeService: AccountEventModeService,
    private visitorSettingService: VisitorSettingService,
    private visitorThemeService: VisitorThemeService,
    private visitorInfoService: VisitorInfoService,
    private visitorLanguageService: VisitorLanguageService,
    private visitorManageService: VisitorManageService,
    private seatingSettingService: SeatingSettingService,
    private localityService: LocalityService,
    private gpsService: GpsService,
    private photoService: PhotoService,
    private fileService: FileService,
    private linkService: LinkService,
    private popupService: PopupService,
    private functionService: FunctionService,
  ) { }

  ngOnInit() {}

  ngOnDestroy(): void {
    this.unwatch();
  }

  /**
   * Before view enter
   */
  ionViewWillEnter() {
    if (!this.type) {
      this.type = 'setting';
    }

    this.pageInfoFormList = [];
    this.langCode = this.languageService.getAccountLanguage()?.code;
    this.getTranslation();
    this.watch();

    if (this.type === 'setting' || this.type === 'info') {
      this.setupForm();
    }
  }

  /**
   * Before view leave
   */
  ionViewWillLeave() {
    this.unwatch();
  }

  /**
   * Watch field
   */
  watch() {
    this.watchPageSetting();

    if (this.type === 'info') {
      this.watchPageTheme();
      this.watchPageInfo();
      this.watchPageLangList();
      this.watchAccountInfo();
    }

    if (this.type === 'theme') {
      this.watchPageTheme();
      // this.watchPageType();
      // this.setupSwiperConfig();
    }
    this.setupGpsCountry();
  }

  /**
   * Unwatch field
   */
  unwatch() {
    this.unwatchPageSetting();
    this.unwatchPageTheme();
    // this.unwatchPageType();
    this.unwatchPageInfo();
    this.unwatchPageLangList();
    this.unwatchAccountInfo();
    this.clearForm();
  }

  calculateSlidePerView() {
    const width = this.getContainerWidth();
    const maxWidthPerSlide = 170;
    
    let slidesPerView = 1;
    slidesPerView = Math.floor(width / maxWidthPerSlide);
    if (!slidesPerView || slidesPerView < 1) {
      slidesPerView = 1;
    }
    slidesPerView += 0.5;
    return slidesPerView;
  }

  getContainerWidth() {
    return this.swiperGrid?.nativeElement?.offsetWidth;
  }

  clearForm() {
    this.pageSettingForm?.reset();
    this.pageInfoFormList = [];
  }

  /**
   * Watch page setting
   */
  async watchPageSetting() {
    if (!this.pageSettingSubscription) {
      this.pageSettingSubscription = this.visitorSettingService.observablePageSetting.subscribe((pageSetting: PageSetting) => {
        if (!this.pageSetting || !this.functionService.isEqual(pageSetting, this.pageSetting)) {
          if (pageSetting) {
            this.pageSetting = { ...pageSetting };
          } else {
            this.pageSetting = this.visitorSettingService.getPageSetting();
          }
          this.setupPageSettingFormValue();
        }
      });
    }
    
  }

  /**
   * Unwatch page setting
   */
  async unwatchPageSetting() {
    if (this.pageSettingSubscription) {
      this.pageSettingSubscription.unsubscribe();
      this.pageSettingSubscription = null;
    }
  }

  /**
   * Watch page theme
   */
  async watchPageTheme() {
    this.pageThemeSubscription = this.visitorThemeService.observablePageTheme.subscribe((pageTheme: string) => {
      this.pageTheme = pageTheme;
      if (!this.pageTheme) {
        this.pageTheme = '1.1';
      }
      this.setupPageTheme();
    });
  }

  /**
   * Unwatch page theme
   */
  async unwatchPageTheme() {
    if (this.pageThemeSubscription) {
      this.pageThemeSubscription.unsubscribe();
      this.pageThemeSubscription = null;
    }
  }

  setupPageTheme() {
    if (this.pageTheme === '1' || this.pageTheme === '2' || this.pageTheme === '3') {
      this.pageTheme = this.pageTheme + '.1';
    }
  }

  /**
   * Watch page info
   */
  async watchPageInfo() {
    if (!this.pageInfoSubscription) {
      this.pageInfoSubscription = this.visitorInfoService.observablePageInfo.subscribe((pageInfo: any) => {
        if (!this.pageInfo || !this.functionService.isEqual(pageInfo, this.pageInfo)) {
          if (pageInfo) {
            this.pageInfo = { ...pageInfo };
          } else {
            this.pageInfo = this.visitorInfoService.getPageInfo();
          }
          this.setupPageInfoFormValue();
        }
      });
    }
    
  }

  /**
   * Unwatch page info
   */
  async unwatchPageInfo() {
    if (this.pageInfoSubscription) {
      this.pageInfoSubscription.unsubscribe();
      this.pageInfoSubscription = null;
    }
  }

  /**
   * Watch page language list
   */
  async watchPageLangList() {
    if (!this.pageLangListSubscription) {
      this.pageLangListSubscription = this.visitorLanguageService.observablePageLangList.subscribe(async () => {
        this.pageLangList = [ ...this.visitorLanguageService.getPangeLangList() ];
        await this.setupLanguage();
        await this.setupPageInfoFormList();
      });
    }
    
  }

  /**
   * Unwatch page language list
   */
  async unwatchPageLangList() {
    if (this.pageLangListSubscription) {
      this.pageLangListSubscription.unsubscribe();
      this.pageLangListSubscription = null;
    }
  }

  /**
   * Watch Account Info
   */
  async watchAccountInfo() {
    if (!this.accountInfoSubscription) {
      this.accountInfoSubscription = this.accountInfoService.observableAccountInfo.subscribe((accountInfo: AccountInfo) => {
        this.accountInfo = accountInfo;
        if (accountInfo?.location) {
          this.pageLocation = accountInfo.location;
        }
        this.setupGpsCountry();
        this.setupAccountInfo();
      });
    }
    
  }

  /**
   * Unwatch Account Info
   */
  async unwatchAccountInfo() {
    if (this.accountInfoSubscription) {
      this.accountInfoSubscription.unsubscribe();
      this.accountInfoSubscription = null;
    }
  }

  checkVariable(variable: string) {
    if (variable === 'guest_seating') {
      if (!this.pageSetting?.seatingBeforeCheckin) {
        return false;
      }
    } else if (variable === 'number_of_guest') {
      if (!this.pageSetting?.pax) {
        return false;
      }
    } else if (variable === 'guest_session') {
      if (!this.pageSetting?.session) {
        return false;
      }
    }
    return true;
  }

  setEditMode(field: string) {
    this.resetEditMode();
    if (field && !this.functionService.isUndefined(this.editMode[field])) {
      this.ngZone.run(async () => {
        this.editMode[field] = true;
        await this.functionService.delay(20);
        if (field === 'greeting') {
          this.greetingInput?.setFocus();
        } else if (field === 'msg') {
          this.msgInput?.setFocus();
        } else if (field === 'pax') {
          this.paxInput?.setFocus();
        } else if (field === 'seating') {
          this.seatingInput?.setFocus();
        } else if (field === 'session') {
          this.sessionInput?.setFocus();
        } else if (field === 'itinerary') {
          this.itineraryInput?.setFocus();
        } else if (field === 'groupName') {
          this.groupNameInput?.setFocus();
        } else if (field === 'title') {
          this.titleInput?.setFocus();
        } else if (field === 'date') {
          this.dateInput?.setFocus();
        } else if (field === 'startTime') {
          this.startTimeInput?.setFocus();
        } else if (field === 'endTime') {
          this.endTimeInput?.setFocus();
        } else if (field === 'timezone') {
          this.timezoneInput?.setFocus();
        } else if (field === 'floorplan') {
          this.floorplanInput.setFocus();
        } else if (field === 'menu') {
          this.menuInput.setFocus();
        } else if (field === 'attachment') {
          this.attachmentInput.setFocus();
        }
      });
    }
  }

  resetEditMode() {
    this.editMode = {
      title: false,
      date: false,
      startTime: false,
      endTime: false,
      timezone: false,
      greeting: false,
      msg: false,
      pax: false,
      seating: false,
      session: false,
      itinerary: false,
      groupName: false,
      floorplan: false,
      menu: false,
      attachment: false,
    };
  }

  /**
   * Setup language
   */
  async setupLanguage() {
    if (this.pageLangList?.length) {
      if (this.langCode && this.pageLangList?.findIndex((x: Language) => x.code === this.langCode) === -1) {
        const langCode = this.languageService.getAccountLanguage()?.code ? this.languageService.getAccountLanguage().code : 'en';
        const index = this.pageLangList?.findIndex((x: Language) => x.code === langCode);
        if (index !== -1) {
          this.langCode = this.pageLangList[index].code;
        } else {
          this.langCode = this.pageLangList[0].code;
        }
      } else if (!this.langCode) {
        const langCode = this.languageService.getAccountLanguage()?.code ? this.languageService.getAccountLanguage().code : 'en';
        const index = this.pageLangList?.findIndex((x: Language) => x.code === langCode);
        if (index !== -1) {
          this.langCode = this.pageLangList[index].code;
        } else {
          this.langCode = this.pageLangList[0].code;
        }
      }
    }
    await this.getTranslation();
  }

  /**
   * Setup form
   */
  async setupForm() {
    this.validationMsg = {
      date: [{ type: 'required', msg: this.translate.instant('VALIDATION.required', {
        field: this.translate.instant('DATE.lbl.wedding_date') })
      }],
    };

    if (this.type === 'info') {
      await this.setupPageInfoFormList();
      await this.setupPageInfoValue();
    }

    if (this.type === 'setting') {
      await this.setupPageSettingForm();
      await this.setupPageSettingFormValue();
    }
  }

  /**
   * Setup page setting form
   */
  async setupPageSettingForm() {
    this.pageSettingForm = this.formBuilder.group({
      googleMap: new FormControl(true),
      waze: new FormControl(true),
      uber: new FormControl(true),
      grab: new FormControl(true),
      apple: new FormControl(true),
      pax: new FormControl(true),
      btn: new FormControl(true),
      qrcode: new FormControl(true),
      seatingBeforeCheckin: new FormControl(false),
      session: new FormControl(false),
      groupMember: new FormControl(true),
      calendar: new FormControl(true),
      backgroundUrl: new FormControl(''),
      floorplan: new FormControl(null),
      menu: new FormControl(null),
      attachment: new FormControl([]),
      webCheckin: new FormControl(false),
      webCheckinStartHour: new FormControl(0),
      webCheckinStartMinute: new FormControl(0),
      webCheckinEndHour: new FormControl(0),
      webCheckinEndMinute: new FormControl(0),
    });
  }

  /**
   * Setup page info form list based on language
   */
  async setupPageInfoFormList() {
    if (this.pageLangList) {
      this.pageLangList?.forEach((language: Language) => { 
        const languageIndex = this.pageInfoFormList?.findIndex((x: any) => this.functionService.isEqual(x.language, language));
        if (language && languageIndex === -1) {
          const form: FormGroup = this.formBuilder.group({
            greeting: new FormControl(''),
            msg: new FormControl(''),
            title: new FormControl(''),
            title2: new FormControl(''),
            pax: new FormControl(''),
            seating: new FormControl(''),
            session: new FormControl(''),
            groupName: new FormControl(''),
            groupType: new FormControl(''),
            groupMemberType: new FormControl(''),
            date: new FormControl('', [ Validators.required ]),
            startTime: new FormControl(''),
            endTime: new FormControl(''),
            cocktailTime: new FormControl(''),
            timezone: new FormControl(''),
            venue: new FormControl(''),
            hall: new FormControl(''),
            address: new FormControl(''),
            remark: new FormControl(''),
            guestPass: new FormControl(''),
            itinerary: new FormControl(''),
            floorplan: new FormControl(''),
            menu: new FormControl(''),
            attachment: new FormControl(''),
          });
          form.updateValueAndValidity();
          this.pageInfoFormList.push({
            language,
            form,
          });
          this.setupPageInfoValue(language);
        }
      });

      if (this.pageInfoFormList.length > this.pageLangList.length) {
        this.pageInfoFormList?.forEach((value: any, formIndex: number) => {
          if (value && value.language) {
            const index = this.pageLangList?.findIndex((x: Language) => this.functionService.isEqual(x, value.language));
            if (index === -1) {
              this.pageInfoFormList?.splice(formIndex, 1);
            }
          }
        });
      }
    }
  }

  /**
   * Setup page setting form value
   */
  async setupPageSettingFormValue() {
    if (this.pageSettingForm && this.pageSetting) {
      Object.keys(this.pageSetting)?.forEach((key: string) => {
        if (this.pageSettingForm?.controls?.[key]) {
          // this.pageSettingForm.controls[key].setValue(this.pageSetting[key] && this.pageSetting[key] === true ? true : false);
          this.pageSettingForm.controls[key].setValue(this.pageSetting[key]);
        }
      });
      if (this.type === 'setting' && this.enableSession && this.pageSettingForm?.controls?.session && !this.pageSettingForm?.value?.session) {
        this.pageSettingForm.controls.session.setValue(true);
      }
      this.pageSettingForm.updateValueAndValidity();
    }
  }

  /**
   * Setup page info required field
   */
  // setupPageInfoRequiredField() {
  //   if (this.pageSettingForm?.value && this.pageInfoFormList) {
  //     this.pageInfoFormList?.forEach((infoForm: any) => {
  //       if (infoForm?.form) {
  //         const form: FormGroup = infoForm.form;
  //         if (this.pageSettingForm?.value?.cocktail) {
  //           form.controls.cocktailTime.setValidators([ Validators.required ]);
  //         } else {
  //           form.controls.cocktailTime.clearValidators();
  //         }
  //         if (this.pageSettingForm?.value?.timezone) {
  //           form.controls.timezone.setValidators([ Validators.required ]);
  //         } else {
  //           form.controls.timezone.clearValidators();
  //         }
  //       }
  //     });
  //   }
  // }

  /**
   * Setup page info form value
   * @param selectedLang Selected language
   */
  async setupPageInfoValue(selectedLang?: Language) {
    await this.setupPageInfoFormValue(selectedLang);
    await this.setupAccountInfo(selectedLang);
    await this.setupPageInfoFormLocation(false, selectedLang);
    await this.setupMsg();
  }

  async setupGpsCountry() {
    this.googleMapCountry = this.checkGoogleMapCountry();
    this.wazeCountry = this.checkWazeCountry();
    this.grabCountry = this.checkGrabCountry();
    this.uberCountry = this.checkUberCountry();
  }

  /**
   * Setup page info form value
   * @param selectedLang Selected language
   */
  async setupPageInfoFormValue(selectedLang?: Language) {
    if (this.pageLangList && this.pageInfoFormList?.length) {
      this.pageLangList?.forEach((lang: Language) => {
        if (lang?.code && (!selectedLang || this.functionService.isEqual(lang, selectedLang))) {
          const languageIndex = this.pageInfoFormList?.findIndex((x: any) => {
            return this.functionService.isEqual(x.language, lang) && x?.form?.value;
          });
          if (languageIndex !== -1) {
            const form: FormGroup = this.pageInfoFormList[languageIndex].form;
            if (this.pageInfo?.[lang.code]) {
              Object.keys(this.pageInfo?.[lang.code])?.forEach((key: string) => {
                if (form.controls?.[key] && this.pageInfo[lang.code][key]) {
                  form.controls[key].setValue(this.pageInfo[lang.code][key]);
                }
              });
            }
            if (this.functionService.isUndefined(this.pageInfo?.[lang.code]?.title)) {
              form.controls.title.setValue('[WEDDING_TITLE]');
            }
            if (this.functionService.isUndefined(this.pageInfo?.[lang.code]?.date)) {
              form.controls.date.setValue('[WEDDING_DATE]');
            }
            if (this.functionService.isUndefined(this.pageInfo?.[lang.code]?.startTime)) {
              form.controls.startTime.setValue('[WEDDING_START_TIME]');
            }
            if (this.functionService.isUndefined(this.pageInfo?.[lang.code]?.endTime)) {
              form.controls.endTime.setValue('[WEDDING_END_TIME]');
            }
            if (this.functionService.isUndefined(this.pageInfo?.[lang.code]?.timezone)) {
              form.controls.timezone.setValue('[WEDDING_TIMEZONE]');
            }
            if (this.functionService.isUndefined(this.pageInfo?.[lang.code]?.msg)) {
              form.controls.msg.setValue(this.translate.instant('VISITOR.msg'));
            }
            form.updateValueAndValidity();
          }
        }
      });
    }
  }



  /**
   * Setup account info
   * @param selectedLang Selected language
   */
  async setupAccountInfo(selectedLang?: Language) {
    if (this.accountInfo && this.pageLangList?.length && this.pageInfoFormList?.length) {
      this.pageLangList?.forEach((lang: Language) => {
        if (!selectedLang || this.functionService.isEqual(lang, selectedLang)) {
          const languageIndex = this.pageInfoFormList?.findIndex((x: any) =>
            this.functionService.isEqual(x.language, lang) && x?.form?.value);
          if (languageIndex !== -1) {
            const form: FormGroup = this.pageInfoFormList[languageIndex].form;
            if (this.functionService.isUndefined(form?.value?.title)) {
              form.controls.title.setValue('[WEDDING_TITLE]');
            }
            if (!form?.value?.date) {
              form.controls.date.setValue('[WEDDING_DATE]');
            }
            if (this.functionService.isUndefined(form?.value?.startTime)) {
              form.controls.startTime.setValue('[WEDDING_START_TIME]');
            }
            if (this.functionService.isUndefined(form?.value?.endTime)) {
              form.controls.startTime.setValue('[WEDDING_END_TIME]');
            }
            if (this.functionService.isUndefined(form?.value?.timezone)) {
              form.controls.timezone.setValue('[WEDDING_TIMEZONE]');
            }
            form.updateValueAndValidity();
          }
        }
      });
    }
  }

  /**
   * Setup page info form location
   * @param update update flag
   * @param selectedLang selected language
   */
  async setupPageInfoFormLocation(update?: boolean, selectedLang?: Language) {
    if (this.pageLocation && this.pageLangList?.length && this.pageInfoFormList?.length) {
      this.pageLangList?.forEach((lang: Language) => {
        if (!selectedLang || this.functionService.isEqual(lang, selectedLang)) {
          const languageIndex = this.pageInfoFormList?.findIndex((x: any) =>
            this.functionService.isEqual(x.language, lang) && x?.form?.value);
          if (languageIndex !== -1) {
            const form: FormGroup = this.pageInfoFormList[languageIndex].form;
            if ((update || !form.value.venue)) {
              form.controls.venue.setValue(this.pageLocation.name ? this.pageLocation.name : '');
            }
            if ((update || !form.value.address)) {
              form.controls.address.setValue(this.pageLocation.address ? this.pageLocation.address : '');
            }
            form.updateValueAndValidity();
          }
        }
      });
    }
  }


  /**
   * Setup msg
   */
  async setupMsg() {
    this.pageLangList?.forEach((lang: Language) => {
      if (lang?.code && this.pageInfoFormList) {
        const index = this.pageInfoFormList?.findIndex((x: any) => this.functionService.isEqual(x.language, lang));
        if (index !== -1 && this.pageInfoFormList[index].form) {
          const form: FormGroup = this.pageInfoFormList[index].form;
          let flag = false;
          if (this.functionService.isUndefined(this.pageInfo?.[lang?.code]?.msg)) {
            if (this.translation?.[lang.code]?.VISITOR?.msg) {
              form.controls.msg.setValue(this.translation[lang.code].VISITOR.msg);
            } else {
              form.controls.msg.setValue(this.translate.instant('VISITOR.msg'));
            }
            flag = true;
          }
          if (this.functionService.isUndefined(this.pageInfo?.[lang?.code]?.greeting)) {
            if (this.translation?.[lang.code]?.VISITOR?.variable?.greeting) {
              form.controls.greeting.setValue(this.translation[lang.code].VISITOR.variable.greeting);
            } else {
              form.controls.greeting.setValue(this.translate.instant('VISITOR.variable.greeting'));
            }
            flag = true;
          }
          if (this.pageSetting?.pax && !this.pageInfo?.[lang?.code]?.pax) {
            if (this.translation?.[lang.code]?.VISITOR?.variable?.pax) {
              form.controls.pax.setValue(this.translation[lang.code].VISITOR.variable.pax);
            } else {
              form.controls.pax.setValue(this.translate.instant('VISITOR.variable.pax'));
            }
            flag = true;
          }
          if (this.pageSetting?.seatingBeforeCheckin && !this.pageInfo?.[lang?.code]?.seating) {
            if (this.translation?.[lang.code]?.VISITOR?.variable?.seating) {
              form.controls.seating.setValue(this.translation[lang.code].VISITOR.variable.seating);
            } else {
              form.controls.seating.setValue(this.translate.instant('VISITOR.variable.seating'));
            }
            // if (this.translation?.[lang.code]?.VISITOR?.variable?.seating) {
            //   form.controls.seating.setValue(this.translation[lang.code].VISITOR.variable.seating.replace('{{ seating }}', this.getSeatingTypeName()));
            // } else {
            //   form.controls.seating.setValue(this.translate.instant('VISITOR.variable.seating').replace('{{ seating }}', this.getSeatingTypeName()));
            // }
            flag = true;
          }
          if (this.pageSetting?.session && !this.pageInfo?.[lang?.code]?.session) {
            if (this.translation?.[lang.code]?.VISITOR?.variable?.session) {
              form.controls.session.setValue(this.translation[lang.code].VISITOR.variable.session);
            } else {
              form.controls.session.setValue(this.translate.instant('VISITOR.variable.session'));
            }
            flag = true;
          }
          if (this.checkGuestPass() && !this.pageInfo?.[lang?.code]?.guestPass) {
            if (this.translation?.[lang.code]?.VISITOR?.variable?.guestPass) {
              form.controls.guestPass.setValue(this.translation[lang.code].VISITOR.variable.guestPass);
            } else {
              form.controls.guestPass.setValue(this.translate.instant('VISITOR.variable.guestPass'));
            }
            flag = true;
          }
          if (!this.pageInfo?.[lang?.code]?.groupName) {
            if (this.translation?.[lang.code]?.VISITOR?.variable?.groupName) {
              form.controls.groupName.setValue(this.translation[lang.code].VISITOR.variable.groupName);
            } else {
              form.controls.groupName.setValue(this.translate.instant('VISITOR.variable.groupName'));
            }
            flag = true;
          }

          if (!this.pageInfo?.[lang?.code]?.floorplan) {
            if (this.translation?.[lang.code]?.VISITOR?.setting?.attachment?.floorplan?.title) {
              form.controls.floorplan.setValue(this.translation[lang.code].VISITOR.setting.attachment.floorplan.title);
            } else {
              form.controls.floorplan.setValue(this.translate.instant('VISITOR.setting.attachment.floorplan.title'));
            }
            flag = true;
          }

          if (!this.pageInfo?.[lang?.code]?.menu) {
            if (this.translation?.[lang.code]?.VISITOR?.setting?.attachment?.menu?.title) {
              form.controls.menu.setValue(this.translation[lang.code].VISITOR.setting.attachment.menu.title);
            } else {
              form.controls.menu.setValue(this.translate.instant('VISITOR.setting.attachment.menu.title'));
            }
            flag = true;
          }

          if (!this.pageInfo?.[lang?.code]?.attachment) {
            if (this.translation?.[lang.code]?.VISITOR?.setting?.attachment?.title) {
              form.controls.attachment.setValue(this.translation[lang.code].VISITOR.setting.attachment.title);
            } else {
              form.controls.attachment.setValue(this.translate.instant('VISITOR.setting.attachment.title'));
            }
            flag = true;
          }

          if (!this.pageInfo?.[lang?.code]?.groupType) {
            if (this.pageSetting?.qrcode) {
              if (this.translation?.[lang.code]?.QRCODE?.lbl?.group_qrcode) {
                form.controls.groupType.setValue(this.translation[lang.code].QRCODE.lbl.group_qrcode);
              } else {
                form.controls.groupType.setValue(this.translate.instant('QRCODE.lbl.group_qrcode'));
              }
            }
            flag = true;
          }
          if (!this.pageInfo?.[lang?.code]?.groupMemberType) {
            if (this.pageSetting?.qrcode) {
              if (this.translation?.[lang.code]?.QRCODE?.lbl?.guest_group_qrcode) {
                form.controls.groupMemberType.setValue(this.translation[lang.code].QRCODE.lbl.guest_group_qrcode);
              } else {
                form.controls.groupMemberType.setValue(this.translate.instant('QRCODE.lbl.guest_group_qrcode'));
              }
            } else {
              if (this.translation?.[lang.code]?.QRCODE?.lbl?.guest_group_invitation) {
                form.controls.groupMemberType.setValue(this.translation[lang.code].QRCODE.lbl.guest_group_invitation);
              } else {
                form.controls.groupMemberType.setValue(this.translate.instant('QRCODE.lbl.guest_group_invitation'));
              }
            }
            flag = true;
          }
          if (flag) {
            form.updateValueAndValidity();
          }
        }
      }
    });
  }

  async reset() {
    if (this.type === 'setting' || this.type === 'info') {
      const confirm = await this.popupService.presentConfirm(
        this.translate.instant('VISITOR.setting.reset.' + this.type)
      );
      confirm.onWillDismiss().then((result: any) => {
        if (result?.data?.confirm) {
          if (this.type === 'setting') {
            this.pageSetting = this.visitorSettingService.getDefaultSetting();
            this.setupPageSettingFormValue();
          } else if (this.type === 'info') {
            this.pageInfo = this.visitorInfoService.getDefaultPageInfo();
            this.setupPageInfoValue();
          }
        }
      });
    }
  }

  resetTitle() {
    this.pageLangList?.forEach((lang: Language) => {
      if (lang?.code && this.pageInfoFormList) {
        const index = this.pageInfoFormList?.findIndex((x: any) => this.functionService.isEqual(x.language, lang));
        if (index !== -1 && this.pageInfoFormList[index].form) {
          const form: FormGroup = this.pageInfoFormList[index].form;
          form.controls.title.setValue('[WEDDING_TITLE]');
          form.updateValueAndValidity();
        }
      }
    });
  }

  resetDate() {
    this.pageLangList?.forEach((lang: Language) => {
      if (lang?.code && this.pageInfoFormList) {
        const index = this.pageInfoFormList?.findIndex((x: any) => this.functionService.isEqual(x.language, lang));
        if (index !== -1 && this.pageInfoFormList[index].form) {
          const form: FormGroup = this.pageInfoFormList[index].form;
          form.controls.date.setValue('[WEDDING_DATE]');
          form.updateValueAndValidity();
        }
      }
    });
  }

  resetStartTime() {
    this.pageLangList?.forEach((lang: Language) => {
      if (lang?.code && this.pageInfoFormList) {
        const index = this.pageInfoFormList?.findIndex((x: any) => this.functionService.isEqual(x.language, lang));
        if (index !== -1 && this.pageInfoFormList[index].form) {
          const form: FormGroup = this.pageInfoFormList[index].form;
          form.controls.startTime.setValue('[WEDDING_START_TIME]');
          form.updateValueAndValidity();
        }
      }
    });
  }

  resetEndTime() {
    this.pageLangList?.forEach((lang: Language) => {
      if (lang?.code && this.pageInfoFormList) {
        const index = this.pageInfoFormList?.findIndex((x: any) => this.functionService.isEqual(x.language, lang));
        if (index !== -1 && this.pageInfoFormList[index].form) {
          const form: FormGroup = this.pageInfoFormList[index].form;
          form.controls.endTime.setValue('[WEDDING_END_TIME]');
          form.updateValueAndValidity();
        }
      }
    });
  }

  resetTimezone() {
    this.pageLangList?.forEach((lang: Language) => {
      if (lang?.code && this.pageInfoFormList) {
        const index = this.pageInfoFormList?.findIndex((x: any) => this.functionService.isEqual(x.language, lang));
        if (index !== -1 && this.pageInfoFormList[index].form) {
          const form: FormGroup = this.pageInfoFormList[index].form;
          form.controls.timezone.setValue('[WEDDING_TIMEZONE]');
          form.updateValueAndValidity();
        }
      }
    });
  }

  resetGreeting() {
    this.pageLangList?.forEach((lang: Language) => {
      if (lang?.code && this.pageInfoFormList) {
        const index = this.pageInfoFormList?.findIndex((x: any) => this.functionService.isEqual(x.language, lang));
        if (index !== -1 && this.pageInfoFormList[index].form) {
          const form: FormGroup = this.pageInfoFormList[index].form;
          if (this.translation?.[lang.code]?.VISITOR?.variable?.greeting) {
            form.controls.greeting.setValue(this.translation[lang.code].VISITOR.variable.greeting);
          } else {
            form.controls.greeting.setValue(this.translate.instant('VISITOR.variable.greeting'));
          }
          form.updateValueAndValidity();
        }
      }
    });
  }

  resetMsg() {
    this.pageLangList?.forEach((lang: Language) => {
      if (lang?.code && this.pageInfoFormList) {
        const index = this.pageInfoFormList?.findIndex((x: any) => this.functionService.isEqual(x.language, lang));
        if (index !== -1 && this.pageInfoFormList[index].form) {
          const form: FormGroup = this.pageInfoFormList[index].form;
          if (this.translation?.[lang.code]?.VISITOR?.msg) {
            form.controls.msg.setValue(this.translation[lang.code].VISITOR.msg);
          } else {
            form.controls.msg.setValue(this.translate.instant('VISITOR.msg'));
          }
          form.updateValueAndValidity();
        }
      }
    });
  }

  resetPax() {
    this.pageLangList?.forEach((lang: Language) => {
      if (lang?.code && this.pageInfoFormList) {
        const index = this.pageInfoFormList?.findIndex((x: any) => this.functionService.isEqual(x.language, lang));
        if (index !== -1 && this.pageInfoFormList[index].form) {
          const form: FormGroup = this.pageInfoFormList[index].form;
          if (this.translation?.[lang.code]?.VISITOR?.variable?.pax) {
            form.controls.pax.setValue(this.translation[lang.code].VISITOR.variable.pax);
          } else {
            form.controls.pax.setValue(this.translate.instant('VISITOR.variable.pax'));
          }
          form.updateValueAndValidity();
        }
      }
    });
  }

  resetSeating() {
    this.pageLangList?.forEach((lang: Language) => {
      if (lang?.code && this.pageInfoFormList) {
        const index = this.pageInfoFormList?.findIndex((x: any) => this.functionService.isEqual(x.language, lang));
        if (index !== -1 && this.pageInfoFormList[index].form) {
          const form: FormGroup = this.pageInfoFormList[index].form;
          if (this.translation?.[lang.code]?.VISITOR?.variable?.seating) {
            form.controls.seating.setValue(this.translation[lang.code].VISITOR.variable.seating);
          } else {
            form.controls.seating.setValue(this.translate.instant('VISITOR.variable.seating'));
          }
          // if (this.translation?.[lang.code]?.VISITOR?.variable?.seating) {
          //   form.controls.seating.setValue(this.translation[lang.code].VISITOR.variable.seating.replace('{{ seating }}', this.getSeatingTypeName()));
          // } else {
          //   form.controls.seating.setValue(this.translate.instant('VISITOR.variable.seating').replace('{{ seating }}', this.getSeatingTypeName()));
          // }
          form.updateValueAndValidity();
        }
      }
    });
  }

  resetSession() {
    this.pageLangList?.forEach((lang: Language) => {
      if (lang?.code && this.pageInfoFormList) {
        const index = this.pageInfoFormList?.findIndex((x: any) => this.functionService.isEqual(x.language, lang));
        if (index !== -1 && this.pageInfoFormList[index].form) {
          const form: FormGroup = this.pageInfoFormList[index].form;
          if (this.translation?.[lang.code]?.VISITOR?.variable?.session) {
            form.controls.session.setValue(this.translation[lang.code].VISITOR.variable.session);
          } else {
            form.controls.session.setValue(this.translate.instant('VISITOR.variable.session'));
          }
          form.updateValueAndValidity();
        }
      }
    });
  }

  resetGroupName() {
    this.pageLangList?.forEach((lang: Language) => {
      if (lang?.code && this.pageInfoFormList) {
        const index = this.pageInfoFormList?.findIndex((x: any) => this.functionService.isEqual(x.language, lang));
        if (index !== -1 && this.pageInfoFormList[index].form) {
          const form: FormGroup = this.pageInfoFormList[index].form;
          if (this.translation?.[lang.code]?.VISITOR?.variable?.groupName) {
            form.controls.groupName.setValue(this.translation[lang.code].VISITOR.variable.groupName);
          } else {
            form.controls.groupName.setValue(this.translate.instant('VISITOR.variable.groupName'));
          }
          form.updateValueAndValidity();
        }
      }
    });
  }

  resetFloorplan() {
    this.pageLangList?.forEach((lang: Language) => {
      if (lang?.code && this.pageInfoFormList) {
        const index = this.pageInfoFormList?.findIndex((x: any) => this.functionService.isEqual(x.language, lang));
        if (index !== -1 && this.pageInfoFormList[index].form) {
          const form: FormGroup = this.pageInfoFormList[index].form;
          if (this.translation?.[lang.code]?.VISITOR?.setting?.attachment?.floorplan?.title) {
            form.controls.floorplan.setValue(this.translation[lang.code].VISITOR.setting.attachment.floorplan.title);
          } else {
            form.controls.floorplan.setValue(this.translate.instant('VISITOR.setting.attachment.floorplan.title'));
          }
          form.updateValueAndValidity();
        }
      }
    });
  }

  resetMenu() {
    this.pageLangList?.forEach((lang: Language) => {
      if (lang?.code && this.pageInfoFormList) {
        const index = this.pageInfoFormList?.findIndex((x: any) => this.functionService.isEqual(x.language, lang));
        if (index !== -1 && this.pageInfoFormList[index].form) {
          const form: FormGroup = this.pageInfoFormList[index].form;
          if (this.translation?.[lang.code]?.VISITOR?.setting?.attachment?.menu?.title) {
            form.controls.menu.setValue(this.translation[lang.code].VISITOR.setting.attachment.menu.title);
          } else {
            form.controls.menu.setValue(this.translate.instant('VISITOR.setting.attachment.menu.title'));
          }
          form.updateValueAndValidity();
        }
      }
    });
  }

  resetAttachment() {
    this.pageLangList?.forEach((lang: Language) => {
      if (lang?.code && this.pageInfoFormList) {
        const index = this.pageInfoFormList?.findIndex((x: any) => this.functionService.isEqual(x.language, lang));
        if (index !== -1 && this.pageInfoFormList[index].form) {
          const form: FormGroup = this.pageInfoFormList[index].form;
          if (this.translation?.[lang.code]?.VISITOR?.setting?.attachment?.title) {
            form.controls.attachment.setValue(this.translation[lang.code].VISITOR.setting.attachment.title);
          } else {
            form.controls.attachment.setValue(this.translate.instant('VISITOR.setting.attachment.title'));
          }
          form.updateValueAndValidity();
        }
      }
    });
  }

  resetGroupType() {
    this.pageLangList?.forEach((lang: Language) => {
      if (lang?.code && this.pageInfoFormList) {
        const index = this.pageInfoFormList?.findIndex((x: any) => this.functionService.isEqual(x.language, lang));
        if (index !== -1 && this.pageInfoFormList[index].form) {
          
          if (this.pageSetting?.qrcode) {
            const form: FormGroup = this.pageInfoFormList[index].form;
            if (this.translation?.[lang.code]?.QRCODE?.lbl?.group_qrcode) {
              form.controls.groupType.setValue(this.translation[lang.code].QRCODE.lbl.group_qrcode);
            } else {
              form.controls.groupType.setValue(this.translate.instant('QRCODE.lbl.group_qrcode'));
            }
            form.updateValueAndValidity();
          }
          
        }
      }
    });
  }

  resetGroupMemberType() {
    this.pageLangList?.forEach((lang: Language) => {
      if (lang?.code && this.pageInfoFormList) {
        const index = this.pageInfoFormList?.findIndex((x: any) => this.functionService.isEqual(x.language, lang));
        if (index !== -1 && this.pageInfoFormList[index].form) {
          const form: FormGroup = this.pageInfoFormList[index].form;
          if (this.pageSetting?.qrcode) {
            if (this.translation?.[lang.code]?.QRCODE?.lbl?.guest_group_qrcode) {
              form.controls.groupMemberType.setValue(this.translation[lang.code].QRCODE.lbl.guest_group_qrcode);
            } else {
              form.controls.groupMemberType.setValue(this.translate.instant('QRCODE.lbl.guest_group_qrcode'));
            }
          } else {
            if (this.translation?.[lang.code]?.QRCODE?.lbl?.guest_group_invitation) {
              form.controls.groupMemberType.setValue(this.translation[lang.code].QRCODE.lbl.guest_group_invitation);
            } else {
              form.controls.groupMemberType.setValue(this.translate.instant('QRCODE.lbl.guest_group_invitation'));
            }
          }
          form.updateValueAndValidity();
        }
      }
    });
  }

  resetVenue() {
    this.pageLangList?.forEach((lang: Language) => {
      if (lang?.code && this.pageInfoFormList) {
        const index = this.pageInfoFormList?.findIndex((x: any) => this.functionService.isEqual(x.language, lang));
        if (index !== -1 && this.pageInfoFormList[index].form) {
          if (this.pageLocation?.name) {
            const form: FormGroup = this.pageInfoFormList[index].form;
            form.controls.venue.setValue(this.pageLocation.name);
            form.updateValueAndValidity();
          }
        }
      }
    });
  }

  resetAddress() {
    this.pageLangList?.forEach((lang: Language) => {
      if (lang?.code && this.pageInfoFormList) {
        const index = this.pageInfoFormList?.findIndex((x: any) => this.functionService.isEqual(x.language, lang));
        if (index !== -1 && this.pageInfoFormList[index].form) {
          if (this.pageLocation?.address) {
            const form: FormGroup = this.pageInfoFormList[index].form;
            form.controls.address.setValue(this.pageLocation.address);
            form.updateValueAndValidity();
          }
        }
      }
    });
  }

  getTemplateVariableList() {
    return this.accountEventModeService.eventMode ? TemplateVariableEvent : TemplateVariableWedding;
  }

  getSeatingTypeName(): string {
    return this.seatingSettingService.getSeatingTypeName();
  }

  /**
   * Get page info form
   * @param language Language
   */
  getPageInfoForm(langCode: string): FormGroup {
    const index = this.pageInfoFormList?.findIndex((x: any) => x.language.code === langCode);
    if (index !== -1) {
      return this.pageInfoFormList[index].form;
    } else {
      return null;
    }
  }

  async getTranslation(language?: Language) {
    if (language) {
      this.langCode = language.code;
    }
    if (this.langCode) {
      this.translation = this.languageService.translation;
      if (language) {
        await this.setupPageInfoFormList();
        await this.setupPageInfoValue(language);
        await this.popupService.dismissLoading();
      }
      // await this.unwatchTranlsation();
      // const translate = this.functionService.cloneDeep(this.translate);
      // this.translationSubscription = translate.getTranslation(this.langCode).subscribe(async (res: any) => {
      //   if (!this.translation) {
      //     this.translation = {};
      //   }
      //   if (!this.translation?.[this.langCode]) {
      //     this.translation[this.langCode] = {};
      //   }
      //   this.translation[this.langCode] = res;
      //   if (language) {
      //     await this.setupPageInfoFormList();
      //     await this.setupPageInfoValue(language);
      //     await this.popupService.dismissLoading();
      //   }
      //   await this.unwatchTranlsation();
      // });
    }
  }

  async unwatchTranlsation() {
    if (this.translationSubscription) {
      this.translationSubscription.unsubscribe();
      this.translationSubscription = null;
    }
  }

  getTranslate(variable: string) {
    let result: string;
    if (this.translation?.[this.langCode]) {
      if (variable === 'TEMPLATE.variable.guest_name' && this.translation[this.langCode]?.TEMPLATE?.variable?.guest_name) {
        result = this.translation[this.langCode].TEMPLATE.variable.guest_name;
      } else if (variable === 'TEMPLATE.variable.guest_alternate_name' && this.translation[this.langCode]?.TEMPLATE?.variable?.guest_alternate_name) {
        result = this.translation[this.langCode].TEMPLATE.variable.guest_alternate_name;
      } else if (variable === 'TEMPLATE.variable.guest_seating' && this.translation[this.langCode]?.TEMPLATE?.variable?.guest_seating) {
        result = this.translation[this.langCode].TEMPLATE.variable.guest_seating;
      } else if (variable === 'TEMPLATE.variable.number_of_guest' && this.translation[this.langCode]?.TEMPLATE?.variable?.number_of_guest) {
        result = this.translation[this.langCode].TEMPLATE.variable.number_of_guest;
      } else if (variable === 'TEMPLATE.variable.guest_session' && this.translation[this.langCode]?.TEMPLATE?.variable?.guest_session) {
        result = this.translation[this.langCode].TEMPLATE.variable.guest_session;
      } else if (variable === 'TEMPLATE.variable.group_name' && this.translation[this.langCode]?.TEMPLATE?.variable?.group_name) {
        result = this.translation[this.langCode].TEMPLATE.variable.group_name;
      } else if (variable === 'TEMPLATE.variable.seating_type' && this.translation[this.langCode]?.TEMPLATE?.variable?.seating_type) {
        result = this.translation[this.langCode].TEMPLATE.variable.seating_type;
      }
    }
    if (!result) {
      result = this.translate.instant(variable) !== variable ? this.translate.instant(variable) : '';
    }
    return this.replaceEventType(result);
  }

  getHtml(template: string) {
    // if (template) {
    //   template = this.functionService.replaceNewLine(template, '<br>');
    // }
    const variableList = this.getTemplateVariableList();
    variableList.forEach((variable: string) => {
      const field = '[' + variable.toUpperCase() + ']';
      let newField = '';
      // if (variable === 'wedding_title') {
      //   newField = '<span class="variable">' + this.accountInfoService.accountTitle + '</span>';
      // } else if (variable === 'wedding_date') {
      //   newField = '<span class="variable">' + this.accountInfoService.accountTitle + '</span>';
      // } else if (variable === 'wedding_start_time') {
      //   newField = '<span class="variable">' + this.accountInfoService.accountTitle + '</span>';
      // } else if (variable === 'wedding_timezone') {
      //   newField = '<span class="variable">' + this.accountInfoService.accountTitle + '</span>';
      // } else {
      //   newField = '<span class="variable">' + this.replaceEventType(this.translate.instant('TEMPLATE.variable.' + variable)) + '</span>';
      // }
      newField = '<span class="variable">' + this.replaceEventType(this.translate.instant('TEMPLATE.variable.' + variable)) + '</span>';
      template = this.functionService.replaceAll(template, field, newField);
    });

    return template;
  }

  checkGuestPass(): boolean {
    if (this.pageTheme === '6.1' || this.pageTheme === '7.1') {
      return true;
    }
    return false;
  }

  /**
   * Check if uber is available in account country
   * @returns True if uber is available
   */
  checkUberCountry(): boolean {
    return this.visitorSettingService.checkUberCountry();
  }

  checkGrabCountry(): boolean {
    return this.visitorSettingService.checkGrabCountry();
  }

  /**
   * Check if google map is available in account country
   * @returns True if google map is available
   */
  checkGoogleMapCountry(): boolean {
    return this.visitorSettingService.checkGoogleMapCountry();
  }

  /**
   * Check if waze is available in account country
   * @returns True if waze is available
   */
  checkWazeCountry(): boolean {
    return this.visitorSettingService.checkWazeCountry();
  }

  /**
   * Update GPS setting
   * @param type GPS type
   */
  updateGpsSetting(type: GpsType) {
    if (this.pageSettingForm) {
      this.pageSettingForm?.controls?.[type]?.setValue(this.pageSettingForm?.value?.[type] ? false : true);
    }
  }

  updateAttachment(type: AttachmentType) {
    if (type) {
      if (type === 'floorplan') {
        let attachment: VisitorAttachment = this.pageSettingForm?.value?.floorplan;
        if (!attachment?.id) {
          attachment = {
            id: type,
            title: {
              custom: false,
              value: type,
            },
            enable: true,
            url: '',
            mimeType: '',
            type,
          };
        } else {
          attachment.enable = attachment?.enable ? false :  true;
        }
        this.pageSettingForm?.controls?.floorplan.setValue(attachment);
      } else if (type === 'menu') {
        let attachment: VisitorAttachment = this.pageSettingForm?.value?.menu;
        if (!attachment?.id) {
          attachment = {
            id: type,
            title: {
              custom: false,
              value: type,
            },
            enable: true,
            url: '',
            mimeType: '',
            type,
          };
        } else {
          attachment.enable = attachment?.enable ? false :  true;
        }
        this.pageSettingForm?.controls?.menu.setValue(attachment);
      } else if (type === 'attachment') {
        let attachment: VisitorAttachment = this.pageSettingForm?.value?.attachment?.[0];
        if (!attachment?.id) {
          attachment = {
            id: type,
            title: {
              custom: false,
              value: type,
            },
            enable: true,
            url: '',
            mimeType: '',
            type,
          };
        } else {
          attachment.enable = attachment?.enable ? false :  true;
        }
        this.pageSettingForm?.controls?.attachment.setValue([ attachment ]);
      }
    }
  }

  /**
   * New language based on available language
   */
  newLanguage() {
    if (this.languageList?.length) {
      const options = this.languageList.filter((value: Language) => {
        if (this.pageLangList?.findIndex((x: Language) => x.code === value.code) !== -1
        || this.langCode === value.code) {
          return false;
        } else {
          return true;
        }
      }).map((value: Language) => {
        return {
          text: value.name,
          value
        };
      });
      if (options?.length) {
        this.presentLanguagePicker(options);
      }
    }
  }

  /**
   * Present language picker
   * @param options Picker options
   * @param selectedIndex Selected language index
   */
  async presentLanguagePicker(options: any[], selectedIndex?: number) {
    const picker = await this.pickerController.create({
      columns: [{
        name: 'language',
        selectedIndex,
        options
      }],
      buttons: [{
        text: this.translate.instant('BTN.cancel'),
        role: 'cancel'
      }, {
        text: this.translate.instant('BTN.confirm'),
        handler: ((result: any) => {
          if (result?.language?.value) {
            this.addLanguage(result.language.value);
          }
        })
      }]
    });
    picker.present();
  }

  async addLanguage(language: Language) {
    const index = this.pageLangList?.findIndex((x: Language) => x.code === language.code);
    if (index === -1) {
      await this.popupService.presentLoading();
      this.pageLangList.push(language);
      this.langCode = language.code;
      await this.getTranslation(language);
    }
  }

  /**
   * Present navigation picker
   * @param options Picker option
   * @param selectedIndex Selected navigation index
   */
  async presentNavigationPicker(options: any[], selectedIndex?: number) {
    const picker = await this.pickerController.create({
      columns: [{
        name: 'gps',
        selectedIndex,
        options
      }],
      buttons: [{
        text: this.translate.instant('BTN.cancel'),
        role: 'cancel'
      }, {
        text: this.translate.instant('BTN.confirm'),
        handler: (result: any) => {
          if (result?.gps?.value) {
            if (result.gps.value === 'google') {
              this.gpsService.openGoogleNav(this.pageLocation);
            } else if (result.gps.value === 'waze') {
              this.gpsService.openWazeNav(this.pageLocation);
            } else if (result.gps.value === 'uber') {
              this.gpsService.openUberNav(this.pageLocation);
            } else if (result.gps.value === 'grab') {
              this.gpsService.openGrab(this.pageLocation);
            } else if (result.gps.value === 'appleMap') {
              this.gpsService.openAppleNav(this.pageLocation);
            } else {
              this.gpsService.openGoogleNav(this.pageLocation);
            }
          }
        }
      }]
    });
    picker.present();
  }

  async presentSelfCheckinTimePicker(type: string, hour?: number, minute?: number) {
    const hourList: PickerColumnOption[] = WebCheckinHourList;
    const minuteList: PickerColumnOption[] = WebCheckinMinuteList;

    const hourIndex = hour ? hourList.findIndex((x: PickerColumnOption) => x.value === hour) : 0;
    const minuteIndex = minute ? minuteList.findIndex((x: PickerColumnOption) => x.value === minute) : 0;

    const picker = await this.pickerController.create({
      columns: [{
        name: 'hour',
        selectedIndex: hourIndex,
        options: hourList,
      }, {
        name: 'minute',
        selectedIndex: minuteIndex,
        options: minuteList
      }],
      buttons: [{
        text: this.translate.instant('BTN.cancel'),
        role: 'cancel'
      }, {
        text: this.translate.instant('BTN.confirm'),
        handler: (result: any) => {
          if (type === 'start') {
            this.pageSettingForm.controls.webCheckinStartHour.setValue(result?.hour?.value);
            this.pageSettingForm.controls.webCheckinStartMinute.setValue(result?.minute?.value);
          } else if (type === 'end') {
            this.pageSettingForm.controls.webCheckinEndHour.setValue(result?.hour?.value);
            this.pageSettingForm.controls.webCheckinEndMinute.setValue(result?.minute?.value);
          }
        }
      }]
    });
    picker.present();
  }

  /**
   * Present location modal for venue setup
   * @param location Location
   */
  async presentLocationModal(location?: LocationDetail) {
    const country: Country = this.localityService.getAccountCountry();
    const modal = await this.modalController.create({
      component: LocationComponent,
      cssClass: 'modal-full-screen-bk',
      componentProps: {
        country,
        location
      }
    });
    modal.present();
    modal.onWillDismiss().then((result: any) => {
      if (result?.data?.location) {
        if (!this.functionService.isEqual(this.pageLocation, result.data.location)) {
          this.pageLocation = result.data.location;
          this.setupPageInfoFormLocation(true);
        }
      }
    });
  }

  /**
   * Present visitor page modal
   * @param previewData Preview data
   */
  async presentVisitorPageModal(previewData?: any) {
    const modal = await this.modalController.create({
      component: VisitorPageComponent,
      cssClass: 'modal-full-screen',
      componentProps: {
        preview: true,
        accountId: this.accountInfo?.accountId,
        previewData
      }
    });
    modal.present();
  }

  setupSelfCheckinTime() {
    if (this.pageSettingForm.value.webCheckin) {
      this.pageSettingForm.controls.webCheckinStartHour.setValue(2);
      this.pageSettingForm.controls.webCheckinStartMinute.setValue(0);
      this.pageSettingForm.controls.webCheckinEndHour.setValue(2);
      this.pageSettingForm.controls.webCheckinEndMinute.setValue(0);
    }
  }

  selectPhoto() {
    this.photoService.selectPhoto('background_img', true);
  }

  selectFloorplan() {
    this.fileService.selectFile('floorplan', -1, true);
  }

  selectAttachment() {
    this.fileService.selectFile('attachment', -1, true);
  }

  selectMenu() {
    this.fileService.selectFile('menu', -1, true);
  }

  getAttachmentByType(type: AttachmentType) {
    return this.visitorSettingService.getAttachmentByType(type);
  }

  /**
   * Slide to delete language
   * @param slidingItem Sliding item
   */
  async slideDelete(slidingItem?: any) {
    if (this.pageLangList?.length > 1) {
      const modal = await this.popupService.presentConfirm(
        this.translate.instant('CRUD.confirm_delete_field', { field: this.translate.instant('BTN.language') })
      );
      modal.onWillDismiss().then(async (result: any) => {
        if (result?.data?.confirm) {
          const index = this.pageLangList?.findIndex((x: Language) => x.code === this.langCode);
          if (index !== -1) {
            this.pageLangList?.splice(index, 1);
            await this.setupLanguage();
            await this.setupPageInfoFormList();
          }
        }
      });
    } else {
      await this.popupService.presentToast(this.translate.instant('QRCODE.msg.delete_last_record'), 'danger');
    }
    if (slidingItem) { slidingItem.close(); }
  }

  checkPageInfoDiff() {
    let diff = false;
    const pageInfo = this.generatePageInfo();
    if (!this.functionService.isEmpty(pageInfo)) {
      if (this.functionService.isEmpty(this.pageInfo)) {
        diff = true;
      } else {
        Object.keys(pageInfo)?.forEach((langCode: string) => {
          if (pageInfo?.[langCode] && !this.functionService.isEmpty(pageInfo[langCode])) {
            Object.keys(pageInfo[langCode]).forEach((key: string) => {
              if (!this.functionService.isEqual(pageInfo[langCode][key], this.pageInfo?.[langCode]?.[key])) {
                diff = true;
              }
            });
          }
        });
      }
    }
    return diff;
  }

  /**
   * Discard setting
   */
  async discard() {
    if ((this.type === 'setting' && this.pageSetting && !this.functionService.isEqual(this.pageSetting, this.pageSettingForm?.value))
      || (this.type === 'info' && this.pageInfo && !this.functionService.isEqual(this.pageInfo, this.generatePageInfo()))
      || (this.type === 'theme' && ((this.visitorThemeService.pageTheme && this.visitorThemeService.pageTheme !== this.pageTheme)))
    ) {
      const actionSheet = await this.actionSheetController.create({
        header: this.translate.instant('MSG.discard_msg'),
        buttons: [{
          text: this.translate.instant('BTN.confirm'),
          role: 'destructive',
          icon: 'trash',
          handler: () => {
            this.dismissModal();
          }
        }, {
          text: this.translate.instant('BTN.cancel'),
          icon: 'close',
          role: 'cancel',
          handler: () => {
          }
        }]
      });
      actionSheet.present();
    } else {
      this.dismissModal();
    }
  }

  /**
   * Copy Qr template variable
   * @param field Qr template variable field
   */
  async copy(field: TemplateVariableType) {
    const variableList = this.getTemplateVariableList();
    if (field && variableList?.indexOf(field) !== -1) {
      await this.popupService.presentLoading();
      
      try {
        await Clipboard.write({ string: '[' + field.toUpperCase() + ']' });
        await this.popupService.dismissLoading();
        await this.popupService.presentToast(this.translate.instant('COPY.success'), 'success');
      } catch (err: any) {
        await this.popupService.dismissLoading();
        await this.popupService.presentToast(this.translate.instant('COPY.fail'), 'danger');
      }
    }
  }

  /**
   * Dismiss page setting modal
   */
  async dismissModal() {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { this.modalController.dismiss(); }
    }
  }

  /**
   * Valid page info form list
   */
  async validPageInfoFormList() {
    let flag = true;
    if (this.pageInfoFormList) {
      this.pageInfoFormList?.forEach((obj: any) => {
        if (flag && obj.form && obj.language) {
          this.ngZone.run(() => {
            const language: Language = obj.language;
            const form: FormGroup = obj.form;
            form.updateValueAndValidity();
            form.markAllAsTouched();
            this.ngZone.run(() => {
              if (!form.valid) {
                flag = false;
                this.ngZone.run(() => {
                  this.type = 'info';
                  this.langCode = language.code;
                  this.getTranslation();
                });
                return flag;
              }
            });
          });
        }
      });
    }
    return flag;
  }

  /**
   * Generate page info
   */
  generatePageInfo() {
    const pageInfo: any = {};
    if (this.pageInfoFormList) {
      this.pageInfoFormList?.forEach((infoForm: any) => {
        if (infoForm?.language?.code && infoForm?.form) {
          pageInfo[infoForm.language.code] = infoForm.form.value;
        }
      });
    }

    if (this.pageInfo) {
      Object.keys(this.pageInfo)?.forEach((langCode: string) => {
        if (!pageInfo?.[langCode] && this.pageLangList?.findIndex((x: Language) => x.code === langCode) === -1) {
          pageInfo[langCode] = this.functionService.firestoreDeleteField;
        }
      });
    }
    return pageInfo;
  }

  /**
   * Generate data for update
   */
  async generateData() {
    const data: any = {};
    const pageInfo = this.generatePageInfo();

    if (this.type === 'setting') {
      if (!this.functionService.isEqual(this.pageSettingForm?.value, this.pageSetting)) {
        data.setting = this.pageSettingForm.value;
      }
    }

    if (this.type === 'theme') {
      if (this.pageTheme !== this.visitorThemeService.pageTheme) {
        data.themeId = this.pageTheme;
      }
    }

    if (this.type === 'info') {
      if (!this.functionService.isEqual(this.pageLangList, this.visitorLanguageService.pageLangList)) {
        data.languageList = this.pageLangList;
      }
      if (pageInfo) {
        data.info = pageInfo;
      }
      // if (this.pageLocation && !this.functionService.isEqual(this.pageLocation, this.accountInfoService.accountInfo?.location)) {
      //   data.location = this.pageLocation;
      // }
    }
    return data;
  }

  /**
   * Preview visitor page
   */
  async preview() {
    let valid = false;
    if (this.type === 'setting') {
      this.pageSettingForm?.markAllAsTouched();
      if (this.pageSettingForm?.valid) {
        valid = true;
      }
    } else if (this.type === 'info') {
      if (await this.validPageInfoFormList()) {
        valid = true;
      }
    } else if (this.type === 'theme') {
      valid = true;
    }

    if (valid) {
      const data: any = await this.generateData();
      this.presentVisitorPageModal({ ...data });
    } else {
      this.popupService.presentAlert(this.translate.instant('MSG.action_error'));
    }
  }

  /**
   * Test gps navigation
   */
  testGps() {
    const options = [];
    if (this.pageSetting?.googleMap) {
      options.push({
        value: 'google',
        text: this.translate.instant('VISITOR.setting.lbl.google'),
      });
    }
    if (this.pageSetting?.waze) {
      options.push({
        value: 'waze',
        text: this.translate.instant('VISITOR.setting.lbl.waze'),
      });
    }
    if (this.pageSetting?.uber) {
      options.push({
        value: 'uber',
        text: this.translate.instant('VISITOR.setting.lbl.uber'),
      });
    }
    if (this.pageSetting?.grab) {
      options.push({
        value: 'grab',
        text: this.translate.instant('VISITOR.setting.lbl.grab'),
      });
    }

    if (this.pageSetting?.apple) {
      options.push({
        value: 'appleMap',
        text: this.translate.instant('VISITOR.setting.lbl.apple'),
      });
    }

    if (options?.length > 1) {
      this.presentNavigationPicker(options);
    } else if (this.pageSetting?.googleMap) {
      this.gpsService.openGoogleNav(this.pageLocation);
    } else if (this.pageSetting?.waze) {
      this.gpsService.openWazeNav(this.pageLocation);
    } else if (this.pageSetting?.uber) {
      this.gpsService.openUberNav(this.pageLocation);
    } else if (this.pageSetting?.grab) {
      this.gpsService.openGrab(this.pageLocation);
    } else if (this.pageSetting?.apple) {
      this.gpsService.openAppleNav(this.pageLocation);
    } else {
      this.gpsService.openGoogleNav(this.pageLocation);
    }
  }

  openGrab() {
    if (this.pageLocation && !this.functionService.isEmpty(this.pageLocation)) {
      let url = 'https://grab.onelink.me/2695613898?af_dp=grab://open?screenType=BOOKING&sourceID=&c=&pid=';
      if (this.pageLocation?.coordinate) {
        url = url + '&dropOffLatitude=' + this.pageLocation.coordinate.lat + '&dropOffLongitude=' + this.pageLocation.coordinate.lng;
      }
      // if (this.pageLocation.name) {
      //   url = url + '&dropoff[nickname]=' + this.pageLocation.name;
      // }
      if (this.pageLocation.address) {
        url = url + '&dropOffAddress=' + this.functionService.replaceAll(this.pageLocation.address, ' ', '+');
      }
      this.openGps(url);
    }
  }

  async checkSave() {
    let valid = false;
    if (this.type === 'setting') {
      this.pageSettingForm?.markAllAsTouched();
      if (this.pageSettingForm?.valid) {
        valid = true;
      }
    } else if (this.type === 'info') {
      if (await this.validPageInfoFormList()) {
        valid = true;
      }
    } else if (this.type === 'theme') {
      if (this.pageTheme) {
        valid = true;
      }
    }
    return valid;
  }

  /**
   * Save data
   */
  async save() {
    const valid = await this.checkSave();
    if (valid) {
      const data: any = await this.generateData();
      if (!this.functionService.isEmpty(data)) {
        await this.visitorManageService.savePageSetting(data);
        this.popupService.saveSuccessToast();
      }
      this.dismissModal();
    } else {
      this.popupService.presentAlert(this.translate.instant('MSG.action_error'));
    }
  }

  getPreviewImgName(themeId: string) {
    return themeId?.replace('.', '_');
  }

  selectTheme(themeId: string) {
    if (this.pageTheme === themeId) {
      this.pageTheme = '';
    } else {
      this.pageTheme = themeId;
    }
  }

  setPageTheme(pageTheme: string) {
    this.pageTheme = pageTheme;
  }

  /**
   * Ion Slide Change
   */
  async ionSlideChange() {
    // const prom1 = this.ionSlides.isBeginning();
    // const prom2 = this.ionSlides.isEnd();
    // Promise.all([prom1, prom2]).then((data) => {
    //   data[0] ? this.disablePrevBtn = true : this.disablePrevBtn = false;
    //   data[1] ? this.disableNextBtn = true : this.disableNextBtn = false;
    // });
  }

  loadData(event: any) {
    setTimeout(() => {
      if (this.listLimit >= this.themeList.length) {
        // event.target.disabled = true;
      } else {
        this.listLimit += 4;
      }
      event.target.complete();
    }, 500);
  }

  trackByFn(index: number, item: Theme) {
    if (item?.themeId) {
      return item?.themeId;
    }
    return index;
  }

  async openGps(url: string) {
    url = encodeURI(url);
    if (this.platform.is('ios') && this.platform.is('hybrid')) {
      window.open(url, '_system');
    } else {
      this.openUrl(url);
    }
  }

  async openUrl(url: string) {
    this.linkService.openUrl(url);
  }

  replaceEventType(text: string) {
    return this.accountEventModeService.replaceEventType(text);
  }

}
