import { GuestDeleteService } from 'src/app/services/guest/guest-delete.service';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { ModuleService } from 'src/app/services/account/privilege/module.service';
import { PrivilegeService } from 'src/app/services/account/privilege/privilege.service';
import { GuestService } from 'src/app/services/guest/guest.service';
import { GuestListService } from 'src/app/services/guest/guest-list.service';
import { GroupService } from 'src/app/services/group/group.service';
import { GiftService } from 'src/app/services/gift/gift.service';
import { GiftManageService } from 'src/app/services/gift/gift-manage.service';
import { PopupService } from 'src/app/services/general/popup.service';
import { FunctionService } from 'src/app/services/general/function.service';

import { GiftLogComponent } from 'src/app/components/log/gift-log/gift-log.component';
import { GuestListComponent } from 'src/app/components/guest/guest-list/guest-list.component';
import { GiftManageComponent } from 'src/app/components/gift/gift-manage/gift-manage.component';

import { Guest } from 'src/app/interfaces/guest';
import { Group } from 'src/app/interfaces/group';
import { Gift } from 'src/app/interfaces/gift';
import { ModuleType } from 'src/app/types/general';
import { GiftDeleteService } from 'src/app/services/gift/gift-delete.service';
import { GuestEditComponent } from 'src/app/components/guest/guest-edit/guest-edit.component';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { CurrentPrivilege } from 'src/app/interfaces/privilege';

/**
 * Giftr profile component
 */
@Component({
  selector: 'app-gift-profile',
  templateUrl: './gift-profile.component.html',
  styleUrls: ['./gift-profile.component.scss'],
})
export class GiftProfileComponent implements OnInit, OnDestroy {

  @ViewChild(CdkVirtualScrollViewport) cdkVirtualScrollViewport: CdkVirtualScrollViewport;
  /**
   * Guest count
   */
  count: number;
  /**
   * Gift
   */
  gift: Gift;
  /**
   * Guest list
   */
  guestList: Guest[];
  /**
   * Edit mode
   */
  editMode: boolean;
  /**
   * Select all
   */
  selectAll: boolean;
  /**
   * Selected guest list
   */
  selectedGuestList: string[];
  /**
   * Selected group list
   */
  selectedGroupList: string[];
  /**
   * AccountModule
   */
  module: ModuleType;

  viewportHeight: number;

  currentPrivilege: CurrentPrivilege;

  /**
   * Gift list subscription
   */
  private giftListSubscription: Subscription;

  private privilegeSubscription: Subscription;

  /**
   * Constructor
   * @param translate translate service 
   * @param modalController modal controller
   * @param guestService guest service
   * @param guestListService guest list service
   * @param groupService group service
   * @param giftService gift service
   * @param giftManageService gift manage service
   * @param popupService popup service
   * @param functionService function service
   */
  constructor(
    private translate: TranslateService,
    private modalController: ModalController,
    private guestService: GuestService,
    private guestListService: GuestListService,
    private guestDeleteService: GuestDeleteService,
    private groupService: GroupService,
    private giftService: GiftService,
    private giftManageService: GiftManageService,
    private giftDeleteService: GiftDeleteService,
    private moduleService: ModuleService,
    private privilegeService: PrivilegeService,
    private popupService: PopupService,
    private functionService: FunctionService,
  ) { }

  ngOnInit(): void {
      
  }

  ngOnDestroy(): void {
      this.unwatchGiftList();
      this.unwatchPrivilege();
  }

  /**
   * Before view will enter
   */
  ionViewWillEnter() {
    if (!this.module) {
      this.module = this.moduleService.currentModule;
    }
    this.selectedGuestList = [];
    this.selectedGroupList = [];
    this.watchGiftList();
    this.watchPrivilege();
  }

  /**
   * Before view will leave
   */
  ionViewWillLeave() {
    this.unwatchGiftList();
    this.unwatchPrivilege();
  }

  /**
   * Watch gift list
   */
  async watchGiftList() {
    if (!this.giftListSubscription) {
      this.giftListSubscription = this.giftService.observableGiftList.subscribe(() => {
        this.setupGift();
      });
    }
    
  }

  /**
   * Unwatch gift list
   */
  async unwatchGiftList() {
    if (this.giftListSubscription) {
      this.giftListSubscription.unsubscribe();
      this.giftListSubscription = null;
    }
  }

  async watchPrivilege() {
    if (!this.privilegeSubscription) {
      this.privilegeSubscription = this.privilegeService.observableCurrentPrivilege.subscribe(() => {
        this.setupPrivilege();
      })
    }
  }

  async unwatchPrivilege() {
    if (this.privilegeSubscription) {
      this.privilegeSubscription.unsubscribe();
      this.privilegeSubscription = null;
    }
  }

  setupPrivilege() {
    if (!this.currentPrivilege) {
      this.currentPrivilege = {};
    }
    this.currentPrivilege = {
      'gift': {
        'edit': this.checkPrivilege('gift', 'edit'),
      },
    };
  }

  /**
   * Setup gift
   */
  setupGift() {
    this.count = 0;
    if (this.gift?.giftId) {
      const gift = this.giftService.getGiftById(this.gift.giftId);
      let guestIdList: string[] = [];
      if ((this.gift?.guestIdList && !this.guestList?.length) || this.isEqual(this.gift?.guestIdList, gift?.guestIdList)) {
        guestIdList = this.gift.guestIdList;
      }
      if (this.isEqual(this.gift?.groupIdList, gift?.groupIdList)) {
        const groupList = gift?.groupIdList?.length ? this.groupService.getGroupListById(gift.groupIdList) : [];
        groupList?.forEach((group: Group) => {
          if (group?.memberList?.length) {
            guestIdList = [...new Set([...guestIdList, ...group.memberList])];
          }
        });
      }

      const guestList = this.guestService.getGuestListById(guestIdList);
      this.count = guestList.length;
      if (!this.count) {
        this.count = 0;
      }
      this.guestList = this.guestListService.generateGroupList(guestList);
      this.setupViewportHeight();
      this.setupViewport();
      this.gift = gift;
    }
  }

  setupViewport() {
    if (this.cdkVirtualScrollViewport) {
      setTimeout(() => {
        this.cdkVirtualScrollViewport?.checkViewportSize();
      }, 200);
    } else {
      setTimeout(() => {
        this.setupViewport();
      }, 200);
    }
  }

  /**
   * Check is two variable is equal
   * @param a variable a
   * @param b variable b
   * @returns true if two varible is equal
   */
  isEqual(a: any, b: any): boolean {
    return this.functionService.isEqual(a, b);
  }

  /**
   * Toggle edit mode
   */
  toggleEditMode(editMode?: boolean) {
    if (this.functionService.isUndefined(editMode)) {
      this.editMode = !this.editMode;
    } else {
      this.editMode = editMode;
    }
    this.resetSelectAll();
  }

  /**
   * Reset select all
   */
   resetSelectAll() {
    this.selectAll = false;
    this.selectedGuestList = [];
    this.selectedGroupList = [];
  }

  /**
   * Toggle select all
   */
  toggleSelectAll() {
    this.selectAll = !this.selectAll;

    this.selectedGuestList = [];
    this.selectedGroupList = [];

    if (this.selectAll) {
      this.guestList?.forEach((guest: Guest) => {
        if (guest?.group?.groupId) {
          this.setSelectedGroup(guest.group.groupId, true);
        } else if (guest?.guestId) {
          this.selectedGuestList.push(guest.guestId);
        }
      });
    }
  }

  /**
   * Check select all
   */
  checkSelectAll() {
    if (this.count && this.selectedGuestList?.length === this.count) {
      this.selectAll = true;
    } else {
      this.selectAll = false;
    }
  }

  /**
   * Set selected group
   * @param groupId group id
   * @param selected selected flag
   * @param skipCheck skip check
   */
  setSelectedGroup(groupId: string, selected?: boolean, skipCheck?: boolean) {
    const index = this.selectedGroupList?.indexOf(groupId);
    if (index === -1) {
      if (this.functionService.isUndefined(selected) || selected === true) {
        this.selectedGroupList.push(groupId);
        if (!skipCheck) {
          this.checkSelectedGroup(groupId, true, this.functionService.isUndefined(selected) ? false : true);
        }
      }
    } else {
      if (this.functionService.isUndefined(selected) || selected === false) {
        if (this.selectedGroupList?.length) {
          this.selectedGroupList?.splice(index, 1);
        }
        if (!skipCheck) {
          this.checkSelectedGroup(groupId, false, this.functionService.isUndefined(selected) ? false : true);
        }
      }
    }
  }

  /**
   * Check selected group
   * @param groupId group id
   * @param selected selected flag
   * @param skipCheck skip check flag
   */
  checkSelectedGroup(groupId: string, selected?: boolean, skipCheck?: boolean) {
    const index = this.guestList?.findIndex((x) => x?.group?.groupId === groupId);
    let memberList: string[] = [];
    if (index !== -1 && this.guestList?.[index]?.group?.memberList?.length) {
      memberList = this.guestList[index].group.memberList;
    }

    if (memberList?.length) {
      let flag = true;
      memberList?.forEach((guestId: string) => {
        const selectedIndex = this.selectedGuestList?.indexOf(guestId);
        if (selectedIndex === -1) {
          flag = false;
          if (!this.functionService.isUndefined(selected) && selected === true) {
            this.selectedGuestList.push(guestId);
          }
        } else {
          if (!this.functionService.isUndefined(selected) && selected === false) {
            this.selectedGuestList?.splice(selectedIndex, 1);
          }
        }
      });
      if (this.functionService.isUndefined(selected)) {
        this.setSelectedGroup(groupId, flag, true);
      }
    }

    if (!skipCheck) {
      this.checkSelectAll();
    }
  }

  /**
   * Bulk action
   * @param type bulk action type
   */
  async bulkAction(type: string) {
    if (type === 'add') {
      await this.presentGuestListModal();
      this.toggleEditMode(false);
    } else {
      this.checkSingleRecord();
      if (type === 'delete') {
        if (this.module === 'trash') {
          this.promptDelete();
        } else {
          this.promptRemove();
        }
      } else if (type === 'info') {
        await this.presentGuestEditModal();
        this.toggleEditMode(false);
      }
    }
  }

  /**
   * Check if single record then trigger select all
   */
  checkSingleRecord() {
    if (!this.selectAll && this.count === 1) {
      this.toggleSelectAll();
    }
  }

  /**
   * Check user privilege
   * @param action Action
   */
  checkPrivilege(module: ModuleType, action: string): boolean {
    return this.privilegeService.checkCurrentUserPrivilege(module, action);
  }

  /**
   * Set selected guest
   * @param guestId guest id
   */
  setSelectedGuest(guestId: string) {
    const index = this.selectedGuestList?.indexOf(guestId);
    if (index === -1) {
      this.selectedGuestList.push(guestId);
    } else {
      this.selectedGuestList?.splice(index, 1);
    }
    this.checkSelectAll();
  }

  /**
   * Dismiss modal
   */
  async dismissModal() {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { this.modalController.dismiss(); }
    }
  }

  /**
   * Present gift manage modal
   * @param gift Gift
   */
  async presentGiftManageModal(gift?: Gift) {
    if (gift?.giftId) {
      const modal = await this.modalController.create({
        component: GiftManageComponent,
        componentProps: {
          gift,
          module: this.module
        }
      });
      modal.present();
      modal.onDidDismiss().then((result: any) => {
        if (result?.data?.dismiss) {
          this.dismissModal();
        }
      });
    } else {
      this.popupService.presentActionError();
    }
  }

  /**
   * Presesnt guest edit modal.
   * Trigger bulk edit mode if selected guest list > 1
   */
  async presentGuestEditModal() {
    if (this.checkPrivilege(this.module, 'edit')) {
      if (this.selectedGuestList?.length) {
        let guest: Guest;
        let bulkEditMode = true;
        if (this.selectedGuestList?.length === 1) {
          bulkEditMode = false;
          guest = this.guestService.getGuest(this.selectedGuestList[0]);
        }
        const modal = await this.modalController.create({
          component: GuestEditComponent,
          cssClass: '',
          componentProps: {
            bulkEditMode,
            guest,
            selectedGuestList: bulkEditMode ? this.selectedGuestList : []
          }
        });
        modal.present();
      }
    } else {
      this.popupService.presentAlert(this.translate.instant('ACCOUNT_PRIVILEGE.msg.no_privilege'));
    }
  }

  /**
   * Present guest list modal
   */
  async presentGuestListModal() {
    const modal = await this.modalController.create({
      component: GuestListComponent,
      componentProps: {
        selectMode: true,
        mode: 'gift',
        excludeGuestIdList: this.gift?.guestIdList,
        excludeGroupIdList: this.gift?.groupIdList,
      }
    });
    modal.present();
    modal.onDidDismiss().then(async (result: any) => {
      const groupIdList = result?.data?.selectedGroupList?.length ? result.data.selectedGroupList : [];
      const guestIdList = result?.data?.selectedGuestList?.length ? result.data.selectedGuestList : [];
      if (groupIdList.length || guestIdList.length) {
        await this.popupService.presentLoading();
        if (groupIdList.length) {
          this.gift.groupIdList = [...new Set([...this.gift.groupIdList, ...groupIdList])];
        }
        if (guestIdList.length) {
          this.gift.guestIdList = [...new Set([...this.gift.guestIdList, ...guestIdList])];
        }
        await this.giftManageService.saveGiftList([ this.gift.giftId ], null, [ this.gift ]);
        this.popupService.dismissLoading();
        this.popupService.saveSuccessToast();
      }
    });
  }

  async presentGiftLogModal() {
    if (this.gift?.giftId) {
      const modal = await this.modalController.create({
        component: GiftLogComponent,
        componentProps: {
          giftId: this.gift.giftId,
        }
      });
      modal.present();
    } else {
      this.popupService.presentActionError();
    }
  }

  /**
   * Prompt confirm restore modal
   */
  async promptDelete() {
    if (this.editMode) {
      const guestList: Guest[] = this.guestService.getGuestListById(this.selectedGuestList);
      const modal = await this.popupService.presentConfirm(
        this.translate.instant('CRUD.confirm_delete_db_field_count', {
          field: this.selectedGuestList?.length > 1
          ? this.translate.instant('GUEST.lbl.guests')
          : this.translate.instant('GUEST.lbl.guest'),
          count: this.selectedGuestList?.length }), '', '',  '', '', '', false, '', guestList
      );
      modal.onDidDismiss().then(async (result: any) => {
        if (result?.data?.confirm) {
          await this.deleteGuest();
        }
        this.toggleEditMode(false);
      });
    } else {
      this.popupService.presentActionError();
    }
  }

  /**
   * Prompt confirm delete modal
   */
  async promptRemove() {
    if (this.editMode) {
      const guestList: Guest[] = this.guestService.getGuestListById(this.selectedGuestList);
      const modal = await this.popupService.presentConfirm(
        this.translate.instant('CRUD.confirm_delete_field_count', {
          field: this.selectedGuestList?.length > 1
          ? this.translate.instant('GUEST.lbl.guests')
          : this.translate.instant('GUEST.lbl.guest'),
          count: this.selectedGuestList?.length }), '', '',  '', '', '', false, '', guestList
      );
      modal.onDidDismiss().then(async (result: any) => {
        if (result?.data?.confirm) {
          await this.removeGuest();
        }
        this.toggleEditMode(false);
      });
    } else {
      this.popupService.presentActionError();
    }
  }

  /**
   * Prompt delete gift
   */
   async promptDeleteGift() {
    const modal = await this.popupService.presentConfirm(
      this.translate.instant('CRUD.confirm_delete_db_field', {
        field: this.translate.instant('GIFT.lbl.gift')
      })
    );
    modal.onDidDismiss().then(async (result: any) => {
      if (result?.data?.confirm && this.gift?.giftId) {
        this.deleteGift([ this.gift.giftId ]);
      }
    });
  }

  /**
   * Prompt restore gift
   */
  async promptRestoreGift() {
    const modal = await this.popupService.presentConfirm(
      this.translate.instant('CRUD.confirm_restore_field', {
        field: this.translate.instant('GIFT.lbl.gift')
      })
    );
    modal.onDidDismiss().then(async (result: any) => {
      if (result?.data?.confirm && this.gift?.giftId) {
        this.restoreGift([ this.gift.giftId ]);
      }
    });
  }

  /**
   * Remove gift from db
   */
  async deleteGift(giftIdList: string[]) {
    await this.popupService.presentLoading();
    if (giftIdList?.length) {
      await this.giftDeleteService.deleteGiftList(giftIdList);
    }
    await this.popupService.dismissLoading();
    this.dismissModal();
    this.popupService.saveSuccessToast();
  }

  /**
   * Save restore guest
   */
  async restoreGift(giftIdList: string[]) {
    await this.popupService.presentLoading();
    if (giftIdList?.length) {
      await this.giftDeleteService.restoreGiftList(giftIdList);
    }
    await this.popupService.dismissLoading();
    this.dismissModal();
    this.popupService.saveSuccessToast();
  }

  /**
   * Remove guest from db
   */
  async removeGuest() {
    if (this.editMode) {
      await this.popupService.presentLoading();
      await this.guestDeleteService.removeGuestList(this.selectedGuestList);
      this.popupService.dismissLoading();
      this.popupService.saveSuccessToast();
    } else {
      this.popupService.presentActionError();
    }
  }

  /**
   * Save delete guest
   */
  async deleteGuest() {
    if (this.editMode) {
      await this.popupService.presentLoading();
      await this.guestDeleteService.deleteGuestList(this.selectedGuestList);
      this.popupService.dismissLoading();
      this.popupService.saveSuccessToast();
    } else {
      this.popupService.presentActionError();
    }
  }

  estimateItemSize(index: number, guest: Guest): number {
    return this.itemHeightFn(guest);
  }

  /**
   * Calculate item height for virtual scroll
   * @param item Item
   */
   itemHeightFn(item: any) {
    const margin = 10;
    const guestItem = 100;
    const groupItem = 80;

    if (item?.group?.memberList?.length) {
      return (item.group.memberList.length * guestItem) + groupItem + margin;
    }
    return guestItem + margin;
  }

  setupViewportHeight() {
    this.viewportHeight = this.calculateViewportHeight(this.guestList);
  }

  calculateViewportHeight(guestList: Guest[]) {
    return guestList?.reduce((a: number, b: Guest) => {
      return a + this.itemHeightFn(b);
    }, 0) + 10 + 16;
  }

  /**
   * Track item by guest id / group id or index for virtual scroll
   * @param index Index
   * @param item item
   */
  trackByFn(index: number, item: Guest) {
    if (item?.guestId) {
      return item.guestId;
    } else if (item?.group?.groupId) {
      return item.group.groupId;
    }
    return index;
  }

}
