import { FunctionService } from 'src/app/services/general/function.service';
import { Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Platform, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { CountdownComponent } from 'ngx-countdown';

import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
// import { cfaSignInPhone, cfaSignInPhoneOnCodeSent } from 'capacitor-firebase-auth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

import { AuthService } from 'src/app/services/user/auth.service';
import { PopupService } from 'src/app/services/general/popup.service';
import { ErrorService } from 'src/app/services/general/error.service';
import { Mobile } from 'src/app/interfaces/general';

import { KeyboardService } from 'src/app/services/general/keyboard.service';
import { PluginListenerHandle } from '@capacitor/core';


/**
 * Mobile authentication component
 */
@Component({
  selector: 'app-login-phone',
  templateUrl: './login-phone.component.html',
  styleUrls: ['./login-phone.component.scss'],
})
export class LoginPhoneComponent implements OnInit, OnDestroy {

  /**
   * OTP count down viewchild
   */
  @ViewChild('cd', { static: false }) private countdown: CountdownComponent;

  @Input() pageMode: boolean;

  @Input() guestMode: boolean;

  @Input() set setReady(flag: boolean) {
    if (flag) {
      this.initialize();
    } else {
      this.unsubscribe();
    }
  }

  @Output() result = new EventEmitter<boolean>();

  /**
   * Ready state after recaptcha and mobile input loaded.
   */
  ready: boolean;

  mobile: Mobile;
  mobileInvalid: boolean;

  /**
   * OTP Form
   */
  otpForm: FormGroup;
  /**
   * Validation Message
   */
  validationMsg: any;
  /**
   * Action Type
   */
  actionType = '';
  /**
   * App verifier
   */
  appVerifier: any;
  /**
   * Count down config
   */
  countdownConfig: any;
  /**
   * Verification ID
   */
  private verificationId: string;
  
  private phoneCodeSent: PluginListenerHandle;
  private phoneVerificationFailed: PluginListenerHandle;

  /**
   * Constructor
   * @param ngZone Ng Zone
   * @param formBuilder Form Builder
   * @param platform Platform
   * @param modalController Modal Controller
   * @param translate Translate
   * @param authService Authentication Service
   * @param popupService Popup Service
   */
  constructor(
    private ngZone: NgZone,
    private formBuilder: FormBuilder,
    private platform: Platform,
    private modalController: ModalController,
    private translate: TranslateService,
    private authService: AuthService,
    private functionService: FunctionService,
    private popupService: PopupService,
    private errorService: ErrorService,
    private keyboardService: KeyboardService,
  ) {
  }

  ngOnInit() {
    
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  /**
   * Initialize
   */
  async initialize() {
    this.ready = false;
    await this.popupService.presentLoading();
    await this.unsubscribe();
    await this.setupRecaptcha();
    this.verificationId = null;
    this.setupPhoneForm();
  }

  /**
   * Before view enter
   */
  async ionViewWillEnter() {
    this.initialize();
  }

  ionViewWillLeave() {
    this.unsubscribe();
  }

  async unsubscribe() {
    if (this.platform.is('hybrid')) {
      if (this.phoneCodeSent) {
        await this.phoneCodeSent.remove();
        this.phoneCodeSent = null;
      }
      if (this.phoneVerificationFailed) {
        await this.phoneVerificationFailed.remove();
        this.phoneVerificationFailed = null;
      }
    }
  }

  /**
   * Setup mobile form
   */
  setupPhoneForm() {
    this.actionType = 'mobile';
    this.setupValidation();
  }

  /**
   * Setup OTP form
   */
  setupOtpForm() {
    this.otpForm = this.formBuilder.group({
      otp: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(6)])
    });

    this.countdownConfig = {
      leftTime: 300,
      format: 'mm:ss'
    };
    this.ngZone.run(() => {
      this.actionType = 'otp';
      setTimeout(() => {
        this.popupService.dismissLoading();
      }, 500);
    });
  }

  /**
   * Setup form validation
   */
  async setupValidation() {
    this.validationMsg = {
      otp: [
        { type: 'required', msg: this.translate.instant('VALIDATION.required', { field: this.translate.instant('MOBILE.lbl.otp') }) },
        { type: 'minlength', msg: this.translate.instant('MOBILE.validation.otp_length') },
        { type: 'maxlength', msg: this.translate.instant('MOBILE.validation.otp_length') }
      ]
    };
  }

  /**
   * Setup recaptcha
   */
  async setupRecaptcha() {
    if (!this.platform.is('hybrid')) {
      await this.platform.ready();
      try {
        this.appVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
          size: 'invisible',
          callback: (response: any) => {
            this.sendOtpWeb();
          },
          'expired-callback': (err: any) => {
            console.log(err);
          }
        });
      } catch (err) {
        this.errorService.logError(err);
      }
      if (this.appVerifier) {
        await this.appVerifier.render();
      }
    }
    setTimeout(() => {
      this.popupService.dismissLoading();
      this.ready = true;
    }, 300);
  }

  updateMobile(mobile: Mobile) {
    this.mobile = mobile;
  }

  updateMobileInvalid(invalid: boolean) {
    this.mobileInvalid = invalid;
  }

  /**
   * Stop count down
   */
  stopCountdown() {
    if (this.countdown) {
      this.countdown.stop();
      this.countdownConfig = null;
    }
  }

  /**
   * Handle event
   * @param event Event
   */
  handleEvent(event: any) {
    if (event?.action === 'done') {
      this.stopCountdown();
    }
  }

  /**
   * Request OTP
   */
  async requestOtp(resend?: boolean) {
    if (this.mobile?.no && !this.mobileInvalid) {
      await this.popupService.presentLoading();
      if (this.platform.is('hybrid')) {
        await this.sendOtpNative(resend);
      } else {
        await this.appVerifier.verify();
      }
    }
  }

  /**
   * Send OTP Web
   */
  async sendOtpWeb() {
    this.authService.sendOtpWeb(this.mobile.no, this.appVerifier)
    .then((result: firebase.auth.ConfirmationResult) => {
      if (result && result.verificationId) {
        this.verificationId = result.verificationId;
        this.setupOtpForm();
      }
    });
  }

  /**
   * Send OTP Native
   */
  async sendOtpNative(resendCode?: boolean) {
    if (this.mobile?.no) {
      this.phoneCodeSent = await FirebaseAuthentication.addListener('phoneCodeSent', async (event) => {
        this.verificationId = event.verificationId;
        if (this.verificationId) {
          this.setupOtpForm();
        } else {
          this.promptError();
        }
      });
      this.phoneVerificationFailed = await FirebaseAuthentication.addListener('phoneVerificationFailed', async (event) => {
        if (event) {
          this.promptError(event?.message);
        }
      });
      
      await FirebaseAuthentication.signInWithPhoneNumber({
        phoneNumber: this.mobile.no,
        resendCode: resendCode ? true : false,
      });
      // const { verificationId } = await FirebaseAuthentication.signInWithPhoneNumber({
      //   phoneNumber:  this.mobile.no
      // });
      // this.verificationId = verificationId;
      // if (this.verificationId) {
      //   this.setupOtpForm();
      // }
    }
  }

  /**
   * Auto submit OTP once valid OTP format enter / paste
   * @param value OTP value
   */
  async autoSubmitOTP(value: number) {
    if (value.toString().length === 6) {
      this.popupService.presentLoading();
      this.otpForm.markAllAsTouched();
      setTimeout(() => {
        if (this.otpForm.valid && this.verificationId) {
          this.verifyOtp();
        } else {
          this.popupService.dismissLoading();
        }
      }, 200);
    }
  }

  /**
   * Verify OTP
   */
  async verifyOtp() {
    this.otpForm.markAllAsTouched();
    if (this.otpForm.valid && this.verificationId) {
      await this.popupService.presentLoading();
      this.stopCountdown();
      this.authService.phoneSignIn(this.verificationId, this.otpForm.value.otp.toString()).then((result: boolean) => {
        if (result) {
          if (!this.pageMode) {
            this.dismissModal(true);
          } else {
            this.result.next(true);
          }
        } else {
          if (!this.pageMode) {
            this.popupService.dismissLoading();
          } else {
            this.result.next(false);
          }
        }
      });
    }
  }



  /**
   * Back
   */
  async back() {
    await this.popupService.presentLoading();
    if (this.actionType) {
      if (this.actionType === 'otp') {
        this.actionType = 'mobile';
      } else if (this.actionType === 'mobile') {
        this.dismissModal();
      } else {
        this.dismissModal();
      }
    } else {
      this.dismissModal();
    }
    this.popupService.dismissLoading();
  }

  /**
   * Dismiss mobile authentication modal
   * @param login Login
   */
  async dismissModal(login?: boolean) {
    if (this.modalController) {
      if (this.platform.is('hybrid')) {
        await this.keyboardService.hide();
        if (this.platform.is('ios')) {
          this.functionService.delay(100);
        }
      }
      const modal = await this.modalController.getTop();
      if (modal) { await modal.dismiss({ login }); }
    }
  }

  async promptError(msg?: string) {
    if (!msg) {
      msg = this.translate.instant('AUTH.error.msg');
    }
    await this.popupService.presentAlert(msg);
    await this.popupService.dismissLoading();
  }

}
