<ion-header class="header-light">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="back()">
        <ion-icon color="dark" class="back-icon" src="./assets/wedding/icon/general/chevron-back.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <app-offline-indicator></app-offline-indicator>
      {{ 'VISITOR.wizard.title' | translate }}
    </ion-title>
    <ion-buttons slot="end" *ngIf="pageLangList?.length > 1 && (step === 'info' || step === 'cocktail' || step === 'venue' || step === 'msg') && langCode && getLanguageByCode(langCode) as lang">
      <ion-button (click)="changeLanguage()">
        <ion-text class="font-10" color="dark">{{ lang }}</ion-text>
        <!-- <ion-icon color="dark" src="./assets/wedding/icon/general/language.svg"></ion-icon> -->
      </ion-button>
    </ion-buttons>
    <ion-progress-bar [value]="progress" color="primary"></ion-progress-bar>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding-bottom" #content>
  <ng-container *ngIf="step === 'info'">
    <ng-container *ngIf="true then introDivBlock"></ng-container>

    <form class="ion-padding-horizontal" [formGroup]="infoForm" *ngIf="infoForm">
      <ion-item class="ion-item-no-padding" lines="none">
        <ion-label position="stacked" color="dark">
          {{ replaceEventType('ACCOUNT.lbl.wedding_title' | translate) }}
          &nbsp;<span class="font-underline ion-float-right" (click)="resetTitle()">{{ 'BTN.reset' | translate }}</span>
        </ion-label>
        <ion-item class="prompt-input-item ion-item-no-padding" lines="none" (click)="setEditMode('title')">
          <div class="full-width text-area" *ngIf="editMode.title">
            <ion-textarea class="background-white fixed-height" (ionBlur)="editMode.title = false;" formControlName="title" autoGrow="true" tabindex="1" #titleInput></ion-textarea>
          </div>
          <ion-text class="full-width html-div wrap-newline" *ngIf="!editMode.title" [innerHtml]="getHtml(infoForm?.value?.title)"></ion-text>
        </ion-item>
        <div class="error-div">
          <ng-container *ngFor="let validation of validationMsg?.title">
            <div class="error-message" *ngIf="infoForm.get('title').hasError(validation.type) && (infoForm.get('title').dirty || infoForm.get('title').touched)">
              <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{  replaceEventType(validation.msg) }}
            </div>
          </ng-container>
        </div>
      </ion-item>

      <ion-item class="ion-item-no-padding" lines="none">
        <ion-label position="stacked" color="dark">
          {{ replaceEventType('DATE.lbl.wedding_date' | translate) }} <ion-text color="danger">*</ion-text>
          &nbsp;<span class="font-underline ion-float-right" (click)="resetDate()">{{ 'BTN.reset' | translate }}</span>
        </ion-label>
        <ion-item class="prompt-input-item ion-item-no-padding" lines="none" (click)="setEditMode('date')">
          <div class="full-width text-area" *ngIf="editMode.date">
            <ion-textarea class="background-white fixed-height" (ionBlur)="editMode.date = false;" formControlName="date" autoGrow="true" tabindex="2" #dateInput></ion-textarea>
          </div>
          <ion-text class="full-width html-div wrap-newline" *ngIf="!editMode.date" [innerHtml]="getHtml(infoForm?.value?.date)"></ion-text>
        </ion-item>
        <div class="error-div">
          <ng-container *ngFor="let validation of validationMsg?.date">
            <div class="error-message" *ngIf="infoForm.get('date').hasError(validation.type) && (infoForm.get('date').dirty || infoForm.get('date').touched)">
              <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{  replaceEventType(validation.msg) }}
            </div>
          </ng-container>
        </div>
      </ion-item>

      <ion-grid class="ion-no-margin ion-no-padding">
        <ion-row class="ion-no-margin ion-no-padding">
          <ion-col class="ion-no-margin ion-no-padding" style="padding-right:5px;">
            <ion-item class="ion-item-no-padding" lines="none">
              <ion-label position="stacked" color="dark">
                {{ 'VISITOR.setting.lbl.startTime' | translate }}
                &nbsp;<span class="font-underline ion-float-right" (click)="resetStartTime()">{{ 'BTN.reset' | translate }}</span>
              </ion-label>
              <ion-item class="prompt-input-item ion-item-no-padding" lines="none" (click)="setEditMode('startTime')">
                <div class="full-width" *ngIf="editMode.startTime">
                  <ion-input (ionBlur)="editMode.startTime = false;" formControlName="startTime" type="text" inputmode="text" tabindex="3" #startTimeInput></ion-input>
                </div>
                <ion-text class="full-width html-div wrap-newline" *ngIf="!editMode.startTime" [innerHtml]="getHtml(infoForm?.value?.startTime)"></ion-text>  
              </ion-item>
              <div class="error-div">
                <ng-container *ngFor="let validation of validationMsg?.startTime">
                  <div class="error-message" *ngIf="infoForm.get('startTime').hasError(validation.type) && (infoForm.get('startTime').dirty || infoForm.get('startTime').touched)">
                    <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ validation.msg }}
                  </div>
                </ng-container>
              </div>
            </ion-item>
          </ion-col>
          <ion-col class="ion-no-margin ion-no-padding" style="padding-left: 5px;">
            <ion-item class="ion-item-no-padding" lines="none">
              <ion-label position="stacked" color="dark">
                {{ 'VISITOR.setting.lbl.endTime' | translate }}
                &nbsp;<span class="font-underline ion-float-right" (click)="resetEndTime()">{{ 'BTN.reset' | translate }}</span>
              </ion-label>
              <ion-item class="prompt-input-item ion-item-no-padding" lines="none" (click)="setEditMode('endTime')">
                <div class="full-width" *ngIf="editMode.endTime">
                  <ion-input (ionBlur)="editMode.endTime = false;" formControlName="endTime" type="text" inputmode="text" tabindex="4" #endTimeInput></ion-input>
                </div>
                <ion-text class="full-width html-div wrap-newline" *ngIf="!editMode.endTime" [innerHtml]="getHtml(infoForm?.value?.endTime)"></ion-text>  
              </ion-item>
              <div class="error-div">
                <ng-container *ngFor="let validation of validationMsg?.endTime">
                  <div class="error-message" *ngIf="infoForm.get('endTime').hasError(validation.type) && (infoForm.get('endTime').dirty || infoForm.get('endTime').touched)">
                    <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ validation.msg }}
                  </div>
                </ng-container>
              </div>
            </ion-item>
          </ion-col>
        </ion-row>
      </ion-grid>
    </form>
  </ng-container>
  <ng-container *ngIf="step === 'cocktail'">
    <ng-container *ngIf="true then introDivBlock"></ng-container>

    <form class="ion-padding-horizontal" [formGroup]="cocktailForm" *ngIf="cocktailForm">
      <ion-grid fixed>
        <ion-row>
          <ion-col size="6">
            <ion-card class="ion-padding ion-text-center" [color]="cocktailForm?.value?.enable === 'yes' ? 'tertiary' : 'light'" (click)="setCocktailEnable('yes')" button>
              {{ 'BTN.yes' | translate }}
            </ion-card>
          </ion-col>
          <ion-col size="6">
            <ion-card class="ion-padding ion-text-center" [color]="cocktailForm?.value?.enable === 'no' ? 'tertiary' : 'light'" (click)="setCocktailEnable('no')" button>
              {{ 'BTN.no' | translate }}
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>
      <ion-item class="ion-item-no-padding" lines="none" *ngIf="cocktailForm?.value?.enable === 'yes'">
        <ion-label position="stacked" color="dark">{{ 'VISITOR.setting.lbl.cocktailTime' | translate }}
          &nbsp;
          <ion-text class="font-8">
            ({{ 'VISITOR.eg.cocktail' | translate }})
          </ion-text>
        </ion-label>
        <ion-item class="prompt-input-item ion-item-no-padding" lines="none">
          <div class="full-width text-area">
            <ion-textarea class="background-white fixed-height" formControlName="cocktailTime" tabindex="5"></ion-textarea>
          </div>
        </ion-item>
        <div class="error-div">
          <ng-container *ngFor="let validation of validationMsg?.cocktailTime">
            <div class="error-message" *ngIf="cocktailForm.get('cocktailTime').hasError(validation.type) && (cocktailForm.get('cocktailTime').dirty || cocktailForm.get('cocktailTime').touched)">
              <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ validation.msg }}
            </div>
          </ng-container>
        </div>
      </ion-item>
    </form>
  </ng-container>
  <ng-container *ngIf="step === 'venue'">
    <ng-container *ngIf="true then introDivBlock"></ng-container>

    <ng-container>
      <ng-container *ngIf="pageLocation?.coordinate && pageLocation?.name else pageLocationBlock">
        <ion-item class="background-transparent ion-margin ion-item-no-padding" lines="none">
          <ion-label color="dark">{{ 'VISITOR.setting.lbl.gps' | translate }}</ion-label>
          <ion-buttons class="ion-no-margin" slot="end">
            <ion-button class="font-12" fill="solid" size="small" color="warning" (click)="testGps()">
              <ion-icon class="icon-small" src="./assets/wedding/icon/general/search.svg"></ion-icon>
              <ion-label class="ion-padding-horizontal">{{ 'BTN.preview' | translate }}</ion-label>
            </ion-button>
            <ion-button class="font-12" fill="solid" size="small" color="warning" (click)="presentLocationModal(pageLocation)">
              <ion-icon class="icon-small" src="./assets/wedding/icon/general/edit.svg"></ion-icon>
              <ion-label class="ion-padding-horizontal">{{ 'CRUD.edit' | translate }}</ion-label>
            </ion-button>
          </ion-buttons>
        </ion-item>
      </ng-container>
      <ng-template #pageLocationBlock>
        <div class="ion-padding">
          <ion-button class="ion-margin-horizontal" expand="block" color="warning" (click)="presentLocationModal()">
            {{ 'LOCATION.btn.setup_venue' | translate }}
          </ion-button>
        </div>
      </ng-template>
    </ng-container>

    <form class="ion-padding-horizontal" [formGroup]="venueForm" *ngIf="venueForm">
      <ion-item class="ion-item-no-padding" lines="none">
        <ion-label position="stacked" color="dark">
          {{ 'LOCATION.lbl.venue_name' | translate }}
          &nbsp;<span class="font-underline ion-float-right" (click)="resetVenue()">{{ 'BTN.reset' | translate }}</span>
        </ion-label>
        <ion-item class="prompt-input-item ion-item-no-padding" lines="none">
          <div class="full-width text-area">
            <ion-textarea class="background-white fixed-height" formControlName="venue" tabindex="6"></ion-textarea>
          </div>
        </ion-item>
        <div class="error-div">
          <ng-container *ngFor="let validation of validationMsg?.venue">
            <div class="error-message" *ngIf="venueForm.get('venue').hasError(validation.type) && (venueForm.get('venue').dirty || venueForm.get('venue').touched)">
              <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ validation.msg }}
            </div>
          </ng-container>
        </div>
      </ion-item>
  
      <ion-item class="ion-item-no-padding" lines="none">
        <ion-label position="stacked" color="dark">{{ 'LOCATION.lbl.venue_hall' | translate }}</ion-label>
        <ion-item class="prompt-input-item ion-item-no-padding" lines="none">
          <div class="full-width text-area">
            <ion-textarea class="background-white fixed-height" formControlName="hall" tabindex="7"></ion-textarea>
          </div>
        </ion-item>
        <div class="error-div">
          <ng-container *ngFor="let validation of validationMsg?.hall">
            <div class="error-message" *ngIf="venueForm.get('hall').hasError(validation.type) && (venueForm.get('hall').dirty || venueForm.get('hall').touched)">
              <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ validation.msg }}
            </div>
          </ng-container>
        </div>
      </ion-item>
  
      <ion-item class="ion-item-no-padding" lines="none">
        <ion-label position="stacked" color="dark">
          {{ 'LOCATION.lbl.venue_address' | translate }}
          &nbsp;<span class="font-underline ion-float-right" (click)="resetAddress()">{{ 'BTN.reset' | translate }}</span>
        </ion-label>
        <ion-item class="prompt-input-item ion-item-no-padding" lines="none">
          <div class="full-width text-area">
            <ion-textarea class="background-white" formControlName="address" autogrow="true" tabindex="8"></ion-textarea>
          </div>
        </ion-item>
        <div class="error-div">
          <ng-container *ngFor="let validation of validationMsg?.address">
            <div class="error-message" *ngIf="venueForm.get('address').hasError(validation.type) && (venueForm.get('address').dirty || venueForm.get('address').touched)">
              <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ validation.msg }}
            </div>
          </ng-container>
        </div>
      </ion-item>

      <ion-item class="ion-item-no-padding" lines="none">
        <ion-label position="stacked" color="dark">{{ 'GUEST.lbl.remark' | translate }}</ion-label>
        <ion-item class="prompt-input-item ion-item-no-padding" lines="none">
          <div class="full-width text-area">
            <ion-textarea class="background-white fixed-height" formControlName="remark" tabindex="9"></ion-textarea>
          </div>
        </ion-item>
        <div class="error-div">
          <ng-container *ngFor="let validation of validationMsg?.remark">
            <div class="error-message" *ngIf="venueForm.get('remark').hasError(validation.type) && (venueForm.get('remark').dirty || venueForm.get('remark').touched)">
              <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ validation.msg }}
            </div>
          </ng-container>
        </div>
      </ion-item>
    </form>
  </ng-container>
  <ng-container *ngIf="step === 'setting'">
    <ng-container *ngIf="true then introDivBlock"></ng-container>

    <form class="ion-padding-horizontal" [formGroup]="settingForm" *ngIf="settingForm">
      <ion-card class="setting-card">
        <ion-item class="setting-item" lines="none">
          <ion-icon class="setting-icon icon-small" src="./assets/wedding/icon/tabs/guest-outline.svg" slot="start"></ion-icon>
          <ion-toggle formControlName="pax" color="success">
            <ion-text class="ion-text-wrap">
              <div class="font-14 line-150 font-bold" [innerHtml]="'VISITOR.setting.pax.title' | translate"></div>
              <div class="font-12">
                <ion-text [innerHtml]="'VISITOR.setting.pax.on' | translate" *ngIf="settingForm.value?.pax"></ion-text>
                <ion-text [innerHtml]="'VISITOR.setting.pax.off' | translate" *ngIf="!settingForm.value?.pax"></ion-text>
              </div>
            </ion-text>
          </ion-toggle>
        </ion-item>
      </ion-card>

      <ion-card class="setting-card">
        <ion-item class="setting-item" lines="none">
          <ion-icon class="setting-icon icon-small" src="./assets/wedding/icon/guest/seating-outline.svg" slot="start"></ion-icon>
          <ion-toggle formControlName="seatingBeforeCheckin" color="success">
            <ion-text class="ion-text-wrap">
              <div class="font-14 line-150 font-bold" [innerHtml]="'VISITOR.setting.seating.title' | translate"></div>
              <div class="font-12">
                <ion-text [innerHtml]="'VISITOR.setting.seating.on' | translate" *ngIf="settingForm.value?.seatingBeforeCheckin"></ion-text>
                <ion-text [innerHtml]="'VISITOR.setting.seating.off' | translate" *ngIf="!settingForm.value?.seatingBeforeCheckin"></ion-text>
              </div>
            </ion-text>
          </ion-toggle>
        </ion-item>
      </ion-card>

      <ion-card class="setting-card">
        <ion-item class="setting-item" lines="none">
          <ion-icon class="setting-icon icon-small" name="time-outline" slot="start"></ion-icon>
          <ion-toggle formControlName="groupMember" color="success">
            <ion-text class="ion-text-wrap">
              <div class="font-14 font-bold" [innerHtml]="'VISITOR.setting.groupMember.title' | translate"></div>
              <div class="font-12">
                <ion-text [innerHtml]="'VISITOR.setting.groupMember.on' | translate" *ngIf="settingForm.value?.groupMember"></ion-text>
                <ion-text [innerHtml]="'VISITOR.setting.groupMember.off' | translate" *ngIf="!settingForm.value?.groupMember"></ion-text>
              </div>
            </ion-text>
          </ion-toggle>
        </ion-item>
      </ion-card>
    </form>

    <div class="ion-margin ion-text-center">
      <ion-text class="font-12 font-underline" (click)="presentVisitorSetting('setting')">
        {{ 'SETTING.lbl.field' | translate : { field: 'BTN.advance' | translate } }}
      </ion-text>
    </div>
  </ng-container>
  <ng-container *ngIf="step === 'attachment'">
    <ng-container *ngIf="true then introDivBlock"></ng-container>

    <form class="ion-padding-horizontal" [formGroup]="attachmentForm" *ngIf="attachmentForm">
      <ion-card class="setting-card">
        <ion-item class="gps-item" lines="none">
          <ion-label class="ion-text-wrap">
            <ion-item class="ion-item-no-padding" lines="none">
              <ion-icon class="setting-icon icon-small" src="./assets/wedding/icon/general/document-outline.svg"></ion-icon>
              <ion-label class="ion-text-wrap">
                <div class="font-14 font-bold" [innerHtml]="'VISITOR.setting.attachment.title' | translate"></div>
                <div class="font-12">
                  <ion-text [innerHtml]="'VISITOR.setting.attachment.intro' | translate"></ion-text>
                  <!-- <ion-text [innerHtml]="'VISITOR.setting.attachment.off' | translate" *ngIf="!attachmentForm.value?.floorplan?.enable && !attachmentForm.value?.menu?.enable && !attachmentForm.value?.attachment?.[0]?.enable"></ion-text> -->
                </div>
              </ion-label>
            </ion-item>
            <div>
              <ion-button class="gps-button" size="small" [color]="attachmentForm?.value?.floorplan?.enable ? 'success' : 'light'" (click)="updateAttachment('floorplan')">
                <ion-text class="font-10">{{ 'VISITOR.setting.attachment.floorplan.title' | translate }}</ion-text>
              </ion-button>
              <ion-button class="gps-button" size="small" [color]="attachmentForm?.value?.menu?.enable ? 'success' : 'light'" (click)="updateAttachment('menu')">
                <ion-text class="font-10">{{ 'VISITOR.setting.attachment.menu.title' | translate }}</ion-text>
              </ion-button>
              <ion-button class="gps-button" size="small" [color]="attachmentForm?.value?.attachment?.[0]?.enable ? 'success' : 'light'" (click)="updateAttachment('attachment')">
                <ion-text class="font-10">{{ 'LBL.other' | translate }}</ion-text>
              </ion-button>
            </div>
          </ion-label>
        </ion-item>
      </ion-card>

      <ion-card class="setting-card" *ngIf="attachmentForm?.value?.floorplan?.enable">
        <ion-item class="full-width" lines="none">
          <ion-icon class="setting-icon icon-small" src="./assets/wedding/icon/visitor/floorplan.svg"></ion-icon>
          <ion-label class="ion-text-wrap">
            <div class="font-14 font-bold" [innerHtml]="'VISITOR.setting.attachment.floorplan.title' | translate"></div>
            <div class="font-12">
              <ion-text [innerHtml]="'VISITOR.setting.attachment.floorplan.on' | translate" *ngIf="pageSetting?.floorplan?.enable"></ion-text>
              <ion-text [innerHtml]="'VISITOR.setting.attachment.floorplan.off' | translate" *ngIf="!pageSetting?.floorplan?.enable"></ion-text>
            </div>
            <div class="font-10">
              *<ion-text [innerHtml]="'VISITOR.setting.attachment.support' | translate"></ion-text>
            </div>
          </ion-label>
        </ion-item>
        <div class="background-img-div ion-padding-horizontal ion-margin-horizontal ion-padding-bottom" (click)="selectFloorplan()">
          <ion-item class="full-width" color="light" lines="none" *ngIf="!pageSetting?.floorplan?.url">
            <ion-label class="ion-margin ion-padding ion-text-center">
              <ion-img class="center upload-img" src="./assets/wedding/icon/general/cloud-upload-outline.svg"></ion-img>
              <div class="ion-text-center font-14 font-semi">{{ 'VISITOR.setting.attachment.floorplan.upload' | translate }}</div>
            </ion-label>
          </ion-item>
          <ion-item class="background-transparent full-width" lines="none" *ngIf="pageSetting?.floorplan?.url">
            <ion-label class="ion-no-margin custom-background-label">
              <ion-icon class="font-40 center" src="./assets/wedding/icon/general/document-outline.svg"></ion-icon>
              <div class="ion-text-center">
                <ion-text class="font-10 font-med font-underline">{{ 'CRUD.edit' | translate }}</ion-text>
              </div>
            </ion-label>
          </ion-item>
        </div>
      </ion-card>

      <ion-card class="setting-card" *ngIf="attachmentForm?.value?.menu?.enable">
        <ion-item class="full-width" lines="none">
          <ion-icon class="setting-icon icon-small" src="./assets/wedding/icon/visitor/menu.svg"></ion-icon>
          <ion-label class="ion-text-wrap">
            <div class="font-14 font-bold" [innerHtml]="'VISITOR.setting.attachment.menu.title' | translate"></div>
            <div class="font-12">
              <ion-text [innerHtml]="'VISITOR.setting.attachment.menu.on' | translate" *ngIf="pageSetting?.menu?.enable"></ion-text>
              <ion-text [innerHtml]="'VISITOR.setting.attachment.menu.off' | translate" *ngIf="!pageSetting?.menu?.enable"></ion-text>
            </div>
            <div class="font-10">
              *<ion-text [innerHtml]="'VISITOR.setting.attachment.support' | translate"></ion-text>
            </div>
          </ion-label>
        </ion-item>
        <div class="background-img-div ion-padding-horizontal ion-margin-horizontal ion-padding-bottom" (click)="selectMenu()">
          <ion-item class="full-width" color="light" lines="none" *ngIf="!pageSetting?.menu?.url">
            <ion-label class="ion-margin ion-padding ion-text-center">
              <ion-img class="center upload-img" src="./assets/wedding/icon/general/cloud-upload-outline.svg"></ion-img>
              <div class="ion-text-center font-14 font-semi">{{ 'VISITOR.setting.attachment.menu.upload' | translate }}</div>
            </ion-label>
          </ion-item>
          <ion-item class="background-transparent full-width" lines="none" *ngIf="pageSetting?.menu?.url">
            <ion-label class="ion-no-margin custom-background-label">
              <ion-icon class="font-40 center" src="./assets/wedding/icon/general/document-outline.svg"></ion-icon>
              <div class="ion-text-center">
                <ion-text class="font-10 font-med font-underline">{{ 'CRUD.edit' | translate }}</ion-text>
              </div>
            </ion-label>
          </ion-item>
        </div>
      </ion-card>

      <ion-card class="setting-card" *ngIf="attachmentForm?.value?.attachment?.[0]?.enable">
        <ion-item class="full-width" lines="none">
          <ion-icon class="setting-icon icon-small" src="./assets/wedding/icon/general/document-outline.svg"></ion-icon>
          <ion-label class="ion-text-wrap">
            <div class="font-14 font-bold" [innerHtml]="'LBL.other' | translate"></div>
            <div class="font-12">
              <ion-text [innerHtml]="'VISITOR.setting.attachment.on' | translate" *ngIf="pageSetting?.attachment?.[0]?.enable"></ion-text>
              <ion-text [innerHtml]="'VISITOR.setting.attachment.off' | translate" *ngIf="!pageSetting?.attachment?.[0]?.enable"></ion-text>
            </div>
            <div class="font-10">
              *<ion-text [innerHtml]="'VISITOR.setting.attachment.support' | translate"></ion-text>
            </div>
          </ion-label>
        </ion-item>
        <div class="background-img-div ion-padding-horizontal ion-margin-horizontal ion-padding-bottom" (click)="selectAttachment()">
          <ion-item class="full-width" color="light" lines="none" *ngIf="!pageSetting?.attachment?.[0]?.url">
            <ion-label class="ion-margin ion-padding ion-text-center">
              <ion-img class="center upload-img" src="./assets/wedding/icon/general/cloud-upload-outline.svg"></ion-img>
              <div class="ion-text-center font-14 font-semi">{{ 'VISITOR.setting.attachment.menu.upload' | translate }}</div>
            </ion-label>
          </ion-item>
          <ion-item class="background-transparent full-width" lines="none" *ngIf="pageSetting?.attachment?.[0]?.url">
            <ion-label class="ion-no-margin custom-background-label">
              <ion-icon class="font-40 center" src="./assets/wedding/icon/general/document-outline.svg"></ion-icon>
              <div class="ion-text-center">
                <ion-text class="font-10 font-med font-underline">{{ 'CRUD.edit' | translate }}</ion-text>
              </div>
            </ion-label>
          </ion-item>
        </div>
      </ion-card>
    </form>
  </ng-container>
  <ng-container *ngIf="step === 'theme'">
    <ng-container *ngIf="true then introDivBlock"></ng-container>
    
    <app-setting-visitor-theme class="full-width-height" [pageTheme]="pageTheme" (setPageTheme)="selectPageTheme($event)"></app-setting-visitor-theme>
  </ng-container>
  <ng-container *ngIf="step === 'background'">
    <ng-container *ngIf="true then introDivBlock"></ng-container>

    <div class="ion-padding-horizontal">
      <ion-card class="setting-card">
        <ion-item class="full-width" lines="none">
          <ion-icon class="setting-icon icon-small" src="./assets/wedding/icon/general/image-outline.svg"></ion-icon>
          <ion-label class="ion-text-wrap">
            <div class="font-14 font-bold" [innerHtml]="'VISITOR.setting.background.title' | translate"></div>
            <div class="font-12">
              <ion-text [innerHtml]="'VISITOR.setting.background.on' | translate" *ngIf="pageSetting?.backgroundUrl"></ion-text>
              <ion-text [innerHtml]="'VISITOR.setting.background.off' | translate" *ngIf="!pageSetting?.backgroundUrl"></ion-text>
            </div>
          </ion-label>
        </ion-item>
        <div class="background-img-div ion-padding-horizontal ion-margin-horizontal ion-padding-bottom" (click)="selectPhoto()">
          <ion-item class="full-width" color="light" lines="none" *ngIf="!pageSetting?.backgroundUrl">
            <ion-label class="ion-margin ion-padding ion-text-center">
              <ion-img class="center upload-img" src="./assets/wedding/icon/general/cloud-upload-outline.svg"></ion-img>
              <div class="ion-text-center font-14 font-semi">{{ 'VISITOR.setting.background.upload' | translate }}</div>
            </ion-label>
          </ion-item>
          <ion-item class="background-transparent full-width" lines="none" *ngIf="pageSetting?.backgroundUrl">
            <ion-label class="ion-no-margin custom-background-label">
              <app-cached-image class="custom-background" [type]="'custom-background'" [src]="pageSetting.backgroundUrl" [spinner]="true"></app-cached-image>
              <div class="ion-text-center">
                <ion-text class="font-10 font-med font-underline">{{ 'CRUD.edit' | translate }}</ion-text>
              </div>
            </ion-label>
          </ion-item>
        </div>
      </ion-card>
    </div>
    
  </ng-container>
  <ng-container *ngIf="step === 'msg'">
    <ng-container *ngIf="true then introDivBlock"></ng-container>

    <form class="ion-padding-horizontal" [formGroup]="msgForm" *ngIf="msgForm">
      <ion-item class="ion-item-no-padding" lines="none">
        <ion-label position="stacked" color="dark">
          {{ 'VISITOR.lbl.greeting' | translate }}
          &nbsp;<span class="font-underline ion-float-right" (click)="resetGreeting()">{{ 'BTN.reset' | translate }}</span>
        </ion-label>
        <ion-item class="prompt-input-item ion-item-no-padding" (click)="setEditMode('greeting')" lines="none">
          <div class="full-width text-area" *ngIf="editMode.greeting">
            <ion-textarea class="background-white fixed-height" (ionBlur)="editMode.greeting = false;" formControlName="greeting" autoGrow="true" tabindex="14" #greetingInput></ion-textarea>
          </div>
          <ion-text class="full-width html-div wrap-newline" *ngIf="!editMode.greeting" [innerHtml]="getHtml(msgForm?.value?.greeting)"></ion-text>
        </ion-item>
        <div class="error-div"></div>
      </ion-item>

      <ion-item class="ion-item-no-padding" lines="none">
        <ion-label position="stacked" color="dark">
          {{ 'LBL.msg' | translate }}
          &nbsp;<span class="font-underline ion-float-right" (click)="resetMsg()">{{ 'BTN.reset' | translate }}</span>
        </ion-label>
        <ion-item class="prompt-input-item ion-item-no-padding" (click)="setEditMode('msg')" lines="none">
          <div class="full-width text-area" *ngIf="editMode.msg">
            <ion-textarea class="background-white" formControlName="msg" autoGrow="true" tabindex="15" #msgInput></ion-textarea>
          </div>
          <ion-text class="full-width html-div" *ngIf="!editMode.msg" [innerHtml]="getHtml(msgForm?.value?.msg)"></ion-text>
        </ion-item>
        <div class="error-div"></div>
      </ion-item>
    </form>
    <div class="ion-margin ion-text-center">
      <ion-text class="font-12 font-underline" (click)="presentVisitorSetting('info')">
        {{ 'SETTING.lbl.field' | translate : { field: 'BTN.advance' | translate } }}
      </ion-text>
    </div>
  </ng-container>
  <ng-container *ngIf="step === 'preview'">
    <ng-container *ngIf="true then introDivBlock"></ng-container>
  </ng-container>
</ion-content>
<ion-footer>
  <ion-toolbar>
    <div class="ion-margin">
      <ion-button expand="block" (click)="next()" [disabled]="checkDisabledSubmit()">
        <ng-container *ngIf="step === 'preview'">
          {{ 'BTN.close' | translate }}
        </ng-container>
        <ng-container *ngIf="step !== 'preview'">
          {{ 'BTN.continue' | translate }}
        </ng-container>
      </ion-button>
    </div>

    <div class="ion-margin ion-text-center">
      <ion-text class="font-12 font-underline" (click)="preview()">{{ 'BTN.preview' | translate }}</ion-text>
    </div>
  </ion-toolbar>
</ion-footer>

<ng-template #introDivBlock>
  <ng-container *ngIf="step === 'info'">
    <div class="ion-text-center center ion-margin-bottom">
      <div class="ion-margin ion-padding">
        <ng-container *ngIf="true then introBlock"></ng-container>
      </div>
      <div class="ion-margin">
        <ng-container *ngIf="true then imgBlock"></ng-container>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="step === 'cocktail'">
    <div class="ion-text-center center ion-margin-bottom">
      <div class="ion-margin ion-padding">
        <ng-container *ngIf="true then introBlock"></ng-container>
      </div>
      <div class="ion-margin">
        <ng-container *ngIf="true then imgBlock"></ng-container>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="step === 'venue'">
    <div class="ion-text-center center ion-margin-bottom">
      <div class="ion-margin ion-padding">
        <ng-container *ngIf="true then introBlock"></ng-container>
      </div>
      <div class="ion-margin">
        <ng-container *ngIf="true then imgBlock"></ng-container>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="step === 'setting'">
    <div class="ion-text-center center ion-margin-bottom">
      <div class="ion-margin ion-padding">
        <ng-container *ngIf="true then introBlock"></ng-container>
      </div>
      <div class="ion-margin">
        <ng-container *ngIf="true then imgBlock"></ng-container>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="step === 'attachment'">
    <div class="ion-text-center center ion-margin-bottom">
      <div class="ion-margin ion-padding">
        <ng-container *ngIf="true then introBlock"></ng-container>
      </div>
      <div class="ion-margin">
        <ng-container *ngIf="true then imgBlock"></ng-container>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="step === 'theme'">
    <div class="ion-text-center center ion-margin-bottom">
      <div class="ion-margin ion-padding-horizontal ion-padding-top">
        <ng-container *ngIf="true then introBlock"></ng-container>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="step === 'background'">
    <div class="ion-text-center center ion-margin-bottom">
      <div class="ion-margin ion-padding">
        <ng-container *ngIf="true then introBlock"></ng-container>
      </div>
      <div class="ion-margin">
        <ng-container *ngIf="true then imgBlock"></ng-container>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="step === 'msg'">
    <div class="ion-text-center center ion-margin-bottom">
      <div class="ion-margin ion-padding">
        <ng-container *ngIf="true then introBlock"></ng-container>
      </div>
      <div class="ion-margin">
        <ng-container *ngIf="true then imgBlock"></ng-container>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="step === 'preview'">
    <div class="full-width-height vertical-center center ion-text-center ion-margin-bottom">
      <div class="ion-margin-horizontal ion-padding-horizontal">
        <ng-container *ngIf="true then introBlock"></ng-container>
      </div>
      <div>
        <ng-container *ngIf="true then imgBlock"></ng-container>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #introBlock>
  <ng-container *ngIf="step === 'info'">
    <ion-text class="font-20 font-med" color="dark">{{ replaceEventType('VISITOR.wizard.intro.info' | translate) }}</ion-text>
  </ng-container>
  <ng-container *ngIf="step === 'cocktail'">
    <ion-text class="font-20 font-med" color="dark">{{ replaceEventType('VISITOR.wizard.intro.cocktail' | translate) }}</ion-text>
  </ng-container>
  <ng-container *ngIf="step === 'venue'">
    <ion-text class="font-20 font-med" color="dark">{{ replaceEventType('VISITOR.wizard.intro.venue' | translate) }}</ion-text>
  </ng-container>
  <ng-container *ngIf="step === 'setting'">
    <ion-text class="font-20 font-med" color="dark">{{ replaceEventType('VISITOR.wizard.intro.setting' | translate) }}</ion-text>
  </ng-container>
  <ng-container *ngIf="step === 'attachment'">
    <ion-text class="font-20 font-med" color="dark">{{ replaceEventType('VISITOR.wizard.intro.attachment' | translate) }}</ion-text>
  </ng-container>
  <ng-container *ngIf="step === 'theme'">
    <ion-text class="font-20 font-med" color="dark">{{ replaceEventType('VISITOR.wizard.intro.theme' | translate) }}</ion-text>
  </ng-container>
  <ng-container *ngIf="step === 'background'">
    <ion-text class="font-20 font-med" color="dark">{{ replaceEventType('VISITOR.wizard.intro.background' | translate) }}</ion-text>
  </ng-container>
  <ng-container *ngIf="step === 'msg'">
    <ion-text class="font-20 font-med" color="dark">{{ replaceEventType('VISITOR.wizard.intro.msg' | translate) }}</ion-text>
  </ng-container>
  <ng-container *ngIf="step === 'preview'">
    <ion-text class="font-20 font-med" color="dark">{{ replaceEventType('VISITOR.wizard.intro.preview' | translate) }}</ion-text>
  </ng-container>
</ng-template>

<ng-template #imgBlock>
  <ng-container *ngIf="step === 'info'">
    <ion-img class="info-img center" src="./assets/wedding/img/visitor/wizard/info.webp"></ion-img>
  </ng-container>
  <ng-container *ngIf="step === 'cocktail'">
    <ion-img class="cocktail-img center" src="./assets/wedding/img/visitor/wizard/cocktail.webp"></ion-img>
  </ng-container>
  <ng-container *ngIf="step === 'venue'">
    <ion-img class="venue-img center" src="./assets/wedding/img/visitor/wizard/venue.webp"></ion-img>
  </ng-container>
  <ng-container *ngIf="step === 'setting'">
    <ion-img class="setting-img center" src="./assets/wedding/img/visitor/wizard/setting.webp"></ion-img>
  </ng-container>
  <ng-container *ngIf="step === 'background'">
    <ion-img class="background-img center" src="./assets/wedding/img/visitor/wizard/background.webp"></ion-img>
  </ng-container>
  <ng-container *ngIf="step === 'msg'">
    <ion-img class="msg-img center" src="./assets/wedding/img/visitor/wizard/msg.webp"></ion-img>
  </ng-container>
  <ng-container *ngIf="step === 'preview'">
    <ion-img class="preview-img center" src="./assets/wedding/gif/visitor/preview.gif"></ion-img>
  </ng-container>
</ng-template>

<ng-template #dynamicVariableBlock>
  <div class="ion-margin-vertical">
    <ion-text class="font-10 font-bold">{{ 'QRCODE.lbl.dynamic_variable' | translate }}</ion-text>
    <ion-segment class="background-transparent" class="full-width" scrollable>
      <ng-container *ngFor="let variable of getTemplateVariableList()">
        <ion-segment-button class="dynamic-variable-seg-btn ion-no-padding" (click)="copy(variable)" *ngIf="checkVariable(variable)">
          <ion-text>{{  replaceEventType('TEMPLATE.variable.' + variable | translate) }}</ion-text>
        </ion-segment-button>
      </ng-container>
    </ion-segment>
  </div>
</ng-template>