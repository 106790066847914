import { Component, EventEmitter, Input, OnInit, Output, OnDestroy, ViewChild } from '@angular/core';

import { SettingFieldService } from 'src/app/services/setting/setting-field.service';
import { ModuleService } from 'src/app/services/account/privilege/module.service';
import { GuestService } from 'src/app/services/guest/guest.service';
import { GuestListService } from 'src/app/services/guest/guest-list.service';
import { SeatingSummaryService } from 'src/app/services/seating/seating-summary.service';

import { Seating } from 'src/app/interfaces/seating';
import { Guest } from 'src/app/interfaces/guest';
import { SeatingSummary } from 'src/app/interfaces/seating';

import { ModuleType, SettingFieldType } from 'src/app/types/general';
import { SeatingView } from 'src/app/types/seating';
import { GuestListMode } from 'src/app/types/guest';
import { SettingField } from 'src/app/interfaces/database';
import { Subscription } from 'rxjs';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

/**
 * Seating view component to display seating item in grid view / list view
 */
@Component({
  selector: 'app-seating-view',
  templateUrl: './seating-view.component.html',
  styleUrls: ['./seating-view.component.scss'],
})
export class SeatingViewComponent implements OnInit, OnDestroy {
  
  @ViewChild(CdkVirtualScrollViewport) cdkVirtualScrollViewport: CdkVirtualScrollViewport;

  @Input() gridSize: number;
  @Input() gridNameHeight: number;
  /**
   * Guest list mode
   */
  @Input() mode: GuestListMode;
  /**
   * Seating View Type
   */
  @Input() seatingView: SeatingView;
  /**
   * Edit Mode flag
   */
  @Input() editMode: boolean;
  /**
   * Assign Mode flag
   */
  @Input() assignMode: boolean;
  /**
   * Swap mode flag
   */
  @Input() swapMode: boolean;
  /**
   * Expand flag
   */
  @Input() expand: boolean;

  @Input() width: number;
  /**
   * Disable click action
   */
  @Input() disableClick: boolean;
  /**
   * Disable watching
   */
  @Input() disableWatching: boolean;
  /**
   * Seating info
   */
  @Input() seating: Seating;
  /**
   * Seating summary
   */
  @Input() seatingSummary: SeatingSummary;
  /**
   * Selected seating list
   */
  @Input() selectedSeatingList: string[];
  /**
   * Selected guest list
   */
  @Input() selectedGuestList: string[];
  /**
   * Check selected group
   */
  @Input() checkSelectedGroup: boolean;

  @Input() set ready(ready: boolean) {
    if (ready) {
      this.initialize();
    } else {
      this.unwatchGuestList();
    }
  }

  /**
   * Seating click action
   */
  @Output() seatingClick = new EventEmitter();
  /**
   * Selected group list
   */
  selectedGroupList: string[];
  /**
   * Atternded guest count
   */
  attendedCount: number;
  /**
   * Guest list for list view
   */
  guestList: Guest[];
  /**
   * AccountModule
   */
  module: ModuleType;

  color: string;

  cardHeight: number;

  invitedBy: string;
  category: string;

  private guestListSubscription: Subscription;

  /**
   * Constructor
   * @param guestService guest service
   * @param guestListService guest list service
   * @param guestSummaryService guest summary service
   * @param moduleService module service
   */
  constructor(
    private settingFieldService: SettingFieldService,
    private guestService: GuestService,
    private guestListService: GuestListService,
    private seatingSummaryService: SeatingSummaryService,
    private moduleService: ModuleService,
  ) { }

  /**
   * Component Initial.
   * Watch guest list and seating if not disable.
   */
  ngOnInit() {
    this.initialize();
  }

  ngOnDestroy(): void {
    this.unwatchGuestList();
  }

  initialize() {
    this.module = this.moduleService.currentModule;
    // this.setupGridSize();

    if (this.seating && !this.disableWatching) {
      this.watchGuestList();
    } else if (this.seatingSummary?.guestList) {
      this.setupGuestList(this.seatingSummary.guestList);
    } else if (this.disableWatching && !this.seatingSummary) {
      this.getSeatingSummary(null);
    }
    this.setupColor();
    if (this.assignMode || this.swapMode || this.expand === true) {
      this.expand = true;
      this.setupViewport();
    } else {
      this.expand = false;
    }
  }

  setupViewport() {
    if (this.cdkVirtualScrollViewport) {
      setTimeout(() => {
        this.cdkVirtualScrollViewport?.checkViewportSize();
      }, 200);
    } else {
      setTimeout(() => {
        this.setupViewport();
      }, 200);
    }
  }

  setupSettingField() {
    if (this.seatingSummary?.invitedBy?.length) {
      this.invitedBy = this.getSettingField('invited_by', this.seatingSummary.invitedBy);
    } else if (this.seating?.invitedBy?.length) {
      this.invitedBy = this.getSettingField('invited_by', this.seating.invitedBy);
    } else {
      this.invitedBy = this.getSettingField('invited_by', []);
    }

    if (this.seatingSummary?.category?.length) {
      this.category = this.getSettingField('category', this.seatingSummary.category);
    } else if (this.seating?.category?.length) {
      this.category = this.getSettingField('category', this.seating.category);
    } else {
      this.category = this.getSettingField('category', []);
    }
  }

  /**
   * Watch guest list changes and get latest seating summary and seating guest list
   */
  async watchGuestList() {
    if (!this.guestListSubscription) {
      this.guestListSubscription = this.guestService.observableGuestList.subscribe(() => {
        this.setupData();
      });
    }
    
  }

  async unwatchGuestList() {
    if (this.guestListSubscription) {
      this.guestListSubscription.unsubscribe();
      this.guestListSubscription = null;
    }
  }

  /**
   * Setup seating data
   */
  setupData() {
    this.getSeatingSummary(this.seating);
    if (this.seatingSummary?.guestList) {
      this.setupGuestList(this.seatingSummary.guestList);
    }
    this.setupColor();
  }

  /**
   * Setup guest list
   * @param guestList Guest list
   */
  setupGuestList(guestList: Guest[]) {
    this.guestList = this.guestListService.generateGroupList(guestList);
    this.setupCardHeight();
    this.setupSelectedGroup();
  }

  setupCardHeight() {
    this.cardHeight = this.calculateCardContentHeight(this.guestList);
  }

  setupColor() {
    this.color = this.getColor();
  }
  /**
   * Setup color
   */
  getColor() {
    if (!this.seating?.seatingId && !this.seating?.name) {
      return 'medium';
    } else if (this.module === 'checkin') {
      return this.attendedCount && this.attendedCount >= this.seatingSummary?.guestList?.length ? 'success'
      : this.attendedCount ? 'warning' : 'white';
    } else {
      return this.seatingSummary?.guestList?.length >= this.seating?.maxGuest ? 'success' : this.seatingSummary?.guestList?.length ? 'warning' : 'white';
    }
  }

  /**
   * Setup attended count
   */
  setupAttendedCount() {
    if (this.module === 'checkin' && this.seatingSummary?.guestList?.length) {
      this.attendedCount = this.seatingSummary.guestList.filter((guest: Guest) => {
        if (guest?.status?.checkin) {
          return true;
        } else {
          return false;
        }
      }).length;
    } else {
      this.attendedCount = 0;
    }
  }

  /**
   * Setup selected group
   */
  setupSelectedGroup() {
    if (this.checkSelectedGroup) {
      if (!this.selectedGroupList) {
        this.selectedGroupList = [];
      }
      this.guestList?.forEach((guest: Guest) => {
        if (guest?.group?.groupId && guest?.group?.memberList?.length && this.selectedGroupList?.indexOf(guest.group.groupId) === -1) {
          let flag = true;
          guest.group.memberList?.forEach((guestId: string) => {
            if (this.selectedGuestList?.indexOf(guestId) === -1) {
              flag = false;
            }
          });
          if (flag) {
            this.selectedGroupList.push(guest.group.groupId);
          }
        }
      });
    }
  }

  /**
   * Get seating summary
   * @param seating Seating info
   */
  getSeatingSummary(seating: Seating) {
    this.seatingSummary = this.seatingSummaryService.getSeatingSummary(seating);
    this.setupAttendedCount();
  }

  /**
   * Get list of setting field value
   * @param settingFieldType Setting field type
   * @param settingFieldList Setting field list
   * @returns Join of setting field value
   */
  getSettingField(settingFieldType: SettingFieldType, settingFieldList: SettingField[]): string {
    return this.settingFieldService.joinSettingField(settingFieldType, settingFieldList);
  }

  /**
   * Seating click
   * @param seating Seating
   */
  click(flag?: boolean) {
    if (!this.disableClick) {
      if (this.editMode) {
        this.seatingClick.next('');
      } else if (this.assignMode || this.swapMode) {
        this.seatingClick.next('');
      } else if (flag) {
        if (this.seatingView === 'grid') {
          this.seatingClick.next('');
        } else {
          this.toggleExpand();
        }
      }
    }
  }

  /**
   * Toggle expand
   */
  toggleExpand() {
    this.expand = !this.expand;
  }

  /**
   * Virtual scroll - calculate item height for guest item
   */
  itemHeightFn(item: any) {
    const margin = 10;
    const guestItem = 100;
    const groupItem = 80;

    if (item?.group?.memberList?.length) {
      return (item.group.memberList.length * guestItem) + groupItem + margin;
    }
    return guestItem + margin;
  }

  calculateCardContentHeight(guestList: Guest[]) {
    return guestList?.reduce((a: number, b: Guest) => {
      return a + this.itemHeightFn(b);
    }, 0) + 10;
  }

  estimateItemSize(index: number, guest: Guest): number {
    return this.itemHeightFn(guest) + 10;
  }

  /**
   * Virtual scroll - track guest item
   */
  trackByFn(index: number, item: Guest) {
    if (item) {
      if (item?.guestId) {
        return item.guestId;
      } else if (item?.group?.groupId) {
        return item.group.groupId;
      }
    }
    return index;
  }

}
