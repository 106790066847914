<ng-container *ngIf="!pageMode">
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button (click)="back()">
          <ion-icon color="dark" class="back-icon" src="./assets/wedding/icon/general/chevron-back.svg"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title>{{ 'MODULE.list.qrcode' | translate }}</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <div class="full-width-height vertical-center">
      <ng-container *ngIf="true then mainCard"></ng-container>
    </div>
  </ion-content>
</ng-container>

<ng-container *ngIf="pageMode">
  <!-- [style.height]="previewMode ? 'calc(100vh - 56px - 90px - 32px - 32px)' : '100%'" -->
  <div class="full-width vertical-center">
    <ng-container *ngIf="true then mainCard"></ng-container>
  </div>
</ng-container>

<ng-template #mainCard> 
  <div id="qrcode-div">
    <ng-container *ngIf="websiteDetail?.qrcode?.enable">
      <ng-container *ngIf="!pdfMode then contentCard"></ng-container>
  
      <ng-container *ngIf="websiteType === 'qrcode' || pdfMode then titleCard"></ng-container>
      <ng-container *ngIf="websiteType === 'qrcode' || pdfMode then timeBlock"></ng-container>
      <ng-container *ngIf="true then qrcodeCard"></ng-container>
      <ng-container *ngIf="true then nameCard"></ng-container>
      <ng-container *ngIf="true then downloadBtnBlock"></ng-container>
      
      <ng-container *ngIf="websiteType === 'qrcode' || pdfMode then webCheckinCard"></ng-container>
    </ng-container>
    <ng-container *ngIf="!websiteDetail?.qrcode?.enable">
      <ng-container *ngIf="true then disableCard"></ng-container>
    </ng-container>
    
    <!-- && websiteType !== 'home' -->
    <!-- <ng-container *ngIf="checkinStatus then checkinStatusBlock"></ng-container> -->
  </div>
</ng-template>

<ng-template #qrcodeCard>
  <div class="background-transparent center" [style.height.px]="config.width + 32" [style.width]="pdfMode ? '100%' : (config.width + 32) + 'px'" *ngIf="config?.width && url">
    <ng-container *ngIf="true then qrcodeBlock"></ng-container>
  </div>
</ng-template>

<ng-template #qrcodeBlock>
  <div class="qrcodeDiv vertical-center" #qrcodeDiv>
    <ng-container *ngIf="config?.width && url">
      <ngx-qrcode-styling class="qrcode-styling" [data]="url" [config]="config"></ngx-qrcode-styling>
      <ng-container *ngIf="websiteLogo?.url && websiteLogo?.size">
        <ion-img class="qrcode-logo" [style.width.px]="config.width * websiteLogo.size" [style.height.px]="config.width * websiteLogo.size" [src]="websiteLogo.url"></ion-img>
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #titleCard>
  <div class="ion-padding-horizontal" *ngIf="title">
    <quill-view [content]="title"></quill-view>
  </div>
</ng-template>

<ng-template #nameCard>
  <div class="ion-text-center" *ngIf="name">
    <ion-text class="font-20 font-bold ion-text-center">
      {{ name }}
      <!-- <ng-container *ngIf="guestId === 'test' else guestNameBlock">
        {{ 'WEBSITE.qrcode.guest.test' | translate }}
      </ng-container>
      <ng-template #guestNameBlock>
        {{ guest?.name ? guest.name : group?.groupName }}
      </ng-template> -->
    </ion-text>
    <!-- <div class="font-14 ion-text-center ion-padding-vertical" *ngIf="checkinStatus">
      <app-website-success></app-website-success>
      <ion-icon class="font-40 vertical-middle" src="./assets/wedding/icon/guest/seating-outline.svg"></ion-icon>&nbsp;<ion-text class="font-36 vertical-middle">{{ seating ? seating : '-' }}</ion-text>
    </div> -->
  </div>
</ng-template>

<ng-template #contentCard>
  <div>
    <div class="ion-padding-horizontal" *ngIf="qrcodeTitle">
      <quill-view [content]="qrcodeTitle"></quill-view>
    </div>
    <div *ngIf="websiteDetail?.qrcode?.media?.url">
      <ng-container *ngIf="websiteDetail?.qrcode?.media.type === 'youtube'">
        <app-youtube [websiteMode]="true" [setCardHeight]="mediaHeight" [setCalculateSize]="updateTheme" [setSrc]="websiteDetail?.qrcode?.media.url"></app-youtube>
      </ng-container>
      <ng-container *ngIf="websiteDetail?.qrcode?.media.type === 'base64' || websiteDetail?.qrcode?.media.type === 'firebase'">
        <ion-img class="full-width center" [src]="websiteDetail?.qrcode?.media.url"></ion-img>
      </ng-container>
    </div>
    <div class="ion-padding-horizontal" *ngIf="qrcodeMsg">
      <quill-view [content]="qrcodeMsg"></quill-view>
    </div>
  </div>
</ng-template>

<ng-template #webCheckinCard>
  <div class="ion-padding ion-margin-vertical" *ngIf="websiteDetail?.checkin?.enable && webCheckinBtn && !checkinStatus">
    <ng-container *ngIf="true then webCheckinBtnBlock"></ng-container>
  </div>
</ng-template>

<ng-template #disableCard>
  <ion-card class="background-transparent ion-padding">
    {{ 'WEBSITE.qrcode.disabled' | translate }}
  </ion-card>
</ng-template>

<ng-template #webCheckinBtnBlock>
  <div class="full-width ion-text-center ion-padding-horizontal">
    <ion-button [class]="websiteThemeColor?.button?.fill === 'outline' ? 'website-outline-btn' : 'website-solid-btn'" [fill]="websiteThemeColor?.button?.fill" (click)="toggleWebCheckinMode()" *ngIf="pdfMode">
      {{ webCheckinBtn }}
    </ion-button>
    <ion-text class="font-14 font-underline" *ngIf="!pdfMode">
      {{ webCheckinBtn }}
    </ion-text>
  </div>
</ng-template>

<ng-template #downloadBtnBlock>
  <div class="ion-text-center ion-padding-horizontal ion-margin-horizontal" *ngIf="!pdfMode">
    <div class="ion-padding-top" *ngIf="websiteType === 'qrcode'">
      <ion-button class="full-width" [class]="websiteThemeColor?.button?.fill === 'outline' ? 'website-outline-btn' : 'website-solid-btn'" [fill]="websiteThemeColor?.button?.fill" (click)="download()">
        {{ websiteDetail?.qrcode?.btn ? websiteDetail.qrcode.btn : ('BTN.download' | translate) }}
      </ion-button>
    </div>
    <ng-container *ngIf="websiteType !== 'qrcode'">
      <ion-text class="font-12 font-underline" (click)="download()">{{ websiteDetail?.qrcode?.btn ? websiteDetail.qrcode.btn : ('BTN.download' | translate) }}</ion-text>
    </ng-container>
  </div>
</ng-template>

<ng-template #timeBlock>
  <app-website-time class="full-width" [pageMode]="true" [previewMode]="previewMode ? true : false" [setAccountInfo]="accountInfo" [setStartTime]="accountInfo?.time?.seconds" [setEndTime]="accountInfo?.endTime?.seconds" [setWebsiteDetail]="websiteDetail" [websiteThemeColor]="websiteThemeColor" [setLocale]="accountInfo?.locale" [setDateFormat]="accountInfo?.dateFormat" [setTimeFormat]="accountInfo?.timeFormat" [setTimezone]="accountInfo?.timezone" [setFontSize]="websiteDetail?.time?.fontSize" [setDesign]="websiteDetail?.time?.design" [saveCalendar]="false"></app-website-time>
</ng-template>

<ng-template #checkinStatusBlock>
  <app-website-checkin-success [websiteDetail]="websiteDetail" [setGuest]="guest" [setGroup]="group" [setMemberList]="memberList" [setCheckinStatus]="checkinStatus"></app-website-checkin-success>
</ng-template>