import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, Subscription, map } from 'rxjs';

import { UpdateByService } from 'src/app/services/user/update-by.service';
import { FunctionService } from 'src/app/services/general/function.service';

import { SettingFieldSortingType } from 'src/app/types/general';
import { SettingField } from 'src/app/interfaces/database';
import { DefaultDietaryReqList, StdDietaryReqList } from 'src/app/commons/dietaryReq';
import { ErrorService } from 'src/app/services/general/error.service';
import { AccountEventModeService } from '../account/account-event-mode.service';


/**
 * Dietary request service
 */
@Injectable({
  providedIn: 'root'
})
export class DietaryReqService implements OnInit, OnDestroy {
  
  reservedField: SettingField = {
    custom: false,
    value: 'none',
  };
  /**
   * Standard dietary request database
   */
  // stdDietaryReqList: string[] = StdDietaryReqList;
  // defaultDietaryReqList: string[] = DefaultDietaryReqList;
  /**
   * Dietary request list
   */
  dietaryReqList: SettingField[];
  /**
   * Observable dietary request list
   */
  observableDietaryReqList: any;
  /**
   * Dietary request subscription
   */
  private dietaryReqSubscription: Subscription;
  /**
   * Account ID
   */
  private accountId: string;

  

  /**
   * Constructor
   * @param afs angualr firestore
   * @param translate translate service
   * @param userService user serivce
   * @param functionService function service
   */
  constructor(
    private afs: AngularFirestore,
    private updateByService: UpdateByService,
    private functionService: FunctionService,
    private errorService: ErrorService
  ) {
    this.dietaryReqList = [];
    this.observableDietaryReqList = new BehaviorSubject<SettingField[]>(this.dietaryReqList);
  }

  ngOnInit(): void {

  }

  ngOnDestroy() {
    this.unwatchDietaryReq();
    this.dietaryReqList = [];
  }

  /**
   * Setup Account ID and watch / unwatch dietary request
   */
  async setupAccountId(accountId: string) {
    this.accountId = accountId;

    if (this.accountId) {
      await this.watchDietaryReq();
    } else {
      await this.unwatchDietaryReq();
      this.dietaryReqList = [];
    }
  }

  /**
   * Watch dietary request from firestore
   */
  async watchDietaryReq() {
    if (this.accountId && !this.dietaryReqSubscription) {
      this.dietaryReqSubscription = this.afs.doc(`accounts/${ this.accountId }/accountSetting/dietaryReq`)
      .snapshotChanges().pipe(map(changes => {
        const data: any = changes.payload.data();
        return data?.list;
      }), map((dietaryReqList: SettingField[]) => {
        if (dietaryReqList?.length) {
          return dietaryReqList.map((settingField: SettingField) => {
            if (!settingField?.id) {
              settingField.id = this.functionService.randomId() + '_' + settingField.value;
            }
            // if (settingField?.createBy) {
            //   delete settingField.createBy;
            // }
            // if (settingField?.updateBy) {
            //   delete settingField.updateBy;
            // }
            return settingField;
          });
        } else {
          return [];
          // return this.stdDietaryReqList.map((value: string) => {
          //   return { id: value, custom: false, value };
          // });
        }
      })).subscribe({
        next: (dietaryReqList: SettingField[]) => {
          if (!this.checkReservedField(dietaryReqList)) {
            dietaryReqList.push(this.reservedField);
          }
          this.dietaryReqList = dietaryReqList.filter((item: SettingField, index: number, self: SettingField[]) =>
            index === self.findIndex((t) => t.value === item.value && t.custom === item.custom)
          );
          this.observableDietaryReqList.next(this.dietaryReqList);
        }});
    }
  }

  /**
   * Unwatch dietary request
   */
  async unwatchDietaryReq() {
    if (this.dietaryReqSubscription) {
      this.dietaryReqSubscription.unsubscribe();
      this.dietaryReqSubscription = null;
    }
  }

  checkReservedField(settingFieldList: SettingField[]) {
    const index = settingFieldList?.findIndex((x: SettingField) => {
      return x.custom === this.reservedField.custom && x.value === this.reservedField.value;
    });
    return index !== -1;
  }

  /**
   * Search dietary request List
   * @param keyword Keyword
   * @param type Type
   * @param filter Filter
   * @returns List of dietary request
   */
  searchDietaryReqList(keyword?: string, filter?: any, sorting?: SettingFieldSortingType, desc?: boolean): SettingField[] {
    let dietaryReqList: SettingField[] = this.getDietaryReqList();
    if (dietaryReqList?.length) {
      if (keyword) {
        dietaryReqList = dietaryReqList.filter((dietaryReq: SettingField) => {
          return this.searchDietaryReqBykeyword(dietaryReq, keyword);
        });
      }

      if (filter?.enable) {
        dietaryReqList = dietaryReqList.filter((dietaryReq: SettingField) => {
          return this.filterDietaryReqByCriteria(dietaryReq, filter);
        });
      }
    }
    return dietaryReqList;
  }

  /**
   * Search dietary request by keyword
   * @param dietaryReq Dietary request
   * @param keyword Keyword
   */
  searchDietaryReqBykeyword(dietaryReq: SettingField, keyword: string): boolean {
    if (keyword && dietaryReq?.value) {
      const value = dietaryReq.custom ? dietaryReq.value :
        this.functionService.getTranslate('LIST.dietary_req.' + dietaryReq.value).toLowerCase();
      if (this.functionService.search(value, keyword)) {
        return true;
      } else if (this.functionService.chineseMath(value, keyword)) {
        return true;
      }
    }
    return false;
  }

  /**
   * Filter diteary request by criteria
   * @param dietaryReq dietary request
   * @param filter filter criteria
   */
  filterDietaryReqByCriteria(dietaryReq: SettingField, filter: any): boolean {
    if (filter?.enable) {
      if (filter?.type) {
        if (filter?.type === 'system' && dietaryReq?.custom) {
          return false;
        } else if (filter?.type === 'user' && !dietaryReq?.custom) {
          return false;
        }
      }

      // if (filter?.createBy) {
      //   if (filter?.createBy === 'system') {
      //     if (dietaryReq?.custom) {
      //       return false;
      //     }
      //   } else {
      //     if (!dietaryReq?.createBy?.uid || dietaryReq?.createBy?.uid !== filter?.createBy) {
      //       return false;
      //     }
      //   }
      // }

      // if (filter?.updateBy) {
      //   if (!dietaryReq?.updateBy?.uid || dietaryReq?.updateBy?.uid !== filter?.updateBy) {
      //     return false;
      //   }
      // }
    }
    return true;
  }

  /**
   * Sort special request list
   * @param dietaryReqList list to be sorted
   * @param field Sorting type
   * @param desc Descending flag
   */
  sortList(dietaryReqList: SettingField[], field?: SettingFieldSortingType, desc?: boolean): SettingField[] {
    if (dietaryReqList?.length) {
      dietaryReqList.sort((a: SettingField, b: SettingField) => {
        const nameA = a.custom ? a.value.toString() : this.functionService.getTranslate('LIST.dietary_req.' + a.value).toString();
        const nameB = b.custom ? b.value.toString() : this.functionService.getTranslate('LIST.dietary_req.' + b.value).toString();
        if (field) {
          // if (field === 'createdTime') {
          //   const timeA: number = a?.createBy?.time?.seconds ? a.createBy.time.seconds : 0;
          //   const timeB: number = b?.createBy?.time?.seconds ? b.createBy.time.seconds : 0;
          //   if (timeA !== timeB) {
          //     return this.functionService.compare(timeA, timeB, desc);
          //   }
          // } else if (field === 'updatedTime') {
          //   const timeA: number = a?.updateBy?.time?.seconds ? a.updateBy.time.seconds : 0;
          //   const timeB: number = b?.updateBy?.time?.seconds ? b.updateBy.time.seconds : 0;
          //   if (timeA !== timeB) {
          //     return this.functionService.compare(timeA, timeB, desc);
          //   }
          // }
          return this.functionService.compare(nameA.toLowerCase(), nameB.toLowerCase(), desc);
        } else {
          return this.functionService.compare(nameA.toLowerCase(), nameB.toLowerCase(), desc);
        }
        return this.functionService.compare(nameA.toLowerCase(), nameB.toLowerCase(), false);
      });
    }
    return dietaryReqList;
  }

  /**
   * Save dietary request into firestore
   * @param dietaryReqList Dietary request list
   */
  async saveDietaryReq(list: SettingField[]) {
    if (this.accountId && list?.length) {
      const data: any = {
        list,
        updateBy: this.updateByService.updateBy
      };

      const accountsRef = this.afs.firestore.doc(`accounts/${ this.accountId }/accountSetting/dietaryReq/`);
      accountsRef.set(data, { merge: true }).then(result => {
      }).catch((err: any) => {
        this.errorService.logError(err);
      });
    }
  }

  getDietaryReqList() {
    return this.dietaryReqList?.length ? this.dietaryReqList : this.getStdDietaryReqList();
  }

  getStdDietaryReqList(): SettingField[] {
    const stdDietaryReqList: SettingField[] = [];
    StdDietaryReqList?.forEach((value) => {
      const settingField: SettingField = {
        id: value,
        value,
        custom: false,
      }
      stdDietaryReqList.push(settingField);
    });
    return stdDietaryReqList;
  }

  getDefaultDietaryReqList(): SettingField[] {
    const defaultDietaryReqList: SettingField[] = [];
    DefaultDietaryReqList?.forEach((value) => {
      const settingField: SettingField = {
        id: value,
        value,
        custom: false,
      }
      defaultDietaryReqList.push(settingField);
    });
    return defaultDietaryReqList;
  }
}
