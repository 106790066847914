import { Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IonList, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { DeviceSafeAreaService } from 'src/app/services/device/device-safe-area.service';
import { ModuleService } from 'src/app/services/account/privilege/module.service';
import { GiftService } from 'src/app/services/gift/gift.service';
import { GiftListService } from 'src/app/services/gift/gift-list.service';
import { GiftDeleteService } from 'src/app/services/gift/gift-delete.service';
import { FunctionService } from 'src/app/services/general/function.service';
import { TabService } from 'src/app/services/general/tab.service';
import { PopupService } from 'src/app/services/general/popup.service';

import { GiftManageComponent } from 'src/app/components/gift/gift-manage/gift-manage.component';

import { Gift } from 'src/app/interfaces/gift';
import { GiftSortType, GiftType } from 'src/app/types/gift';
import { GuestListMode } from 'src/app/types/guest';
import { ModuleType } from 'src/app/types/general';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

/**
 * Gift list component
 */
@Component({
  selector: 'app-gift-list-component',
  templateUrl: './gift-list.component.html',
  styleUrls: ['./gift-list.component.scss'],
})
export class GiftListComponent implements OnInit, OnDestroy {

  /**
   * Watch screen resize change
   */
  @HostListener('window:resize', ['$event']) onResize(event: any) {
    this.setupContentHeight();
    this.resizeLimit();
  }
  /**
   * Watch screen orientation change
   */
  @HostListener('window:orientationchange', ['$event']) onOrientationChange(event: any) {
    this.setupContentHeight();
    this.resizeLimit();
  }

  @ViewChild(CdkVirtualScrollViewport) cdkVirtualScrollViewport: CdkVirtualScrollViewport;
  /**
   * Header for getting header height
   */
  @ViewChild('header', { read: ElementRef }) header: ElementRef;

  @ViewChild('content', { read: ElementRef }) contentElement: ElementRef;
  /**
   * Footer for getting footer height
   */
  @ViewChild('footer', { read: ElementRef }) footer: ElementRef;
  /**
   * Ion list for closing opened item
   */
  @ViewChild('list') list: IonList;

  /**
   * Gift list title
   */
  @Input() title: string;
  /**
   * Guest list mode
   */
  @Input() mode: GuestListMode;
  /**
   * Page mode
   */
  @Input() pageMode: boolean;
  /**
   * Default filter
   */
  @Input() private defaultFilter: any;
  /**
   * Reset component
   */
  @Input() set reset(reset: boolean) {
    if (reset) {
      this.initialize();
    } else {
      this.unwatchGiftList();
    }
  }
  /**
   * Edit mode
   */
  editMode: boolean;
  /**
   * Select mode
   */
  selectMode: boolean;
  /**
   * Select all
   */
  selectAll: boolean;
  /**
   * Reset search
   */
  resetSearch: boolean;
  /**
   * Selected gift list
   */
  selectedGiftList: string[];
  /**
   * Gift list
   */
  giftList: Gift[];
  /**
   * Current module
   */
  module: ModuleType;
  /**
   * Gift type
   */
  giftType: GiftType;
  /**
   * Currency
   */
  currency: string;

  contentHeight: string;

  limit: number;

  private viewReady: boolean;
  /**
   * Search term
   */
  private searchTerm: string;
  /**
   * Filter
   */
  private filter: any;
  /**
   * Sorting field
   */
  private sorting: GiftSortType;
  /**
   * Sort descending flag
   */
  private desc: boolean;
  /**
   * Gift list subscription
   */
  private giftListSubscription: Subscription;

  /**
   * Constructor
   * @param platform platform
   * @param translate translate service
   * @param modalController modal controller
   * @param moduleService module service
   * @param giftService gift service
   * @param giftListService gift list service
   * @param giftDeleteService gift delete service
   * @param tabService tab service
   * @param popupService popup service
   */
  constructor(
    private translate: TranslateService,
    private modalController: ModalController,
    private moduleService: ModuleService,
    private deviceSafeAreaService: DeviceSafeAreaService,
    private giftService: GiftService,
    private giftListService: GiftListService,
    private giftDeleteService: GiftDeleteService,
    private tabService: TabService,
    private popupService: PopupService,
    private functionService: FunctionService,
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.viewReady = true;
  }

  ngOnDestroy(): void {
    this.unwatchGiftList();
  }

  /**
   * Before view enter.
   */
  ionViewWillEnter() {
    this.initialize();
  }

  ionViewDidEnter() {
    this.viewReady = true;
  }

  /**
   * Before view leave
   */
  ionViewWillLeave() {
    this.unwatchGiftList();
  }

  initialize() {
    this.viewReady = false;
    this.selectedGiftList = [];
    if (!this.module) {
      this.module = this.moduleService.currentModule;
    }
    this.watchGiftList();
    this.setupContentHeight();
    this.setupLimit();
  }

  /**
   * Watch gift list
   */
  async watchGiftList() {
    if (!this.giftListSubscription) {
      if (this.module === 'trash') {
        this.giftListSubscription = this.giftService.observableTrashGiftList.subscribe(() => {
          this.setupGiftList();
        });
      } else {
        this.giftListSubscription = this.giftService.observableGiftList.subscribe(() => {
          this.setupGiftList();
        });
      }
    }
    
  }

  /**
   * Unwatch gift list
   */
  async unwatchGiftList() {
    if (this.giftListSubscription) {
      this.giftListSubscription.unsubscribe();
      this.giftListSubscription = null;
    }
  }

  /**
   * Check default filter value
   */
  checkDefaultFilter() {
    if (this.defaultFilter?.enable) {
      if (!this.filter) { this.filter = {}; }
      Object?.keys(this.defaultFilter)?.forEach(key => {
        if (!this.defaultFilter?.[key]) {
          this.defaultFilter[key] = null;
        } else {
          this.filter[key] = this.defaultFilter[key];
        }
      });
    }
  }

  async setupContentHeight() {
    if (this.pageMode) {
      await this.functionService.delay(300);
      if (this.header?.nativeElement?.offsetHeight) {
        this.contentHeight = 'calc(100vh - ' + (this.getHeaderHeight() + this.getFooterHeight()) + 'px)';
      } else {
        await this.functionService.delay(300);
        this.setupContentHeight();
      }
    } else {
      this.contentHeight = '100%';
    }
    
  }

  /**
   * Setup guest list and group guest into same object.
   * Apply default filter for specified guest list.
   */
  setupGiftList() {
    if (this.mode !== 'preview') {
      this.checkDefaultFilter();
      const type: any = {};
      if (this.giftType) {
        type.giftType = this.giftType;
        if (this.giftType === 'cash' && this.currency) {
          type.currency = this.currency;
        }
      }
      const giftList = this.giftListService.searchGiftList(
        this.searchTerm,
        type,
        this.filter,
        [],
        this.sorting,
        this.desc
      );
      this.giftList = giftList;

      this.setupLimit();
      this.setupViewport();
    }
  }

  setupViewport() {
    if (this.cdkVirtualScrollViewport && this.viewReady) {
      setTimeout(() => {
        this.cdkVirtualScrollViewport?.checkViewportSize();
      }, 200);
    } else {
      setTimeout(() => {
        this.setupViewport();
      }, 200);
    }
  }

  setupLimit() {
    if (!this.limit) {
      this.limit = this.getLimit();
    } else {
      const limit = this.getLimit();
      if (limit > this.limit) {
        this.limit = limit;
      }
    }
  }

  /**
   * Search guest
   * @param data data for search, filter, sorting
   */
  search(data: any) {
    this.searchTerm = data?.keyword;
    this.filter = data?.filter?.enable ? data.filter : null;
    this.sorting = data?.sorting;
    this.desc = data?.desc ? true : false;
    this.setupGiftList();
  }

  getScreenHeight() {
    return '100vh';
    // if (window?.innerHeight) {
    //   return window.innerHeight.toString() + 'px';
    // } else {
    //   return '100vh';
    // }
  }

  /**
   * Get header height
   */
  getHeaderHeight() {
    let header = 0;
    if (this.header?.nativeElement?.offsetHeight) {
      header = this.header.nativeElement.offsetHeight;
      if (this.pageMode) { 
        if (this.tabService.showTab) {
          header += this.deviceSafeAreaService.getSafeArea();
        }
      }
    }
    
    return header;
  }

  getContentHeight(): number {
    return this.contentElement?.nativeElement?.offsetHeight ? this.contentElement.nativeElement.offsetHeight : 0;
  }

  /**
   * Get footer height
   */
  getFooterHeight() {
    let footer = 0;
    if (this.pageMode) {
      if (this.editMode) {
        if (this.footer?.nativeElement?.offsetHeight) {
          footer += this.footer.nativeElement.offsetHeight;
        }
        if (!footer) {
          footer = 106;
        }
      }
      
      if (this.tabService.showTab) {
        footer += 50;
      }
    }
    return footer;
  }

  setupFooter() {
    if (this.pageMode) {
      this.tabService.updateShowTab(!this.editMode);
    }
  }

  /**
   * Close opened sliding item.
   */
  closeSliding() {
    if (this.list) {
      this.list?.closeSlidingItems();
    }
  }

  /**
   * Toggle edit mode
   */
  toggleEditMode(editMode?: boolean) {
    if (this.mode !== 'preview') {
      if (this.functionService.isUndefined(editMode)) {
        this.editMode = !this.editMode;
      } else {
        this.editMode = editMode;
      }
      this.resetSelectAll();
      this.setupFooter();
      this.closeSliding();
      this.setupContentHeight();
    }
  }

  /**
   * Reset select all
   */
  resetSelectAll() {
    this.selectAll = false;
    this.selectedGiftList = [];
  }

  /**
   * Toggle select all
   */
  toggleSelectAll() {
    this.selectAll = !this.selectAll;
    this.selectedGiftList = [];
    if (this.selectAll) {
      this.giftList?.forEach((gift: Gift) => {
        this.selectedGiftList.push(gift.giftId);
      });
    }
  }

  /**
   * Check select all
   */
  checkSelectAll() {
    if (this.giftList.length && this.selectedGiftList?.length === this.giftList.length) {
      this.selectAll = true;
    } else {
      this.selectAll = false;
    }
  }

  /**
   * Set selected guest
   * @param guestId guest id
   */
  setSelectedGift(guestId: string) {
    const index = this.selectedGiftList?.indexOf(guestId);
    if (index === -1) {
      this.selectedGiftList.push(guestId);
    } else {
      this.selectedGiftList?.splice(index, 1);
    }
    this.checkSelectAll();
  }

  /**
   * Bulk action
   * @param type bulk action type
   */
  async bulkAction(type: string) {
    if (type) {
      if (type === 'add') {
        await this.presentGiftManageModal(true);
        this.toggleEditMode(false);
      } else {
        this.checkSingleRecord();
        if (type === 'info') {
          await this.presentGiftManageModal(false, true, this.selectedGiftList);
          this.toggleEditMode(false);
        } else if (type === 'delete') {
          if (this.module === 'trash') {
            this.promptDelete();
          } else {
            this.promptRemove();
          }
        } else if (type === 'restore') {
          this.promptRestore();
        }
      }
    }
  }

  /**
   * Check if single record then trigger select all
   */
  checkSingleRecord() {
    if (!this.selectAll && this.giftList.length === 1) {
      this.toggleSelectAll();
    }
  }

  /**
   * Dismiss modal
   * @param gift Gift
   */
  async dismissModal(gift?: Gift) {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { this.modalController.dismiss({ gift }); }
    }
  }

  /**
   * Present gift manage modal
   * @param newGiftMode new gift mode
   * @param bulkEditMode bulk edit modal
   * @param selectedGiftList selected gift list
   */
  async presentGiftManageModal(newGiftMode?: boolean, bulkEditMode?: boolean, selectedGiftList?: string[]) {
    let gift: Gift;
    if (!newGiftMode) {
      if (this.selectedGiftList?.length === 1) {
        bulkEditMode = false;
        gift = this.giftService.getGiftById(this.selectedGiftList[0]);
      }
    }
    const modal = await this.modalController.create({
      component: GiftManageComponent,
      cssClass: '',
      componentProps: {
        newGiftMode,
        gift,
        bulkEditMode,
        selectedGiftList
      }
    });
    modal.present();
  }

  /**
   * Prompt confirm delete modal
   */
   async promptRemove() {
    if (this.editMode) {
      const giftList: Gift[] = this.giftService.getGiftListById(this.selectedGiftList);
      const modal = await this.popupService.presentConfirm(
        this.translate.instant('CRUD.confirm_delete_field_count', {
          field: this.translate.instant('GIFT.lbl.gift'), count: this.selectedGiftList?.length }),
          null, null, null, null, null, null, null, null, null, giftList
      );
      modal.onDidDismiss().then(async (result: any) => {
        if (result?.data?.confirm) {
          await this.removeGift();
        }
        this.toggleEditMode(false);
      });
    }
  }

  /**
   * Prompt confirm restore modal
   */
  async promptRestore() {
    if (this.editMode) {
      const giftList: Gift[] = this.giftService.getGiftListById(this.selectedGiftList);
      const modal = await this.popupService.presentConfirm(
        this.translate.instant('CRUD.confirm_restore_field', {
          field: this.translate.instant('GIFT.lbl.gift'), count: this.selectedGiftList?.length }),
          null, null, null, null, null, null, null, null, null, giftList
      );
      modal.onDidDismiss().then((result: any) => {
        if (result?.data?.confirm) {
          this.restoreGift();
        }
      });
    }
  }

  /**
   * Prompt confirm restore modal
   */
  async promptDelete() {
    if (this.editMode) {
      const giftList: Gift[] = this.giftService.getGiftListById(this.selectedGiftList);
      const modal = await this.popupService.presentConfirm(
        this.translate.instant('CRUD.confirm_delete_db_field', {
          field: this.translate.instant('GIFT.lbl.gift'), count: this.selectedGiftList?.length }),
          null, null, null, null, null, null, null, null, null, giftList
      );
      modal.onDidDismiss().then(async (result: any) => {
        if (result?.data?.confirm) {
          await this.deleteGift();
        }
        this.toggleEditMode(false);
      });
    }
  }

  /**
   * Save remove gift
   */
  async removeGift() {
    if (this.editMode) {
      await this.popupService.presentLoading();
      if (this.selectedGiftList?.length) {
        await this.giftDeleteService.removeGiftList(this.selectedGiftList);
      }
      this.popupService.dismissLoading();
      this.popupService.saveSuccessToast();
    }
  }

  /**
   * Remove gift from db
   */
  async deleteGift() {
    if (this.editMode) {
      await this.popupService.presentLoading();
      if (this.selectedGiftList?.length) {
        await this.giftDeleteService.deleteGiftList(this.selectedGiftList);
      }
      this.popupService.dismissLoading();
      this.popupService.saveSuccessToast();
    }
  }

  /**
   * Save restore guest
   */
  async restoreGift() {
    if (this.editMode) {
      await this.popupService.presentLoading();
      if (this.selectedGiftList?.length) {
        await this.giftDeleteService.restoreGiftList(this.selectedGiftList);
      }
      this.popupService.dismissLoading();
      this.popupService.saveSuccessToast();
    }
  }

  /**
   * Ion virtual scroll track by index
   * @param index index
   * @param item gift item / gift id
   * @returns index
   */
  trackByFn(index: number, item: Gift) {
    if (item?.giftId) {
      return item.giftId;
    }
    return index;
  }
  
  resizeLimit() {
    const limit = this.getLimit();
    if (limit > this.limit) {
      this.limit = limit;
      // this.scrollToTop();
    }
  }

  getLimit() {
    const limit = Math.ceil(this.getContentHeight() / 110);
    return limit > 10 ? limit : 20;
  }

  async loadLimit(event: any) {
    await this.functionService.delay(50);
    if (this.limit >= this.giftList.length) {
      // event.target.disabled = true;
    } else {
      this.limit += this.getLimit();
    }
    event.target.complete();
    this.setupViewport();
  }

}
