<ion-header>
  <ion-toolbar color="light">
    <ion-buttons slot="start">
      <ion-button (click)="dismissModal()">
        <ion-icon class="back-icon" src="./assets/wedding/icon/general/chevron-back.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <app-offline-indicator></app-offline-indicator>
      {{ 'CRUD.update_field' | translate: { field : seatingTypeName } }}
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding-bottom" color="white">
  <form class="ion-padding" [formGroup]="seatingForm" (ngSubmit)="seatingFormSubmit()" *ngIf="seatingForm">
    <ion-item class="ion-item-no-padding" color="white" lines="none" *ngIf="seating && seatingSummary && !bulkEditMode">
      <ion-label position="stacked" color="dark">{{ 'SEATING.lbl.name' | translate: { seating: seatingTypeName } }}</ion-label>
      <ion-input class="prompt-input" formControlName="name" debounce="800" type="text" inputmode="text" autocapitalize="words" tabindex="1"></ion-input>
      <div class="error-div">
        <ng-container *ngFor="let validation of validationMsg?.name">
          <div class="error-message" *ngIf="seatingForm.get('name').hasError(validation.type) && (seatingForm.get('name').dirty || seatingForm.get('name').touched)">
            <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ validation.msg }}
          </div>
        </ng-container>
      </div>
    </ion-item>

    <ng-container *ngIf="bulkEditMode && selectedSeatingList?.length">
      <ion-card class="ion-no-margin ion-padding" color="secondary">
        <ion-label position="stacked" color="dark">{{ 'SEATING.lbl.selected_seating' | translate: { seating: seatingTypeName } }}</ion-label>
        <div>
          <ion-label class="ion-text-wrap">
            {{ selectedSeatingListName }}
          </ion-label>
        </div>
      </ion-card>
      <div class="error-div">
      </div>
    </ng-container>

    <ion-item class="ion-item-no-padding" color="white" lines="none">
      <ion-label position="stacked" color="dark">{{ 'SEATING.lbl.count' | translate: { seating: seatingTypeName } }}</ion-label>
      <ion-item class="ion-item-no-padding ion-margin-bottom full-width" color="white" lines="none">
        <ion-input class="prompt-input ion-text-center" formControlName="count" min="1" type="number" inputmode="numeric" tabindex="2">
          <ion-button class="qty-btn" slot="start" (click)="minusSeating()" fill="clear">
            <ion-icon src="./assets/wedding/icon/general/remove.svg"></ion-icon>
          </ion-button>
          <ion-button class="qty-btn" slot="end" (click)="addSeating()" fill="clear">
            <ion-icon src="./assets/wedding/icon/general/add.svg"></ion-icon>
          </ion-button>
        </ion-input>
        
      </ion-item>
    </ion-item>
    <div class="error-div">
      <ng-container *ngFor="let validation of validationMsg?.count">
        <div class="error-message" *ngIf="seatingForm.get('count').hasError(validation.type) && (seatingForm.get('count').dirty || seatingForm.get('count').touched)">
          <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ validation.msg }}
        </div>
      </ng-container>
    </div>

    <ion-item class="ion-item-no-padding" color="white" lines="none">
      <ion-label position="stacked" color="dark">{{ 'SEATING.lbl.max_guest' | translate: { seating: seatingTypeName } }}</ion-label>
      <ion-item class="ion-item-no-padding ion-margin-bottom full-width" color="white" lines="none">
        <ion-input class="prompt-input ion-text-center" formControlName="maxGuest" min="1" type="number" inputmode="numeric" tabindex="3">
          <ion-button class="qty-btn" slot="start" (click)="minusMaxGuest()" fill="clear">
            <ion-icon src="./assets/wedding/icon/general/remove.svg"></ion-icon>
          </ion-button>
          <ion-button class="qty-btn" slot="end" (click)="addMaxGuest()" fill="clear">
            <ion-icon src="./assets/wedding/icon/general/add.svg"></ion-icon>
          </ion-button>
        </ion-input>
      </ion-item>
    </ion-item>
    <div class="error-div">
      <ng-container *ngFor="let validation of validationMsg?.maxGuest">
        <div class="error-message" *ngIf="seatingForm.get('maxGuest').hasError(validation.type) && (seatingForm.get('maxGuest').dirty || seatingForm.get('maxGuest').touched)">
          <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ validation.msg }}
        </div>
      </ng-container>
    </div>

    <div *ngIf="(!seatingSummary || !seatingSummary.invitedBy || !seatingSummary.invitedBy.length) && invitedByList?.length">
      <ion-label class="custom-stacked-label" position="stacked" color="dark">{{ 'GUEST.lbl.invited_by' | translate }}</ion-label>
      <ion-segment scrollable formControlName="invitedBy" *ngIf="invitedByList">
        <ion-segment-button [value]="invitedBy.value" *ngFor="let invitedBy of invitedByList">
          <ion-text>
            <ng-container *ngIf="invitedBy.custom">{{ invitedBy.value }}</ng-container>
            <ng-container *ngIf="!invitedBy.custom">{{ 'LIST.invited_by.' + invitedBy.value | translate }}</ng-container>
          </ion-text>
        </ion-segment-button>
      </ion-segment>
      <div class="error-div"></div>
    </div>

    <ion-item class="ion-item-no-padding" color="white" lines="none" (click)="presentSettingFieldModal('category', seatingForm?.value?.category)" *ngIf="!seatingSummary || !seatingSummary.category || !seatingSummary.category.length">
      <ion-label position="stacked" color="dark">{{ 'LBL.category' | translate }}</ion-label>
      <ion-item class="prompt-input-item" lines="none">
        <ion-label class="ion-no-margin" >{{ category }}</ion-label>
        <ion-note slot="end"><ion-icon src="./assets/wedding/icon/general/chevron-down.svg" class="font-18"></ion-icon></ion-note>
      </ion-item>
      <div class="error-div"></div>
    </ion-item>

    <ion-card class="ion-no-margin ion-padding-vertical" color="light">
      <ion-item color="light" lines="none">
        <ion-toggle formControlName="reserved" color="success">
          <ion-text class="ion-text-wrap">
            <div class="font-12 line-150 font-bold ">{{ 'SEATING.setting.reserved.title' | translate: { seating: seatingTypeName } }}</div>
            <div class="font-10">
              {{ 'SEATING.setting.reserved.msg' | translate: { seating: seatingTypeName } }}
            </div>
          </ion-text>
        </ion-toggle>
      </ion-item>
    </ion-card>
    

    <div class="ion-padding">
      <ion-button expand="block" color="primary" fill="solid" type="submit" [disabled]="!seatingForm.valid">
        {{ 'BTN.save' | translate }}
      </ion-button>
    </div>
  </form>

  <ion-item-sliding class="ion-margin-vertical" *ngIf="!bulkEditMode" #deleteItem>
    <ion-item-options side="start" (ionSwipe)="promptDelete(deleteItem)" lines="none">
      <ion-item-option color="danger" (click)="promptDelete(deleteItem)" lines="none">
        <ion-icon src="./assets/wedding/icon/general/checkmark.svg"></ion-icon>
      </ion-item-option>
    </ion-item-options>
    <ion-item color="danger" lines="none">
      <ion-icon slot="start" class="icon-small" src="./assets/wedding/icon/general/trash.svg"></ion-icon> 
      <ion-text slot="start">{{ 'CRUD.swipe_field' | translate: { field: 'CRUD.delete' | translate } }}</ion-text> 
      <ion-icon slot="start" class="icon-small" src="./assets/wedding/icon/general/arrow-forward.svg"></ion-icon>
    </ion-item>
    <ion-item-options side="end" (ionSwipe)="promptDelete(deleteItem)" lines="none">
      <ion-item-option color="danger" (click)="promptDelete(deleteItem)" lines="none">
        <ion-icon src="./assets/wedding/icon/general/checkmark.svg"></ion-icon>
      </ion-item-option>
    </ion-item-options>
  </ion-item-sliding>
  
</ion-content>