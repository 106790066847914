import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AccountInfo } from 'src/app/interfaces/account';
import { WebsiteDetail, WebsiteItineraryDetail } from 'src/app/interfaces/website';
import { FunctionService } from 'src/app/services/general/function.service';

@Component({
  selector: 'app-website-itinerary',
  templateUrl: './website-itinerary.component.html',
  styleUrls: ['./website-itinerary.component.scss'],
})
export class WebsiteItineraryComponent  implements OnInit, OnDestroy {

  @Input() set setReady(ready: boolean) {
    if (ready) {
      this.setup();
    }
  }

  @Input() previewMode: boolean;
  @Input() pageMode: boolean;
  
  @Input() set setWebsiteDetail(websiteDetail: WebsiteDetail) {
    this.websiteDetail = websiteDetail;
    this.title = this.getContent(this.websiteDetail?.itinerary?.title);
    this.msg = this.getContent(this.websiteDetail?.itinerary?.msg);
  }

  @Input() accountInfo: AccountInfo;

  @Input() mediaHeight: number;

  @Input() updateTheme: boolean;

  websiteDetail: WebsiteDetail;

  title: string;
  msg: string;

  itineraryList: WebsiteItineraryDetail[];
  
  constructor(
    private functionService: FunctionService,
  ) { }

  ngOnInit() {}

  ngOnDestroy(): void {
      
  }

  setup() {
    if (this.websiteDetail?.itinerary?.itineraryList) {
      this.itineraryList = Object.values(this.websiteDetail.itinerary.itineraryList).sort((a, b) => {
        return this.functionService.compare(a.order, b.order);
      });
    } else {
      this.itineraryList = [];
    }

    // const title = this.websiteDetail?.itinerary?.title;
    // const msg = this.websiteDetail?.itinerary?.msg;

    // if (title) {
    //   if (! this.functionService.sanitizeHtml(title).trim()) {
    //     this.websiteDetail.itinerary.title = '';
    //   }
    // }

    // if (msg) {
    //   if (! this.functionService.sanitizeHtml(msg).trim()) {
    //     this.websiteDetail.itinerary.msg = '';
    //   }
    // }
  }

  getContent(msg: string) {
    if (msg) {
      if (!this.functionService.sanitizeHtml(msg).trim()) {
        return '';
      }
    }
    return msg;
  }

}
