import { Injectable, OnDestroy, OnInit } from '@angular/core';

import { ModuleService } from 'src/app/services/account/privilege/module.service';
import { GuestService } from 'src/app/services/guest/guest.service';
import { GroupService } from 'src/app/services/group/group.service';
import { GiftService } from 'src/app/services/gift/gift.service';
import { FunctionService } from 'src/app/services/general/function.service';

import { Guest } from 'src/app/interfaces/guest';
import { Gift } from 'src/app/interfaces/gift';
import { GuestSortType, GuestFilterType, BlastSentType, GuestListMode } from 'src/app/types/guest';
import { ModuleType } from 'src/app/types/general';
import { BlastMsgService } from '../blast/blast-msg.service';
import { BlastMsg } from 'src/app/interfaces/blast';

/**
 * Guest list service to get guest data.
 */
@Injectable({
  providedIn: 'root'
})
export class GuestListService implements OnInit, OnDestroy {

  /**
   * Not attending guest flag
   */
  notAttendingGuest: boolean;
  /**
   * Pending guest flag
   */
  pendingGuest: boolean;

  /**
   * Constructor
   * @param moduleService module service
   * @param guestService guest service
   * @param groupService group service
   * @param giftService gift service
   * @param functionService function service
   */
  constructor(
    private moduleService: ModuleService,
    private guestService: GuestService,
    private groupService: GroupService,
    private giftService: GiftService,
    private blastMsgService: BlastMsgService,
    private functionService: FunctionService,
  ) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  /**
   * Set check-in module setting for guest status
   * @param pendingGuest show pending guest
   * @param notAttendingGuest show not attending guest
   */
  setCheckinSetting(pendingGuest: boolean, notAttendingGuest: boolean) {
    this.pendingGuest = pendingGuest;
    this.notAttendingGuest = notAttendingGuest;
  }

  /**
   * Search Guest List
   * @param keyword Keyword
   * @param type type of guest list
   * @param filter filter criteria
   * @param sorting sorting field
   * @param desc sort by descending flag
   * @param deleted deleted guest list
   * @returns List of Guest
   */
  searchGuestList(
    keyword?: string,
    type?: GuestFilterType,
    filter?: any,
    excludeGuestIdList?: string[],
    excludeGroupIdList?: string[],
    sorting?: GuestSortType,
    desc?: boolean,
    module?: ModuleType,
    mode?: GuestListMode,
  ) {
    let guestList: Guest[] = this.guestService.getGuestList(module);
    if (guestList) {
      if (module === 'trash') {
        guestList = guestList.filter((guest: Guest) => {
          return this.filterDeletedGuest(guest, true);
        });
      }
      // if (this.moduleService.currentModule === 'checkin' && (!this.notAttendingGuest || !this.pendingGuest)) {
      //   guestList = guestList.filter((guest: Guest) => {
      //     return this.excludeGuestForCheckin(guest);
      //   });
      // }

      if (excludeGuestIdList?.length) {
        guestList = guestList.filter((guest: Guest) => {
          return this.excludeGuestList(guest, excludeGuestIdList, excludeGroupIdList);
        });
      }

      if (keyword) {
        guestList = guestList.filter((guest: Guest) => {
          return this.searchGuestByKeyword(guest, keyword);
        });
      }

      if (type) {
        guestList = guestList.filter((guest: Guest) => {
          return this.filterGuestByType(guest, type);
        });
        if (type?.msgTemplateId && type?.msgTemplateType?.value && type?.blastSentType) {
          let blastMsgList: BlastMsg[] = [];
          if (!type.msgTemplateType?.custom) {
            blastMsgList = this.blastMsgService.getBlastMsgListByTemplateType(type.msgTemplateType);
          } else {
            blastMsgList = this.blastMsgService.getBlastMsgListByTemplateId(type.msgTemplateId);
          }
          guestList = guestList.filter((guest: Guest) => {
            return this.filterGuestByBlastMsgStatus(guest, type.blastSentType, blastMsgList);
          });
        }
      }

      if (filter?.enable) {
        guestList = guestList.filter((guest: Guest) => {
          return this.filterGuestByCriteria(guest, filter);
        });
      }
    }

    const list = this.generateGroupList(guestList, sorting, desc, mode);
    let count = guestList?.length ? guestList.length : 0;

    if (filter?.giftReceived) {
      count = list.length;
    } else if (type?.giftReceived) {
      count = list.length;
    } else if (!this.functionService.isUndefined(filter?.status?.gift)) {
      count = list.length;
    }
    return {
      count,
      list
    };
  }

  /**
   * Generate guest and grouped same groudId guest into same group object.
   * @param guestList guest list to be grouped
   * @param sorting sorting field
   * @param desc descending flag
   */
  generateGroupList(guestList: Guest[], sorting?: GuestSortType, desc?: boolean, mode?: GuestListMode) {
    const newList: any[] = [];
    if (guestList?.length) {
      guestList?.forEach((guest) => {
        if (guest?.guestId) {
          let flag = true;
          if (!guest?.groupId) {
            flag = false;
          } else {
            const index = newList?.findIndex((x) => x?.group?.groupId === guest.groupId);
            if (index === -1) {
              const group = { ...this.groupService.getGroup(guest.groupId) };
              if (group?.groupId) {
                const memberList = guestList?.filter((x) => x.groupId === group.groupId).sort((a: Guest, b: Guest) => {
                  return this.functionService.compare(a.name.toLowerCase(), b.name.toLowerCase());
                }).map((x) => {
                  return x.guestId;
                });
                if (memberList?.length) {
                  group.memberList = memberList?.filter((memberId: string, index: number, self: string[]) => {
                    return self.indexOf(memberId) === index;
                  });
                  newList.push( { group });
                } else {
                  flag = false;
                }
              } else {
                flag = false;
              }
            }
          }
          if (!flag) {
            newList.push(guest);
          }
        }
      });
    }
    return this.sortList(newList, sorting, desc, mode);
  }

  /**
   * Exclude guest
   * @param guest Guest
   * @param excludeGuestIdList Guest id to be excluded
   */
  excludeGuestList(guest: Guest, excludeGuestIdList: string[], excludeGroupIdList: string[]) {
    if (excludeGroupIdList?.length) {
      if (guest?.groupId && excludeGroupIdList?.indexOf(guest.groupId) !== -1) {
        return false;
      }
    }
    if (excludeGuestIdList?.length) {
      if (guest?.guestId && excludeGuestIdList?.indexOf(guest.guestId) !== -1) {
        return false;
      }
    }
    return true;
  }

  /**
   * Exlclude not attending / pending guest for check-in module
   * @param guest guest
   * @returns filter flag
   */
  excludeGuestForCheckin(guest: Guest): boolean {
    if (!this.notAttendingGuest && guest?.status?.attending === 'not_attending') {
      return false;
    }
    if (!this.pendingGuest && guest?.status?.attending === 'pending') {
      return false;
    }
    return true;
  }

  /**
   * Search guest by keyword
   * @param guest Guest
   * @param keyword Keyword
   */
  searchGuestByKeyword(guest: Guest, keyword: string): boolean {
    if (keyword && guest?.guestId) {
      if (guest.groupId) {
        const group = this.groupService.getGroup(guest.groupId);
        if (group && group.groupName) {
          if (guest.groupId === keyword) {
            return true;
          } else if (this.functionService.search(group.groupName, keyword)) {
            return true;
          } else if (this.functionService.chineseMath(group.groupName, keyword)) {
            return true;
          } else if (this.functionService.search(group?.mobile?.no, keyword)) {
            return true;
          } else if (this.functionService.search(group?.email, keyword)) {
            return true;
          }
        }
      }
      if (guest.name) {
        if (this.functionService.search(guest.name, keyword)) {
          return true;
        } else if (this.functionService.chineseMath(guest.name, keyword)) {
          return true;
        }
      }
      if (guest.nickname) {
        if (this.functionService.search(guest.nickname, keyword)) {
          return true;
        } else if (this.functionService.chineseMath(guest.nickname, keyword)) {
          return true;
        }
      }
      if (guest.remark) {
        if (this.functionService.search(guest.remark, keyword)) {
          return true;
        } else if (this.functionService.chineseMath(guest.remark, keyword)) {
          return true;
        }
      }
      if (this.functionService.search(guest?.email, keyword)) {
        return true;
      }
      if (this.functionService.search(guest?.mobile?.no, keyword)) {
        return true;
      }
      if (guest.guestId === keyword) {
        return true;
      }
    } else {
      return false;
    }
  }

  /**
   * Filter guest by delete status
   * @param guest guest
   * @param deleted deleted
   * @returns true if valid
   */
  filterDeletedGuest(guest: Guest, deleted?: boolean): boolean {
    if (deleted && !guest?.status?.deleted) {
      return false;
    } else if (!deleted && guest?.status?.deleted) {
      return false;
    }
    return true;
  }

  /**
   * Filter guest by type
   * @param guest guest
   * @param type filter type
   */
  filterGuestByType(guest: Guest, type: GuestFilterType): boolean {
    if (guest && type) {
      if (!this.functionService.isUndefined(type?.rsvp)) {
        if (type?.rsvp && !guest?.status?.rsvp) {
          return false;
        } else if (!type?.rsvp && guest?.status?.rsvp) {
          return false;
        }
      }

      if (type.qrcode) {
        if (type.qrcode === 'sent' && !guest?.status?.qrcode) {
          return false;
        }
        if (type.qrcode === 'not_send' && guest?.status?.qrcode) {
          return false;
        }
      }

      if (type.seating) {
        if (type.seating === 'assigned' && !guest.seating) {
          return false;
        }
        if (type.seating === 'not_assign' && guest.seating) {
          return false;
        }
      }

      if (type?.mobile || type?.email) {
        if (guest?.groupId) {
          const group = this.groupService.getGroup(guest.groupId);
          if (type?.mobile) {
            if (type.mobile === 'with' && !guest?.mobile?.no && !group?.mobile?.no) {
              return false;
            } else if (type.mobile === 'without' && (guest?.mobile?.no || group?.mobile?.no)) {
              return false;
            }
          }
          if (type?.email) {
            if (type.email === 'assigned' && !guest?.email && !group?.email) {
              return false;
            } else if (type.email === 'not_assign' && (guest?.email || group?.email)) {
              return false;
            }
          }
        } else {
          if (type?.mobile) {
            if (type.mobile === 'with' && !guest?.mobile?.no) {
              return false;
            } else if (type.mobile === 'without' && guest?.mobile?.no) {
              return false;
            }
          }
          if (type?.email) {
            if (type.email === 'assigned' && !guest?.email) {
              return false;
            } else if (type.email === 'not_assign' && guest?.email) {
              return false;
            }
          }
        }
      }

      if (type.attending) {
        if (guest.status.attending !== type.attending) {
          return false;
        }
      }

      if (type.group) {
        if (type.group === 'individual' && guest.groupId) {
          return false;
        } else if (type.group === 'group' && !guest.groupId) {
          return false;
        }
      }

      if (type.checkin) {
        if (type.checkin === 'attended' && !guest?.status?.checkin) {
          return false;
        } else if (type.checkin === 'not_attend' && guest?.status?.checkin) {
          return false;
        }
      }

      if (type.msgTemplateId) {

      }

      if (type?.giftReceived) {
        if (guest?.groupId) {
          const group = this.groupService.getGroup(guest?.groupId);
          if (type.giftReceived === 'received') {
            if (!guest?.status?.gift && !group?.status?.gift) {
              return false;
            }
          } else if (type.giftReceived === 'not_receive') {
            if (guest?.status?.gift || group?.status?.gift) {
              return false;
            }
          }
        } else {
          if (type.giftReceived === 'received') {
            if (!guest?.status?.gift) {
              return false;
            }
          } else if (type.giftReceived === 'not_receive') {
            if (guest?.status?.gift) {
              return false;
            }
          }
        }
      }
    }
    return true;
  }

  filterGuestByBlastMsgStatus(guest: Guest, blastSentType: BlastSentType, blastMsgList: BlastMsg[]): boolean {
    if (guest && blastSentType) {
      const msgIndex = blastMsgList.findIndex((blastMsg: BlastMsg) => {
        return (blastMsg?.guestId && blastMsg.guestId === guest.guestId) || (blastMsg?.groupId && blastMsg.groupId === guest.groupId);
      });
      if (blastSentType === 'sent') {
        if (msgIndex === -1 || blastMsgList[msgIndex]?.sent?.status !== 'sent') {
          return false;
        } 
      } else if (blastSentType === 'not_send') {
        if (msgIndex !== -1 && blastMsgList[msgIndex]?.sent?.status === 'sent') {
          return false;
        }
      }
    }
    return true;
  }

  /**
   * Filter guest by criteria
   * @param guest guest
   * @param filter filter criteria
   */
  filterGuestByCriteria(guest: Guest, filter: any): boolean {
    if (filter?.enable) {
      if (filter.invitedBy) {
        if (guest?.invitedBy?.[0]?.custom !== filter?.invitedBy?.custom || guest?.invitedBy?.[0]?.value !== filter?.invitedBy?.value) {
          return false;
        }
      }

      if (filter?.category) {
        if (guest?.category?.[0]?.custom !== filter?.category?.[0]?.custom || guest?.category?.[0]?.value !== filter?.category?.[0]?.value) {
          return false;
        }
      }

      if (filter?.dietaryReq) {
        if (guest?.dietaryReq?.[0]?.custom !== filter?.dietaryReq?.[0]?.custom
          || guest?.dietaryReq?.[0]?.value !== filter?.dietaryReq?.[0]?.value) {
          return false;
        }
      }

      if (filter?.specialReq) {
        if (guest?.specialReq?.[0]?.custom !== filter?.specialReq?.[0]?.custom
          || guest?.specialReq?.[0]?.value !== filter?.specialReq?.[0]?.value) {
          return false;
        }
      }

      if (filter?.session) {
        if (guest?.session?.[0]?.custom !== filter?.session?.[0]?.custom
          || guest?.session?.[0]?.value !== filter?.session?.[0]?.value) {
          return false;
        }
      }

      if (filter?.mobile) {
        if (filter.mobile === 'assigned' && !guest?.mobile?.no) {
          return false;
        } else if (filter.mobile === 'not_assign' && guest?.mobile?.no) {
          return false;
        }
      }

      if (filter?.email) {
        if (filter.email === 'assigned' && !guest?.email) {
          return false;
        } else if (filter.email === 'not_assign' && guest?.email) {
          return false;
        }
      }

      if (filter?.group) {
        if (filter.group === 'individual' && guest.groupId) {
          return false;
        } else if (filter.group === 'group' && !guest.groupId) {
          return false;
        }
      }

      if (filter?.seating) {
        if (filter.seating === 'assigned' && !guest.seating) {
          return false;
        } else if (filter.seating === 'not_assign' && guest.seating) {
          return false;
        }
      }

      if (!this.functionService.isUndefined(filter?.rsvp)) {
        if (filter.rsvp && !guest?.status?.rsvp) {
          return false;
        } else if (!filter.rsvp && guest?.status?.rsvp) {
          return false;
        }
      }

      if (filter?.giftStatus) {
        if (filter.giftStatus === 'received' && !guest?.giftList?.length) {
          return false;
        } else if (filter.giftStatus === 'not_receive' && guest?.giftList?.length) {
          return false;
        }
      }

      if (filter?.giftReceived) {
        if (guest?.groupId) {
          const group = this.groupService.getGroup(guest?.groupId);
          if (filter.giftReceived === 'received') {
            if (!guest?.status?.gift && !group?.status?.gift) {
              return false;
            }
          } else if (filter.giftReceived === 'not_receive') {
            if (guest?.status?.gift || group?.status?.gift) {
              return false;
            }
          }
        } else {
          if (filter.giftReceived === 'received') {
            if (!guest?.status?.gift) {
              return false;
            }
          } else if (filter.giftReceived === 'not_receive') {
            if (guest?.status?.gift) {
              return false;
            }
          }
        }
      }

      if (filter?.status) {
        if (!this.functionService.isUndefined(filter?.status?.attending) && !filter?.status?.checkin) {
          if (guest?.status?.attending !== filter.status.attending) {
            return false;
          }
        }

        if (!this.functionService.isUndefined(filter?.status?.deleted)) {
          if (guest?.status?.deleted !== filter.status.deleted) {
            return false;
          }
        }

        if (!this.functionService.isUndefined(filter?.status?.checkin)) {
          if (guest?.status?.checkin !== filter.status.checkin) {
            return false;
          }
        }

        if (!this.functionService.isUndefined(filter?.status?.gift)) {
          // let flag = true;
          // if (guest?.status?.gift !== filter.status.gift) {
          //   flag = false;
          // }
          // if (!flag) { return flag; }
          if (guest?.groupId) {
            const group = this.groupService.getGroup(guest?.groupId);
            if (filter.status.gift) {
              if (!guest?.status?.gift && !group?.status?.gift) {
                return false;
              }
            } else {
              if (guest?.status?.gift || group?.status?.gift) {
                return false;
              }
            }
          } else {
            if (filter.status.gift) {
              if (!guest?.status?.gift) {
                return false;
              }
            } else {
              if (guest?.status?.gift) {
                return false;
              }
            }
          }
        }

        if (!this.functionService.isUndefined(filter?.status?.qrcode)) {
          if (filter.status.qrcode === 'sent') {
            if (!guest?.status?.qrcode) {
              return false;
            }
          } else if (filter?.status?.qrcode === 'not_send') {
            if (guest?.status?.qrcode) {
              return false;
            }
          } else if (guest?.status?.qrcode !== filter?.status?.qrcode) {
            return false;
          }
        }
      }

      if (filter?.createBy) {
        if (!guest?.createBy?.uid || guest?.createBy?.uid !== filter?.createBy) {
          return false;
        }
      }

      if (filter?.updateBy) {
        if (!guest?.updateBy?.uid || guest?.updateBy?.uid !== filter?.updateBy) {
          return false;
        }
      }

      if (filter.checkinBy) {
        if (!guest.status.checkin || !guest.checkinBy || !guest.checkinBy.uid || guest.checkinBy.uid !== filter.checkinBy) {
          return false;
        }
      }

      if (filter.deleteBy) {
        if (!guest.deleteBy || !guest.deleteBy.uid || guest.deleteBy.uid !== filter.deleteBy) {
          return false;
        }
      }

      if (filter.giftBy) {
        if (!guest.giftBy || !guest.giftBy.uid || guest.giftBy.uid !== filter.giftBy) {
          return false;
        }
      }

      if (this.moduleService.currentModule === 'gift' && (filter.giftType || filter.currency || filter.amount)) {
        if (guest?.giftList?.length) {
          const giftList: Gift[] = this.giftService.getGiftListById(guest.giftList);
          if (giftList?.length) {
            let falseCount = 0;
            giftList?.forEach((gift: Gift) => {
              let flag = true;
              if (flag && filter.giftType) {
                if (gift.giftType !== filter.giftType) {
                  flag = false;
                  falseCount++;
                }
              }
              if (flag && filter.currency) {
                if (gift.giftType !== 'cash' || gift.currency !== filter.currency) {
                  flag = false;
                  falseCount++;
                }
              }
              if (flag && filter.amount) {
                if (flag && gift.giftType !== 'cash') {
                  flag = false;
                  falseCount++;
                }
                if (flag && filter.amount.lower) {
                  if (gift.amount < filter.amount.lower) {
                    flag = false;
                    falseCount++;
                  }
                }
                if (flag && filter.amount.upper) {
                  if (gift.amount > filter.amount.upper) {
                    flag = false;
                    falseCount++;
                  }
                }
              }
            });
            if (falseCount === giftList.length) {
              return false;
            } else {
              return true;
            }
          }
        }
        return false;
      }
    }
    return true;
  }

  /**
   * Sort guest list
   * @param guestList guest list to be sorted
   * @param field sorting field
   * @param desc descending flag
   */
  sortList(guestList: Guest[], field?: GuestSortType, desc?: boolean, mode?: GuestListMode): Guest[] {
    if (guestList?.length) {
      guestList.sort((a: Guest, b: Guest) => {
        const nameA = a?.group?.groupName ? a.group.groupName.toString() : a?.name ? a.name.toString() : '';
        const nameB = b?.group?.groupName ? b.group.groupName.toString() : b?.name ? b.name.toString() : '';

        if (field) {
          if (field === 'name') {
            if (a[field] !== b[field]) {
              return this.functionService.compare(nameA.toLowerCase(), nameB.toLowerCase(), desc);
            }
          } else if (field === 'checkinTime') {
              let timeA: number = a?.checkinBy?.time?.seconds ? a.checkinBy.time.seconds
              : a?.group?.checkinBy?.time?.seconds ? a.group.checkinBy.time.seconds : 0;
              let timeB: number =  b?.checkinBy?.time?.seconds ? b.checkinBy.time.seconds
              : b?.group?.checkinBy?.time?.seconds ? b.group.checkinBy.time.seconds : 0;

              if (!a?.status?.checkin) { timeA = 0; }
              if (!b?.status?.checkin) { timeB = 0; }

              if (timeA !== timeB) {
                return this.functionService.compare(timeA, timeB, desc);
              }
          } else if (field === 'deletedTime') {
            const timeA: number = a?.deleteBy?.time?.seconds ? a.deleteBy.time.seconds
            : a?.group?.deleteBy?.time?.seconds ? a.group.deleteBy.time.seconds : 0;
            const timeB: number =  b?.deleteBy?.time?.seconds ? b.deleteBy.time.seconds
            : b?.group?.deleteBy?.time?.seconds ? b.group.deleteBy.time.seconds : 0;

            if (timeA !== timeB) {
              return this.functionService.compare(timeA, timeB, desc);
            }
          } else if (field === 'createdTime') {
            const timeA: number = a?.createBy?.time?.seconds ? a.createBy.time.seconds
            : a?.group?.createBy?.time?.seconds ? a.group.createBy.time.seconds : 0;
            const timeB: number =  b?.createBy?.time?.seconds ? b.createBy.time.seconds
            : b?.group?.createBy?.time?.seconds ? b.group.createBy.time.seconds : 0;

            if (timeA !== timeB) {
              return this.functionService.compare(timeA, timeB, desc);
            }
          } else if (field === 'updatedTime') {
            const timeA: number = a?.updateBy?.time?.seconds ? a.updateBy.time.seconds
            : a?.group?.updateBy?.time?.seconds ? a.group.updateBy.time.seconds : 0;
            const timeB: number = b?.updateBy?.time?.seconds ? b.updateBy.time.seconds
            : b?.group?.updateBy?.time?.seconds ? b.group.updateBy.time.seconds : 0;

            if (timeA !== timeB) {
              return this.functionService.compare(timeA, timeB, desc);
            }
          } else if (field === 'rsvpTime') {
            const timeA: number = a?.rsvp?.updateBy?.time?.seconds ? a.rsvp.updateBy.time.seconds
            : a?.group?.rsvp?.updateBy?.time?.seconds ? a.group.rsvp.updateBy.time.seconds : 0;
            const timeB: number = b?.rsvp?.updateBy?.time?.seconds ? b.rsvp.updateBy.time.seconds
            : b?.group?.rsvp?.updateBy?.time?.seconds ? b.group.rsvp.updateBy.time.seconds : 0;

            if (timeA !== timeB) {
              return this.functionService.compare(timeA, timeB, desc);
            }
          }
        } else if (mode === 'invites') {
          let timeA: number = a?.group?.rsvp?.updateBy?.time?.seconds ? a.group.rsvp.updateBy.time.seconds
          : a?.rsvp?.updateBy?.time?.seconds ? a.rsvp.updateBy.time.seconds : 0;
          let timeB: number =  b?.group?.rsvp?.updateBy?.time?.seconds ? b.group.rsvp.updateBy.time.seconds
          : b?.rsvp?.updateBy?.time?.seconds ? b.rsvp.updateBy.time.seconds : 0;
          
          if (timeA !== timeB) {
            return this.functionService.compare(timeA, timeB, true);
          }
        } else {
          return this.functionService.compare(nameA.toLowerCase(), nameB.toLowerCase(), desc);
        }
        return this.functionService.compare(nameA.toLowerCase(), nameB.toLowerCase(), false);
      });
    }
    return guestList;
  }

}

