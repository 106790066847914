import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { AuthService } from '../user/auth.service';
import { Provider } from 'src/app/interfaces/user';
import { MobileService } from '../general/mobile.service';
import { Mobile } from 'src/app/interfaces/general';
import { OnlineService } from '../general/online.service';
import { PopupService } from '../general/popup.service';
import { TranslateService } from '@ngx-translate/core';
import { ErrorService } from '../general/error.service';
import { Group } from 'src/app/interfaces/group';
import { Guest } from 'src/app/interfaces/guest';
import { SettingField } from 'src/app/interfaces/database';
import { WebsiteForm, WebsiteFormFields } from 'src/app/interfaces/website';
import { BehaviorSubject } from 'rxjs';
import { FunctionService } from '../general/function.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AccountEventModeService } from '../account/account-event-mode.service';
import { UpdateByService } from '../user/update-by.service';

@Injectable({
  providedIn: 'root'
})
export class WebsiteFormService {

  accountId: string;

  websiteForm: WebsiteForm;

  websiteFormFields: WebsiteFormFields;

  observable: any;

  constructor(
    private fns: AngularFireFunctions,
    private translate: TranslateService,
    private afs: AngularFirestore,
    private accountEventModeService: AccountEventModeService,
    private authService: AuthService,
    private mobileService: MobileService,
    private functionService: FunctionService,
    private onlineService: OnlineService,
    private popupService: PopupService,
    private errorService: ErrorService,
    private updateByService: UpdateByService,
  ) {
    this.observable = new BehaviorSubject<WebsiteForm>(this.websiteForm);
  }

  setup(websiteForm: WebsiteForm) {
    if (!this.functionService.isEqual(websiteForm, this.websiteForm)) {
      this.websiteForm = websiteForm;
      this.websiteFormFields = this.websiteForm?.fields ? this.websiteForm.fields : {};

      if (!this.websiteForm?.maxGuest) {
        this.websiteForm.maxGuest = this.getMaxGuest();
      }
      this.setupFields();
    }
  }

  setupFields() {
    this.websiteFormFields = this.getFields();
    this.websiteForm.fields = this.websiteFormFields;
    this.observable.next(this.websiteForm);
  }

  getFields() {
    const websiteFormFields: WebsiteFormFields = this.websiteFormFields;

    if (!this.functionService.isUndefined(websiteFormFields) && !this.functionService.isEmpty(websiteFormFields)) {
      if (this.functionService.isUndefined(websiteFormFields?.name)) {
        websiteFormFields.name = this.getQuestion('name');
      }
      if (this.functionService.isUndefined(websiteFormFields?.status)) {
        websiteFormFields.status = this.getQuestion('status');
      }
      if (this.functionService.isUndefined(websiteFormFields?.group)) {
        websiteFormFields.group = this.getQuestion('group');
      }
      if (this.functionService.isUndefined(websiteFormFields?.member)) {
        websiteFormFields.member = this.getQuestion('member');
      }
      if (this.functionService.isUndefined(websiteFormFields?.dietaryReq)) {
        websiteFormFields.dietaryReq = this.getQuestion('dietaryReq');
      }
      if (this.functionService.isUndefined(websiteFormFields?.specialReq)) {
        websiteFormFields.specialReq = this.getQuestion('specialReq');
      }
      if (this.functionService.isUndefined(websiteFormFields?.contact)) {
        websiteFormFields.contact = this.getQuestion('contact');
      }
      if (this.functionService.isUndefined(websiteFormFields?.summary)) {
        websiteFormFields.summary = this.getQuestion('summary');
      }
      

      if (this.functionService.isUndefined(websiteFormFields?.attending)) {
        websiteFormFields.attending = this.getField('attending');
      }
      if (this.functionService.isUndefined(websiteFormFields?.not_attending)) {
        websiteFormFields.not_attending = this.getField('not_attending');
      }
      if (this.functionService.isUndefined(websiteFormFields?.mobile)) {
        websiteFormFields.mobile = this.getField('mobile');
      }
      if (this.functionService.isUndefined(websiteFormFields?.email)) {
        websiteFormFields.email = this.getField('email');
      }
      if (this.functionService.isUndefined(websiteFormFields?.btn)) {
        websiteFormFields.btn = this.getField('btn');
      }
      if (this.functionService.isUndefined(websiteFormFields?.required)) {
        websiteFormFields.required = this.getField('required');
      }
      if (this.functionService.isUndefined(websiteFormFields?.invalid)) {
        websiteFormFields.invalid = this.getField('invalid');
      }

      if (this.functionService.isUndefined(websiteFormFields?.edit)) {
        websiteFormFields.edit = this.getMsg('edit');
      }
      if (this.functionService.isUndefined(websiteFormFields?.success)) {
        websiteFormFields.success = this.getMsg('success');
      }
      if (this.functionService.isUndefined(websiteFormFields?.fail)) {
        websiteFormFields.fail = this.getMsg('fail');
      }
      if (this.functionService.isUndefined(websiteFormFields?.cutOffMsg)) {
        websiteFormFields.cutOffMsg = this.getMsg('cutOffMsg');
      }
      return { ...websiteFormFields };
    } else {
      return {
        name: this.getQuestion('name'),
        status: this.getQuestion('status'),
        group: this.getQuestion('group'),
        member: this.getQuestion('member'),
        dietaryReq: this.getQuestion('dietaryReq'),
        specialReq: this.getQuestion('specialReq'),
        contact: this.getQuestion('contact'),
        summary: this.getQuestion('summary'),
        
        attending: this.getField('attending'),
        not_attending: this.getField('not_attending'),
        mobile: this.getField('mobile'),
        email: this.getField('email'),
        btn: this.getField('btn'),
        required: this.getField('required'),
        invalid: this.getField('invalid'),

        edit: this.getMsg('edit'),
        success: this.getMsg('success'),
        fail: this.getMsg('fail'),
        cutOffMsg: this.getMsg('cutOffMsg'),
      };      
    }
  }

  getWebsiteForm(): WebsiteForm {
    let websiteForm = this.websiteForm;
    if (!websiteForm) {
      websiteForm = {
        enable: true,
        title: this.getTitle(),
        msg: '',
        // btn: this.getButton(),
        edit: true,
        media: { url: '', type: '', mimeType: '' },
        fields: {},
        cutOff: {
            enable: true,
            msg: this.getCutOffMsg(),
            time: -1440,
        },
        maxGuest: this.getMaxGuest(),
        dietaryReq: {
            enable: true,
            others: false,
        },
        specialReq: {
            enable: true,
            others: false,
        },
        email: {
            type: 'optional',
        },
        mobile: {
            type: 'optional',
        },
      };
    }
    
    websiteForm.fields = this.getFields();
    return { ...websiteForm };
  }

  getTitle(text?: boolean) {
    const title = this.translate.instant('WEBSITE.form.title');
    if (text) {
      return title;
    }
    return '<p class="ql-align-center"><strong class="ql-size-large">' + title + '</strong></p>';
  }

  // getMsg() {
  //   return '';
  // }

  getButton() {
    return this.translate.instant('WEBSITE.form.fields.btn');
  }

  getCutOffMsg() {
    return this.translate.instant('WEBSITE.form.cutOff.msg.msg');
  }

  getQuestion(type: string) {
    if (type) {
      const field = 'WEBSITE.form.questions.' + type;
      const translate = this.accountEventModeService.replaceEventType(this.translate.instant(field));
      if (translate && translate !== field) {
        return '<span class="ql-size-large">' + translate + '</span>';
      }
    }
    return '<span class="ql-size-large"> </span>';
  }

  getMsg(type: string) {
    if (type) {
      const field = 'WEBSITE.form.msg.' + type;
      const translate = this.accountEventModeService.replaceEventType(this.translate.instant(field));
      if (translate) {
        if (type === 'required' || type === 'invalid' || type === 'cutOffMsg') {
          return translate;
        } else if (translate !== field) {
          return '<span class="ql-size-large">' + translate + '</span>';
        }
      }
    }
    return '<span class="ql-size-large"> </span>';
  }

  getField(type: string) {
    if (type) {
      const field = 'WEBSITE.form.fields.' + type;
      const translate = this.accountEventModeService.replaceEventType(this.translate.instant(field));
      return translate;
    }
    return '';
  }

  getMaxGuest() {
    return 5;
  }

  async checkAuth(): Promise<Mobile> {
    let mobile: Mobile = {
      code: 0,
      no: '',
    };
    const currentUser = await this.authService.getCurrentUser();
    if (currentUser?.uid && currentUser?.providerData?.length) {
      currentUser.providerData.forEach((provider: Provider) => {
        if (provider?.providerId === 'phone' && provider?.phoneNumber) {
          mobile = this.mobileService.generate(provider.phoneNumber);
          if (mobile?.code && mobile?.no) {
                   
          }
        }
      });
    }
    return mobile;
  }

  async validateWebsiteLink(accountId: string, linkId: string) {
    if (this.onlineService.online) {
      if (accountId && linkId) {
        await this.popupService.presentLoading();
        await this.fns.httpsCallable('validateWebsiteLinkCall')({ accountId, linkId })
        .toPromise().then(async (result: any) => {
          
        }).catch((err: any) => {
          this.errorService.logError(err);
          // this.popupService.presentAlert(this.translate.instant('ACCOUNTS_LOGIN.error'));
        });
        this.popupService.dismissLoading();
      } else {
        // this.popupService.presentAlert(this.translate.instant('ACCOUNTS_LOGIN.error'));
      }
    }
  }

  async createWebsiteGuest(accountId: string, linkId: string, guestList: Guest[], group: Group, newDietaryReq?: SettingField[], newSpecialReq?: SettingField[]) {
    if (this.onlineService.online) {
      if (accountId && guestList?.length) {
        await this.popupService.presentLoading();
        return await this.fns.httpsCallable('createWebsiteGuestCall')({ accountId, linkId, guestList, group, newDietaryReq, newSpecialReq })
        .toPromise().then(async (result: any) => {
          await this.popupService.dismissLoading();
          return result;
        }).catch(async (err: any) => {
          console.log(err);
          this.errorService.logError(err);
          await this.popupService.dismissLoading();
          return null;
          // this.popupService.presentAlert(this.translate.instant('ACCOUNTS_LOGIN.error'));
        });
      } else {
        // this.popupService.presentAlert(this.translate.instant('ACCOUNTS_LOGIN.error'));
      }
    }
    return null;
  }

  async retrieveWebsiteGuest(accountId: string, linkId: string, guestId: string, groupId: string) {
    if (this.onlineService.online) {
      if (accountId && (linkId || guestId || groupId)) {
        await this.popupService.presentLoading();
        await this.fns.httpsCallable('retrieveWebsiteGuestCall')({ accountId, linkId, guestId, groupId })
        .toPromise().then(async (result: any) => {
          
        }).catch((err: any) => {
          this.errorService.logError(err);
          // this.popupService.presentAlert(this.translate.instant('ACCOUNTS_LOGIN.error'));
        });
        this.popupService.dismissLoading();
      } else {
        // this.popupService.presentAlert(this.translate.instant('ACCOUNTS_LOGIN.error'));
      }
    }
  }

  async save(form: WebsiteForm) {
    if (this.accountId && !this.functionService.isEqual(form, this.websiteForm)) {
      form.updateBy = this.updateByService.updateBy;
      const ref = this.afs.firestore.doc(`accounts/${ this.accountId }/accountSetting/website`);
      ref.set({ form }, { merge: true });
    }
  }

  async saveFields(fields: WebsiteFormFields) {
    if (this.accountId && fields && !this.functionService.isEqual(fields, this.websiteForm?.fields)) {
      const updateBy = this.updateByService.updateBy;
      const ref = this.afs.firestore.doc(`accounts/${ this.accountId }/accountSetting/website`);
      ref.set({ form: { fields, updateBy } }, { merge: true });
    }
  }
  
}
