import { Injectable } from '@angular/core';
import { WebsiteAttachment } from 'src/app/interfaces/website';
import { FunctionService } from '../general/function.service';
import { BehaviorSubject } from 'rxjs';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { TranslateService } from '@ngx-translate/core';
import { UpdateByService } from '../user/update-by.service';

@Injectable({
  providedIn: 'root'
})
export class WebsiteDressCodeService {

  accountId: string;

  dressCode: WebsiteAttachment;

  observable: any;

  constructor(
    private afs: AngularFirestore,
    private translate: TranslateService,
    private functionService: FunctionService,
    private updateByService: UpdateByService,
  ) { 
    this.observable = new BehaviorSubject<WebsiteAttachment>(this.dressCode);
  }

  setup(dressCode: WebsiteAttachment) {
    if (!this.functionService.isEqual(this.dressCode, dressCode)) {
      this.dressCode = dressCode;
      this.observable.next(this.dressCode);
    }
  }

  getDressCode(): WebsiteAttachment {
    if (this.dressCode) {
      return { ...this.dressCode };
    } else {
      return {
        title: this.translate.instant('ATTACHMENT.list.dressCode'),
        enable: false,
        msg: '',
        base64: '',
        attachmentUrl: {
          url: '',
          type: '',
        },
        mimeType: '',
        fileName: '',
        attachmentType: 'dressCode',
      };
    }
  }

  async save(dressCode: WebsiteAttachment) {
    if (this.accountId && !this.functionService.isEqual(dressCode, this.dressCode)) {
      dressCode.updateBy = this.updateByService.updateBy;
      const ref = this.afs.firestore.doc(`accounts/${ this.accountId }/accountSetting/website`);
      ref.set({ dressCode }, { merge: true });
    }
  }
}
