import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { WebsiteTime } from 'src/app/interfaces/website';
import { FunctionService } from '../general/function.service';
import { LocaleService } from '../general/locale.service';
import { UpdateByService } from '../user/update-by.service';

@Injectable({
  providedIn: 'root'
})
export class WebsiteTimeService implements OnInit, OnDestroy {

  accountId: string;

  websiteTime: WebsiteTime;

  observable: any;

  constructor(
    private afs: AngularFirestore,
    private functionService: FunctionService,
    private updateByService: UpdateByService,
  ) {
    this.observable = new BehaviorSubject<WebsiteTime>(this.websiteTime);
  }

  ngOnInit(): void {
      
  }

  ngOnDestroy(): void {
      
  }

  setup(websiteTime: WebsiteTime) {
    if (!this.functionService.isEqual(this.websiteTime, websiteTime)) {
      this.websiteTime = websiteTime;
      this.observable.next(this.websiteTime);
    }
  }

  getWebsiteTime(): WebsiteTime {
    if (this.websiteTime) {
      return { ...this.websiteTime };
    } else {
      return {
        design: 1,
        fontSize: 'normal',
        saveCalendar: true,
        countDown: true,
      }
    }
  };

  async save(time: WebsiteTime) {
    if (this.accountId && !this.functionService.isEqual(time, this.websiteTime)) {
      time.updateBy = this.updateByService.updateBy;
      const ref = this.afs.firestore.doc(`accounts/${ this.accountId }/accountSetting/website`);
      ref.set({ time }, { merge: true });
    }
  }
}
