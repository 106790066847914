import { ModuleService } from 'src/app/services/account/privilege/module.service';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IonContent, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { CheckinService } from 'src/app/services/checkin/checkin.service';
import { CheckinSettingService } from 'src/app/services/checkin/checkin-setting.service';
import { GuestService } from 'src/app/services/guest/guest.service';
import { GuestListService } from 'src/app/services/guest/guest-list.service';
import { GuestManageService } from 'src/app/services/guest/guest-manage.service';
import { PopupService } from 'src/app/services/general/popup.service';
import { FunctionService } from 'src/app/services/general/function.service';

import { CheckinSetting } from 'src/app/interfaces/setting';
import { Guest } from 'src/app/interfaces/guest';
import { Group } from 'src/app/interfaces/group';
import { ModuleType } from 'src/app/types/general';
import { GroupService } from 'src/app/services/group/group.service';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { CheckinMethodType } from 'src/app/types/guest';

@Component({
  selector: 'app-checkin-bulk',
  templateUrl: './checkin-bulk.component.html',
  styleUrls: ['./checkin-bulk.component.scss'],
})
export class CheckinBulkComponent implements OnInit, OnDestroy {

  @ViewChild(CdkVirtualScrollViewport) cdkVirtualScrollViewport: CdkVirtualScrollViewport;

  checkinMethod: CheckinMethodType;
  module: ModuleType;
  /**
   * Gift mode
   */
  giftMode: boolean;
  /**
   * Guest count
   */
  count: number;
  /**
   * Select all flag
   */
  selectAll: boolean;
  /**
   * Guest list
   */
  guestList: Guest[];
  /**
   * Guest group list
   */
  groupList: Group[];
  /**
   * Guest individual list
   */
  guestIndividualList: Guest[];
  /**
   * Selected guest list
   */
  selectedGuestList: string[];
  /**
   * Selected group list
   */
  selectedGroupList: string[];
  /**
   * Check-in setting
   */
  checkinSetting: CheckinSetting;

  /**
   * Check-in setting subscription
   */
  private checkinSettingSubscription: Subscription;

  /**
   * Constructor
   * @param modalController Modal controller
   * @param translate Translate service
   * @param guestListService Guest list service
   * @param groupService Guest group service
   * @param guestManageService Guest manage service
   * @param popupService Popup service
   * @param functionService Function service
   */
  constructor(
    private modalController: ModalController,
    private translate: TranslateService,
    private checkinSettingService: CheckinSettingService,
    private guestService: GuestService,
    private guestListService: GuestListService,
    private guestManageService: GuestManageService,
    private groupService: GroupService,
    private checkinService: CheckinService,
    private popupService: PopupService,
    private functionService: FunctionService,
  ) { }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    this.unwatchCheckinSetting();
  }

  /**
   * Genreate individual guest list and select all guest.
   */
  ionViewWillEnter() {
    this.module = 'checkin';
    this.watchCheckinSetting();
    this.setupGuestList();
  }

  /**
   * Before view leave
   */
  ionViewWillLeave() {
    this.unwatchCheckinSetting();
  }

  /**
   * Setup guest list
   */
  setupGuestList() {
    const guestIdList = this.selectedGuestList;
    this.groupList?.forEach((group: Group) => {
      if (group?.memberList?.length) {
        group.memberList?.forEach((guestId: string) => {
          if (guestIdList?.indexOf(guestId) === -1) {
            guestIdList.push(guestId);
          }
        });
      }
    });
    if (guestIdList?.length) {
      const guestList = this.guestService.getGuestListById(guestIdList);
      this.guestList = this.guestListService.generateGroupList(guestList);
      this.setupViewport();
      this.count = guestList.length;
    }
    this.toggleSelectAll();
  }

  setupViewport() {
    if (this.cdkVirtualScrollViewport) {
      setTimeout(() => {
        this.cdkVirtualScrollViewport?.checkViewportSize();
      }, 200);
    } else {
      setTimeout(() => {
        this.setupViewport();
      }, 200);
    }
  }

  /**
   * Watch check-in setting
   */
  async watchCheckinSetting() {
    if (!this.checkinSettingSubscription) {
      this.checkinSettingSubscription = this.checkinSettingService.observableCheckinSetting.subscribe((checkinSetting: CheckinSetting) => {
        this.checkinSetting = checkinSetting;
      });
    }
  }

  /**
   * Unwatch check-in setting
   */
  async unwatchCheckinSetting() {
    if (this.checkinSettingSubscription) {
      this.checkinSettingSubscription.unsubscribe();
      this.checkinSettingSubscription = null;
    }
  }

  /**
   * Dismiss modal
   * @param dismiss Dismiss previous modal flag
   */
  async dismissModal(dismiss?: boolean) {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { await this.modalController.dismiss({ dismiss }); }
    }
  }

  /**
   * Toggle select all
   */
  toggleSelectAll() {
    this.selectAll = !this.selectAll;

    this.selectedGuestList = [];
    this.selectedGroupList = [];

    if (this.selectAll) {
      this.guestList?.forEach((guest: Guest) => {
        if (guest?.group?.groupId) {
          this.setSelectedGroup(guest.group.groupId, true);
        } else if (guest?.guestId) {
          this.selectedGuestList.push(guest.guestId);
        }
      });
    }
  }

  /**
   * Check select all
   */
  checkSelectAll() {
    if (this.count && this.selectedGuestList?.length === this.count) {
      this.selectAll = true;
    } else {
      this.selectAll = false;
    }
  }

  /**
   * Set selected group
   * @param groupId group id
   * @param selected selected flag
   * @param skipCheck skip check
   */
  setSelectedGroup(groupId: string, selected?: boolean, skipCheck?: boolean) {
    const index = this.selectedGroupList?.indexOf(groupId);
    if (index === -1) {
      if (this.functionService.isUndefined(selected) || selected === true) {
        this.selectedGroupList.push(groupId);
        if (!skipCheck) {
          this.checkSelectedGroup(groupId, true, this.functionService.isUndefined(selected) ? false : true);
        }
      }
    } else {
      if (this.functionService.isUndefined(selected) || selected === false) {
        if (this.selectedGroupList?.length) {
          this.selectedGroupList?.splice(index, 1);
        }
        if (!skipCheck) {
          this.checkSelectedGroup(groupId, false, this.functionService.isUndefined(selected) ? false : true);
        }
      }
    }
  }

  /**
   * Check selected group
   * @param groupId group id
   * @param selected selected flag
   * @param skipCheck skip check flag
   */
  checkSelectedGroup(groupId: string, selected?: boolean, skipCheck?: boolean) {
    const index = this.guestList?.findIndex((x) => x?.group?.groupId === groupId);
    let memberList: string[] = [];
    if (index !== -1 && this.guestList?.[index]?.group?.memberList) {
      memberList = this.guestList[index].group.memberList;
    }

    if (memberList?.length) {
      let flag = true;
      memberList?.forEach((guestId: string) => {
        const selectedIndex = this.selectedGuestList?.indexOf(guestId);
        if (selectedIndex === -1) {
          flag = false;
          if (!this.functionService.isUndefined(selected) && selected === true) {
            this.selectedGuestList.push(guestId);
          }
        } else {
          if (!this.functionService.isUndefined(selected) && selected === false) {
            this.selectedGuestList?.splice(selectedIndex, 1);
          }
        }
      });
      if (this.functionService.isUndefined(selected)) {
        this.setSelectedGroup(groupId, flag, true);
      }
    }

    if (!skipCheck) {
      this.checkSelectAll();
    }
  }

  /**
   * Set selected guest
   * @param guestId Guest id
   */
  setSelectedGuest(guestId: string) {
    const index = this.selectedGuestList?.indexOf(guestId);
    if (index === -1) {
      this.selectedGuestList.push(guestId);
    } else {
      this.selectedGuestList?.splice(index, 1);
    }
  }

  /**
   * Unselect guestIdList Guest id list
   */
  unselectAll(guestIdList?: string[]) {
    if (guestIdList) {
      guestIdList?.forEach((guestId: string) => {
        const index = this.selectedGuestList?.indexOf(guestId);
        if (index !== -1) {
          this.selectedGuestList?.splice(index, 1);
        }
      });
    } else {
      if (this.groupList) {
        this.groupList?.forEach((group: Group) => {
          if (group?.memberList) {
            group?.memberList?.forEach((guestId: string) => {
              const index = this.selectedGuestList?.indexOf(guestId);
              if (index !== -1) {
                this.selectedGuestList?.splice(index, 1);
              }
            });
          }
        });
      }
      this.guestIndividualList?.forEach((guest: Guest) => {
        if (guest?.guestId) {
          const index = this.selectedGuestList?.indexOf(guest.guestId);
          if (index !== -1) {
            this.selectedGuestList?.splice(index, 1);
          }
        }
      });
    }
  }

  /**
   * save
   */
  async save() {
    if (this.selectedGuestList?.length) {
      await this.saveCheckin();
      if (this.checkinSetting?.giftRecord) {

        // this.checkinService.receiveGift(true, this.selectedGuestList, [], [], [], true, true, true);
        const guestList = this.guestService.getGuestListById(this.selectedGuestList);
        const groupList = this.groupService.getGroupListById(this.selectedGroupList);
        this.checkinService.receiveGift(this.checkinMethod, true, this.selectedGuestList, this.selectedGroupList, guestList, groupList, true, true, true);
      } else {
        this.dismissModal(true);
      }
    } else {
      this.popupService.presentToast(this.translate.instant('VALIDATION.select_field',
        { field: this.translate.instant('GUEST.lbl.guest') }));
    }
  }

  /**
   * Save checkin
   */
  async saveCheckin() {
    await this.popupService.presentLoading();
    if (this.selectedGuestList?.length) {
      await this.guestManageService.saveGuest({ status: { checkin: true }}, this.selectedGuestList, 'checkin');
    }
    await this.popupService.dismissLoading();
    await this.popupService.dismissModal(true);
    this.popupService.saveSuccessToast();
  }

  /**
   * Calculate item height for virtual scroll
   * @param item Item
   */
  itemHeightFn(item: any) {
    const margin = 10;
    const guestItem = 100;
    const groupItem = 80;

    if (item?.group?.memberList?.length) {
      return (item.group.memberList.length * guestItem) + groupItem + margin;
    }
    return guestItem + margin;
  }

  /**
   * Track item by guest id / group id or index for virtual scroll
   * @param index Index
   * @param item item
   */
  trackByFn(index: number, item: Guest) {
    if (item?.guestId) {
      return item.guestId;
    } else if (item?.group?.groupId) {
      return item.group.groupId;
    }
    return index;
  }

}
