import {
  af, 
  ar, 
//   arDZ, 
//   arEG, 
//   arMA, 
//   arSA, 
//   arTN, 
  az, 
  be, 
//   beTarask, 
  bg, 
  bn, 
  bs, 
  ca, 
  cs, 
  cy, 
  da, 
  de, 
//   deAT, 
  el, 
  enAU, 
  enCA, 
  enGB, 
//   enIE, 
//   enIN, 
//   enNZ, 
  enUS, 
//   enZA, 
//   eo, 
  es, 
  et, 
  eu,
  faIR, 
  fi, 
  fr, 
//   frCA, 
//   frCH, 
  fy, 
  gd, 
  gl, 
  gu, 
  he, 
  hi, 
  hr, 
  ht, 
  hu, 
  hy, 
  id, 
  is, 
  it, 
//   itCH, 
  ja, 
//   jaHira, 
  ka, 
  kk, 
  km, 
//   kn, 
  ko, 
  lb, 
  lt, 
  lv, 
  mk, 
  mn, 
  ms, 
  mt, 
  nb, 
  nl, 
//   nlBE, 
  nn, 
  oc, 
  pl, 
  pt, 
//   ptBR, 
  ro, 
  ru, 
  sk, 
  sl, 
  sq, 
  sr, 
//   srLatn, 
  sv, 
  ta, 
  te, 
  th, 
  tr, 
//   ug, 
  uk, 
  uz, 
//   uzCyrl, 
  vi, 
  zhCN, 
  zhHK, 
  zhTW
} from 'date-fns/locale';
import { LocaleType } from '../interfaces/database';

export const LocaleList: LocaleType[] = [
    {
        "dateFnsLocale": ms,
        "locale": "ms",
        "summary": "Malay",
        "language": "Malay",
        "dateFormat": "dd/MM/yyyy",
        "timeFormat": "HH:mm",
        "is24Hr": true
    },
    {
        "dateFnsLocale": enGB,
        "locale": "en-GB",
        "summary": "English (United Kingdom)",
        "language": "English (United Kingdom)",
        "dateFormat": "dd/MM/yyyy",
        "timeFormat": "HH:mm",
        "is24Hr": true
    },
    {
        "dateFnsLocale": zhCN,
        "locale": "zh-CN",
        "summary": "Chinese Simplified (Mainland China)",
        "language": "Chinese Simplified",
        "dateFormat": "yyyy/MM/dd",
        "timeFormat": "HH:mm",
        "is24Hr": true
    },
    {
        "dateFnsLocale": zhTW,
        "locale": "zh-TW",
        "summary": "Chinese Traditional (Taiwan)",
        "language": "Chinese Traditional",
        "dateFormat": "yyyy/MM/dd",
        "timeFormat": "HH:mm",
        "is24Hr": true
    },
    {
        "dateFnsLocale": af,
        "locale": "af",
        "summary": "Afrikaans",
        "language": "Afrikaans",
        "dateFormat": "yyyy-MM-dd",
        "timeFormat": "HH:mm",
        "is24Hr": true
    },
    {
        "dateFnsLocale": ar,
        "locale": "ar",
        "summary": "Arabic",
        "language": "Modern Standard Arabic",
        "dateFormat": "dd/MM/yyyy",
        "timeFormat": "HH:mm",
        "is24Hr": true
    },
    {
        "dateFnsLocale": az,
        "locale": "az",
        "summary": "Azerbaijani",
        "language": "Azerbaijani",
        "dateFormat": "dd.MM.yyyy",
        "timeFormat": "HH:mm",
        "is24Hr": true
    },
    {
        "dateFnsLocale": be,
        "locale": "be",
        "summary": "Belarusian",
        "language": "Belarusian",
        "dateFormat": "dd.MM.yyyy",
        "timeFormat": "HH:mm",
        "is24Hr": true
    },
    {
        "dateFnsLocale": bg,
        "locale": "bg",
        "summary": "Bulgarian",
        "language": "Bulgarian",
        "dateFormat": "dd.MM.yyyy",
        "timeFormat": "HH:mm",
        "is24Hr": true
    },
    {
        "dateFnsLocale": bn,
        "locale": "bn",
        "summary": "Bengali",
        "language": "Bengali",
        "dateFormat": "dd/MM/yyyy",
        "timeFormat": "HH:mm",
        "is24Hr": true
    },
    {
        "dateFnsLocale": bs,
        "locale": "bs",
        "summary": "Bosnian",
        "language": "Bosnian",
        "dateFormat": "dd.MM.yyyy",
        "timeFormat": "HH:mm",
        "is24Hr": true
    },
    {
        "dateFnsLocale": ca,
        "locale": "ca",
        "summary": "Catalan",
        "language": "Catalan",
        "dateFormat": "dd/MM/yyyy",
        "timeFormat": "HH:mm",
        "is24Hr": true
    },
    {
        "dateFnsLocale": cs,
        "locale": "cs",
        "summary": "Czech",
        "language": "Czech",
        "dateFormat": "dd.MM.yyyy",
        "timeFormat": "HH:mm",
        "is24Hr": true
    },
    {
        "dateFnsLocale": cy,
        "locale": "cy",
        "summary": "Welsh",
        "language": "Welsh",
        "dateFormat": "dd/MM/yyyy",
        "timeFormat": "HH:mm",
        "is24Hr": true
    },
    {
        "dateFnsLocale": da,
        "locale": "da",
        "summary": "Danish",
        "language": "Danish",
        "dateFormat": "dd/MM/yyyy",
        "timeFormat": "HH:mm",
        "is24Hr": true
    },
    {
        "dateFnsLocale": de,
        "locale": "de",
        "summary": "German",
        "language": "German",
        "dateFormat": "dd.MM.yyyy",
        "timeFormat": "HH:mm",
        "is24Hr": true
    },
    {
        "dateFnsLocale": el,
        "locale": "el",
        "summary": "Greek",
        "language": "Greek",
        "dateFormat": "dd/MM/yyyy",
        "timeFormat": "HH:mm",
        "is24Hr": true
    },
    {
        "dateFnsLocale": enAU,
        "locale": "en-AU",
        "summary": "English (Australia)",
        "language": "English",
        "dateFormat": "dd/MM/yyyy",
        "timeFormat": "HH:mm",
        "is24Hr": true
    },
    {
        "dateFnsLocale": enCA,
        "locale": "en-CA",
        "summary": "English (Canada)",
        "language": "English",
        "dateFormat": "yyyy-MM-dd",
        "timeFormat": "HH:mm",
        "is24Hr": true
    },
    {
        "dateFnsLocale": enUS,
        "locale": "en-US",
        "summary": "English (United States)",
        "language": "English (United States)",
        "dateFormat": "MM/dd/yyyy",
        "timeFormat": "hh:mm a",
        "is24Hr": true
    },
    {
        "dateFnsLocale": es,
        "locale": "es",
        "summary": "Spanish",
        "language": "Spanish",
        "dateFormat": "dd/MM/yyyy",
        "timeFormat": "HH:mm",
        "is24Hr": true
    },
    {
        "dateFnsLocale": et,
        "locale": "et",
        "summary": "Estonian",
        "language": "Estonian",
        "dateFormat": "dd.MM.yyyy",
        "timeFormat": "HH:mm",
        "is24Hr": true
    },
    {
        "dateFnsLocale": eu,
        "locale": "eu",
        "summary": "Basque",
        "language": "Basque",
        "dateFormat": "dd/MM/yyyy",
        "timeFormat": "HH:mm",
        "is24Hr": true
    },
    {
        "dateFnsLocale": faIR,
        "locale": "fa-IR",
        "summary": "Persian",
        "language": "Persian",
        "dateFormat": "yyyy/MM/dd",
        "timeFormat": "HH:mm",
        "is24Hr": true
    },
    {
        "dateFnsLocale": fi,
        "locale": "fi",
        "summary": "Finnish",
        "language": "Finnish",
        "dateFormat": "dd.MM.yyyy",
        "timeFormat": "HH:mm",
        "is24Hr": true
    },
    {
        "dateFnsLocale": fr,
        "locale": "fr",
        "summary": "French",
        "language": "French",
        "dateFormat": "dd/MM/yyyy",
        "timeFormat": "HH:mm",
        "is24Hr": true
    },
    {
        "dateFnsLocale": fy,
        "locale": "fy",
        "summary": "Western Frisian (Netherlands)",
        "language": "West Frisian",
        "dateFormat": "dd-MM-yyyy",
        "timeFormat": "HH:mm",
        "is24Hr": true
    },
    {
        "dateFnsLocale": gd,
        "locale": "gd",
        "summary": "Scottish Gaelic",
        "language": "Scottish Gaelic",
        "dateFormat": "dd/MM/yyyy",
        "timeFormat": "HH:mm",
        "is24Hr": true
    },
    {
        "dateFnsLocale": gl,
        "locale": "gl",
        "summary": "Galician",
        "language": "Galician",
        "dateFormat": "dd/MM/yyyy",
        "timeFormat": "HH:mm",
        "is24Hr": true
    },
    {
        "dateFnsLocale": gu,
        "locale": "gu",
        "summary": "Gujarati (India)",
        "language": "Gujarati",
        "dateFormat": "dd-MM-yyyy",
        "timeFormat": "HH:mm",
        "is24Hr": true
    },
    {
        "dateFnsLocale": he,
        "locale": "he",
        "summary": "Hebrew",
        "language": "Hebrew",
        "dateFormat": "dd/MM/yyyy",
        "timeFormat": "HH:mm",
        "is24Hr": true
    },
    {
        "dateFnsLocale": hi,
        "locale": "hi",
        "summary": "Hindi (India)",
        "language": "Hindi",
        "dateFormat": "dd/MM/yyyy",
        "timeFormat": "HH:mm",
        "is24Hr": true
    },
    {
        "dateFnsLocale": hr,
        "locale": "hr",
        "summary": "Croatian",
        "language": "Croatian",
        "dateFormat": "dd.MM.yyyy",
        "timeFormat": "HH:mm",
        "is24Hr": true
    },
    {
        "dateFnsLocale": ht,
        "locale": "ht",
        "summary": "Haitian Creole",
        "language": "Haitian Creole",
        "dateFormat": "dd/MM/yyyy",
        "timeFormat": "HH:mm",
        "is24Hr": true
    },
    {
        "dateFnsLocale": hu,
        "locale": "hu",
        "summary": "Hungarian",
        "language": "Hungarian",
        "dateFormat": "yyyy.MM.dd",
        "timeFormat": "HH:mm",
        "is24Hr": true
    },
    {
        "dateFnsLocale": hy,
        "locale": "hy",
        "summary": "Armenian",
        "language": "Armenian",
        "dateFormat": "dd.MM.yyyy",
        "timeFormat": "HH:mm",
        "is24Hr": true
    },
    {
        "dateFnsLocale": id,
        "locale": "id",
        "summary": "Indonesian",
        "language": "Indonesian",
        "dateFormat": "dd/MM/yyyy",
        "timeFormat": "HH:mm",
        "is24Hr": true
    },
    {
        "dateFnsLocale": is,
        "locale": "is",
        "summary": "Icelandic",
        "language": "Icelandic",
        "dateFormat": "dd.MM.yyyy",
        "timeFormat": "HH:mm",
        "is24Hr": true
    },
    {
        "dateFnsLocale": it,
        "locale": "it",
        "summary": "Italian",
        "language": "Italian",
        "dateFormat": "dd/MM/yyyy",
        "timeFormat": "HH:mm",
        "is24Hr": true
    },
    {
        "dateFnsLocale": ja,
        "locale": "ja",
        "summary": "Japanese",
        "language": "Japanese",
        "dateFormat": "yyyy/MM/dd",
        "timeFormat": "HH:mm",
        "is24Hr": true
    },
    {
        "dateFnsLocale": ka,
        "locale": "ka",
        "summary": "Georgian",
        "language": "Georgian",
        "dateFormat": "dd.MM.yyyy",
        "timeFormat": "HH:mm",
        "is24Hr": true
    },
    {
        "dateFnsLocale": kk,
        "locale": "kk",
        "summary": "Kazakh",
        "language": "Kazakh",
        "dateFormat": "dd.MM.yyyy",
        "timeFormat": "HH:mm",
        "is24Hr": true
    },
    {
        "dateFnsLocale": km,
        "locale": "km",
        "summary": "Khmer (Cambodian)",
        "language": "Khmer",
        "dateFormat": "dd/MM/yyyy",
        "timeFormat": "HH:mm",
        "is24Hr": true
    },
    {
        "dateFnsLocale": ko,
        "locale": "ko",
        "summary": "Korean",
        "language": "Korean",
        "dateFormat": "yyyy.MM.dd",
        "timeFormat": "HH:mm",
        "is24Hr": true
    },
    {
        "dateFnsLocale": lb,
        "locale": "lb",
        "summary": "Luxembourgish",
        "language": "Luxembourgish",
        "dateFormat": "dd/MM/yyyy",
        "timeFormat": "HH:mm",
        "is24Hr": true
    },
    {
        "dateFnsLocale": lt,
        "locale": "lt",
        "summary": "Lithuanian",
        "language": "Lithuanian",
        "dateFormat": "yyyy.MM.dd",
        "timeFormat": "HH:mm",
        "is24Hr": true
    },
    {
        "dateFnsLocale": lv,
        "locale": "lv",
        "summary": "Latvian (Latvia)",
        "language": "Latvian",
        "dateFormat": "dd.MM.yyyy",
        "timeFormat": "HH:mm",
        "is24Hr": true
    },
    {
        "dateFnsLocale": mk,
        "locale": "mk",
        "summary": "Macedonian",
        "language": "Macedonian",
        "dateFormat": "dd.MM.yyyy",
        "timeFormat": "HH:mm",
        "is24Hr": true
    },
    {
        "dateFnsLocale": mn,
        "locale": "mn",
        "summary": "Mongolian",
        "language": "Mongolian",
        "dateFormat": "yyyy.MM.dd",
        "timeFormat": "HH:mm",
        "is24Hr": true
    },
    {
        "dateFnsLocale": mt,
        "locale": "mt",
        "summary": "Maltese",
        "language": "Maltese",
        "dateFormat": "dd/MM/yyyy",
        "timeFormat": "HH:mm",
        "is24Hr": true
    },
    {
        "dateFnsLocale": nb,
        "locale": "nb",
        "summary": "Norwegian Bokmål",
        "language": "Norwegian Bokmål",
        "dateFormat": "dd.MM.yyyy",
        "timeFormat": "HH:mm",
        "is24Hr": true
    },
    {
        "dateFnsLocale": nl,
        "locale": "nl",
        "summary": "Dutch",
        "language": "Dutch",
        "dateFormat": "dd-MM-yyyy",
        "timeFormat": "HH:mm",
        "is24Hr": true
    },
    {
        "dateFnsLocale": nn,
        "locale": "nn",
        "summary": "Norwegian Nynorsk",
        "language": "Norwegian Nynorsk",
        "dateFormat": "dd.MM.yyyy",
        "timeFormat": "HH:mm",
        "is24Hr": true
    },
    {
        "dateFnsLocale": oc,
        "locale": "oc",
        "summary": "Occitan",
        "language": "Occitan",
        "dateFormat": "dd/MM/yyyy",
        "timeFormat": "HH:mm",
        "is24Hr": true
    },
    {
        "dateFnsLocale": pl,
        "locale": "pl",
        "summary": "Polish",
        "language": "Polish",
        "dateFormat": "dd.MM.yyyy",
        "timeFormat": "HH:mm",
        "is24Hr": true
    },
    {
        "dateFnsLocale": pt,
        "locale": "pt",
        "summary": "Portuguese",
        "language": "Portuguese",
        "dateFormat": "dd/MM/yyyy",
        "timeFormat": "HH:mm",
        "is24Hr": true
    },
    {
        "dateFnsLocale": ro,
        "locale": "ro",
        "summary": "Romanian",
        "language": "Romanian",
        "dateFormat": "dd.MM.yyyy",
        "timeFormat": "HH:mm",
        "is24Hr": true
    },
    {
        "dateFnsLocale": ru,
        "locale": "ru",
        "summary": "Russian",
        "language": "Russian",
        "dateFormat": "dd.MM.yyyy",
        "timeFormat": "HH:mm",
        "is24Hr": true
    },
    {
        "dateFnsLocale": sk,
        "locale": "sk",
        "summary": "Slovak",
        "language": "Slovak",
        "dateFormat": "dd.MM.yyyy",
        "timeFormat": "HH:mm",
        "is24Hr": true
    },
    {
        "dateFnsLocale": sl,
        "locale": "sl",
        "summary": "Slovenian",
        "language": "Slovenian",
        "dateFormat": "dd.MM.yyyy",
        "timeFormat": "HH:mm",
        "is24Hr": true
    },
    {
        "dateFnsLocale": sq,
        "locale": "sq",
        "summary": "Albanian",
        "language": "Shqip",
        "dateFormat": "dd/MM/yyyy",
        "timeFormat": "HH:mm",
        "is24Hr": true
    },
    {
        "dateFnsLocale": sr,
        "locale": "sr",
        "summary": "Serbian cyrillic",
        "language": "Serbian",
        "dateFormat": "dd.MM.yyyy",
        "timeFormat": "HH:mm",
        "is24Hr": true
    },
    {
        "dateFnsLocale": sv,
        "locale": "sv",
        "summary": "Swedish",
        "language": "Swedish",
        "dateFormat": "yyyy-MM-dd",
        "timeFormat": "HH:mm",
        "is24Hr": true
    },
    {
        "dateFnsLocale": ta,
        "locale": "ta",
        "summary": "Tamil (India)",
        "language": "Tamil",
        "dateFormat": "dd/MM/yyyy",
        "timeFormat": "HH:mm",
        "is24Hr": true
    },
    {
        "dateFnsLocale": te,
        "locale": "te",
        "summary": "Telugu",
        "language": "Telugu",
        "dateFormat": "dd/MM/yyyy",
        "timeFormat": "HH:mm",
        "is24Hr": true
    },
    {
        "dateFnsLocale": th,
        "locale": "th",
        "summary": "Thai",
        "language": "Thai",
        "dateFormat": "dd/MM/yyyy",
        "timeFormat": "HH:mm",
        "is24Hr": true
    },
    {
        "dateFnsLocale": tr,
        "locale": "tr",
        "summary": "Turkish",
        "language": "Turkish",
        "dateFormat": "dd.MM.yyyy",
        "timeFormat": "HH:mm",
        "is24Hr": true
    },
    {
        "dateFnsLocale": uk,
        "locale": "uk",
        "summary": "Ukrainian",
        "language": "Ukrainian",
        "dateFormat": "dd.MM.yyyy",
        "timeFormat": "HH:mm",
        "is24Hr": true
    },
    {
        "dateFnsLocale": uz,
        "locale": "uz",
        "summary": "Uzbek",
        "language": "Uzbek",
        "dateFormat": "dd.MM.yyyy",
        "timeFormat": "HH:mm",
        "is24Hr": true
    },
    {
        "dateFnsLocale": vi,
        "locale": "vi",
        "summary": "Vietnamese (Vietnam)",
        "language": "Vietnamese",
        "dateFormat": "dd/MM/yyyy",
        "timeFormat": "HH:mm",
        "is24Hr": true
    },
    {
        "dateFnsLocale": zhHK,
        "locale": "zh-HK",
        "summary": "Chinese Traditional (Hong Kong)",
        "language": "Chinese Traditional",
        "dateFormat": "dd/MM/yyyy",
        "timeFormat": "HH:mm",
        "is24Hr": true
    }
];

export const LocaleToSubset = {
    // Arabic script
    'ar-SA': 'arabic',
    'fa-IR': 'arabic',
    'ur-PK': 'arabic',
    'ps-AF': 'arabic',
  
    // Cyrillic script
    'ru-RU': 'cyrillic',
    'uk-UA': 'cyrillic',
    'bg-BG': 'cyrillic',
    'sr-RS': 'cyrillic',
  
    // Greek script
    'el-GR': 'greek',
  
    // Hebrew script
    'he-IL': 'hebrew',
  
    // Latin script
    'en-US': 'latin',
    'en-GB': 'latin',
    'fr-FR': 'latin',
    'de-DE': 'latin',
    'es-ES': 'latin',
    'it-IT': 'latin',
    'pt-PT': 'latin',
    'pt-BR': 'latin',
    'nl-NL': 'latin',
    'sv-SE': 'latin',
    'da-DK': 'latin',
    'fi-FI': 'latin',
    'no-NO': 'latin',
    'pl-PL': 'latin',
    'cs-CZ': 'latin',
    'hu-HU': 'latin',
    'ro-RO': 'latin',
    'sk-SK': 'latin',
    'sl-SI': 'latin',
    'hr-HR': 'latin',
    'lt-LT': 'latin',
    'lv-LV': 'latin',
    'et-EE': 'latin',
  
    // Latin extended script
    'vi-VN': 'vietnamese',
  
    // Chinese scripts
    'zh-CN': 'chinese-simplified',
    'zh-TW': 'chinese-traditional',
    'zh-HK': 'chinese-traditional',
  
    // Japanese script
    'ja-JP': 'japanese',
  
    // Korean script
    'ko-KR': 'korean',
  
    // Thai script
    'th-TH': 'thai',
  
    // Additional scripts
    'hi-IN': 'latin',  // Hindi primarily uses Devanagari script but Google Fonts might not have a specific subset
    'bn-BD': 'latin', // Bengali primarily uses Bengali script but might use latin in Google Fonts
    'ta-IN': 'latin', // Tamil primarily uses Tamil script but might use latin in Google Fonts
    'te-IN': 'latin', // Telugu primarily uses Telugu script but might use latin in Google Fonts
    'ml-IN': 'latin', // Malayalam primarily uses Malayalam script but might use latin in Google Fonts
    'kn-IN': 'latin', // Kannada primarily uses Kannada script but might use latin in Google Fonts
    'gu-IN': 'latin', // Gujarati primarily uses Gujarati script but might use latin in Google Fonts
  };

export const DateFormatList: string[] = [
    'PPPP',
    'PPP',
    'PP',
    'P',
    'do MMM yyyy, EEEE',
    'do MMM yyyy, EEE',
    'do MMM yyyy',
    'MMM do yyyy, EEEE',
    'MMM do yyyy, EEE',
    'MMM do yyyy',
    'yyyy MMM do, EEEE',
    'yyyy MMM do, EEE',
    'yyyy MMM do',
];

export const TimeFormatList: string[] = [
    'pppp',
    'ppp',
    'pp',
    'p',
    'h:mm a',
    'HH:mm',
    'h:mm a z',
    'HH:mm z',
    'z h:mm a',
    'z HH:mm',
];

export const LocaleDateFormatList = {
    "af": "yyyy-MM-dd",
    "ar": "dd/MM/yyyy",
    "az": "dd.MM.yyyy",
    "be": "dd.MM.yyyy",
    "bg": "dd.MM.yyyy",
    "bn": "dd/MM/yyyy",
    "bs": "dd.MM.yyyy",
    "ca": "dd/MM/yyyy",
    "cs": "dd.MM.yyyy",
    "cy": "dd/MM/yyyy",
    "da": "dd/MM/yyyy",
    "de": "dd.MM.yyyy",
    "el": "dd/MM/yyyy",
    "enAU": "dd/MM/yyyy",
    "enCA": "yyyy-MM-dd",
    "enGB": "dd/MM/yyyy",
    "enUS": "MM/dd/yyyy",
    "es": "dd/MM/yyyy",
    "et": "dd.MM.yyyy",
    "eu": "dd/MM/yyyy",
    "faIR": "yyyy/MM/dd",
    "fi": "dd.MM.yyyy",
    "fr": "dd/MM/yyyy",
    "fy": "dd-MM-yyyy",
    "gd": "dd/MM/yyyy",
    "gl": "dd/MM/yyyy",
    "gu": "dd-MM-yyyy",
    "he": "dd/MM/yyyy",
    "hi": "dd/MM/yyyy",
    "hr": "dd.MM.yyyy",
    "ht": "dd/MM/yyyy",
    "hu": "yyyy.MM.dd",
    "hy": "dd.MM.yyyy",
    "id": "dd/MM/yyyy",
    "is": "dd.MM.yyyy",
    "it": "dd/MM/yyyy",
    "ja": "yyyy/MM/dd",
    "ka": "dd.MM.yyyy",
    "kk": "dd.MM.yyyy",
    "km": "dd/MM/yyyy",
    "ko": "yyyy.MM.dd",
    "lb": "dd/MM/yyyy",
    "lt": "yyyy.MM.dd",
    "lv": "dd.MM.yyyy",
    "mk": "dd.MM.yyyy",
    "mn": "yyyy.MM.dd",
    "ms": "dd/MM/yyyy",
    "mt": "dd/MM/yyyy",
    "nb": "dd.MM.yyyy",
    "nl": "dd-MM-yyyy",
    "nn": "dd.MM.yyyy",
    "oc": "dd/MM/yyyy",
    "pl": "dd.MM.yyyy",
    "pt": "dd/MM/yyyy",
    "ro": "dd.MM.yyyy",
    "ru": "dd.MM.yyyy",
    "sk": "dd.MM.yyyy",
    "sl": "dd.MM.yyyy",
    "sq": "dd/MM/yyyy",
    "sr": "dd.MM.yyyy",
    "sv": "yyyy-MM-dd",
    "ta": "dd/MM/yyyy",
    "te": "dd/MM/yyyy",
    "th": "dd/MM/yyyy",
    "tr": "dd.MM.yyyy",
    "uk": "dd.MM.yyyy",
    "uz": "dd.MM.yyyy",
    "vi": "dd/MM/yyyy",
    "zhCN": "yyyy/MM/dd",
    "zhHK": "dd/MM/yyyy",
    "zhTW": "yyyy/MM/dd"
};

export const LocaleTimeFormatList = {
    "af": "HH:mm",
    "ar": "HH:mm",
    "az": "HH:mm",
    "be": "HH:mm",
    "bg": "HH:mm",
    "bn": "HH:mm",
    "bs": "HH:mm",
    "ca": "HH:mm",
    "cs": "HH:mm",
    "cy": "HH:mm",
    "da": "HH:mm",
    "de": "HH:mm",
    "el": "HH:mm",
    "enAU": "HH:mm",
    "enCA": "HH:mm",
    "enGB": "HH:mm",
    "enUS": "hh:mm a",
    "es": "HH:mm",
    "et": "HH:mm",
    "eu": "HH:mm",
    "faIR": "HH:mm",
    "fi": "HH:mm",
    "fr": "HH:mm",
    "fy": "HH:mm",
    "gd": "HH:mm",
    "gl": "HH:mm",
    "gu": "HH:mm",
    "he": "HH:mm",
    "hi": "HH:mm",
    "hr": "HH:mm",
    "ht": "HH:mm",
    "hu": "HH:mm",
    "hy": "HH:mm",
    "id": "HH:mm",
    "is": "HH:mm",
    "it": "HH:mm",
    "ja": "HH:mm",
    "ka": "HH:mm",
    "kk": "HH:mm",
    "km": "HH:mm",
    "ko": "HH:mm",
    "lb": "HH:mm",
    "lt": "HH:mm",
    "lv": "HH:mm",
    "mk": "HH:mm",
    "mn": "HH:mm",
    "ms": "HH:mm",
    "mt": "HH:mm",
    "nb": "HH:mm",
    "nl": "HH:mm",
    "nn": "HH:mm",
    "oc": "HH:mm",
    "pl": "HH:mm",
    "pt": "HH:mm",
    "ro": "HH:mm",
    "ru": "HH:mm",
    "sk": "HH:mm",
    "sl": "HH:mm",
    "sq": "HH:mm",
    "sr": "HH:mm",
    "sv": "HH:mm",
    "ta": "HH:mm",
    "te": "HH:mm",
    "th": "HH:mm",
    "tr": "HH:mm",
    "uk": "HH:mm",
    "uz": "HH:mm",
    "vi": "HH:mm",
    "zhCN": "HH:mm",
    "zhHK": "HH:mm",
    "zhTW": "HH:mm"
};

// export const LocaleList: LocaleType[] = [
//     {
//         dateFnsLocale: af,
//         locale: 'af',
//         summary: "Afrikaans",
//         language: "Afrikaans"
//     },
//     {
//         dateFnsLocale: ar,
//         locale: 'ar',
//         // summary: "Arabic (Modern Standard Arabic - Al-fusha)",
//         summary: "Arabic",
//         language: "Modern Standard Arabic"
//     },
//     // {
//     //     dateFnsLocale: arDZ,
//     //     locale: 'ar-DZ',
//     //     summary: "Arabic (Algerian Arabic)",
//     //     language: "Algerian Arabic"
//     // },
//     // {
//     //     dateFnsLocale: arEG,
//     //     locale: 'ar-EG',
//     //     summary: "Arabic (Egypt)",
//     //     language: "Arabic"
//     //     },
//     // {
//     //     dateFnsLocale: arMA,
//     //     locale: 'ar-MA',
//     //     summary: "Arabic (Moroccan Arabic)",
//     //     language: "Moroccan Arabic"
//     // },
//     // {
//     //     dateFnsLocale: arSA,
//     //     locale: 'ar-SA',
//     //     summary: "Arabic (Saudi Arabic)",
//     //     language: "Arabic"
//     // },
//     // {
//     //     dateFnsLocale: arTN,
//     //     locale: 'ar-TN',
//     //     summary: "Arabic (Tunisian Arabic)",
//     //     language: "Arabic"
//     // },
//     {
//         dateFnsLocale: az,
//         locale: 'az',
//         summary: "Azerbaijani",
//         language: "Azerbaijani"
//     },
//     {
//         dateFnsLocale: be,
//         locale: 'be',
//         summary: "Belarusian",
//         language: "Belarusian"
//     },
//     {
//         dateFnsLocale: bg,
//         locale: 'bg',
//         summary: "Bulgarian",
//         language: "Bulgarian"
//     },
//     {
//         dateFnsLocale: bn,
//         locale: 'bn',
//         summary: "Bengali",
//         language: "Bengali"
//     },
//     {
//         dateFnsLocale: bs,
//         locale: 'bs',
//         summary: "Bosnian",
//         language: "Bosnian"
//     },
//     {
//         dateFnsLocale: ca,
//         locale: 'ca',
//         summary: "Catalan",
//         language: "Catalan"
//     },
//     {
//         dateFnsLocale: cs,
//         locale: 'cs',
//         summary: "Czech",
//         language: "Czech"
//     },
//     {
//         dateFnsLocale: cy,
//         locale: 'cy',
//         summary: "Welsh",
//         language: "Welsh"
//     },
//     {
//         dateFnsLocale: da,
//         locale: 'da',
//         summary: "Danish",
//         language: "Danish"
//     },
//     {
//         dateFnsLocale: de,
//         locale: 'de',
//         summary: "German",
//         language: "German"
//     },
//     // {
//     //     dateFnsLocale: deAT,
//     //     locale: 'de-AT',
//     //     summary: "German (Austria)",
//     //     language: "German"
//     // },
//     {
//         dateFnsLocale: el,
//         locale: 'el',
//         summary: "Greek",
//         language: "Greek"
//     },
//     // {
//     //     dateFnsLocale: enAU,
//     //     locale: 'en-AU',
//     //     summary: "English (Australia)",
//     //     language: "English"
//     // },
//     // {
//     //     dateFnsLocale: enCA,
//     //     locale: 'en-CA',
//     //     summary: "English (Canada)",
//     //     language: "English"
//     // },
//     {
//         dateFnsLocale: enGB,
//         locale: 'en-GB',
//         summary: "English (United Kingdom)",
//         language: "English (United Kingdom)"
//     },
//     // {
//     //     dateFnsLocale: enIE,
//     //     locale: 'en-IE',
//     //     summary: "English (Ireland)",
//     //     language: "English"
//     // },
//     // {
//     //     dateFnsLocale: enIN,
//     //     locale: 'en-IN',
//     //     summary: "English (India)",
//     //     language: "English"
//     // },
//     // {
//     //     dateFnsLocale: enNZ,
//     //     locale: 'en-NZ',
//     //     summary: "English (New Zealand)",
//     //     language: "English"
//     // },
//     {
//         dateFnsLocale: enUS,
//         locale: 'en-US',
//         summary: "English (United States)",
//         language: "English (United States)"
//     },
//     // {
//     //     dateFnsLocale: enZA,
//     //     locale: 'en-ZA',
//     //     summary: "English (South Africa)",
//     //     language: "English"
//     // },
//     // {
//     //     dateFnsLocale: eo,
//     //     locale: 'en-EO',
//     //     summary: "Esperanto",
//     //     language: "Esperanto"
//     // },
//     {
//         dateFnsLocale: es,
//         locale: 'es',
//         summary: "Spanish",
//         language: "Spanish"
//     },
//     {
//         dateFnsLocale: et,
//         locale: 'et',
//         summary: "Estonian",
//         language: "Estonian"
//     },
//     {
//         dateFnsLocale: eu,
//         locale: 'eu',
//         summary: "Basque",
//         language: "Basque"
//     },
//     {
//         dateFnsLocale: faIR,
//         locale: 'fa-IR',
//         summary: "Persian",
//         language: "Persian"
//     },
//     {
//         dateFnsLocale: fi,
//         locale: 'fi',
//         summary: "Finnish",
//         language: "Finnish"
//     },
//     {
//         dateFnsLocale: fr,
//         locale: 'fr',
//         summary: "French",
//         language: "French"
//     },
//     // {
//     //     dateFnsLocale: frCA,
//     //     locale: 'fr-CA',
//     //     summary: "French (Canada)",
//     //     language: "French"
//     // },
//     // {
//     //     dateFnsLocale: frCH,
//     //     locale: 'fr-CH',
//     //     summary: "French (Switzerland)",
//     //     language: "French"
//     // },
//     {
//         dateFnsLocale: fy,
//         locale: 'fy',
//         summary: "Western Frisian (Netherlands)",
//         language: "West Frisian"
//     },
//     {
//         dateFnsLocale: gd,
//         locale: 'gd',
//         summary: "Scottish Gaelic",
//         language: "Scottish Gaelic"
//     },
//     {
//         dateFnsLocale: gl,
//         locale: 'gl',
//         summary: "Galician",
//         language: "Galician"
//     },
//     {
//         dateFnsLocale: gu,
//         locale: 'gu',
//         summary: "Gujarati (India)",
//         language: "Gujarati"
//     },
//     {
//         dateFnsLocale: he,
//         locale: 'he',
//         summary: "Hebrew",
//         language: "Hebrew"
//     },
//     {
//         dateFnsLocale: hi,
//         locale: 'hi',
//         summary: "Hindi (India)",
//         language: "Hindi"
//     },
//     {
//         dateFnsLocale: hr,
//         locale: 'hr',
//         summary: "Croatian",
//         language: "Croatian"
//     },
//     {
//         dateFnsLocale: ht,
//         locale: 'ht',
//         summary: "Haitian Creole",
//         language: "Haitian Creole"
//     },
//     {
//         dateFnsLocale: hu,
//         locale: 'hu',
//         summary: "Hungarian",
//         language: "Hungarian"
//     },
//     {
//         dateFnsLocale: hy,
//         locale: 'hy',
//         summary: "Armenian",
//         language: "Armenian"
//     },
//     {
//         dateFnsLocale: id,
//         locale: 'id',
//         summary: "Indonesian",
//         language: "Indonesian"
//     },
//     {
//         dateFnsLocale: is,
//         locale: 'is',
//         summary: "Icelandic",
//         language: "Icelandic"
//     },
//     {
//         dateFnsLocale: it,
//         locale: 'it',
//         summary: "Italian",
//         language: "Italian"
//     },
//     // {
//     //     dateFnsLocale: itCH,
//     //     locale: 'it-CH',
//     //     summary: "Italian (Switzerland)",
//     //     language: "Italian"
//     // },
//     {
//         dateFnsLocale: ja,
//         locale: 'ja',
//         summary: "Japanese",
//         language: "Japanese"
//     },
//     // {
//     //     dateFnsLocale: jaHira,
//     //     locale: 'jaHira',
//     //     summary: "Japanese (Hiragana)",
//     //     language: "Japanese (Hiragana)"
//     // },
//     {
//         dateFnsLocale: ka,
//         locale: 'ka',
//         summary: "Georgian",
//         language: "Georgian"
//     },
//     {
//         dateFnsLocale: kk,
//         locale: 'kk',
//         summary: "Kazakh",
//         language: "Kazakh"
//     },
//     {
//         dateFnsLocale: km,
//         locale: 'km',
//         summary: "Khmer (Cambodian)",
//         language: "Khmer"
//     },
//     // {
//     //     dateFnsLocale: kn,
//     //     locale: 'kn',
//     //     summary: "Kannada (India)",
//     //     language: "Kannada"
//     // },
//     {
//         dateFnsLocale: ko,
//         locale: 'ko',
//         summary: "Korean",
//         language: "Korean"
//     },
//     {
//         dateFnsLocale: lb,
//         locale: 'lb',
//         summary: "Luxembourgish",
//         language: "Luxembourgish"
//     },
//     {
//         dateFnsLocale: lt,
//         locale: 'lt',
//         summary: "Lithuanian",
//         language: "Lithuanian"
//     },
//     {
//         dateFnsLocale: lv,
//         locale: 'lv',
//         summary: "Latvian (Latvia)",
//         language: "Latvian"
//     },
//     {
//         dateFnsLocale: mk,
//         locale: 'mk',
//         summary: "Macedonian",
//         language: "Macedonian"
//     },
//     {
//         dateFnsLocale: mn,
//         locale: 'mn',
//         summary: "Mongolian",
//         language: "Mongolian"
//     },
//     {
//         dateFnsLocale: ms,
//         locale: 'ms',
//         summary: "Malay",
//         language: "Malay"
//     },
//     {
//         dateFnsLocale: mt,
//         locale: 'mt',
//         summary: "Maltese",
//         language: "Maltese"
//     },
//     {
//         dateFnsLocale: nb,
//         locale: 'nb',
//         summary: "Norwegian Bokmål",
//         language: "Norwegian Bokmål"
//     },
//     {
//         dateFnsLocale: nl,
//         locale: 'nl',
//         summary: "Dutch",
//         language: "Dutch"
//     },
//     // {
//     //     dateFnsLocale: nlBE,
//     //     locale: 'nl-BE',
//     //     summary: "Dutch (Belgium)",
//     //     language: "Dutch (Belgium)"
//     // },
//     {
//         dateFnsLocale: nn,
//         locale: 'nn',
//         summary: "Norwegian Nynorsk",
//         language: "Norwegian Nynorsk"
//     },
//     {
//         dateFnsLocale: oc,
//         locale: 'oc',
//         summary: "Occitan",
//         language: "Occitan"
//     },
//     {
//         dateFnsLocale: pl,
//         locale: 'pl',
//         summary: "Polish",
//         language: "Polish"
//     },
//     {
//         dateFnsLocale: pt,
//         locale: 'pt',
//         summary: "Portuguese",
//         language: "Portuguese"
//     },
//     // {
//     //     dateFnsLocale: ptBR,
//     //     locale: 'pt-BR',
//     //     summary: "Portuguese (Brazil)",
//     //     language: "Portuguese"
//     // },
//     {
//         dateFnsLocale: ro,
//         locale: 'ro',
//         summary: "Romanian",
//         language: "Romanian"
//     },
//     {
//         dateFnsLocale: ru,
//         locale: 'ru',
//         summary: "Russian",
//         language: "Russian"
//     },
//     {
//         dateFnsLocale: sk,
//         locale: 'sk',
//         summary: "Slovak",
//         language: "Slovak"
//     },
//     {
//         dateFnsLocale: sl,
//         locale: 'sl',
//         summary: "Slovenian",
//         language: "Slovenian"
//     },
//     {
//         dateFnsLocale: sq,
//         locale: 'sq',
//         summary: "Albanian",
//         language: "Shqip"
//     },
//     {
//         dateFnsLocale: sr,
//         locale: 'sr',
//         summary: "Serbian cyrillic",
//         language: "Serbian"
//     },
//     // {
//     //     dateFnsLocale: srLatn,
//     //     locale: 'sr-Latn',
//     //     summary: "Serbian latin",
//     //     language: "Serbian"
//     // },
//     {
//         dateFnsLocale: sv,
//         locale: 'sv',
//         summary: "Swedish",
//         language: "Swedish"
//     },
//     {
//         dateFnsLocale: ta,
//         locale: 'ta',
//         summary: "Tamil (India)",
//         language: "Tamil"
//     },
//     {
//         dateFnsLocale: te,
//         locale: 'te',
//         summary: "Telugu",
//         language: "Telugu"
//     },
//     {
//         dateFnsLocale: th,
//         locale: 'th',
//         summary: "Thai",
//         language: "Thai"
//     },
//     {
//         dateFnsLocale: tr,
//         locale: 'tr',
//         summary: "Turkish",
//         language: "Turkish"
//     },
//     // {
//     //     dateFnsLocale: ug,
//     //     locale: 'ug',
//     //     summary: "Uighur",
//     //     language: "Uighur"
//     // },
//     {
//         dateFnsLocale: uk,
//         locale: 'uk',
//         summary: "Ukrainian",
//         language: "Ukrainian"
//     },
//     {
//         dateFnsLocale: uz,
//         locale: 'uz',
//         summary: "Uzbek",
//         language: "Uzbek"
//     },
//     // {
//     //     dateFnsLocale: uzCyrl,
//     //     locale: 'uz-Cyrl',
//     //     summary: "Uzbek Cyrillic",
//     //     language: "Uzbek"
//     // },
//     {
//         dateFnsLocale: vi,
//         locale: 'vi',
//         summary: "Vietnamese (Vietnam)",
//         language: "Vietnamese"
//     },
//     {
//         dateFnsLocale: zhCN,
//         locale: 'zh-CN',
//         summary: "Chinese Simplified (Mainland China)",
//         language: "Chinese Simplified"
//     },
//     {
//         dateFnsLocale: zhHK,
//         locale: 'zh-HK',
//         summary: "Chinese Traditional (Hong Kong)",
//         language: "Chinese Traditional"
//     },
//     {
//         dateFnsLocale: zhTW,
//         locale: 'zh-TW',
//         summary: "Chinese Traditional (Taiwan)",
//         language: "Chinese Traditional"
//     }
// ];
