import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, Subscription, distinctUntilChanged, map } from 'rxjs';

import { ModuleService } from 'src/app/services/account/privilege/module.service';
import { Range } from 'src/app/interfaces/general';
import { Gift } from 'src/app/interfaces/gift';
import { ModuleType } from 'src/app/types/general';

/**
 * Gift service.
 * Watch / get gift data.
 */
@Injectable({
  providedIn: 'root'
})
export class GiftService implements OnInit, OnDestroy {

  /**
   * Gift list
   */
  giftList: Gift[];
  trashGiftList: Gift[];
  /**
   * Currency code list of unique saved currency.
   */
  currencyList: string[];
  /**
   * Minimum / maximum saved cash amount
   */
  amount: Range;
  /**
   * Observable gift list
   */
  observableGiftList: any;
  observableTrashGiftList: any;
  /**
   * Observable currency list
   */
  observableCurrencyList: any;
  /**
   * Observable minimum / maximum cash gift amount
   */
  observableAmount: any;

  /**
   * Account ID
   */
  private accountId: string;
  /**
   * Module
   */
  private module: ModuleType;
  /**
   * Gift list subscription
   */
  private giftListSubscription: Subscription;
  private trashGiftListSubscription: Subscription;
  /**
   * module subscription
   */
  private moduleSubscription: Subscription;

  /**
   * Constructor
   * @param afs Angular firestore
   */
  constructor(
    private afs: AngularFirestore,
    private moduleService: ModuleService,
  ) {
    this.giftList = [];
    this.trashGiftList = [];
    this.currencyList = [];

    this.observableGiftList = new BehaviorSubject<Gift[]>(this.giftList);
    this.observableTrashGiftList = new BehaviorSubject<Gift[]>(this.trashGiftList);
    this.observableCurrencyList = new BehaviorSubject<string[]>(this.currencyList);
    this.observableAmount = new BehaviorSubject<any>(this.amount);
  }
  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.unwatchCurrentModule();
    this.unwatchGiftList();
    this.unwatchTrashGiftList();

    this.giftList = [];
    this.trashGiftList = [];
    this.currencyList = [];
  }

  /**
   * Setup Account ID and start watching gift list.
   * @param accountId Account ID
   */
  async setupAccountId(accountId: string) {
    this.accountId = accountId;
    if ( this.accountId) {
      await this.watchCurrentModule();
    } else {
      await this.unwatchCurrentModule();
      await this.unwatchGiftList();
      await this.unwatchTrashGiftList();

      this.giftList = [];
      this.trashGiftList = [];
      this.currencyList = [];
    }
  }

  /**
   * Watch current module
   */
  async watchCurrentModule() {
    if (!this.moduleSubscription) {
      this.moduleSubscription = this.moduleService.observableCurrentModule.subscribe((module: ModuleType) => {
        if (module && this.module !== module) {
          this.module = module;
          this.watchGiftList();
          if (this.module === 'trash') {
            this.watchTrashGiftList();
          } else {
            this.unwatchTrashGiftList();
          }
        }
      });
    }
  }

  /**
   * Unwatch current module
   */
  async unwatchCurrentModule() {
    if (this.moduleSubscription) {
      this.moduleSubscription.unsubscribe();
      this.moduleSubscription = null;
    }
  }

  /**
   * Watch gift list
   */
  async watchGiftList() {
    if (this.accountId && !this.giftListSubscription) {
      this.giftListSubscription = this.afs.collection(`accounts/${ this.accountId }/gifts/`,
      ref => ref.where('delete', '==', false))
      .snapshotChanges().pipe(distinctUntilChanged(), map(actions => actions.map( a => {
        const gift: Gift = a.payload.doc.data() as Gift;
        if (!gift?.giftId || gift.giftId !== a.payload.doc.id) { gift.giftId = a.payload.doc.id; }
        return gift;
      }).filter((gift: Gift) => {
        if (gift?.giftId && gift?.qty > 0) {
          if (gift.giftType === 'cash' && (gift.amount || gift.name) && gift.currency) {
            return true;
          } else if (gift.giftType === 'other' && gift.name) {
            return true;
          }
        }
        return false;
      }))).subscribe((giftList: Gift[]) => {
        this.giftList = giftList;
        this.setupCurrencyList();
        this.setupAmount();
        this.observableGiftList.next(this.giftList);
      });
    }
  }

  async watchTrashGiftList() {
    if (this.accountId) {
      if (this.module === 'trash' && !this.trashGiftListSubscription) {
        this.trashGiftListSubscription = this.afs.collection(`accounts/${ this.accountId }/gifts/`,
        ref => ref.where('delete', '==', true))
        .snapshotChanges().pipe(distinctUntilChanged(), map(actions => actions.map( a => {
          const data: Gift = a.payload.doc.data() as Gift;
          if (!data.giftId) { data.giftId = a.payload.doc.id; }
          return data;
        }).filter((gift: Gift) => {
          if (gift?.giftId && gift?.qty > 0) {
            if (gift.giftType === 'cash' && gift.amount && gift.currency) {
              return true;
            } else if (gift.giftType === 'other' && gift.name) {
              return true;
            }
          }
          return false;
        }))).subscribe((giftList: Gift[]) => {
          this.trashGiftList = giftList;
          this.observableTrashGiftList.next(this.trashGiftList);
        });
      }
    }
  }

  /**
   * Unwatch gift list
   */
  async unwatchGiftList() {
    if (this.giftListSubscription) {
      this.giftListSubscription.unsubscribe();
      this.giftListSubscription = null;
    }
  }

  async unwatchTrashGiftList() {
    if (this.trashGiftListSubscription) {
      this.trashGiftListSubscription.unsubscribe();
      this.trashGiftListSubscription = null;
    }
  }

  getGiftList(module?: ModuleType) {
    if (!module) {
      module = this.module;
    }
    if (module === 'trash') {
      return this.trashGiftList ? this.trashGiftList : [];
    } else {
      return this.giftList ? this.giftList : [];
    }
  }

  /**
   * Setup currency list
   */
  setupCurrencyList(module?: ModuleType) {
    const giftList = this.getGiftList(module);
    this.currencyList = [ ...new Set(giftList.filter((gift: Gift) => {
      if (gift.giftType === 'cash' && gift.currency) {
        return true;
      } else {
        return false;
      }
    }).map(x => x.currency))];
    this.observableCurrencyList.next(this.currencyList);
  }

  /**
   * Setup minimum / maximum amount
   */
  setupAmount() {
    this.amount = this.getMinMaxAmount();
    this.observableAmount.next(this.amount);
  }

  /**
   * Get minimum / maximum amount
   * @param currency currency code to filter, blank for all currency
   * @returns Minimum / Maxium cash amount
   */
  getMinMaxAmount(currency?: string, module?: ModuleType): Range {
    const giftList = this.getGiftList(module);
    const amountList = giftList?.filter((gift: Gift) => {
      if (gift?.giftType === 'cash' && gift?.currency && gift?.amount) {
        if (currency && gift.currency !== currency) {
          return false;
        }
        return true;
      }
      return false;
    }).map((gift: Gift) => {
      return gift?.amount;
    });
    return {
      lower: amountList.length ? Math.min( ...amountList ) : 0,
      upper: amountList.length ? Math.max( ...amountList ) : 0
    };
  }

  /**
   * Get new gift ID
   * @returns New Gift ID from firestore
   */
  getNewGiftId(): string {
    return this.accountId ? this.afs.collection(`accounts/${ this.accountId }/gifts/`).ref.doc().id : '';
  }

  /**
   * Get gift by gift ID.
   * @param giftId Gift ID
   * @returns Gift
   */
  getGiftById(giftId: string, module?: ModuleType): Gift {
    let gift: Gift;
    const giftList = this.getGiftList(module);
    const index = giftList?.findIndex((x: Gift) => x?.giftId === giftId);
    if (index !== -1) {
      gift = giftList[index];
    }
    return gift;
  }

  /**
   * Get gift list by gift ID list.
   * @param giftIdList Gift ID List
   * @returns Gift list
   */
  getGiftListById(giftIdList: string[], module?: ModuleType): Gift[] {
    const giftList = this.getGiftList(module);
    return giftIdList?.length && giftList?.length ? giftList.filter((gift: Gift) => {
      if (giftIdList?.indexOf(gift.giftId) !== -1) {
        return true;
      }
      return false;
    }) : [];
  }

  /**
   * Get gift list by guest ID
   * @param guestId Guest ID
   * @returns Gift list
   */
  getGiftListByGuestId(guestId: string, module?: ModuleType): Gift[] {
    const giftList = this.getGiftList(module);
    return guestId && giftList?.length ? giftList.filter((x: Gift) => {
      return x.guestIdList?.indexOf(guestId) !== -1 ? true : false;
    }) : [];
  }

  /**
   * Get gift list by group id
   * @param groupId group id
   * @returns gift list
   */
  getGiftListByGroupId(groupId: string, module?: ModuleType): Gift[] {
    const giftList = this.getGiftList(module);
    return groupId && giftList?.length ? giftList.filter((x: Gift) => {
      return x.groupIdList?.indexOf(groupId) !== -1 ? true : false;
    }) : [];
  }

  /**
   * Get gift id list by guest id
   * @param guestId guest id
   * @returns gift id list
   */
  getGiftIdListByGuestId(guestId: string): string[] {
    return this.getGiftListByGuestId(guestId).map((x: Gift) => {
      return x.giftId;
    });
  }

  /**
   * Get gift id list by group id
   * @param guestId group id
   * @returns gift id list
   */
  getGiftIdListByGroupId(groupId: string): string[] {
    return this.getGiftListByGroupId(groupId).map((x: Gift) => {
      return x.giftId;
    });
  }

}
