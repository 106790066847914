import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { StdWebsiteContentsList } from 'src/app/commons/website';
import { WebsiteContent, WebsiteContents, WebsiteDetail, WebsiteStdContent } from 'src/app/interfaces/website';
import { FunctionService } from '../general/function.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { WebsiteDetailService } from './website-detail.service';
import { UpdateByService } from '../user/update-by.service';

@Injectable({
  providedIn: 'root'
})
export class WebsiteContentsService implements OnInit, OnDestroy {

  accountId: string;

  websiteContents: WebsiteContents;

  private websiteDetail: WebsiteDetail;

  observable: any;

  websiteDetailSubscription: Subscription;
  
  constructor(
    private afs: AngularFirestore,
    private functionService: FunctionService,
    private updateByService: UpdateByService,
  ) {
    this.observable = new BehaviorSubject<WebsiteContents>(this.websiteContents);
  }
  
  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  setWebsiteDetail(websiteDetail: WebsiteDetail) {
    this.websiteDetail = websiteDetail;
  }

  setup(websiteContents: WebsiteContents) {
    if (!websiteContents) {
      websiteContents = {};
    }
    Object?.keys(websiteContents)?.forEach((contentId: string) => {
      if (contentId === 'host_msg') {
        if (!this.websiteDetail?.hostMsg?.enable) {
          websiteContents[contentId].enable = false;
        }
      } else if (contentId === 'save_the_date') {
        if (!this.websiteDetail?.form?.enable) {
          websiteContents[contentId].enable = false;
        }
      } else if (contentId === 'venue') {
        if (!this.websiteDetail?.venue?.enable) {
          websiteContents[contentId].enable = false;
        }
      } else if (contentId === 'gallery') {
        if (!this.websiteDetail?.gallery?.enable) {
          websiteContents[contentId].enable = false;
        }
      } else if (contentId === 'contact') {
        if (!this.websiteDetail?.contact?.enable) {
          websiteContents[contentId].enable = false;
        }
      } else if (contentId === 'itinerary') {
        if (!this.websiteDetail?.itinerary?.enable) {
          websiteContents[contentId].enable = false;
        }
      } else if (contentId === 'dress_code') {
        if (!this.websiteDetail?.dressCode?.enable) {
          websiteContents[contentId].enable = false;
        }
      } else if (contentId === 'time') {
        // if (!this.websiteDetail?.time?.enable) {
        //   websiteContents[contentId].enable = false;
        // }
      } else if (contentId === 'qrcode') {
        if (!this.websiteDetail?.qrcode?.enable) {
          websiteContents[contentId].enable = false;
        }
      } else if (contentId === 'web_checkin') {
        if (!this.websiteDetail?.checkin?.enable) {
          websiteContents[contentId].enable = false;
        }
      }
    });
    if (!this.functionService.isEqual(websiteContents, this.websiteContents)) {
      this.websiteContents = websiteContents;
      this.observable.next(this.websiteContents);
    }
  }
  

  getWebsiteContents(websiteContents?: WebsiteContents) {
    if (!websiteContents) {
      websiteContents = this.websiteContents ? { ...this.websiteContents } : {}
    }
    Object.values(StdWebsiteContentsList).forEach((websiteStdContent: WebsiteStdContent) => {
      if (websiteStdContent?.contentId && !websiteContents?.[websiteStdContent.contentId]) {
        websiteContents[websiteStdContent.contentId] = {
          contentId: websiteStdContent.contentId,
          title: '',
          msg: '',
          enable: true,
          custom: false,
          order: websiteStdContent.order,
        };
      }
      // if (websiteStdContent?.contentId && websiteContents?.[websiteStdContent.contentId]?.enable) {
      //   if (websiteStdContent.contentId === 'host_msg') {
          
      //   } else if (websiteStdContent.contentId === 'save_the_date') {
          
      //   } else if (websiteStdContent.contentId === 'venue') {
          
      //   } else if (websiteStdContent.contentId === 'gallery') {
          
      //   } else if (websiteStdContent.contentId === 'contact') {
          
      //   } else if (websiteStdContent.contentId === 'itinerary') {
          
      //   }
      // }
    });
    return this.fixOrderNumbers(websiteContents);
  }

  getStdWebsiteContents() {
    let websiteContents: WebsiteContents = {};
    Object.values(StdWebsiteContentsList).forEach((websiteStdContent: WebsiteStdContent) => {
      if (websiteStdContent?.contentId) {
        websiteContents[websiteStdContent.contentId] = {
          contentId: websiteStdContent.contentId,
          title: '',
          msg: '',
          enable: true,
          custom: false,
          order: websiteStdContent.order,
        };
      }
    });

    websiteContents = this.fixOrderNumbers(websiteContents);

    return { ...websiteContents };
  }

  getWebsiteContent(contentId: string) {
    const websiteContents = this.getWebsiteContents();
    return websiteContents?.[contentId] ? { ...websiteContents[contentId] } : null;
  }

  fixOrderNumbers(objects: WebsiteContents ) {
    // Sort the objects by order number
    const sortedObjects = Object.values(objects).sort((a, b) => a.order - b.order);

    // Map to track the next available order number
    let nextOrderNumber = Math.max(...sortedObjects.map(obj => obj.order)) + 1;

    // Iterate through the sorted objects
    for (const obj of sortedObjects) {
      if (sortedObjects.some(o => o !== obj && o.order === obj.order)) {
        obj.order = nextOrderNumber++;
      }
    }

    // Reconstruct the objects map with updated order numbers
    const result: WebsiteContents = {};
    for (const obj of sortedObjects) {
        result[obj.contentId] = obj;
    }

    return result;
  }

  async save(contents: WebsiteContents) {
    if (this.accountId && !this.functionService.isEqual(contents, this.websiteContents)) {
      const ref = this.afs.firestore.doc(`accounts/${ this.accountId }/accountSetting/website`);
      ref.set({ contents }, { merge: true });
    }
  }

  async saveWebsiteContent(content: WebsiteContent) {
    if (this.accountId && content?.contentId && !this.functionService.isEqual(content, this.websiteContents?.[content.contentId])) {
      const contents = {};
      content.updateBy = this.updateByService.updateBy;
      contents[content.contentId] = content;
      const ref = this.afs.firestore.doc(`accounts/${ this.accountId }/accountSetting/website`);
      ref.set({ contents }, { merge: true });
    }
  }

  async deleteWebsiteContent(contentId: string) {
    if (this.accountId && contentId) {
      const websiteContent = this.getWebsiteContent(contentId);
      if (websiteContent?.custom) {
        const ref = this.afs.firestore.doc(`accounts/${ this.accountId }/accountSetting/website`);
        ref.set({ contents: { [contentId]: this.functionService.firestoreDeleteField } }, { merge: true });
      }
    }
  }
}
