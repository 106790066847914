import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Inbox, InboxStatus, InboxTarget } from 'src/app/interfaces/inbox';
import { InboxSortType, InboxType } from 'src/app/types/inbox';

import { FunctionService } from '../general/function.service';

import { InboxContentService } from './inbox-content.service';
import { InboxService } from './inbox.service';
import { UserService } from '../user/user.service';


@Injectable({
  providedIn: 'root'
})
export class InboxListService implements OnInit, OnDestroy {

  constructor(
    private inboxService: InboxService,
    private inboxContentService: InboxContentService,
    private userService: UserService,
    private functionService: FunctionService,
  ) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  getInboxListByType(inboxType?: InboxType): Inbox[] {
    let inboxList = this.functionService.cloneDeep(this.inboxService.list);

    if (inboxList?.length && inboxType && inboxType !== 'notification') {
      inboxList = inboxList.filter((inbox: Inbox) => {
        return inbox.inboxType === inboxType;
      });
    }
    
    return inboxList;
  }

  getInboxById(inboxId: string, inboxType?: InboxType) {
    if (inboxId) {
      const list = this.getInboxListByType(inboxType);
      const index = list?.findIndex((x: Inbox) => {
        return x.inboxId === inboxId;
      });
      if (index !== -1 && list?.[index]) {
        return list[index];
      }
    }
    return null;
  }

  getInboxStatusById(inboxId: string, inboxType: InboxType): InboxStatus {
    if (inboxType === 'releaseNote') {
      const list = this.inboxService.releaseNoteStatusList?.filter((inboxStatus: InboxStatus) => {
        return inboxStatus?.inboxId === inboxId;
      });

      return list?.[0] ? list[0] : { read: false };
    } else {
      const inbox = this.getInboxById(inboxId, inboxType);
      return inbox?.inboxStatus ? inbox.inboxStatus : {};
    }
  }

  search(
    keyword?: string,
    type?: InboxType,
    filter?: any,
    sorting?: InboxSortType,
    desc?: boolean,
  ) {
    let inboxList = this.getInboxListByType(type);

    if (keyword) {
      inboxList = inboxList.filter((inbox: Inbox) => {
        return this.searchInboxByKeyword(inbox, keyword);
      });
    }

    if (filter?.enable) {
      inboxList = inboxList.filter((inbox: Inbox) => {
        return this.filterInboxByCriteria(inbox, filter);
      });
    }

    return this.sortList(inboxList, sorting, desc);
  }

  sortList(inboxList: Inbox[], field?: InboxSortType, desc?: boolean): Inbox[] {
    if (inboxList?.length) {
      inboxList.sort((a: Inbox, b: Inbox) => {
        if (field) {
          return this.functionService.compare(a?.createBy?.time?.seconds, b?.createBy?.time?.seconds, desc);
        } else {
          return this.functionService.compare(a?.createBy?.time?.seconds, b?.createBy?.time?.seconds, desc);
        }
        return this.functionService.compare(a?.createBy?.time?.seconds, b?.createBy?.time?.seconds, true);
      });
    }
    return inboxList;
  }

  searchInboxByKeyword(inbox: Inbox, keyword: string): boolean {
    if (keyword && inbox?.inboxId) {
      const inboxContent = this.inboxContentService.getMsgContent(inbox?.inboxContent);
      if (inboxContent?.title) {
        if (this.functionService.search(inboxContent.title, keyword)) {
          return true;
        } else if (this.functionService.chineseMath(inboxContent.title, keyword)) {
          return true;
        }

        if (inboxContent?.description) {
          if (this.functionService.search(inboxContent.description, keyword)) {
            return true;
          } else if (this.functionService.chineseMath(inboxContent.description, keyword)) {
            return true;
          }
        }

        if (inboxContent?.msg) {
          if (this.functionService.search(inboxContent.msg, keyword)) {
            return true;
          } else if (this.functionService.chineseMath(inboxContent.msg, keyword)) {
            return true;
          }
        }
      }
    } else {
      return false;
    }
  }

  filterInboxByCriteria(inbox: Inbox, filter: any): boolean {
    if (filter?.enable) {
      if (filter?.accountId) {
        let flag = false;
        if (inbox?.inboxTargetList?.length) {
          inbox.inboxTargetList?.forEach((inboxTarget: InboxTarget) => {
            if (inboxTarget?.accounts?.length) {
              if (filter.accountId !== 'none') {
                const index = inboxTarget.accounts.indexOf(filter.accountId);
                if (index !== -1) {
                  flag = true;
                }
              }
            } else {
              if (filter.accountId === 'none') {
                flag = true;
              }
            }
          });
        }

        if (!flag) {
          return false;
        }
      }

      if (!this.functionService.isUndefined(filter?.readStatus) && typeof filter.readStatus === 'boolean') {
        if (inbox?.inboxType === 'releaseNote') {
          const inboxStatus = this.getInboxStatusById(inbox.inboxId, inbox.inboxType);
          if (filter.readStatus) {
            if (!inboxStatus?.read) {
              return false;
            }
          } else {
            if (inboxStatus?.read) {
              return false;
            }
          }
        } else {
          if (filter.readStatus) {
            if (!inbox?.inboxStatus?.read) {
              return false;
            }
          } else {
            if (inbox?.inboxStatus?.read) {
              return false;
            }
          }
        }
        
      }

      if (filter?.startDate) {
        if (filter.startDate > inbox?.createBy?.time) {
          return false;
        }
      }

      if (filter?.endDate) {
        if (inbox?.createBy?.time > filter.endDate) {
          return false;
        }
      }

      if (filter?.createBy) {
        if (inbox?.createBy?.uid !== filter?.createBy) {
          return false;
        }
      }

    }
    return true;
  }

}
