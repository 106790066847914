import { AccountAupService } from 'src/app/services/account/account-aup.service';

import { DebugModeService } from 'src/app/services/general/debug-mode.service';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { PrivilegeService } from 'src/app/services/account/privilege/privilege.service';
import { ModuleService } from 'src/app/services/account/privilege/module.service';
import { PopupService } from 'src/app/services/general/popup.service';

import { BulkActionField } from 'src/app/interfaces/general';
import { ModuleType, SelectedActionType } from 'src/app/types/general';
import { GuestListMode } from 'src/app/types/guest';

/**
 * Bulk action component
 */
@Component({
  selector: 'app-bulk-action',
  templateUrl: './bulk-action.component.html',
  styleUrls: ['./bulk-action.component.scss'],
})
export class BulkActionComponent implements OnInit, OnDestroy {

  @Input() showGuestSeating: boolean;
  /**
   * Bulk action mode
   */
  @Input() private mode: GuestListMode | 'gallery';
  /**
   * Count of total list
   */
  @Input() private count: number;
  /**
   * Selected list
   */
  @Input() private selectedList: string[];

  @Input() set setReady(ready: boolean) {
    if (ready) {
      this.initialize();
    }
  }
  /**
   * Action output with selected action mode
   */
  @Output() action = new EventEmitter<SelectedActionType>();
  /**
   * Toggle edit mode
   */
  @Output() toggleEditMode = new EventEmitter<boolean>();

  /**
   * Next step
   */
  nextStep: boolean;
  /**
   * Select field message
   */
  msg: string;
  /**
   * showField
   */
  showField: BulkActionField;
  /**
   * Show bar
   */
  showBar: boolean;

  debugMode: boolean;

  /**
   * Current module
   */
  private module: ModuleType;
  /**
   * Selected action
   */
  private selectedAction: SelectedActionType;

  /**
   * Constructor
   * @param translate Translate service
   * @param popupService Popup service
   */
  constructor(
    private translate: TranslateService,
    private moduleService: ModuleService,
    private privilegeService: PrivilegeService,
    private accountAupService: AccountAupService,
    private debugModeSerivce: DebugModeService,
    private popupService: PopupService,
  ) { }

  /**
   * Initial next step and action msg
   */
  ngOnInit() {

  }

  ngOnDestroy(): void {
  }

  initialize() {
    if (!this.module) {
      this.module = this.moduleService.currentModule;
    }
    this.nextStep = false;
    this.setupField();
    this.setupMsg();
    this.debugMode = this.debugModeSerivce.debugMode;
  }

  /**
   * Setup action msg based on action mode
   */
  setupMsg() {
    let field: string;
    if (this.mode === 'seating') {
      field = this.translate.instant('MODULE.action.guest.seating');
    } else if (this.mode === 'gift') {
      field = this.translate.instant('GIFT.lbl.gift');
    } else {
      field = this.translate.instant('GUEST.lbl.guest');
    }
    if (field) {
      this.msg = this.translate.instant('VALIDATION.select_field', { field });
    } else {
      this.msg = this.translate.instant('MSG.please_select');
    }
  }

  /**
   * Setup field
   */
  setupField() {
    this.showBar = false;
    this.showField = {};
    if (this.module === 'checkin') {
      if (this.mode === 'group') {
        this.showField.add = true;
        this.showField.info = true;
        this.showField.checkin = true;
        this.showField.uncheck = true;
      } else if (this.mode === 'seating') {
        this.showField.add = true;
        this.showField.info = true;
        this.showField.checkin = true;
        this.showField.uncheck = true;
      } else if (this.mode === 'checkin') {
        this.showField.info = true;
        this.showField.assign = true;
        this.showField.checkin = true;
        this.showField.uncheck = true;
      } else {
        this.showField.add = true;
        this.showField.assign = true;
        this.showField.checkin = true;
        this.showField.uncheck = true;
      }
      if (this.showField.add && !this.checkPrivilege(this.module, 'new')) {
        this.showField.add = false;
      }
      if (this.showField.info && !this.checkPrivilege(this.module, 'edit')) {
        this.showField.info = false;
      }
      if (this.showField.delete && !this.checkPrivilege(this.module, 'delete')) {
        this.showField.delete = false;
      }
      if (this.showField.add || this.showField.info || this.showField.delete) {
        this.showBar = true;
      }
    } else if (this.module === 'trash') {
      this.showField.restore = true;
      this.showField.delete = true;
      this.showBar = true;
    } else if (this.module === 'gift') {
      if (this.mode === 'gift') {
        this.showField.add = true;
        this.showField.info = true;
        this.showField.delete = true;
      } else if (this.mode === 'list') {
        this.showField.add = true;
        this.showField.info = true;
        this.showField.delete = true;
      } else if (this.mode === 'group') {
        this.showField.add = true;
        this.showField.info = true;
        this.showField.delete = true;
      } else if (this.mode === 'preview') {
        this.showField.info = true;
        this.showField.delete = true;
      }
      if (this.showField.add && !this.checkPrivilege(this.module, 'new')) {
        this.showField.add = false;
      }
      if (this.showField.info && !this.checkPrivilege(this.module, 'edit')) {
        this.showField.info = false;
      }
      if (this.showField.delete && !this.checkPrivilege(this.module, 'delete')) {
        this.showField.delete = false;
      }
      if (this.showField.add || this.showField.info || this.showField.delete) {
        this.showBar = true;
      }
    } else if (this.module === 'guest') {
      if (this.mode === 'attending' || this.mode === 'qrcode' || this.mode === 'list') {
        this.showField.add = true;
        this.showField.info = true;
        this.showField.assign = true;
        this.showField.delete = true;
      } else if (this.mode === 'group') {
        this.showField.add = true;
        this.showField.info = true;
        this.showField.assign = true;
        this.showField.delete = true;
      } else if (this.mode === 'seating') {
        this.showField.add = true;
        this.showField.info = true;
        this.showField.assign = true;
        this.showField.delete = true;
      } else if (this.mode === 'preview') {
        if (this.showGuestSeating) {
          this.showField.assign = true;
        } else {
          this.showField.info = true;
        }
        this.showField.delete = true;
      }
      if (this.showField.add && !this.checkPrivilege(this.module, 'new')) {
        this.showField.add = false;
      }
      if (this.showField.info && !this.checkPrivilege(this.module, 'edit')) {
        this.showField.info = false;
      }
      if (this.showField.assign && !this.checkPrivilege(this.module, 'seating')) {
        this.showField.assign = false;
      }
      if (this.showField.delete && !this.checkPrivilege(this.module, 'delete')) {
        this.showField.delete = false;
      }
      if (this.showField.add || this.showField.info || this.showField.assign || this.showField.delete) {
        this.showBar = true;
      }
    } else if (this.mode === 'preview') {
      if (this.showGuestSeating) {
        this.showField.assign = true;
      } else {
        this.showField.info = true;
      }
      this.showField.delete = true;
      this.showBar = true;
    } else if (this.mode === 'invites') {
      this.showField.add = true;
      this.showField.info = true;
      this.showField.assign = true;
      this.showField.delete = true;
      this.showBar = true;
    } else if (this.module === 'website') {
      if (this.mode === 'group') {
        this.showField.add = true;
        this.showField.info = true;
        this.showField.assign = true;
        this.showField.delete = true;
        this.showBar = true;
      } else if (this.mode === 'gallery') {
        // this.showField.add = true;
        this.showField.delete = true;
        this.showField.moveTop = true;
        this.showField.moveBottom = true;
        this.showBar = true;
      }
      
    }
  }

  /**
   * Bulk action, go to next step if applicable.
   * Add - output to parent.
   * Others - check if selected any item and output to parent automatically without go to next step.
   * @param mode bulk action mode
   */
  async bulkAction(selectedAction: SelectedActionType) {
    if (selectedAction && !this.accountAupService.isExceed()) {
      if (selectedAction === 'add') {
        this.action.next(selectedAction);
      } else {
        this.selectedAction = selectedAction;
        if (this.checkSelected(false)) {
          this.action.next(this.selectedAction);
        } else {
          this.nextStep = true;
        }
      }
    }
  }

  /**
   * Cancel next step
   */
  cancel() {
    if (this.module !== 'guest') {
      this.toggleEditMode.next(false);
    }
    this.nextStep = false;
  }

  /**
   * Confirm and output to parent
   */
  async confirm() {
    if (this.selectedAction && this.checkSelected(true)) {
      this.action.next(this.selectedAction);
    }
  }

  /**
   * Check user privilege
   * @param action Action
   */
  checkPrivilege(module: ModuleType, action: string): boolean {
    return this.privilegeService.checkCurrentUserPrivilege(module, action);
  }

  /**
   * Check if any item selected / total item is equal to 1, auto select at parent.
   * @returns true - if selected, else false and prompt alert
   */
  checkSelected(prompt?: boolean): boolean {
    if (this.count === 1 || this.selectedList?.length) {
      return true;
    } else if (prompt) {
      this.popupService.presentAlert(this.msg);
      return false;
    }
  }

}
