import { AccountAupService } from './account-aup.service';

import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subscription, map } from 'rxjs';

import { UpdateByService } from 'src/app/services/user/update-by.service';
import { AccountLoginService } from 'src/app/services/account/account-login.service';

import { FunctionService } from 'src/app/services/general/function.service';
import { ErrorService } from 'src/app/services/general/error.service';
import { AccountInfo, CoupleList } from 'src/app/interfaces/account';
import { Platform } from '@ionic/angular';
import { AccountEventModeService } from './account-event-mode.service';
import { PopupService } from '../general/popup.service';
import { LocaleService } from '../general/locale.service';

/**
 * Account info service
 */
@Injectable({
  providedIn: 'root'
})
export class AccountInfoService implements OnInit, OnDestroy {
  /**
   * Account Info
   */
  accountInfo: AccountInfo;
  /**
   * Observable Account Info
   */
  observableAccountInfo: any;
  /**
   * Account ID
   */
  accountId: string;

  ready: boolean;
  /**
   * Account info subscription
   */
  private accountInfoSubscription: Subscription;

  /**
   * Constructor
   * @param router router
   * @param afs angular firestore
   * @param translate translate service
   * @param accountLoginService account login service
   * @param functionService function service
   * @param popupService popup service
   */
  constructor(
    private platform: Platform,
    private router: Router,
    private afs: AngularFirestore,
    private translate: TranslateService,
    private accountLoginService: AccountLoginService,
    private accountAupService: AccountAupService,
    private accountEventModeService: AccountEventModeService,
    // private localeService: LocaleService,
    private updateByService: UpdateByService,
    private popupService: PopupService,
    private functionService: FunctionService,
    private errorService: ErrorService,
  ) {
    this.observableAccountInfo = new BehaviorSubject<any>(this.accountInfo);
  }

  ngOnInit(): void {
      
  }

  ngOnDestroy() {
    this.unwatchAccountInfo();
  }

  /**
   * Setup Account ID
   * @param accountId Account ID
   */
  async setupAccountId(accountId: string) {
    this.accountId = accountId;
    
    if (this.accountId) {
      await this.watchAccountInfo();
    } else {
      this.ready = false;
      await this.unwatchAccountInfo();
    }
  }

  /**
   * Watch account info
   */
  async watchAccountInfo() {
    if (this.accountId) {
      if (!this.accountInfoSubscription) {
        try {
          this.accountInfoSubscription = this.afs.doc(`accounts/${ this.accountId }/accountDetail/info/`)
          .snapshotChanges().pipe(map(changes => {
            const data: AccountInfo = changes.payload.data() as AccountInfo;
            return data;
          })).subscribe({
            next: async (accountInfo: AccountInfo) => {
              // if (!accountInfo?.dateFormat) {
              //   accountInfo.dateFormat = 'PPPP';
              // }
              // if (!accountInfo?.timeFormat) {
              //   accountInfo.timeFormat = 'p';
              // }
              await this.setupAccountInfo(accountInfo);
              if (!this.ready) {
                await this.functionService.delay(200);
                this.ready = true;
              }
            }, error: (err: any) => {
              console.error(err);
              this.errorService.logError(err);
              this.goAccountLogoutPage();
              this.ready = true;
            }
          });
        } catch (err: any) {
          console.error(err);
        }
      } 
    }
  }

  /**
   * Unwatch account info
   */
  async unwatchAccountInfo() {
    if (this.accountInfoSubscription) {
      this.accountInfoSubscription.unsubscribe();
      this.accountInfoSubscription = null;
    }
  }

  /**
   * Setup account info
   * @param accountInfo account info
   */
   async setupAccountInfo(accountInfo: AccountInfo) {
    if (accountInfo && !this.functionService.isEqual(this.accountInfo, accountInfo)) {
      this.accountInfo = accountInfo;
      if (this.accountInfo) {
        if (this.accountInfo?.weddingId !== this.accountLoginService.weddingId) {
          await this.accountLoginService.setupAccountLogin(this.accountId, this.accountInfo.weddingId);
        }
        if (this.accountInfo?.gallery?.length && !this.accountInfo?.gallery?.[0]) {
          this.accountInfo.gallery = [];
        }
      }
      this.accountEventModeService.eventMode = this.accountInfo?.eventMode ? true : false;
      this.observableAccountInfo.next(this.accountInfo);
    }
    await this.checkAccountInvalid();
  }


  /**
   * Check account invalid
   */
  async checkAccountInvalid() {
    if (this.accountId && (!this.accountInfo || !this.accountInfo.enable)) {
      const modal = await this.popupService.presentAlert(this.translate.instant('ACCOUNTS.msg.invalid_wedding'));
      modal.onDidDismiss().then(() => {
        setTimeout(() => {
          this.goAccountLogoutPage();
        }, 500);
      });
    }
  }

  /**
   * Update account info
   * @param data account info data
   */
  async updateAccountInfo(data: any) {
    if (this.accountId) {
      if (!this.functionService.isEmpty(data) && !this.accountAupService.isExceed()) {
        data.updateBy = this.updateByService.updateBy;
        const accountsRef = this.afs.firestore.doc(`accounts/${ this.accountId }/accountDetail/info`);
        accountsRef.set(data, { merge: true }).then((result) => {
        }).catch((err: any) => {
          this.errorService.logError(err);
        });
      }
    }
  }

  /**
   * Get account title
   * @return account title
   */
  get accountTitle(): string {
    if (this.accountInfo?.title?.value) {
      return this.accountInfo.title.value;
    } else if (this.accountInfo?.coupleList.length === 2 &&
      this.accountInfo?.coupleList[0]?.name && this.accountInfo?.coupleList[1]?.name) {
      return this.translate.instant('ACCOUNT.auto_title',
        { couple1: this.accountInfo.coupleList[0].name, couple2: this.accountInfo.coupleList[1].name }
      );
    }
    return '';
  }

  /**
   * Go upgrade subscription pagae
   */
  async goUpgradeSubscriptionPage() {
    const accountId = this.accountInfo?.accountId;
    const navigationExtras: NavigationExtras = { state: {} };
    if (accountId) {
      navigationExtras.state.accountId = accountId;
      if (this.accountInfo?.accountStatus?.premium && this.accountInfo?.accountType !== 'full') {
        navigationExtras.state.upgradeMode = true;
      } else if (!this.accountInfo?.accountStatus?.premium && this.accountInfo?.accountStatus?.trial) {
        navigationExtras.state.upgradeTrial = true;
      }
    }
    if (this.platform.is('hybrid') && this.platform.is('ios')) {
      if (navigationExtras.state.upgradeMode) {
        this.router.navigate(['/subscription/account'], navigationExtras);
      } else {
        navigationExtras.state.backPremiumPage = true;
        this.router.navigate(['/subscription/premium'], navigationExtras);
      }
    } else {
      navigationExtras.state.backPremiumPage = true;
      this.router.navigate(['/subscription/premium'], navigationExtras);
    }
  }

  getCouple(coupleId: number): CoupleList {
    if (this.accountInfo?.coupleList) {
      const index = this.accountInfo?.coupleList?.findIndex((x: CoupleList) => {
        return x.name && coupleId && x.accountCouple?.coupleId === coupleId;
      });
      if (index !== -1) {
        return this.accountInfo?.coupleList[index];
      }
    }
    
    return null;
  }

  getCoupleName(coupleId: number): string {
    const index = this.accountInfo?.coupleList?.findIndex((x: CoupleList) => {
      return x.name && coupleId && x.accountCouple?.coupleId === coupleId;
    });
    if (index !== -1) {
      return this.accountInfo.coupleList[index].name;
    }
    return '';
  }

  goAccountLogoutPage() {
    this.router.navigate(['/accounts/logout']);
  }

}
