import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import * as Add2Calendar from 'add2calendar';
import { Calendar } from '@awesome-cordova-plugins/calendar/ngx';
import { Platform } from '@ionic/angular';
// import { Subscription } from 'rxjs';
import { AccountInfo } from 'src/app/interfaces/account';
import { Timezone } from 'src/app/interfaces/database';
import { WebsiteDetail, WebsiteThemeColor } from 'src/app/interfaces/website';

import { DateTimeService } from 'src/app/services/general/date-time.service';
import { FunctionService } from 'src/app/services/general/function.service';

import { LinkService } from 'src/app/services/general/link.service';
import { LocalizationService } from 'src/app/services/general/localization.service';

@Component({
  selector: 'app-website-time',
  templateUrl: './website-time.component.html',
  styleUrls: ['./website-time.component.scss'],
})
export class WebsiteTimeComponent  implements OnInit, OnDestroy {
  
  @Input() set setReady(ready: boolean) {
    this.ready = ready;

    if (ready) {
      this.initialize();
    } else {
      // this.unwatch();
    }
  }

  @Input() set setFontSize(fontSize: string) {
    if (fontSize) {
      this.fontSize = fontSize;
    }
    this.setup();
  }

  @Input() previewMode: boolean;
  @Input() pageMode: boolean;

  @Input() set setWebsiteDetail(websiteDetail: WebsiteDetail) {
    this.websiteDetail = websiteDetail;
    this.setup();
  }
  @Input() set setAccountInfo(accountInfo: AccountInfo) {
    this.accountInfo = accountInfo;
    this.setup();
  }

  @Input() websiteThemeColor: WebsiteThemeColor;

  @Input() set setStartTime(startTime: number) {
    this.startTime = startTime;
    this.setup();
  };
  @Input() set setEndTime(endTime: number) {
    this.endTime = endTime;
    this.setup();
  };
  
  @Input() set setDateFormat(dateFormat: string) {
    this.dateFormat = dateFormat ? dateFormat : this.localizationService.getDateFormat();
    this.setup();
  };
  @Input() set setTimeFormat(timeFormat: string) {
    this.timeFormat = timeFormat ? timeFormat : this.localizationService.getTimeFormat();
    this.setup();
  };
  @Input() set setLocale(locale: string) {
    this.locale = locale;
    this.setup();
  };

  @Input() set setTimezone(timezone: Timezone) {
    this.timezone = timezone;
    this.setup();
  };

  @Input() set setDesign(design: number) {
    this.design = design;
    this.setup();
  };
  @Input() saveCalendar: boolean;

  ready: boolean;

  fontSize: string;
  iconSize: string;
  
  fullText: string;

  websiteDetail: WebsiteDetail;
  accountInfo: AccountInfo;

  startTime: number;
  endTime: number;
  dateFormat: string;
  timeFormat: string;
  locale: string;
  timezone: Timezone;
  design: number;

  formatStartDate: string;
  formatStartTime: string;
  formatEndDate: string;
  formatEndTime: string;
  fontSize1: string;
  fontSize2: string;
  fontSize3: string;

  // fontSize1: string;
  // fontSize2: string;
  // fontSize3: string;

  // private websiteTimeSubscription: Subscription;


  constructor(
    private platform: Platform,
    private calendar: Calendar,
    private dateTimeService: DateTimeService,
    private localizationService: LocalizationService,
    private linkService: LinkService,
    private functionService: FunctionService,
  ) {}

  ngOnInit(): void {
      
  }

  ngOnDestroy(): void {
  }

  ionViewWillEnter() {
    this.initialize();
  }

  ionViewWillLeave() {
  }

  initialize() {
    if (!this.design) {
      this.design = 1;
    }
    
    if (this.functionService.isUndefined(this.dateFormat) && this.accountInfo?.dateFormat) {
      this.dateFormat = this.accountInfo.dateFormat;
    }
    if (this.functionService.isUndefined(this.timeFormat) && this.accountInfo?.timeFormat) {
      this.timeFormat = this.accountInfo.timeFormat;
    }
    if (!this.locale && this.accountInfo?.locale) {
      this.locale = this.accountInfo?.locale;
    }
    this.setup();
  }

  setup() {
    this.formatStartDate = this.getFormattedDate(this.startTime * 1000);
    this.formatEndDate = this.getFormattedDate(this.endTime * 1000);
    this.formatStartTime = this.getFormattedTime(this.startTime * 1000);
    this.formatEndTime = this.getFormattedTime(this.endTime * 1000);
    this.fontSize1= this.getFontSize('1');
    this.fontSize2 = this.getFontSize('2');
    this.fontSize3 = this.getFontSize('3');
    this.iconSize = this.getIconSize();
  }

  getFormattedDate(time: number) {
    if (this.dateFormat && time) {
      return this.dateTimeService.format(time, this.dateFormat, '', this.timezone?.name, this.locale);
    }
    return '';
  }

  getFormattedTime(time: number) {
    if (this.timeFormat && time) {
      return this.dateTimeService.format(time, this.timeFormat, '', this.timezone?.name, this.locale);
    }
    return ''; 
  }

  format(time: number, timeFormat?: string, timezone?: string, locale?: string) {
    return this.dateTimeService.format(time, timeFormat, '', timezone, locale);
  }

  getIconSize() {
    if (this.fontSize === 'huge') {
      return '50px';
    } else if (this.fontSize === 'large') {
      return '36px';
    } else if (this.fontSize === 'small') {
      return '20px';
    } else if (this.fontSize === 'tiny') {
      return '16px';
    } else {
      return '24px';
    }
  }

  getFontSize(type: string) {
    if (!this.fontSize && this.websiteDetail?.time?.fontSize) {
      this.fontSize = this.websiteDetail.time.fontSize;
    }
    if (this.design === 1 || this.design === 2) {
      if (this.fontSize === 'huge') {
        if (type === '1') {
          return '2.5em';
        } else if (type === '2') {
          return '2.0em';
        }
        // this.fontSize1 = '2.5em';
        // this.fontSize2 = '2.0em';
      } else if (this.fontSize === 'large') {
        if (type === '1') {
          return '1.75em';
        } else if (type === '2') {
          return '1.5em';
        }
        // this.fontSize1 = '1.5em';
        // this.fontSize2 = '1.25em';
      } else if (this.fontSize === 'small') {
        if (type === '1') {
          return '1em';
        } else if (type === '2') {
          return '0.75em';
        }
        // this.fontSize1 = '0.75em';
        // this.fontSize2 = '0.5em';
      } else if (this.fontSize === 'tiny') {
        if (type === '1') {
          return '0.5em';
        } else if (type === '2') {
          return '0.25em';
        }
      } else {
        if (type === '1') {
          return '1.25em';
        } else if (type === '2') {
          return '1em';
        }
        // this.fontSize1 = '1em';
        // this.fontSize2 = '0.75em';
      }
    } else if (this.design === 3) {
      if (this.fontSize === 'huge') {
        if (type === '1') {
          return '60px';
        } else if (type === '2') {
          return '26px'
        } else if (type === '3') {
          return '24px';
        }
        // this.fontSize1 = '60px';
        // this.fontSize2 = '26px';
        // this.fontSize3 = '24px';
      } else if (this.fontSize === 'large') {
        if (type === '1') {
          return '50px';
        } else if (type === '2') {
          return '22px'
        } else if (type === '3') {
          return '20px';
        }
        // this.fontSize1 = '50px';
        // this.fontSize2 = '22px';
        // this.fontSize3 = '20px';
      } else if (this.fontSize === 'small') {
        if (type === '1') {
          return '36px';
        } else if (type === '2') {
          return '16px'
        } else if (type === '3') {
          return '14px';
        }
        // this.fontSize1 = '36px';
        // this.fontSize2 = '16px';
        // this.fontSize3 = '14px';
      } else {
        if (type === '1') {
          return '40px';
        } else if (type === '2') {
          return '18px'
        } else if (type === '3') {
          return '16px';
        }
        // this.fontSize1 = '40px';
        // this.fontSize2 = '18px';
        // this.fontSize3 = '16px';
      }
    }
  }

  getSaveCalendar() {
    if (!this.functionService.isUndefined(this.saveCalendar)) {
      return this.saveCalendar;
    } else {
      return this.websiteDetail?.time?.saveCalendar;
    }
  }

  async openCalendar(type: string) {
    if (this.getSaveCalendar()) {
      if (this.platform.is('hybrid')) {
        const permission = await this.calendar.hasWritePermission();
        if (permission) {
          this.calendar.createEventWithOptions(
            this.accountInfo?.title?.value,
            this.accountInfo?.location?.name,
            '',
            new Date(this.startTime),
            new Date(this.endTime ? this.endTime : this.startTime),
          );
        } else {
          await this.calendar.requestWritePermission();
          this.openCalendar(type);
        }
      } else {
        const singleEventArgs = {
          title       : this.functionService.sanitizeHtml(this.accountInfo?.title?.value),
          start       : this.dateTimeService.format(this.startTime * 1000, 'PPpp', 'en', this.accountInfo?.timezone?.name),
          end         : this.dateTimeService.format((this.endTime ? this.endTime : this.startTime) * 1000, 'PPpp', 'en', this.accountInfo?.timezone?.name),
          location    : this.accountInfo?.location?.name,
          description : '',
          isAllDay    : false
        };
        const calendar = new Add2Calendar(singleEventArgs);
        let url = '';
        if (type === 'google') {
          url = calendar.getGoogleUrl();
        } else if (type === 'ical') {
          url = calendar.getICalUrl();
        } else if (type === 'outlook') {
          url = calendar.getOutlookUrl();
        } else if (type === 'yahoo') {
          url = calendar.getYahooUrl();
        }
        if (url) {
          // window.open(url);
          this.openUrl(url);
        }
      }
    }
  }

  async openUrl(url: string) {
    this.linkService.openUrl(url);
  }
}
