/**
 * Universal URL
 */
export const UniversalUrl: string[] = [
    'subscription/premium',
    '/subscription/account',
    '/subscription/trial',
    '/subscription/payment-result',
    '/payment/result/',
    '/payment/stripe-result',
    '/blast/launching',
    '/links',
    '/about',
    '/vip',
    '/business'
];

/**
 * Public accessible url
 */
export const PublicUrl: string[] = [
    '/main',
    '/user/logout',
    '/gift-card',
    '/accounts/preview-login',
    '/accounts/migrate',
    '/about/home',
    '/about/company',
    '/about/privacy',
    '/about/term',
    '/about/data-protection',
    '/about/contact',
    '/about/support',
    '/about/app-update',
    '/about/app-download',
    'subscription/premium',
    '/subscription/account',
    '/subscription/trial',
    '/subscription/payment-result',
    '/payment/result/',
    '/payment/stripe-status',
    '/blast/launching',
    '/links/',
    '/vip',
    '/business',
    '/website'
];

/**
 * Disable anonymous login URL
 */
export const HideAnonymousLoginUrl: string[] = [
    '/gift-card',
    '/subscription/payment-result',
    '/payment/result/',
    '/accounts/setup'
];

/**
 * Redirect user to others page for listed URL
 */
export const UserRedirectUrl: string[] = [
    '/main'
];

/**
 * Redirect to account page for listed URL
 */
export const AccountRedirectUrl: string[] = [
    '/main',
    '/tabs-accounts/list',
    '/accounts/login',
    '/accounts/list',
    '/accounts/setup',
    '/subscription/trial',
    '/subscription/premium',
    '/subscription/payment-result',
    '/payment/result/',
    '/user/credit'
];

/**
 * Invalid User URL
 */
export const InvalidUserUrl: string[] = [
    '/main',
    '/user/logout'
];

/**
 * Show Menu URL
 */
export const ShowMenuUrl: string[] = [
    '/tabs-accounts/',
    '/tabs-user/',
    '/tabs-account/',
    '/tabs-guest/',
    '/tabs-checkin/',
    '/tabs-gift/',
    '/tabs-trash/',
    '/sms/',
    '/accounts/',
    '/account/',
    '/user/profile',
    '/user/referral',
    '/about/',
    '/business/',
    '/blast/',
    '/inbox/',
];

export const GuestPageHostNameOld: string = 'vip.thebigday.my';
export const GuestPageHostName: string = 'vip.thebigdays.com';

export const WeddingWebsiteUrl: string = 'vips.thebigdays.com';

export const ShortUrlList: string[] = [
    'tbd.im',
    'tbds.vip',
    'tbds.app',
    'thebigdays-url.web.app', 
    'thebigdays-url.firebaseapp.com'
];

/**
 * Dynamic URL
 */
export const DynamicUrl: any = {
    short: {
        // download: 'https://access.thebigday.my/download',
        gift: 'https://gift.thebigdays.com',
        gift2: 'https://gift.thebigday.my',
        login: 'https://access.thebigdays.com',
        login2: 'https://access.thebigday.my',
        vip: 'https://tbd.im',
        weddingWebsite: 'https://thebigdays-url.web.app',
        // link: 'https://link.thebigday.my',
        // visitor: 'https://visitor.thebigday.my',
    },
    long: {
        home: 'https://wedding.thebigdays.com',
        website: 'https://thebigdays.com',
        // download: 'https://access.thebigday.my/download',
        login: 'https://wedding.thebigdays.com/#/links/login/',
        login2: 'https://wedding.thebigday.my/#/links/login/',
        gift: 'https://wedding.thebigdays.com/#/links/gift/',
        gift2: 'https://wedding.thebigday.my/#/links/gift/',
        migrate: 'https://wedding.thebigdays.com/#/links/migrate/',
        // promo: 'https://wedding.thebigdays.com/#/links/promo/',
        visitor: 'https://' + GuestPageHostName + '/#/vip/',
        visitor2: 'https://wedding.thebigdays.com/#/vip/',
        visitor3: 'https://wedding.thebigday.my/#/vip/',
        visitor4: 'https://vip.thebigday.my/#/vip/',
        guestWeb: 'https://' + WeddingWebsiteUrl + '/#/website/',
        guestWeb2: 'https://wedding.thebigdays.com/#/website/',
        subscription: 'https://wedding.thebigdays.com/#/subscription/account/',
        helpCenter: {
            en: 'https://thebigdays.com/help-center/',
            'zh-TW': 'https://thebigdays.com/zh/%E5%B9%AB%E5%8A%A9%E4%B8%AD%E5%BF%83/',
        },
        store: {
            appstore: 'https://apps.apple.com/app/id1565639963',
            playstore: 'https://play.google.com/store/apps/details?id=my.thebigday.wedding',
            appgallery: 'https://urldra.cloud.huawei.com/fICYXL7LwI'
        },
        review: {
            facebook: 'https://www.facebook.com/thebigday.my/',
            google: 'https://g.page/r/CQ07owUNJusbEAE'
        }
    }
};


export const WhatsAppDeeplink = 'https://wa.me/[MOBILE]?text=[MSG]'
/**
 * App URL
 */
export const AppUrl = 'https://wedding.thebigdays.com';

export const AppDownloadUrl = '/about/app-download';

/**
 * Support URL
 */
export const SupportUrl: any = {
    youtube: 'https://www.youtube.com/channel/UCoq-VtYmceUmTOPfsrEPmuw',
    facebook: 'https://www.facebook.com/thebigday.my',
    instagram: 'https://www.instagram.com/thebigday.my/',
    messenger: 'https://m.me/thebigday.my',
    email: 'mailto:support@thebigdays.com'
};

export const ReviewUrl: any = {
    facebook: 'https://www.facebook.com/thebigday.my/reviews/'
};

export const QrHowVideoUrl: any = {
    en: 'https://youtu.be/EvZiY4OYQqQ',
    'zh-TW': 'https://youtu.be/WdQfUN5wQ5k',
};

export const HowImportUrl: any = {
    en: 'https://thebigdays.com/how-do-i-import-my-excel-guest-list-to-wedding-account/',
    'zh-TW': 'https://thebigdays.com/zh/%e5%a6%82%e4%bd%95%e4%bd%bf%e7%94%a8excel%e5%8c%af%e5%85%a5%e8%b3%93%e5%ae%a2%e5%90%8d%e5%96%ae%e5%88%b0%e5%a9%9a%e7%a6%ae%e5%b8%b3%e6%88%b6%ef%bc%9f/'
};

export const GuideScanGuestUrl: any = {
    en: 'https://thebigdays.com/how-to-scan-check-in-guest/',
    'zh-TW': 'https://thebigdays.com/zh/%e5%a6%82%e4%bd%95%e8%ae%93%e8%b3%93%e5%ae%a2%e6%8e%83%e7%a2%bc%e7%b0%bd%e5%88%b0%ef%bc%9f/',
};

export const GuideScanAccountUrl: any = {
    en: '',
    'zh-TW': '',
};

export const GuestListTemplateDriveUrl: any = {
    'en': 'https://docs.google.com/spreadsheets/d/1VOp65zOmOFkb8WJcVbbBKawx6uto3PCp/edit?usp=sharing&ouid=115179684551566878248&rtpof=true&sd=true',
    'ms': 'https://docs.google.com/spreadsheets/d/1nt98poa5Btj0Ib4MCv68DVQ3ig7AnsUN/edit?usp=sharing&ouid=115179684551566878248&rtpof=true&sd=true',
    'zh': 'https://docs.google.com/spreadsheets/d/1CdcNR1HXXfskYdDrDg79P077cZ3uxlKE/edit?usp=sharing&ouid=115179684551566878248&rtpof=true&sd=true',
    'zh-TW': 'https://docs.google.com/spreadsheets/d/1yenVyVFNM1l2uPhUxA0bPD9i6mSmKZPO/edit?usp=sharing&ouid=115179684551566878248&rtpof=true&sd=true',
};

export const GuestListTemplateCloudUrl: any = {
    'en': 'https://firebasestorage.googleapis.com/v0/b/thebigday-wedding.appspot.com/o/excel%2Fthebigday%20Guest%20List%20Template%20-%20en.xlsx?alt=media&token=deb06180-10d7-4062-86c0-c8f898cfa626',
    'ms': 'https://firebasestorage.googleapis.com/v0/b/thebigday-wedding.appspot.com/o/excel%2Fthebigday%20Guest%20List%20Template%20-%20ms.xlsx?alt=media&token=89a77b83-0dd1-425f-8e19-ddcfe6e38fd8',
    'zh': 'https://firebasestorage.googleapis.com/v0/b/thebigday-wedding.appspot.com/o/excel%2Fthebigday%20Guest%20List%20Template%20-%20zh.xlsx?alt=media&token=53bb659d-36a8-4480-995f-c429a13b6621',
    'zh-TW': 'https://firebasestorage.googleapis.com/v0/b/thebigday-wedding.appspot.com/o/excel%2Fthebigday%20Guest%20List%20Template%20-%20zh-tw.xlsx?alt=media&token=fae771d6-2883-43ea-8f72-506e9e81cc37',
};
