import { Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { IonTextarea, IonInput, Platform, PickerController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { TemplateVariableEvent, TemplateVariableWedding } from 'src/app/commons/template';
import { AccountInfo } from 'src/app/interfaces/account';
import { Country, Language } from 'src/app/interfaces/database';
import { LocationDetail } from 'src/app/interfaces/general';
import { PageSetting, VisitorAttachment } from 'src/app/interfaces/visitor';
import { AccountInfoService } from 'src/app/services/account/account-info.service';
import { FileService } from 'src/app/services/general/file.service';
import { FunctionService } from 'src/app/services/general/function.service';
import { LanguageService } from 'src/app/services/general/language.service';
import { LinkService } from 'src/app/services/general/link.service';
import { PhotoService } from 'src/app/services/general/photo.service';
import { PopupService } from 'src/app/services/general/popup.service';
import { LocalityService } from 'src/app/services/location/locality.service';
import { VisitorInfoService } from 'src/app/services/visitor/visitor-info.service';
import { VisitorLanguageService } from 'src/app/services/visitor/visitor-language.service';
import { VisitorManageService } from 'src/app/services/visitor/visitor-manage.service';
import { VisitorSettingService } from 'src/app/services/visitor/visitor-setting.service';
import { VisitorThemeService } from 'src/app/services/visitor/visitor-theme.service';

import { AttachmentType } from 'src/app/types/general';
import { TemplateVariableType } from 'src/app/types/template';
import { VisitorSettingType, VisitorSettingWizardType } from 'src/app/types/visitor';
import { LocationComponent } from 'src/app/components/general/location/location.component';
import { VisitorPageComponent } from 'src/app/components/visitor/visitor-page/visitor-page.component';

import { Clipboard } from '@capacitor/clipboard';
import { SettingVisitorComponent } from '../setting-visitor/setting-visitor.component';
import { Subscription } from 'rxjs';
import { VisitorService } from 'src/app/services/visitor/visitor.service';
import { GpsService } from 'src/app/services/general/gps.service';
import { AccountEventModeService } from 'src/app/services/account/account-event-mode.service';

@Component({
  selector: 'app-setting-visitor-wizard',
  templateUrl: './setting-visitor-wizard.component.html',
  styleUrls: ['./setting-visitor-wizard.component.scss'],
})
export class SettingVisitorWizardComponent  implements OnInit, OnDestroy {

  @ViewChild('titleInput') titleInput: IonTextarea;
  @ViewChild('dateInput') dateInput: IonTextarea;
  @ViewChild('startTimeInput') startTimeInput: IonInput;
  @ViewChild('greetingInput') greetingInput: IonTextarea;
  @ViewChild('msgInput') msgInput: IonTextarea;
  
  langCode: string;
  pageLangList: Language[];
  
  validationMsg: any;

  editMode = {
    title: false,
    date: false,
    startTime: false,
    endTime: false,
    timezone: false,
    greeting: false,
    msg: false,
    pax: false,
    seating: false,
    session: false,
    itinerary: false,
    groupName: false,
    floorplan: false,
    menu: false,
    attachment: false,
  };

  step: VisitorSettingWizardType;
  
  infoForm: FormGroup;
  cocktailForm: FormGroup;
  venueForm: FormGroup;
  settingForm: FormGroup;
  attachmentForm: FormGroup;
  msgForm: FormGroup;

  pageLocation: LocationDetail;
  pageSetting: PageSetting;
  pageTheme: string;

  accountInfo: AccountInfo;

  progress: number;

  private pageInfo: any;

  private pageSettingSubscription: Subscription;
  private pageThemeSubscription: Subscription;
  private pageInfoSubscription: Subscription;
  private pageLangListSubscription: Subscription;
  private accountInfoSubscription: Subscription;
  
  constructor(
    private ngZone: NgZone,
    private platform: Platform,
    private modalController: ModalController,
    private pickerController: PickerController,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private accountInfoService: AccountInfoService,
    private accountEventModeService: AccountEventModeService,
    private visitorService: VisitorService,
    private visitorSettingService: VisitorSettingService,
    private visitorInfoService: VisitorInfoService,
    private visitorThemeService: VisitorThemeService,
    private visitorLanguageService: VisitorLanguageService,
    private visitorManageService: VisitorManageService,
    private localityService: LocalityService,
    private gpsService: GpsService,
    private linkService: LinkService,
    private fileService: FileService,
    private photoService: PhotoService,
    private languageService: LanguageService,
    private functionService: FunctionService,
    private popupService: PopupService,
  ) { }

  ngOnInit() {}

  ngOnDestroy(): void {
    this.unwatch();
  }

  ionViewWillEnter() {
    this.initial();
  }

  ionViewWillLeave() {
    this.unwatch();
  }

  initial() {
    this.langCode = this.languageService.getAccountLanguage()?.code;
    this.setupForm();
    if (!this.step) {
      this.step = 'info';
    }
    this.setupProgress();

    this.watch();
    // this.pageSetting = { ...this.visitorSettingService.getPageSetting() };
    // this.pageTheme = this.visitorThemeService.getPageTheme();
    // this.pageInfo = { ...this.visitorInfoService.getPageInfo() };
    // this.pageLangList = [ ...this.visitorLanguageService.getPangeLangList() ]
    
    // this.accountInfo = this.accountInfoService.accountInfo;
    // this.pageLocation = this.accountInfo?.location;
    
    // this.setupsettingFormValue();
    // this.setupPageInfoFormValue();
    // this.setupAccountInfo();
  }

  watch() {
    this.watchPageSetting();
    this.watchPageLangList();
    this.watchPageInfo();
    this.watchPageTheme();
    this.watchAccountInfo();
  }

  unwatch() {
    this.unwatchPageSetting();
    this.unwatchPageLangList();
    this.unwatchPageInfo();
    this.unwatchPageTheme();
    this.unwatchAccountInfo();
  }

  /**
   * Watch page setting
   */
  async watchPageSetting() {
    if (!this.pageSettingSubscription) {
      this.pageSettingSubscription = this.visitorSettingService.observablePageSetting.subscribe((pageSetting: PageSetting) => {
        if (!this.pageSetting || !this.functionService.isEqual(pageSetting, this.pageSetting)) {
          if (pageSetting) {
            this.pageSetting = { ...pageSetting };
          } else {
            this.pageSetting = this.visitorSettingService.getPageSetting();
          }
          this.setupsettingFormValue();
        }
      });
    }
    
  }

  /**
   * Unwatch page setting
   */
  async unwatchPageSetting() {
    if (this.pageSettingSubscription) {
      this.pageSettingSubscription.unsubscribe();
      this.pageSettingSubscription = null;
    }
  }
  
  /**
   * Watch page theme
   */
  async watchPageTheme() {
    if (!this.pageThemeSubscription) {
      this.pageThemeSubscription = this.visitorThemeService.observablePageTheme.subscribe((pageTheme: string) => {
        this.pageTheme = pageTheme;
        if (!this.pageTheme) {
          this.pageTheme = '1.1';
        }
        this.setupPageTheme();
      });
    }
    
  }

  /**
   * Unwatch page theme
   */
  async unwatchPageTheme() {
    if (this.pageThemeSubscription) {
      this.pageThemeSubscription.unsubscribe();
      this.pageThemeSubscription = null;
    }
  }

  /**
   * Watch page info
   */
  async watchPageInfo() {
    if (!this.pageInfoSubscription) {
      this.pageInfoSubscription = this.visitorInfoService.observablePageInfo.subscribe((pageInfo: any) => {
        if (!this.pageInfo || !this.functionService.isEqual(pageInfo, this.pageInfo)) {
          if (pageInfo) {
            this.pageInfo = { ...pageInfo };
          } else {
            this.pageInfo = this.visitorInfoService.getPageInfo();
          }
          this.setupPageInfoFormValue();
        }
      });
    }
    
  }

  /**
   * Unwatch page info
   */
  async unwatchPageInfo() {
    if (this.pageInfoSubscription) {
      this.pageInfoSubscription.unsubscribe();
      this.pageInfoSubscription = null;
    }
  }

  /**
   * Watch page language list
   */
  async watchPageLangList() {
    if (!this.pageLangListSubscription) {
      this.pageLangListSubscription = this.visitorLanguageService.observablePageLangList.subscribe(() => {
        this.pageLangList = this.visitorLanguageService.getPangeLangList();
        // await this.setupLanguage();
        // await this.setupPageInfoFormList();
      });
    }
    
  }

  /**
   * Unwatch page language list
   */
  async unwatchPageLangList() {
    if (this.pageLangListSubscription) {
      this.pageLangListSubscription.unsubscribe();
      this.pageLangListSubscription = null;
    }
  }

  /**
   * Watch Account Info
   */
  async watchAccountInfo() {
    if (!this.accountInfoSubscription) {
      this.accountInfoSubscription = this.accountInfoService.observableAccountInfo.subscribe((accountInfo: AccountInfo) => {
        this.accountInfo = accountInfo;
        if (accountInfo?.location) {
          this.pageLocation = accountInfo.location;
        }
        this.setupAccountInfo();
      });
    }
    
  }

  /**
   * Unwatch Account Info
   */
  async unwatchAccountInfo() {
    if (this.accountInfoSubscription) {
      this.accountInfoSubscription.unsubscribe();
      this.accountInfoSubscription = null;
    }
  }

  setupPageTheme() {
    if (this.pageTheme === '1' || this.pageTheme === '2' || this.pageTheme === '3') {
      this.pageTheme = this.pageTheme + '.1';
    }
  }

  setupForm() {
    this.validationMsg = {
      date: [{ type: 'required', msg: this.translate.instant('VALIDATION.required', {
        field: this.translate.instant('DATE.lbl.wedding_date') })
      }],
    };

    this.infoForm = this.formBuilder.group({
      title: new FormControl(''),
      date: new FormControl('', [ Validators.required ]),
      startTime: new FormControl(''),
      endTime: new FormControl(''),
    });

    this.cocktailForm = this.formBuilder.group({
      enable: new FormControl('no'),
      cocktailTime: new FormControl(''),
    });

    this.venueForm = this.formBuilder.group({
      venue: new FormControl(''),
      address: new FormControl(''),
      hall: new FormControl(''),
      remark: new FormControl(''),
    });

    this.settingForm = this.formBuilder.group({
      pax:  new FormControl(true),
      seatingBeforeCheckin: new FormControl(false),
      groupMember:  new FormControl(true),
    });

    this.attachmentForm = this.formBuilder.group({
      floorplan: new FormControl(null),
      menu: new FormControl(null),
      attachment: new FormControl([]),
    });
    
    this.msgForm = this.formBuilder.group({
      greeting:  new FormControl(''),
      msg: new FormControl('')
    });
  }

  /**
  * Setup page setting form value
  */
  async setupsettingFormValue() {
    if (this.settingForm && this.pageSetting) {
      Object.keys(this.pageSetting)?.forEach((key: string) => {
        if (this.settingForm?.controls?.[key]) {
          this.settingForm.controls[key].setValue(this.pageSetting[key]);
        }
        if (this.attachmentForm?.controls?.[key]) {
          this.attachmentForm.controls[key].setValue(this.pageSetting[key]);
        }
      });
      
      this.settingForm.updateValueAndValidity();
    }
  }

  async setupPageInfoFormValue() {
    const langCode = this.langCode ? this.langCode : this.languageService.getAccountLanguage()?.code;
    if (this.pageInfo?.[langCode]) {
      Object.keys(this.pageInfo?.[langCode])?.forEach((key: string) => {
        if (this.infoForm.controls?.[key] && this.pageInfo[langCode][key]) {
          this.infoForm.controls[key].setValue(this.pageInfo[langCode][key]);
        }
      });
      if (this.infoForm.controls?.cocktailTime) {
        if (this.pageInfo?.[langCode]?.cocktailTime) {
          this.cocktailForm.controls.enable.setValue('yes');
          this.cocktailForm.controls.cocktailTime.setValue(this.pageInfo[langCode].cocktailTime);
        } else {
          this.cocktailForm.controls.enable.setValue('no');
        }
      }
      
      if (this.pageInfo?.[langCode]?.greeting && this.msgForm.controls?.greeting) {
        this.msgForm.controls.greeting.setValue(this.pageInfo[langCode].greeting);
      }
      if (this.pageInfo?.[langCode]?.msg && this.msgForm.controls?.msg) {
        this.msgForm.controls.msg.setValue(this.pageInfo[langCode].msg);
      }

      if (this.pageInfo?.[langCode]?.venue && this.venueForm.controls?.venue) {
        this.venueForm.controls.venue.setValue(this.pageInfo[langCode].venue);
      }

      if (this.pageInfo?.[langCode]?.hall && this.venueForm.controls?.hall) {
        this.venueForm.controls.hall.setValue(this.pageInfo[langCode].hall);
      }

      if (this.pageInfo?.[langCode]?.address && this.venueForm.controls?.address) {
        this.venueForm.controls.address.setValue(this.pageInfo[langCode].address);
      }

      if (this.pageInfo?.[langCode]?.remark && this.venueForm.controls?.remark) {
        this.venueForm.controls.remark.setValue(this.pageInfo[langCode].remark);
      }
    }

    if (this.functionService.isUndefined(this.pageInfo?.[langCode]?.title)) {
      this.infoForm.controls.title.setValue('[WEDDING_TITLE]');
    }
    if (this.functionService.isUndefined(this.pageInfo?.[langCode]?.date)) {
      this.infoForm.controls.date.setValue('[WEDDING_DATE]');
    }
    if (this.functionService.isUndefined(this.pageInfo?.[langCode]?.startTime)) {
      this.infoForm.controls.startTime.setValue('[WEDDING_START_TIME]');
    }
    if (this.functionService.isUndefined(this.pageInfo?.[langCode]?.endTime)) {
      this.infoForm.controls.endTime.setValue('[WEDDING_END_TIME]');
    }
    if (this.functionService.isUndefined(this.pageInfo?.[langCode]?.greeting)) {
      this.msgForm.controls.greeting.setValue(this.translate.instant('VISITOR.variable.greeting'));
    }
    if (this.functionService.isUndefined(this.pageInfo?.[langCode]?.msg)) {
      this.msgForm.controls.msg.setValue(this.translate.instant('VISITOR.msg'));
    }

    if (this.pageLocation?.name) {
      if (this.functionService.isUndefined(this.pageInfo?.[langCode]?.venue) && this.pageLocation?.name) {
        this.venueForm.controls.venue.setValue(this.pageLocation.name);
      }
      if (this.functionService.isUndefined(this.pageInfo?.[langCode]?.address) && this.pageLocation?.address) {
        this.venueForm.controls.address.setValue(this.pageLocation.address);
      }
    }

    this.infoForm.updateValueAndValidity();
    this.cocktailForm.updateValueAndValidity();
    this.msgForm.updateValueAndValidity();
    this.venueForm.updateValueAndValidity();
  }

  async setupAccountInfo() {
    if (this.accountInfo && this.infoForm) {
      if (this.functionService.isUndefined(this.infoForm?.value?.title)) {
        this.infoForm.controls.title.setValue('[WEDDING_TITLE]');
      }
      if (!this.infoForm?.value?.date) {
        this.infoForm.controls.date.setValue('[WEDDING_DATE]');
      }
      if (this.functionService.isUndefined(this.infoForm?.value?.startTime)) {
        this.infoForm.controls.startTime.setValue('[WEDDING_START_TIME]');
      }
      if (this.functionService.isUndefined(this.infoForm?.value?.endTime)) {
        this.infoForm.controls.endTime.setValue('[WEDDING_END_TIME]');
      }
    }
  }

  /**
   * Present navigation picker
   * @param options Picker option
   * @param selectedIndex Selected navigation index
   */
  async presentNavigationPicker(options: any[], selectedIndex?: number) {
    const picker = await this.pickerController.create({
      columns: [{
        name: 'gps',
        selectedIndex,
        options
      }],
      buttons: [{
        text: this.translate.instant('BTN.cancel'),
        role: 'cancel'
      }, {
        text: this.translate.instant('BTN.confirm'),
        handler: (result: any) => {
          if (result?.gps?.value) {
            if (result.gps.value === 'google') {
              this.gpsService.openGoogleNav(this.pageLocation);
            } else if (result.gps.value === 'waze') {
              this.gpsService.openWazeNav(this.pageLocation);
            } else if (result.gps.value === 'uber') {
              this.gpsService.openUberNav(this.pageLocation);
            } else if (result.gps.value === 'grab') {
              this.gpsService.openGrab(this.pageLocation);
            } else if (result.gps.value === 'appleMap') {
              this.gpsService.openAppleNav(this.pageLocation);
            } else {
              this.gpsService.openGoogleNav(this.pageLocation);
            }
          }
        }
      }]
    });
    picker.present();
  }

  /**
   * Present location modal for venue setup
   * @param location Location
   */
  async presentLocationModal(location?: LocationDetail) {
    const country: Country = this.localityService.getAccountCountry();
    const modal = await this.modalController.create({
      component: LocationComponent,
      cssClass: 'modal-full-screen-bk',
      componentProps: {
        country,
        location
      }
    });
    modal.present();
    modal.onWillDismiss().then((result: any) => {
      if (result?.data?.location) {
        if (!this.functionService.isEqual(this.pageLocation, result.data.location)) {
          this.pageLocation = result.data.location;
          // this.setupPageInfoFormLocation(true);
        }
      }
    });
  }

  async presentVisitorPageModal(previewData?: any) {
    const modal = await this.modalController.create({
      component: VisitorPageComponent,
      cssClass: 'modal-full-screen',
      componentProps: {
        preview: true,
        accountId: this.accountInfo?.accountId,
        previewData
      }
    });
    modal.present();
  }

  /**
   * Present check-in setting
   */
  async presentVisitorSetting(type: VisitorSettingType) {
    if (type) {
      const modal = await this.modalController.create({
        component: SettingVisitorComponent,
        cssClass: 'modal-full-screen-bk',
        componentProps: {
          type
        }
      });
      modal.present();
    }
  }

  async presentLanguagePicker(options: any[], selectedIndex?: number) {
    const picker = await this.pickerController.create({
      columns: [{
        name: 'language',
        selectedIndex,
        options
      }],
      buttons: [{
        text: this.translate.instant('BTN.cancel'),
        role: 'cancel'
      }, {
        text: this.translate.instant('BTN.confirm'),
        handler: ((result: any) => {
          if (result?.language?.value?.code && result.language.value.code !== this.langCode) {
            this.langCode = result.language.value.code
            this.setupPageInfoFormValue();
          }
        })
      }]
    });
    picker.present();
  }

  async changeLanguage() {
    if (this.pageLangList?.length > 1) {
      const options = this.pageLangList.map((value: Language) => {
        return {
          text: value.name,
          value
        };
      });
      if (options?.length) {
        const selectedIndex = this.pageLangList.findIndex((language: Language) => {
          return language.code == this.langCode;
        });
        this.presentLanguagePicker(options, selectedIndex);
      }
    }
  }

  async preview() {
    if (!this.checkDisabledSubmit()) {
      const data: any = this.generateData();
      this.presentVisitorPageModal({ ...data });
    }
    // let valid = false;
    // if (this.type === 'setting') {
    //   this.pageSettingForm?.markAllAsTouched();
    //   if (this.pageSettingForm?.valid) {
    //     valid = true;
    //   }
    // } else if (this.type === 'info') {
    //   if (await this.validPageInfoFormList()) {
    //     valid = true;
    //   }
    // } else if (this.type === 'theme') {
    //   valid = true;
    // }

    // if (valid) {
    //   const data: any = await this.generateData();
    //   this.presentVisitorPageModal({ ...data });
    // } else {
    //   this.popupService.presentAlert(this.translate.instant('MSG.action_error'));
    // }
  }

  async discard() {
    this.dismissModal();
  }

  /**
   * Dismiss page setting modal
   */
  async dismissModal() {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { this.modalController.dismiss(); }
    }
  }

  setupProgress() {
    this.progress = this.getProgress();
  }

  getProgress() {
    if (this.step === 'info') {
      return 0;
    } else if (this.step === 'cocktail') {
      return 0.125;
    } else if (this.step === 'venue') {
      return 0.25;
    } else if (this.step === 'setting') {
      return 0.375;
    } else if (this.step === 'attachment') {
      return 0.5;
    } else if (this.step === 'theme') {
      return 0.625;
    } else if (this.step === 'background') {
      return 0.75;
    } else if (this.step === 'msg') {
      return 0.875;
    } else if (this.step === 'preview') {
      return 1;
    }
    return 0;
  }

  getTemplateVariableList() {
    return this.accountEventModeService.eventMode ? TemplateVariableEvent : TemplateVariableWedding;
  }

  getHtml(template: string) {
    const variableList = this.getTemplateVariableList();
    variableList.forEach((variable: TemplateVariableType) => {
      const field = '[' + variable.toUpperCase() + ']';
      let newField = '';
      // if (variable === 'wedding_title') {
      //   newField = '<span class="variable">' + this.accountInfoService.accountTitle + '</span>';
      // } else if (variable === 'wedding_date') {
      //   newField = '<span class="variable">' + this.accountInfoService.accountTitle + '</span>';
      // } else if (variable === 'wedding_start_time') {
      //   newField = '<span class="variable">' + this.accountInfoService.accountTitle + '</span>';
      // } else if (variable === 'wedding_timezone') {
      //   newField = '<span class="variable">' + this.accountInfoService.accountTitle + '</span>';
      // } else {
      //   newField = '<span class="variable">' + this.replaceEventType(this.translate.instant('TEMPLATE.variable.' + variable)) + '</span>';
      // }
      newField = '<span class="variable">' + this.replaceEventType(this.translate.instant('TEMPLATE.variable.' + variable)) + '</span>';
      template = this.functionService.replaceAll(template, field, newField);
    });

    return template;
  }

  getLanguageByCode(langCode: string) {
    const language = this.languageService.getLanguageByCode(langCode);
    return language?.name ? language.name : '';
  }

  back() {
    if (this.step === 'info') {
      this.discard();
    } else if (this.step === 'cocktail') {
      this.step = 'info';
    } else if (this.step === 'venue') {
      this.step = 'cocktail';
    } else if (this.step === 'setting') {
      this.step = 'venue';
    } else if (this.step === 'attachment') {
      this.step = 'setting';
    } else if (this.step === 'theme') {
      this.step = 'attachment';
    } else if (this.step === 'background') {
      this.step = 'theme';
    } else if (this.step === 'msg') {
      this.step = 'background';
    } else if (this.step === 'preview') {
      this.step = 'msg';
    }
    this.setupProgress();
  }

  async next() {
    if (!this.checkDisabledSubmit()) {
      if (this.step === 'info') {
        let info: any = this.pageInfo ? this.pageInfo : {};
        if (!info?.[this.langCode]) {
          info = {};
          info[this.langCode] = {};
        }
  
        Object.keys(this.infoForm.value)?.forEach((key: string) => {
          if (!this.functionService.isEqual(this.infoForm.value?.[key], this.pageInfo?.[this.langCode]?.[key])) {
            info[this.langCode][key] = this.infoForm.value?.[key];
          }
        });
        if (!this.functionService.isEmpty(info[this.langCode])) {
          await this.visitorManageService.savePageSetting({ info });
        }
        this.step = 'cocktail';
      } else if (this.step === 'cocktail') {
        if (this.cocktailForm?.value?.enable) {
          let info: any = this.pageInfo ? this.pageInfo : {};
          if (!info?.[this.langCode]) {
            info = {};
            info[this.langCode] = {};
          }

          if (this.cocktailForm.value.enable === 'yes') {
            if (this.pageInfo?.[this.langCode]?.cocktailTime !== this.cocktailForm?.value?.cocktailTime) {
              info[this.langCode].cocktailTime = this.cocktailForm.value.cocktailTime;
            }
          } else if (this.cocktailForm.value.enable === 'no') {
            if (this.pageInfo?.[this.langCode]?.cocktailTime) {
              info[this.langCode].cocktailTime = '';
            }
          }
          if (!this.functionService.isEmpty(info[this.langCode]) && this.langCode) {
            await this.visitorManageService.savePageSetting({ info });
          }
        }
        this.step = 'venue';
      } else if (this.step === 'venue') {
        let info: any = this.pageInfo ? this.pageInfo : {};
        if (!info?.[this.langCode]) {
          info = {};
          info[this.langCode] = {};
        }
        Object.keys(this.venueForm.value)?.forEach((key: string) => {
          if (!this.functionService.isEqual(this.venueForm.value?.[key], this.pageInfo?.[this.langCode]?.[key])) {
            info[this.langCode][key] = this.venueForm.value?.[key];
          }
        });
        if (!this.functionService.isEmpty(info[this.langCode]) && this.langCode) {
          await this.visitorManageService.savePageSetting({ info });
        }
        this.step = 'setting';
      } else if (this.step === 'setting') {
        const setting: PageSetting = this.pageSetting;
        Object.keys(this.settingForm.value)?.forEach((key: string) => {
          if (!this.functionService.isEqual(this.settingForm.value?.[key], this.pageSetting?.[key])) {
            setting[key] = this.settingForm.value?.[key];
          }
        });
        if (!this.functionService.isEmpty(setting) && this.langCode) {
          await this.visitorManageService.savePageSetting({ setting });
        }
        this.step = 'attachment';
      } else if (this.step === 'attachment') {
        this.step = 'theme';
      } else if (this.step === 'theme') {
        if (this.pageTheme && this.pageTheme !== this.visitorThemeService.getPageTheme()) {
          const themeId = this.pageTheme;
          await this.visitorManageService.savePageSetting({ themeId });
        }
        this.step = 'background';
      } else if (this.step === 'background') {
        this.step = 'msg';
      } else if (this.step === 'msg') {
        let info: any = this.pageInfo ? this.pageInfo : {};
        if (!info?.[this.langCode]) {
          info = {};
          info[this.langCode] = {};
        }
        Object.keys(this.msgForm.value)?.forEach((key: string) => {
          if (!this.functionService.isEqual(this.msgForm.value?.[key], this.pageInfo?.[this.langCode]?.[key])) {
            info[this.langCode][key] = this.msgForm.value?.[key];
          }
        });
        if (!this.functionService.isEmpty(info[this.langCode]) && this.langCode) {
          await this.visitorManageService.savePageSetting({ info });
        }
        this.step = 'preview';
      } else if (this.step === 'preview') {
        if (!this.visitorService.getQuickSetupStatus()) {
          await this.visitorManageService.savePageSetting({ quickSetup: true });
        }
        // const data: PageData = this.generateData();
        // if (!this.functionService.isEmpty(data)) {
        //   if (!this.visitorLanguageService.pageLangList?.length) {
        //     data.languageList = this.visitorLanguageService.getDefaultPageLangList();
        //   }
        //   await this.visitorManageService.savePageSetting(data);
        //   this.popupService.saveSuccessToast();
        // }
        this.dismissModal();
      }
      this.setupProgress();
    }
  }

  async copy(field: TemplateVariableType) {
    const variableList = this.getTemplateVariableList();
    if (field && variableList?.indexOf(field) !== -1) {
      await this.popupService.presentLoading();
      try {
        await Clipboard.write({ string: '[' + field.toUpperCase() + ']' });
        await this.popupService.dismissLoading();
        await this.popupService.presentToast(this.translate.instant('COPY.success'), 'success');
      } catch (err: any) {
        await this.popupService.dismissLoading();
        await this.popupService.presentToast(this.translate.instant('COPY.fail'), 'danger');
      }
    }
  }

  checkVariable(variable: string) {
    if (variable === 'guest_seating') {
      if (!this.pageSetting?.seatingBeforeCheckin) {
        return false;
      }
    } else if (variable === 'number_of_guest') {
      if (!this.pageSetting?.pax) {
        return false;
      }
    } else if (variable === 'guest_session') {
      if (!this.pageSetting?.session) {
        return false;
      }
    }
    return true;
  }

  setCocktailEnable(status: string) {
    this.cocktailForm.controls.enable.setValue(status);
  }

  setEditMode(field: string) {
    this.resetEditMode();
    if (field && !this.functionService.isUndefined(this.editMode[field])) {
      this.ngZone.run(async () => {
        this.editMode[field] = true;
        await this.functionService.delay(20);
        if (field === 'title') {
          this.titleInput?.setFocus();
        } else if (field === 'date') {
          this.dateInput?.setFocus();
        } else if (field === 'startTime') {
          this.startTimeInput?.setFocus();
        } else if (field === 'greeting') {
          this.greetingInput?.setFocus();
        } else if (field === 'msg') {
          this.msgInput?.setFocus();
        }
      });
    }
  }

  resetEditMode() {
    this.editMode = {
      title: false,
      date: false,
      startTime: false,
      endTime: false,
      timezone: false,
      greeting: false,
      msg: false,
      pax: false,
      seating: false,
      session: false,
      itinerary: false,
      groupName: false,
      floorplan: false,
      menu: false,
      attachment: false,
    };
  }

  resetTitle() {
    this.infoForm.controls.title.setValue('[WEDDING_TITLE]');
    this.infoForm.updateValueAndValidity();
  }

  resetDate() {
    this.infoForm.controls.date.setValue('[WEDDING_DATE]');
    this.infoForm.updateValueAndValidity();
  }

  resetStartTime() {
    this.infoForm.controls.startTime.setValue('[WEDDING_START_TIME]');
    this.infoForm.updateValueAndValidity();
  }

  resetEndTime() {
    this.infoForm.controls.endTime.setValue('[WEDDING_END_TIME]');
    this.infoForm.updateValueAndValidity();
  }

  resetGreeting() {
    this.msgForm.controls.greeting.setValue(this.translate.instant('VISITOR.variable.greeting'));
    this.msgForm.updateValueAndValidity();
  }

  resetMsg() {
    this.msgForm.controls.msg.setValue(this.translate.instant('VISITOR.msg'));
    this.msgForm.updateValueAndValidity();
  }

  resetVenue() {
    if (this.pageLocation?.name) {
      this.venueForm.controls.venue.setValue(this.pageLocation.name);
      this.venueForm.updateValueAndValidity();
    }
  }

  resetAddress() {
    if (this.pageLocation?.address) {
      this.venueForm.controls.address.setValue(this.pageLocation.address);
      this.venueForm.updateValueAndValidity();
    }
  }

  replaceEventType(text: string) {
    return this.accountEventModeService.replaceEventType(text);
  }

  updateAttachment(type: AttachmentType) {
    if (type) {
      if (type === 'floorplan') {
        let attachment: VisitorAttachment = this.attachmentForm?.value?.floorplan;
        if (!attachment?.id) {
          attachment = {
            id: type,
            title: {
              custom: false,
              value: type,
            },
            enable: true,
            url: '',
            mimeType: '',
            type,
          };
        } else {
          attachment.enable = attachment?.enable ? false :  true;
        }
        this.attachmentForm?.controls?.floorplan.setValue(attachment);
      } else if (type === 'menu') {
        let attachment: VisitorAttachment = this.attachmentForm?.value?.menu;
        if (!attachment?.id) {
          attachment = {
            id: type,
            title: {
              custom: false,
              value: type,
            },
            enable: true,
            url: '',
            mimeType: '',
            type,
          };
        } else {
          attachment.enable = attachment?.enable ? false :  true;
        }
        this.attachmentForm?.controls?.menu.setValue(attachment);
      } else if (type === 'attachment') {
        let attachment: VisitorAttachment = this.attachmentForm?.value?.attachment?.[0];
        if (!attachment?.id) {
          attachment = {
            id: type,
            title: {
              custom: false,
              value: type,
            },
            enable: true,
            url: '',
            mimeType: '',
            type,
          };
        } else {
          attachment.enable = attachment?.enable ? false :  true;
        }
        this.attachmentForm?.controls?.attachment.setValue([ attachment ]);
      }
    }
  }

  selectPhoto() {
    this.photoService.selectPhoto('background_img', true);
  }

  selectFloorplan() {
    this.fileService.selectFile('floorplan', -1, true);
  }

  selectAttachment() {
    this.fileService.selectFile('attachment', -1, true);
  }

  selectMenu() {
    this.fileService.selectFile('menu', -1, true);
  }

  getAttachmentByType(type: AttachmentType) {
    return this.visitorSettingService.getAttachmentByType(type);
  }

  generatePageInfo() {
    let pageInfo: any = this.pageInfo ? this.pageInfo : {};
    if (this.langCode) {
      if (!pageInfo?.[this.langCode]) {
        pageInfo = {};
        pageInfo[this.langCode] = {};
      }
      // const data: any = {};
      Object.keys(this.infoForm.value)?.forEach((key: string) => {
        if (!this.functionService.isEqual(this.infoForm.value?.[key], pageInfo?.[this.langCode]?.[key])) {
          pageInfo[this.langCode][key] = this.infoForm.value?.[key];
        }
      });
      if (this.cocktailForm.value.enable === 'yes') {
        if (this.pageInfo?.[this.langCode]?.cocktailTime !== this.cocktailForm?.value?.cocktailTime) {
          pageInfo[this.langCode].cocktailTime = this.cocktailForm.value.cocktailTime;
        }
      } else if (this.cocktailForm.value.enable === 'no') {
        if (this.pageInfo?.[this.langCode]?.cocktailTime) {
          pageInfo[this.langCode].cocktailTime = '';
        }
      }
      Object.keys(this.venueForm.value)?.forEach((key: string) => {
        if (!this.functionService.isEqual(this.venueForm.value?.[key], this.pageInfo?.[this.langCode]?.[key])) {
          pageInfo[this.langCode][key] = this.venueForm.value?.[key];
        }
      });
      // Object.keys(this.settingForm.value)?.forEach((key: string) => {
      //   if (!this.functionService.isEqual(this.settingForm.value?.[key], this.pageSetting?.[key])) {
      //     pageInfo[this.langCode][key] = this.settingForm.value?.[key];
      //   }
      // });
      Object.keys(this.msgForm.value)?.forEach((key: string) => {
        if (!this.functionService.isEqual(this.msgForm.value?.[key], this.pageInfo?.[this.langCode]?.[key])) {
          pageInfo[this.langCode][key] = this.msgForm.value?.[key];
        }
      });
      // if (!this.functionService.isEmpty(data) && this.langCode) {
      //   pageInfo[this.langCode] = data;
      // }
    }

    return pageInfo;
  }

  generatePageSetting() {
    const pageSetting: PageSetting = this.pageSetting;
    Object.keys(this.settingForm.value)?.forEach((key: string) => {
      if (!this.functionService.isEqual(this.settingForm.value?.[key], this.pageSetting?.[key])) {
        pageSetting[key] = this.settingForm.value?.[key];
      }
    });
    return pageSetting;
  }

  generateData() {
    const info = this.generatePageInfo();
    const setting = this.generatePageSetting();
    const themeId = this.pageTheme;
    return { info, setting, themeId };
  }

  /**
   * Test gps navigation
   */
  testGps() {
    const options = [];
    if (this.pageSetting?.googleMap) {
      options.push({
        value: 'google',
        text: this.translate.instant('VISITOR.setting.lbl.google'),
      });
    }
    if (this.pageSetting?.waze) {
      options.push({
        value: 'waze',
        text: this.translate.instant('VISITOR.setting.lbl.waze'),
      });
    }
    if (this.pageSetting?.uber) {
      options.push({
        value: 'uber',
        text: this.translate.instant('VISITOR.setting.lbl.uber'),
      });
    }
    if (this.pageSetting?.grab) {
      options.push({
        value: 'grab',
        text: this.translate.instant('VISITOR.setting.lbl.grab'),
      });
    }
    if (this.pageSetting?.apple) {
      options.push({
        value: 'appleMap',
        text: this.translate.instant('VISITOR.setting.lbl.apple'),
      });
    }
    if (options?.length > 1) {
      this.presentNavigationPicker(options);
    } else if (this.pageSetting?.googleMap) {
      this.gpsService.openGoogleNav(this.pageLocation);
    } else if (this.pageSetting?.waze) {
      this.gpsService.openWazeNav(this.pageLocation);
    } else if (this.pageSetting?.uber) {
      this.gpsService.openUberNav(this.pageLocation);
    } else if (this.pageSetting?.grab) {
      this.gpsService.openGrab(this.pageLocation);
    } else if (this.pageSetting?.apple) {
      this.gpsService.openAppleNav(this.pageLocation);
    } else {
      this.gpsService.openGoogleNav(this.pageLocation);
    }
  }

  async openGps(url: string) {
    url = encodeURI(url);
    if (this.platform.is('ios') && this.platform.is('hybrid')) {
      window.open(url, '_system');
    } else {
      this.openUrl(url);
    }
  }

  async openUrl(url: string) {
    this.linkService.openUrl(url);
  }

  selectPageTheme(pageTheme: string) {
    this.pageTheme = pageTheme;
  }

  checkDisabledSubmit() {
    if (this.step === 'info') {
      if (!this.infoForm?.value?.date) {
        return true;
      }
    } else if (this.step === 'cocktail') {
      if (!this.cocktailForm?.value?.enable) {
        return true;
      } else if (this.cocktailForm.value.enable === 'yes' && !this.cocktailForm?.value?.cocktailTime) {
        return true;
      }
    } else if (this.step === 'venue') {
      
    } else if (this.step === 'setting') {

    } else if (this.step === 'attachment') {
      if (this.attachmentForm?.value?.floorplan?.enable && !this.pageSetting?.floorplan?.url) {
        return true;
      }
      if (this.attachmentForm?.value?.menu?.enable && !this.pageSetting?.menu?.url) {
        return true;
      }
      if (this.attachmentForm?.value?.attachment?.[0]?.enable && !this.pageSetting?.attachment?.[0]?.url) {
        return true;
      }
    } else if (this.step === 'theme') {
      if (!this.pageTheme) {
        return true;
      }
    } else if (this.step === 'background') {

    } else if (this.step === 'msg') {

    }
    return false;
  }

}
