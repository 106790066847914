import { Privilege } from '../interfaces/privilege';

/**
 * Preloaded standard system action list
 */
export const StdActionList: Privilege = {
    account: [
        'view',
        'edit',
        'member',
        'setting'
    ],
    website: [
        'view',
        'designs',
        'contents',
        'invites'
    ],
    budget: [
        'view',
        'edit',
        'delete'
    ],
    expense: [
        'view',
        'edit',
        'delete'
    ],
    task: [
        'view',
        'edit',
        'delete'
    ],
    rsvp: [
        'view',
        'edit',
        'delete'
    ],
    guest: [
        'view',
        'new',
        'edit',
        'delete',
        'seating',
        'qrcode'
    ],
    checkin: [
        'view',
        'new',
        'edit',
        'checkin',
        'uncheck',
        'seating'
    ],
    qrcode: [
        'view',
        'edit',
        'delete'
    ],
    gift: [
        'view',
        'new',
        'edit',
        'delete'
    ],
    trash: [
        'view',
        'restore',
        'delete'
    ],
    blast: [
        'view',
        'send',
        'new',
        'edit',
    ]
};

/**
 * Default action list
 */
export const DefaultActionList: Privilege = {
    account: [
        'view'
    ],
    website: [
        'view'
    ],
    budget: [
        'view',
        'edit'
    ],
    expense: [
        'view',
        'edit'
    ],
    task: [
        'view',
        'edit'
    ],
    rsvp: [
        'view',
        'edit'
    ],
    guest: [
        'view',
        'new',
        'edit',
        'seating',
        'qrcode'
    ],
    checkin: [
        'view',
        'new',
        'checkin',
        'uncheck',
        'seating'
    ],
    qrcode: [
        'view',
        'edit'
    ],
    gift: [
        'view',
        'new',
        'edit'
    ],
    trash: [
        'view'
    ],
    blast: [
        'view',
        'send',
        'edit'
    ]
};
