import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { AccountInfoService } from '../account/account-info.service';
import { FunctionService } from '../general/function.service';
import { VisitorDefaultService } from './visitor-default.service';
import { VisitorManageService } from './visitor-manage.service';
import { DateTimeService } from '../general/date-time.service';

@Injectable({
  providedIn: 'root'
})
export class VisitorInfoService implements OnInit, OnDestroy {

  /**
   * Page info
   */
  pageInfo: any;
  /**
   * Obeservable page info
   */
  observablePageInfo: any;

  constructor(
    private accountInfoService: AccountInfoService,
    private visitorDefaultService: VisitorDefaultService,
    private visitorManageService: VisitorManageService,
    private dateTimeService: DateTimeService,
    private functionService: FunctionService,
  ) {
    this.observablePageInfo = new BehaviorSubject<any>(this.pageInfo);
  }
  
  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  setupPageInfo(pageInfo?: any) {
    if (!this.functionService.isEqual(pageInfo, this.pageInfo)) {
      this.pageInfo = pageInfo;
      this.visitorManageService.pageInfo = pageInfo;
      this.observablePageInfo.next(this.pageInfo);
      this.checkPageInfo();
    }
  }

  checkPageInfo() {
    let flag = false;
    if (this.pageInfo) {
      Object.keys(this.pageInfo)?.forEach((language: string) => {
        if (language && this.pageInfo?.[language]) {
          if (this.pageInfo?.[language]?.title && this.pageInfo?.[language]?.title === this.accountInfoService.accountTitle) {
            this.pageInfo[language].title = '[WEDDING_TITLE]';
            flag = true;
          }
          if (this.accountInfoService.accountInfo?.time?.seconds) {
            if (this.pageInfo?.[language]?.date && this.pageInfo?.[language]?.date === this.dateTimeService.format(this.accountInfoService.accountInfo.time.seconds * 1000, 'do MMM yyyy, EEEE', language, this.accountInfoService?.accountInfo?.timezone?.name)) {
              this.pageInfo[language].date = '[WEDDING_DATE]';
              flag = true;
            }
            if (this.pageInfo?.[language]?.startTime && this.pageInfo?.[language]?.startTime === this.dateTimeService.format(this.accountInfoService.accountInfo.time.seconds * 1000, 'p', language, this.accountInfoService?.accountInfo?.timezone?.name)) {
              this.pageInfo[language].startTime = '[WEDDING_START_TIME]';
              flag = true;
            }

            if (this.accountInfoService.accountInfo?.endTime?.seconds && this.pageInfo?.[language]?.endTime && this.pageInfo?.[language]?.endTime === this.dateTimeService.format(this.accountInfoService.accountInfo.endTime.seconds * 1000, 'p', language, this.accountInfoService?.accountInfo?.timezone?.name)) {
              this.pageInfo[language].endTime = '[WEDDING_END_TIME]';
              flag = true;
            }
          }
          
          if (this.pageInfo?.[language]?.timezone && this.pageInfo?.[language]?.timezone === this.accountInfoService.accountInfo?.timezone?.utc) {
            this.pageInfo[language].timezone = '[WEDDING_TIMEZONE]';
            flag = true;
          }
          if (this.pageInfo?.[language]?.groupName && this.pageInfo?.[language]?.groupName === 'VISITOR.variable.groupName') {
            const variable = '[GROUP_NAME]';
            if (language === 'en') {
              this.pageInfo[language].groupName = 'Group Name: ' + variable;
              flag = true;
            } else if (language === 'ms') {
              this.pageInfo[language].groupName = 'Nama Kumpulan: ' + variable;
              flag = true;
            } else if (language === 'zh') {
              this.pageInfo[language].groupName = '团体名称：' + variable;
              flag = true;
            } else if (language === 'zh-TW') {
              this.pageInfo[language].groupName = '團體名稱：' + variable;
              flag = true;
            }
          }
        }
      });
      if (flag) {
        this.visitorManageService.savePageSetting({ info: this.pageInfo });
      }
    }
  }

  getPageInfo() {
    return this.pageInfo ? this.pageInfo : this.getDefaultPageInfo();
  }

  getDefaultPageInfo() {
    return this.visitorDefaultService.getDefaultPageInfo();
  }
}
