import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ZhuyinService } from 'src/app/services/chinese/zhuyin.service';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { customAlphabet, nanoid } from 'nanoid';
import compareVersions, { compare as CompareVersion } from 'compare-versions';

/**
 * Deep compare equality of two variable
 */
import equal from 'fast-deep-equal/es6';

/**
 * firebase js library for generating firebase timestamp.
 */
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

/**
 * Chinese related library
 */
/**
 * Pinyin match for simplified chinese
 */
import PinyinMatch from 'pinyin-match';
/**
 * Convert between traditional / simplified chinese.
 * tify - simplified -> traditional
 * sify - traditional -> simplified
 */
import { sify } from 'chinese-conv';
/**
 * Convert zhuyin to pinyin.
 * Not compatible with zhuyin tone.
 */
// import { toPinyin } from 'zhuyin';
/**
 * Convert chinese to cangjie code / key.
 */
// import cangjie from 'cangjie-tool';

/**
 * Loadsh library.
 */
import _ from 'lodash';
import { differenceInDays, getTime } from 'date-fns';
// import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

const AlphaNumericSet = 'abcdefghjkmnpqrstuvwxyzABCDEFGHJKLMNPQRSTUVWXYZ123456789';
const NumericSet = '0123456789';

/**
 * General function library.
 */
@Injectable({
  providedIn: 'root'
})
export class FunctionService implements OnInit, OnDestroy {

  /**
   * Constructor
   */
  constructor(
    private afs: AngularFirestore,
    private translate: TranslateService,
    private zhuyinService: ZhuyinService,
  ) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  /**
   * Natural sorting of two value
   * @param a first item
   * @param b second item
   * @param desc descending flag
   */
  compare(a: any, b: any, desc?: boolean): 0 | 1 | -1 {
    if (desc) {
      return b?.toString()?.localeCompare(a?.toString(), 'zh', { numeric: true, sensitivity: 'base'});
    } else {
      return a?.toString()?.localeCompare(b?.toString(), 'zh', { numeric: true, sensitivity: 'base'});
    }
  }

  compareVersion(a: string, b: string, operator: compareVersions.CompareOperator): boolean {
    if (a && b) {
      return CompareVersion(a, b, operator);
    }
    return false;
  }

  /**
   * Deep equal compare between 2 variables.
   * @param a Variable A
   * @param b Variable B
   * @returns true if two variables is deep equal.
   */
  isEqual(a: any, b: any): boolean {
    return equal(a, b);
  }

  clone(value: any): any {
    return _.clone(value);
  }

  /**
   * Deep clone
   * @param value value
   * @returns Deep cloned variable.
   */
  cloneDeep(value: any): any {
    return _.cloneDeep(value);
  }

  /**
   * Find difference between two variable.
   * @param a Variable A
   * @param b Variabe B
   * @returns array of difference.
   */
  difference(a: any, b: any): any[] {
    return _.difference(a, b);
  }

  existed(text: string, array: string[]) {
    let existed = false;
    if (text && array?.length) {
      array?.forEach((x: string) => {
        if (!existed) {
          if (text.indexOf(x) !== -1) {
            existed = true;
          }
        }
      })
    }
    return existed;
  }

  /**
   * Convert value to number
   * @param value value
   * @returns converted number
   */
  toNumber(value: any): number {
    return _.toNumber(value);
  }

  /**
   * Check if value is empty
   * @param value value
   * @returns true if value is empty
   */
  isEmpty(value: any): boolean {
    return _.isEmpty(value);
  }

  /**
   * Check if value is null
   * @param value value
   * @return true if value is null
   */
  isUndefined(value: any): boolean {
    // return _.isUndefined(value);
    return value === null || value === undefined || _.isUndefined(value) ? true : false;
  }

  

  /**
   * Check if value is a number
   * @param value value
   * @return true if value is a number
   */
  isNumber(value: any): boolean {
    return !isNaN(Number(value));
  }

  validateEmail(email: string) {
    const regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    return regexp.test(email);
  }

  isEmailExisted(text: string): boolean {
    // Regular expression to match email addresses
    const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/g;

    // Array to store matched email addresses
    const emails: string[] = [];

    // Find matches in the input string
    const matches = text.match(emailRegex);

    if (matches) {
      // Remove duplicates by converting the array to a Set and back to an array
      const uniqueMatches = Array.from(new Set(matches));

      // Push the unique email addresses to the result array
      emails.push(...uniqueMatches);
    }

    return emails?.length ? true : false;
  }

  /**
   * Get Countdown between now and param time
   * @param time Time
   * @returns count down number
   */
  getCountDown(time: any): number {
    if (time?.seconds) {
      return differenceInDays(time.seconds * 1000, getTime(new Date()));
    }
    return -1;
  }

  getTranslate(value: string): string {
    if (value) {
      const translate = this.translate.instant(value);
      if (translate !== value) {
        return translate;
      }
    }
    return '';
  }

  replaceAll(text: string, search: string, replace: string) {
    // var regEx = new RegExp(search, "ig");
    // return text.replace(regEx, replace);
    return text.split(search).join(replace);
  }

  replaceNewLine(value: string, replace?: string) {
    if (this.isUndefined(replace)) {
      replace = ' ';
    }
    return value?.toString()?.replace(/(\r\n|\n|\r)/gm, replace)?.trim();
  }

  containsUnicodeCharacters(value: string) {
    if (!value || this.isUndefined(value)) {
      return false;
    }
    return /[^\u0000-\u00ff]/.test(value);
  }

  /**
   * Search function
   * @param query value
   * @param keyword keyword
   * @returns true if keyword match.
   */
  search(query: string, keyword: string): boolean {
    if (query?.toString()?.toLowerCase()?.indexOf(keyword?.toString().toLowerCase()) > -1) {
      return true;
    }
    return false;
  }

orderScore(query: string, keyword: string): number {
    if (query?.toString()?.toLowerCase() === keyword?.toString()?.toLowerCase()) {
      return 99999;
    } else {
      const queryWords = query.trim().split(/\s+/);
      let score = 0;
      queryWords?.forEach((value: string, index: number) => {
        let queryScore = 0;
        if (value?.toString()?.toLowerCase() === keyword?.toString()?.toLowerCase()) {
          queryScore = 9999 - index;
        } else if (this.search(query, keyword)) {
          queryScore = 999 - index;
        } else if (this.isChinese(value) && this.chineseMath(value, keyword)) {
          queryScore = 99 - index;
        }
        if (queryScore && queryScore > score) {
          score = queryScore;
        }
      });
      return score;
    }
  }

  isChinese(value: string): boolean {
    const chinese = /[\u4E00-\u9FFF\u3400-\u4DBF]/;
    if (value && chinese.test(value)) {
      return true;
    }
    return false;
  }

  isSimplifiedChinese(value: string): boolean {
    const simplifiedChiense = /[\u4E00-\u9FFF]/;
    if (value && simplifiedChiense.test(value)) {
      return true;
    }
    return false;
  }

  isTraditionalChinese(value: string): boolean {
    const traditionalChinese = /[\u4E00-\u62FF\u6300-\u77FF\u7800-\u8CFF\u8D00-\u9FFF]/;
    if (value && traditionalChinese.test(value)) {
      return true;
    }
    return false;
  }

  chineseMath(value: string, keyword: string): boolean {
    if (this.isChinese(value)) {
      const simplified = sify(value);
      if (this.pinyinMatch(simplified, keyword)) {
        return true;
      } else if (this.zhuyinMatch(simplified, keyword)) {
        return true;
      }
      // else if (this.cangjieMatch(value, keyword)) {
      //   return true;
      // }
    }
    return false;
  }

  /**
   * Search value by chinese method - Pinyin / Zhuyin (TW) / Cangjie (HK)
   * @param value value to match
   * @param keyword keyword to match
   * @returns true if keyword match the value.
   */
  pinyinMatch(value: string, keyword: string): boolean {
    if (PinyinMatch.match(value, keyword)) {
      return true;
    }
    return false;
  }

  zhuyinMatch(value: string, keyword: string) {
    const pinyinFirstLetter = this.zhuyinService.toPinyin(keyword, { everything: true, numbered: true }, true)?.join(' ')?.toString()?.trim();
    if (PinyinMatch.match(value, pinyinFirstLetter)) {
      return true;
    } else {
      const pinyin = this.zhuyinService.toPinyin(keyword, { everything: true, numbered: true }, false)?.join(' ')?.toString()?.trim();
      if (PinyinMatch.match(value, pinyin)) {
        return true;
      }
    }
    return false;
  }

  // cangjieMatch(value: string, keyword: string) {
  //   const traditional = tify(value);
  //   let cangjieCode = '';
  //   let cangjieKey = '';
  //   [...traditional]?.forEach((char: string) => {
  //     const result = cangjie.toCode(char);
  //     if (result) {
  //       if (result.key) { cangjieKey = cangjieKey + ' ' + result.key; }
  //       if (result.cangjie) { cangjieCode = cangjieCode + ' ' + result.cangjie; }
  //     }
  //   });
  //   if (cangjieKey?.toLocaleLowerCase()?.indexOf(keyword.toLocaleLowerCase()) !== -1) {
  //     return true;
  //   } else if (cangjieCode?.toLocaleLowerCase()?.indexOf(keyword.toLocaleLowerCase()) !== -1) {
  //     return true;
  //   }
  //   return false;
  // }

  generateFirestoreId(): string {
    return this.afs.createId();
  }

  generateFirebaseId(path): string {
    const dbRef = firebase.database().ref(path).push();
    return dbRef.key;
  }
  /**
   * Generate given timestamp in firestore format
   * @param time timestamp
   */
  generateTimestamp(time: any) {
    const date = new Date(time);
    return firebase.firestore.Timestamp.fromDate(date);
  }

  increaseFirestore(num?: number) {
    return firebase.firestore.FieldValue.increment(num ? num : 1);
  }

  /**
   * Generate random ID.
   * @returns Random ID
   */
  randomId(): string {
    return nanoid();
  }

  customRandomId(minLength: number, maxLength: number, numeric?: boolean): string {
    const min = Math.ceil(minLength);
    const max = Math.floor(maxLength);
    const codeLength: number = Math.floor(Math.random() * (max - min + 1) ) + min;
    const customNanoId = customAlphabet(numeric ? NumericSet : AlphaNumericSet, codeLength);
    return customNanoId();
  }

  randomNumber(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

  sanitizeHtml(input: string): string {
    // const sanitizedHtml: SafeHtml = this.sanitizer.bypassSecurityTrustHtml(input);
    // const plainText: string = sanitizedHtml.toString();
    
    // Remove HTML tags using a regular expression
    const strippedText: string = this.decodeHtmlEntities(input).replace(/<[^>]*>/g, '');
  
    return strippedText;
  }

  decodeHtmlEntities(input: string): string {
    const doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;
  }

  /**
   * Get firestore local timestamp
   */
  get firestoreLocalTime() {
    return firebase.firestore.Timestamp.now();
  }

  /**
   * Get firebase service timestamp
   */
  get firebaseServerTime() {
    return firebase.database.ServerValue.TIMESTAMP;
  }

  /**
   * Get firestore service timestamp
   */
  get firestoreServerTime(): firebase.firestore.FieldValue {
    return firebase.firestore.FieldValue.serverTimestamp();
  }

  get firestoreDeleteField() {
    return firebase.firestore.FieldValue.delete();
  }

  // mergeArrayObj(array1: any[], array2: any[]): any[] {
  //   const mergedArray: any[] = [...array1, ...array2].filter(
  //     (obj, index, self) => index === self.findIndex((o) => this.isEqual(o, obj))
  //   );
  //   return mergedArray;
  // }

  // mergeArray(array1: any[], array2: any[]): any[] {
  //   const mergedArray = Array.from(new Set(array1.concat(array2)));
  //   return mergedArray;
  // }

}
