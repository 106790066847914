import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { WebsiteGallery, WebsiteGalleryLayout } from 'src/app/interfaces/website';
import { FunctionService } from '../general/function.service';
import { WebsiteGallerySample } from 'src/app/commons/website';
import { WebsiteGalleryLayoutType } from 'src/app/types/website';
import { UpdateByService } from '../user/update-by.service';

@Injectable({
  providedIn: 'root'
})
export class WebsiteGalleryService implements OnInit, OnDestroy {

  accountId: string;

  websiteGallery: WebsiteGallery;

  observable: any;

  constructor(
    private afs: AngularFirestore,
    private translate: TranslateService,
    private functionService: FunctionService,
    private updateByService: UpdateByService,
  ) {
    this.observable = new BehaviorSubject<WebsiteGallery>(this.websiteGallery);
  }

  ngOnInit(): void {
      
  }

  ngOnDestroy(): void {
      
  }

  setup(websiteGallery: WebsiteGallery) {
    if (!this.functionService.isEqual(this.websiteGallery, websiteGallery)) {
      this.websiteGallery = websiteGallery;
      Object.keys(this.websiteGallery?.list)?.forEach((mediaId: string) => {
        if (this.websiteGallery.list[mediaId]?.sample && this.websiteGallery.list[mediaId]?.mediaUrl?.url && !this.websiteGallery.list[mediaId]?.thumbnailUrl?.url) {
          this.websiteGallery.list[mediaId].thumbnailUrl = this.websiteGallery.list[mediaId]?.mediaUrl;
          this.websiteGallery.list[mediaId].thumbnailUrl.url = this.websiteGallery.list[mediaId].thumbnailUrl.url.replace('/website/cover/', '/website/cover/thumbnail/').replace('/website/gallery/', '/website/gallery/thumbnail/');
        }
      });
      
      this.observable.next(this.websiteGallery);
    }
  }

  getWebsiteGallery(): WebsiteGallery {
    if (this.websiteGallery) {
      return this.functionService.cloneDeep(this.websiteGallery);
    } else {
      return this.functionService.cloneDeep(this.getDefaultWebsiteGallery());
    }
  }

  getDefaultWebsiteGallery(): WebsiteGallery {
    return {
        title: this.getTitle(),
        msg: '',
        remark: '',
        enable: true,
        layout: this.getDefaultLayout(),
        list: WebsiteGallerySample
    };
  }

  getDefaultLayout(): WebsiteGalleryLayout {
    return {
      type: 'mansory',
      size: 2,
      gap: 2,
      borderRadius: 1,
      shadow: 2,
      thumbnail: true,
      thumbnailPosition: 'top',
      loop: false,
      autoPlay: true,
      backgroundSize: 'auto',
    };
  }

  getDefaultLayoutByType(type: WebsiteGalleryLayoutType): WebsiteGalleryLayout {
    const layout = this.getDefaultLayout();
    if (type) {
      layout.type = type;
    }
    
    return layout;
  }

  getTitle(text?: boolean) {
    const title = this.translate.instant('WEBSITE.gallery.title');
    if (text) {
      return title;
    }
    return '<p class="ql-align-center"><strong class="ql-size-large">' + title + '</strong></p>';
  }

  getMsg() {
    return '';
  }

  getMaxGalleryCount() {
    return 50;
  }

  calculateMaximumGalleryCount(currentCount: number) {
    const maxCount = this.getMaxGalleryCount() - currentCount;
    return maxCount;
  }

  async save(gallery: WebsiteGallery) {
    if (this.accountId && !this.functionService.isEqual(gallery, this.websiteGallery)) {
      gallery.updateBy = this.updateByService.updateBy;
      const ref = this.afs.firestore.doc(`accounts/${ this.accountId }/accountSetting/website`);
      ref.set({ gallery }, { merge: true });
    }
  }
}
