
import { Injectable, OnDestroy } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { TranslateService } from '@ngx-translate/core';
import { Platform } from '@ionic/angular';
import { BehaviorSubject, fromEvent, merge, Subscription } from 'rxjs';

import { PopupService } from 'src/app/services/general/popup.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UrlService } from './url.service';
import { PublicUrl } from 'src/app/commons/url';
/**
 * User online service.
 * Watch connection to Firebase realtime database
 */
@Injectable({
  providedIn: 'root'
})
export class OnlineService implements OnDestroy {

  /**
   * Online status observable
   */
  observableOnline: any;
  /**
   * Online status
   */
  online: boolean;

  private timeout: NodeJS.Timeout | string | number | undefined;

  private pendingWrite: boolean;

  private pendingWriteTimestamp: number;

  // private pendingWriteFlag: boolean;

  private pendingWriteToast: HTMLIonToastElement;

  private pendingWriteCount: number;
  private pendingWriteDimissCount: number;

  /**
   * Ready to watch / observe online flag
   */
  private ready: boolean;
  /**
   * Firebase realtime database connected subscription
   */
  private dbConnectSubscription: Subscription;

  private onlineOfflineEvents: Subscription;

  private urlSubscription: Subscription;

  /**
   * Constructor
   * @param translate Translate
   * @param db Firebase realtime database
   * @param afs Angular Firestore
   * @param popupService Popup service
   * @param errorService Error Service
   * @param deviceService Device Service
   */
  constructor(
    private platform: Platform,
    private translate: TranslateService,
    private db: AngularFireDatabase,
    private afs: AngularFirestore,
    private urlService: UrlService,
    private popupService: PopupService,
  ) {
    this.initialize();
  }

  ngOnDestroy() {
    this.unwatchFirebase();
    this.unwatchOnline();
    this.unwatchUrl();
  }

  /**
   * Initialize online state.
   * Watch Firebase realtime db connected status,
   * Watch device disconnect,
   * Watch switch off tab.
   */
  async initialize() {
    this.ready = false;
    this.online = true;
    this.observableOnline = new BehaviorSubject<boolean>(this.online);

    await this.platform.ready();
    this.watchDbConnected();
    this.watchOnline();
    this.watchUrl();
  }

  async watchOnline() {
    this.pendingWrite = false;
    this.pendingWriteTimestamp = 0;
    this.pendingWriteDimissCount = 0;
    
    const onlineEvent = fromEvent(window, 'online');
    const offlineEvent = fromEvent(window, 'offline');

    // Merge the online and offline event streams
    this.onlineOfflineEvents = merge(onlineEvent, offlineEvent).subscribe(event => {
      if (event.type === 'online') {
        if (!this.online) {
          this.setupOnline(true);
        }
      } else if (event.type === 'offline') {
        if (this.online) {
          this.setupOnline(false);
        }
      }
    });
  }

  async unwatchOnline() {
    if (this.onlineOfflineEvents) {
      this.onlineOfflineEvents.unsubscribe();
      this.onlineOfflineEvents = null;
    }
  }

  /**
   * Watch Firebase realtime database connected status
   */
  async watchDbConnected() {
    if (this.dbConnectSubscription) {
      this.dbConnectSubscription.unsubscribe();
    }
    
    // Using Firebase native connectivity listener
    // try {
    //   const connectedRef = this.db.database.ref('.info/connected');
    //   connectedRef.on('value', (snap) => {
    //     const connected = snap.val() as boolean;
    //     // console.log(connected);
    //     // connected || 
    //     const online = (!window.navigator.onLine || document?.visibilityState === 'hidden') ? false : true;
    //     this.setupOnline(online);
    //   });
    // } catch (err) {
    //   console.error(err);
    // }
    
    
    if (!this.dbConnectSubscription) {
      this.dbConnectSubscription = this.db.object('.info/connected').valueChanges().subscribe((connected: boolean) => {
        const online = !connected || document?.visibilityState === 'hidden' ? false : true;
        this.setupOnline(online);
        // if (!this.ready) {
        //   this.ready = true;
        // }
        // if (!this.online) {
        //   this.preparePendingWrite();
        // }
        // if (this.ready) {
        //   this.observableOnline.next(this.online);
        // }
      });
    }
  }

  setupOnline(online: boolean) {
    if (!this.ready) {
      this.ready = true;
    }

    this.online = online;

    if (!online) {
      // this.preparePendingWrite();
    }

    if (this.ready) {
      this.observableOnline.next(this.online);
    }
  }

  /**
   * Unwatch Firebase realtime database connected status
   */
  async unwatchFirebase() {
    if (this.dbConnectSubscription) {
      this.dbConnectSubscription.unsubscribe();
      this.dbConnectSubscription = null;
      // this.online = false;
      // this.observableOnline.next(this.online);
    }
  }

  /**
   * Reset firebase watch status
   */
  async resetWatchFirebase() {
    await this.unwatchFirebase();
    await this.watchDbConnected();
  }

  /**
   * Online network required, check if is online.
   * @returns true if user is online, popup internet required and return false
   */
  isOnline(): boolean {
    if (this.online) {
      return true;
    } else {
      this.popupService.presentToast(this.translate.instant('ONLINE.msg.internet_required'), 'danger');
      return false;
    }
  }

  async checkPendingWrites() {
    if (!this.online && !this.pendingWrite) {
      this.pendingWrite = true;
      this.pendingWriteTimestamp = Date.now();
      this.afs.firestore.waitForPendingWrites().then(() => {
        this.dimissPendingWriteToast();

        this.pendingWrite = false;
        this.pendingWriteTimestamp = 0;
        this.pendingWriteDimissCount = 0;

        // this.preparePendingWrite();
      }).catch((err) => {
        console.error(err);
        // console.error('Error waiting for pending writes: ', error);
      });
    }
  }

  async preparePendingWrite() {
    try {
      if (!this.online) {
        if (this.timeout) {
          clearTimeout(this.timeout);
          this.timeout = null;
        }

        if (this.pendingWrite && this.pendingWriteTimestamp) {
          const now = Date.now();
          const diff = now - this.pendingWriteTimestamp;
          if (diff > 5000) {
            await this.presentPendingWriteToast();
            this.pendingWriteCount += 1;
          }
        } else {
          await this.checkPendingWrites();
        }
        this.timeout = setTimeout(() => {
          this.preparePendingWrite();
        }, 5000);
      }
    } catch(err) {
      console.error(err);
    }
  }

  async presentPendingWriteToast() {
    // if (!this.pendingWriteToast) {
    //   const msg = this.translate.instant('ONLINE.msg.pending');

    //   if (this.pendingWriteDimissCount > 0) {
    //     const now = Date.now();
    //     const diff = now - this.pendingWriteTimestamp;
    //     if (diff > (10000 * this.pendingWriteCount)) {
    //       const pendingWriteToast = await this.popupService.presentToast(msg, 'danger', true, 0, 'bottom', true, 'reload-outline');
    //       this.pendingWriteToast = pendingWriteToast;
    //       this.pendingWriteTimestamp = now;

    //       if (this.urlService.checkUrl(PublicUrl)) {
    //         this.dimissPendingWriteToast();
    //       }
    //       pendingWriteToast.onDidDismiss().then(() => {
    //         this.pendingWriteToast = null;
    //         this.pendingWriteDimissCount += 1;
    //         this.unwatchFirebase();
    //       });
    //     }
    //   } else {
    //     const pendingWriteToast = await this.popupService.presentToast(msg, 'danger', true, 0, 'bottom', true, 'reload-outline');
    //     this.pendingWriteToast = pendingWriteToast;
        
    //     if (this.urlService.checkUrl(PublicUrl)) {
    //       this.dimissPendingWriteToast();
    //     }
        
    //     pendingWriteToast.onDidDismiss().then(() => {
    //       this.pendingWriteToast = null;
    //       this.pendingWriteDimissCount += 1;
    //       this.unwatchFirebase();
    //     });
    //   }
    // }
  }

  async dimissPendingWriteToast() {
    if (this.pendingWriteToast) {
      await this.pendingWriteToast.dismiss();
      await this.popupService.dismissAllToast();
      this.pendingWriteToast = null;
    }
  }

  /**
   * Watch URL
   */
  async watchUrl() {
    if (!this.urlSubscription) {
      this.urlSubscription = this.urlService.observableUrl.subscribe((url: string) => {
        if (this.pendingWriteToast && url) {
          if (this.urlService.checkUrl(PublicUrl)) {
            this.dimissPendingWriteToast();
          }
        }
      });
    }
  }

  async unwatchUrl() {
    if (this.urlSubscription) {
      this.urlSubscription.unsubscribe();
      this.urlSubscription = null;
    }
  }
}
