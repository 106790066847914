import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { WebsiteQrcode } from 'src/app/interfaces/website';
import { FunctionService } from '../general/function.service';
import { UpdateByService } from '../user/update-by.service';

@Injectable({
  providedIn: 'root'
})
export class WebsiteQrcodeService implements OnInit, OnDestroy {

  accountId: string;

  websiteQrcode: WebsiteQrcode;

  observable: any;

  constructor(
    private afs: AngularFirestore,
    private translate: TranslateService,
    private functionService: FunctionService,
    private updateByService: UpdateByService,
  ) {
    this.observable = new BehaviorSubject<WebsiteQrcode>(this.websiteQrcode);
  }

  ngOnInit(): void {
      
  }

  ngOnDestroy(): void {
      
  }

  setup(websiteQrcode: WebsiteQrcode) {
    if (!this.functionService.isEqual(this.websiteQrcode, websiteQrcode)) {
      this.websiteQrcode = websiteQrcode;
      this.observable.next(this.websiteQrcode);
    }
  }

  getWebsiteQrcode(): WebsiteQrcode {
    if (this.websiteQrcode) {
      return { ...this.websiteQrcode };
    } else {
      return {
        title: this.getTitle(),
        msg: this.getMsg(),
        btn: this.getButton(),
        remark: '',
        enable: true,
        media: {
          url: '',
          type: '',
          mimeType: '',
        },
      };
    }
  }

  getTitle(text?: boolean) {
    const title = this.translate.instant('WEBSITE.qrcode.title');
    if (text) {
      return title;
    }
    return '<p class="ql-align-center"><strong class="ql-size-large">' + title + '</strong></p>';
  }

  getButton() {
    return this.translate.instant('WEBSITE.qrcode.btn');
  }

  getMsg(text?: boolean) {
    const msg = this.translate.instant('WEBSITE.qrcode.msg');
    if (text) {
      return msg;
    }
    return '<p class="ql-align-center">' + msg + '</p>';
  }

  async save(qrcode: WebsiteQrcode) {
    if (this.accountId && !this.functionService.isEqual(qrcode, this.websiteQrcode)) {
      qrcode.updateBy = this.updateByService.updateBy;
      const ref = this.afs.firestore.doc(`accounts/${ this.accountId }/accountSetting/website`);
      ref.set({ qrcode }, { merge: true });
    }
  }
}
