import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DateTimeService } from 'src/app/services/general/date-time.service';

@Component({
  selector: 'app-date-time-format',
  templateUrl: './date-time-format.component.html',
  styleUrls: ['./date-time-format.component.scss'],
})
export class DateTimeFormatComponent  implements OnInit, OnDestroy {

  selected: string;
  type: string;
  locale: string;
  time: number;

  formatList: { text: string, format: string }[] = [];
  
  constructor(
    private modalController: ModalController,
    private dateTimeService: DateTimeService,
  ) { }

  ngOnInit() {}

  ngOnDestroy(): void {
      
  }

  ionViewWillEnter() {
    const { formatList, selected } = this.dateTimeService.generateFormatList(this.type, this.locale, this.selected, this.time);
    this.formatList = formatList;
    // this.selected = selected;

  }

  async dismissModal(format?: string) {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { this.modalController.dismiss({ format }); }
    }
  }

  select(format: string) {
    this.dismissModal(format);
  }

}
