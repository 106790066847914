import { GroupEditComponent } from './components/group/group-edit/group-edit.component';
import { AccountLoginListComponent } from './components/account/account-login-list/account-login-list.component';
import { UserWelcomeComponent } from './components/user/user-welcome/user-welcome.component';
import { CheckinBulkComponent } from './components/checkin/checkin-bulk/checkin-bulk.component';
import { GroupLogComponent } from './components/log/group-log/group-log.component';
import { GiftLogComponent } from './components/log/gift-log/gift-log.component';
import { SettingGiftComponent } from 'src/app/components/setting/setting-gift/setting-gift.component';
import { GuestProfileComponent } from './components/guest/guest-profile/guest-profile.component';
import { AccountPrivilegeComponent } from './components/account/account-privilege/account-privilege.component';

import { GiftCardTemplateComponent } from './components/subscription/gift-card-template/gift-card-template.component';
import { DownloadAppComponent } from './components/general/download-app/download-app.component';
import { AccountShareComponent } from './components/account/account-share/account-share.component';
import { AccountInfoComponent } from './components/account/account-info/account-info.component';
import { GiftPromptComponent } from './components/gift/gift-prompt/gift-prompt.component';
import { NgModule, ErrorHandler, Injectable, enableProdMode, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouteReuseStrategy, Router } from '@angular/router';

import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule, SETTINGS } from '@angular/fire/compat/firestore';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { AngularFireFunctionsModule, REGION, ORIGIN } from '@angular/fire/compat/functions';
import { AngularFireRemoteConfigModule, DEFAULTS } from '@angular/fire/compat/remote-config';
import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService } from '@angular/fire/compat/analytics';
import { AngularFirePerformanceModule, PerformanceMonitoringService } from '@angular/fire/compat/performance';
// import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgCircleProgressModule } from 'ng-circle-progress';

import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';

import { IonicModule, IonicRouteStrategy, Platform } from '@ionic/angular';
// import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
// import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
// import { WebView } from '@awesome-cordova-plugins/ionic-webview/ngx';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
// import { InAppPurchase2 } from '@awesome-cordova-plugins/in-app-purchase-2/ngx';
import { IsDebug } from '@awesome-cordova-plugins/is-debug/ngx';
import { AppAvailability } from '@awesome-cordova-plugins/app-availability/ngx';
import { NativeGeocoder } from '@awesome-cordova-plugins/native-geocoder/ngx';
import { Calendar } from '@awesome-cordova-plugins/calendar/ngx';

import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { CountdownModule } from 'ngx-countdown';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxStripeModule } from 'ngx-stripe';

import { QuillModule } from 'ngx-quill';

import { AppRoutingModule } from 'src/app/app-routing.module';
import { AppComponent } from 'src/app/app.component';

import { Ng2FittextModule } from "ng2-fittext";
import { NgxFitTextModule } from '@pikselin/ngx-fittext';
import { NgxCaptchaModule } from 'ngx-captcha';


import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule, SwRegistrationOptions } from '@angular/service-worker';

// import { NgxMasonryModule } from 'ngx-masonry';
import { GALLERY_CONFIG, GalleryConfig } from 'ng-gallery';
import { LIGHTBOX_CONFIG, LightboxConfig } from 'ng-gallery/lightbox';
// import { Angular2ImageGalleryModule } from 'angular2-image-gallery';

import { ErrorService } from 'src/app/services/general/error.service';

import { UserAvatarComponent } from 'src/app/components/user/user-avatar/user-avatar.component';
import { AccountRoleComponent } from 'src/app/components/account/account-role/account-role.component';
import { AccountCoupleComponent } from 'src/app/components/account/account-couple/account-couple.component';
import { AccountUserComponent } from 'src/app/components/account/account-user/account-user.component';
import { SettingFieldFilterComponent } from 'src/app/components/setting/setting-field-filter/setting-field-filter.component';

import { SettingCheckinComponent } from 'src/app/components/setting/setting-checkin/setting-checkin.component';

import { GuestStatisticComponent } from 'src/app/components/guest/guest-statistic/guest-statistic.component';
import { CheckinProfileComponent } from 'src/app/components/checkin/checkin-profile/checkin-profile.component';
import { GuestEditComponent } from 'src/app/components/guest/guest-edit/guest-edit.component';
import { GuestLogComponent } from 'src/app/components/log/guest-log/guest-log.component';
import { GuestGroupComponent } from 'src/app/components/group/guest-group/guest-group.component';
import { GroupChangeComponent } from 'src/app/components/group/group-change/group-change.component';
import { GroupChangeValueComponent } from 'src/app/components/group/group-change-value/group-change-value.component';
import { GuestQrcodeComponent } from 'src/app/components/qrcode/guest-qrcode/guest-qrcode.component';
import { QrcodeTemplateComponent } from 'src/app/components/qrcode/qrcode-template/qrcode-template.component';
import { GuestListFilterComponent } from 'src/app/components/guest/guest-list-filter/guest-list-filter.component';
import { SeatingNewComponent } from 'src/app/components/seating/seating-new/seating-new.component';
import { SeatingEditComponent } from 'src/app/components/seating/seating-edit/seating-edit.component';
import { SeatingDetailComponent } from 'src/app/components/seating/seating-detail/seating-detail.component';
import { SeatingListFilterComponent } from 'src/app/components/seating/seating-list-filter/seating-list-filter.component';
import { GiftListFilterComponent } from 'src/app/components/gift/gift-list-filter/gift-list-filter.component';

import { CropComponent } from 'src/app/components/general/crop/crop.component';
import { BulkActionComponentsModule } from 'src/app/components/general/bulk-action/bulk-action.components.module';
import { SelectAllComponentsModule } from 'src/app/components/general/select-all/select-all.components.module';
import { SearchComponentsModule } from 'src/app/components/general/search/search.components.module';
import { OfflineComponentsModule } from 'src/app/components/general/offline/offline.components.module';
import { GuestComponentsModule } from 'src/app/components/guest/guest.components.module';
import { AlertComponent } from 'src/app/components/general/prompt/alert/alert.component';
import { ConfirmComponent } from 'src/app/components/general/prompt/confirm/confirm.component';
import { InputComponent } from 'src/app/components/general/prompt/input/input.component';

// import { environment as environmentDev } from 'src/environments/environment';
import { environment } from 'src/environments/environment.prod';
import { GiftComponentsModule } from './components/gift/gift.components.module';
import { AccountsComponentsModule } from './components/accounts/accounts.components.module';
import { UserSetupComponent } from './components/user/user-setup/user-setup.component';
import { AccountComponentsModule } from './components/account/account.components.module';
import { ProductAccountDetailComponent } from './components/subscription/product-account-detail/product-account-detail.component';
import { CachedImageComponentsModule } from './components/general/cached-image/cached-image.components.module';

import { CheckinBulkGiftComponent } from './components/checkin/checkin-bulk-gift/checkin-bulk-gift.component';
import { GeneralComponentsModule } from './components/general/general.components.module';
import { LoginComponentsModule } from './components/login/login.components.module';
import { LocationComponent } from './components/general/location/location.component';
import { NewsCardComponentsModule } from './components/news-card/news-card.components.module';
// // import { SwiperModule } from 'swiper/angular';

// import function to register Swiper custom elements
import { register } from 'swiper/element/bundle';
// register Swiper custom elements
register();

import { ScrollingModule } from '@angular/cdk/scrolling';
import { ScrollingModule as ExperimentalScrollingModule } from '@angular/cdk-experimental/scrolling';

import { SettingComponentsModule } from './components/setting/setting.components.module';
import { SettingFieldComponent } from './components/setting/setting-field/setting-field.component';
import { InterceptorService } from './services/general/interceptor-service.service';

import { ChromeComponent } from './components/general/chrome/chrome.component';
import { NgxQrcodeStylingModule } from 'ngx-qrcode-styling';
import { UserReceiptComponent } from './components/user/user-receipt/user-receipt.component';
import { PromoComponent } from './components/general/promo/promo.component';

// import { Capacitor } from '@capacitor/core';
import { SettingFieldNewComponent } from './components/setting/setting-field-new/setting-field-new.component';


import * as SentryAngular from '@sentry/angular';
import { InboxDetailComponent } from './components/inbox/inbox-detail/inbox-detail.component';
import { InboxComponentsModule } from './components/inbox/inbox.components.module';

import { AccountRoleNewComponent } from './components/account/account-role-new/account-role-new.component';
import { WebsiteComponentsModule } from './components/website/website.components.module';

import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { Capacitor } from '@capacitor/core';
import { ExportQrcodeComponent } from './components/qrcode/export-qrcode/export-qrcode.component';

/**
 * Custom error handler by Sentry
 */
@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  /**
   * Constructor
   * @param platform Platform
   * @param errorService Error handler service
   */
  constructor(
    private platform: Platform,
    private errorService: ErrorService,
  ) {}

  /**
   * General error handler
   * @param error Error obj
   */
  async handleError(error: any): Promise<void> {
    await this.platform.ready();
    await this.errorService.logError(error);
  }
}
// let environment = environmentProd;
// if (isDevMode()) {
//   environment = environmentDev;
// } else {
//   environment = environmentProd;
// }

if (environment.production) {
  enableProdMode();
}

/**
 * Load internationalization translation file
 */
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/wedding/i18n/', '.json');
}

// if (environment.emulator) {
//   environment.firebase.databaseURL = 'http://localhost:9000/?ns=thebigday-wedding';
// }

@NgModule({
  declarations: [
    AppComponent,
    AlertComponent,
    ConfirmComponent,
    InputComponent,
    LocationComponent,
    DownloadAppComponent,
    ChromeComponent,
    ProductAccountDetailComponent,
    // LoginPhoneComponent,
    CropComponent,
    UserSetupComponent,
    UserWelcomeComponent,
    UserAvatarComponent,
    UserReceiptComponent,
    GiftCardTemplateComponent,
    AccountRoleComponent,
    AccountRoleNewComponent,
    AccountCoupleComponent,
    AccountUserComponent,
    AccountInfoComponent,
    AccountShareComponent,
    AccountPrivilegeComponent,
    AccountLoginListComponent,
    SettingFieldFilterComponent,
    GuestProfileComponent,
    GuestGroupComponent,
    GroupChangeComponent,
    GroupChangeValueComponent,
    GroupEditComponent,
    GroupLogComponent,
    CheckinProfileComponent,
    CheckinBulkGiftComponent,
    CheckinBulkComponent,
    GuestEditComponent,
    GuestQrcodeComponent,
    GuestLogComponent,
    QrcodeTemplateComponent,
    ExportQrcodeComponent,
    SeatingNewComponent,
    // SeatingViewComponent,
    SeatingEditComponent,
    SeatingDetailComponent,
    GuestListFilterComponent,
    SeatingListFilterComponent,
    GiftPromptComponent,
    GiftListFilterComponent,
    GiftLogComponent,
    GuestStatisticComponent,
    SettingFieldComponent,
    SettingFieldNewComponent,
    // SettingSeatingComponent,
    SettingCheckinComponent,
    SettingGiftComponent,
    PromoComponent,
    InboxDetailComponent,

    // SmsTemplateManageComponent,
  ],
  // entryComponents: [
  //   AlertComponent,
  //   ConfirmComponent,
  //   InputComponent,
  //   LoginPhoneComponent,
  //   CropComponent,
  //   UserAvatarComponent,
  //   UserWelcomeComponent,
  //   UserReceiptComponent,
  //   AccountRoleComponent,
  //   AccountCoupleComponent,
  //   AccountUserComponent,
  //   SettingFieldFilterComponent,
  //   GuestProfileComponent,
  //   GuestGroupComponent,
  //   GroupChangeComponent,
  //   GroupChangeValueComponent,
  //   GroupLogComponent,
  //   CheckinProfileComponent,
  //   CheckinBulkGiftComponent,
  //   CheckinBulkComponent,
  //   GuestEditComponent,
  //   GuestQrcodeComponent,
  //   GuestLogComponent,
  //   QrcodeTemplateComponent,
  //   SeatingNewComponent,
  //   SeatingEditComponent,
  //   SeatingDetailComponent,
  //   GuestListFilterComponent,
  //   SeatingListFilterComponent,
  //   GiftListFilterComponent,
  //   GiftPromptComponent,
  //   GiftLogComponent,
  //   GuestStatisticComponent,
  //   SettingFieldComponent,
  //   SettingFieldNewComponent,
  //   SettingCheckinComponent,
  //   SettingGiftComponent,
  //   PromoComponent,
  // ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule.forRoot({
      backButtonText: '',
      mode: 'ios',
      hardwareBackButton: true,
      swipeBackEnabled: false,
      backButtonIcon: './assets/wedding/icon/general/chevron-back.svg',
      scrollAssist: true,
      scrollPadding: false,
      innerHTMLTemplatesEnabled: true,
    }),
    QuillModule.forRoot({
      modules: {
        syntax: false,
        // defaultEmptyValue: '',
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'], 
          ['blockquote', 'code-block'],
          [{ size: [ 'small', false, 'large', 'huge']}],
          [{ 'align': [] }, { 'list': 'ordered'}, { 'list': 'bullet' }],
          // [{ 'script': 'sub'}, { 'script': 'super' }],
          // ['link'],
          ['clean'],
        ]
      }
    }),
    NgxStripeModule.forRoot(''),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule.enablePersistence({ synchronizeTabs: true }),
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    AngularFireFunctionsModule,
    AngularFireStorageModule,
    AngularFireMessagingModule,
    AngularFireRemoteConfigModule,
    AngularFireAnalyticsModule,
    AngularFirePerformanceModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      useDefaultLang: true,
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [ HttpClient ],
      },
    }),
    NgxIntlTelInputModule,
    NgCircleProgressModule.forRoot(),
    CountdownModule,
    Ng2FittextModule,
    NgxFitTextModule,
    NgxCaptchaModule,
    // NgxQRCodeModule,
    NgxQrcodeStylingModule,
    ImageCropperModule,
    // IonicSelectableModule,
    // Angular2ImageGalleryModule,
    // NgxMasonryModule,
    HammerModule,
    // // SwiperModule,
    // SwiperDirective,
    ScrollingModule,
    ExperimentalScrollingModule,
    GeneralComponentsModule,
    OfflineComponentsModule,
    CachedImageComponentsModule,
    SettingComponentsModule,
    LoginComponentsModule,
    AccountsComponentsModule,
    NewsCardComponentsModule,
    AccountComponentsModule,
    GuestComponentsModule,
    GiftComponentsModule,
    SearchComponentsModule,
    SelectAllComponentsModule,
    BulkActionComponentsModule,
    InboxComponentsModule,
    WebsiteComponentsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgxExtendedPdfViewerModule,
    ServiceWorkerModule.register('./combined-sw.js', { enabled: !Capacitor.isNativePlatform() }),
  ],
  providers: [
    // StatusBar,
    // SplashScreen,
    FileOpener,
    IsDebug,
    AppAvailability,
    NativeGeocoder,
    Calendar,
    // WebView,

    // InAppPurchase2,http
    ScreenTrackingService,
    UserTrackingService,
    PerformanceMonitoringService,
    {
      provide: SETTINGS, 
      useValue: {
        cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED,
        experimentalAutoDetectLongPolling: true, 
        merge: true, 
      }
    },
    {
      provide: SwRegistrationOptions,
      useFactory: () => ({ enabled: environment.production }),
    },
    { provide: REGION, useValue: 'asia-northeast1' },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    {
      provide: SentryAngular.TraceService,
      deps: [ Router ],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [ SentryAngular.TraceService ],
      multi: true,
    },
    {
      provide: GALLERY_CONFIG,
      useValue: {
        // autoHeight: true,
        thumbView: 'contain',
        imageSize: 'contain'
      } as GalleryConfig
    },
    {
      provide: LIGHTBOX_CONFIG,
      useValue: {
        keyboardShortcuts: false,
        exitAnimationTime: 1000,
        hasBackdrop: true,
      } as LightboxConfig
    }
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  bootstrap: [AppComponent],
})
export class AppModule {}
