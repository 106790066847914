import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MediaType } from 'src/app/interfaces/general';
import { WebsiteContentMediaList } from 'src/app/interfaces/website';

@Injectable({
  providedIn: 'root'
})
export class WebsiteContentMediaService implements OnInit, OnDestroy {

  mediaList: WebsiteContentMediaList;

  observableMediaList: any;

  empty: MediaType = {
    url: '',
    type: '',
    mimeType: '',
  }
  
  constructor() {
    this.observableMediaList = new BehaviorSubject<WebsiteContentMediaList>(this.mediaList);
  }
  
  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  setup(contentId: string, media: MediaType) {
    if (!this.mediaList) {
      this.mediaList = {};
    }
    if (contentId) {
      if (media) {
        this.mediaList[contentId] = media;
      } else {
        this.mediaList[contentId] = { ...this.empty };
      }
      this.observableMediaList.next(this.mediaList);
    }
  }
  
}
