import { MobileService } from 'src/app/services/general/mobile.service';
import { TranslateService } from '@ngx-translate/core';
import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Platform, ModalController } from '@ionic/angular';
import { PhoneNumberType } from 'google-libphonenumber';
import { SearchCountryField, CountryISO } from 'ngx-intl-tel-input';
import { Mobile } from 'src/app/interfaces/general';
import { LocalityService } from 'src/app/services/location/locality.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-mobile',
  templateUrl: './mobile.component.html',
  styleUrls: ['./mobile.component.scss'],
})
export class MobileComponent implements OnInit, OnDestroy {
  
  msg: string;
  modalMode: boolean;
  form: FormGroup;
  validationMsg: any;
  /**
   * Ngx intl tel variable
   */
  /**
   * Ready state after recaptcha and mobile input loaded.
   */
  ready: boolean;
  /**
   * Separate Dial Code
   */
  separateDialCode = false;
  /**
   * Search Country Field
   */
  SearchCountryField = SearchCountryField;
  /**
   * Country ISO
   */
  CountryISO = CountryISO;
  /**
   * Preferred Countries
   */
  preferredCountries: CountryISO[] = [];
  /**
   * Selected Country ISO
   */
  selectedCountryISO: CountryISO;

  invalidMobile: boolean;
  mobile: Mobile;

  @Input() ignoreType: boolean;

  @Input() otpMode: boolean;

  @Input() required: boolean;

  @Input() lbl: string;

  @Input() requiredMsg: string;
  @Input() invalidMsg: string;

  @Input() set setCssClass(cssClass: string) {
    this.setupCssClass(cssClass);
  }

  @Input() set setMobile(mobile: Mobile) {
    if (mobile?.no && mobile?.code) {
      if (mobile?.no !== this.mobile?.no || mobile.code !== this.mobile?.code) {
        this.mobile = mobile;
        this.setupFormValue();
      }
    } else {
      this.mobile = {
        code: 0,
        country: '',
        no: ''
      };
      this.setupFormValue();
    }
  }
  
  @Output() outputMobile = new EventEmitter<Mobile>();
  @Output() outputMobileInvalid = new EventEmitter<boolean>();

  cssClass: string;

  private phoneSubscription: Subscription;

  constructor(
    private platform: Platform,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private modalController: ModalController,
    private localityService: LocalityService,
    private mobileService: MobileService,
  ) { }

  ngOnInit() {
    this.initialize();
  }

  ngOnDestroy() {
    this.unwatchPhoneChanges();
  }

  ionViewWillEnter() {
    this.setupCssClass(this.cssClass ? this.cssClass : '');
  }

  initialize() {
    this.setupPhoneCountry();
    this.setupForm();
  }

  /**
   * Setup form
   */
   setupForm() {
    this.form = this.formBuilder.group({
      mobile: new FormControl('', [ this.required ? Validators.required : Validators.nullValidator ]),
    });

    this.validationMsg = {
      mobile: [
        { type: 'invalid_format', msg: this.invalidMsg ? this.invalidMsg : this.translate.instant('VALIDATION.invalid_format', { field: this.translate.instant('MOBILE.lbl.no') }) },
        { type: 'required', msg: this.requiredMsg ? this.requiredMsg : this.translate.instant('VALIDATION.required', { field: this.translate.instant('MOBILE.lbl.no') }) },
      ],
    };

    this.setupFormValue();
    this.phoneChanges();
  }

  /**
   * Setup form vale
   */
  setupFormValue() {
    if (this.form && this.mobile?.no) {
      // const no = this.mobileService.generateE164Format(this.mobile.no);
      this.form.controls.mobile.setValue(this.mobile.no);
    }
  }

  setupCssClass(cssClass: string) {
    if (cssClass) {
      this.cssClass = cssClass;
    } else {
      this.cssClass = 'phone-input background-white';
    }
  }

  /**
   * Setup default country for phone input
   */
  async setupPhoneCountry() {
    await this.platform.ready();
    const country = this.localityService.getUserCountry();
    if (country?.name && CountryISO[country.name]) {
      this.selectedCountryISO = CountryISO[country.name];
      if (this.preferredCountries.indexOf(CountryISO[country.name]) === -1) {
        this.preferredCountries.push(CountryISO[country.name]);
      }
    } else {
      this.selectedCountryISO = CountryISO.Singapore;
    }
    this.ready = true;
  }

  /**
   * Watch mobile input changes
   */
  async phoneChanges() {
    if (!this.phoneSubscription) {
      this.phoneSubscription = this.form?.get('mobile').valueChanges.subscribe((mobile: any) => {
        this.validatePhone(mobile);
      });
    }
    
  }

  async unwatchPhoneChanges() {
    if (this.phoneSubscription) {
      this.phoneSubscription.unsubscribe();
      this.phoneSubscription = null;
    }
  }

  /**
   * Validate mobile no
   * @param mobile Mobile no
   */
  async validatePhone(mobile: any) {
    this.invalidMobile = false;
    this.outputMobileInvalid.next(false);
    if (mobile) {
      if (mobile.e164Number) {
        const no = mobile.e164Number;
        const rawFormat = this.mobileService.generateRawFormat(no);
        const numberType = this.mobileService.generateNumberType(rawFormat);
        const code = rawFormat.getCountryCode();
        const country = mobile?.countryCode ? mobile.countryCode : this.mobileService.getCountryByPhoneCode(code);

        if (no) {
          if (this.ignoreType && numberType !== PhoneNumberType.UNKNOWN) {
            const result: Mobile = {
              code,
              country,
              no,
            };
            this.mobile = result;
            this.outputMobile.next(result);
          } else if (numberType === PhoneNumberType.MOBILE || numberType === PhoneNumberType.FIXED_LINE_OR_MOBILE) {
            const result: Mobile = {
              code,
              country,
              no,
            };
            this.mobile = result;
            this.outputMobile.next(result);
          } else if (no && no.indexOf('+6010') === 0 && no.slice(3).length === 10) {
            const result: Mobile = {
              code: 60,
              country: 'MY',
              no,
            };
            this.mobile = result;
            this.outputMobile.next(result);
          } else {
            this.form.controls.mobile.setErrors({invalid_format: true});
            this.invalidMobile = true;
            this.outputMobileInvalid.next(true);
          }
        } else {
          this.form.controls.mobile.setErrors({invalid_format: true});
          this.invalidMobile = true;
          this.outputMobileInvalid.next(true);
        }
        
      } else {
        this.form.controls.mobile.setErrors({invalid_format: true});
        this.invalidMobile = true;
        this.outputMobileInvalid.next(true);
      }
    } else {
      const mobile: Mobile = {
        code: 0,
        country: '',
        no: '',
      };
      this.outputMobile.next(mobile);
    }
  }

  async dismissModal(mobile?: Mobile) {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { await modal.dismiss({ mobile }); }
    }
  }

  async save() {
    this.form?.markAllAsTouched();
    if (this.form.valid && !this.invalidMobile && this.mobile?.no) {
      this.dismissModal(this.mobile);
    }
  }

}
