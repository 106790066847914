import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { GalleryItem, ImageItem } from 'ng-gallery';
import { WebsiteGallery, WebsiteMedia, WebsiteMediaList } from 'src/app/interfaces/website';
import { FunctionService } from 'src/app/services/general/function.service';


@Component({
  selector: 'app-website-gallery',
  templateUrl: './website-gallery.component.html',
  styleUrls: ['./website-gallery.component.scss'],
})
export class WebsiteGalleryComponent  implements OnInit, OnDestroy {

  @Input() set setReady(ready: boolean) {
    if (ready) {
      this.setup();
    }
  }

  @Input() previewMode: boolean;
  @Input() pageMode: boolean;

  @Input() set setWebsiteGallery(websiteGallery: WebsiteGallery) {
    this.websiteGallery = websiteGallery;
    this.title = this.getContent(websiteGallery?.title);
    this.msg = this.getContent(websiteGallery?.msg);
  }

  @Input() mediaHeight: number;

  websiteGallery: WebsiteGallery;
  title: string;
  msg: string;

  websiteGalleryList: WebsiteMediaList;
  list: WebsiteMedia[];
  items: GalleryItem[];

  constructor(
    private modalController: ModalController,
    private functionService: FunctionService,
  ) { }

  ngOnInit() {}

  ngOnDestroy(): void {
      
  }

  ionViewWillEnter() {
    this.setup();
  }

  ionViewWillLeave() {

  }

  setup() {
    const title = this.websiteGallery?.title;
    const msg = this.websiteGallery?.msg;

    if (title) {
      if (!this.functionService.sanitizeHtml(title).trim()) {
        this.websiteGallery.title = '';
      }
    }

    if (msg) {
      if (!this.functionService.sanitizeHtml(msg).trim()) {
        this.websiteGallery.msg = '';
      }
    }
    this.setupList();
  }

  setupList() {
    if (!this.websiteGallery?.list && this.websiteGalleryList) {
      this.websiteGallery.list = this.websiteGalleryList;
    }
    if (!this.websiteGalleryList && this.websiteGallery?.list) {
      this.websiteGalleryList = this.websiteGallery.list;
    }

    const list = this.websiteGalleryList ? Object.values(this.websiteGalleryList) : [];
    this.list = list?.filter((media: WebsiteMedia) => {
      return media?.mediaUrl?.url;
    }).sort((a: WebsiteMedia, b: WebsiteMedia) => {
      return this.functionService.compare(a.order, b.order);
    });

    if (this.websiteGallery?.layout?.type === 'carousel') {
      this.items = [];
      this.list.forEach((media: WebsiteMedia) => {
        if (media?.mediaUrl?.url) {
          this.items.push(new ImageItem({
            src: media.mediaUrl.url,
            thumb: media?.thumbnailUrl?.url ? media.thumbnailUrl.url : media.mediaUrl.url,
          }));
        }
      });
    }
  }

  async back() {
    this.dismissModal();
  }

  async dismissModal() {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { this.modalController.dismiss(); }
    }
  }

  getContent(msg: string) {
    if (msg) {
      if (!this.functionService.sanitizeHtml(msg).trim()) {
        return '';
      }
    }
    return msg;
  }

  // getPhoto(mediaId: string): WebsiteMedia {
  //   return this.websiteGalleryList?.[mediaId];
  // }

}
