import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActionSheetController, ModalController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { SettingFieldComponent } from 'src/app/components/setting/setting-field/setting-field.component';
import { SettingField } from 'src/app/interfaces/database';
import { UpdateBy } from 'src/app/interfaces/user';
import { WebsiteForm, WebsiteLink } from 'src/app/interfaces/website';
import { AccountUserService } from 'src/app/services/account/account-user.service';
import { FunctionService } from 'src/app/services/general/function.service';
import { PopupService } from 'src/app/services/general/popup.service';
import { SettingFieldService } from 'src/app/services/setting/setting-field.service';
import { UpdateByService } from 'src/app/services/user/update-by.service';
import { WebsiteDetailService } from 'src/app/services/website/website-detail.service';
import { WebsiteLinksService } from 'src/app/services/website/website-links.service';
import { SettingFieldType } from 'src/app/types/general';

import { Clipboard } from '@capacitor/clipboard';
import { Share } from '@capacitor/share';
import { ErrorService } from 'src/app/services/general/error.service';
import { AccountInfoService } from 'src/app/services/account/account-info.service';
import { DynamicUrl } from 'src/app/commons/url';
import { WebsiteFormService } from 'src/app/services/website/website-form.service';
import { DateTimeService } from 'src/app/services/general/date-time.service';
import { AccountUser } from 'src/app/interfaces/account';
import { InvitedByService } from 'src/app/services/setting/invited-by.service';
import { CategoryService } from 'src/app/services/setting/category.service';
import { WebsiteLinkEditComponent } from '../website-link-edit/website-link-edit.component';
import { WebsiteLinkService } from 'src/app/services/website/website-link.service';
import { WebsiteSettingFieldService } from 'src/app/services/website/website-setting-field.service';
import { PrivilegeService } from 'src/app/services/account/privilege/privilege.service';

@Component({
  selector: 'app-website-link',
  templateUrl: './website-link.component.html',
  styleUrls: ['./website-link.component.scss'],
})
export class WebsiteLinkComponent  implements OnInit, OnDestroy {

  type: string;

  form: FormGroup;

  validationMsg: any;

  websiteLinks: WebsiteLink[];

  websiteLink: WebsiteLink;

  websiteForm: WebsiteForm;

  hybrid: boolean;

  msg: string;

  websiteUrl: string;

  guestCount: any;
  groupCount: any;

  privilege: boolean;

  settingFieldOnlineReg: SettingField = this.websiteSettingFieldService.settingFieldOnlineReg;
  
  private websiteLinksSubscription: Subscription;
  private guestCountSubscription: Subscription;
  private groupCountSubscription: Subscription;

  constructor(
    private platform: Platform,
    private translate: TranslateService,
    private actionSheetController: ActionSheetController,
    private formBuilder: FormBuilder,
    private modalController: ModalController,
    private accountInfoService: AccountInfoService,
    private accountUserService: AccountUserService,
    private websiteFormService: WebsiteFormService,
    private websiteLinksService: WebsiteLinksService,
    private websiteLinkService: WebsiteLinkService,
    private websiteSettingFieldService: WebsiteSettingFieldService,
    private privilegeService: PrivilegeService,
    private settingFieldService: SettingFieldService,
    private dateTimeService: DateTimeService,
    private updateByService: UpdateByService,
    private functionService: FunctionService,
    private popupService: PopupService,
    private errorService: ErrorService,
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.unwatchWebsiteLinks();
    this.unwatchGuestCount();
    this.unwatchGroupCount();
  }

  ionViewWillEnter() {
    this.hybrid = this.platform.is('hybrid');
    this.websiteForm = this.websiteFormService.getWebsiteForm();

    this.privilege = this.checkPrivilege('invites');

    if (!this.type) {
      if (this.privilege) {
        this.type = 'new';
      } else {
        this.type = 'generated';
      }
    }

   this.setupWebsiteLinks(this.websiteLinksService.websiteLinks);

    this.setupForm();
    this.watchWebsiteLinks();
    this.watchGuestCount();
    this.watchGroupCount();
  }

  ionViewWillLeave() {
    this.unwatchWebsiteLinks();
    this.unwatchGuestCount();
    this.unwatchGroupCount();
  }

  watchWebsiteLinks() {
    if (!this.websiteLinksSubscription) {
      this.websiteLinksSubscription = this.websiteLinksService.observable.subscribe((websiteLinks: WebsiteLink[]) => {
        this.setupWebsiteLinks(websiteLinks);
        // if (this.websiteLink?.linkId && websiteLinks?.[this.websiteLink.linkId] && !this.functionService.isEqual(this.websiteLink, websiteLinks[this.websiteLink.linkId])) {
        //   this.websiteLink = this.functionService.cloneDeep(websiteLinks[this.websiteLink.linkId]);
        //   this.setupFormValue();
        // }
      })
    }
  }

  async unwatchWebsiteLinks() {
    if (this.websiteLinksSubscription) {
      this.websiteLinksSubscription.unsubscribe();
      this.websiteLinksSubscription = null;
    }
  }

  async watchGuestCount() {
    if (!this.guestCountSubscription) {
      this.guestCountSubscription = this.websiteLinksService.observableGuestCount.subscribe((guestCount: any) => {
        this.guestCount = guestCount;
      });
    }
  }

  async watchGroupCount() {
    if (!this.groupCountSubscription) {
      this.groupCountSubscription = this.websiteLinksService.observableGroupCount.subscribe((groupCount: any) => {
        this.groupCount = groupCount;
      });
    }
  }

  async unwatchGuestCount() {
    if (this.guestCountSubscription) {
      this.guestCountSubscription.unsubscribe();
      this.guestCountSubscription = null;
    }
  }

  async unwatchGroupCount() {
    if (this.groupCountSubscription) {
      this.groupCountSubscription.unsubscribe();
      this.groupCountSubscription = null;
    }
  }

  setupWebsiteLinks(websiteLinks: WebsiteLink[]) {
    this.websiteLinks = this.functionService.cloneDeep(websiteLinks.filter((x: WebsiteLink) => {
      return !x.preview;
    }).sort((a: WebsiteLink, b: WebsiteLink) => {
      return this.functionService.compare(a?.createBy?.time?.seconds, b?.createBy?.time?.seconds, true);
    }));
  }

  setupForm() {
    this.form = this.formBuilder.group({
      name: new FormControl(this.getNameValue((this.websiteLinks?.length ? this.websiteLinks.length : 0) + 1), [ Validators.required ]),
      invitedByEnable: new FormControl(true),
      invitedBy: new FormControl('', [ Validators.required ]),
      categoryEnable: new FormControl(false),
      category: new FormControl(''),
      // group: new FormControl(false),
      // maxGuest: new FormControl(1, [ Validators.required ]),
    });

    this.validationMsg = {
      name: [{ type: 'required', msg: this.translate.instant('VALIDATION.required', { field: this.translate.instant('WEBSITE.link.lbl.name') }) }],
      invitedBy: [{ type: 'required', msg: this.translate.instant('VALIDATION.required', { field: this.translate.instant('GUEST.lbl.invited_by') }) }],
      category: [{ type: 'required', msg: this.translate.instant('VALIDATION.required', { field: this.translate.instant('LBL.category') }) }],
      // maxGuest: [
      //   { type: 'required', msg: this.translate.instant('VALIDATION.required', { field: this.translate.instant('WEBSITE.link.lbl.max_guest') }) },
      //   { type: 'min', msg: this.translate.instant('VALIDATION.min_number', { number: 2 }) }
      // ],
    };

    // this.setupFormValue();
  }

  // setupFormValue() {
  //   if (this.form) {
  //     if (!this.functionService.isUndefined(this.websiteLink?.invitedBy)) {
  //       this.form.controls.invitedBy.setValue(this.websiteLink.invitedBy);
  //     } else {
  //       this.form.controls.invitedBy.setValue([]);
  //     }

  //     if (!this.functionService.isUndefined(this.websiteLink?.category)) {
  //       this.form.controls.category.setValue(this.websiteLink.category);
  //     } else {
  //       this.form.controls.category.setValue([]);
  //     }

  //     if (!this.functionService.isUndefined(this.websiteLink?.maxGuest)) {
  //       if (this.websiteLink.maxGuest > 1) {
  //         this.form.controls.group.setValue(true);
  //         this.form.controls.maxGuest.setValue(this.websiteLink.maxGuest);
  //       } else {
  //         this.form.controls.group.setValue(false);
  //         this.form.controls.maxGuest.setValue(1);
  //       }
  //     } else {
  //       this.form.controls.group.setValue(false);
  //       this.form.controls.maxGuest.setValue(1);
  //     }
  
  //     this.form.updateValueAndValidity();
  //   } else {
  //     setTimeout(() => {
  //       this.setupFormValue();
  //     }, 200);
  //   }
  // }

  resetName() {
    this.form?.controls?.name?.setValue(this.getNameValue((this.websiteLinks?.length ? this.websiteLinks.length : 0) + 1));
  }

  getNameValue(id?: number) {
    return this.translate.instant('WEBSITE.link.lbl.name_id', { id });
  }

  toggleInvitedBy() {
    if (this.form?.value?.invitedByEnable) {
      this.form.controls.invitedBy.setValue([]);
      this.form.controls.invitedBy.setValidators(Validators.required);
    } else {
      this.form.controls.invitedBy.setValue([ this.settingFieldOnlineReg ]);
      this.form.controls.invitedBy.setValidators(Validators.nullValidator);
    }
    this.form.controls.invitedBy.updateValueAndValidity();
  }

  toggleCategory() {
    if (this.form?.value?.categoryEnable) {
      this.form.controls.category.setValue([]);
      this.form.controls.category.setValidators(Validators.required);
    } else {
      this.form.controls.category.setValue([ this.settingFieldOnlineReg ]);
      this.form.controls.category.setValidators(Validators.nullValidator);
    }
    this.form.controls.category.updateValueAndValidity();
  }

  // toggleGroup() {
  //   if (this.form?.value?.group) {
  //     this.form.controls.maxGuest.setValue(2);
  //     this.form.controls.maxGuest.setValidators([ Validators.required, Validators.min(2) ]);
  //   } else {
  //     this.form.controls.maxGuest.setValue(1);
  //     this.form.controls.maxGuest.setValidators([ Validators.nullValidator ]);
  //   }
  //   this.form.controls.maxGuest.updateValueAndValidity();
  // }
  
  async back() {
    this.dismissModal();
    // const websiteLink = this.generateData();
    // if ((websiteLink?.linkId && !this.functionService.isEqual(this.websiteLink, websiteLink)) || (!websiteLink?.linkId && (websiteLink?.invitedBy?.length || websiteLink?.category?.length || websiteLink?.maxGuest !== 10))) {
    //   this.confirmDiscard();
    // } else {
    //   this.dismissModal();
    // }
  }

  async confirmDiscard() {
    const actionSheet = await this.actionSheetController.create({
      header: this.translate.instant('DISCARD.msg'),
      buttons: [{
        role: 'destructive',
        text: this.translate.instant('DISCARD.btn.save'),
        icon: 'checkmark-done',
        handler: () => {
          this.saveNewWebsiteLink(true);
        }
      }, {
        text: this.translate.instant('DISCARD.btn.discard'),
        icon: 'trash',
        handler: () => {
          this.dismissModal();
        }
      }, {
        text: this.translate.instant('BTN.cancel'),
        icon: 'close',
        role: 'cancel',
        handler: () => {
        }
      }]
    });
    actionSheet.present();
  }

  async dismissModal(websiteLink?: WebsiteLink) {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { this.modalController.dismiss({ websiteLink }); }
    }
  }

  /**
   * Present setting field cmodal
   * @param settingFieldType Setting field type
   * @param selected Selected setting field
   */
  async presentSettingFieldModal(settingFieldType: SettingFieldType, selected: SettingField[]) {
    const modal = await this.modalController.create({
      component: SettingFieldComponent,
      componentProps: {
        settingFieldType,
        selected
      }
    });
    modal.present();
    modal.onWillDismiss().then((result: any) => {
      if (result?.data?.selected) {
        if (settingFieldType === 'invited_by') {
          if (this.form.value.category !== result.data.selected) {
            this.form.controls.invitedBy.setValue(result.data.selected);
          }
        } else if (settingFieldType === 'category') {
          if (this.form.value.category !== result.data.selected) {
            this.form.controls.category.setValue(result.data.selected);
          }
        } else if (settingFieldType === 'dietary_req') {
          if (this.form.value.dietaryReq !== result.data.selected) {
            this.form.controls.dietaryReq.setValue(result.data.selected);
          }
        } else if (settingFieldType === 'special_req') {
          if (this.form.value.specialReq !== result.data.selected) {
            this.form.controls.specialReq.setValue(result.data.selected);
          }
        } else if (settingFieldType === 'session') {
          if (this.form.value.session !== result.data.selected) {
            this.form.controls.session.setValue(result.data.selected);
          }
        }
      }
    });
  }

  async presentWebsiteLinkEditModal(websiteLink: WebsiteLink) {
    const modal = await this.modalController.create({
      component: WebsiteLinkEditComponent,
      componentProps: {
        websiteLink
      }
    });
    modal.present();
  }

  // addPax() {
  //   this.form.controls.maxGuest.setValue(this.form.value?.maxGuest + 1);
  //   this.form.controls.maxGuest.updateValueAndValidity();
  // }

  // minusPax() {
  //   if (this.form?.value?.maxGuest > 2) {
  //     this.form.controls.maxGuest.setValue(this.form.value.maxGuest - 1);
  //     this.form.controls.maxGuest.updateValueAndValidity();
  //   }
  // }

  // setupMaxGuest() {
  //   if (this.form?.value?.group) {
  //     this.form.controls.maxGuest.setValue(10);
  //   } else {
  //     this.form.controls.maxGuest.setValue(1);
  //   }
  // }

  // checkMaxGuest() {
  //   if (this.form.value.maxGuest) {
  //     if (this.form.value.maxGuest === 1) {
  //       this.form.controls.group.setValue(false);
  //     } else {
  //       this.form.controls.group.setValue(true);
  //     }
  //   }
  // }

  /**
   * Get list of setting field value
   * @param settingFieldType Setting field type
   * @param settingFieldList Setting field list
   * @returns Join of setting field value
   */
  getSettingField(settingFieldType: SettingFieldType, settingFieldList: SettingField[]): string {
    return this.settingFieldService.joinSettingField(settingFieldType, settingFieldList);
  }

  getUserByUid(uid: string): AccountUser {
    if (uid) {
      return this.accountUserService.getUserByUid(uid);
    } else {
      return null;
    }
  }

  getUserNameByUid(uid: string): string {
    if (uid) {
      return this.accountUserService.getUserByUid(uid)?.name;
    } else {
      return null;
    }
  }

  format(timestamp: number, dateTimeFormat?: string, timezone?: string) {
    return this.dateTimeService.format(timestamp, dateTimeFormat, '', timezone);
  }

  getTitle(): string {
    return this.accountInfoService?.accountTitle;
  }
  
  getUrl() {
    if (this.websiteLink?.link?.short) {
      return this.websiteLink.link.short.replace('https://vips.', 'https://wedding.');
    } else if (this.websiteLink?.link?.url) {
      return this.websiteLink.link.url.replace('https://vips.', 'https://wedding.');
    } else if (this.websiteUrl) {
      return this.websiteUrl;
    }
    return '';
  }

  async toggleEnable(websiteLink: WebsiteLink, slidingItem?: any) {
    if (websiteLink?.linkId) {
      if (websiteLink?.enable) {
        const confirm = await this.popupService.presentConfirm(
          this.translate.instant('CRUD.confirm_delete_field', {
            field: this.translate.instant('WEBSITE.link.title.title')
        }));
        confirm.onDidDismiss().then((result: any) => {
          if (result?.data?.confirm) {
            websiteLink.enable = false;
            this.saveWebsiteLink(websiteLink);
          }
        });
      } else {
        const confirm = await this.popupService.presentConfirm(
          this.translate.instant('CRUD.confirm_restore_field', {
            field: this.translate.instant('WEBSITE.link.title.title')
        }));
        confirm.onDidDismiss().then((result: any) => {
          if (result?.data?.confirm) {
            websiteLink.enable = true;
            this.saveWebsiteLink(websiteLink);
          }
        });
      }
    }

    if (slidingItem) {
      slidingItem.close();
    }
  }

  generateData() {
    const updateBy: UpdateBy = this.updateByService.getUpdateBy();
    const websiteLink: WebsiteLink = {
      linkId: this.websiteLink?.linkId ? this.websiteLink.linkId : '',
      accountId: this.websiteLink?.accountId ? this.websiteLink.accountId : this.websiteLinksService.accountId,
      name: this.form?.value?.name,
      invitedBy: this.form?.value?.invitedBy,
      category: this.form?.value?.category,
      // maxGuest: this.form?.value?.maxGuest,
      link: {
        short: this.websiteLink?.link?.short ? this.websiteLink.link.short : '',
        url: this.websiteLink?.link?.url ? this.websiteLink.link.url : '',
      },
      enable: !this.websiteLink?.linkId || this.websiteLink?.enable ? true : false,
      createBy: this.websiteLink?.createBy ? this.websiteLink.createBy : updateBy,
      updateBy: this.websiteLink?.updateBy ? this.websiteLink.updateBy : updateBy,
    };

    if (!websiteLink?.link?.url && websiteLink?.linkId) {
      websiteLink.link.url = this.websiteLinkService.generateUrl(websiteLink.linkId);
    }
    return websiteLink;
  }

  async saveNewWebsiteLink(dismiss?: boolean) {
    if (this.checkPrivilege('invites')) {
      this.form.markAllAsTouched();

      if (this.form.valid) {
        await this.popupService.presentLoading();
  
        let websiteLink = this.generateData();

        websiteLink = await this.websiteLinkService.saveWebsiteLink(websiteLink);
        await this.popupService.dismissLoading();
        
        if (websiteLink?.link?.url) {
          await this.popupService.saveSuccessToast();
          await this.share(websiteLink, true);
        } else {
          await this.popupService.saveFailToast();
        }
        if (dismiss) {
          await this.dismissModal(websiteLink);
        }
      }
    } else {
      this.popupService.presentAlert(this.translate.instant('ACCOUNT_PRIVILEGE.msg.no_privilege'));
    }
  }

  async saveWebsiteLink(websiteLink: WebsiteLink) {
    await this.popupService.presentLoading();
    await this.websiteLinkService.saveWebsiteLink(websiteLink);
    this.updateWebsiteLink(websiteLink);
    await this.popupService.dismissLoading();
    await this.popupService.saveSuccessToast();
  }

  updateWebsiteLink(websiteLink: WebsiteLink) {
    const index = this.websiteLinks.findIndex((x: WebsiteLink) => {
      return x.linkId === websiteLink.linkId;
    });
    if (index !== -1) {
      this.websiteLinks[index] = websiteLink;
    } else {
      this.websiteLinks.push(websiteLink);
    }
  }

  async copyUrl() {
    const msg = this.getUrl();
    if (msg) {
      try {
        await Clipboard.write({ string: msg });
        this.popupService.presentToast(this.translate.instant('COPY.success'), 'success');
      } catch (err: any) {
        this.popupService.presentToast(this.translate.instant('COPY.fail'), 'danger');
      }
    } else {
      this.popupService.presentToast(this.translate.instant('COPY.fail'), 'danger');
    }
  }

  async copyMsg() {
    const msg = this.generateMsg();
    if (msg) {
      try {
        await Clipboard.write({ string: msg });
        this.popupService.presentToast(this.translate.instant('COPY.success'), 'success');
      } catch (err: any) {
        this.popupService.presentToast(this.translate.instant('COPY.fail'), 'danger');
      }
    } else {
      this.popupService.presentToast(this.translate.instant('COPY.fail'), 'danger');
    }
  }

  generateMsg(): string {
    const url = this.getUrl();
    if (url) {
      return (this.msg ? (this.msg + '\n\n') : '') + url;
    }
    return '';
  }

  async share(websiteLink?: WebsiteLink, skipLoading?: boolean) {
    if (!skipLoading) {
      await this.popupService.presentLoading();
    }
    await this.websiteLinkService.share(websiteLink? websiteLink : this.websiteLink);
    await this.popupService.dismissLoading();
  }

  trackByFn(index: number, item: WebsiteLink) {
    if (item?.linkId) {
      return item.linkId;
    }
    return index;
  }

  checkPrivilege(action: string): boolean {
    return this.privilegeService.checkCurrentUserPrivilege('website', action);
  }

}
