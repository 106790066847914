<ion-header>
  <ion-toolbar color="light">
    <ion-buttons slot="start">
      <ion-button (click)="discard()">
        <ion-icon class="back-icon" src="./assets/wedding/icon/general/chevron-back.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <app-offline-indicator></app-offline-indicator>
      {{ 'CRUD.update_field' | translate: { field: 'GUEST.lbl.guest' | translate } }}
    </ion-title>
    <ion-buttons slot="end" *ngIf="isHybrid">
      <ion-button (click)="presentReadContactsModal()">
        <ion-icon src="./assets/wedding/icon/general/person-circle-outline.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content color="white">
  <form class="ion-padding-horizontal" [formGroup]="form" (ngSubmit)="guestEditFormSubmit()" *ngIf="form">
    <ion-list class="background-transparent">
      <div class="input-div">
        <ion-label class="custom-stacked-label" position="stacked" color="dark">{{ 'LBL.status' | translate }}</ion-label>
        <ion-segment class="seg-btn-status" formControlName="attendingStatus" scrollable>
          <ion-segment-button value="attending" layout="icon-top">
            <ion-icon class="icon-large" src="./assets/wedding/icon/guest/status/attending.svg"></ion-icon>
            <ion-label class="font-10">{{ 'LIST.attending_status.attending' | translate }}</ion-label>
          </ion-segment-button>
          <ion-segment-button value="pending" layout="icon-top">
            <ion-icon class="icon-large" src="./assets/wedding/icon/guest/status/pending.svg"></ion-icon>
            <ion-label class="font-10">{{ 'LIST.attending_status.pending' | translate }}</ion-label>
          </ion-segment-button>
          <ion-segment-button value="not_attending" layout="icon-top">
            <ion-icon class="icon-large" src="./assets/wedding/icon/guest/status/not-attending.svg"></ion-icon>
            <ion-label class="font-10">{{ 'LIST.attending_status.not_attending' | translate }}</ion-label>
          </ion-segment-button>
        </ion-segment>
        <div class="error-div">
          <ng-container *ngFor="let validation of validationMsg?.attendingStatus">
            <div class="error-message" *ngIf="form?.get('attendingStatus').hasError(validation.type) && (form?.get('attendingStatus').dirty || form?.get('attendingStatus').touched)">
              <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ validation.msg }}
            </div>
          </ng-container>
        </div>
      </div>
     <div class="input-div">
        <ion-item class="ion-item-no-padding" color="white" lines="none" *ngIf="!bulkEditMode">
          <ion-label position="stacked" color="dark">{{ 'LBL.name' | translate }}</ion-label>
          <ion-input formControlName="name" class="prompt-input" [placeholder]="" type="text" inputmode="text" autocapitalize="words" autocomplete="on" tabindex="1" clearInput></ion-input>
          <div class="error-div">
            <ng-container *ngFor="let validation of validationMsg?.name">
              <div class="error-message" *ngIf="form?.get('name').hasError(validation.type) && (form?.get('name').dirty || form?.get('name').touched)">
                <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ validation.msg }}
              </div>
            </ng-container>
          </div>
        </ion-item>
        <ion-item class="ion-item-no-padding" color="white" lines="none" *ngIf="!bulkEditMode">
          <ion-label position="stacked" color="dark">{{ 'LBL.nickname' | translate }}</ion-label>
          <ion-input formControlName="nickname" class="prompt-input" [placeholder]="" type="text" inputmode="text" autocapitalize="words" autocomplete="on" tabindex="2" clearInput></ion-input>
          <div class="error-div">
            <ng-container *ngFor="let validation of validationMsg?.nickname">
              <div class="error-message" *ngIf="form?.get('nickname').hasError(validation.type) && (form?.get('nickname').dirty || form?.get('nickname').touched)">
                <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ validation.msg }}
              </div>
            </ng-container>
          </div>
        </ion-item>
        <div *ngIf="invitedByList?.length">
          <ion-label class="custom-stacked-label" position="stacked" color="dark">{{ 'GUEST.lbl.invited_by' | translate }}</ion-label>
          <ion-segment scrollable formControlName="invitedBy" *ngIf="invitedByList">
            <ion-segment-button [value]="invitedBy.value" *ngFor="let invitedBy of invitedByList">
              <ion-text>
                <ng-container *ngIf="invitedBy.custom">{{ invitedBy.value }}</ng-container>
                <ng-container *ngIf="!invitedBy.custom">{{ 'LIST.invited_by.' + invitedBy.value | translate }}</ng-container>
              </ion-text>
            </ion-segment-button>
          </ion-segment>
          <div class="error-div">
            <ng-container *ngFor="let validation of validationMsg?.invitedBy">
              <div class="error-message" *ngIf="form?.get('invitedBy').hasError(validation.type) && (form?.get('invitedBy').dirty || form?.get('invitedBy').touched)">
                <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ validation.msg }}
              </div>
            </ng-container>
          </div>
        </div>
        <ion-item class="ion-item-no-padding" color="white" lines="none" (click)="presentSettingFieldModal('category', form?.value?.category)">
          <ion-label position="stacked" color="dark">{{ 'LBL.category' | translate }}</ion-label>
          <ion-item class="prompt-input-item" lines="none">
            <ion-label class="ion-no-margin" >{{ category }}</ion-label>
            <ion-note slot="end"><ion-icon src="./assets/wedding/icon/general/chevron-down.svg" class="font-18"></ion-icon></ion-note>
          </ion-item>
          <div class="error-div">
            <ng-container *ngFor="let validation of validationMsg?.category">
              <div class="error-message" *ngIf="form?.get('category').hasError(validation.type) && (form?.get('category').dirty || form?.get('category').touched)">
                <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ validation.msg }}
              </div>
            </ng-container>
          </div>
        </ion-item>
        <ion-item class="ion-item-no-padding" color="white" lines="none" (click)="presentGroupModal(group)" *ngIf="!bulkEditMode && !newGuest">
          <ion-label position="stacked" color="dark">{{ 'LIST.group.group' | translate }}</ion-label>
          <ion-item class="prompt-input-item" lines="none">
            <ion-label class="ion-no-margin">
              <ng-container *ngIf="guest?.groupId && group?.memberList else groupBlock">
                {{ group.memberList.length }} {{ 'GUEST.lbl.guests' | translate }}
              </ng-container>
              <ng-template #groupBlock>
                -
              </ng-template>
            </ion-label>
            <ion-note slot="end"><ion-icon src="./assets/wedding/icon/general/chevron-down.svg" class="font-18"></ion-icon></ion-note>
          </ion-item>
          <div class="error-div">
          </div>
        </ion-item>
        <ion-item class="ion-item-no-padding" color="white" lines="none" (click)="presentSeatingDetailModal(guest.seating)" *ngIf="!bulkEditMode && !newGuest">
          <ion-label position="stacked" color="dark">{{ seatingTypeName }}</ion-label>
          <ion-item class="prompt-input-item" lines="none">
            <ion-label class="ion-no-margin">
              <ng-container *ngIf="form.value.seating">
                {{ form.value.seating }}
              </ng-container>
              <ng-container *ngIf="!form.value.seating">
                {{ 'LIST.assign_status.not_yet_assign' | translate }}
              </ng-container>
            </ion-label>
            <ion-note slot="end"><ion-icon src="./assets/wedding/icon/general/chevron-down.svg" class="font-18"></ion-icon></ion-note>
          </ion-item>
          <div class="error-div">
            <ng-container *ngFor="let validation of validationMsg?.seating">
              <div class="error-message" *ngIf="form?.get('seating').hasError(validation.type) && (form?.get('seating').dirty || form?.get('seating').touched)">
                <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ validation.msg }}
              </div>
            </ng-container>
          </div>
        </ion-item>
        <div>
          <app-mobile class="full-width-height" [lbl]="'MOBILE.lbl.no' | translate" [setMobile]="mobile" [setCssClass]="''" (outputMobile)="updateMobile($event)" (outputMobileInvalid)="updateMobileInvalid($event)"></app-mobile>
        </div>
        <ion-item class="ion-item-no-padding" color="white" lines="none">
          <ion-label position="stacked" color="dark">{{ 'LBL.email' | translate }}</ion-label>
          <ion-input formControlName="email" class="prompt-input" [placeholder]="" type="email" inputmode="email" autocomplete="on" tabindex="3" clearInput></ion-input>
          <div class="error-div">
            <ng-container *ngFor="let validation of validationMsg?.email">
              <div class="error-message" *ngIf="form?.get('email').hasError(validation.type) && (form?.get('email').dirty || form?.get('email').touched)">
                <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ validation.msg }}
              </div>
            </ng-container>
          </div>
        </ion-item>
        <ion-item class="ion-item-no-padding" color="white" lines="none" (click)="presentSettingFieldModal('dietary_req', form?.value?.dietaryReq)">
          <ion-label position="stacked" color="dark">{{ 'GUEST.lbl.dietary_req' | translate }}</ion-label>
          <ion-item class="prompt-input-item" lines="none">
            <ion-label class="ion-no-margin" >{{ dietaryReq }}</ion-label>
            <ion-note slot="end"><ion-icon src="./assets/wedding/icon/general/chevron-down.svg" class="font-18"></ion-icon></ion-note>
          </ion-item>
          <div class="error-div">
            <ng-container *ngFor="let validation of validationMsg?.dietaryReq">
              <div class="error-message" *ngIf="form?.get('dietaryReq').hasError(validation.type) && (form?.get('dietaryReq').dirty || form?.get('dietaryReq').touched)">
                <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ validation.msg }}
              </div>
            </ng-container>
          </div>
        </ion-item>
        <ion-item class="ion-item-no-padding" color="white" lines="none" (click)="presentSettingFieldModal('special_req', form?.value?.specialReq)">
          <ion-label position="stacked" color="dark">{{ 'GUEST.lbl.special_req' | translate }}</ion-label>
          <ion-item class="prompt-input-item" lines="none">
            <ion-label class="ion-no-margin" >{{ specialReq }}</ion-label>
            <ion-note slot="end"><ion-icon src="./assets/wedding/icon/general/chevron-down.svg" class="font-18"></ion-icon></ion-note>
          </ion-item>
          <div class="error-div">
            <ng-container *ngFor="let validation of validationMsg?.specialReq">
              <div class="error-message" *ngIf="form?.get('specialReq').hasError(validation.type) && (form?.get('specialReq').dirty || form?.get('specialReq').touched)">
                <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ validation.msg }}
              </div>
            </ng-container>
          </div>
        </ion-item>
        <ion-item class="ion-item-no-padding" color="white" lines="none" (click)="presentSettingFieldModal('session', form?.value?.session)" *ngIf="showSession">
          <ion-label position="stacked" color="dark">{{ 'LBL.session' | translate }}</ion-label>
          <ion-item class="prompt-input-item" lines="none">
            <ion-label class="ion-no-margin" >{{ session }}</ion-label>
            <ion-note slot="end"><ion-icon src="./assets/wedding/icon/general/chevron-down.svg" class="font-18"></ion-icon></ion-note>
          </ion-item>
          <div class="error-div">
            <ng-container *ngFor="let validation of validationMsg?.session">
              <div class="error-message" *ngIf="form?.get('session').hasError(validation.type) && (form?.get('session').dirty || form?.get('session').touched)">
                <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ validation.msg }}
              </div>
            </ng-container>
          </div>
        </ion-item>
        <ion-item class="ion-item-no-padding" color="white" lines="none">
          <ion-label position="stacked" color="dark">{{ 'GUEST.lbl.remark' | translate }}</ion-label>
          <ion-input formControlName="remark" class="prompt-input" [placeholder]="" type="text" inputmode="text" tabindex="4" clearInput></ion-input>
          <div class="error-div">
            <ng-container *ngFor="let validation of validationMsg?.remark">
              <div class="error-message" *ngIf="form?.get('remark').hasError(validation.type) && (form?.get('remark').dirty || form?.get('remark').touched)">
                <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ validation.msg }}
              </div>
            </ng-container>
          </div>
        </ion-item>
      </div>
    </ion-list>
  </form>
  <div *ngIf="!newGuest && !bulkEditMode && currentPrivilege?.[module]?.['delete']">
    <ion-list class="ion-margin-vertical">
      <ion-item-sliding #deleteItem>
        <ion-item-options side="start" (ionSwipe)="promptRemove(guest, deleteItem)" lines="none">
          <ion-item-option color="danger" (click)="promptRemove(guest, deleteItem)" lines="none">
            <ion-icon src="./assets/wedding/icon/general/checkmark.svg"></ion-icon>
          </ion-item-option>
        </ion-item-options>
        <ion-item color="danger" lines="none">
          <ion-icon slot="start" class="icon-small" src="./assets/wedding/icon/general/trash.svg"></ion-icon> 
          <ion-text slot="start">{{ 'CRUD.swipe_field' | translate: { field: 'CRUD.delete' | translate } }}</ion-text> 
          <ion-icon slot="start" class="icon-small" src="./assets/wedding/icon/general/arrow-forward.svg"></ion-icon>
        </ion-item>
        <ion-item-options side="end" (ionSwipe)="promptRemove(guest, deleteItem)" lines="none">
          <ion-item-option color="danger" (click)="promptRemove(guest, deleteItem)" lines="none">
            <ion-icon src="./assets/wedding/icon/general/checkmark.svg"></ion-icon>
          </ion-item-option>
        </ion-item-options>
      </ion-item-sliding>
    </ion-list>
  </div>

</ion-content>
<ion-footer>
  <ion-toolbar>
    <ion-button class="ion-margin" expand="block" color="primary" fill="solid" type="button" (click)="guestEditFormSubmit()" [disabled]="!form?.valid || mobileInvalid">
      <ng-container *ngIf="newGuest">{{ 'CRUD.update' | translate }}</ng-container>
      <ng-container *ngIf="!newGuest">{{ 'BTN.save' | translate }}</ng-container>
    </ion-button>
  </ion-toolbar>
</ion-footer>