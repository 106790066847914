import { OnlineService } from 'src/app/services/general/online.service';
import { ModalController } from '@ionic/angular';
import { ErrorService } from 'src/app/services/general/error.service';
import { FunctionService } from 'src/app/services/general/function.service';
import { PopupService } from 'src/app/services/general/popup.service';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { InvisibleReCaptchaComponent } from 'ngx-captcha';
import { AccountLogin, LoginUrl } from 'src/app/interfaces/login';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { AccountInfo } from 'src/app/interfaces/account';
import { NavigationExtras, Router } from '@angular/router';

@Component({
  selector: 'app-accounts-password-login',
  templateUrl: './accounts-password-login.component.html',
  styleUrls: ['./accounts-password-login.component.scss'],
})
export class AccountsPasswordLoginComponent implements OnInit, OnDestroy {

  /**
   * Captcha viewchild
   */
  @ViewChild('captchaElem', { static: false }) captchaElem: InvisibleReCaptchaComponent;
  /**
   * Wedding password login form
   */
  passwordForm: FormGroup;
  /**
   * Wedding password login form validation message
   */
  validationMsg: any;
  /**
   * Recaptcha site key
   */
  siteKey: string = environment.recaptcha.invisible;
  /**
   * Recaptcha flag
   */
  recaptcha: boolean;
  /**
   * Recaptcha model
   */
  recaptchaModel: any;
  /**
   * Recaptcha Response
   */
  private recaptchaResponse: string;

  constructor(
    private router: Router,
    private fns: AngularFireFunctions,
    private modalController: ModalController,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private onlineService: OnlineService,
    private functionService: FunctionService,
    private popupService: PopupService,
    private errorService: ErrorService,
  ) { }

  ngOnInit() {
    
  }

  ngOnDestroy() {
    this.recaptcha = false;
  }

  ionViewWillEnter() {
    this.setupForm();
    this.recaptcha = true;
  }

  ionDidWillLeave() {
    this.recaptcha = false;
  }

  /**
   * Setup form
   */
  setupForm() {
    this.passwordForm = this.formBuilder.group({
      weddingId: new FormControl('', Validators.required),
      weddingPass: new FormControl('', Validators.required)
    });

    this.validationMsg = {
      weddingId: [{ type: 'required', msg: this.translate.instant('VALIDATION.required', { field: this.translate.instant('ACCOUNT.lbl.wedding_id') }) }],
      weddingPass: [{ type: 'required', msg: this.translate.instant('VALIDATION.required', { field: this.translate.instant('ACCOUNT.lbl.wedding_pass') }) }]
    };
  }

  /**
   * Dismiss modal
   * @param dismiss dismiss flag
   */
  async dismissModal(dismiss?: boolean) {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { this.modalController.dismiss({ dismiss }); }
    }
  }

  /**
   * Submit login password
   */
  async submitPassword() {
    this.passwordForm.markAllAsTouched();
    if (this.passwordForm.valid && this.passwordForm.value.weddingId && this.passwordForm.value.weddingPass && this.captchaElem) {
      await this.popupService.presentLoading();
      this.captchaElem?.execute();
    }
  }

  /**
   * Handle recaptcha reset
   */
  handleReset() {
  }

  /**
   * Handle recaptcha ready
   */
  handleReady() {
  }

  /**
   * Handle recaptcha load
   */
  handleLoad() {
  }

  /**
   * Handle recaptcha expire
   */
  handleExpire() {
    this.popupService.dismissLoading();
  }

  /**
   * Handle recaptcha success
   * @param event event
   */
  handleSuccess(event: any) {
    this.recaptchaResponse = event;
    if (this.recaptchaResponse && this.recaptchaResponse === this.captchaElem?.getCurrentResponse()) {
      if (this.passwordForm.value.weddingId && this.passwordForm.value.weddingPass) {
        const loginUrl: LoginUrl = {
          login: {
            weddingId: this.passwordForm.value.weddingId,
            weddingPass: this.passwordForm.value.weddingPass,
          },
          timestamp: this.functionService.firestoreLocalTime.seconds
        };
        this.getAccountLoginPreviewCall(loginUrl);
      } else {
        this.popupService.dismissLoading();
      }
      this.resetRecaptcha();
    } else {
      this.popupService.dismissLoading();
    }
  }

  /**
   * Handle recaptcha error
   */
  handleError() {
    this.popupService.dismissLoading();
  }

  /**
   * Reset recaptcha
   */
  resetRecaptcha() {
    if (this.captchaElem && this.recaptchaResponse) {
      this.recaptchaResponse = null;
      this.captchaElem.resetCaptcha();
    }
  }

  /**
   * Go account login preview fns call
   * @param loginUrl login url
   */
  async getAccountLoginPreviewCall(loginUrl: LoginUrl) {
    if (this.onlineService.isOnline()) {
      if (loginUrl) {
        await this.popupService.presentLoading();
        await this.fns.httpsCallable('getAccountLoginPreviewCall')({ loginUrl })
        .toPromise().then(async (result: any) => {
          const accountInfo: AccountInfo = result?.accountInfo;
          const accountLogin: AccountLogin = result?.accountLogin;
          if (accountInfo?.accountId && accountLogin?.accountId) {
            loginUrl.accountId = accountInfo?.accountId;
            loginUrl.title = accountInfo?.title?.value;
            loginUrl.time = accountInfo?.time?._seconds ? {
              seconds: accountInfo.time._seconds
            } : accountInfo?.time?.seconds ? {
              seconds: accountInfo.time.seconds
            } : null;
            loginUrl.utc = accountInfo?.timezone?.utc;
            loginUrl.venue = accountInfo?.location?.name;
            loginUrl.role = accountLogin?.role;
            loginUrl.anonymous = accountLogin?.role?.coupleId > 0 || accountLogin?.isOwner ? false : true;
            await this.goAccountLoginPreviewPage(loginUrl, accountLogin);
          } else {
            this.popupService.presentAlert(this.translate.instant('ACCOUNTS_LOGIN.invalid'));
          }
        }).catch((err: any) => {
          this.errorService.logError(err);
          this.popupService.presentAlert(this.translate.instant('ACCOUNTS_LOGIN.error'));
        });
        this.popupService.dismissLoading();
      } else {
        this.popupService.presentAlert(this.translate.instant('ACCOUNTS_LOGIN.error'));
      }
    }
  }

  /**
   * Go account login preivew page
   * @param loginUrl login url
   * @param accountLogin account login
   */
  async goAccountLoginPreviewPage(loginUrl: LoginUrl, accountLogin?: AccountLogin) {
    const navigationExtras: NavigationExtras = {
      state: {
        loginUrl,
        accountLogin,
        loginMethod: 'password'
      }
    };
    await this.router.navigate(['/accounts/preview-login'], navigationExtras);
    this.dismissModal();
  }

}
