import { UserPromptService } from './user-prompt.service';
import { ProviderService } from 'src/app/services/user/provider.service';
import { UrlService } from 'src/app/services/general/url.service';
import { AnalyticsService } from 'src/app/services/general/analytics.service';
import { Injectable, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { PhoneNumberUtil } from 'google-libphonenumber';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

import { UserService } from 'src/app/services/user/user.service';
import { UserManageService } from 'src/app/services/user/user-manage.service';
import { AccountService } from 'src/app/services/account/account.service';
import { StorageService } from 'src/app/services/general/storage.service';
import { LinkService } from 'src/app/services/general/link.service';
import { PopupService } from 'src/app/services/general/popup.service';
import { ErrorService } from 'src/app/services/general/error.service';
import { FunctionService } from 'src/app/services/general/function.service';

import { Provider } from 'src/app/interfaces/user';

import { SignInWithApple, SignInWithAppleOptions, SignInWithAppleResponse } from '@capacitor-community/apple-sign-in';
// import { FacebookLogin, FacebookLoginResponse } from '@capacitor-community/facebook-login';
// import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';

import { PublicUrl, UniversalUrl } from 'src/app/commons/url';
import { OnlineDbService } from './online-db.service';
import { MobileService } from '../general/mobile.service';
import { DeviceManageService } from '../device/device-manage.service';
import { DeviceTokenService } from '../device/device-token.service';
import { FirebaseAuthentication, SignInWithOAuthOptions } from '@capacitor-firebase/authentication';


// const twitter = new Twitter();

/**
 * Authentication Service.
 * Authenticate user using Firebase Authentication.
 * Sign in / Sign out using web / native method.
 * Link / Unlink Provider for user account.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnInit, OnDestroy {

  /**
   * Current User Info from Firebase Auth.
   */
  currentUser: firebase.User;
  /**
   * Observable Login Ready State.
   */
  observableLoginReady: any;
  /**
   * Observable current user
   */
  observableCurrentuser: any;
  /**
   * Current Authentication Type.
   */
  authType: string;
  /**
   * Login Ready State for authentication check was finished.
   * False: Login action in processing and show loading state at home page
   */
  loginReady: boolean;

  guestMode: boolean;

  private idTokenChange: firebase.Unsubscribe;

  private authStateChange: firebase.Unsubscribe;
  /**
   * Constructor
   * @param ngZone NG zone
   * @param router Router
   * @param platform Platform
   * @param modalController Modal Controller
   * @param afAuth Angular Fire Authentication
   * @param translate Translate
   * @param popupService Popup Service
   * @param storageService Storage Service
   * @param accountSetupService Account Service
   * @param userService User Service
   * @param onlineDbService Online Service
   * @param errorService Error Service
   */
  constructor(
    private ngZone: NgZone,
    private router: Router,
    private platform: Platform,
    private afAuth: AngularFireAuth,
    private translate: TranslateService,
    private popupService: PopupService,
    private storageService: StorageService,
    private linkService: LinkService,
    private accountService: AccountService,
    private userService: UserService,
    private providerService: ProviderService,
    private userManageService: UserManageService,
    private userPromptService: UserPromptService,
    private deviceManageService: DeviceManageService,
    private deviceTokenService: DeviceTokenService,
    private mobileService: MobileService,
    private onlineDbService: OnlineDbService,
    private urlService: UrlService,
    private errorService: ErrorService,
    private functionService: FunctionService,
    private analyticsService: AnalyticsService,
  ) {
    this.initialize();
  }

  ngOnInit(): void {
      
  }

  ngOnDestroy(): void {
      this.unwatch();
  }

  /**
   * Initialize state.
   */
  async initialize() {
    this.loginReady = false;
    this.observableLoginReady = new BehaviorSubject<boolean>(this.loginReady);
    this.observableCurrentuser = new BehaviorSubject<firebase.User>(this.currentUser);
    this.setupAuth();
  }

  // mapUser() {
  //   this.user = this.afAuth.authState.pipe(
  //     switchMap(user => {
  //       if (user) {
  //         return this.afs.doc(`users/${user.uid}`).valueChanges();
  //       } else {
  //         return of(null);
  //       }
  //     })
  //   );
  // }

  /**
   * Setup AngualrFire authentication state.
   */
  async setupAuth() {
    await this.platform.ready();
    await this.afAuth.setPersistence('local');
    await this.afAuth.useDeviceLanguage();
    await this.checkCurrentAuth();
  }

  /**
   * Get current firebase user
   * @returns firebase current user
   */
  async getCurrentUser(): Promise<firebase.User> {
    await this.platform.ready();
    return await this.afAuth.currentUser;
  }

  /**
   * Check current authentication status.
   */
  async checkCurrentAuth() {
    try {
      this.currentUser = await this.getCurrentUser();
      this.observableCurrentuser.next(this.currentUser);
      if (this.currentUser?.uid) {
        await this.unwatchNewLogin();
        this.analyticsService.userId(this.currentUser.uid);
        this.providerService.setupProviderList(this.currentUser?.providerData);
        const authType = await this.getAuthType();
        await this.functionService.delay(200);
        await this.userService.initialUser(this.currentUser.uid);
        await this.onlineDbService.setUID(this.currentUser.uid);
        await this.functionService.delay(200);

        if (!this.userService?.user?.uid) {
          await this.userManageService.createNewUserData();
          await this.deviceManageService.saveDevice();
        }
        this.ngZone.run(async () => {
          await this.goLandingPage();
          this.updateLoginReady(true);
          await this.userPromptService.initial();
          
          if (this.userService?.user?.name) {
            await this.deviceManageService.readDevice(this.deviceTokenService.token);
          } 
        });
      } else {
        await this.watchAuthState();
      }
    } catch(err: any) {
      console.error(err);
    }
  }

  /***
   * Go landing page
   */
  async goLandingPage() {
    try {
      if (this.currentUser) {
        if (this.userService.user?.name) {
          this.analyticsService.userLogin(this.authType, this.currentUser?.uid);
          const accountId = await this.accountService.readAccountId();
          if (accountId) {
            await this.accountService.setupAccount(accountId);
          } else {
            await this.linkService.goAccountsListPage(true);
          }
          await this.popupService.dismissLoading();
        }
      } else {
        await this.storageService.logout();
        if (await this.accountService.readAccountId()) {
          await this.accountService.logoutAccount();
        }
        await this.goMainPage();
      }
    } catch(err: any) {
      console.error(err);
    }
    
  }

  async goMainPage() {
    if (!this.urlService.checkUrl(PublicUrl) && !this.urlService.checkUrl(UniversalUrl)) {
      await this.linkService.goMainPage();
    }
  }

  /**
   * Update login ready
   * @param loginReady login ready status
   */
  updateLoginReady(loginReady: boolean) {
    if (this.loginReady !== loginReady) {
      this.loginReady = loginReady;
      this.linkService.loginReady = loginReady;
      this.observableLoginReady.next(this.loginReady);
    }
  }

  /**
   * Watch firebase authentication state.
   */
  async watchAuthState() {
    await this.watchInvalidUser();
    await this.watchNewLogin();
  }

  async unwatch() {
    await this.unwatchInvalidUser();
    await this.unwatchNewLogin();
  }

  /**
   * Watch user login status & new login state update
   */
  async watchNewLogin() {
    await this.unwatchNewLogin();
    this.authStateChange = await this.afAuth.onAuthStateChanged((currentUser: firebase.User) => {
      if (currentUser && !this.currentUser) {
        this.currentUser = currentUser;
        this.observableCurrentuser.next(this.currentUser);
        this.checkCurrentAuth();
      }
    });
  }

  async unwatchNewLogin() {
    if (this.authStateChange) {
      this.authStateChange();
      this.authStateChange = null;
    }
  }

  /**
   * Watch invalid user from Firebase
   */
  async watchInvalidUser() {
    await this.unwatchInvalidUser();
    this.idTokenChange = await this.afAuth.onIdTokenChanged((currentUser: firebase.User) => {
      if (!currentUser) {
        this.currentUser = null;
        this.observableCurrentuser.next(this.currentUser);
        if (!this.loginReady) {
          this.updateLoginReady(true);
        } else {
          this.invalidUserPrompt();
        }
      }
    });
  }

  async unwatchInvalidUser() {
    if (this.idTokenChange) {
      this.idTokenChange();
      this.idTokenChange = null;
    }
  }

  /**
   * Prompt invalid user and logout user after prompt
   */
  async invalidUserPrompt() {
    if (this.router.url && PublicUrl?.indexOf(this.router?.url) === -1) {
      const modal = await this.popupService.presentAlert(this.translate.instant('USER.msg.invalid_user'));
      modal.onWillDismiss().then(() => {
        this.router.navigate(['/user/logout']);
      });
    }
  }

  /**
   * Set authentication type
   */
  async setAuthType(providerId: string) {
    this.authType = providerId;
    await this.storageService.set('auth_type', providerId);
  }

  /**
   * Get authentication type
   */
  async getAuthType(): Promise<string> {
    if (!this.authType) {
      this.authType = await this.storageService.get('auth_type');
    }
    return this.authType;
  }

  /**
   * Google login
   */
  async googleSignIn(retry?: boolean, link?: boolean) {
    this.updateLoginReady(false);
    if (this.platform.is('hybrid')) {
      try {
        if (link) {
          const result = await FirebaseAuthentication.linkWithGoogle();
          const credential = firebase.auth.GoogleAuthProvider.credential(result?.credential?.idToken);
          await this.signInWithCredential(credential);
        } else {
          const currentUser = await FirebaseAuthentication.getCurrentUser();
          if (currentUser?.user?.uid) {
            await this.googleSignOut();
            await this.functionService.delay(500);
          }
          const result = await FirebaseAuthentication.signInWithGoogle();
          const credential = firebase.auth.GoogleAuthProvider.credential(result?.credential?.idToken);
          await this.signInWithCredential(credential);
        }
        
      } catch (err) {
        if (err?.code === 'auth/popup-blocked' && !retry && !this.platform.is('hybrid')) {
          this.googleSignIn(true, link);
        } else {
          this.logError(err);
          this.promptError(err);
        }
      }
      // try {
      //   const googleUser = await GoogleAuth.signIn();
      //   const credential = firebase.auth.GoogleAuthProvider.credential(googleUser.authentication.idToken);
      //   await this.signInWithCredential(credential);
      // } catch (err) {
      //   if (err?.code === 'auth/popup-blocked' && !retry && !this.platform.is('hybrid')) {
      //     this.googleSignIn(true);
      //   } else {
      //     this.logError(err);
      //     this.promptError(err);
      //   }
      // }
    } else {
      const provider = new firebase.auth.GoogleAuthProvider();
      provider.addScope('email');
      provider.addScope('profile');
      await this.oAuthLogin(provider);
    }
  }

  /**
   * Google logout
   */
  async googleSignOut() {
    if (this.platform.is('hybrid')) {
      FirebaseAuthentication.signOut();
    }
  }

  /**
   * Facebook login
   */
  async facebookSignIn(retry?: boolean, link?: boolean) {
    this.updateLoginReady(false);
    if (this.platform.is('hybrid')) {
      try {
        const FACEBOOK_PERMISSIONS = ['email', 'public_profile'];
        if (link) {
          const result = await FirebaseAuthentication.linkWithFacebook({ scopes: FACEBOOK_PERMISSIONS });
          const credential = firebase.auth.FacebookAuthProvider.credential(result?.credential?.accessToken);
          await this.signInWithCredential(credential);
        } else {
          const currentUser = await FirebaseAuthentication.getCurrentUser();
          if (currentUser?.user?.uid) {
            await this.facebookSignOut();
            await this.functionService.delay(500);
          }
          const result = await FirebaseAuthentication.signInWithFacebook({ scopes: FACEBOOK_PERMISSIONS });
          const credential = firebase.auth.FacebookAuthProvider.credential(result?.credential?.accessToken);
          await this.signInWithCredential(credential);
        }
      
        // 
        // const result: FacebookLoginResponse = await FacebookLogin.login({ permissions: FACEBOOK_PERMISSIONS });
        // if (result?.accessToken?.token) {
        //   const credential = firebase.auth.FacebookAuthProvider.credential(result.accessToken.token);
        //   await this.signInWithCredential(credential);
        // } else {
        //   this.promptError();
        // }
      } catch (err) {
        if (err?.code === 'auth/popup-blocked' && !retry && !this.platform.is('hybrid')) {
          this.facebookSignIn(true, link);
        } else {
          this.logError(err);
          this.promptError(err);
        }
      }
    } else {
      const provider = new firebase.auth.FacebookAuthProvider();
      provider.addScope('email');
      provider.addScope('public_profile');
      await this.oAuthLogin(provider);
    }
  }

  /**
   * Facebook logout
   */
  async facebookSignOut() {
    if (this.platform.is('hybrid')) {
      FirebaseAuthentication.signOut();
      // await FacebookLogin.logout();
    }
  }

  /**
   * Sign in by Apple
   */
  async appleSignIn(retry?: boolean) {
    this.updateLoginReady(false);
    const provider = new firebase.auth.OAuthProvider('apple.com');
    provider.addScope('name');
    provider.addScope('email');
    if (this.platform.is('hybrid') && this.platform.is('ios')) {
      try {
        const option: SignInWithAppleOptions = {
          clientId: 'my.thebigday.wedding',
          redirectURI: 'https://thebigday-wedding.firebaseapp.com/__/auth/handler',
          scopes: 'email name',
        };
        const result: SignInWithAppleResponse = await SignInWithApple.authorize(option);
        if (result?.response?.identityToken) {
          if (result?.response?.givenName && result?.response?.familyName) {
            await this.storageService.set('display_name', result?.response?.givenName + ' ' + result?.response?.familyName);
          } else if (result?.response?.givenName) {
            await this.storageService.set('display_name', result?.response?.givenName);
          } else if (result?.response?.familyName) {
            await this.storageService.set('display_name', result?.response?.familyName);
          }
          const credential = provider.credential({
            idToken: result.response.identityToken
          });
          await this.signInWithCredential(credential);
        } else {
          this.promptError();
        }
      } catch (err) {
        if (err?.code === 'auth/popup-blocked' && !retry && !this.platform.is('hybrid')) {
          this.appleSignIn(true);
        } else {
          this.logError(err);
          this.promptError(err);
        }
      }
    } else {
      await this.oAuthLogin(provider);
    }
  }

  /**
   * Sign out by Apple
   */
  async appleSignOut() {
    if (this.platform.is('hybrid') && this.platform.is('ios')) {
    }
  }

  /**
   * Sign in by Twitter
   */
  async twitterSignIn(retry?: boolean) {
    this.updateLoginReady(false);
    if (this.platform.is('hybrid')) {
      // try {
      //   const result = await Twitter.login();
      //   if (result?.authToken && result?.authTokenSecret) {
      //     const credential = firebase.auth.TwitterAuthProvider.credential(result.authToken, result.authTokenSecret);
      //     await this.signInWithCredential(credential);
      //   } else {
      //     this.promptError();
      //   }
      // } catch (err) {
      //   if (err?.code === 'auth/popup-blocked' && !retry && !this.platform.is('hybrid')) {
      //     this.twitterSignIn(true);
      //   } else {
      //     this.logError(err);
      //     this.promptError(err);
      //   }
      // }
    } else {
      const provider = new firebase.auth.TwitterAuthProvider();
      await this.oAuthLogin(provider);
    }
  }

  /**
   * Sign out by Twitter
   */
  async twitterSignOut() {
    if (this.platform.is('hybrid')) {
      // const result = await Twitter.logout();
    }
  }

  async microsoftSignIn() {
    const provider = new firebase.auth.OAuthProvider('microsoft.com');
    provider.addScope('email');
    await this.oAuthLogin(provider);
  }

  async yahooSignIn() {
    const provider = new firebase.auth.OAuthProvider('yahoo.com');
    provider.addScope('email');
    await this.oAuthLogin(provider);
  }

  /**
   * Send OTP for firebase mobile authentication (WEB)
   * @param mobile Mobile
   * @param appVerifier App Verifier
   */
  async sendOtpWeb(mobile: string, appVerifier: firebase.auth.ApplicationVerifier): Promise<firebase.auth.ConfirmationResult> {
    if (!this.platform.is('hybrid')) {
      try {
        const result = await this.afAuth.signInWithPhoneNumber(mobile, appVerifier);
        return result;
      } catch (err) {
        this.logError(err);
        this.promptError(err);
        return null;
      }
    }
  }

  /**
   * Mobile Authentication
   * @param verificationId Verification ID
   * @param code Verification Code
   */
  async phoneSignIn(verificationId: string, code: string): Promise<boolean> {
    if (verificationId && code) {
      const credential = firebase.auth.PhoneAuthProvider.credential(verificationId, code);
      return await this.signInWithCredential(credential);
    }
    this.promptError();
    return false;
  }

  /**
   * Anonymous Sign In
   */
  async anonymousSignIn() {
    this.updateLoginReady(false);
    try {
      await this.afAuth.signInAnonymously();
    } catch (err) {
      this.logError(err);
      this.promptError(err);
    }
  }

  /**
   * Sign in with Credential for navtive login.
   * Note: If currentUser existed, link with new credential else sign in directly
   * @param credential Firebase auth credential
   */
  async signInWithCredential(credential: firebase.auth.AuthCredential): Promise<boolean> {
    if (this.currentUser) {
      return await this.currentUser.linkWithCredential(credential).then(async (result: any) => {
        if (result?.user?.providerData && !this.functionService.isEmpty(result.user.providerData)) {
          await this.updateProvider(result.user.providerData);
          return true;
        } else {
          return false;
        }
      }).catch((err) => {
        this.logError(err);
        this.promptError(err);
        return false;
      });
    } else {
      try {
        await this.afAuth.signInWithCredential(credential);
        return true;
      } catch (err) {
        this.logError(err);
        this.promptError(err);
        return false;
      }
    }
  }

  /**
   * OAuth login for web login.
   * Note: If currentUser existed, link with new credential else sign in directly
   * @param provider Provider
   */
  async oAuthLogin(provider: any) {
    if (this.currentUser) {
      if (this.platform.is('hybrid')) {
        this.currentUser.linkWithRedirect(provider).then(() => {
          this.afAuth.getRedirectResult().then((result: any) => {
            if (result?.user?.providerData?.length) {
              this.updateProvider(result.user.providerData);
            }
          });
        }).catch((err) => {
          this.logError(err);
          this.promptError(err);
        });
      } else {
        this.currentUser.linkWithPopup(provider).then(result => {
          if (result?.user?.providerData?.length) {
            this.updateProvider(result.user.providerData);
          }
        }).catch((err) => {
          this.logError(err);
          this.promptError(err);
        });
      }
    } else {
      if (this.platform.is('hybrid')) {
        this.afAuth.signInWithRedirect(provider).then(() => {
          this.afAuth.getRedirectResult().then(() => {
          }).catch(err => {
            this.logError(err);
            this.promptError(err);
          });
        }).catch((err) => {
          this.logError(err);
          this.promptError(err);
        });
      } else {
        this.afAuth.signInWithPopup(provider).then((result) => {
        }).catch((err) => {
          this.logError(err);
          this.promptError(err);
        });
      }
    }
  }

  /**
   * Prompt authentication error
   * @param err Error message
   */
  async promptError(err?: any) {
    this.popupService.dismissLoading();
    if (err?.code) {
      if (err.code !== 'auth/popup-closed-by-user') {
        this.logError(err);

        const msg = this.translate.instant('AUTH.' + err.code + '.msg') !== 'AUTH.' + err.code + '.msg'
        ? this.translate.instant('AUTH.' + err.code + '.msg')
        : this.translate.instant('AUTH.error.msg');

        const alert = await this.popupService.presentAlert(msg);
        alert.onDidDismiss().then(() => {
          this.checkPromptErrorReady(err.code);
        });
      } else {
        this.checkPromptErrorReady(err.code);
      }
    } else if (err?.msg && (err.msg.indexOf('Sign in with Apple is available on iOS 13.0+ only.') !== -1 || err.msg.indexOf('Sign in with Apple is available on iOS 13.0+ only.') !== -1)) {
      const alert = await this.popupService.presentAlert(this.translate.instant('AUTH.ios13.msg'));
      alert.onDidDismiss().then(() => {
        this.updateLoginReady(true);
      });
    } else {
      const msg = err?.msg ? err.msg : this.translate.instant('AUTH.error.msg');
      const alert = await this.popupService.presentAlert(msg);
      alert.onDidDismiss().then(() => {
        this.updateLoginReady(true);
      });
    }
  }

  /**
   * Check error code for update login ready status.
   * Update only if error code is not related to mobile authentication.
   * @param errorCode Error Code
   */
  async checkPromptErrorReady(errorCode: string) {
    if (errorCode !== 'auth/invalid-verification-code' && errorCode !== 'auth/missing-verification-code' &&
      errorCode !== 'auth/invalid-mobile-number' && errorCode !== 'auth/mobile-number-already-exists' &&
      errorCode !== 'auth/too-many-requests'
    ) {
      this.updateLoginReady(true);
    }
  }

  /**
   * Link new provider from Firebase Authentication
   * @param providerId Provider Id
   */
  async linkProvider(providerId: string) {
    if (providerId) {
      switch (providerId) {
        case 'facebook.com': {
          await this.facebookSignIn(true);
          break;
        }
        case 'google.com': {
          await this.googleSignIn(true);
          break;
        }
        case 'apple.com': {
          await this.appleSignIn();
          break;
        }
        case 'twitter.com': {
          await this.twitterSignIn();
          break;
        }
        default: {
           break;
        }
      }
    }
  }

  /**
   * Unlink provider from Firebase Authentication
   * @param providerId Provider ID
   */
  async unlinkProvider(providerId: string) {
    if (providerId) {
      await this.popupService.presentLoading();
      await firebase.auth().currentUser.unlink(providerId).then(async (result: any) => {
        if (result?.providerData) {
          await this.updateProvider(result.providerData);
        } else {
          this.popupService.dismissLoading();
        }
      }).catch(err => {
        this.logError(err);
        this.promptError(err);
        this.popupService.dismissLoading();
      });
    }
  }

  /**
   * Update Firestore user provider data after link / unlink
   * @param providerData Provider Data
   */
  async updateProvider(providerData: Provider[]) {
    if (providerData) {
      if (!this.functionService.isEqual(this.userService.user.provider, providerData)) {
        const avatar = this.userService.user?.avatar;
        const mobile = this.userService.user?.mobile;
        const email = this.userService.user?.email;
        const data: any = {};
        const providerList = providerData.map((provider: Provider) => {
          if (provider.email && !email) {
            data.email = provider.email;
          }
          if (provider.phoneNumber && (provider.providerId === 'phone' || !mobile?.no)) {
            const phoneUtil = PhoneNumberUtil.getInstance();
            data.mobile = {
              code: phoneUtil.parseAndKeepRawInput(provider.phoneNumber).getCountryCode(),
              no: provider.phoneNumber,
              country: '',
            };
            data.mobile.country = this.mobileService.getCountryByPhoneCode(data.mobile.code);
          }
          if (provider.photoURL && !avatar?.url) {
            data.avatar = {
              url: provider.photoURL,
              type: 'provider'
            };
          }
          return { ...provider };
        });

        if (this.userService?.user?.isAnonymous) {
          data.isAnonymous = false;
        }
        if (providerList?.length) { data.provider = providerList; }

        if (!this.functionService.isEmpty(data)) {
          await this.userManageService.updateUser(data).then(() => {
          }).catch((err) => {
            this.logError(err);
          });
        }
      }
    }
    this.popupService.dismissLoading();
  }

  logError(err: any) {
    let valid = true;
    if (err && typeof err === 'string') {
      if (err.toLowerCase().indexOf('popup has been closed by the user') !== -1) {
        valid = false;
      }
    } else if (err?.msg && typeof err.msg === 'string') {
      if (err.msg.toLowerCase().indexOf('popup has been closed by the user') !== -1) {
        valid = false;
      }
    }
    if (valid) {
      this.errorService.logError(err);
    }
  }

}
