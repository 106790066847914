<ion-grid class="full-width" *ngIf="days || hours || minutes" fixed>
  <ion-row>
    <ion-col class="ion-text-center" size="4">
      <ion-label class="font-10">{{ 'LBL.days' | translate }}</ion-label>
    </ion-col>
    <ion-col class="ion-text-center" size="1">
    </ion-col>
    <ion-col class="ion-text-center" size="3">
      <ion-label class="font-10">{{ 'LBL.hours' | translate }}</ion-label>
    </ion-col>
    <ion-col class="ion-text-center" size="1">
    </ion-col>
    <ion-col class="ion-text-center" size="3">
      <ion-label class="font-10">{{ 'LBL.minutes' | translate }}</ion-label>
    </ion-col>
  </ion-row>
  <ion-row class="countdown-row">
    <ion-col class="ion-text-center" size="4">
      <div><ion-text class="font-34 font-bold">{{ days ? days : 0 }}</ion-text></div>
    </ion-col>
    <ion-col class="ion-text-center" size="1">
      <div class="flick"><ion-text class="font-34 font-bold">:</ion-text></div>
    </ion-col>
    <ion-col class="ion-text-center" size="3">
      <div><ion-text class="font-34 font-bold">{{ hours ? hours : 0 }}</ion-text></div>
    </ion-col>
    <ion-col class="ion-text-center" size="1">
      <div class="flick"><ion-text class="font-34 font-bold">:</ion-text></div>
    </ion-col>
    <ion-col class="ion-text-center" size="3">
      <div><ion-text class="font-34 font-bold">{{ minutes ? minutes : 0 }}</ion-text></div>
    </ion-col>
  </ion-row>
  <ion-row *ngIf="msg">
    <ion-col class="ion-text-center">
      <ng-container *ngIf="countDownType === 'rsvp_cutoff'">
        <quill-view [content]="msg"></quill-view>
      </ng-container>
      <ng-container *ngIf="countDownType === 'event_time'">
        <ion-text class="font-12 font-med ion-text-uppercase countdown-text">
          {{ msg }}
        </ion-text>
      </ng-container>
    </ion-col>
  </ion-row>
</ion-grid>