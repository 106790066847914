<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="back()">
        <ion-icon color="dark" class="back-icon" src="./assets/wedding/icon/general/chevron-back.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      {{ 'WEBSITE.link.title.edit' | translate }}
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content color="light">
  <form class="ion-padding-horizontal" [formGroup]="form" *ngIf="form">
        

    <ion-item class="ion-item-no-padding background-transparent" lines="none">
      <ion-label position="stacked" color="dark">
        {{ 'WEBSITE.link.lbl.name' | translate }}&nbsp;<ion-text color="danger">*</ion-text>
        <!-- <span class="font-underline ion-float-right" (click)="resetName()">{{ 'BTN.reset' | translate }}</span> -->
      </ion-label>
      
      <ion-item class="website-input-item" lines="none">
        <div class="font-12 full-width">
          <ion-input formControlName="name" type="text" inputmode="text"></ion-input>
        </div>
      </ion-item>
      <div class="error-div">
        <ng-container *ngFor="let validation of validationMsg?.name">
          <div class="error-message" *ngIf="form?.get('name').hasError(validation.type) && (form?.get('name').dirty || form?.get('name').touched)">
            <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ validation.msg }}
          </div>
        </ng-container>
      </div>
    </ion-item>

    <ion-item class="website-input-item" lines="none">
      <ion-toggle formControlName="invitedByEnable" (ionChange)="toggleInvitedBy()" color="success">
        <ion-text class="font-12 ion-text-wrap" color="dark">
          <span *ngIf="form?.value?.invitedByEnable" [innerHTML]="'WEBSITE.link.invitedBy.on' | translate"></span>
          <span *ngIf="!form?.value?.invitedByEnable" [innerHTML]="'WEBSITE.link.invitedBy.on' | translate"></span>
        </ion-text>
      </ion-toggle>
    </ion-item>
    <div class="error-div">
    </div>

    <ion-item class="ion-item-no-padding background-transparent" lines="none" (click)="presentSettingFieldModal('invited_by', form?.value?.invitedBy)" *ngIf="form?.value?.invitedByEnable">
      <ion-label position="stacked" color="dark">{{ 'GUEST.lbl.invited_by' | translate }}&nbsp; <ion-text color="danger">*</ion-text></ion-label>
      <ion-item class="website-input-item" lines="none">
        <ion-label class="font-12 ion-no-margin" >{{ getSettingField('invited_by', form.value.invitedBy) }}</ion-label>
        <ion-note slot="end"><ion-icon src="./assets/wedding/icon/general/chevron-down.svg" class="font-18"></ion-icon></ion-note>
      </ion-item>
      <div class="error-div">
        <ng-container *ngFor="let validation of validationMsg?.invitedBy">
          <div class="error-message" *ngIf="form?.get('invitedBy').hasError(validation.type) && (form?.get('invitedBy').dirty || form?.get('invitedBy').touched)">
            <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ validation.msg }}
          </div>
        </ng-container>
      </div>
    </ion-item>

    <ion-item class="website-input-item" lines="none">
      <ion-toggle formControlName="categoryEnable" (ionChange)="toggleCategory()" color="success">
        <ion-text class="font-12 ion-text-wrap" color="dark">
          <span *ngIf="form?.value?.categoryEnable" [innerHTML]="'WEBSITE.link.category.on' | translate"></span>
          <span *ngIf="!form?.value?.categoryEnable" [innerHTML]="'WEBSITE.link.category.on' | translate"></span>
        </ion-text>
      </ion-toggle>
    </ion-item>
    <div class="error-div">
    </div>
  
    <ion-item class="ion-item-no-padding background-transparent" lines="none" (click)="presentSettingFieldModal('category', form?.value?.category)" *ngIf="form?.value?.categoryEnable">
      <ion-label position="stacked" color="dark">{{ 'LBL.category' | translate }}&nbsp;<ion-text color="danger">*</ion-text></ion-label>
      <ion-item class="website-input-item" lines="none">
        <ion-label class="font-12 ion-no-margin" >{{ getSettingField('category', form.value.category) }}</ion-label>
        <ion-note slot="end"><ion-icon src="./assets/wedding/icon/general/chevron-down.svg" class="font-18"></ion-icon></ion-note>
      </ion-item>
      <div class="error-div">
        <ng-container *ngFor="let validation of validationMsg?.category">
          <div class="error-message" *ngIf="form?.get('category').hasError(validation.type) && (form?.get('category').dirty || form?.get('category').touched)">
            <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ validation.msg }}
          </div>
        </ng-container>
      </div>
    </ion-item>

    <!-- <ion-item class="website-input-item" lines="none">
      <ion-toggle formControlName="group" (ionChange)="toggleGroup()" color="success">
        <ion-text class="font-12 ion-text-wrap" color="dark">
          <span *ngIf="form?.value?.group" [innerHTML]="'WEBSITE.link.group.on' | translate"></span>
          <span *ngIf="!form?.value?.group" [innerHTML]="'WEBSITE.link.group.on' | translate"></span>
        </ion-text>
      </ion-toggle>
    </ion-item>
    <div class="error-div">
    </div>

    <ng-container *ngIf="form?.value?.group">
      <ion-item class="ion-item-no-padding background-transparent" lines="none">
        <ion-label position="stacked" color="dark">{{ 'WEBSITE.link.lbl.max_guest' | translate }}&nbsp;<ion-text color="danger">*</ion-text></ion-label>
        <ion-item class="website-input-item full-width ion-item-no-padding" lines="none">
          <ion-input class="ion-text-center" formControlName="maxGuest" [min]="1" type="number" inputmode="numeric" tabindex="1">
            <ion-button class="qty-btn" slot="start" (click)="minusPax()" fill="clear">
              <ion-icon color="medium" src="./assets/wedding/icon/general/remove.svg"></ion-icon>
            </ion-button>
            <ion-button class="qty-btn" slot="end" (click)="addPax()" fill="clear">
              <ion-icon color="medium" src="./assets/wedding/icon/general/add.svg"></ion-icon>
            </ion-button>
          </ion-input>
        </ion-item>
      </ion-item>
      <div class="error-div">
        <ng-container *ngFor="let validation of validationMsg?.maxGuest">
          <div class="error-message" *ngIf="form?.get('maxGuest').hasError(validation.type) && (form?.get('maxGuest').dirty || form?.get('maxGuest').touched)">
            <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ validation.msg }}
          </div>
        </ng-container>
      </div>
    </ng-container> -->
  </form>
            
  <ion-item-sliding class="ion-margin-vertical" *ngIf="websiteLink?.linkId" #deleteItem>
    <ion-item-options side="start" (ionSwipe)="promptRemove(deleteItem)" lines="none">
      <ion-item-option color="danger" (click)="promptRemove(deleteItem)" lines="none">
        <ion-icon src="./assets/wedding/icon/general/checkmark.svg"></ion-icon>
      </ion-item-option>
    </ion-item-options>
    <ion-item color="danger" lines="none">
      <ion-icon slot="start" class="icon-small" src="./assets/wedding/icon/general/trash.svg"></ion-icon> 
      <ion-text slot="start">{{ 'CRUD.swipe_field' | translate: { field: 'CRUD.delete' | translate } }}</ion-text> 
      <ion-icon slot="start" class="icon-small" src="./assets/wedding/icon/general/arrow-forward.svg"></ion-icon>
    </ion-item>
    <ion-item-options side="end" (ionSwipe)="promptRemove(deleteItem)" lines="none">
      <ion-item-option color="danger" (click)="promptRemove(deleteItem)" lines="none">
        <ion-icon src="./assets/wedding/icon/general/checkmark.svg"></ion-icon>
      </ion-item-option>
    </ion-item-options>
  </ion-item-sliding>

</ion-content>
<ion-footer>
  <ion-toolbar>
    <ion-button expand="block" (click)="save()" [disabled]="!form?.valid">
      {{ 'BTN.save' | translate }}
    </ion-button>
  </ion-toolbar>
</ion-footer>
