import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { ModalController, Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';

import { ModuleService } from 'src/app/services/account/privilege/module.service';
import { AccountUserService } from 'src/app/services/account/account-user.service';
import { InvitedByService } from 'src/app/services/setting/invited-by.service';
import { SettingFieldService } from 'src/app/services/setting/setting-field.service';
import { GiftService } from 'src/app/services/gift/gift.service';
import { FunctionService } from 'src/app/services/general/function.service';

import { AccountUserComponent } from 'src/app/components/account/account-user/account-user.component';

import { SettingField } from 'src/app/interfaces/database';
import { Range } from 'src/app/interfaces/general';
import { SettingFieldType, ModuleType } from 'src/app/types/general';
import { GuestSortType, GuestListMode } from 'src/app/types/guest';
import { SettingFieldComponent } from 'src/app/components/setting/setting-field/setting-field.component';
import { CheckinSettingService } from 'src/app/services/checkin/checkin-setting.service';
import { CheckinSetting } from 'src/app/interfaces/setting';
import { SeatingSettingService } from 'src/app/services/seating/seating-setting.service';

/**
 * Guest list filter component
 */
@Component({
  selector: 'app-guest-list-filter',
  templateUrl: './guest-list-filter.component.html',
  styleUrls: ['./guest-list-filter.component.scss'],
})
export class GuestListFilterComponent implements OnInit, OnDestroy {

  /**
   * Currency list
   */
  currencyList: string[];
  /**
   * Min / max cash gift amount
   */
  amount: Range;
  /**
   * Sorting type
   */
  sorting: GuestSortType;
  /**
   * Descending flag
   */
  desc: boolean;
  /**
   * Selected filter conditions
   */
  filter: any;
  /**
   * AccountModule
   */
  module: ModuleType;
  /**
   * Sorting field
   */
  sortingField: {
    name?: boolean,
    seating?: boolean,
    invitedBy?: boolean,
    category?: boolean,
    checkinTime?: boolean,
    createdTime?: boolean,
    updatedTime?: boolean,
    deletedTime?: boolean,
    rsvpTime?: boolean,
  };
  /**
   * Show field
   */
  showField: {
    rsvp?: boolean;
    giftStatus?: boolean,
    giftType?: boolean,
    currency?: boolean,
    amount?: boolean,
    invitedBy?: boolean,
    category?: boolean,
    checkinStatus?: boolean,
    status?: boolean,
    giftReceived?: boolean,
    group?: boolean,
    seating?: boolean,
    dietaryReq?: boolean,
    specialReq?: boolean,
    session?: boolean,
    mobile?: boolean,
    email?: boolean,
    createdBy?: boolean,
    updateBy?: boolean,
    checkinBy?: boolean,
    deleteBy?: boolean,
    giftBy?: boolean,
  };
  /**
   * Hide field
   */
  hideField: any;
  /**
   * Filter form
   */
  filterForm: FormGroup;
  /**
   * Invited by list
   */
  invitedByList: SettingField[];

  seatingTypeName: string;

  category: string;
  dietaryReq: string;
  specialReq: string;
  session: string;
  /**
   * Guest list mode
   */
   private guestListMode: GuestListMode;
  /**
   * Invited by subscription
   */
  private invitedBySubscription: Subscription;
  /**
   * Currency list subscription
   */
  private currencyListSubscription: Subscription;
  /**
   * amount subscription
   */
  private amountSubscription: Subscription;

  /**
   * Constructor
   * @param platform platform
   * @param formBuilder form builder
   * @param modalController modal controller
   * @param moduleService module service
   * @param settingFieldService setting field service
   * @param invitedByService invited by service
   * @param accountUserService account user service
   * @param giftService gift service
   * @param functionService function service
   */
  constructor(
    private platform: Platform,
    private formBuilder: FormBuilder,
    private modalController: ModalController,
    private moduleService: ModuleService,
    private settingFieldService: SettingFieldService,
    private invitedByService: InvitedByService,
    private accountUserService: AccountUserService,
    private seatingSettingService: SeatingSettingService,
    private checkinSettingService: CheckinSettingService,
    private giftService: GiftService,
    private functionService: FunctionService,
  ) { }

  ngOnInit() {}

  ngOnDestroy() {
    this.unwatch();
  }

  /**
   * Before view enter
   */
  ionViewWillEnter() {
    if (!this.module) {
      this.module = this.moduleService.currentModule;
    }
    this.watch();
    this.initialize();
  }

  /**
   * Before view leave
   */
  ionViewWillLeave() {
    this.unwatch();
  }

  watch() {
    this.watchInvitedBy();
    if (this.module === 'gift') {
      this.watchCurrencyList();
      this.watchAmount();
    }
  }

  unwatch() {
    this.unwatchInvitedBy();
    this.unwatchCurrencyList();
    this.unwatchAmount();
  }

  /**
   * Initialize
   */
  async initialize() {
    this.showField = {};
    this.sortingField = {};

    this.showField.rsvp = false;
    this.showField.category = true;
    this.showField.createdBy = true;
    this.showField.dietaryReq = true;
    this.showField.group = true;
    this.showField.invitedBy = true;
    this.showField.seating = true;
    this.showField.specialReq = true;
    this.showField.session = true;
    this.showField.mobile = true;
    this.showField.email = true;
    this.showField.status = true;
    this.showField.updateBy = true;

    this.sortingField.category = true;
    this.sortingField.createdTime = true;
    this.sortingField.invitedBy = true;
    this.sortingField.name = true;
    this.sortingField.seating = true;
    this.sortingField.updatedTime = true;

    if (this.module === 'guest') {
    } else if (this.module === 'trash') {
      this.showField.deleteBy = true;
      this.sortingField.deletedTime = true;
    } else if (this.module === 'checkin') {
      this.showField.checkinBy = true;
      this.showField.checkinStatus = true;
      this.sortingField.checkinTime = true;
      if (this.getCheckinSetting()?.giftRecord && !this.getCheckinSetting()?.giftDetail) {
        this.showField.giftReceived = true;
      }
    } else if (this.module === 'gift') {
      this.showField.amount = true;
      this.showField.currency = true;
      this.showField.giftStatus = true;
      this.showField.giftType = true;
      this.showField.giftBy = true;
      this.showField.checkinStatus = true;
    } else if (this.module === 'website') {
      // module invites
      this.showField.rsvp = false;
      this.sortingField.rsvpTime = true;
    }

    this.seatingTypeName = this.getSeatingTypeName();
    
    this.setupForm();
    this.setupShowField();

    this.checkHideField();
    if (this.filter) {
      this.setupFormValue();
    }
  }

  /**
   * Check hide field
   */
  checkHideField() {
    if (this.hideField) {
      Object.keys(this.hideField)?.forEach((key: string) => {
        if (this.showField[key]) {
          this.showField[key] = false;
        }
      });
    }
  }

  /**
   * Watch invited by list
   */
  async watchInvitedBy() {
    if (!this.invitedBySubscription) {
      this.invitedBySubscription = this.invitedByService.observableInvitedbyList.subscribe((invitedByList: SettingField[]) => {
        this.invitedByList = this.invitedByService.getInvitedByList();
      });
    }
    
  }

  /**
   * Unwatch invited by list
   */
  async unwatchInvitedBy() {
    if (this.invitedBySubscription) {
      this.invitedBySubscription.unsubscribe();
      this.invitedBySubscription = null;
    }
  }

  /**
   * Watch currency list
   */
  async watchCurrencyList() {
    if (!this.currencyListSubscription) {
      this.currencyListSubscription = this.giftService.observableCurrencyList.subscribe((currencyList: string[]) => {
        this.currencyList = currencyList;
      });
    }
    
  }

  /**
   * Unwatch currency list
   */
  async unwatchCurrencyList() {
    if (this.currencyListSubscription) {
      this.currencyListSubscription.unsubscribe();
      this.currencyListSubscription = null;
    }
  }

  /**
   * Watch amount
   */
  async watchAmount() {
    if (!this.amountSubscription) {
      this.amountSubscription = this.giftService.observableAmount.subscribe((amount: any) => {
        this.amount = amount;
      });
    }
    
  }

  /**
   * Unwatch amount
   */
  async unwatchAmount() {
    if (this.amountSubscription) {
      this.amountSubscription.unsubscribe();
      this.amountSubscription = null;
    }
  }

  /**
   * Setup form
   */
  setupForm() {
    this.filterForm = this.formBuilder.group({
      rsvp: new FormControl('', []),
      giftStatus: new FormControl('', []),
      giftType: new FormControl('', []),
      currency: new FormControl('', []),
      amount: new FormControl('', []),
      invitedBy: new FormControl('', []),
      category: new FormControl('', []),
      group: new FormControl('', []),
      seating: new FormControl('', []),
      checkinStatus: new FormControl('', []),
      giftReceived: new FormControl('', []),
      dietaryReq: new FormControl('', []),
      specialReq: new FormControl('', []),
      session: new FormControl('', []),
      mobile: new FormControl('', []),
      email: new FormControl('', []),
      attending: new FormControl('', []),
      createBy: new FormControl('', []),
      updateBy: new FormControl('', []),
      checkinBy: new FormControl('', []),
      deleteBy: new FormControl('', []),
      giftBy: new FormControl('', [])
    });
  }

  /**
   * Setup fitler form value
   */
  async setupFormValue() {
    await this.platform.ready();
    if (this.filter && this.filterForm) {
      if (!this.functionService.isUndefined(this.filter?.rsvp)) {
        this.filterForm.controls.rsvp.setValue(this.filter.rsvp);
      }
      if (this.filter.giftStatus) {
        this.filterForm.controls.giftStatus.setValue(this.filter.giftStatus);
      }
      if (this.filter.giftType) {
        this.filterForm.controls.giftType.setValue(this.filter.giftType);
      }
      if (this.filter.giftReceived) {
        this.filterForm.controls.giftReceived.setValue(this.filter.giftReceived);
      }
      if (this.filter.currency) {
        this.filterForm.controls.currency.setValue(this.filter.currency);
      }
      if (this.filter.amount) {
        this.filterForm.controls.amount.setValue(this.filter.amount);
      }
      if (this.filter.invitedBy) {
        this.filterForm.controls.invitedBy.setValue(this.filter.invitedBy);
      }
      if (this.filter.category) {
        this.filterForm.controls.category.setValue(this.filter.category);
      }
      if (this.filter.group) {
        this.filterForm.controls.group.setValue(this.filter.group);
      }
      if (this.filter.seating) {
        this.filterForm.controls.seating.setValue(this.filter.seating);
      }
      if (this.filter?.dietaryReq) {
        this.filterForm.controls.dietaryReq.setValue(this.filter.dietaryReq);
      }
      if (this.filter.specialReq) {
        this.filterForm.controls.specialReq.setValue(this.filter.specialReq);
      }
      if (this.filter.session) {
        this.filterForm.controls.session.setValue(this.filter.session);
      }
      if (this.filter.mobile) {
        this.filterForm.controls.mobile.setValue(this.filter.mobile);
      }
      if (this.filter.email) {
        this.filterForm.controls.email.setValue(this.filter.email);
      }
      if (this.filter?.status?.attending) {
        this.filterForm.controls.attending.setValue(this.filter.status.attending);
      }
      if (this.filter?.checkinStatus) {
        this.filterForm.controls.checkinStatus.setValue(this.filter.checkinStatus);
      }
      if (!this.functionService.isUndefined(this.filter?.status?.checkinStatus)) {
        this.filterForm.controls.checkinStatus.setValue(this.filter.status.checkinStatus ? 'attended' : 'not_attend');
      }
      if (this.filter?.createBy) {
        this.filterForm.controls.createBy.setValue(this.filter.createBy);
      }
      if (this.filter.updateBy) {
        this.filterForm.controls.updateBy.setValue(this.filter.updateBy);
      }
      if (this.filter.checkinBy) {
        this.filterForm.controls.checkinBy.setValue(this.filter.checkinBy);
      }
      if (this.filter.deleteBy) {
        this.filterForm.controls.deleteBy.setValue(this.filter.deleteBy);
      }
      if (this.filter.giftBy) {
        this.filterForm.controls.giftBy.setValue(this.filter.giftBy);
      }
      this.setupSettingField();
    }
  }

  setupSettingField() {
    this.category = this.getSettingField('category', this.filterForm?.value?.category);
    this.dietaryReq = this.getSettingField('dietary_req', this.filterForm?.value?.dietaryReq);
    this.specialReq = this.getSettingField('special_req', this.filterForm?.value?.specialReq);
    this.session = this.getSettingField('session', this.filterForm?.value?.session);
  }

  getCheckinSetting(): CheckinSetting {
    return this.checkinSettingService.checkinSetting;
  }

  getSeatingTypeName(): string {
    return this.seatingSettingService.getSeatingTypeName();
  }

  /**
   * Check is form is empty
   * @returns true if form is empty
   */
  isEmptyForm(): boolean {
    let empty = true;
    if (this.filterForm?.value) {
      Object.keys(this.filterForm.value)?.forEach(key => {
        if (key && this.filterForm?.value?.[key] && empty) { empty = false; }
      });
    }
    return empty;
  }

  /**
   * Reset filter form
   */
  reset() {
    this.filterForm.reset();
  }

  /**
   * Setup show field based on guest list mode.
   */
  setupShowField() {
    if (this.guestListMode === 'attending') {
      this.showField.status = false;
    } else if (this.guestListMode === 'group') {
      this.showField.group = false;
    } else if (this.guestListMode === 'seating') {
      this.showField.seating = false;
    }
  }

  /**
   * Present setting field cmodal
   * @param settingFieldType Setting field type
   */
   async presentSettingFieldModal(settingFieldType: SettingFieldType, selected: SettingField[]) {
    const modal = await this.modalController.create({
      component: SettingFieldComponent,
      componentProps: {
        settingFieldType,
        selected
      }
    });
    modal.present();
    modal.onWillDismiss().then((result: any) => {
      if (result?.data?.selected) {
        if (settingFieldType === 'category') {
          if (this.filterForm.value.category !== result.data.selected) {
            this.filterForm.controls.category.setValue(result.data.selected);
            this.setupSettingField();
          }
        } else if (settingFieldType === 'dietary_req') {
          if (this.filterForm.value.dietaryReq !== result.data.selected) {
            this.filterForm.controls.dietaryReq.setValue(result.data.selected);
            this.setupSettingField();
          }
        } else if (settingFieldType === 'special_req') {
          if (this.filterForm.value.specialReq !== result.data.selected) {
            this.filterForm.controls.specialReq.setValue(result.data.selected);
            this.setupSettingField();
          }
        } else if (settingFieldType === 'session') {
          if (this.filterForm.value.session !== result.data.selected) {
            this.filterForm.controls.session.setValue(result.data.selected);
            this.setupSettingField();
          }
        }
      }
    });
  }

  /**
   * Present account user modal to select user
   * @param type type - createBy / updateBy
   */
  async presentAccountUserModal(type: string) {
    if (type) {
      const modal = await this.modalController.create({
        component: AccountUserComponent,
        componentProps: { selectedUid: this.filterForm.value[type] }
      });
      modal.present();
      modal.onWillDismiss().then((result: any) => {
        if (result?.data?.uid) {
          this.filterForm.controls[type].setValue(result.data.uid);
        }
      });
    }
  }

  /**
   * Dismiss Guest list filter modal
   * @param filter filter
   */
  async dismissModal(filter?: any) {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { this.modalController.dismiss({
        filter,
        sorting: this.sorting,
        desc: this.desc
      }); }
    }
  }

  /**
   * Get list of setting field value
   * @param settingFieldType Setting field type
   * @param settingFieldList Setting field list
   * @returns Join of setting field value
   */
  getSettingField(settingFieldType: SettingFieldType, settingFieldList: SettingField[]): string {
    return this.settingFieldService.joinSettingField(settingFieldType, settingFieldList);
  }

  /**
   * Get user name by uid
   * @param uid User ID
   * @returns User name
   */
  getUserName(uid: string): string {
    if (uid) {
      return this.accountUserService.getUserByUid(uid)?.name;
    } else {
      return null;
    }
  }

  /**
   * Submit filter form.
   */
  filterFormSubmit() {
    this.filterForm.markAllAsTouched();
    if (this.filterForm.valid) {
      const filter = this.filterForm.value;

      if (filter.attending) {
        filter.status = {
          attending: filter.attending
        };
      }

      if (filter.checkinStatus) {
        filter.status = {
          checkin: filter.checkinStatus === 'attended' ? true : false
        };
      }

      if (!this.isEmptyForm()) {
        filter.enable = true;
      }
      this.dismissModal(filter);
    }
  }

}
