import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { GuestListService } from 'src/app/services/guest/guest-list.service';

import { GuestListComponent } from 'src/app/components/guest/guest-list/guest-list.component';
import { Guest } from 'src/app/interfaces/guest';

/**
 * Alert component
 */
@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit, OnDestroy {

  font: string;
  backgroundColor: string;
  color: string;
  /**
   * Title
   */
  title: any;
  /**
   * Subtitle
   */
  subtitle: any;
  /**
   * Message
   */
  msg: any;
  /**
   * Btn text
   */
  btn?: string;
  /**
   * Preview guest list for prompt action
   */
  previewGuestList: Guest[];
  hideWarning: boolean;
  /**
   * Show guest seating
   */
  private showGuestSeating: boolean;

  /**
   * Constructor
   * @param modalController Modal Controller
   */
  constructor(
    private modalController: ModalController,
    private guestListService: GuestListService,
  ) { }

  ngOnInit() {}

  ngOnDestroy() {}

  /**
   * Dismiss alert modal
   */
  async dismissModal() {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { modal.dismiss(); }
    }
  }

  /**
   * Present guest list modal to preivew
   */
  async presentGuestListModal() {
    if (this.previewGuestList?.length) {
      const guestList = this.guestListService.generateGroupList(this.previewGuestList);
      const modal = await this.modalController.create({
        component: GuestListComponent,
        cssClass: '',
        componentProps: {
          guestList,
          mode: 'preview',
          editMode: true,
          count: this.previewGuestList.length,
          showGuestSeating: this.showGuestSeating,
        }
      });
      modal.present();
    }
  }

}
