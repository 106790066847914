import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { PickerController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { AccountCoupleService } from 'src/app/services/account/account-couple.service';
import { PopupService } from 'src/app/services/general/popup.service';
import { FunctionService } from 'src/app/services/general/function.service';

import { AccountCouple } from 'src/app/interfaces/account';

@Component({
  selector: 'app-account-couple-type',
  templateUrl: './account-couple-type.component.html',
  styleUrls: ['./account-couple-type.component.scss'],
})
export class AccountCoupleTypeComponent implements OnInit, OnDestroy {

  @Input() showEventMode: boolean;
  @Input() hideRole: boolean;
  @Input() eventMode: boolean;
  /**
   * footer flag for component mode
   */
  @Input() footer: boolean;
  /**
   * save mode flag for component mode
   */
  @Input() saveMode: boolean;
  /**
   * Initialize and watch  couple type
   */
  @Input() set setReady(ready: boolean) {
    if (ready) {
      this.initialize();
    } else {
      this.unwatchCouple();
    }
  }
  /**
   * output couple type result for component mode
   */
  @Output() outputCoupleType = new EventEmitter<AccountCouple[]>();
  @Output() outputEventMode = new EventEmitter<boolean>();

  
  /**
   * Show advance for couple type configuration
   */
  showAdvance: boolean;
  /**
   * Couple type list
   */
  accountCoupleList: AccountCouple[];

  /**
   * Account couple subcription
   */
  private accountCoupleSubscription: Subscription;

  /**
   * Constructor
   * @param pickerController picker controller
   * @param translate translater service
   * @param accountCoupleService account couple service
   * @param functionService general funciton service
   * @param popupService popup service
   */
  constructor(
    private pickerController: PickerController,
    private translate: TranslateService,
    private accountCoupleService: AccountCoupleService,
    private functionService: FunctionService,
    private popupService: PopupService,
  ) { }

  ngOnInit(): void {
    
  }

  ngOnDestroy(): void {
    this.unwatchCouple();
  }

  /**
   * Before view enter
   */
  ionViewWillEnter() {
    this.initialize();
  }

  /**
   * Before view leave
   */
  ionViewWillLeave() {
    this.unwatchCouple();
  }

  initialize() {
    if (!this.eventMode) {
      this.eventMode = false;
    }
    this.watchCouple();
  }

  /**
   * Watch couple type and couple list
   */
  async watchCouple() {
    if (!this.accountCoupleSubscription) {
      this.accountCoupleSubscription = this.accountCoupleService.observableCoupleType.subscribe((accountCoupleList: AccountCouple[]) => {
        if (!accountCoupleList?.length || !this.accountCoupleService.accountId) {
          this.accountCoupleList = this.functionService.cloneDeep(this.accountCoupleService.getStdCoupleType());
        } else {
          this.accountCoupleList = this.functionService.cloneDeep(accountCoupleList);
        }
      });
    }
  }

  /**
   * Unwatch couple type and couple list
   */
  async unwatchCouple() {
    if (this.accountCoupleSubscription) {
      this.accountCoupleSubscription.unsubscribe();
      this.accountCoupleSubscription = null;
    }
  }
  /**
   * Toggle Advance
   */
  toggleAdvance() {
    this.showAdvance = !this.showAdvance;
  }

  /**
   * Present Couple type picker
   * @param coupleId Couple ID
   */
  async presentCoupleTypePicker(coupleId: number) {
    if (coupleId) {
      const options = [];
      this.accountCoupleService?.stdCoupleType?.forEach((value: string) => {
        options.push({
          value,
          text: this.translate.instant('LIST.wedding_couple_type.' + value)
        });
      });
      const accountCoupleIndex = this.accountCoupleList.findIndex((x: AccountCouple) => {
        return x.coupleId === coupleId;
      });
      const selectedIndex = options?.findIndex(x => x?.value === this.accountCoupleList?.[accountCoupleIndex]?.coupleType);
      if (selectedIndex !== -1) {
        const picker = await this.pickerController.create({
          columns: [{
            name: 'accountCouple',
            selectedIndex,
            options
          }],
          buttons: [{
            text: this.translate.instant('BTN.cancel'),
            role: 'cancel'
          }, {
            text: this.translate.instant('BTN.confirm'),
            handler: (result: any) => {
              if (result?.accountCouple?.value) {
                const accountCoupleIndex = this.accountCoupleList?.findIndex((x: AccountCouple) => x.coupleId === coupleId);
                if (accountCoupleIndex !== -1 &&
                this.accountCoupleList?.[accountCoupleIndex]?.coupleType !== result?.accountCouple?.value) {
                  this.accountCoupleList[accountCoupleIndex].coupleType = result.accountCouple.value;
                  if (this.saveMode) {
                    this.saveCoupleType();
                  } else {
                    this.outputCoupleType.next(this.accountCoupleList);
                  }
                }
              }
            }
          }]
        });
        picker.present();
      }
    }
  }

  setEventMode() {
    this.outputEventMode.next(this.eventMode);
  }

  /**
   * Save Couple Type
   */
  async saveCoupleType() {
    await this.popupService.presentLoading();
    await this.accountCoupleService.updateAccountCouple({ accountCoupleList: this.accountCoupleList });
    this.popupService.dismissLoading();
    this.popupService.saveSuccessToast();
  }
}
