import { Timezone } from '../interfaces/database';


export const TimeFormatList12Hr = ['US', 'CA', 'MX', 'PH', 'AU', 'NZ', 'IN', 'PK', 'LK', 'BD', 'NP', 'MY', 'SG', 'GB', 'IE', 'ZA', 'JM', 'BB', 'TT', 'BM'];

/**
 * Preloaded timezone list database
 */
export const TimezoneList: Timezone[] = [{
  utc: 'UTC+14:00',
  name: 'Pacific/Kiritimati',
  label: 'Pacific/Kiritimati (UTC+14)',
  offset: 840
}, {
  utc: 'UTC+13:00',
  name: 'Pacific/Apia',
  label: 'Pacific/Apia (UTC+13)',
  offset: 780
}, {
  utc: 'UTC+13:00',
  name: 'Pacific/Enderbury',
  label: 'Pacific/Enderbury (UTC+13)',
  offset: 780
}, {
  utc: 'UTC+13:00',
  name: 'Pacific/Fakaofo',
  label: 'Pacific/Fakaofo (UTC+13)',
  offset: 780
}, {
  utc: 'UTC+13:00',
  name: 'Pacific/Tongatapu',
  label: 'Pacific/Tongatapu (UTC+13)',
  offset: 780
}, {
  utc: 'UTC+12:45',
  name: 'Pacific/Chatham',
  label: 'Pacific/Chatham (UTC+12:45)',
  offset: 747
}, {
  utc: 'UTC+12:00',
  name: 'Antarctica/McMurdo',
  label: 'Antarctica/McMurdo (NZST)',
  offset: 720
}, {
  utc: 'UTC+12:00',
  name: 'Asia/Anadyr',
  label: 'Asia/Anadyr (UTC+12)',
  offset: 720
}, {
  utc: 'UTC+12:00',
  name: 'Asia/Kamchatka',
  label: 'Asia/Kamchatka (UTC+12)',
  offset: 720
}, {
  utc: 'UTC+12:00',
  name: 'Pacific/Auckland',
  label: 'Pacific/Auckland (NZST)',
  offset: 720
}, {
  utc: 'UTC+12:00',
  name: 'Pacific/Fiji',
  label: 'Pacific/Fiji (UTC+12)',
  offset: 720
}, {
  utc: 'UTC+12:00',
  name: 'Pacific/Funafuti',
  label: 'Pacific/Funafuti (UTC+12)',
  offset: 720
}, {
  utc: 'UTC+12:00',
  name: 'Pacific/Kwajalein',
  label: 'Pacific/Kwajalein (UTC+12)',
  offset: 720
}, {
  utc: 'UTC+12:00',
  name: 'Pacific/Majuro',
  label: 'Pacific/Majuro (UTC+12)',
  offset: 720
}, {
  utc: 'UTC+12:00',
  name: 'Pacific/Nauru',
  label: 'Pacific/Nauru (UTC+12)',
  offset: 720
}, {
  utc: 'UTC+12:00',
  name: 'Pacific/Tarawa',
  label: 'Pacific/Tarawa (UTC+12)',
  offset: 720
}, {
  utc: 'UTC+12:00',
  name: 'Pacific/Wake',
  label: 'Pacific/Wake (UTC+12)',
  offset: 720
}, {
  utc: 'UTC+12:00',
  name: 'Pacific/Wallis',
  label: 'Pacific/Wallis (UTC+12)',
  offset: 720
}, {
  utc: 'UTC+11:00',
  name: 'Antarctica/Casey',
  label: 'Antarctica/Casey (UTC+11)',
  offset: 660
}, {
  utc: 'UTC+11:00',
  name: 'Antarctica/Macquarie',
  label: 'Antarctica/Macquarie (UTC+11)',
  offset: 660
}, {
  utc: 'UTC+11:00',
  name: 'Asia/Magadan',
  label: 'Asia/Magadan (UTC+11)',
  offset: 660
}, {
  utc: 'UTC+11:00',
  name: 'Asia/Sakhalin',
  label: 'Asia/Sakhalin (UTC+11)',
  offset: 660
}, {
  utc: 'UTC+11:00',
  name: 'Asia/Srednekolymsk',
  label: 'Asia/Srednekolymsk (UTC+11)',
  offset: 660
}, {
  utc: 'UTC+11:00',
  name: 'Pacific/Bougainville',
  label: 'Pacific/Bougainville (UTC+11)',
  offset: 660
}, {
  utc: 'UTC+11:00',
  name: 'Pacific/Efate',
  label: 'Pacific/Efate (UTC+11)',
  offset: 660
}, {
  utc: 'UTC+11:00',
  name: 'Pacific/Guadalcanal',
  label: 'Pacific/Guadalcanal (UTC+11)',
  offset: 660
}, {
  utc: 'UTC+11:00',
  name: 'Pacific/Kosrae',
  label: 'Pacific/Kosrae (UTC+11)',
  offset: 660
}, {
  utc: 'UTC+11:00',
  name: 'Pacific/Norfolk',
  label: 'Pacific/Norfolk (UTC+11)',
  offset: 660
}, {
  utc: 'UTC+11:00',
  name: 'Pacific/Noumea',
  label: 'Pacific/Noumea (UTC+11)',
  offset: 660
}, {
  utc: 'UTC+11:00',
  name: 'Pacific/Pohnpei',
  label: 'Pacific/Pohnpei (UTC+11)',
  offset: 660
}, {
  utc: 'UTC+10:30',
  name: 'Australia/Lord_Howe',
  label: 'Australia/Lord Howe (UTC+10:30)',
  offset: 630
}, {
  utc: 'UTC+10:00',
  name: 'Antarctica/DumontDUrville',
  label: 'Antarctica/DumontDUrville (UTC+10)',
  offset: 600
}, {
  utc: 'UTC+10:00',
  name: 'Asia/Ust-Nera',
  label: 'Asia/Ust-Nera (UTC+10)',
  offset: 600
}, {
  utc: 'UTC+10:00',
  name: 'Asia/Vladivostok',
  label: 'Asia/Vladivostok (UTC+10)',
  offset: 600
}, {
  utc: 'UTC+10:00',
  name: 'Australia/Brisbane',
  label: 'Australia/Brisbane (AEST)',
  offset: 600
}, {
  utc: 'UTC+10:00',
  name: 'Australia/Currie',
  label: 'Australia/Currie (AEST)',
  offset: 600
}, {
  utc: 'UTC+10:00',
  name: 'Australia/Hobart',
  label: 'Australia/Hobart (AEST)',
  offset: 600
}, {
  utc: 'UTC+10:00',
  name: 'Australia/Lindeman',
  label: 'Australia/Lindeman (AEST)',
  offset: 600
}, {
  utc: 'UTC+10:00',
  name: 'Australia/Melbourne',
  label: 'Australia/Melbourne (AEST)',
  offset: 600
}, {
  utc: 'UTC+10:00',
  name: 'Australia/Sydney',
  label: 'Australia/Sydney (AEST)',
  offset: 600
}, {
  utc: 'UTC+10:00',
  name: 'Pacific/Chuuk',
  label: 'Pacific/Chuuk (UTC+10)',
  offset: 600
}, {
  utc: 'UTC+10:00',
  name: 'Pacific/Guam',
  label: 'Pacific/Guam (ChST)',
  offset: 600
}, {
  utc: 'UTC+10:00',
  name: 'Pacific/Port_Moresby',
  label: 'Pacific/Port Moresby (UTC+10)',
  offset: 600
}, {
  utc: 'UTC+10:00',
  name: 'Pacific/Saipan',
  label: 'Pacific/Saipan (ChST)',
  offset: 600
}, {
  utc: 'UTC+09:30',
  name: 'Australia/Adelaide',
  label: 'Australia/Adelaide (ACST)',
  offset: 570
}, {
  utc: 'UTC+09:30',
  name: 'Australia/Broken_Hill',
  label: 'Australia/Broken Hill (ACST)',
  offset: 570
}, {
  utc: 'UTC+09:30',
  name: 'Australia/Darwin',
  label: 'Australia/Darwin (ACST)',
  offset: 570
}, {
  utc: 'UTC+09:00',
  name: 'Asia/Chita',
  label: 'Asia/Chita (UTC+09)',
  offset: 540
}, {
  utc: 'UTC+09:00',
  name: 'Asia/Dili',
  label: 'Asia/Dili (UTC+09)',
  offset: 540
}, {
  utc: 'UTC+09:00',
  name: 'Asia/Jayapura',
  label: 'Asia/Jayapura (WIT)',
  offset: 540
}, {
  utc: 'UTC+09:00',
  name: 'Asia/Khandyga',
  label: 'Asia/Khandyga (UTC+09)',
  offset: 540
}, {
  utc: 'UTC+09:00',
  name: 'Asia/Seoul',
  label: 'Asia/Seoul (KST)',
  offset: 540
}, {
  utc: 'UTC+09:00',
  name: 'Asia/Tokyo',
  label: 'Asia/Tokyo (JST)',
  offset: 540
}, {
  utc: 'UTC+09:00',
  name: 'Asia/Yakutsk',
  label: 'Asia/Yakutsk (UTC+09)',
  offset: 540
}, {
  utc: 'UTC+09:00',
  name: 'Pacific/Palau',
  label: 'Pacific/Palau (UTC+09)',
  offset: 540
}, {
  utc: 'UTC+08:45',
  name: 'Australia/Eucla',
  label: 'Australia/Eucla (UTC+08:45)',
  offset: 525
}, {
  utc: 'UTC+08:30',
  name: 'Asia/Pyongyang',
  label: 'Asia/Pyongyang (KST)',
  offset: 510
}, {
  utc: 'UTC+08:00',
  name: 'Asia/Brunei',
  label: 'Asia/Brunei (UTC+08)',
  offset: 480
}, {
  utc: 'UTC+08:00',
  name: 'Asia/Choibalsan',
  label: 'Asia/Choibalsan (UTC+08)',
  offset: 480
}, {
  utc: 'UTC+08:00',
  name: 'Asia/Hong_Kong',
  label: 'Asia/Hong Kong (HKT)',
  offset: 480
}, {
  utc: 'UTC+08:00',
  name: 'Asia/Irkutsk',
  label: 'Asia/Irkutsk (UTC+08)',
  offset: 480
}, {
  utc: 'UTC+08:00',
  name: 'Asia/Kuala_Lumpur',
  label: 'Asia/Kuala Lumpur (UTC+08)',
  offset: 480
}, {
  utc: 'UTC+08:00',
  name: 'Asia/Kuching',
  label: 'Asia/Kuching (UTC+08)',
  offset: 480
}, {
  utc: 'UTC+08:00',
  name: 'Asia/Macau',
  label: 'Asia/Macau (CST)',
  offset: 480
}, {
  utc: 'UTC+08:00',
  name: 'Asia/Makassar',
  label: 'Asia/Makassar (WITA)',
  offset: 480
}, {
  utc: 'UTC+08:00',
  name: 'Asia/Manila',
  label: 'Asia/Manila (UTC+08)',
  offset: 480
}, {
  utc: 'UTC+08:00',
  name: 'Asia/Shanghai',
  label: 'Asia/Shanghai (CST)',
  offset: 480
}, {
  utc: 'UTC+08:00',
  name: 'Asia/Singapore',
  label: 'Asia/Singapore (UTC+08)',
  offset: 480
}, {
  utc: 'UTC+08:00',
  name: 'Asia/Taipei',
  label: 'Asia/Taipei (CST)',
  offset: 480
}, {
  utc: 'UTC+08:00',
  name: 'Asia/Ulaanbaatar',
  label: 'Asia/Ulaanbaatar (UTC+08)',
  offset: 480
}, {
  utc: 'UTC+08:00',
  name: 'Australia/Perth',
  label: 'Australia/Perth (AWST)',
  offset: 480
}, {
  utc: 'UTC+07:00',
  name: 'Antarctica/Davis',
  label: 'Antarctica/Davis (UTC+07)',
  offset: 420
}, {
  utc: 'UTC+07:00',
  name: 'Asia/Bangkok',
  label: 'Asia/Bangkok (UTC+07)',
  offset: 420
}, {
  utc: 'UTC+07:00',
  name: 'Asia/Barnaul',
  label: 'Asia/Barnaul (UTC+07)',
  offset: 420
}, {
  utc: 'UTC+07:00',
  name: 'Asia/Ho_Chi_Minh',
  label: 'Asia/Ho Chi Minh (UTC+07)',
  offset: 420
}, {
  utc: 'UTC+07:00',
  name: 'Asia/Hovd',
  label: 'Asia/Hovd (UTC+07)',
  offset: 420
}, {
  utc: 'UTC+07:00',
  name: 'Asia/Jakarta',
  label: 'Asia/Jakarta (WIB)',
  offset: 420
}, {
  utc: 'UTC+07:00',
  name: 'Asia/Krasnoyarsk',
  label: 'Asia/Krasnoyarsk (UTC+07)',
  offset: 420
}, {
  utc: 'UTC+07:00',
  name: 'Asia/Novokuznetsk',
  label: 'Asia/Novokuznetsk (UTC+07)',
  offset: 420
}, {
  utc: 'UTC+07:00',
  name: 'Asia/Novosibirsk',
  label: 'Asia/Novosibirsk (UTC+07)',
  offset: 420
}, {
  utc: 'UTC+07:00',
  name: 'Asia/Phnom_Penh',
  label: 'Asia/Phnom Penh (UTC+07)',
  offset: 420
}, {
  utc: 'UTC+07:00',
  name: 'Asia/Pontianak',
  label: 'Asia/Pontianak (WIB)',
  offset: 420
}, {
  utc: 'UTC+07:00',
  name: 'Asia/Tomsk',
  label: 'Asia/Tomsk (UTC+07)',
  offset: 420
}, {
  utc: 'UTC+07:00',
  name: 'Asia/Vientiane',
  label: 'Asia/Vientiane (UTC+07)',
  offset: 420
}, {
  utc: 'UTC+07:00',
  name: 'Indian/Christmas',
  label: 'Indian/Christmas (UTC+07)',
  offset: 420
}, {
  utc: 'UTC+06:30',
  name: 'Asia/Yangon',
  label: 'Asia/Yangon (UTC+06:30)',
  offset: 390
}, {
  utc: 'UTC+06:30',
  name: 'Indian/Cocos',
  label: 'Indian/Cocos (UTC+06:30)',
  offset: 390
}, {
  utc: 'UTC+06:00',
  name: 'Antarctica/Vostok',
  label: 'Antarctica/Vostok (UTC+06)',
  offset: 360
}, {
  utc: 'UTC+06:00',
  name: 'Asia/Almaty',
  label: 'Asia/Almaty (UTC+06)',
  offset: 360
}, {
  utc: 'UTC+06:00',
  name: 'Asia/Bishkek',
  label: 'Asia/Bishkek (UTC+06)',
  offset: 360
}, {
  utc: 'UTC+06:00',
  name: 'Asia/Dhaka',
  label: 'Asia/Dhaka (UTC+06)',
  offset: 360
}, {
  utc: 'UTC+06:00',
  name: 'Asia/Omsk',
  label: 'Asia/Omsk (UTC+06)',
  offset: 360
}, {
  utc: 'UTC+06:00',
  name: 'Asia/Qyzylorda',
  label: 'Asia/Qyzylorda (UTC+06)',
  offset: 360
}, {
  utc: 'UTC+06:00',
  name: 'Asia/Thimphu',
  label: 'Asia/Thimphu (UTC+06)',
  offset: 360
}, {
  utc: 'UTC+06:00',
  name: 'Asia/Urumqi',
  label: 'Asia/Urumqi (UTC+06)',
  offset: 360
}, {
  utc: 'UTC+06:00',
  name: 'Indian/Chagos',
  label: 'Indian/Chagos (UTC+06)',
  offset: 360
}, {
  utc: 'UTC+05:45',
  name: 'Asia/Kathmandu',
  label: 'Asia/Kathmandu (UTC+05:45)',
  offset: 345
}, {
  utc: 'UTC+05:30',
  name: 'Asia/Colombo',
  label: 'Asia/Colombo (UTC+05:30)',
  offset: 330
}, {
  utc: 'UTC+05:30',
  name: 'Asia/Kolkata',
  label: 'Asia/Kolkata (IST)',
  offset: 330
}, {
  utc: 'UTC+05:00',
  name: 'Antarctica/Mawson',
  label: 'Antarctica/Mawson (UTC+05)',
  offset: 300
}, {
  utc: 'UTC+05:00',
  name: 'Asia/Aqtau',
  label: 'Asia/Aqtau (UTC+05)',
  offset: 300
}, {
  utc: 'UTC+05:00',
  name: 'Asia/Aqtobe',
  label: 'Asia/Aqtobe (UTC+05)',
  offset: 300
}, {
  utc: 'UTC+05:00',
  name: 'Asia/Ashgabat',
  label: 'Asia/Ashgabat (UTC+05)',
  offset: 300
}, {
  utc: 'UTC+05:00',
  name: 'Asia/Atyrau',
  label: 'Asia/Atyrau (UTC+05)',
  offset: 300
}, {
  utc: 'UTC+05:00',
  name: 'Asia/Dushanbe',
  label: 'Asia/Dushanbe (UTC+05)',
  offset: 300
}, {
  utc: 'UTC+05:00',
  name: 'Asia/Karachi',
  label: 'Asia/Karachi (PKT)',
  offset: 300
}, {
  utc: 'UTC+05:00',
  name: 'Asia/Oral',
  label: 'Asia/Oral (UTC+05)',
  offset: 300
}, {
  utc: 'UTC+05:00',
  name: 'Asia/Samarkand',
  label: 'Asia/Samarkand (UTC+05)',
  offset: 300
}, {
  utc: 'UTC+05:00',
  name: 'Asia/Tashkent',
  label: 'Asia/Tashkent (UTC+05)',
  offset: 300
}, {
  utc: 'UTC+05:00',
  name: 'Asia/Yekaterinburg',
  label: 'Asia/Yekaterinburg (UTC+05)',
  offset: 300
}, {
  utc: 'UTC+05:00',
  name: 'Indian/Kerguelen',
  label: 'Indian/Kerguelen (UTC+05)',
  offset: 300
}, {
  utc: 'UTC+05:00',
  name: 'Indian/Maldives',
  label: 'Indian/Maldives (UTC+05)',
  offset: 300
}, {
  utc: 'UTC+04:30',
  name: 'Asia/Kabul',
  label: 'Asia/Kabul (UTC+04:30)',
  offset: 270
}, {
  utc: 'UTC+04:30',
  name: 'Asia/Tehran',
  label: 'Asia/Tehran (UTC+04:30)',
  offset: 270
}, {
  utc: 'UTC+04:00',
  name: 'Asia/Baku',
  label: 'Asia/Baku (UTC+04)',
  offset: 240
}, {
  utc: 'UTC+04:00',
  name: 'Asia/Dubai',
  label: 'Asia/Dubai (UTC+04)',
  offset: 240
}, {
  utc: 'UTC+04:00',
  name: 'Asia/Muscat',
  label: 'Asia/Muscat (UTC+04)',
  offset: 240
}, {
  utc: 'UTC+04:00',
  name: 'Asia/Tbilisi',
  label: 'Asia/Tbilisi (UTC+04)',
  offset: 240
}, {
  utc: 'UTC+04:00',
  name: 'Asia/Yerevan',
  label: 'Asia/Yerevan (UTC+04)',
  offset: 240
}, {
  utc: 'UTC+04:00',
  name: 'Europe/Astrakhan',
  label: 'Europe/Astrakhan (UTC+04)',
  offset: 240
}, {
  utc: 'UTC+04:00',
  name: 'Europe/Samara',
  label: 'Europe/Samara (UTC+04)',
  offset: 240
}, {
  utc: 'UTC+04:00',
  name: 'Europe/Saratov',
  label: 'Europe/Saratov (UTC+04)',
  offset: 240
}, {
  utc: 'UTC+04:00',
  name: 'Europe/Ulyanovsk',
  label: 'Europe/Ulyanovsk (UTC+04)',
  offset: 240
}, {
  utc: 'UTC+04:00',
  name: 'Indian/Mahe',
  label: 'Indian/Mahe (UTC+04)',
  offset: 240
}, {
  utc: 'UTC+04:00',
  name: 'Indian/Mauritius',
  label: 'Indian/Mauritius (UTC+04)',
  offset: 240
}, {
  utc: 'UTC+04:00',
  name: 'Indian/Reunion',
  label: 'Indian/Reunion (UTC+04)',
  offset: 240
}, {
  utc: 'UTC+03:00',
  name: 'Africa/Addis_Ababa',
  label: 'Africa/Addis Ababa (EAT)',
  offset: 180
}, {
  utc: 'UTC+03:00',
  name: 'Africa/Asmara',
  label: 'Africa/Asmara (EAT)',
  offset: 180
}, {
  utc: 'UTC+03:00',
  name: 'Africa/Dar_es_Salaam',
  label: 'Africa/Dar es Salaam (EAT)',
  offset: 180
}, {
  utc: 'UTC+03:00',
  name: 'Africa/Djibouti',
  label: 'Africa/Djibouti (EAT)',
  offset: 180
}, {
  utc: 'UTC+03:00',
  name: 'Africa/Juba',
  label: 'Africa/Juba (EAT)',
  offset: 180
}, {
  utc: 'UTC+03:00',
  name: 'Africa/Kampala',
  label: 'Africa/Kampala (EAT)',
  offset: 180
}, {
  utc: 'UTC+03:00',
  name: 'Africa/Khartoum',
  label: 'Africa/Khartoum (EAT)',
  offset: 180
}, {
  utc: 'UTC+03:00',
  name: 'Africa/Mogadishu',
  label: 'Africa/Mogadishu (EAT)',
  offset: 180
}, {
  utc: 'UTC+03:00',
  name: 'Africa/Nairobi',
  label: 'Africa/Nairobi (EAT)',
  offset: 180
}, {
  utc: 'UTC+03:00',
  name: 'Antarctica/Syowa',
  label: 'Antarctica/Syowa (UTC+03)',
  offset: 180
}, {
  utc: 'UTC+03:00',
  name: 'Asia/Aden',
  label: 'Asia/Aden (UTC+03)',
  offset: 180
}, {
  utc: 'UTC+03:00',
  name: 'Asia/Amman',
  label: 'Asia/Amman (EEST)',
  offset: 180
}, {
  utc: 'UTC+03:00',
  name: 'Asia/Baghdad',
  label: 'Asia/Baghdad (UTC+03)',
  offset: 180
}, {
  utc: 'UTC+03:00',
  name: 'Asia/Bahrain',
  label: 'Asia/Bahrain (UTC+03)',
  offset: 180
}, {
  utc: 'UTC+03:00',
  name: 'Asia/Beirut',
  label: 'Asia/Beirut (EEST)',
  offset: 180
}, {
  utc: 'UTC+03:00',
  name: 'Asia/Damascus',
  label: 'Asia/Damascus (EEST)',
  offset: 180
}, {
  utc: 'UTC+03:00',
  name: 'Asia/Famagusta',
  label: 'Asia/Famagusta (UTC+03)',
  offset: 180
}, {
  utc: 'UTC+03:00',
  name: 'Asia/Gaza',
  label: 'Asia/Gaza (EEST)',
  offset: 180
}, {
  utc: 'UTC+03:00',
  name: 'Asia/Hebron',
  label: 'Asia/Hebron (EEST)',
  offset: 180
}, {
  utc: 'UTC+03:00',
  name: 'Asia/Jerusalem',
  label: 'Asia/Jerusalem (IDT)',
  offset: 180
}, {
  utc: 'UTC+03:00',
  name: 'Asia/Kuwait',
  label: 'Asia/Kuwait (UTC+03)',
  offset: 180
}, {
  utc: 'UTC+03:00',
  name: 'Asia/Nicosia',
  label: 'Asia/Nicosia (EEST)',
  offset: 180
}, {
  utc: 'UTC+03:00',
  name: 'Asia/Qatar',
  label: 'Asia/Qatar (UTC+03)',
  offset: 180
}, {
  utc: 'UTC+03:00',
  name: 'Asia/Riyadh',
  label: 'Asia/Riyadh (UTC+03)',
  offset: 180
}, {
  utc: 'UTC+03:00',
  name: 'Europe/Athens',
  label: 'Europe/Athens (EEST)',
  offset: 180
}, {
  utc: 'UTC+03:00',
  name: 'Europe/Bucharest',
  label: 'Europe/Bucharest (EEST)',
  offset: 180
}, {
  utc: 'UTC+03:00',
  name: 'Europe/Chisinau',
  label: 'Europe/Chisinau (EEST)',
  offset: 180
}, {
  utc: 'UTC+03:00',
  name: 'Europe/Helsinki',
  label: 'Europe/Helsinki (EEST)',
  offset: 180
}, {
  utc: 'UTC+03:00',
  name: 'Europe/Istanbul',
  label: 'Europe/Istanbul (UTC+03)',
  offset: 180
}, {
  utc: 'UTC+03:00',
  name: 'Europe/Kiev',
  label: 'Europe/Kiev (EEST)',
  offset: 180
}, {
  utc: 'UTC+03:00',
  name: 'Europe/Kirov',
  label: 'Europe/Kirov (UTC+03)',
  offset: 180
}, {
  utc: 'UTC+03:00',
  name: 'Europe/Mariehamn',
  label: 'Europe/Mariehamn (EEST)',
  offset: 180
}, {
  utc: 'UTC+03:00',
  name: 'Europe/Minsk',
  label: 'Europe/Minsk (UTC+03)',
  offset: 180
}, {
  utc: 'UTC+03:00',
  name: 'Europe/Moscow',
  label: 'Europe/Moscow (MSK)',
  offset: 180
}, {
  utc: 'UTC+03:00',
  name: 'Europe/Riga',
  label: 'Europe/Riga (EEST)',
  offset: 180
}, {
  utc: 'UTC+03:00',
  name: 'Europe/Simferopol',
  label: 'Europe/Simferopol (MSK)',
  offset: 180
}, {
  utc: 'UTC+03:00',
  name: 'Europe/Sofia',
  label: 'Europe/Sofia (EEST)',
  offset: 180
}, {
  utc: 'UTC+03:00',
  name: 'Europe/Tallinn',
  label: 'Europe/Tallinn (EEST)',
  offset: 180
}, {
  utc: 'UTC+03:00',
  name: 'Europe/Uzhgorod',
  label: 'Europe/Uzhgorod (EEST)',
  offset: 180
}, {
  utc: 'UTC+03:00',
  name: 'Europe/Vilnius',
  label: 'Europe/Vilnius (EEST)',
  offset: 180
}, {
  utc: 'UTC+03:00',
  name: 'Europe/Volgograd',
  label: 'Europe/Volgograd (UTC+03)',
  offset: 180
}, {
  utc: 'UTC+03:00',
  name: 'Europe/Zaporozhye',
  label: 'Europe/Zaporozhye (EEST)',
  offset: 180
}, {
  utc: 'UTC+03:00',
  name: 'Indian/Antananarivo',
  label: 'Indian/Antananarivo (EAT)',
  offset: 180
}, {
  utc: 'UTC+03:00',
  name: 'Indian/Comoro',
  label: 'Indian/Comoro (EAT)',
  offset: 180
}, {
  utc: 'UTC+03:00',
  name: 'Indian/Mayotte',
  label: 'Indian/Mayotte (EAT)',
  offset: 180
}, {
  utc: 'UTC+02:00',
  name: 'Africa/Blantyre',
  label: 'Africa/Blantyre (CAT)',
  offset: 120
}, {
  utc: 'UTC+02:00',
  name: 'Africa/Bujumbura',
  label: 'Africa/Bujumbura (CAT)',
  offset: 120
}, {
  utc: 'UTC+02:00',
  name: 'Africa/Cairo',
  label: 'Africa/Cairo (EET)',
  offset: 120
}, {
  utc: 'UTC+02:00',
  name: 'Africa/Ceuta',
  label: 'Africa/Ceuta (CEST)',
  offset: 120
}, {
  utc: 'UTC+02:00',
  name: 'Africa/Gaborone',
  label: 'Africa/Gaborone (CAT)',
  offset: 120
}, {
  utc: 'UTC+02:00',
  name: 'Africa/Harare',
  label: 'Africa/Harare (CAT)',
  offset: 120
}, {
  utc: 'UTC+02:00',
  name: 'Africa/Johannesburg',
  label: 'Africa/Johannesburg (SAST)',
  offset: 120
}, {
  utc: 'UTC+02:00',
  name: 'Africa/Kigali',
  label: 'Africa/Kigali (CAT)',
  offset: 120
}, {
  utc: 'UTC+02:00',
  name: 'Africa/Lubumbashi',
  label: 'Africa/Lubumbashi (CAT)',
  offset: 120
}, {
  utc: 'UTC+02:00',
  name: 'Africa/Lusaka',
  label: 'Africa/Lusaka (CAT)',
  offset: 120
}, {
  utc: 'UTC+02:00',
  name: 'Africa/Maputo',
  label: 'Africa/Maputo (CAT)',
  offset: 120
}, {
  utc: 'UTC+02:00',
  name: 'Africa/Maseru',
  label: 'Africa/Maseru (SAST)',
  offset: 120
}, {
  utc: 'UTC+02:00',
  name: 'Africa/Mbabane',
  label: 'Africa/Mbabane (SAST)',
  offset: 120
}, {
  utc: 'UTC+02:00',
  name: 'Africa/Tripoli',
  label: 'Africa/Tripoli (EET)',
  offset: 120
}, {
  utc: 'UTC+02:00',
  name: 'Antarctica/Troll',
  label: 'Antarctica/Troll (UTC+02)',
  offset: 120
}, {
  utc: 'UTC+02:00',
  name: 'Arctic/Longyearbyen',
  label: 'Arctic/Longyearbyen (CEST)',
  offset: 120
}, {
  utc: 'UTC+02:00',
  name: 'Europe/Amsterdam',
  label: 'Europe/Amsterdam (CEST)',
  offset: 120
}, {
  utc: 'UTC+02:00',
  name: 'Europe/Andorra',
  label: 'Europe/Andorra (CEST)',
  offset: 120
}, {
  utc: 'UTC+02:00',
  name: 'Europe/Belgrade',
  label: 'Europe/Belgrade (CEST)',
  offset: 120
}, {
  utc: 'UTC+02:00',
  name: 'Europe/Berlin',
  label: 'Europe/Berlin (CEST)',
  offset: 120
}, {
  utc: 'UTC+02:00',
  name: 'Europe/Bratislava',
  label: 'Europe/Bratislava (CEST)',
  offset: 120
}, {
  utc: 'UTC+02:00',
  name: 'Europe/Brussels',
  label: 'Europe/Brussels (CEST)',
  offset: 120
}, {
  utc: 'UTC+02:00',
  name: 'Europe/Budapest',
  label: 'Europe/Budapest (CEST)',
  offset: 120
}, {
  utc: 'UTC+02:00',
  name: 'Europe/Busingen',
  label: 'Europe/Busingen (CEST)',
  offset: 120
}, {
  utc: 'UTC+02:00',
  name: 'Europe/Copenhagen',
  label: 'Europe/Copenhagen (CEST)',
  offset: 120
}, {
  utc: 'UTC+02:00',
  name: 'Europe/Gibraltar',
  label: 'Europe/Gibraltar (CEST)',
  offset: 120
}, {
  utc: 'UTC+02:00',
  name: 'Europe/Kaliningrad',
  label: 'Europe/Kaliningrad (EET)',
  offset: 120
}, {
  utc: 'UTC+02:00',
  name: 'Europe/Ljubljana',
  label: 'Europe/Ljubljana (CEST)',
  offset: 120
}, {
  utc: 'UTC+02:00',
  name: 'Europe/Luxembourg',
  label: 'Europe/Luxembourg (CEST)',
  offset: 120
}, {
  utc: 'UTC+02:00',
  name: 'Europe/Madrid',
  label: 'Europe/Madrid (CEST)',
  offset: 120
}, {
  utc: 'UTC+02:00',
  name: 'Europe/Malta',
  label: 'Europe/Malta (CEST)',
  offset: 120
}, {
  utc: 'UTC+02:00',
  name: 'Europe/Monaco',
  label: 'Europe/Monaco (CEST)',
  offset: 120
}, {
  utc: 'UTC+02:00',
  name: 'Europe/Oslo',
  label: 'Europe/Oslo (CEST)',
  offset: 120
}, {
  utc: 'UTC+02:00',
  name: 'Europe/Paris',
  label: 'Europe/Paris (CEST)',
  offset: 120
}, {
  utc: 'UTC+02:00',
  name: 'Europe/Podgorica',
  label: 'Europe/Podgorica (CEST)',
  offset: 120
}, {
  utc: 'UTC+02:00',
  name: 'Europe/Prague',
  label: 'Europe/Prague (CEST)',
  offset: 120
}, {
  utc: 'UTC+02:00',
  name: 'Europe/Rome',
  label: 'Europe/Rome (CEST)',
  offset: 120
}, {
  utc: 'UTC+02:00',
  name: 'Europe/San_Marino',
  label: 'Europe/San Marino (CEST)',
  offset: 120
}, {
  utc: 'UTC+02:00',
  name: 'Europe/Sarajevo',
  label: 'Europe/Sarajevo (CEST)',
  offset: 120
}, {
  utc: 'UTC+02:00',
  name: 'Europe/Skopje',
  label: 'Europe/Skopje (CEST)',
  offset: 120
}, {
  utc: 'UTC+02:00',
  name: 'Europe/Stockholm',
  label: 'Europe/Stockholm (CEST)',
  offset: 120
}, {
  utc: 'UTC+02:00',
  name: 'Europe/Tirane',
  label: 'Europe/Tirane (CEST)',
  offset: 120
}, {
  utc: 'UTC+02:00',
  name: 'Europe/Vaduz',
  label: 'Europe/Vaduz (CEST)',
  offset: 120
}, {
  utc: 'UTC+02:00',
  name: 'Europe/Vatican',
  label: 'Europe/Vatican (CEST)',
  offset: 120
}, {
  utc: 'UTC+02:00',
  name: 'Europe/Vienna',
  label: 'Europe/Vienna (CEST)',
  offset: 120
}, {
  utc: 'UTC+02:00',
  name: 'Europe/Warsaw',
  label: 'Europe/Warsaw (CEST)',
  offset: 120
}, {
  utc: 'UTC+02:00',
  name: 'Europe/Zagreb',
  label: 'Europe/Zagreb (CEST)',
  offset: 120
}, {
  utc: 'UTC+02:00',
  name: 'Europe/Zurich',
  label: 'Europe/Zurich (CEST)',
  offset: 120
}, {
  utc: 'UTC+01:00',
  name: 'Africa/Algiers',
  label: 'Africa/Algiers (CET)',
  offset: 60
}, {
  utc: 'UTC+01:00',
  name: 'Africa/Bangui',
  label: 'Africa/Bangui (WAT)',
  offset: 60
}, {
  utc: 'UTC+01:00',
  name: 'Africa/Brazzaville',
  label: 'Africa/Brazzaville (WAT)',
  offset: 60
}, {
  utc: 'UTC+01:00',
  name: 'Africa/Casablanca',
  label: 'Africa/Casablanca (WEST)',
  offset: 60
}, {
  utc: 'UTC+01:00',
  name: 'Africa/Douala',
  label: 'Africa/Douala (WAT)',
  offset: 60
}, {
  utc: 'UTC+01:00',
  name: 'Africa/El_Aaiun',
  label: 'Africa/El Aaiun (WEST)',
  offset: 60
}, {
  utc: 'UTC+01:00',
  name: 'Africa/Kinshasa',
  label: 'Africa/Kinshasa (WAT)',
  offset: 60
}, {
  utc: 'UTC+01:00',
  name: 'Africa/Lagos',
  label: 'Africa/Lagos (WAT)',
  offset: 60
}, {
  utc: 'UTC+01:00',
  name: 'Africa/Libreville',
  label: 'Africa/Libreville (WAT)',
  offset: 60
}, {
  utc: 'UTC+01:00',
  name: 'Africa/Luanda',
  label: 'Africa/Luanda (WAT)',
  offset: 60
}, {
  utc: 'UTC+01:00',
  name: 'Africa/Malabo',
  label: 'Africa/Malabo (WAT)',
  offset: 60
}, {
  utc: 'UTC+01:00',
  name: 'Africa/Ndjamena',
  label: 'Africa/Ndjamena (WAT)',
  offset: 60
}, {
  utc: 'UTC+01:00',
  name: 'Africa/Niamey',
  label: 'Africa/Niamey (WAT)',
  offset: 60
}, {
  utc: 'UTC+01:00',
  name: 'Africa/Porto-Novo',
  label: 'Africa/Porto-Novo (WAT)',
  offset: 60
}, {
  utc: 'UTC+01:00',
  name: 'Africa/Tunis',
  label: 'Africa/Tunis (CET)',
  offset: 60
}, {
  utc: 'UTC+01:00',
  name: 'Africa/Windhoek',
  label: 'Africa/Windhoek (WAT)',
  offset: 60
}, {
  utc: 'UTC+01:00',
  name: 'Atlantic/Canary',
  label: 'Atlantic/Canary (WEST)',
  offset: 60
}, {
  utc: 'UTC+01:00',
  name: 'Atlantic/Faroe',
  label: 'Atlantic/Faroe (WEST)',
  offset: 60
}, {
  utc: 'UTC+01:00',
  name: 'Atlantic/Madeira',
  label: 'Atlantic/Madeira (WEST)',
  offset: 60
}, {
  utc: 'UTC+01:00',
  name: 'Europe/Dublin',
  label: 'Europe/Dublin (IST)',
  offset: 60
}, {
  utc: 'UTC+01:00',
  name: 'Europe/Guernsey',
  label: 'Europe/Guernsey (BST)',
  offset: 60
}, {
  utc: 'UTC+01:00',
  name: 'Europe/Isle_of_Man',
  label: 'Europe/Isle of Man (BST)',
  offset: 60
}, {
  utc: 'UTC+01:00',
  name: 'Europe/Jersey',
  label: 'Europe/Jersey (BST)',
  offset: 60
}, {
  utc: 'UTC+01:00',
  name: 'Europe/Lisbon',
  label: 'Europe/Lisbon (WEST)',
  offset: 60
}, {
  utc: 'UTC+01:00',
  name: 'Europe/London',
  label: 'Europe/London (BST)',
  offset: 60
}, {
  utc: 'UTC',
  name: 'Africa/Abidjan',
  label: 'Africa/Abidjan (UTC)',
  offset: 0
}, {
  utc: 'UTC',
  name: 'Africa/Accra',
  label: 'Africa/Accra (UTC)',
  offset: 0
}, {
  utc: 'UTC',
  name: 'Africa/Bamako',
  label: 'Africa/Bamako (UTC)',
  offset: 0
}, {
  utc: 'UTC',
  name: 'Africa/Banjul',
  label: 'Africa/Banjul (UTC)',
  offset: 0
}, {
  utc: 'UTC',
  name: 'Africa/Bissau',
  label: 'Africa/Bissau (UTC)',
  offset: 0
}, {
  utc: 'UTC',
  name: 'Africa/Conakry',
  label: 'Africa/Conakry (UTC)',
  offset: 0
}, {
  utc: 'UTC',
  name: 'Africa/Dakar',
  label: 'Africa/Dakar (UTC)',
  offset: 0
}, {
  utc: 'UTC',
  name: 'Africa/Freetown',
  label: 'Africa/Freetown (UTC)',
  offset: 0
}, {
  utc: 'UTC',
  name: 'Africa/Lome',
  label: 'Africa/Lome (UTC)',
  offset: 0
}, {
  utc: 'UTC',
  name: 'Africa/Monrovia',
  label: 'Africa/Monrovia (UTC)',
  offset: 0
}, {
  utc: 'UTC',
  name: 'Africa/Nouakchott',
  label: 'Africa/Nouakchott (UTC)',
  offset: 0
}, {
  utc: 'UTC',
  name: 'Africa/Ouagadougou',
  label: 'Africa/Ouagadougou (UTC)',
  offset: 0
}, {
  utc: 'UTC',
  name: 'Africa/Sao_Tome',
  label: 'Africa/Sao Tome (UTC)',
  offset: 0
}, {
  utc: 'UTC',
  name: 'America/Danmarkshavn',
  label: 'America/Danmarkshavn (UTC)',
  offset: 0
}, {
  utc: 'UTC',
  name: 'America/Scoresbysund',
  label: 'America/Scoresbysund (UTC)',
  offset: 0
}, {
  utc: 'UTC',
  name: 'Atlantic/Azores',
  label: 'Atlantic/Azores (UTC)',
  offset: 0
}, {
  utc: 'UTC',
  name: 'Atlantic/Reykjavik',
  label: 'Atlantic/Reykjavik (UTC)',
  offset: 0
}, {
  utc: 'UTC',
  name: 'Atlantic/St_Helena',
  label: 'Atlantic/St Helena (UTC)',
  offset: 0
}, {
  utc: 'UTC',
  name: 'UTC',
  label: 'UTC (UTC)',
  offset: 0
}, {
  utc: 'UTC-01:00',
  name: 'Atlantic/Cape_Verde',
  label: 'Atlantic/Cape Verde (UTC-01)',
  offset: -60
}, {
  utc: 'UTC-02:00',
  name: 'America/Godthab',
  label: 'America/Godthab (UTC-02)',
  offset: -120
}, {
  utc: 'UTC-02:00',
  name: 'America/Miquelon',
  label: 'America/Miquelon (UTC-02)',
  offset: -120
}, {
  utc: 'UTC-02:00',
  name: 'America/Noronha',
  label: 'America/Noronha (UTC-02)',
  offset: -120
}, {
  utc: 'UTC-02:00',
  name: 'Atlantic/South_Georgia',
  label: 'Atlantic/South Georgia (UTC-02)',
  offset: -120
}, {
  utc: 'UTC-02:30',
  name: 'America/St_Johns',
  label: 'America/St Johns (NDT)',
  offset: -150
}, {
  utc: 'UTC-03:00',
  name: 'America/Araguaina',
  label: 'America/Araguaina (UTC-03)',
  offset: -180
}, {
  utc: 'UTC-03:00',
  name: 'America/Argentina/Buenos_Aires',
  label: 'America/Argentina/Buenos Aires (UTC-03)',
  offset: -180
}, {
  utc: 'UTC-03:00',
  name: 'America/Argentina/Catamarca',
  label: 'America/Argentina/Catamarca (UTC-03)',
  offset: -180
}, {
  utc: 'UTC-03:00',
  name: 'America/Argentina/Cordoba',
  label: 'America/Argentina/Cordoba (UTC-03)',
  offset: -180
}, {
  utc: 'UTC-03:00',
  name: 'America/Argentina/Jujuy',
  label: 'America/Argentina/Jujuy (UTC-03)',
  offset: -180
}, {
  utc: 'UTC-03:00',
  name: 'America/Argentina/La_Rioja',
  label: 'America/Argentina/La Rioja (UTC-03)',
  offset: -180
}, {
  utc: 'UTC-03:00',
  name: 'America/Argentina/Mendoza',
  label: 'America/Argentina/Mendoza (UTC-03)',
  offset: -180
}, {
  utc: 'UTC-03:00',
  name: 'America/Argentina/Rio_Gallegos',
  label: 'America/Argentina/Rio Gallegos (UTC-03)',
  offset: -180
}, {
  utc: 'UTC-03:00',
  name: 'America/Argentina/Salta',
  label: 'America/Argentina/Salta (UTC-03)',
  offset: -180
}, {
  utc: 'UTC-03:00',
  name: 'America/Argentina/San_Juan',
  label: 'America/Argentina/San Juan (UTC-03)',
  offset: -180
}, {
  utc: 'UTC-03:00',
  name: 'America/Argentina/San_Luis',
  label: 'America/Argentina/San Luis (UTC-03)',
  offset: -180
}, {
  utc: 'UTC-03:00',
  name: 'America/Argentina/Tucuman',
  label: 'America/Argentina/Tucuman (UTC-03)',
  offset: -180
}, {
  utc: 'UTC-03:00',
  name: 'America/Argentina/Ushuaia',
  label: 'America/Argentina/Ushuaia (UTC-03)',
  offset: -180
}, {
  utc: 'UTC-03:00',
  name: 'America/Bahia',
  label: 'America/Bahia (UTC-03)',
  offset: -180
}, {
  utc: 'UTC-03:00',
  name: 'America/Belem',
  label: 'America/Belem (UTC-03)',
  offset: -180
}, {
  utc: 'UTC-03:00',
  name: 'America/Cayenne',
  label: 'America/Cayenne (UTC-03)',
  offset: -180
}, {
  utc: 'UTC-03:00',
  name: 'America/Fortaleza',
  label: 'America/Fortaleza (UTC-03)',
  offset: -180
}, {
  utc: 'UTC-03:00',
  name: 'America/Glace_Bay',
  label: 'America/Glace Bay (ADT)',
  offset: -180
}, {
  utc: 'UTC-03:00',
  name: 'America/Goose_Bay',
  label: 'America/Goose Bay (ADT)',
  offset: -180
}, {
  utc: 'UTC-03:00',
  name: 'America/Halifax',
  label: 'America/Halifax (ADT)',
  offset: -180
}, {
  utc: 'UTC-03:00',
  name: 'America/Maceio',
  label: 'America/Maceio (UTC-03)',
  offset: -180
}, {
  utc: 'UTC-03:00',
  name: 'America/Moncton',
  label: 'America/Moncton (ADT)',
  offset: -180
}, {
  utc: 'UTC-03:00',
  name: 'America/Montevideo',
  label: 'America/Montevideo (UTC-03)',
  offset: -180
}, {
  utc: 'UTC-03:00',
  name: 'America/Paramaribo',
  label: 'America/Paramaribo (UTC-03)',
  offset: -180
}, {
  utc: 'UTC-03:00',
  name: 'America/Punta_Arenas',
  label: 'America/Punta Arenas (UTC-03)',
  offset: -180
}, {
  utc: 'UTC-03:00',
  name: 'America/Recife',
  label: 'America/Recife (UTC-03)',
  offset: -180
}, {
  utc: 'UTC-03:00',
  name: 'America/Santarem',
  label: 'America/Santarem (UTC-03)',
  offset: -180
}, {
  utc: 'UTC-03:00',
  name: 'America/Sao_Paulo',
  label: 'America/Sao Paulo (UTC-03)',
  offset: -180
}, {
  utc: 'UTC-03:00',
  name: 'America/Thule',
  label: 'America/Thule (ADT)',
  offset: -180
}, {
  utc: 'UTC-03:00',
  name: 'Antarctica/Palmer',
  label: 'Antarctica/Palmer (UTC-03)',
  offset: -180
}, {
  utc: 'UTC-03:00',
  name: 'Antarctica/Rothera',
  label: 'Antarctica/Rothera (UTC-03)',
  offset: -180
}, {
  utc: 'UTC-03:00',
  name: 'Atlantic/Bermuda',
  label: 'Atlantic/Bermuda (ADT)',
  offset: -180
}, {
  utc: 'UTC-03:00',
  name: 'Atlantic/Stanley',
  label: 'Atlantic/Stanley (UTC-03)',
  offset: -180
}, {
  utc: 'UTC-04:00',
  name: 'America/Anguilla',
  label: 'America/Anguilla (AST)',
  offset: -240
}, {
  utc: 'UTC-04:00',
  name: 'America/Antigua',
  label: 'America/Antigua (AST)',
  offset: -240
}, {
  utc: 'UTC-04:00',
  name: 'America/Aruba',
  label: 'America/Aruba (AST)',
  offset: -240
}, {
  utc: 'UTC-04:00',
  name: 'America/Asuncion',
  label: 'America/Asuncion (UTC-04)',
  offset: -240
}, {
  utc: 'UTC-04:00',
  name: 'America/Barbados',
  label: 'America/Barbados (AST)',
  offset: -240
}, {
  utc: 'UTC-04:00',
  name: 'America/Blanc-Sablon',
  label: 'America/Blanc-Sablon (AST)',
  offset: -240
}, {
  utc: 'UTC-04:00',
  name: 'America/Boa_Vista',
  label: 'America/Boa Vista (UTC-04)',
  offset: -240
}, {
  utc: 'UTC-04:00',
  name: 'America/Campo_Grande',
  label: 'America/Campo Grande (UTC-04)',
  offset: -240
}, {
  utc: 'UTC-04:00',
  name: 'America/Caracas',
  label: 'America/Caracas (UTC-04)',
  offset: -240
}, {
  utc: 'UTC-04:00',
  name: 'America/Cuiaba',
  label: 'America/Cuiaba (UTC-04)',
  offset: -240
}, {
  utc: 'UTC-04:00',
  name: 'America/Curacao',
  label: 'America/Curacao (AST)',
  offset: -240
}, {
  utc: 'UTC-04:00',
  name: 'America/Detroit',
  label: 'America/Detroit (EDT)',
  offset: -240
}, {
  utc: 'UTC-04:00',
  name: 'America/Dominica',
  label: 'America/Dominica (AST)',
  offset: -240
}, {
  utc: 'UTC-04:00',
  name: 'America/Grand_Turk',
  label: 'America/Grand Turk (AST)',
  offset: -240
}, {
  utc: 'UTC-04:00',
  name: 'America/Grenada',
  label: 'America/Grenada (AST)',
  offset: -240
}, {
  utc: 'UTC-04:00',
  name: 'America/Guadeloupe',
  label: 'America/Guadeloupe (AST)',
  offset: -240
}, {
  utc: 'UTC-04:00',
  name: 'America/Guyana',
  label: 'America/Guyana (UTC-04)',
  offset: -240
}, {
  utc: 'UTC-04:00',
  name: 'America/Havana',
  label: 'America/Havana (CDT)',
  offset: -240
}, {
  utc: 'UTC-04:00',
  name: 'America/Indiana/Indianapolis',
  label: 'America/Indiana/Indianapolis (EDT)',
  offset: -240
}, {
  utc: 'UTC-04:00',
  name: 'America/Indiana/Marengo',
  label: 'America/Indiana/Marengo (EDT)',
  offset: -240
}, {
  utc: 'UTC-04:00',
  name: 'America/Indiana/Petersburg',
  label: 'America/Indiana/Petersburg (EDT)',
  offset: -240
}, {
  utc: 'UTC-04:00',
  name: 'America/Indiana/Vevay',
  label: 'America/Indiana/Vevay (EDT)',
  offset: -240
}, {
  utc: 'UTC-04:00',
  name: 'America/Indiana/Vincennes',
  label: 'America/Indiana/Vincennes (EDT)',
  offset: -240
}, {
  utc: 'UTC-04:00',
  name: 'America/Indiana/Winamac',
  label: 'America/Indiana/Winamac (EDT)',
  offset: -240
}, {
  utc: 'UTC-04:00',
  name: 'America/Iqaluit',
  label: 'America/Iqaluit (EDT)',
  offset: -240
}, {
  utc: 'UTC-04:00',
  name: 'America/Kentucky/Louisville',
  label: 'America/Kentucky/Louisville (EDT)',
  offset: -240
}, {
  utc: 'UTC-04:00',
  name: 'America/Kentucky/Monticello',
  label: 'America/Kentucky/Monticello (EDT)',
  offset: -240
}, {
  utc: 'UTC-04:00',
  name: 'America/Kralendijk',
  label: 'America/Kralendijk (AST)',
  offset: -240
}, {
  utc: 'UTC-04:00',
  name: 'America/La_Paz',
  label: 'America/La Paz (UTC-04)',
  offset: -240
}, {
  utc: 'UTC-04:00',
  name: 'America/Lower_Princes',
  label: 'America/Lower Princes (AST)',
  offset: -240
}, {
  utc: 'UTC-04:00',
  name: 'America/Manaus',
  label: 'America/Manaus (UTC-04)',
  offset: -240
}, {
  utc: 'UTC-04:00',
  name: 'America/Marigot',
  label: 'America/Marigot (AST)',
  offset: -240
}, {
  utc: 'UTC-04:00',
  name: 'America/Martinique',
  label: 'America/Martinique (AST)',
  offset: -240
}, {
  utc: 'UTC-04:00',
  name: 'America/Montserrat',
  label: 'America/Montserrat (AST)',
  offset: -240
}, {
  utc: 'UTC-04:00',
  name: 'America/Nassau',
  label: 'America/Nassau (EDT)',
  offset: -240
}, {
  utc: 'UTC-04:00',
  name: 'America/New_York',
  label: 'America/New York (EDT)',
  offset: -240
}, {
  utc: 'UTC-04:00',
  name: 'America/Nipigon',
  label: 'America/Nipigon (EDT)',
  offset: -240
}, {
  utc: 'UTC-04:00',
  name: 'America/Pangnirtung',
  label: 'America/Pangnirtung (EDT)',
  offset: -240
}, {
  utc: 'UTC-04:00',
  name: 'America/Port-au-Prince',
  label: 'America/Port-au-Prince (EDT)',
  offset: -240
}, {
  utc: 'UTC-04:00',
  name: 'America/Port_of_Spain',
  label: 'America/Port of Spain (AST)',
  offset: -240
}, {
  utc: 'UTC-04:00',
  name: 'America/Porto_Velho',
  label: 'America/Porto Velho (UTC-04)',
  offset: -240
}, {
  utc: 'UTC-04:00',
  name: 'America/Puerto_Rico',
  label: 'America/Puerto Rico (AST)',
  offset: -240
}, {
  utc: 'UTC-04:00',
  name: 'America/Santiago',
  label: 'America/Santiago (UTC-04)',
  offset: -240
}, {
  utc: 'UTC-04:00',
  name: 'America/Santo_Domingo',
  label: 'America/Santo Domingo (AST)',
  offset: -240
}, {
  utc: 'UTC-04:00',
  name: 'America/St_Barthelemy',
  label: 'America/St Barthelemy (AST)',
  offset: -240
}, {
  utc: 'UTC-04:00',
  name: 'America/St_Kitts',
  label: 'America/St Kitts (AST)',
  offset: -240
}, {
  utc: 'UTC-04:00',
  name: 'America/St_Lucia',
  label: 'America/St Lucia (AST)',
  offset: -240
}, {
  utc: 'UTC-04:00',
  name: 'America/St_Thomas',
  label: 'America/St Thomas (AST)',
  offset: -240
}, {
  utc: 'UTC-04:00',
  name: 'America/St_Vincent',
  label: 'America/St Vincent (AST)',
  offset: -240
}, {
  utc: 'UTC-04:00',
  name: 'America/Thunder_Bay',
  label: 'America/Thunder Bay (EDT)',
  offset: -240
}, {
  utc: 'UTC-04:00',
  name: 'America/Toronto',
  label: 'America/Toronto (EDT)',
  offset: -240
}, {
  utc: 'UTC-04:00',
  name: 'America/Tortola',
  label: 'America/Tortola (AST)',
  offset: -240
}, {
  utc: 'UTC-05:00',
  name: 'America/Atikokan',
  label: 'America/Atikokan (EST)',
  offset: -300
}, {
  utc: 'UTC-05:00',
  name: 'America/Bahia_Banderas',
  label: 'America/Bahia Banderas (CDT)',
  offset: -300
}, {
  utc: 'UTC-05:00',
  name: 'America/Bogota',
  label: 'America/Bogota (UTC-05)',
  offset: -300
}, {
  utc: 'UTC-05:00',
  name: 'America/Cancun',
  label: 'America/Cancun (EST)',
  offset: -300
}, {
  utc: 'UTC-05:00',
  name: 'America/Cayman',
  label: 'America/Cayman (EST)',
  offset: -300
}, {
  utc: 'UTC-05:00',
  name: 'America/Chicago',
  label: 'America/Chicago (CDT)',
  offset: -300
}, {
  utc: 'UTC-05:00',
  name: 'America/Eirunepe',
  label: 'America/Eirunepe (UTC-05)',
  offset: -300
}, {
  utc: 'UTC-05:00',
  name: 'America/Guayaquil',
  label: 'America/Guayaquil (UTC-05)',
  offset: -300
}, {
  utc: 'UTC-05:00',
  name: 'America/Indiana/Knox',
  label: 'America/Indiana/Knox (CDT)',
  offset: -300
}, {
  utc: 'UTC-05:00',
  name: 'America/Indiana/Tell_City',
  label: 'America/Indiana/Tell City (CDT)',
  offset: -300
}, {
  utc: 'UTC-05:00',
  name: 'America/Jamaica',
  label: 'America/Jamaica (EST)',
  offset: -300
}, {
  utc: 'UTC-05:00',
  name: 'America/Lima',
  label: 'America/Lima (UTC-05)',
  offset: -300
}, {
  utc: 'UTC-05:00',
  name: 'America/Matamoros',
  label: 'America/Matamoros (CDT)',
  offset: -300
}, {
  utc: 'UTC-05:00',
  name: 'America/Menominee',
  label: 'America/Menominee (CDT)',
  offset: -300
}, {
  utc: 'UTC-05:00',
  name: 'America/Merida',
  label: 'America/Merida (CDT)',
  offset: -300
}, {
  utc: 'UTC-05:00',
  name: 'America/Mexico_City',
  label: 'America/Mexico City (CDT)',
  offset: -300
}, {
  utc: 'UTC-05:00',
  name: 'America/Monterrey',
  label: 'America/Monterrey (CDT)',
  offset: -300
}, {
  utc: 'UTC-05:00',
  name: 'America/North_Dakota/Beulah',
  label: 'America/North Dakota/Beulah (CDT)',
  offset: -300
}, {
  utc: 'UTC-05:00',
  name: 'America/North_Dakota/Center',
  label: 'America/North Dakota/Center (CDT)',
  offset: -300
}, {
  utc: 'UTC-05:00',
  name: 'America/North_Dakota/New_Salem',
  label: 'America/North Dakota/New Salem (CDT)',
  offset: -300
}, {
  utc: 'UTC-05:00',
  name: 'America/Panama',
  label: 'America/Panama (EST)',
  offset: -300
}, {
  utc: 'UTC-05:00',
  name: 'America/Rainy_River',
  label: 'America/Rainy River (CDT)',
  offset: -300
}, {
  utc: 'UTC-05:00',
  name: 'America/Rankin_Inlet',
  label: 'America/Rankin Inlet (CDT)',
  offset: -300
}, {
  utc: 'UTC-05:00',
  name: 'America/Resolute',
  label: 'America/Resolute (CDT)',
  offset: -300
}, {
  utc: 'UTC-05:00',
  name: 'America/Rio_Branco',
  label: 'America/Rio Branco (UTC-05)',
  offset: -300
}, {
  utc: 'UTC-05:00',
  name: 'America/Winnipeg',
  label: 'America/Winnipeg (CDT)',
  offset: -300
}, {
  utc: 'UTC-06:00',
  name: 'America/Belize',
  label: 'America/Belize (CST)',
  offset: -360
}, {
  utc: 'UTC-06:00',
  name: 'America/Boise',
  label: 'America/Boise (MDT)',
  offset: -360
}, {
  utc: 'UTC-06:00',
  name: 'America/Cambridge_Bay',
  label: 'America/Cambridge Bay (MDT)',
  offset: -360
}, {
  utc: 'UTC-06:00',
  name: 'America/Chihuahua',
  label: 'America/Chihuahua (MDT)',
  offset: -360
}, {
  utc: 'UTC-06:00',
  name: 'America/Costa_Rica',
  label: 'America/Costa Rica (CST)',
  offset: -360
}, {
  utc: 'UTC-06:00',
  name: 'America/Denver',
  label: 'America/Denver (MDT)',
  offset: -360
}, {
  utc: 'UTC-06:00',
  name: 'America/Edmonton',
  label: 'America/Edmonton (MDT)',
  offset: -360
}, {
  utc: 'UTC-06:00',
  name: 'America/El_Salvador',
  label: 'America/El Salvador (CST)',
  offset: -360
}, {
  utc: 'UTC-06:00',
  name: 'America/Guatemala',
  label: 'America/Guatemala (CST)',
  offset: -360
}, {
  utc: 'UTC-06:00',
  name: 'America/Inuvik',
  label: 'America/Inuvik (MDT)',
  offset: -360
}, {
  utc: 'UTC-06:00',
  name: 'America/Managua',
  label: 'America/Managua (CST)',
  offset: -360
}, {
  utc: 'UTC-06:00',
  name: 'America/Mazatlan',
  label: 'America/Mazatlan (MDT)',
  offset: -360
}, {
  utc: 'UTC-06:00',
  name: 'America/Ojinaga',
  label: 'America/Ojinaga (MDT)',
  offset: -360
}, {
  utc: 'UTC-06:00',
  name: 'America/Regina',
  label: 'America/Regina (CST)',
  offset: -360
}, {
  utc: 'UTC-06:00',
  name: 'America/Swift_Current',
  label: 'America/Swift Current (CST)',
  offset: -360
}, {
  utc: 'UTC-06:00',
  name: 'America/Tegucigalpa',
  label: 'America/Tegucigalpa (CST)',
  offset: -360
}, {
  utc: 'UTC-06:00',
  name: 'America/Yellowknife',
  label: 'America/Yellowknife (MDT)',
  offset: -360
}, {
  utc: 'UTC-06:00',
  name: 'Pacific/Easter',
  label: 'Pacific/Easter (UTC-06)',
  offset: -360
}, {
  utc: 'UTC-06:00',
  name: 'Pacific/Galapagos',
  label: 'Pacific/Galapagos (UTC-06)',
  offset: -360
}, {
  utc: 'UTC-07:00',
  name: 'America/Creston',
  label: 'America/Creston (MST)',
  offset: -420
}, {
  utc: 'UTC-07:00',
  name: 'America/Dawson',
  label: 'America/Dawson (PDT)',
  offset: -420
}, {
  utc: 'UTC-07:00',
  name: 'America/Dawson_Creek',
  label: 'America/Dawson Creek (MST)',
  offset: -420
}, {
  utc: 'UTC-07:00',
  name: 'America/Fort_Nelson',
  label: 'America/Fort Nelson (MST)',
  offset: -420
}, {
  utc: 'UTC-07:00',
  name: 'America/Hermosillo',
  label: 'America/Hermosillo (MST)',
  offset: -420
}, {
  utc: 'UTC-07:00',
  name: 'America/Los_Angeles',
  label: 'America/Los Angeles (PDT)',
  offset: -420
}, {
  utc: 'UTC-07:00',
  name: 'America/Phoenix',
  label: 'America/Phoenix (MST)',
  offset: -420
}, {
  utc: 'UTC-07:00',
  name: 'America/Tijuana',
  label: 'America/Tijuana (PDT)',
  offset: -420
}, {
  utc: 'UTC-07:00',
  name: 'America/Vancouver',
  label: 'America/Vancouver (PDT)',
  offset: -420
}, {
  utc: 'UTC-07:00',
  name: 'America/Whitehorse',
  label: 'America/Whitehorse (PDT)',
  offset: -420
}, {
  utc: 'UTC-08:00',
  name: 'America/Anchorage',
  label: 'America/Anchorage (AKDT)',
  offset: -480
}, {
  utc: 'UTC-08:00',
  name: 'America/Juneau',
  label: 'America/Juneau (AKDT)',
  offset: -480
}, {
  utc: 'UTC-08:00',
  name: 'America/Metlakatla',
  label: 'America/Metlakatla (AKDT)',
  offset: -480
}, {
  utc: 'UTC-08:00',
  name: 'America/Nome',
  label: 'America/Nome (AKDT)',
  offset: -480
}, {
  utc: 'UTC-08:00',
  name: 'America/Sitka',
  label: 'America/Sitka (AKDT)',
  offset: -480
}, {
  utc: 'UTC-08:00',
  name: 'America/Yakutat',
  label: 'America/Yakutat (AKDT)',
  offset: -480
}, {
  utc: 'UTC-08:00',
  name: 'Pacific/Pitcairn',
  label: 'Pacific/Pitcairn (UTC-08)',
  offset: -480
}, {
  utc: 'UTC-09:00',
  name: 'America/Adak',
  label: 'America/Adak (HDT)',
  offset: -540
}, {
  utc: 'UTC-09:00',
  name: 'Pacific/Gambier',
  label: 'Pacific/Gambier (UTC-09)',
  offset: -540
}, {
  utc: 'UTC-09:30',
  name: 'Pacific/Marquesas',
  label: 'Pacific/Marquesas (UTC-09:30)',
  offset: -570
}, {
  utc: 'UTC-10:00',
  name: 'Pacific/Honolulu',
  label: 'Pacific/Honolulu (HST)',
  offset: -600
}, {
  utc: 'UTC-10:00',
  name: 'Pacific/Rarotonga',
  label: 'Pacific/Rarotonga (UTC-10)',
  offset: -600
}, {
  utc: 'UTC-10:00',
  name: 'Pacific/Tahiti',
  label: 'Pacific/Tahiti (UTC-10)',
  offset: -600
}, {
  utc: 'UTC-11:00',
  name: 'Pacific/Midway',
  label: 'Pacific/Midway (SST)',
  offset: -660
}, {
  utc: 'UTC-11:00',
  name: 'Pacific/Niue',
  label: 'Pacific/Niue (UTC-11)',
  offset: -660
}, {
  utc: 'UTC-11:00',
  name: 'Pacific/Pago_Pago',
  label: 'Pacific/Pago Pago (SST)',
  offset: -660
}];
