<ion-content>
  <div class="full-height vertical-center" [style.--backgroundLine]="websiteThemeColor?.backgroundLine" [style.--backgroundColor]="websiteThemeColor?.background" [style.--fontColor]="websiteThemeColor?.font" [style.--btnFont]="websiteThemeColor?.button?.font" [style.--btnBackground]="websiteThemeColor?.button?.color" [style.--font]="font">
    <ion-card class="center">
      <ion-button class="close-btn" [class]="websiteThemeColor?.button?.fill === 'outline' ? 'website-outline-btn' : 'website-solid-btn'" [fill]="websiteThemeColor?.button?.fill" (click)="dismissModal()">
        <ion-icon class="close-icon font-20" src="./assets/wedding/icon/general/close.svg"></ion-icon>
      </ion-button>      
      <ion-card-content>
        <div>
          <div><ion-icon class="icon-warning center" src="./assets/wedding/icon/general/warning.svg" *ngIf="!hideWarning"></ion-icon></div>
          <div class="ion-margin font-20" *ngIf="title" [innerHTML]="title"></div>
          <div class="ion-margin font-18" *ngIf="subtitle" [innerHTML]="subtitle"></div>
        </div>
        <div class="ion-text-center ion-margin-vertical font-16" *ngIf="msg" [innerHTML]="msg"></div>
        
      </ion-card-content>
    </ion-card>
  </div>
</ion-content>