import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { OnlineService } from './online.service';
import { PopupService } from './popup.service';
import { ErrorService } from './error.service';
import { TranslateService } from '@ngx-translate/core';
import { UpdateByService } from '../user/update-by.service';
import { AccountInfoService } from '../account/account-info.service';
import { DynamicUrl } from 'src/app/commons/url';

@Injectable({
  providedIn: 'root'
})
export class ShortUrlService {

  constructor(
    private fns: AngularFireFunctions,
    private translate: TranslateService,
    private onlineService: OnlineService,
    private accountInfoService: AccountInfoService,
    private updateByService: UpdateByService,
    private errorService :ErrorService,
    private popupService: PopupService,
  ) { }

  async generateShortUrl(originalUrl: string) {
    if (this.onlineService.isOnline()) {
      if (originalUrl) {
        const createdBy = this.updateByService.getUpdateBy();
        const title = this.accountInfoService.accountTitle;
        const domain = DynamicUrl.short.weddingWebsite;
        await this.popupService.presentLoading();
        return await this.fns.httpsCallable('generateShortUrlCall')({ originalUrl, createdBy, title, domain })
        .toPromise().then(async (result: any) => {
          await this.popupService.dismissLoading();
          if (result) {
            return result;
          } else {
            this.popupService.presentAlert(this.translate.instant('ACCOUNTS_LOGIN.invalid'));
          }
        }).catch(async (err: any) => {
          await this.popupService.dismissLoading();
          this.errorService.logError(err);
          this.popupService.presentAlert(this.translate.instant('ACCOUNTS_LOGIN.error'));
        });
        
      } else {
        this.popupService.presentAlert(this.translate.instant('ACCOUNTS_LOGIN.error'));
      }
    }
    return null;
  }

  setupShortUrl(shortUrl: any) {
    return (shortUrl?.domain ? shortUrl.domain : DynamicUrl.short.weddingWebsite) + '/' + shortUrl.shortUrlId;
  }
}
