<div *ngIf="websiteDetail?.hostMsg">
  <div class="ion-text-center">
    <div class="ion-padding-horizontal" *ngIf="title">
      <quill-view [content]="title"></quill-view>
    </div>

    <div class="ion-padding-horizontal" *ngIf="msg">
      <quill-view [content]="msg"></quill-view>
    </div>

    <div class="ion-margin-bottom" *ngIf="websiteDetail?.hostMsg?.media?.url">
      <ng-container *ngIf="websiteDetail?.hostMsg?.media.type === 'youtube'">
        <app-youtube [websiteMode]="true" [setCardHeight]="mediaHeight" [setCalculateSize]="updateTheme" [setSrc]="websiteDetail?.hostMsg?.media.url"></app-youtube>
      </ng-container>
      <ng-container *ngIf="websiteDetail?.hostMsg?.media.type === 'base64' || websiteDetail?.hostMsg?.media.type === 'firebase'">
        <ion-img class="full-width center" [src]="websiteDetail?.hostMsg?.media.url"></ion-img>
      </ng-container>
    </div>

    <div class="ion-padding-horizontal" *ngIf="!accountInfo?.eventMode && websiteDetail?.hostMsg?.couple1?.enable && websiteDetail?.hostMsg?.couple1?.name">
      <ion-grid class="full-width ion-no-padding" fixed>
        <ion-row class="ion-align-items-start ion-justify-content-center">
          <ion-col class="host-col-left" size="6">
            <ion-avatar class="big-avatar center" *ngIf="websiteDetail?.hostMsg?.couple1?.avatar?.url">
              <ion-img [src]="websiteDetail.hostMsg.couple1.avatar.url"></ion-img>
            </ion-avatar>
            <div class="ion-text-wrap">
              <ion-text class="font-14 content-msg" [style.color]="fontColor" slot="end">
                {{ websiteDetail.hostMsg.couple1.name }}
              </ion-text>
            </div>
          </ion-col>
          <ion-col class="host-col-right" size="6">
            <ion-avatar class="big-avatar center" *ngIf="websiteDetail?.hostMsg?.couple2?.avatar?.url">
              <ion-img [src]="websiteDetail.hostMsg.couple2.avatar.url"></ion-img>
            </ion-avatar>
            <div class="ion-text-wrap">
              <ion-text class="font-14 content-msg" [style.color]="fontColor" slot="end">
                {{ websiteDetail.hostMsg.couple2.name }}
              </ion-text>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
      <div class="full-width background-transparent ion-text-left" *ngIf="couple1Msg">
        <quill-view [content]="couple1Msg"></quill-view>
      </div>
      <div class="full-width background-transparent ion-text-right" *ngIf="couple2Msg">
        <quill-view [content]="couple2Msg"></quill-view>
      </div>
    </div>
<!-- 
    <ng-container *ngIf="websiteDetail?.hostMsg?.couple1?.enable && websiteDetail?.hostMsg?.couple1?.name">
      <ion-item class="background-transparent" lines="none">
        <ion-avatar slot="start" class="big-avatar" *ngIf="websiteDetail?.hostMsg?.couple1?.avatar?.url">
          <ion-img [src]="websiteDetail.hostMsg.couple1.avatar.url"></ion-img>
        </ion-avatar>
        <ion-text class="font-16 content-msg" [style.color]="fontColor" slot="end">
          {{ websiteDetail.hostMsg.couple1.name }}
        </ion-text>
      </ion-item>
      <ion-item class="background-transparent" lines="none" *ngIf="getContent(websiteDetail.hostMsg.couple1?.msg)">
        <quill-view [content]="websiteDetail.hostMsg.couple1.msg"></quill-view>
      </ion-item>
    </ng-container>

    <ng-container *ngIf="websiteDetail?.hostMsg?.couple2?.enable && websiteDetail?.hostMsg?.couple2?.name">
      <ion-item class="background-transparent" lines="none">
        <ion-avatar slot="start" class="big-avatar" *ngIf="websiteDetail?.hostMsg?.couple2?.avatar?.url">
          <ion-img [src]="websiteDetail.hostMsg.couple2.avatar.url"></ion-img>
        </ion-avatar>
        <ion-text class="font-16 content-msg" [style.color]="fontColor" slot="end">
          {{ websiteDetail.hostMsg.couple2.name }}
        </ion-text>
      </ion-item>
      <ion-item class="background-transparent" lines="none" *ngIf="getContent(websiteDetail.hostMsg.couple2?.msg)">
        <quill-view [content]="websiteDetail.hostMsg.couple2.msg"></quill-view>
      </ion-item>
    </ng-container> -->

    <div class="ion-padding-horizontal" *ngIf="accountInfo?.eventMode && websiteDetail?.hostMsg?.organizer?.enable && websiteDetail?.hostMsg?.organizer?.name">
      <div>
        <ion-avatar class="big-avatar" *ngIf="websiteDetail?.hostMsg?.organizer?.avatar?.url">
          <ion-img [src]="websiteDetail.hostMsg.organizer.avatar.url"></ion-img>
        </ion-avatar>
      </div>
      <div class="ion-text-center">
        <ion-text class="font-16 content-msg" [style.color]="fontColor">
          {{ websiteDetail.hostMsg.organizer.name }}
        </ion-text>
      </div>
      <div *ngIf="organizerMsg">
        <quill-view [content]="organizerMsg"></quill-view>
      </div>
    </div>
  </div>
</div>