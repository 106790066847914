<ng-container *ngIf="!pageMode">
    <ion-header>
        <ion-toolbar>
            <ion-buttons slot="start">
                <ion-button (click)="back()">
                  <ion-icon color="dark" class="back-icon" src="./assets/wedding/icon/general/chevron-back.svg"></ion-icon>
                </ion-button>
            </ion-buttons>
            <ion-title>
              {{ 'BTN.preview' | translate }}
            </ion-title>
            <!-- <ion-progress-bar [value]="getProgress()" color="primary"></ion-progress-bar> -->
            <ng-contaienr *ngIf="landscapeMode || step === 'name' then headerBlock"></ng-contaienr>
        </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
        <div id="fom-div" *ngIf="true then formBlock"></div>
    </ion-content>
    <ion-footer *ngIf="step !== 'otp'">
        <ion-toolbar>
            <ng-container *ngIf="true then buttonBlock"></ng-container>
            <ng-container *ngIf="true then cutOffBlock"></ng-container>
        </ion-toolbar>
    </ion-footer>
</ng-container>

<ng-container *ngIf="pageMode">
    <!-- [style.height]="previewMode ? 'calc(100vh - 56px - 90px)' : '100vh'" -->
    <div class="full-width vertical-center ion-padding">
        <!-- <ion-progress-bar [value]="getProgress()" color="primary"></ion-progress-bar> -->
        <ng-contaienr *ngIf="landscapeMode || step === 'name' then headerBlock"></ng-contaienr>
        <div class="ion-padding-horizontal" id="form-div" *ngIf="true then formBlock"></div>
        <ng-container *ngIf="true then buttonBlock"></ng-container>
        <ng-container *ngIf="true then cutOffBlock"></ng-container>
    </div>
</ng-container>

<ng-template #formBlock>
    <ng-container *ngIf="step === 'otp'">
        <app-login-phone class="full-width-height" [pageMode]="true" [setReady]="true" (result)="setOtpResult($event)"></app-login-phone>
        <!-- <app-mobile class="full-width-height" [lbl]="'MOBILE.lbl.no' | translate" [setMobile]="mobile" (outputMobile)="updateMobile($event)" (outputMobileInvalid)="updateMobileInvalid($event)"></app-mobile>
        <ng-container *ngIf="mobile?.no && !mobileInvalid">
            <app-
        </ng-container> -->
        <div class="ion-text-center">
            <ion-text class="font-12 font-underline" (click)="setAnonymous()">
                {{ 'WEBSITE.form.otp.anonymous' | translate }}
            </ion-text>
        </div>
    </ng-container>
    <ng-container *ngIf="step === 'name'">

        <!-- <ng-container *ngIf="true then countDownBlock"></ng-container> -->
        <div class="full-width" *ngIf="nameQuestion">
            <quill-view [content]="nameQuestion"></quill-view>
        </div>
        <form class="full-width ion-padding-horizontal" [formGroup]="nameForm" *ngIf="nameForm">
            <ion-input formControlName="name" class="input" [placeholder]="" type="text" inputmode="text" autocapitalize="words" autocomplete="on" autofocus="true" tabindex="1" clearInput></ion-input>
            <div class="error-div">
                <ng-container *ngFor="let validation of validationMsg?.name">
                  <div class="error-message" *ngIf="nameForm.get('name').hasError(validation.type) && (nameForm.get('name').dirty || nameForm.get('name').touched)">
                    <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ validation.msg }}
                  </div>
                </ng-container>
            </div>
        </form>
    </ng-container>
    <ng-container *ngIf="step === 'status'">
        <div class="full-width" *ngIf="statusQuestion">
            <quill-view [content]="statusQuestion"></quill-view>
        </div>
        <ion-grid class="full-width" fixed>
            <ion-row>
                <ion-col size="6">
                    <ion-item class="input-item input-item-margin full-width ion-text-center"  [color]="attendingStatus === 'attending' ? 'white' : ''" (click)="setAttendingStatus('attending')">
                        <ion-text class="ion-text-center">{{ websiteDetail?.form?.fields?.attending }}</ion-text>
                    </ion-item>
                    <!-- <ion-button color="danger" expand="block" [fill]="attendingStatus === 'not_attending' ? 'solid' : 'outline'" (click)="setAttendingStatus('not_attending')"><ion-icon class="icon-only" src="./assets/wedding/icon/general/close.svg"></ion-icon></ion-button> -->
                </ion-col>
                <ion-col size="6">
                    <ion-item class="input-item input-item-margin full-width ion-text-center" [color]="attendingStatus === 'not_attending' ? 'white' : ''" (click)="setAttendingStatus('not_attending')">
                        <ion-text class="ion-text-center">{{ websiteDetail?.form?.fields?.not_attending }}</ion-text>
                    </ion-item>
                    <!-- <ion-button color="success" expand="block" [fill]="attendingStatus === 'attending' ? 'solid' : 'outline'" (click)="setAttendingStatus('attending')"><ion-icon class="icon-only" src="./assets/wedding/icon/general/checkmark.svg"></ion-icon></ion-button> -->
                </ion-col>
            </ion-row>
        </ion-grid>
        <div class="error-div">
            <ng-container>
              <div class="error-message" *ngIf="promptErr && attendingStatus !== 'attending' && attendingStatus !== 'not_attending'">
                <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ websiteDetail?.form?.fields?.required ? websiteDetail.form.fields.required : ('WEBSITE.form.fields.required' | translate) }}
              </div>
            </ng-container>
        </div>
        <!-- <ion-item class="input-item input-item-margin full-width"  [color]="attendingStatus === 'attending' ? 'white' : ''" (click)="setAttendingStatus('attending')">
            {{ websiteDetail?.form?.questions?.attending }}
        </ion-item>
        <ion-item class="input-item input-item-margin full-width" [color]="attendingStatus === 'not_attending' ? 'white' : ''" (click)="setAttendingStatus('not_attending')">
            {{ websiteDetail?.form?.questions?.not_attending }}
        </ion-item> -->
    </ng-container>
    
    <ng-container *ngIf="step === 'group'">
        <div class="full-width" *ngIf="groupQuestion">
            <quill-view [content]="groupQuestion"></quill-view>
        </div>
        <ion-item class="full-width background-transparent" lines="none">
            <ion-button class="qty-btn" [class]="websiteThemeColor?.button?.fill === 'outline' ? 'website-outline-btn' : 'website-solid-btn'" [fill]="websiteThemeColor?.button?.fill" [style.--btnFont]="websiteThemeColor?.button?.font" [style.--btnBackground]="websiteThemeColor?.button?.color" slot="start" (click)="minusQty()">
                <ion-icon slot="icon-only" src="./assets/wedding/icon/general/remove.svg"></ion-icon>
              </ion-button>
            <ion-input class="input qty-input full-width ion-text-center ion-no-padding" [min]="1" [max]="maxGuest ? maxGuest : 1" type="number" inputmode="numeric" autofocus="true" [(ngModel)]="pax" (ionChange)="qtyChanged()">
            </ion-input>
            <ion-button class="qty-btn" [class]="websiteThemeColor?.button?.fill === 'outline' ? 'website-outline-btn' : 'website-solid-btn'" [fill]="websiteThemeColor?.button?.fill" [style.--btnFont]="websiteThemeColor?.button?.font" [style.--btnBackground]="websiteThemeColor?.button?.color" slot="end" (click)="addQty()">
                <ion-icon slot="icon-only" src="./assets/wedding/icon/general/add.svg"></ion-icon>
            </ion-button>
        </ion-item>
        <div class="error-div">
            <ng-container>
              <div class="error-message" *ngIf="promptErr && (!pax || pax <= 0 || pax > maxGuest)">
                <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ websiteDetail?.form?.fields?.invalid ? websiteDetail.form.fields.invalid : ('WEBSITE.form.fields.invalid' | translate) }}
              </div>
            </ng-container>
        </div>
    </ng-container>
    <ng-container *ngIf="step === 'member'">
        <div class="full-width" *ngIf="memberQuestion">
            <quill-view [content]="memberQuestion"></quill-view>
        </div>
        <div class="full-width-height viewport-div" [style.height.px]="(memberList.length) * 92" *ngIf="memberList?.length">
            <cdk-virtual-scroll-viewport [itemSize]="92">
                <div class="full-width-height ion-padding-horizontal">
                    <div class="member-div" *cdkVirtualFor="let member of memberList; let i = index;">
                        <ng-container>
                            <ion-label class="guest-no-lbl" position="stacked">
                                <ion-icon class="vertical-middle" src="./assets/wedding/icon/general/person.svg"></ion-icon> #{{ i + 1 }}
                            </ion-label>
                            <ion-item class="input-item full-width-height" lines="none">
                                <ion-input [id]="'input-' + i" [(ngModel)]="member.name" class="name-input" type="text" inputmode="text" autocapitalize="words" autocomplete="on" [autofocus]="i === 0 ? true : false" [tabindex]="i + 1" [clearInput]="isHybrid ? true : false" [readonly]="i === 0"></ion-input>
                            </ion-item>
                            <div class="error-div">
                                <div class="error-message" *ngIf="promptErr && !member.name">
                                    <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ 'VALIDATION.required' | translate: { field: 'GUEST.lbl.guest_name' | translate } }}
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </cdk-virtual-scroll-viewport>
        </div> 
    </ng-container>
    <ng-container *ngIf="step === 'dietaryReq'">
        <div class="full-width ion-padding-horizontal" *ngIf="memberList?.length > 1">
            <ng-container *ngFor="let member of memberList; let i = index">
                <ion-label class="guest-no-lbl label-no-margin" position="stacked">
                    <ion-icon class="vertical-middle" src="./assets/wedding/icon/general/person.svg"></ion-icon> #{{ i + 1 }}
                </ion-label>
                <ion-item class="input-item input-item-request-margin full-width" [color]="selectedIndex === i ? 'white' : ''" (click)="selectedIndex = i">
                    {{ member.name }}
                </ion-item>
            </ng-container>
        </div>
        <div class="full-width" *ngIf="dietaryReqQuestion">
            <quill-view [content]="dietaryReqQuestion"></quill-view>
        </div>
        <div class="full-width ion-padding-horizontal" *ngFor="let settingField of dietaryReqList">
            <ion-item class="input-item input-item-margin full-width" [color]="checkSettingField(settingField, getDietaryReq(selectedIndex)) ? 'white' : ''" (click)="setDietaryReq(settingField, selectedIndex)">
                {{ getSettingField('dietary_req', settingField) }}
            </ion-item>
        </div>
    </ng-container>
    <ng-container *ngIf="step === 'specialReq'">
        <div class="full-width ion-padding-horizontal" *ngIf="memberList?.length > 1">
            <ng-container *ngFor="let member of memberList; let i = index">
                <ion-label class="guest-no-lbl label-no-margin" position="stacked">
                    <ion-icon class="vertical-middle" src="./assets/wedding/icon/general/person.svg"></ion-icon> #{{ i + 1 }}
                </ion-label>
                <ion-item class="input-item input-item-request-margin full-width" [color]="selectedIndex === i ? 'white' : ''" (click)="selectedIndex = i">
                    {{ member.name }}
                </ion-item>
            </ng-container>
        </div>
        <div class="full-width" *ngIf="specialReqQuestion">
            <quill-view [content]="specialReqQuestion"></quill-view>
        </div>
        <div class="full-width ion-padding-horizontal" *ngFor="let settingField of specialReqList">
            <ion-item class="input-item input-item-margin full-width" [color]="checkSettingField(settingField, getSpecialReq(selectedIndex)) ? 'white' : ''" (click)="setSpecialReq(settingField, selectedIndex)">
                {{ getSettingField('special_req', settingField) }}
            </ion-item>
        </div>
        
        <!-- <ng-container *ngIf="websiteDetail?.form?.specialReq?.others">
            <ion-item [color]="checkSettingField(others, getSpecialReq(selectedIndex)) ? 'tertiary' : 'white'" (click)="setSpecialReq(others, selectedIndex)">
                {{ getSettingField('special_req', others) }}
            </ion-item>
            <div *ngIf="checkSettingField(others, getSpecialReq(selectedIndex))">
                <ion-item class="ion-item-no-padding" lines="none">
                    <ion-label position="stacked">{{ 'LIST.category.others' | translate }}&nbsp;<ion-text *ngIf="websiteDetail?.form?.email?.type === 'required'">*</ion-text></ion-label>
                    <ion-input class="input" [(ngModel)]="newSpecialReq[selectedIndex]" type="text" inputmode="text" autocapitalize="words" autocomplete="on" tabindex="1" clearInput></ion-input>
                    <div class="error-div">
                        <div class="error-message" *ngIf="!newSpecialReq?.[selectedIndex]">
                            <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ 'VALIDATION.required' | translate: { field: 'GUEST.lbl.special_req' | translate } }}
                        </div>
                    </div>
                </ion-item>
            </div>
        </ng-container> -->
    </ng-container>
    <ng-container *ngIf="step === 'contact'">
        <div class="full-width" *ngIf="contactQuestion">
            <quill-view [content]="contactQuestion"></quill-view>
        </div>
        <form class="full-width ion-padding-horizontal" [formGroup]="contactForm" *ngIf="contactForm">
            <ion-item class="full-width background-transparent ion-item-no-padding" lines="none">
                <ion-label position="stacked">{{ websiteDetail?.form?.fields?.email }}&nbsp;<ion-text *ngIf="websiteDetail?.form?.email?.type === 'required'">*</ion-text></ion-label>
                <ion-input formControlName="email" class="input" [placeholder]="" type="email" inputmode="email" autocomplete="on" tabindex="1" autofocus="true" clearInput></ion-input>
                <div class="error-div">
                  <ng-container *ngFor="let validation of validationMsg?.email">
                    <div class="error-message" *ngIf="contactForm.get('email').hasError(validation.type) && (contactForm.get('email').dirty || contactForm.get('email').touched)">
                      <ion-icon src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon> {{ validation.msg }}
                    </div>
                  </ng-container>
                </div>
            </ion-item>
        </form>

        <div class="full-width ion-padding-horizontal" *ngIf="websiteDetail?.form?.mobile?.type !== 'hidden'">
          <app-mobile class="full-width-height" [lbl]="websiteDetail?.form?.fields?.mobile" [setCssClass]="'website-phone-input background-transparent'" [setMobile]="mobile" [required]="websiteDetail?.form?.mobile?.type === 'required' ? true : false" [requiredMsg]="websiteDetail?.form?.fields?.required" [invalidMsg]="websiteDetail?.form?.fields?.invalid" (outputMobile)="updateMobile($event)" (outputMobileInvalid)="updateMobileInvalid($event)"></app-mobile>
        </div> 
    </ng-container>
    <ng-container class="line-120" *ngIf="step === 'summary'">
        <div class="full-width">
            <ng-container *ngIf="(guest?.guestId || group?.groupId) && (!websiteDetail?.form?.edit || !editMode) else summaryTitleBlock">
                <quill-view [content]="editMsg" *ngIf="editMsg"></quill-view>
            </ng-container>
            <ng-template #summaryTitleBlock>
                <quill-view [content]="summaryMsg" *ngIf="summaryMsg"></quill-view>
            </ng-template>
        </div>
        <div class="full-width ion-padding-horizontal">
            <!-- <div>
                {{ nameForm.value.name }}
            </div> -->
            <div class="font-12" *ngIf="attendingStatus">
                <ion-icon class="summary-icon vertical-middle" src="./assets/wedding/icon/general/star-outline.svg"></ion-icon>{{ websiteDetail?.form?.fields?.[attendingStatus] }}
            </div>
            <div class="font-12" *ngIf="contactForm?.value?.email">
                <ion-icon class="summary-icon vertical-middle" name="mail-outline"></ion-icon>{{ contactForm.value.email }}
            </div>
            <div class="font-12"  *ngIf="mobile?.no">
                <ion-icon class="summary-icon vertical-middle" src="./assets/wedding/icon/general/phone.svg"></ion-icon>{{ mobile.no }}
            </div>
            <ng-container *ngIf="attendingStatus === 'attending'">
                <!-- <div>
                    {{ pax }} guests
                </div>
                
                <div>
                    {{ checkSettingField(others, getDietaryReq[0]) ? dietaryReqOthers : getSettingField('dietary_req', getDietaryReq[0] ) }}
                </div>
                <div>
                    {{ checkSettingField(others, getSpecialReq[0]) ? specialReqOthers : getSettingField('special_req', getSpecialReq[0] ) }}
                </div> -->
            </ng-container>
            
            <ng-container *ngIf="memberList?.length">
                <ng-container *ngFor="let member of memberList; let i = index">
                    <div class="ion-margin-vertical">
                        <div class="font-bold font-16">
                            <ion-icon class="summary-icon vertical-middle" src="./assets/wedding/icon/general/checkmark-circle.svg"></ion-icon>{{ member.name }}
                        </div>
                        <ng-container *ngIf="attendingStatus === 'attending'">
                            <div class="summary-guest-request-div">
                                <div class="font-12" *ngIf="checkDietaryReqOn()">
                                    <ion-icon class="summary-icon vertical-middle" name="restaurant-outline"></ion-icon>{{ getSettingField('dietary_req', member.dietaryReq[0], i) }}
                                </div>
                                <div class="font-12" *ngIf="checkSpecialReqOn()">
                                    <ion-icon class="summary-icon vertical-middle" src="./assets/wedding/icon/general/alert-circle-outline.svg"></ion-icon>{{ getSettingField('special_req', member.specialReq[0], i) }}
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </ng-container>
    <ng-container *ngIf="step === 'result'">
        <div>
            <app-website-success></app-website-success>
            <!-- <ion-img class="checked-gif center vertical-center" src="./assets/wedding/gif/visitor/checked.gif" *ngIf="!stopGif"></ion-img> -->
            <!-- <ion-img class="checked-gif center vertical-center" src="./assets/wedding/gif/visitor/checked.jpg" *ngIf="stopGif"></ion-img> -->
            <div class="full-width" *ngIf="successMsg">
                <quill-view [content]="successMsg"></quill-view>
            </div>
        </div>
        <ion-grid class="full-width" fixed>
            <ion-row>
                <!--  -->
                <ion-col *ngIf="websiteDetail?.qrcode?.enable">
                    <ion-button class="center" expand="block" [class]="websiteThemeColor?.button?.fill === 'outline' ? 'website-outline-btn' : 'website-solid-btn'" [fill]="websiteThemeColor?.button?.fill" [style.--btnFont]="websiteThemeColor?.button?.font" [style.--btnBackground]="websiteThemeColor?.button?.color" (click)="goQrCode()">
                        <ion-icon slot="icon-only" src="./assets/wedding/icon/qrcode/qrcode.svg"></ion-icon>
                    </ion-button>
                </ion-col>
                <ion-col *ngIf="!websiteDetail?.qrcode?.enable && websiteDetail?.checkin?.enable && webCheckinTitle">
                    <ion-button class="center" expand="block" [class]="websiteThemeColor?.button?.fill === 'outline' ? 'website-outline-btn' : 'website-solid-btn'" [fill]="websiteThemeColor?.button?.fill" [style.--btnFont]="websiteThemeColor?.button?.font" [style.--btnBackground]="websiteThemeColor?.button?.color" (click)="goCheckin()">
                        {{ webCheckinTitle }}
                    </ion-button>
                </ion-col>
                <ion-col *ngIf="!websiteDetail?.qrcode?.enable && !websiteDetail?.checkin?.enable">
                    <ion-button class="center" expand="block" [class]="websiteThemeColor?.button?.fill === 'outline' ? 'website-outline-btn' : 'website-solid-btn'" [fill]="websiteThemeColor?.button?.fill" [style.--btnFont]="websiteThemeColor?.button?.font" [style.--btnBackground]="websiteThemeColor?.button?.color" (click)="back()">
                        <ion-icon slot="icon-only" src="./assets/wedding/icon/general/home.svg"></ion-icon>
                    </ion-button>
                </ion-col>
            </ion-row>
        </ion-grid>
        <!-- <div class="ion-text-center font-bold">
            Result
        </div>
        <div>
            Registration successfully. 
        </div>
        <div *ngIf="attendingStatus === 'attending'">
            Redirecting to QR Code...
            <div>
                <ion-button class="center" expand="block" (click)="goQrCode()">
                    Go Now
                </ion-button>
            </div>
        </div>
        <div *ngIf="attendingStatus !== 'attending'">
            <ion-button class="center" expand="block" (click)="back()">
                Home
            </ion-button>
        </div> -->
    </ng-container>
</ng-template>

<ng-template #buttonBlock>
    <ion-grid class="full-width button-grid ion-margin-horizontal" *ngIf="step !== 'otp' && step !== 'result'">
        <ion-row *ngIf="step !== 'summary' || (!guest?.guestId && !group?.groupId)">
            <!-- *ngIf="step !== 'name' || previewMode" -->
            <ion-col>
                <ion-button class="center" expand="block" [class]="websiteThemeColor?.button?.fill === 'outline' ? 'website-outline-btn' : 'website-solid-btn'" [fill]="websiteThemeColor?.button?.fill" [style.--btnFont]="websiteThemeColor?.button?.font" [style.--btnBackground]="websiteThemeColor?.button?.color" (click)="prev()">
                    <ion-icon slot="icon-only" src="./assets/wedding/icon/general/chevron-back.svg"></ion-icon>
                </ion-button>
            </ion-col>
            <ion-col>
                <ion-button class="center" expand="block"  [class]="websiteThemeColor?.button?.fill === 'outline' ? 'website-outline-btn' : 'website-solid-btn'" [fill]="websiteThemeColor?.button?.fill" [style.--btnFont]="websiteThemeColor?.button?.font" [style.--btnBackground]="websiteThemeColor?.button?.color" (click)="next()" [disabled]="websiteDetail?.form?.cutOff?.enable && !days && !hours && !minutes">
                    <ion-icon slot="icon-only" src="./assets/wedding/icon/general/chevron-forward.svg" *ngIf="step !== 'summary'"></ion-icon>
                    <ion-icon slot="icon-only" src="./assets/wedding/icon/general/checkmark-done-outline.svg" *ngIf="step === 'summary'"></ion-icon>
                    <!-- {{ (step === 'summary' ? 'BTN.confirm' : 'BTN.next') | translate }} -->
                </ion-button>
            </ion-col>
        </ion-row>
        <ion-row *ngIf="step === 'summary' && (guest?.guestId || group?.groupId) && websiteDetail?.form?.edit">
            <ion-col>
                <ion-button class="center" expand="block" [class]="websiteThemeColor?.button?.fill === 'outline' ? 'website-outline-btn' : 'website-solid-btn'" [fill]="websiteThemeColor?.button?.fill" [style.--btnFont]="websiteThemeColor?.button?.font" [style.--btnBackground]="websiteThemeColor?.button?.color" (click)="edit()" [disabled]="websiteDetail?.form?.cutOff?.enable && !days && !hours && !minutes">
                    <!-- {{ 'CRUD.edit' | translate }} -->
                    <ion-icon slot="icon-only" src="./assets/wedding/icon/general/edit.svg"></ion-icon>
                </ion-button>
            </ion-col>
            <ion-col *ngIf="editMode">
                <ion-button class="center" expand="block" [class]="websiteThemeColor?.button?.fill === 'outline' ? 'website-outline-btn' : 'website-solid-btn'" [fill]="websiteThemeColor?.button?.fill" [style.--btnFont]="websiteThemeColor?.button?.font" [style.--btnBackground]="websiteThemeColor?.button?.color" (click)="next()" [disabled]="getDisabled()">
                    <!-- {{ 'BTN.save' | translate }} -->
                    <ion-icon slot="icon-only" src="./assets/wedding/icon/general/checkmark-done-outline.svg" *ngIf="step === 'summary'"></ion-icon>
                </ion-button>
            </ion-col>
        </ion-row>
    </ion-grid>
</ng-template>

<ng-template #headerBlock>
    <div class="full-width ion-margin-vertical">
        <ng-container *ngIf="websiteDetail?.form?.media?.url">
            <ng-container *ngIf="websiteDetail?.form?.media.type === 'youtube'">
              <app-youtube [websiteMode]="true" [setCardHeight]="mediaHeight" [setCalculateSize]="updateTheme" [setSrc]="websiteDetail?.form?.media.url"></app-youtube>
            </ng-container>
            <ng-container *ngIf="websiteDetail?.form?.media.type === 'base64' || websiteDetail?.form?.media.type === 'firebase'">
              <ion-img class="full-width center" [src]="websiteDetail?.form?.media.url"></ion-img>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="true then titleBlock"></ng-container>
        <ng-container *ngIf="true then timeBlock"></ng-container>
    </div>
    
    <div class="full-width" *ngIf="title">
        <quill-view [content]="title"></quill-view>
    </div>

    <div class="full-width" *ngIf="msg">
        <quill-view [content]="msg"></quill-view>
    </div>
</ng-template>

<!-- <ng-template #countDownBlock>
    <app-count-down class="full-width" [days]="days" [hours]="hours" [minutes]="minutes" [msg]="websiteDetail?.form?.cutOff?.msg"></app-count-down>
</ng-template> -->

<ng-template #titleBlock>
    <ng-container *ngIf="accountTitle">
      <quill-view [content]="accountTitle"></quill-view>
    </ng-container>
  </ng-template>
  
  <ng-template #timeBlock>
    <app-website-time [pageMode]="true" [previewMode]="previewMode ? true : false" [setAccountInfo]="accountInfo" [setStartTime]="accountInfo?.time?.seconds" [setEndTime]="accountInfo?.endTime?.seconds" [setWebsiteDetail]="websiteDetail" [saveCalendar]="false" [setLocale]="accountInfo?.locale" [setDateFormat]="accountInfo?.dateFormat" [setTimeFormat]="accountInfo?.timeFormat" [setTimezone]="accountInfo?.timezone" [setFontSize]="websiteDetail?.time?.fontSize" [setDesign]="websiteDetail?.time?.design"></app-website-time>
  </ng-template>

  <ng-template #cutOffBlock>
    <div class="line-1 full-width ion-text-center ion-margin" *ngIf="websiteDetail?.form?.cutOff?.enable && websiteDetail?.form?.fields?.cutOffMsg && accountInfo">
        <ion-text class="font-12">{{ websiteDetail.form.fields.cutOffMsg }}</ion-text>
        <app-website-time class="full-width-height" [pageMode]="true" [previewMode]="previewMode ? true : false" [setAccountInfo]="accountInfo" [setStartTime]="accountInfo.time.seconds + (websiteDetail?.form?.cutOff?.time * 60)" [setWebsiteDetail]="websiteDetail" [saveCalendar]="false" [setLocale]="accountInfo?.locale" [setDateFormat]="accountInfo?.dateFormat" [setTimeFormat]="accountInfo?.timeFormat" [setTimezone]="accountInfo?.timezone" [setFontSize]="'tiny'" [setDesign]="2" [setReady]="true"></app-website-time>
    </div>
  </ng-template>