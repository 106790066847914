import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CountDownType } from 'src/app/types/general';

@Component({
  selector: 'app-count-down',
  templateUrl: './count-down.component.html',
  styleUrls: ['./count-down.component.scss'],
})
export class CountDownComponent  implements OnInit, OnDestroy {

  @Input() set setReady(ready: boolean) {

  }

  @Input() days: number;
  @Input() hours: number;
  @Input() minutes: number;
  @Input() design: number;
  @Input() msg: string;
  @Input() countDownType: CountDownType;

  constructor(
  ) { }

  ngOnInit() {}

  ngOnDestroy(): void {
      
  }
}
