<ion-header>
  <ion-toolbar class="gradient-primary-right">
    <ion-buttons slot="start">
      <ion-button (click)="dismissModal()">
        <ion-icon color="dark" class="back-icon" src="./assets/wedding/icon/general/chevron-back.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <app-offline-indicator></app-offline-indicator>
      {{ 'LBL.profile' | translate }}
    </ion-title>
    <ion-buttons slot="end" *ngIf="guest?.guestId && module !== 'trash' && currentPrivilege?.[module]?.['edit']">
      <ion-button (click)="presentGuestEditModal(guest)">
        <ion-icon class="font-20" color="dark" src="./assets/wedding/icon/general/edit.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-toolbar class="deleted-titlebar" color="danger" *ngIf="module === 'trash'">
    <ion-title class="font-12">{{ 'MSG.deleted_title_field' | translate: { field: 'GUEST.lbl.guest' | translate } }}</ion-title>
  </ion-toolbar>
  <ion-toolbar class="gradient-primary-right ion-text-center" *ngIf="guest">
    <div>
      <ion-icon class="status-icon icon-large" src="./assets/wedding/icon/guest/status/pending-round-background.svg" *ngIf="guest.status.attending === 'pending'"></ion-icon>
      <ion-icon class="status-icon icon-large" src="./assets/wedding/icon/guest/status/attending-round-background.svg" *ngIf="guest.status.attending === 'attending'"></ion-icon>
      <ion-icon class="status-icon icon-large" src="./assets/wedding/icon/guest/status/not-attending-round-background.svg" *ngIf="guest.status.attending === 'not_attending'"></ion-icon>
      <div>
        <ion-text class="font-10 font-med" color="dark">
          <ng-container *ngIf="guest.status.attending === 'pending'">{{ 'LIST.attending_status.pending' | translate }}</ng-container>
          <ng-container *ngIf="guest.status.attending === 'attending'">{{ 'LIST.attending_status.attending' | translate }}</ng-container>
          <ng-container *ngIf="guest.status.attending === 'not_attending'">{{ 'LIST.attending_status.not_attending' | translate }}</ng-container>
        </ion-text>
      </div>
    </div>
    
    <div class="ion-margin-top">
      <div class="margin-5 ion-text-wrap">
        <ion-text class="font-18 font-bold line-110" color="black" *ngIf="guest.name">{{ guest.name }}</ion-text>
      </div>
      <div class="margin-5 ion-text-wrap">
        <ion-text class="font-12 font-med font-italic line-110" color="dark" *ngIf="guest.nickname">{{ guest.nickname }}</ion-text>
      </div>
      <div class="font-10 margin-5">
        <ion-text class="font-10 line-110 ion-text-wrap" color="black">
          <ng-container *ngIf="invitedBy">
            {{ invitedBy }}
          </ng-container>
          <ng-container *ngIf="invitedBy && category">&nbsp;|&nbsp;</ng-container>
          <ng-container *ngIf="category">
            {{ category }}
          </ng-container>
        </ion-text>
      </div>
    </div>

    <ion-grid fixed>
      <ion-row>
        <ion-col size="4">
          <ion-card class="ion-no-margin vertical-center card-status" (click)="presentGuestQrcodeModal(guest)" button>
            <div><ion-text class="font-10 font-semi">{{ 'MODULE.list.qrcode' | translate }}</ion-text></div>
            <div class="center">
              <ion-icon class="icon-small margin-5" src="./assets/wedding/icon/qrcode/qrcode-green.svg" *ngIf="guest.status.qrcode"></ion-icon>
              <ion-icon class="icon-small margin-5" src="./assets/wedding/icon/qrcode/qrcode.svg" *ngIf="!guest.status.qrcode"></ion-icon> 
            </div>
            <div>
              <ion-text class="font-14" color="dark">
                <ng-container *ngIf="guest.status.qrcode">{{ 'LIST.send_status.sent' | translate }}</ng-container>
                <ng-container *ngIf="!guest.status.qrcode">{{ 'LIST.send_status.not_send' | translate }}</ng-container>
              </ion-text>
            </div>
          </ion-card>
        </ion-col>
        <ion-col size="4">
          <ion-card class="ion-no-margin vertical-center card-status" (click)="presentGroupModal(group)" button>
            <div><ion-text class="font-10 font-semi">{{ 'LIST.group.group' | translate }}</ion-text></div>
            <div class="center">
              <ion-icon class="icon-small margin-5" src="./assets/wedding/icon/guest/group-green.svg" *ngIf="guest.groupId"></ion-icon>
              <ion-icon class="icon-small margin-5" src="./assets/wedding/icon/guest/individual.svg" *ngIf="!guest.groupId"></ion-icon>
            </div>
            <div>
              <ion-text class="font-14" color="dark">
                <ng-container *ngIf="guest.groupId && group else groupBlock">
                  {{ group?.memberList?.length }}&nbsp;{{ 'GUEST.lbl.guests' | translate}}
                </ng-container>
                <ng-template #groupBlock>-</ng-template>
              </ion-text>
            </div>
          </ion-card>
        </ion-col>
        <ion-col size="4">
          <ion-card class="ion-no-margin vertical-center card-status" (click)="presentSeatingDetailModal(guest.seating)" button>
            <div><ion-text class="font-10 font-semi">{{ seatingTypeName }}</ion-text></div>
            <div class="center">
              <ion-icon class="icon-small margin-5" src="./assets/wedding/icon/guest/seating-green.svg" *ngIf="guest.seating"></ion-icon>
              <ion-icon class="icon-small margin-5" src="./assets/wedding/icon/guest/seating-outline.svg" *ngIf="!guest.seating"></ion-icon>
            </div>
            <div class="text-ellipsis">
              <ion-text class="font-14" color="dark">
                <ng-container *ngIf="guest.seating">
                  <ng-container *ngIf="guestSeating">
                    <div *ngIf="guestSeating?.count > 1">
                      <span class="font bold ion-text-uppercase font-10 free-seating">
                        {{ 'SEATING.lbl.free_seating' | translate }}
                      </span>
                    </div>
                  </ng-container>
                  <!-- <div class="text-ellipsis" appTextFit [minFontSize]="10" [maxFontSize]="20">
                    {{ guest.seating }}
                  </div> -->
                  <fit-line [minFontSize]="10" [maxFontSize]="16" [calcOnResize]="true">
                    <div class="text-ellipsis">
                      <ion-label color="dark">{{ guest.seating }}</ion-label>
                    </div>
                  </fit-line>
                </ng-container>
                <span *ngIf="!guest.seating">
                  -
                </span>
              </ion-text>
            </div>
          </ion-card>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
  <ion-toolbar class="ion-no-padding action-toolbar" color="light" *ngIf="guest">
    <ion-grid class="ion-no-padding" *ngIf="guest" fixed>
      <ion-row *ngIf="module === 'guest'">
        <ion-col class="padding-5" *ngIf="currentPrivilege?.[module]?.['seating']">
          <ion-button class="action-btn" color="primary" expand="block" *ngIf="!guest.seating" (click)="presentSeatingListModal()">
            {{ 'SEATING.btn.assign' | translate: { seating: seatingTypeName } }}
          </ion-button>
          <ion-button class="action-btn" color="grey" expand="block" *ngIf="guest.seating" (click)="unassignSeatingConfirm()">
            {{ 'SEATING.btn.unassign' | translate: { seating: seatingTypeName } }}
          </ion-button>
        </ion-col>
        <ion-col class="padding-5" *ngIf="guest.status && currentPrivilege?.[module]?.['qrcode']">
          <ion-button class="action-btn" color="primary" expand="block" *ngIf="!guest.status.qrcode" (click)="presentGuestQrcodeModal(guest)">
            {{ 'QRCODE.btn.send' | translate }}
          </ion-button>
          <ion-button class="action-btn" color="grey" expand="block" *ngIf="guest.status.qrcode" (click)="presentGuestQrcodeModal(guest)">
            {{ 'QRCODE.btn.resend' | translate }}
          </ion-button>
        </ion-col>
      </ion-row>
      <ion-row *ngIf="module === 'trash'">
        <ion-col class="padding-5" *ngIf="currentPrivilege?.[module]?.['restore']">
          <ion-button class="action-btn" expand="block" color="success" (click)="promptRestore()">
            {{ 'CRUD.restore' | translate }}
          </ion-button>
        </ion-col>
        <ion-col class="padding-5" *ngIf="currentPrivilege?.[module]?.['delete']">
          <ion-button class="action-btn" expand="block" color="danger" (click)="promptDelete()">
            {{ 'CRUD.delete' | translate }}
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding-horizontal" color="light">
  <ng-container *ngIf="guest">
    <ion-list class="background-transparent ion-margin-bottom">
      <!-- module invites -->
      <ion-card class="detail-card margin-5 ion-no-margin" *ngIf="module === 'website'">
        <ion-item lines="none">
          <ion-icon slot="start" class="icon-small" color='dark' src="./assets/wedding/icon/general/envelope-outline.svg"></ion-icon>
          <ion-text slot="start">
            <div>
              <ion-text class="font-14 font-bold line-110 ion-text-wrap" color="dark">{{ (guest?.status?.rsvp ? 'BTN.yes' : 'BTN.no') | translate }}</ion-text>
            </div>
            <div>
              <ion-text class="font-12 line-110 ion-text-wrap" color="dark">{{ 'WEBSITE.form.rsvp_status' | translate }}</ion-text>
            </div>
          </ion-text>
        </ion-item>
      </ion-card>

      <ion-card class="detail-card margin-5 ion-no-margin">
        <ion-item lines="none">
          <ion-icon slot="start" class="icon-small" color='dark' src="./assets/wedding/icon/guest/dietary-req.svg"></ion-icon>
          <ion-text slot="start">
            <div>
              <ion-text class="font-14 font-bold line-110 ion-text-wrap" color="dark">{{ dietaryReq }}</ion-text>
            </div>
            <div>
              <ion-text class="font-12 line-110 ion-text-wrap" color="dark">{{ 'GUEST.lbl.dietary_req' | translate }}</ion-text>
            </div>
          </ion-text>
        </ion-item>
      </ion-card>
      
      <ion-card class="detail-card margin-5 ion-no-margin">
        <ion-item lines="none">
          <ion-icon slot="start" class="icon-small" color='dark' src="./assets/wedding/icon/guest/special-req.svg"></ion-icon>
          <ion-text slot="start">
            <div>
              <ion-text class="font-14 font-bold line-110 ion-text-wrap" color="dark">{{ specialReq }}</ion-text>
            </div>
            <div>
              <ion-text class="font-12 line-110 ion-text-wrap" color="dark">{{ 'GUEST.lbl.special_req' | translate }}</ion-text>
            </div>
          </ion-text>
        </ion-item>
      </ion-card>
      
      <ion-card class="detail-card margin-5 ion-no-margin" *ngIf="guest?.mobile?.no">
        <ion-item lines="none">
          <ion-icon slot="start" class="icon-small" color='dark' src="./assets/wedding/icon/general/phone-portrait-outline.svg"></ion-icon>
          <ion-text slot="start">
            <ion-text class="font-14 font-bold line-110 ion-text-wrap" color="dark">
              <div>{{ guest.mobile.no }}</div>
            </ion-text>
            <div>
              <ion-text class="font-12 line-110 ion-text-wrap" color="dark">{{ 'MOBILE.lbl.no' | translate }}</ion-text>
            </div>
          </ion-text>
        </ion-item>
      </ion-card>

      <ion-card class="detail-card margin-5 ion-no-margin" *ngIf="guest?.email">
        <ion-item lines="none">
          <ion-icon slot="start" class="icon-small" color='dark' name="mail-outline"></ion-icon>
          <ion-text slot="start">
            <ion-text class="font-14 font-bold line-110 ion-text-wrap" color="dark">
              <div>{{ guest.email }}</div>
            </ion-text>
            <div>
              <ion-text class="font-12 line-110 ion-text-wrap" color="dark">{{ 'LBL.email' | translate }}</ion-text>
            </div>
          </ion-text>
        </ion-item>
      </ion-card>

      <ion-card class="detail-card margin-5 ion-no-margin" *ngIf="guest?.session?.length && guest?.session[0].value !== 'none' && session">
        <ion-item lines="none">
          <ion-icon slot="start" class="icon-small" color='dark' name="time-outline"></ion-icon>
          <ion-text slot="start">
            <ion-text class="font-14 font-bold line-110 ion-text-wrap" color="dark">
              <div>{{ session }}</div>
            </ion-text>
            <div>
              <ion-text class="font-12 line-110 ion-text-wrap" color="dark">{{ 'LBL.session' | translate }}</ion-text>
            </div>
          </ion-text>
        </ion-item>
      </ion-card>
      
      <ion-card class="detail-card margin-5 ion-no-margin">
        <ion-item lines="none">
          <ion-icon slot="start" class="icon-small" color='dark' src="./assets/wedding/icon/guest/remark.svg"></ion-icon>
          <ion-text slot="start">
            <ion-text class="font-14 font-bold line-110 ion-text-wrap" color="dark">
              <div *ngIf="guest.remark">{{ guest.remark }}</div>
              <div *ngIf="!guest.remark">-</div>
            </ion-text>
            <div>
              <ion-text class="font-12 line-110 ion-text-wrap" color="dark">
                {{ 'GUEST.lbl.remark' | translate }}
              </ion-text>
            </div>
          </ion-text>
        </ion-item>
      </ion-card>

      <ion-card class="detail-card margin-5 ion-no-margin" button>
        <ion-item lines="none" (click)="presentGuestLogModal()" detail>
          <ion-icon slot="start" class="icon-small" color='dark' src="./assets/wedding/icon/guest/log.svg"></ion-icon>
          <ion-text slot="start">
            <ion-text class="font-12" color="dark">
              {{ 'GUEST.lbl.guest_log' | translate }}
            </ion-text>
          </ion-text>
        </ion-item>
      </ion-card>
      
    </ion-list>
  </ng-container>
</ion-content>
