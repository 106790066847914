import { Injectable } from '@angular/core';
import { AccountInfoService } from '../account/account-info.service';

import { LocaleService } from './locale.service';
import { LocalityService } from '../location/locality.service';
import { TimeFormatList12Hr } from 'src/app/commons/timezone';

@Injectable({
  providedIn: 'root'
})
export class LocalizationService {

  constructor(
    private accountInfoService: AccountInfoService,
    private localeService: LocaleService,
    private localityService: LocalityService,
  ) { }

  getCountryCode(): string {
    return this.localityService.getAccountCountry()?.code;
  }

  getLocale(countryCode?: string): string {
    let locale = this.accountInfoService.accountInfo?.locale;

    if (!locale) {
      locale = this.localeService.getLocale('', countryCode ? countryCode : this.localityService.getAccountCountry()?.code);
    }

    return locale ? locale : this.localeService.defaultLocaleType.locale;
  }

  getDateFormat(): string {
    if (this.accountInfoService.accountInfo?.dateFormat) {
      return this.accountInfoService.accountInfo.dateFormat;
    } else {
      return 'PPPP';
    }
  }

  getTimeFormat(countryCode?: string): string {
    if (this.accountInfoService.accountInfo?.timeFormat) {
      return this.accountInfoService.accountInfo.timeFormat;
    } else {
      return 'p';
      // if (this.get12HourTimeFormat(countryCode)) {
      //   return 'p';
      // }
      // return 'HH:mm';
    }
  }

  // get12HourTimeFormat(countryCode?: string) {
  //   if (!countryCode) {
  //     countryCode = this.localityService.getAccountCountry()?.code;
  //   }
  //   if (countryCode) {
  //     if (TimeFormatList12Hr.indexOf(countryCode) !== -1) {
  //       return true;
  //     }
  //   }
  //   return false;
  // }
}
