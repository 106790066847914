<div class="ion-page" *ngIf="pageMode">
  <ng-container *ngIf="true then main"></ng-container>
</div>

<ng-container *ngIf="!pageMode then main"></ng-container>

<ng-template #main>
  <ion-header class="header-light" #header>
    <ion-toolbar color="light">
      <ion-buttons slot="start">
        <ion-button (click)="dismissModal()" *ngIf="!pageMode">
          <ion-icon class="back-icon" src="./assets/wedding/icon/general/chevron-back.svg"></ion-icon>
        </ion-button>
        <ion-menu-button *ngIf="pageMode">
          <ion-icon class="icon-menu" src="./assets/wedding/icon/general/menu.svg"></ion-icon>
          <app-inbox-count></app-inbox-count>
        </ion-menu-button>
      </ion-buttons>
      <ion-title>
        <app-offline-indicator></app-offline-indicator>
        <ng-container *ngIf="assignMode && !swapMode">{{ 'SEATING.title.assign' | translate: { seating: seatingTypeName } }}</ng-container>
        <ng-container *ngIf="!assignMode && swapMode">{{ 'SEATING.title.swap' | translate: { seating: seatingTypeName } }}</ng-container>
        <ng-container *ngIf="!assignMode && !swapMode">{{ 'SEATING.title.list' | translate: { seating: seatingTypeName } }}</ng-container>
      </ion-title>
      <ion-buttons *ngIf="!swapMode && currentPrivilege?.[currentModule]?.['seating']" slot="end">
        <ion-button (click)="presentSeatingNewModal()">
          <ion-icon src="./assets/wedding/icon/general/add.svg" *ngIf="!editMode"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
    <ion-toolbar class="ion-no-padding background-transparent">
      <div class="content-div ion-padding-horizontal">
        <ion-segment scrollable [(ngModel)]="seatingView" (ionChange)="scrollToTop()">
          <ion-segment-button value="grid">
            <ion-label>{{ 'LIST.view.grid' | translate }}</ion-label>
          </ion-segment-button>
          <ion-segment-button value="list">
            <ion-label>{{ 'LIST.view.list' | translate }}</ion-label>
          </ion-segment-button>
        </ion-segment>
      </div>
    </ion-toolbar>
    <ion-toolbar class="ion-no-padding" color="light">
      <div class="content-div">
        <app-search [type]="'seating'" [reset]="resetSearch" (action)="search($event)" (setupHeight)="setupContentHeight()"></app-search>
      </div>
    </ion-toolbar>
    <ion-toolbar color="light" style="height: 30px; --min-height: 30px;">
      <div class="content-div">
        <ion-grid class="ion-no-padding font-12" fixed>
          <ion-row>
            <ion-col class="ion-text-left ion-align-self-center">
              <span class="center">
                <ion-card class="seating-legend ion-no-margin " color="success"></ion-card>
                <ion-text class="ion-text-uppercase">{{ 'LIST.occupancy.full' | translate }}</ion-text>
              </span>
            </ion-col>
            <ion-col class="ion-text-left ion-align-self-center">
              <span class="center">
                <ion-card class="seating-legend ion-no-margin" color="warning"></ion-card>
                <ion-text class="ion-text-uppercase">{{ 'LIST.occupancy.half' | translate }}</ion-text>
              </span>
            </ion-col>
            <ion-col class="ion-text-left ion-align-self-center" *ngIf="!swapMode">
              <span class="center">
                <ion-card class="seating-legend ion-no-margin"></ion-card>
                <ion-text class="ion-text-uppercase">{{ 'LIST.occupancy.empty' | translate }}</ion-text>
              </span>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
    </ion-toolbar>
    <ion-toolbar class="toolbar-select-all" color="light">
      <div class="content-div">
        <app-select-all [total]="count" [selected]="selectedCount" [selectAll]="selectAll" [expandAll]="expandAll" [editMode]="editMode" [expandMode]="seatingView === 'list' ? true : false" (toggleSelect)="toggleSelectAll()" (toggleExpand)="toggleExpandAll()"></app-select-all>
      </div>
    </ion-toolbar>
  </ion-header>
  <ion-content class="full-width" color="light" forceOverscroll="true" #content>  
    <ion-refresher class="background-light" slot="fixed" (ionRefresh)="doRefresh($event)">
      <ion-refresher-content class="background-light"
        pullingIcon="chevron-down-circle-outline"
        pullingText=""
        refreshingSpinner="circles"
        refreshingText="">
      </ion-refresher-content>
    </ion-refresher>
    <ion-list class="background-transparent" *ngIf="seatingList?.length" lines="none" #list>
      <ng-container *ngIf="seatingView === 'grid'">
        <ion-grid class="full-width" fixed>
          <ion-row class="ion-justify-content-center">
            <ng-container *ngIf="seatingList?.length && !assignMode">  
              <ng-container *ngFor="let seating of seatingList | slice : 0 : gridLimit; trackBy: trackByFn;">
                <ng-container *ngIf="!seating?.seatingId && !seating?.deleted && !seating?.name && seatingSummaryList?.[seating.name]">
                  <ion-col class="ion-align-self-center" [size]="'12'" *ngIf="seatingSummaryList[seating.name]?.guestList?.length">
                    <app-seating-view [width]="contentWidth" [mode]="mode" [seating]="seating" [selectedSeatingList]="selectedSeatingList" [editMode]="editMode" [assignMode]="assignMode" [swapMode]="swapMode" [seatingView]="seatingView" [expand]="expandAll" [ready]="ready" (seatingClick)="seatingClick(seating)"></app-seating-view>
                  </ion-col>
                </ng-container>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="seatingList?.length">  
              <ng-container *ngFor="let seating of seatingList | slice : 0 : gridLimit; trackBy: trackByFn;">
                <ng-container *ngIf="seating?.seatingId && !seating?.deleted && seating?.name">
                  <!-- [style.max-width]="seating?.count > 1 ? (500 + 'px') : ''" -->
                  <ion-col class="ion-align-self-center" [size]="seating?.count > 1 ? '12' : 'auto'">
                    <app-seating-view [width]="contentWidth" [gridSize]="gridSize" [gridNameHeight]="gridNameHeight" [mode]="mode" [seating]="seating" [selectedSeatingList]="selectedSeatingList" [editMode]="editMode" [assignMode]="assignMode" [swapMode]="swapMode" [seatingView]="seatingView" [expand]="expandAll" [ready]="ready" (seatingClick)="seatingClick(seating)"></app-seating-view>
                  </ion-col>
                </ng-container>
              </ng-container>
            </ng-container>
          </ion-row>
        </ion-grid>
        
        <ion-infinite-scroll threshold="5%" (ionInfinite)="loadGridData($event)" *ngIf="seatingList?.length && seatingList?.length > gridLimit else bottomSpace">
          <ion-infinite-scroll-content
            loadingSpinner="bubbles"
            [loadingText]="'LOADING.loading' | translate">
          </ion-infinite-scroll-content>
        </ion-infinite-scroll>
  
        <ng-template #bottomSpace>
          <div class="ion-padding">&nbsp;</div>
        </ng-template>
      </ng-container>
      <ng-container *ngIf="seatingView === 'list'">
        <div class="content-div">
          <ng-container *ngIf="seatingList?.length && !assignMode">  
            <ng-container *ngFor="let seating of seatingList | slice : 0 : gridLimit;">
              <ng-container *ngIf="!seating?.seatingId && !seating?.deleted && !seating?.name && seatingSummaryList?.[seating.name]">
                <ng-container *ngIf="seatingSummaryList[seating.name]?.guestList?.length">
                  <app-seating-view [width]="contentWidth" [gridSize]="gridSize" [gridNameHeight]="gridNameHeight" [ready]="ready" [mode]="mode" [seating]="seating" [selectedSeatingList]="selectedSeatingList" [editMode]="editMode" [assignMode]="assignMode" [swapMode]="swapMode" [seatingView]="seatingView" [expand]="expandAll"  (seatingClick)="seatingClick(seating)"></app-seating-view>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
          <ng-container *ngFor="let seating of seatingList | slice : 0 : listLimit;">
            <ng-container class="ion-align-self-center"*ngIf="seating?.seatingId && !seating?.deleted && seating?.name">
              <app-seating-view [width]="contentWidth" [gridSize]="gridSize" [gridNameHeight]="gridNameHeight" [ready]="ready" [mode]="mode" [seating]="seating" [selectedSeatingList]="selectedSeatingList" [editMode]="editMode" [assignMode]="assignMode" [swapMode]="swapMode" [seatingView]="seatingView" [expand]="expandAll" (seatingClick)="seatingClick(seating)"></app-seating-view>
            </ng-container>
          </ng-container>
        </div>
        <ion-infinite-scroll threshold="15%" (ionInfinite)="loadListData($event)" *ngIf="seatingList?.length && seatingList?.length > listLimit">
          <ion-infinite-scroll-content
            loadingSpinner="bubbles"
            [loadingText]="'LOADING.loading' | translate">
          </ion-infinite-scroll-content>
        </ion-infinite-scroll>
      </ng-container>
    </ion-list>
  
    <div class="full-width-height ion-padding ion-text-center vertical-center" *ngIf="!seatingList?.length && !searchTerm && !swapMode && currentPrivilege?.[currentModule]?.['seating']">
      <ion-card class="full-width ion-padding" color="tertiary" (click)="presentSeatingNewModal()" button>
        <ion-icon class="icon-large" src="./assets/wedding/icon/general/add-circle-outline.svg"></ion-icon>
        <div class="font-12">{{ 'CRUD.new_field' | translate: { field: seatingTypeName } }}</div>
      </ion-card>
    </div>
  
    <ng-container *ngIf="currentModule === 'gift' && !seatingList?.length && searchTerm">
      <div class="full-width-height vertical-center ion-padding">
        <div class="center">
          <ion-label class="ion-text-center ion-margin ion-padding" color="medium">
            <ion-icon class="center icon-cactus" src="./assets/wedding/icon/general/no-record.svg"></ion-icon>
            <div class="ion-margin-top font-14">{{ 'LBL.no_found' | translate }}</div>
          </ion-label>
        </div>
      </div>
    </ng-container>
  
    <ion-fab horizontal="end" vertical="bottom" slot="fixed" *ngIf="!assignMode && !swapMode && seatingList?.length && currentPrivilege?.[currentModule]?.['seating']">
      <ion-fab-button color="primary" (click)="toggleEditMode()">
        <ion-icon src="./assets/wedding/icon/general/chevron-up.svg" *ngIf="!editMode"></ion-icon>
        <ion-icon src="./assets/wedding/icon/general/close.svg" *ngIf="editMode"></ion-icon>
      </ion-fab-button>
    </ion-fab>
  </ion-content>
  <ion-footer class="ion-no-border" *ngIf="editMode" #footer>
    <app-bulk-action [mode]="'seating'" [count]="seatingList.length" [selectedList]="selectedSeatingList" [setReady]="true" (action)="bulkAction($event)"></app-bulk-action>
  </ion-footer>
</ng-template>
