import { AnalyticsService } from 'src/app/services/general/analytics.service';
import { OnlineService } from 'src/app/services/general/online.service';
import { LoginService } from 'src/app/services/general/login.service';
import { AuthService } from 'src/app/services/user/auth.service';
import { LinkService } from 'src/app/services/general/link.service';
import { ConfigService } from 'src/app/services/general/config.service';
import { AccountsListService } from 'src/app/services/accounts/accounts-list.service';
import { DatetimeComponent } from 'src/app/components/general/datetime/datetime.component';
import { Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { NavigationExtras, Router } from '@angular/router';
import { ModalController, ActionSheetController, PickerController, Platform, PickerColumnOption } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
// import { set, formatISO } from 'date-fns';

import { UserService } from 'src/app/services/user/user.service';
import { AccountService } from 'src/app/services/account/account.service';
import { AccountInfoService } from 'src/app/services/account/account-info.service';
import { AccountCoupleService } from 'src/app/services/account/account-couple.service';
import { AccountUserService } from 'src/app/services/account/account-user.service';
import { ErrorService } from 'src/app/services/general/error.service';
import { FunctionService } from 'src/app/services/general/function.service';
import { LanguageService } from 'src/app/services/general/language.service';
import { LocalityService } from 'src/app/services/location/locality.service';
import { PopupService } from 'src/app/services/general/popup.service';
import { TimezoneService } from 'src/app/services/general/timezone.service';
import { CurrencyService } from 'src/app/services/general/currency.service';
import { UpdateByService } from 'src/app/services/user/update-by.service';

import { AccountRoleComponent } from 'src/app/components/account/account-role/account-role.component';
import { LocationComponent } from 'src/app/components/general/location/location.component';

import { AccountInfo, AccountUser, AccountCouple, UserRole, CoupleList, Role } from 'src/app/interfaces/account';
import { UpdateBy } from 'src/app/interfaces/user';
import { Timezone, Country, SettingField } from 'src/app/interfaces/database';
import { LocationDetail, MediaType } from 'src/app/interfaces/general';

import { AccountsSetupStep } from 'src/app/types/accounts';

import { InvitedByService } from 'src/app/services/setting/invited-by.service';
import { SettingFieldType } from 'src/app/types/general';
import { CategoryService } from 'src/app/services/setting/category.service';
import { DietaryReqService } from 'src/app/services/setting/dietary-req.service';
import { SpecialReqService } from 'src/app/services/setting/special-req.service';
import { PhotoService } from 'src/app/services/general/photo.service';
import { SeatingNewComponent } from '../../seating/seating-new/seating-new.component';
import { Seating } from 'src/app/interfaces/seating';
import { SeatingSettingService } from 'src/app/services/seating/seating-setting.service';
import { SeatingService } from 'src/app/services/seating/seating.service';
import { SearchSelectListComponent } from '../../general/search-select-list/search-select-list.component';
import { SettingFieldService } from 'src/app/services/setting/setting-field.service';
import { DateTimeService } from 'src/app/services/general/date-time.service';
import { AccountEventModeService } from 'src/app/services/account/account-event-mode.service';
import { set } from 'date-fns';
import { UserManageService } from 'src/app/services/user/user-manage.service';

import { LocalizationService } from 'src/app/services/general/localization.service';
import { VisitorModeService } from 'src/app/services/visitor/visitor-mode.service';
import { DebugModeService } from 'src/app/services/general/debug-mode.service';
import { Subscription } from 'rxjs';
import { WebsiteCoverSample } from 'src/app/commons/website';
import { WebsiteThemeService } from 'src/app/services/website/website-theme.service';
import { VisitorSettingService } from 'src/app/services/visitor/visitor-setting.service';
import { GpsService } from 'src/app/services/general/gps.service';
import { WebsiteDetailService } from 'src/app/services/website/website-detail.service';
import { WebsiteInfoService } from 'src/app/services/website/website-info.service';
import { WebsiteVenueService } from 'src/app/services/website/website-venue.service';
import { WebsiteLinkService } from 'src/app/services/website/website-link.service';
import { AccountRoleService } from 'src/app/services/account/account-role.service';
import { StdActionList } from 'src/app/commons/action';
import { WebsiteMedia, WebsiteMediaList } from 'src/app/interfaces/website';

@Component({
  selector: 'app-accounts-setup-component',
  templateUrl: './accounts-setup.component.html',
  styleUrls: ['./accounts-setup.component.scss'],
})
export class AccountsSetupComponent implements OnInit, OnDestroy {

  @ViewChild('content', { read: ElementRef, static: false }) content: ElementRef;
  @ViewChild('gridContent', { read: ElementRef, static: false }) gridContent: ElementRef;

  /**
   * Watch screen resize change for login slider setup
   */
  @HostListener('window:resize', ['$event'])onResize(event: any) {
    this.setupCoverWidthHeight();
    this.resizeLimit();
  }
  /**
   * Watch screen orientation change for login slider setup
   */
  @HostListener('window:orientationchange', ['$event']) onOrientationChange(event: any) {
    this.setupCoverWidthHeight();
    this.resizeLimit();
  }  
  /**
   * page mode
   */
  @Input() pageMode: boolean;
  /**
   * Reset state
   */
  @Input() set reset(flag: boolean) {
    if (flag) {
      this.checkUserLogin();
    }
  }

  stdInvitedByList: SettingField[] = [];
  stdCategoryList: SettingField[] = [];
  stdDietaryReqList: SettingField[] = [];
  stdSpecialReqList: SettingField[] = [];

  invitedByList: SettingField[] = [];
  categoryList: SettingField[] = [];
  dietaryReqList: SettingField[] = [];
  specialReqList: SettingField[] = [];
  
  newWebsiteMode: boolean;
  newAccountMode: boolean;

  eventMode: boolean;
  migrate: boolean;
  /**
   * Accounts setup type
   */
  type: AccountsSetupStep;
  /**
   * Page location
   */
  pageLocation: LocationDetail;
  /**
   * Title form
   */
  coupleForm: FormGroup;
  /**
   * Account form
   */
  accountForm: FormGroup;

  venueForm: FormGroup;

  /**
   * Validation message
   */
  validationMsg: any;
  /**
   * Premium
   */
  premium: boolean;
  /**
   * Selected Couple ID
   */
  coupleId: number;
  /**
   * Role
   */
  role: UserRole;
  roleList: Role[];
  /**
   * Couple type
   */
  accountCoupleList: AccountCouple[];
  /**
   * Timezone
   */
  timezone: Timezone;
  /**
   * Country list
   */
  countryList: Country[] = this.localityService.getCountryList();
  /**
   * Timezone list
   */
  timezoneList: Timezone[] = [];
  /**
   * Accounts setup step
   */
  step: AccountsSetupStep;

  accountInfo: AccountInfo;

  seatingType: SettingField;

  seatingTypeOther: string;

  guestPerSeating: number;

  seatingList: Seating[];

  progress: number;

  countryLbl: string;

  seatingTypeName: string;

  invitedByTitle: string;
  categoryTitle: string;
  dietaryReqTitle: string;
  specialReqTitle: string;

  dateFormat: string;
  timeFormat: string;
  locale: string;

  formatStartDate: string;
  formatStartTime: string;
  formatEndDate: string;
  formatEndTime: string;
  
  selectedCover: MediaType;
  coverWidth: number;
  coverHeight: number;

  limit: number;
  
  coverSampleList: WebsiteMediaList = WebsiteCoverSample;
  coverList: WebsiteMedia[] = []; 

  private param: any;
  
  private gift: boolean;
  /**
   * Credit id
   */
  private creditId: string;
  /**
   * Account time
   */
  // private time: any;
  /**
   * Account title
   */
  private accountTitle: SettingField;
  /**
   * Couple list
   */
  private coupleList: CoupleList[];

  private websiteHostAvatarSubscription: Subscription;
  
  private coverPhotoSubscription: Subscription;

  /**
   * Constructor
   * @param router Router
   * @param formBuilder Form builder
   * @param fns Angularfire Cloud Function
   * @param modalController Modal Controller
   * @param actionSheetController Action Sheet Controller
   * @param pickerController Picker Controller
   * @param translate Translate
   * @param accountService Account Service
   * @param accountInfoService account info service
   * @param accountCoupleService Couple Service
   * @param accountUserService account user service
   * @param currencyService currency service
   * @param userService User Service
   * @param updateByService update by service
   * @param languageService language service
   * @param localityService locality service
   * @param timezoneService Timezone Service
   * @param popupService Popup Service
   * @param errorService Error Service
   * @param functionService Function service
   */
  constructor(
    private platform: Platform,
    private router: Router,
    private formBuilder: FormBuilder,
    private fns: AngularFireFunctions,
    private modalController: ModalController,
    private actionSheetController: ActionSheetController,
    private pickerController: PickerController,
    private translate: TranslateService,
    private accountService: AccountService,
    private accountRoleService: AccountRoleService,
    private accountEventModeService: AccountEventModeService,
    private accountInfoService: AccountInfoService,
    private accountCoupleService: AccountCoupleService,
    private accountUserService: AccountUserService,
    private accountsListService: AccountsListService,
    private invitedByService: InvitedByService,
    private categoryService: CategoryService,
    private dietaryReqService: DietaryReqService,
    private specialReqService: SpecialReqService,
    private seatingService: SeatingService,
    private seatingSettingService: SeatingSettingService,
    private settingFieldService: SettingFieldService,
    private currencyService: CurrencyService,
    private photoService: PhotoService,
    private authService: AuthService,
    private userService: UserService,
    private userManageService: UserManageService,
    private visitorModeService: VisitorModeService,
    private visitorSettingService: VisitorSettingService,
    private websiteDetailService: WebsiteDetailService,
    private websiteInfoService: WebsiteInfoService,
    private websiteVenueService: WebsiteVenueService,
    private websiteThemeService: WebsiteThemeService,
    private websiteLinkService: WebsiteLinkService,
    private debugModeService: DebugModeService,
    private loginService: LoginService,
    private updateByService: UpdateByService,
    private dateTimeService: DateTimeService,
    private languageService: LanguageService,
    private localizationService: LocalizationService,
    private localityService: LocalityService,
    private timezoneService: TimezoneService,
    private popupService: PopupService,
    private configService: ConfigService,
    private onlineService: OnlineService,
    private linkService: LinkService,
    private gpsService: GpsService,
    private functionService: FunctionService,
    private analyticsService: AnalyticsService,
    private errorService: ErrorService,
  ) { }

  /**
   * Ng on init
   */
  ngOnInit() {
  }

  ngOnDestroy() {
    this.unwatchWebsiteHostAvatar();
    this.unwatchCoverPhoto();
  }

  /**
   * Before view enter
   */
  async ionViewWillEnter() {
    await this.popupService.presentLoading();
    await this.initialize();
    this.popupService.dismissLoading();
  }

  async ionViewWillLeave() {
    this.unwatchWebsiteHostAvatar();
    this.unwatchCoverPhoto();
  }

  /**
   * Initialize
   */
  async initialize() {
    await this.platform.ready();

    this.coverList = Object.values(this.coverSampleList).sort((a: WebsiteMedia, b: WebsiteMedia) => {
      return this.functionService.compare(a?.order, b?.order);
    });
    this.newWebsiteMode = this.checkDebugMode();
    this.seatingTypeName = this.getSeatingTypeName();

    if (!this.type && (await this.accountService.readAccountId())) {
      await this.accountService.logoutAccount(true);
      this.newAccountMode = true;
    } else {
      this.newAccountMode = false;
    }

    if (this.accountInfoService?.accountInfo?.eventMode) {
      this.eventMode = true;
    } else if (!this.eventMode) {
      this.eventMode = false;
    }

    if (this.accountCoupleService.accountCoupleList?.length && !this.newAccountMode) {
      this.accountCoupleList = [ ...this.accountCoupleService.accountCoupleList ];
    } else {
      this.accountCoupleList = [ ...this.accountCoupleService.getStdCoupleType() ];
    }

    // if (!this.selectedCover?.url && WebsiteCoverSample?.[0]?.url) {
    //   this.selectedCover = WebsiteCoverSample[0];
    // }

    this.invitedByTitle = this.getSettingFieldTitle('invited_by');
    this.categoryTitle = this.getSettingFieldTitle('category');
    this.dietaryReqTitle = this.getSettingFieldTitle('dietary_req');
    this.specialReqTitle = this.getSettingFieldTitle('special_req');

    this.countryLbl = this.getCountryName();

    this.setupForm();
    this.setupAccountUser();
    this.setupAccountLocation();
    this.setupAccountTime();
    this.setupCoverWidthHeight();

    this.watchWebsiteHostAvatar();
    this.watchCoverPhoto();

    if (this.type && this.accountInfoService?.accountInfo?.location?.locality?.country) {
      const countryIndex = this.countryList?.findIndex((country: Country) =>
        country.code === this.accountInfoService.accountInfo.location.locality.country);
      this.accountForm.controls.country.setValue(this.countryList[countryIndex]);
      this.setupCountry(this.countryList[countryIndex]?.code)
    } else {
      if (this.userService.user?.locality?.country) {
        this.setupCountry(this.userService.user.locality.country);
      } else {
        const country = this.localityService.getAccountCountry();
        if (country?.code) {
          this.setupCountry(country.code);
        }
      }
    }

    if (this.type) {
      this.step = this.type;
    } else {
      this.step = 'couple';
    }
  }

  watchWebsiteHostAvatar() {
    if (!this.websiteHostAvatarSubscription) {
      this.websiteHostAvatarSubscription = this.photoService.observableWebsiteHostAvatar.subscribe(() => {
        this.setupAvatar();
      });
    }
  }

  unwatchWebsiteHostAvatar() {
    if (this.websiteHostAvatarSubscription) {
      this.websiteHostAvatarSubscription.unsubscribe();
      this.websiteHostAvatarSubscription = null;
    }
  }

  watchCoverPhoto() {
    if (!this.coverPhotoSubscription) {
      this.coverPhotoSubscription = this.photoService.observableWebsiteCover.subscribe((media: MediaType) => {
        this.selectedCover = media;
      });
    }
  }

  unwatchCoverPhoto() {
    if (this.coverPhotoSubscription) {
      this.coverPhotoSubscription.unsubscribe();
      this.coverPhotoSubscription = null;
    }
  }

  setupAvatar() {
    if (this.coupleForm) {
      if (this.photoService?.websiteHostAvatar?.['1']?.url) {
        this.coupleForm.controls.couple1Avatar.setValue(this.photoService.websiteHostAvatar['1']);
      }
      if (this.photoService?.websiteHostAvatar?.['2']?.url) {
        this.coupleForm.controls.couple2Avatar.setValue(this.photoService.websiteHostAvatar['2']);
      }
      if (this.photoService?.websiteHostAvatar?.['0']?.url) {
        this.coupleForm.controls.organizerAvatar.setValue(this.photoService.websiteHostAvatar['0']);
      }
    } else {
      setTimeout(() => {
        this.setupAvatar();
      }, 500);
    }
  }


  checkDebugMode() {
    return this.debugModeService.debugMode || this.checkAdmin() ? true : false;
  }

  checkAdmin() {
    if (this.userService?.user?.uid === '3SAfCuWtlWXAvSnk4at4zezSct72' || this.userService?.user?.uid === 'bhHqzdpkZIS4boEtyY1lfoUUr532' || this.userService?.user?.uid === '4wM05ZZyNmdebOI18F5jzYDdGXo2') {
      return true;
    }
    return false;
  }

  /**
   * Check user login
   */
   async checkUserLogin() {
    if (this.authService.currentUser) {
      if (this.userService.user?.name) {
        this.checkAnonymousLogin();
      } else {
        await this.popupService.dismissLoading();
        const modal = await this.loginService.presentUserSetupModal();
        modal.onDidDismiss().then(() => {
          this.checkAnonymousLogin();
          this.loginService.userSetupModal = null;
        });
      }
    } else {
      this.presentLoginModal(false, false);
    }
  }

  /**
   * Check anonymous login
   */
  async checkAnonymousLogin() {
    if (this.userService?.user?.isAnonymous) {
      this.presentLoginModal(false, false, true);
    } else {
      this.checkIsTrialAvailable();
    }
  }

  /**
   * Present login modal
   * @param loginRequired login required
   * @param showAnonymousLogin show anonymous login
   * @param linkProvider link provider
   */
  async presentLoginModal(loginRequired?: boolean, showAnonymousLogin?: boolean, linkProvider?: boolean) {
    await this.popupService.dismissLoading();
    const modal = await this.loginService.presentLoginModal(loginRequired, showAnonymousLogin, linkProvider);
    modal.onDidDismiss().then(async () => {
      if (this.userService.user && (showAnonymousLogin || !this.userService?.user?.isAnonymous)) {
        this.checkIsTrialAvailable();
      } else {
        this.goMainPage();
      }
    });
  }

  checkIsTrialAvailable() {
    if (!this.accountsListService.isNewTrialAvailable()) {
      this.promptTrialLimit();
    } else {
      this.initialize();
    }
  }

  getTrialLimit() {
    return this.configService?.trial?.trial_new ? this.configService.trial.trial_new : 1;
  }

  async promptTrialLimit() {
    const alert = await this.popupService.presentAlert(
      this.translate.instant('ACCOUNTS.trial.msg.trial_limit', { count: this.getTrialLimit() }));
    alert.onWillDismiss().then(() => {
      this.goMainPage();
    });
  }

  /**
   * Setup account user
   */
  setupAccountUser() {
    if (!this.newAccountMode) {
      if (this.accountService?.accountId) {
        const accountUser: AccountUser = this.accountUserService.getUserByUid(this.userService.uid);
        if (accountUser?.role?.coupleId) {
          this.coupleId = accountUser.role.coupleId;
        } else {
          this.role = accountUser?.role;
        }
      }
    }
  }

  /**
   * Setup account location
   */
  setupAccountLocation() {
    if (!this.newAccountMode) {
      this.pageLocation = this.accountInfoService?.accountInfo?.location;
    }
    
    if (!this.pageLocation) {
      this.pageLocation = {
        locality: {
          country: '',
          state: '',
          city: '',
          town: ''
        },
        name: '',
        address: '',
        placeId: ''
      };
    }
    if (!this.pageLocation?.locality?.country && this.localityService.getUserCountry()?.code) {
      this.pageLocation.locality.country = this.localityService.getUserCountry().code;
    }
    if (this.accountInfoService?.accountInfo?.timezone) {
      this.timezone = this.accountInfoService.accountInfo.timezone;
    }
    this.setupVenue();
  }

  /**
   * Get account time
   */
  setupAccountTime() {
    if (!this.newAccountMode) {
      if (this.accountInfoService?.accountInfo?.time?.seconds) {
        const newDate = set(new Date(this.accountInfoService.accountInfo.time.seconds * 1000), { seconds: 0, milliseconds: 0 });
        const dateISO = this.dateTimeService.formatISO(newDate);
        this.accountForm.controls.date.setValue(dateISO);
        this.accountForm.controls.time.setValue(dateISO);
      }
  
      if (this.accountInfoService?.accountInfo?.endTime?.seconds) {
        const newDate = set(new Date(this.accountInfoService.accountInfo.endTime.seconds * 1000), { seconds: 0, milliseconds: 0 });
        const dateISO = this.dateTimeService.formatISO(newDate);
        this.accountForm.controls.endTime.setValue(dateISO);
      }
    }
    
    this.setupFormatValue();
  }

  /**
   * Setup form
   */
  setupForm() {
    this.coupleForm = this.formBuilder.group({
      title: new FormControl('', [ Validators.required ]),
      couple1: new FormControl('', [ !this.eventMode ? Validators.required : Validators.nullValidator ]),
      couple1Avatar: new FormControl(this.getAvatar('bride'), [ !this.eventMode ? Validators.required : Validators.nullValidator ]),
      couple2: new FormControl('', [ !this.eventMode ? Validators.required : Validators.nullValidator ]),
      couple2Avatar: new FormControl(this.getAvatar('groom'), [ !this.eventMode ? Validators.required : Validators.nullValidator ]),
      organizer: new FormControl('', [ this.eventMode ? Validators.required : Validators.nullValidator ]),
      organizerAvatar: new FormControl(this.getAvatar('organizer'), [ this.eventMode ? Validators.required : Validators.nullValidator ]),
    });

    this.photoService.setupWebsiteHostAvatar('1', this.getAvatar('bride'));
    this.photoService.setupWebsiteHostAvatar('2', this.getAvatar('groom'));
    this.photoService.setupWebsiteHostAvatar('0', this.getAvatar('organizer'));

    this.accountForm = this.formBuilder.group({
      country: new FormControl('', [ Validators.required ]),
      date: new FormControl('', [ Validators.required ]),
      time: new FormControl('', [ Validators.required ]),
      endTime: new FormControl(''),
    });

    this.venueForm = this.formBuilder.group({
      venue: new FormControl(''),
      address: new FormControl(''),
      hall: new FormControl(''),
    });

    if (!this.newAccountMode) {
      if (this.accountInfoService?.accountInfo?.title?.value) {
        this.accountTitle = this.accountInfoService?.accountInfo?.title;
        this.coupleForm.controls.title.setValue(this.accountTitle.value);
      }
  
      if (this.accountInfoService?.accountInfo?.coupleList?.length) {
        this.accountInfoService?.accountInfo?.coupleList.forEach((couple: CoupleList) => {
          if (couple?.name) {
            const coupleId = couple?.accountCouple?.coupleId;
            const avatar = couple?.avatar?.url ? couple.avatar : this.getAvatar(couple?.accountCouple?.coupleType);
  
            if (coupleId === 1) {  
              this.coupleForm.controls.couple1.setValue(couple.name);
              this.coupleForm.controls.couple1Avatar.setValue(avatar);
              this.photoService.setupWebsiteHostAvatar('1', avatar);
            } else if (coupleId === 2) {
              this.coupleForm.controls.couple2.setValue(couple.name);
              this.coupleForm.controls.couple2Avatar.setValue(avatar);
              this.photoService.setupWebsiteHostAvatar('2', avatar);
            }
          }
        });
      }
  
      if (this.accountInfoService?.accountInfo?.organizer) {
        this.coupleForm.controls.organizer.setValue(this.accountInfoService.accountInfo.organizer);
        this.coupleForm.controls.organizerAvatar.setValue(this.accountInfoService.accountInfo.organizerAvatar);
      }
    }

    this.validationMsg = {
      couple1: [{ type: 'required', msg: this.translate.instant('VALIDATION.required', {
        field: this.translate.instant('FIELD.name', {
          field: (this.translate.instant('LIST.wedding_couple_type.' + this.getCoupleType(1))) 
        })
      })}],
      couple2: [{ type: 'required', msg: this.translate.instant('VALIDATION.required', {
        field: this.translate.instant('FIELD.name', {
          field: (this.translate.instant('LIST.wedding_couple_type.' + this.getCoupleType(2))) 
        })
      })}],
      title: [{ type: 'required', msg: this.translate.instant('VALIDATION.required', {
        field: this.translate.instant('ACCOUNT.lbl.wedding_title') }) }],
      organizer: [{ type: 'required', msg: this.translate.instant('VALIDATION.required', {
        field: this.translate.instant('FOR_EVENT.lbl.organizer') }) }],
      role: [{ type: 'required', msg: this.translate.instant('VALIDATION.required', {
        field: this.translate.instant('LBL.role') }) }],
      date: [{ type: 'required', msg: this.translate.instant('VALIDATION.required', {
        field: this.translate.instant('DATE.lbl.wedding_date') }) }],        
      time: [{ type: 'required', msg: this.translate.instant('VALIDATION.required', {
        field: this.translate.instant('DATE.lbl.wedding_time') }) }],
      country: [{ type: 'required', msg: this.translate.instant( 'VALIDATION.required', {
        field: this.translate.instant('LOCATION.lbl.wedding_country') }) }]
    };
  }

  /**
   * Setup couple type
   * @param accountCouple account couple
   */
  setupCoupleType(accountCoupleList: AccountCouple[]) {
    accountCoupleList.forEach((accountCouple: AccountCouple, index: number) => {
      if (!this.functionService.isEqual(accountCouple, this.accountCoupleList?.[index])) {
        const validationMsg = [{ type: 'required', msg: this.translate.instant('VALIDATION.required', {
          field: this.translate.instant('FIELD.name', {
            field: (this.translate.instant('LIST.wedding_couple_type.' + accountCouple.coupleType)) 
          })
        })}];
        if (accountCouple.coupleId === 1) {
          if (this.coupleForm.value.couple1Avatar?.type === 'local') {
            const avatar = this.getAvatar(accountCouple.coupleType);
            this.coupleForm.controls.couple1Avatar.setValue(avatar);
            this.photoService.setupWebsiteHostAvatar('1', avatar);
          }
          this.validationMsg.couple1 = validationMsg;
        } else if (accountCouple.coupleId === 2) {
          if (this.coupleForm.value.couple2Avatar?.type === 'local') {
            const avatar = this.getAvatar(accountCouple.coupleType);
            this.coupleForm.controls.couple2Avatar.setValue(avatar);
            this.photoService.setupWebsiteHostAvatar('2', avatar);
          }
          this.validationMsg.couple2 = validationMsg;
        }
      }
    });
    this.accountCoupleList = this.functionService.cloneDeep(accountCoupleList);
  }

  setupVenue() {
    if (this.pageLocation?.name) {
      this.venueForm.controls.venue.setValue(this.pageLocation.name);
    }
    if (this.pageLocation?.address) {
      this.venueForm.controls.address.setValue(this.pageLocation.address);
    }
  }

  setupProgress() {
    this.progress = this.getProgress();
  }

  /**
   * Country change event
   * @param event Event
   */
  countryChange(country: Country) {
    if (country?.code) {
      this.setupCountry(country.code);
    }
  }

  /**
   * Setup account title
   */
  setupAccountTitle() {
    if (this.coupleForm?.value?.couple1 && this.coupleForm?.value?.couple2) {
      if (!this.accountTitle) {
        this.accountTitle = { value: '', custom: false };
      }
      if (!this.accountTitle?.custom) {
        this.accountTitle = {
          value: this.translate.instant('ACCOUNT.auto_title', {
            couple1: this.coupleForm.value.couple1, couple2: this.coupleForm.value.couple2
          }),
          custom: false
        };
        this.coupleForm.controls.title.setValue(this.accountTitle.value);
      }
    }
    this.setupCustomAccountTitle();
  }

  /**
   * Setup custom account title
   */
  setupCustomAccountTitle() {
    if (this.coupleForm.value.title) {
      if (!this.eventMode) {
        if (this.coupleForm.value.couple1 && this.coupleForm.value.couple2) {
          const autoTitle = this.translate.instant('ACCOUNT.auto_title', {
            couple1: this.coupleForm.value.couple1, couple2: this.coupleForm.value.couple2 });
          if (this.coupleForm.value.title !== autoTitle) {
            this.accountTitle = {
              value: this.coupleForm.value.title,
              custom: true,
            }
          } else {
            this.accountTitle = {
              value: autoTitle,
              custom: false,
            }
          }
        }
      }  else {
        this.accountTitle = {
          value: this.coupleForm.value.title,
          custom: true,
        }
      }
    }
  }

  /**
   * Setup country and setup timezone list based on country
   * @param countryCode Country Code
   */
  setupCountry(countryCode: string) {
    if (countryCode && this.accountForm?.value?.country?.code !== countryCode) {
      const countryIndex = this.countryList?.findIndex((x: Country) => x?.code === countryCode);
      if (countryIndex !== -1) {
        this.accountForm.controls.country.setValue(this.countryList[countryIndex]);
        this.setupTimezoneList(this.countryList[countryIndex].timezones);
        this.dateFormat = this.localizationService.getDateFormat();
        this.timeFormat = this.localizationService.getTimeFormat(countryCode);

        this.locale = this.localizationService.getLocale(countryCode);
        this.setupFormatValue();
      }
    }
  }

  /**
   * Setup timezone list
   * @param timezones Timezone
   */
  setupTimezoneList(timezones: string[]) {
    this.timezoneList = this.timezoneService.getTimezoneList(timezones);
    if (this.timezoneList?.[0]?.name) {
      const index = this.timezoneList?.findIndex((x: Timezone) => this.functionService.isEqual(x, this.timezoneService.getUserTimezone()));
      if (index !== -1) {
        this.timezone = this.timezoneList[index];
      } else {
        this.timezone = this.timezoneList[0];
      }
      // this.setupIsoTime();
    } else {
      this.timezone = null;
    }
  }

  setupEventMode(eventMode: boolean) {
    this.eventMode = eventMode;
    this.accountEventModeService.eventMode = eventMode;
  }

  setupInvitedBy() {
    const stdList = this.invitedByService.getStdInvitedByList(this.eventMode);
    if (this.invitedByService.invitedByList?.length) {
      this.invitedByList = [ ...this.invitedByService.getInvitedByList(this.eventMode) ];
    } else if (!this.invitedByList?.length) {
      this.invitedByList = [ ...this.invitedByService.getDefaultInvitedByList(this.eventMode) ];
    }
    this.stdInvitedByList = stdList.concat(this.invitedByList).filter((item, index, array) => {
      return array.findIndex(i => i.id === item.id || (i.custom === item.custom && i.value === item.value)) === index;
    });
  }

  setupCategory() {
    const stdList = this.categoryService.getStdCategoryList(this.eventMode);
    if (this.categoryService.categoryList?.length) {
      this.categoryList = [ ...this.categoryService.getCategoryList(this.eventMode) ];
    } else if (!this.categoryList?.length) {
      this.categoryList = [ ...this.categoryService.getDefaultCategoryList(this.eventMode) ];
    }
    this.stdCategoryList = stdList.concat(this.categoryList).filter((item, index, array) => {
      return array.findIndex(i => i.id === item.id || (i.custom === item.custom && i.value === item.value)) === index;
    });
  }

  setupDietaryReq() {
    const stdList = this.dietaryReqService.getStdDietaryReqList();
    if (this.dietaryReqService.dietaryReqList?.length) {
      this.dietaryReqList = [ ...this.dietaryReqService.getDietaryReqList() ];
    } else if (!this.dietaryReqList?.length) {
      this.dietaryReqList = [ ...this.dietaryReqService.getDefaultDietaryReqList() ];
    }
    this.stdDietaryReqList = stdList.concat(this.dietaryReqList).filter((item, index, array) => {
      return array.findIndex(i => i.id === item.id || (i.custom === item.custom && i.value === item.value)) === index;
    });
  }

  setupSpecialReq() {
    const stdList = this.specialReqService.getStdSpecialReqList(this.eventMode);
    if (this.specialReqService.specialReqList?.length) {
      this.specialReqList = [ ...this.specialReqService.getSpecialReqList(this.eventMode) ];
    } else if (!this.specialReqList?.length) {
      this.specialReqList = [ ...this.specialReqService.getDefaultSpecialReqList(this.eventMode) ];
    }
    this.stdSpecialReqList = stdList.concat(this.specialReqList).filter((item, index, array) => {
      return array.findIndex(i => i.id === item.id || (i.custom === item.custom && i.value === item.value)) === index;
    });
  }

  /**
   * Setup timezone
   * @param timezone Timezone
   */
  setupTimezone(timezone: any) {
    const timezoneIndex = this.timezoneList?.findIndex((x: Timezone) => x?.name === timezone);
    if (timezoneIndex !== -1) {
      this.timezone = this.timezoneList[timezoneIndex];
    } else {
      this.timezone = null;
    }
  }

  setupSeatingSetting() {
    if (!this.guestPerSeating) {
      this.guestPerSeating = (this.seatingSettingService.getGuestPerSeating(this.accountForm?.value?.country));
    }
    if (!this.seatingType?.value) {
      this.seatingType = (this.seatingSettingService.getSeatingType());
    }
    if (this.seatingType?.value === 'others' && !this.seatingTypeOther && this.seatingSettingService.seatingTypeOther) {
      this.seatingTypeOther = (this.seatingSettingService.seatingTypeOther);
    }
    this.setupSeatingList();
  }

  setupSeatingList(seatingList?: Seating[]) {
    if (!seatingList?.length) {
      seatingList = [];
    }
    if (this.seatingService.seatingList?.length) {
      this.seatingList = seatingList.concat([ ...this.seatingService.seatingList ]).filter((item, index, array) => {
        return array.findIndex(i => i.name === item.name) === index;
      });
    } else {
      this.seatingList = seatingList;
    }
  }

  setupFormatValue() {
    if (this.accountForm?.value?.date) {
      const timestamp = new Date(this.accountForm.value.date).getTime();
      this.formatStartDate = this.getFormFormattedDate(timestamp);
    }
    if (this.accountForm?.value?.time) {
      const timestamp = new Date(this.accountForm.value.time).getTime();
      this.formatStartTime = this.getFormFormattedTime(timestamp);
    }
    if (this.accountForm?.value?.endTime) {
      const timestamp = new Date(this.accountForm.value.endTime).getTime();
      this.formatEndDate = this.getFormFormattedDate(timestamp);
      this.formatEndTime = this.getFormFormattedTime(timestamp);
    }
  }

  format(timestamp: number | Date, dateTimeFormat: string, timeZone?: string, language?: string, locale?: string) {
    return this.dateTimeService.format(timestamp, dateTimeFormat, language, timeZone, locale);
  }

  formatNewDate(date: string) {
    return new Date(date);
  }

  generateTimeStamp(setTimezone?: boolean) {
    let date = this.accountForm?.value?.date;
    let time = this.accountForm?.value?.time;
    
    date = this.format((date ? new Date(date) : new Date()), 'yyyy-MM-dd', null, 'en', 'en-US');
    if (time) {
      time = this.format(new Date(time), 'HH:mm', null, 'en', 'en-US');
    }

    let dateTime = date + 'T' + (time ? time : '19:00') + ':00.000';
    if (setTimezone) {
      dateTime += this.timezone.utc.replace('UTC', '')
    }
    const timestamp = this.functionService.generateTimestamp(dateTime);
    return timestamp;
  }

  generateEndTimeStamp(setTimezone?: boolean) {
    // let date = this.accountForm?.value?.endDate;
    let time = this.accountForm?.value?.endTime;

    if (time) {
      const date = this.format(new Date(time), 'yyyy-MM-dd', null, 'en', 'en-US');
      time = this.format(new Date(time), 'HH:mm', null, 'en', 'en-US');

      let dateTime = date + 'T' + time + ':00.000';
      if (setTimezone) {
        dateTime += this.timezone.utc.replace('UTC', '')
      }
      const timestamp = this.functionService.generateTimestamp(dateTime);

      return timestamp;
    }
    return '';
  }

  /**
   * Prepare for timezone picker value
   */
  async openTimezone() {
    if (!this.timezoneList?.length) {
      if (this.accountForm?.value?.country?.code && this.accountForm?.value?.country?.timezones?.length) {
        this.setupTimezoneList(this.accountForm.value.country.timezones);
      }
    }
    if (this.timezoneList?.length) {
      let selectedIndex = 0;
      const options: PickerColumnOption[] = [];
      this.timezoneList?.forEach((value: Timezone, index: number) => {
        if (value === this.timezone) {
          selectedIndex = index;
        }
        options.push({
          text: value.label,
          value
        });
      });
      this.presentTimezonePicker(options, selectedIndex);
    }
  }

  /**
   * Dismiss modal
   * @param dismiss dismiss flag
   */
   async dismissModal(dismiss?: boolean) {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { this.modalController.dismiss({ dismiss }); }
    }
  }

  async presentCoupleTypePicker(coupleId: number) {
    if (coupleId) {
      const options = [];
      this.accountCoupleService?.stdCoupleType?.forEach((value: string) => {
        options.push({
          value,
          text: this.translate.instant('LIST.wedding_couple_type.' + value)
        });
      });
      const accountCoupleIndex = this.accountCoupleList.findIndex((x: AccountCouple) => {
        return x.coupleId === coupleId;
      });
      const selectedIndex = options?.findIndex(x => x?.value === this.accountCoupleList?.[accountCoupleIndex]?.coupleType);
      if (selectedIndex !== -1) {
        const picker = await this.pickerController.create({
          columns: [{
            name: 'accountCouple',
            selectedIndex,
            options
          }],
          buttons: [{
            text: this.translate.instant('BTN.cancel'),
            role: 'cancel'
          }, {
            text: this.translate.instant('BTN.confirm'),
            handler: (result: any) => {
              if (result?.accountCouple?.value) {
                const accountCoupleList = this.functionService.cloneDeep(this.accountCoupleList);
                const accountCoupleIndex = accountCoupleList?.findIndex((x: AccountCouple) => x.coupleId === coupleId);
                if (accountCoupleIndex !== -1 &&
                accountCoupleList?.[accountCoupleIndex]?.coupleType !== result?.accountCouple?.value) {
                  accountCoupleList[accountCoupleIndex].coupleType = result.accountCouple.value;
                  this.setupCoupleType(accountCoupleList);
                }
              }
            }
          }]
        });
        picker.present();
      }
    }
  }

  /**
   * Present timezone picker
   * @param options Options
   * @param selectedIndex Selected Index
   */
  async presentTimezonePicker(options: any, selectedIndex?: number) {
    const picker = await this.pickerController.create({
      columns: [{
        name: 'timezone',
        selectedIndex,
        options
      }],
      buttons: [{
        text: this.translate.instant('BTN.cancel'),
        role: 'cancel'
      }, {
        text: this.translate.instant('BTN.confirm'),
        handler: (result: any) => {
          if (result?.timezone?.value?.name) {
            this.setupTimezone(result.timezone.value.name);
          }
        }
      }]
    });
    picker.present();
  }

  /**
   * Present role modal
   * @param selected Selected
   */
  async presentRoleModal(selected: UserRole) {
    const modal = await this.modalController.create({
      component: AccountRoleComponent,
      componentProps: {
        selected,
        newAccount: true,
        eventMode: this.eventMode,
      }
    });
    modal.present();
    modal.onWillDismiss().then((result: any) => {
      if (result?.data?.selected) {
        this.coupleId = 0;
        this.role = result.data.selected;
      }
      this.roleList = result?.data?.roleList ? result.data.roleList : [];
    });
  }

  /**
   * Present location modal for country / timezone / venue selection
   */
  async presentLocationModal() {
    if (this.accountForm?.value?.country) {
      const modal = await this.modalController.create({
        component: LocationComponent,
        cssClass: 'modal-full-screen-bk',
        componentProps: {
          country: this.accountForm?.value?.country,
          timezone: this.timezone,
          location: this.pageLocation,
          disableCountry: true,
          disableWatchAccountInfo: true,
          eventMode: this.eventMode,
        }
      });
      modal.present();
      modal.onWillDismiss().then((result: any) => {
        if (result?.data?.location) {
          this.pageLocation = result.data.location;
          this.setupVenue();
          // if (this.pageLocation.locality.country !== this.accountForm.value.country.code) {
          //   this.setupCountry(this.pageLocation.locality.country);
          // }
        } else {
          // this.pageLocation = null;
        }
      });
    } else {
      this.popupService.presentActionError();
    }
  }

  /**
   * Present date time picker modal
   */
  async presentDatetimeModal(presentation: string, type: string) {
    // this.dateTimeService.formatIsoByTimestamp(new Date(value).getTime() / 1000)
    let time: string = '';
    if (type === 'start' && presentation === 'time') {
      const timestamp = this.generateTimeStamp();
      if (timestamp) {
        time = this.dateTimeService.formatIsoByTimestamp(timestamp?.seconds);
      }
    } else if (type === 'start' && presentation === 'date') {
      const timestamp = this.generateTimeStamp();
      if (timestamp) {
        time = this.dateTimeService.formatIsoByTimestamp(timestamp?.seconds);
      }
    } else if (type === 'end') {
      if (this.accountForm?.value?.endTime) {
        time = this.dateTimeService.formatIsoByTimestamp(new Date(this.accountForm?.value?.endTime).getTime() / 1000);
      } else {
        time = this.dateTimeService.formatIsoByTimestamp((new Date(this.accountForm?.value?.time).getTime() / 1000) + (60 * 60 * 3));
      }
    }
    // if (presentation === 'date') {
    //   time = this.dateTimeService.formatIsoByTimestamp(new Date(this.accountForm?.value?.date).getTime() / 1000);
    // } else {
    //   if (type === 'end') {
    //     time = this.dateTimeService.formatIsoByTimestamp(new Date(this.accountForm?.value?.time).getTime() / 1000);
    //   } else {
    //     time = this.dateTimeService.formatIsoByTimestamp(new Date(this.accountForm?.value?.endTime).getTime() / 1000);
    //   }
    // }
    const modal = await this.modalController.create({
      component: DatetimeComponent,
      cssClass: 'modal-transparent',
      backdropDismiss: true,
      componentProps: {
        time,
        min: type === 'end' && this.accountForm?.value?.time ? this.dateTimeService.formatIsoByTimestamp((new Date(this.accountForm?.value?.time).getTime() / 1000) + 60) : '',
        clearBtn: type === 'end' && this.accountForm?.value?.endTime ? true : false,
        // timezone: this.timezone,
        presentation,
        showDateFormat: presentation === 'date' || presentation === 'date-time',
        showTimeFormat: presentation === 'time' || presentation === 'date-time',
        dateFormat: this.dateFormat ? this.dateFormat : this.localizationService.getDateFormat(),
        timeFormat: this.timeFormat ? this.timeFormat : this.localizationService.getTimeFormat(this.accountForm?.value?.country?.code),
        locale: this.locale ? this.locale : this.accountInfo?.locale ? this.accountInfo.locale : '',
        country: this.accountForm?.value?.country?.code ? this.accountForm.value.country.code : '',
      }
    });
    modal.present();
    modal.onWillDismiss().then((result: any) => {
      if (result?.data?.dateTime) {
        if (type === 'start') {
          if (presentation === 'date') {
            this.accountForm.controls.date.setValue(result.data.dateTime);
            if (this.accountForm?.value?.time) {
              this.accountForm.controls.time.setValue(result.data.dateTime);
            }
          } else if (presentation === 'time') {
            if (this.accountForm?.value?.date) {
              this.accountForm.controls.date.setValue(result.data.dateTime);
            }
            this.accountForm.controls.time.setValue(result.data.dateTime);
          }
          
          if (this.accountForm?.value?.time && this.accountForm?.value?.endTime) {
            if (new Date(this.accountForm?.value?.time).getTime() > new Date(this.accountForm?.value?.endTime).getTime()) {
              this.accountForm.controls.endTime.setValue('');
            }
          }
        } else if (type === 'end') {
          this.accountForm.controls.endTime.setValue(result.data.dateTime);
          if (new Date(this.accountForm?.value?.time).getTime() > new Date(this.accountForm?.value?.endTime).getTime()) {
            this.accountForm.controls.endTime.setValue('');
          }
        }
      }
      if (result?.data?.dateFormat) {
        this.dateFormat = result.data.dateFormat;
      }
      if (result?.data?.timeFormat) {
        this.timeFormat = result.data.timeFormat;
      }
      if (result?.data?.locale) {
        this.locale = result.data.locale;
      }
      this.setupFormatValue();
    });
  }
  

  async presentSeatingNewModal() {
    const modal = await this.modalController.create({
      component: SeatingNewComponent,
      cssClass: '',
      componentProps: {
        newMode: true,
        guestPerSeating: this.guestPerSeating,
        seatingType: this.seatingType,
        seatingTypeOther: this.seatingTypeOther,
      }
    });
    modal.present();
    modal.onWillDismiss().then((result: any) => {
      if (result?.data?.seatingList?.length) {
        this.setupSeatingList(result.data.seatingList);
      }
      if (result?.data?.guestPerSeating && result.data.guestPerSeating !== this.guestPerSeating) {
        this.guestPerSeating = result.data.guestPerSeating;
      }
    });
  }

  async openCountryModal() {
    const selected = this.accountForm?.value?.country;
    const modal = await this.modalController.create({
      component: SearchSelectListComponent,
      componentProps: {
        items: this.localityService.getCountryList(),
        selected,
        title: this.getCountryName(),
        itemTextField: 'name',
        placeholder: this.translate.instant('BTN.search'),
        closeButtonText: this.translate.instant('BTN.cancel'),
      }
    });
    modal.present();
    modal.onWillDismiss().then((result: any) => {
      if (result?.data?.item) {
        this.countryChange(result.data.item);
      }
    });
  }

  // selectPhoto(deleteBtn?: boolean) {
  //   this.photoService.selectPhoto('account_gallery', false, deleteBtn);
  // }

  selectAvatarPhoto(coupleId: number) {
    const coupleType = this.getCoupleType(coupleId);
    const header = coupleType ? this.translate.instant('LIST.wedding_couple_type.' + coupleType) : '';

    if (coupleId === 1) {
      this.photoService.selectPhoto('couple1_avatar', false, false, header);
    } else if (coupleId === 2) {
      this.photoService.selectPhoto('couple2_avatar', false, false, header);
    } else if (coupleId === 0) {
      this.photoService.selectPhoto('host_avatar', false, false, header);
    }
  }

  selectCoupleRole(accountCouple: AccountCouple) {
    this.role = {
      coupleId: accountCouple.coupleId,
      type: accountCouple.coupleType,
      custom: false,
    };
    this.coupleId = accountCouple.coupleId;
  }

  selectOrganizerRole() {
    this.role = {
      coupleId: 0,
      type: 'organizer',
      custom: false,
    };
    this.coupleId = 0;
  }

  selectRole(role: UserRole) {
    this.role = role;
    this.coupleId = 0;
  }

  getCountryName() {
    if (this.eventMode) {
      return this.replaceEventType(this.translate.instant('LOCATION.lbl.wedding_country'));
    } else {
      return this.translate.instant('LOCATION.lbl.wedding_country');
    }
  }

  getCountryByCode(countryCode: string) {
    return this.localityService.getCountryByCode(countryCode);
  }

  getAccountGalleryList() {
    if (this.accountInfoService.accountInfo?.gallery?.length) {
      return this.accountInfoService.accountInfo.gallery;
    }
    return this.photoService?.accountGalleryList ? this.photoService.accountGalleryList : [];
  }

  /**
   * Get account couple type
   * @param coupleId couple id
   * @returns account couple type
   */
  getCoupleType(coupleId: number): string {
    if (this.accountCoupleList?.length) {
      const index = this.accountCoupleList.findIndex((accountCouple: AccountCouple) => accountCouple?.coupleId === coupleId);
      if (index !== -1) {
        return this.accountCoupleList[index].coupleType;
      }
    }
    return '';
  }

  /**
   * Check if same couple type applied
   * @returns True if same couple type
   */
  checkSameCoupleType(): boolean {
    if (this.getCoupleType(1) === this.getCoupleType(2)) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Select couple
   * @param coupleId Couple ID
   */
  selectCouple(coupleId: number) {
    this.coupleId = coupleId;
    this.role = null;
  }

  setOtherRole() {
    this.coupleId = 0;
    // if (!this.role || this.role.coupleId) {
      this.role = {
        custom: true,
        type: '',
        coupleId: 0,
      };
    // }
  }

  getValue(settingFieldType: SettingFieldType, settingField: SettingField): string {
    if (settingField?.value) {
      if (settingField?.custom) {
        return settingField.value;
      } else if (settingFieldType) {
        return this.translate.instant('LIST.' + settingFieldType + '.' + settingField.value);
      }
    }
    return '';
  }

  checkSelectedSettingField(settingFieldType: SettingFieldType, settingField: SettingField): boolean {
    if (settingFieldType && settingField) {
      if (settingFieldType === 'invited_by') {
        const index = this.invitedByList.findIndex((x: SettingField) => {
          return x.value === settingField?.value && x.custom === settingField?.custom;
        });
        if (index !== -1) {
          return true;
        }
      } else if (settingFieldType === 'category') {
        const index = this.categoryList.findIndex((x: SettingField) => {
          return x.value === settingField?.value && x.custom === settingField?.custom;
        });
        if (index !== -1) {
          return true;
        }
      } else if (settingFieldType === 'dietary_req') {
        const index = this.dietaryReqList.findIndex((x: SettingField) => {
          return x.value === settingField?.value && x.custom === settingField?.custom;
        });
        if (index !== -1) {
          return true;
        }
      } else if (settingFieldType === 'special_req') {
        const index = this.specialReqList.findIndex((x: SettingField) => {
          return x.value === settingField?.value && x.custom === settingField?.custom;
        });
        if (index !== -1) {
          return true;
        }
      }
    }
    return false;
  }

  getSettingFieldTitle(settingFieldType: SettingFieldType) {
    if (settingFieldType === 'invited_by') {
      return this.translate.instant('GUEST.lbl.invited_by');
    } else if (settingFieldType === 'category') {
      return this.translate.instant('LBL.category');
    } else if (settingFieldType === 'dietary_req') {
      return this.translate.instant('GUEST.lbl.dietary_req');
    } else if (settingFieldType === 'special_req') {
      return this.translate.instant('GUEST.lbl.special_req');
    }
    return '';
  }

  getFormFormattedDate(time: number) {
    if (time) {
      const dateFormat = this.dateFormat ? this.dateFormat : this.localizationService.getDateFormat();
      const locale = this.locale ? this.locale : this.localizationService.getLocale();
  
      return this.dateTimeService.format(time, dateFormat, '', this.accountInfo?.timezone?.name, locale);
    }
    return '';
  }

  getFormFormattedTime(time: number) {
    if (time) {
      const timeFormat = this.timeFormat ? this.timeFormat : this.localizationService.getTimeFormat();
      const locale = this.locale ? this.locale : this.localizationService.getLocale();
      
      return this.dateTimeService.format(time, timeFormat, '', this.accountInfo?.timezone?.name, locale);
    }
    return '';
  }

  getAvatar(coupleType: string): MediaType {
    const avatar: MediaType = {
      url: './assets/wedding/img/avatar/blank.svg',
      type: 'local',
    };
    if (coupleType === 'bride') {
      avatar.url = './assets/wedding/img/avatar/bride.svg';
    } else if (coupleType === 'groom') {
      avatar.url = './assets/wedding/img/avatar/groom.svg';
    } else if (coupleType === 'organizer') {
      avatar.url = '';
    }
    return avatar;
  }

  clickSettingField(settingFieldType: SettingFieldType, settingField: SettingField) {
    if (settingFieldType && settingField) {
      if (settingFieldType === 'invited_by') {
        const index = this.invitedByList.findIndex((x: SettingField) => {
          return x.value === settingField?.value && x.custom === settingField?.custom;
        });
        if (index !== -1) {
          this.invitedByList.splice(index, 1);
        } else {
          this.invitedByList.push(settingField);
        }
      } else if (settingFieldType === 'category') {
        if (!settingField?.custom && settingField.value === 'others') {
          this.popupService.presentToast(this.translate.instant('VALIDATION.reserved', { field: this.getSettingFieldTitle(settingFieldType) }), 'warning')
        } else {
          const index = this.categoryList.findIndex((x: SettingField) => {
            return x.value === settingField?.value && x.custom === settingField?.custom;
          });
          if (index !== -1) {
            this.categoryList.splice(index, 1);
          } else {
            this.categoryList.push(settingField);
          }
        }
      } else if (settingFieldType === 'dietary_req') {
        if (!settingField?.custom && settingField.value === 'none') {
          this.popupService.presentToast(this.translate.instant('VALIDATION.reserved', { field: this.getSettingFieldTitle(settingFieldType) }), 'warning')
        } else {
          const index = this.dietaryReqList.findIndex((x: SettingField) => {
            return x.value === settingField?.value && x.custom === settingField?.custom;
          });
          if (index !== -1) {
            this.dietaryReqList.splice(index, 1);
          } else {
            this.dietaryReqList.push(settingField);
          }
        }
      } else if (settingFieldType === 'special_req') {
        if (!settingField?.custom && settingField.value === 'none') {
          this.popupService.presentToast(this.translate.instant('VALIDATION.reserved', { field: this.getSettingFieldTitle(settingFieldType) }), 'warning')
        } else {
          const index = this.specialReqList.findIndex((x: SettingField) => {
            return x.value === settingField?.value && x.custom === settingField?.custom;
          });
          if (index !== -1) {
            this.specialReqList.splice(index, 1);
          } else {
            this.specialReqList.push(settingField);
          }
        }
      }
    }
  }

  resetSettingField(settingFieldType: SettingFieldType) {
    if (settingFieldType === 'invited_by') {
      this.invitedByList = [ ...this.invitedByService.getDefaultInvitedByList(this.eventMode) ];
    } else if (settingFieldType === 'category') {
      this.categoryList = [ ...this.categoryService.getDefaultCategoryList(this.eventMode) ];
    } else if (settingFieldType === 'dietary_req') {
      this.dietaryReqList = [ ...this.dietaryReqService.getDefaultDietaryReqList() ];
    } else if (settingFieldType === 'special_req') {
      this.specialReqList = [ ...this.specialReqService.getDefaultSpecialReqList(this.eventMode) ];
    }
  }

  resetVenue() {
    if (this.pageLocation?.name) {
      this.venueForm.controls.venue.setValue(this.pageLocation.name);
    }
  }

  resetAddress() {
    if (this.pageLocation?.address) {
      this.venueForm.controls.address.setValue(this.pageLocation.address);
    }
  }

  checkDuplicateSettingField(settingFieldType: SettingFieldType, input: string) {
    if (settingFieldType && input) {
      if (settingFieldType === 'invited_by') {
        const index = this.invitedByList.findIndex((settingField: SettingField) => {
          if (settingField?.custom) {
            if (settingField?.value?.toLowerCase() === input.toLowerCase()) {
              return true;
            }
          } else {
            if (this.getValue(settingFieldType, settingField).toLowerCase() === input.toLowerCase()) {
              return true;
            }
          }
          return false;
        });
        if (index !== -1) {
          return true;
        } else {
          const index2 = this.stdInvitedByList.findIndex((settingField: SettingField) => {
            if (settingField?.custom) {
              if (settingField?.value?.toLowerCase() === input.toLowerCase()) {
                return true;
              }
            } else {
              if (this.getValue(settingFieldType, settingField).toLowerCase() === input.toLowerCase()) {
                return true;
              }
            }
            return false;
          });
          if (index2 !== -1) {
            return true;
          }
        }
      } else if (settingFieldType === 'category') {
        const index = this.categoryList.findIndex((settingField: SettingField) => {
          if (settingField?.custom) {
            if (settingField?.value?.toLowerCase() === input.toLowerCase()) {
              return true;
            }
          } else {
            if (this.getValue(settingFieldType, settingField).toLowerCase() === input.toLowerCase()) {
              return true;
            }
          }
          return false;
        });
        if (index !== -1) {
          return true;
        } else {
          const index2 = this.stdCategoryList.findIndex((settingField: SettingField) => {
            if (settingField?.custom) {
              if (settingField?.value?.toLowerCase() === input.toLowerCase()) {
                return true;
              }
            } else {
              if (this.getValue(settingFieldType, settingField).toLowerCase() === input.toLowerCase()) {
                return true;
              }
            }
            return false;
          });
          if (index2 !== -1) {
            return true;
          }
        }
      }  else if (settingFieldType === 'dietary_req') {
        const index = this.dietaryReqList.findIndex((settingField: SettingField) => {
          if (settingField?.custom) {
            if (settingField?.value?.toLowerCase() === input.toLowerCase()) {
              return true;
            }
          } else {
            if (this.getValue(settingFieldType, settingField).toLowerCase() === input.toLowerCase()) {
              return true;
            }
          }
          return false;
        });
        if (index !== -1) {
          return true;
        } else {
          const index2 = this.stdDietaryReqList.findIndex((settingField: SettingField) => {
            if (settingField?.custom) {
              if (settingField?.value?.toLowerCase() === input.toLowerCase()) {
                return true;
              }
            } else {
              if (this.getValue(settingFieldType, settingField).toLowerCase() === input.toLowerCase()) {
                return true;
              }
            }
            return false;
          });
          if (index2 !== -1) {
            return true;
          }
        }
      } else if (settingFieldType === 'special_req') {
        const index = this.specialReqList.findIndex((settingField: SettingField) => {
          if (settingField?.custom) {
            if (settingField?.value?.toLowerCase() === input.toLowerCase()) {
              return true;
            }
          } else {
            if (this.getValue(settingFieldType, settingField).toLowerCase() === input.toLowerCase()) {
              return true;
            }
          }
          return false;
        });
        if (index !== -1) {
          return true;
        } else {
          const index2 = this.stdSpecialReqList.findIndex((settingField: SettingField) => {
            if (settingField?.custom) {
              if (settingField?.value?.toLowerCase() === input.toLowerCase()) {
                return true;
              }
            } else {
              if (this.getValue(settingFieldType, settingField).toLowerCase() === input.toLowerCase()) {
                return true;
              }
            }
            return false;
          });
          if (index2 !== -1) {
            return true;
          }
        }
      }
    }
    return false;
  }

  async newSettingField(settingFieldType: SettingFieldType) {
    let field = this.getSettingFieldTitle(settingFieldType);
    if (field) {
      const input = await this.popupService.presentInput(this.translate.instant('CRUD.new_field', { field }));
      input.onWillDismiss().then((result: any) => {
        const value = result?.data?.input;
        if (value) {
          if (this.checkDuplicateSettingField(settingFieldType, value)) {
            this.popupService.presentAlert(this.translate.instant('VALIDATION.duplicate_field', { field }));
          } else {
            const settingField: SettingField = {
              custom: true,
              value,
            };
            if (settingFieldType === 'invited_by') {
              this.stdInvitedByList.push(settingField);
              this.invitedByList.push(settingField);
            } else if (settingFieldType === 'category') {
              this.stdCategoryList.push(settingField);
              this.categoryList.push(settingField);
            } else if (settingFieldType === 'dietary_req') {
              this.stdDietaryReqList.push(settingField);
              this.dietaryReqList.push(settingField);
            } else if (settingFieldType === 'special_req') {
              this.stdSpecialReqList.push(settingField);
              this.specialReqList.push(settingField);
            }
          }
        }
      });
    }
  }

  /**
   * Check if show back button
   * @returns true if can show back button
   */
  showBackBtn(): boolean {
    if (this.type === this.step) {
      return false;
    } else {
      if (this.step === 'couple' && this.premium) {
        if (!this.type) {
          return true;
        }
        return false;
      }
      return true;
    }
  }

  /**
   * Check and prompt for discard account setup if input detected
   */
  async discard() {
    if (this.role || this.coupleId || this.coupleForm?.value?.title || this.accountForm?.value?.time || (this.eventMode && this.coupleForm?.value?.organizer) || this.eventMode) {
      const actionSheet = await this.actionSheetController.create({
        header: this.translate.instant('MSG.discard_msg'),
        buttons: [{
          text: this.translate.instant('BTN.confirm'),
          role: 'destructive',
          icon: 'trash',
          handler: () => {
            this.goBack();
          }
        }, {
          text: this.translate.instant('BTN.cancel'),
          icon: 'close',
          role: 'cancel',
          handler: () => {
          }
        }]
      });
      actionSheet.present();
    } else if (!this.premium) {
      this.goBack();
    } else {
      this.goBack();
    }
  }

  /**
   * Skip setup prompt
   */
  async skipSetup() {
    const modal = await this.popupService.presentConfirm(this.translate.instant('ACCOUNTS.msg.setup_later'));
    modal.onDidDismiss().then((result: any) => {
      if (result?.data?.confirm) {
        if (this.creditId) {
          this.goUserCreditPage();
        } else {
          this.goAccountsListPage();
        }
        if (!this.pageMode) {
          this.dismissModal();
        }
      }
    });
  }

  /**
   * Title form submit
   */
  coupleFormSubmit() {
    if (this.eventMode) {
      this.coupleForm.controls.couple1.setValidators([]);
      this.coupleForm.controls.couple2.setValidators([]);
      this.coupleForm.controls.organizer.setValidators([ Validators.required ]);
    } else {
      this.coupleForm.controls.couple1.setValidators([ Validators.required ]);
      this.coupleForm.controls.couple2.setValidators([ Validators.required ]);
      this.coupleForm.controls.organizer.setValidators([ ]);
    }
    this.coupleForm.controls.couple1.updateValueAndValidity();
    this.coupleForm.controls.couple2.updateValueAndValidity();
    this.coupleForm.controls.organizer.updateValueAndValidity();

    this.coupleForm.markAllAsTouched();
    if (this.coupleForm?.valid) {
      this.coupleList = [];
      if (!this.eventMode) {
        this.accountCoupleList?.forEach((accountCouple: AccountCouple) => {
          const coupleName: CoupleList = {
            name: this.coupleForm.value['couple' + accountCouple.coupleId],
            avatar: this.coupleForm.value['couple' + accountCouple.coupleId + 'Avatar'],
            accountCouple,
          };
          this.coupleList.push(coupleName);
        });
      }

      this.setupAccountTitle();
      if (this.eventMode) {
        this.coupleId = 0;
      } else if (this.role?.type && !this.role?.coupleId) {
        this.coupleId = 0;
      }
      this.roleList = this.accountRoleService.searchRoleList('', this.eventMode).filter((x: Role) => {
        return x?.role?.type && x.role.type !== 'others' && x.role.type !== 'viewer' && x.role.type !== 'admin' && x.role.type !== 'usher'  && !x.role.custom;
      });
      this.step = 'role';
      this.setupProgress();
    }
  }

  /**
   * Account form submit
   */
  // accountFormSubmit() {
  //   this.accountForm.markAllAsTouched();
  //   if (this.accountForm.valid && (!this.timezoneList?.length || !this.functionService.isEmpty(this.timezone))) {
  //     this.setupWeddingAccountCall(this.param);
  //   }
  // }

  venueFormSubmit() {
    this.venueForm.markAllAsTouched();
    if (this.venueForm.valid) {
      // this.pageLocation.name = this.venueForm.value.venue;
      // this.pageLocation.address = this.venueForm.value.address;
      // this.pageLocation.hall = this.venueForm.value.hall;

      this.setupInvitedBy();
      this.setupCategory();
      this.step = 'category';
    }
  }

  back() {
    if (this.step === 'couple') {
      if (this.premium && !this.type) {
        this.skipSetup();
      } else {
        this.discard();
      }
    } else if (this.step === 'role') {
      this.step = 'couple';
    } else if (this.step === 'photo') {
      this.roleList = this.accountRoleService.searchRoleList('', this.eventMode).filter((x: Role) => {
        return x?.role?.type && x.role.type !== 'others';
      });
      this.step = 'role';
    } else if (this.step === 'account') {
      this.roleList = this.accountRoleService.searchRoleList('', this.eventMode).filter((x: Role) => {
        return x?.role?.type && x.role.type !== 'others';
      });
      this.step = this.newWebsiteMode ? 'photo' : 'role';
    } else if (this.step === 'venue') {
      this.step = 'account';
    } else if (this.step === 'category') {
      this.step = 'venue';
    } else if (this.step === 'dietary') {
      this.step = 'category';
    } else if (this.step === 'seating') {
      this.step = 'dietary';
    } else if (this.step === 'completed') {
      this.step = 'seating';
    }
    if (this.step === 'photo') {
      this.setupLimit();
    }
    this.setupProgress();
  }

  async submit() {
    if (!this.checkDisabledSubmit()) {
      if (this.step === 'couple') {
        this.coupleFormSubmit();
      } else if (this.step === 'role') {
        this.step = this.newWebsiteMode ? 'photo' : 'account';
      } else if (this.step === 'photo') {
        this.step = 'account';
      } else if (this.step === 'account') {
        this.step = 'venue';
      } else if (this.step === 'venue') {
        this.venueFormSubmit();
      } else if (this.step === 'category') {
        this.setupDietaryReq();
        this.setupSpecialReq();
        this.step = 'dietary';
      } else if (this.step === 'dietary') {
        this.setupSeatingSetting();
        this.step = 'seating';
      } else if (this.step === 'seating') {
        this.setupSeating();
      } else if (this.step === 'completed') {
        this.setupWeddingAccountCall(this.param);
      }
      this.setupProgress();
      if (this.step === 'photo') {
        this.setupLimit();
      }
    }
  }

  async setupSeating() {
    await this.popupService.presentLoading();
    if (this.guestPerSeating !== this.seatingSettingService.getGuestPerSeating()) {
      if (this.seatingList?.length) {
        this.seatingList = this.seatingList.map((seating: Seating) => {
          if (seating.count > 1) {
            seating.maxGuest = this.guestPerSeating * seating.count;
          } else {
            seating.maxGuest = this.guestPerSeating;
          }
          return seating;
        });
      } else if (this.seatingService.seatingList?.length) {
        this.seatingList =  this.functionService.cloneDeep(this.seatingService.seatingList).map((seating: Seating) => {
          if (seating.count > 1) {
            seating.maxGuest = this.guestPerSeating * seating.count;
          } else {
            seating.maxGuest = this.guestPerSeating;
          }
          return seating;
        });
      }
    }
    this.param = await this.generateParam();
    this.step = 'completed';
    this.setupProgress();
    this.popupService.dismissLoading();
  }

  // checkShowSkip(): boolean {
  //   if (this.step === 'photo') {
  //     return true;
  //   }
  //   return false;
  // }

  checkDisabledSubmit() {
    if (this.step === 'couple') {
      if (!this.coupleForm?.value?.title) {
        return true;
      }
      if (!this.eventMode) {
        if (!this.coupleForm?.value?.couple1 || !this.coupleForm?.value?.couple2) {
          return true;
        }
      } else {
        if (!this.coupleForm?.value?.organizer) {
          return true;
        }
      }
    } else if (this.step === 'role') {
      if (!this.coupleId && !this.role?.type) {
        return true;
      }
    } else if (this.step === 'photo') {
      if (this.newWebsiteMode && !this.selectedCover?.url) {
        return true;
      }
    } else if (this.step === 'account') {
      if (!this.accountForm?.value?.country || !this.timezone?.label || !this.accountForm?.value?.time) {
        return true;
      }
    } else if (this.step === 'venue') {

    } else if (this.step === 'category') {
      if (!this.invitedByList?.length || !this.categoryList?.length) {
        return true;
      }
    } else if (this.step === 'dietary') {
      if (!this.dietaryReqList?.length || !this.specialReqList?.length) {
        return true;
      }
    } else if (this.step === 'seating') {
      if (!this.seatingType?.value || (this.seatingType?.value === 'others' && !this.seatingTypeOther) || !this.guestPerSeating) {
        return true;
      }
    }
    return false;
  }

  /**
   * Generate account info data
   * @returns account info
   */
   generateAccountInfo(): AccountInfo {
    const updateBy: UpdateBy = this.updateByService.getUpdateBy();
    let accountInfo: AccountInfo | any = !this.newAccountMode ? this.accountInfoService.accountInfo : null;

    try {
      if (accountInfo) {
        accountInfo.enable = true;
        accountInfo.location = this.pageLocation;
        accountInfo.timezone = this.timezone;
        accountInfo.eventMode = this.eventMode;
        accountInfo.time = this.generateTimeStamp(true);
        accountInfo.endTime = this.generateEndTimeStamp(true);
        if (this.dateFormat && this.dateFormat !== accountInfo?.dateFormat) {
          accountInfo.dateFormat = this.dateFormat;
        }
        if (this.timeFormat && this.timeFormat !== accountInfo?.timeFormat) {
          accountInfo.timeFormat = this.timeFormat;
        }
        if (this.locale && this.locale !== accountInfo?.locale) {
          accountInfo.locale = this.locale;
        }
        accountInfo.updateBy = updateBy;
  
        if (!accountInfo.accountStatus) {
          accountInfo.accountStatus = {};
        }
        if (this.premium) {
          accountInfo.accountStatus = {
            premium: this.premium
          };
        } else {
          accountInfo.accountStatus = {
            trial: !this.premium
          };
        }
  
        if (this.accountTitle?.value && this.accountTitle.value !== accountInfo?.title?.value) {
          accountInfo.title = this.accountTitle;
        }
        
        if (this.eventMode && this.coupleForm?.value?.organizer) {
          accountInfo.organizer = this.coupleForm.value.organizer;
          accountInfo.organizerAvatar = this.coupleForm.value.organizerAvatar;
        }
  
        if (!accountInfo?.language) {
          accountInfo.language = this.languageService.getLanguageCode(this.languageService.getUserLanguage());
        }
  
        if (!this.type || !accountInfo?.createBy) {
          accountInfo.createBy = accountInfo.updateBy;
        }
  
        if (this.coupleList?.length && !this.functionService.isEqual(accountInfo.coupleList, this.coupleList)) {
          accountInfo.coupleList = this.coupleList;
        }
  
        if (!accountInfo.location) {
          accountInfo.location = {};
        }
        if (!accountInfo?.location?.locality) {
          if (!accountInfo?.location) {
            accountInfo.location = {};
          }
          accountInfo.location.locality = {
            country: ''
          };
        }
        if (!accountInfo?.location?.locality?.country && this.accountForm.value?.country?.code) {
          accountInfo.location.locality.country = this.accountForm.value.country.code;
        }
      } else {
        accountInfo = {
          accountId: '',
          weddingId: '',
          enable: true,
          location: this.pageLocation,
          timezone: this.timezone,
          title: this.accountTitle,
          coupleList: this.coupleList?.length ? this.coupleList : [],
          time: this.generateTimeStamp(true),
          endTime: this.generateEndTimeStamp(true),
          dateFormat: this.dateFormat,
          timeFormat: this.timeFormat,
          locale: this.locale,
          accountStatus: {},
          eventMode: this.eventMode,
          language: this.languageService.getLanguageCode(this.languageService.getUserLanguage()),
          updateBy,
          createBy: updateBy,
        };
        if (!accountInfo?.location?.locality) {
          if (!accountInfo?.location) {
            accountInfo.location = {};
          }
          accountInfo.location.locality = {
            country: ''
          };
        }
        if (this.accountForm.value?.country?.code) {
          accountInfo.location.locality.country = this.accountForm.value.country.code;
        }
        if (this.premium) {
          accountInfo.accountStatus = {
            premium: this.premium
          };
        } else {
          accountInfo.accountStatus = {
            trial: !this.premium
          };
        }
        if (this.eventMode && this.coupleForm?.value?.organizer) {
          accountInfo.organizer = this.coupleForm.value.organizer;
          accountInfo.organizerAvatar = this.coupleForm.value.organizerAvatar;
        }
      }
    } catch (err) {
      console.log(err);
    }
    
    return accountInfo;
  }

  /**
   * Generate user role data
   * @returns User role
   */
  generateRole(): UserRole {
    let role: UserRole = {
      coupleId: 0,
      type: '',
      custom: false
    };
    if (!this.eventMode && this.coupleId !== -1 && this.accountCoupleList?.length) {
      const coupleIndex = this.accountCoupleList?.findIndex((x: AccountCouple) => x?.coupleId === this.coupleId);
      if (coupleIndex !== -1) {
        role.coupleId = this.coupleId;
        role.type = this.accountCoupleList[coupleIndex].coupleType;
      }
    }
    if (!role.type && this.role?.type) {
      role = this.role;
    }
    return role;
  }

  setGuestPerSeating(guestPerSeating: number) {
    this.guestPerSeating = guestPerSeating;
  }

  setSeatingType(seatingType: SettingField) {
    this.seatingType = seatingType;
  }

  setSeatingTypeOther(seatingTypeOther: string) {
    this.seatingTypeOther = seatingTypeOther;
  }

  getSeatingTypeName() {
    return this.seatingSettingService.getSeatingTypeName('', this.seatingType, this.seatingTypeOther);
  }

  getProgress(): number {
    if (this.step === 'couple') {
      return 0;
    } else if (this.step === 'role') {
      return 0.125;
    } else if (this.step === 'photo') {
      return 0.25;
    } else if (this.step === 'account') {
      return 0.375;
    } else if (this.step === 'venue') {
      return 0.5;
    } else if (this.step === 'category') {
      return 0.625;
    } else if (this.step === 'dietary') {
      return 0.75;
    } else if (this.step === 'seating') {
      return 0.875;
    } else if (this.step === 'completed') {
      return 1;
    }
    return 0;
  }

  setupAnalytics(accountId: string) {
    if (this.premium) {
      if (this.migrate) {
        this.analyticsService.migrateAccountSetup(accountId);
      } else if (this.gift) {
        this.analyticsService.giftAccountSetup(accountId);
      } else {
        this.analyticsService.premiumAccountSetup(accountId);
      }
    } else {
      this.analyticsService.trialAccountSetup(accountId);
    }
  }

  async generateParam() {
    const uid = this.userService.uid;
    const param: any = {
      accountInfo: this.generateAccountInfo(),
      uid,
      creditId: this.premium && this.creditId ? this.creditId : '',
      eventMode: this.eventMode,
      currency: 'TWD',
    };

    if (!this.type || !this.functionService.isEqual(this.invitedByList, this.invitedByService.getInvitedByList(this.eventMode))) {
      param.invitedByList = this.settingFieldService.sortList('invited_by', this.invitedByList);
    }
    if (!this.type || !this.functionService.isEqual(this.categoryList, this.categoryService.getCategoryList(this.eventMode))) {
      param.categoryList = this.settingFieldService.sortList('category', this.categoryList);
    }

    if (!this.type || !this.functionService.isEqual(this.dietaryReqList, this.dietaryReqService.getDietaryReqList())) {
      param.dietaryReqList = this.settingFieldService.sortList('dietary_req', this.dietaryReqList);
    }

    if (!this.type || !this.functionService.isEqual(this.specialReqList, this.specialReqService.getSpecialReqList(this.eventMode))) {
      param.specialReqList = this.settingFieldService.sortList('special_req', this.specialReqList);
    }

    if (!this.type || !this.functionService.isEqual(this.seatingList, this.seatingService.seatingList)) {
      param.seatingList = this.seatingList;
    }

    if (!this.functionService.isEqual(this.seatingType, this.seatingSettingService.getSeatingType())) {
      param.seatingType = this.seatingType;
    }

    if (this.seatingType?.value === 'others' && this.seatingTypeOther !== this.seatingSettingService.seatingTypeOther) {
      param.seatingTypeOther = this.seatingTypeOther;
    }

    if (this.guestPerSeating && this.guestPerSeating !== this.seatingSettingService.getGuestPerSeating(this.accountForm.value?.country?.code)) {
      param.guestPerSeating = this.guestPerSeating;
    }
    
    const roleList = this.accountRoleService.searchRoleList('', this.eventMode).filter((x: Role) => {
      return x?.role?.type;
    });
    if (roleList?.length) {
      param.roleList = roleList;
      if (this.role?.custom) {
        const index = param.roleList.findIndex((x: Role) => {
          return x.role.custom && x.role.type === this.role.type;
        });
        if (index === -1) {
          const role: Role = {
            role: this.role,
            privilege: StdActionList,
          };
          param.roleList.push(role);
        }
      }
    }

    if (this.type !== 'account') {
      param.role = this.generateRole();
    }

    if (!this.eventMode) {
      param.accountCoupleList = this.accountCoupleList;
    }

    if (!param.accountInfo?.accountId) {
      param.accountInfo.accountId = this.accountsListService.getNewAccountId();
      param.newAccount = true;
    }

    if (param?.coupleList?.length) {
      for (const x of param.coupleList) {
        if (x?.avatar?.url) {
          if (x?.avatar?.type === 'base64') {
            if (x.accountCouple.coupleId === 1) {
              const url = await this.photoService.uploadWebsiteHostImg('couple1_avatar', x.avatar.url, param.accountInfo.accountId);
              x.avatar = {
                url,
                type: 'firebase',
              }
            } else if (x.accountCouple.coupleId === 2) {
              const url = await this.photoService.uploadWebsiteHostImg('couple2_avatar', x.avatar.url, param.accountInfo.accountId);
              x.avatar = {
                url,
                type: 'firebase',
              }
            }
          }
        }
      }
    }
    if (this.eventMode) {
      param.accountInfo.organizer = this.coupleForm.value.organizer;
      if (this.coupleForm?.value?.organizerAvatar?.url) {
        if (this.coupleForm.value.organizerAvatar.type === 'base64') {
          const url = await this.photoService.uploadWebsiteHostImg('host_avatar', this.coupleForm.value.organizerAvatar.url, param.accountInfo.accountId);
          param.accountInfo.organizerAvatar = {
            url,
            type: 'firebase',
          };
        }
      }
    } 

    // const galleryList = this.photoService?.accountGalleryList;
    // if (galleryList?.length) {
    //   const url = await this.photoService.uploadAccountGallery('account_gallery', galleryList[0], false);
    //   param.accountInfo.gallery = [ url ];
    // }

    if (this.selectedCover?.url) {
      if (this.selectedCover?.type === 'base64') {
        const url = await this.photoService.uploadWebsiteCover(this.selectedCover.url, param.accountInfo.accountId);
        param.accountInfo.coverPhoto = {
          url,
          type: 'firebase',
        };
      } else {
        param.accountInfo.coverPhoto = this.selectedCover;
      }
    }

    if (this.newWebsiteMode) {
      param.websiteDetail = this.functionService.cloneDeep(this.websiteDetailService.getWebsiteDetail());
      param.websiteDetail.info.title = this.websiteInfoService.getTitle(false, param.accountInfo.title.value);
      if (param.accountInfo.coverPhoto?.url) {
        param.websiteDetail.theme.cover.media = param.accountInfo.coverPhoto;
      }
      if (!this.eventMode) {
        if (param.accountInfo?.coupleList?.length) {
          param.accountInfo.coupleList.forEach((couple: CoupleList) => {
            if (couple?.accountCouple?.coupleId === 1) {
              param.websiteDetail.hostMsg.couple1.name = couple.name;
              param.websiteDetail.hostMsg.couple1.avatar = couple.avatar;
            } else if (couple?.accountCouple?.coupleId === 2) {
              param.websiteDetail.hostMsg.couple2.name = couple.name;
              param.websiteDetail.hostMsg.couple2.avatar = couple.avatar;
            }
          })
        }
      } else {
        param.websiteDetail.hostMsg.organizer.name = param.accountInfo?.organizer;
        param.websiteDetail.hostMsg.organizer.avatar = param.accountInfo.organizerAvatar;
      }

      if (this.venueForm.value) {
        if (this.venueForm.value.venue) {
          param.websiteDetail.venue.name = this.websiteVenueService.getVenueName(this.venueForm.value.venue);
        }
        if (this.venueForm.value.address) {
          param.websiteDetail.venue.address = this.websiteVenueService.getVenueAddress(this.venueForm.value.address);
        }
        if (this.venueForm.value.hall) {
          param.websiteDetail.venue.hall = this.websiteVenueService.getVenueHall(this.venueForm.value.hall);
        }
      }

      if (param?.newAccount) {
        param.websiteLink = this.websiteLinkService.generateLink(1, false, true, false, param.accountInfo.accountId);
      }
    }
    return param;
  }

  /**
   * Setup account fns call
   */
  async setupWeddingAccountCall(param: any) {
    if (this.onlineService.isOnline()) {
      await this.popupService.presentLoading(this.translate.instant('LOADING.setup'));
      
 
      try {
        const currency = this.currencyService.getUserCurrency()?.code;


        if (!this.userService?.user?.locality?.country && param?.accountInfo?.location?.locality?.country) {
          this.userManageService.updateUser({ locality: { country: param.accountInfo.location.locality.country }});
        }

        await this.fns.httpsCallable('setupWeddingAccountCall')(param).toPromise().then(async (accountId) => {
          if (accountId) {
            this.photoService.accountGalleryList = [];
            this.setupAnalytics(accountId);
            this.analyticsService.setAccountType(this.eventMode);
            
            await this.goAccountLoginPage(accountId);
            await this.functionService.delay(300);
            await this.popupService.dismissLoading();
            if (!this.pageMode) {
              await this.dismissModal(true);
            }
            
          } else {
            if (this.coupleList?.length) {
              for (const x of this.coupleList) {
                if (x?.avatar?.url && x?.avatar?.type === 'firebase') {
                  if (x?.accountCouple?.coupleId === 1) {
                    await this.photoService.deleteWebsiteHostImg('couple1_avatar', param.accountInfo.accountId);
                  } else if (x?.accountCouple?.coupleId === 2) {
                    await this.photoService.deleteWebsiteHostImg('couple2_avatar', param.accountInfo.accountId);
                  }
                }
              }
            }
            if (this.selectedCover?.url && this.selectedCover?.type === 'firebase') {
              await this.photoService.deleteWebsiteCover(param.accountInfo.accountId);
            }
            await this.popupService.dismissLoading();
            this.popupService.presentActionError();
          }
        }).catch(async (err: any) => {
          await this.popupService.dismissLoading();
          this.popupService.presentActionError();
          this.errorService.logError(err);
        });
      } catch(err) {
        await this.popupService.dismissLoading();
        this.popupService.presentActionError();
        this.errorService.logError(err);
      }
      
    }
  }

  /**
   * Go back to account add page.
   */
  goBack() {
    if (this.pageMode) {
      this.router.navigate(['/tabs-accounts/list']);
    } else {
      this.dismissModal();
    }
  }

  /**
   * Go user credit page
   */
  goUserCreditPage() {
    this.router.navigate(['/user/credit']);
  }

  /**
   * Go account list page
   */
  goAccountsListPage() {
    this.router.navigate(['/tabs-accounts/list']);
  }

  /**
   * Go account login page
   * @param accountId Account ID
   */
  async goAccountLoginPage(accountId: string) {
    if (accountId) {
      const navigationExtras: NavigationExtras = {
        state: { accountId, new: true }
      };
      await this.router.navigate(['/accounts/login'], navigationExtras);
    }
  }

  goMainPage() {
    if (!this.pageMode) {
      this.dismissModal();
    }
    this.linkService.goMainPage();
  }

  replaceEventType(text: string) {
    return this.accountEventModeService.replaceEventType(text, this.eventMode);
  }

  /**
   * Check if uber is available in account country
   * @returns True if uber is available
   */
  checkUberCountry(): boolean {
    return this.visitorSettingService.checkUberCountry(this.pageLocation?.locality?.country);
  }

  checkGrabCountry(): boolean {
    return this.visitorSettingService.checkGrabCountry(this.pageLocation?.locality?.country);
  }

  /**
   * Check if google map is available in account country
   * @returns True if google map is available
   */
  checkGoogleMapCountry(): boolean {
    return this.visitorSettingService.checkGoogleMapCountry(this.pageLocation?.locality?.country);
  }

  /**
   * Check if waze is available in account country
   * @returns True if waze is available
   */
  checkWazeCountry(): boolean {
    return this.visitorSettingService.checkWazeCountry(this.pageLocation?.locality?.country);
  }

  testGps() {
    const options = [];
    if (this.checkGoogleMapCountry()) {
      options.push({
        value: 'google',
        text: this.translate.instant('VISITOR.setting.lbl.google'),
      });
    }
    if (this.checkWazeCountry()) {
      options.push({
        value: 'waze',
        text: this.translate.instant('VISITOR.setting.lbl.waze'),
      });
    }
    if (this.checkUberCountry()) {
      options.push({
        value: 'uber',
        text: this.translate.instant('VISITOR.setting.lbl.uber'),
      });
    }
    if (this.checkGrabCountry()) {
      options.push({
        value: 'grab',
        text: this.translate.instant('VISITOR.setting.lbl.grab'),
      });
    }

    options.push({
      value: 'appleMap',
      text: this.translate.instant('VISITOR.setting.lbl.apple'),
    });

    if (options?.length) {
      this.presentNavigationPicker(options);
    }
    //  else if (this.form.value.googleMap) {
    //   this.gpsService.openGoogleNav(this.locationDetail);
    // } else if (this.form.value.waze) {
    //   this.gpsService.openWazeNav(this.locationDetail);
    // } else if (this.form.value.uber) {
    //   this.gpsService.openUberNav(this.locationDetail);
    // } else if (this.form.value.grab) {
    //   this.gpsService.openGrab(this.locationDetail);
    // } else if (this.form.value.appleMap) {
    //   this.gpsService.openAppleNav(this.locationDetail);
    // } else {
    //   this.gpsService.openGoogleNav(this.locationDetail);
    // }
  }

  async presentNavigationPicker(options: any[], selectedIndex?: number) {
    const picker = await this.pickerController.create({
      columns: [{
        name: 'gps',
        selectedIndex,
        options
      }],
      buttons: [{
        text: this.translate.instant('BTN.cancel'),
        role: 'cancel'
      }, {
        text: this.translate.instant('BTN.confirm'),
        handler: (result: any) => {
          if (result?.gps?.value) {
            if (result.gps.value === 'google') {
              this.gpsService.openGoogleNav(this.pageLocation);
            } else if (result.gps.value === 'waze') {
              this.gpsService.openWazeNav(this.pageLocation);
            } else if (result.gps.value === 'uber') {
              this.gpsService.openUberNav(this.pageLocation);
            } else if (result.gps.value === 'grab') {
              this.gpsService.openGrab(this.pageLocation);
            } else if (result.gps.value === 'appleMap') {
              this.gpsService.openAppleNav(this.pageLocation);
            } else {
              this.gpsService.openGoogleNav(this.pageLocation);
            }
          }
        }
      }]
    });
    picker.present();
  }

  async setupCoverWidthHeight(contentWidth?: number) {
    if (!contentWidth) {
      await this.functionService.delay(200);
      contentWidth = this.getContainerWidth();
    }
    
    if (contentWidth) {
      this.coverWidth = this.websiteThemeService.calculatePreviewWidth(contentWidth);
      this.coverHeight = (this.coverWidth * 16) / 9;
    } else {
      setTimeout(() => {
        this.setupCoverWidthHeight(contentWidth);
      }, 200);
    }
  }

  getContainerWidth() {
    return this.gridContent?.nativeElement?.offsetWidth;
  }
  getContainerHeight() {
    return this.gridContent?.nativeElement?.offsetHeight;
  }

  selectCover(cover?: MediaType) {
    if (cover?.url && cover?.type === 'local') {
      this.selectedCover = cover;
      this.photoService.setupWebsiteCover({ ...cover });
    } else {
      this.photoService.selectPhoto('website_cover', false, false);
    }
  }

  setupLimit() {
    const width = this.getContainerWidth();
    const height = this.getContainerHeight();

    if (width && height) {
      this.limit = this.getLimit();
    } else {
      setTimeout(() => {
        this.setupLimit();
      }, 300);
    }
  }

  getLimit() {
    const col = Math.floor(this.getContainerWidth() / (this.coverWidth + 5));
    const row = Math.ceil(this.getContainerHeight() / (this.coverHeight + 5));

    const limit = Math.floor(col * (row));
    // console.log(limit);
    return limit ? limit - 1 : 3;
  }

  async loadLimit(event: any) {
    await this.functionService.delay(50);
    if (this.limit >= this.coverList.length) {
      // event.target.disabled = true;
    } else {
      this.limit += this.getLimit();
    }
    event.target.complete();
  }

  resizeLimit() {
    const limit = this.getLimit();
    if (limit > this.limit) {
      this.limit = limit;
    }
  }

}
