import { TranslateService } from '@ngx-translate/core';
import { FunctionService } from 'src/app/services/general/function.service';
import { LocalityService } from 'src/app/services/location/locality.service';
import { PopupService } from 'src/app/services/general/popup.service';
import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { Platform, ModalController } from '@ionic/angular';
import { ContactPayload, EmailPayload, PhonePayload } from '@capacitor-community/contacts';
import { ReadContactsService } from 'src/app/services/general/read-contacts.service';
import { PhoneNumberFormat, PhoneNumberType, PhoneNumberUtil } from 'google-libphonenumber';
import { Mobile } from 'src/app/interfaces/general';
import { Country } from 'src/app/interfaces/database';
import { ImportContactStep } from 'src/app/types/general';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { MobileService } from 'src/app/services/general/mobile.service';

const phoneUtil = PhoneNumberUtil.getInstance();

@Component({
  selector: 'app-read-contacts',
  templateUrl: './read-contacts.component.html',
  styleUrls: ['./read-contacts.component.scss'],
})
export class ReadContactsComponent implements OnInit, OnDestroy {

  @ViewChild(CdkVirtualScrollViewport) cdkVirtualScrollViewport: CdkVirtualScrollViewport;
  
  @ViewChild('content', { read: ElementRef }) contentElement: ElementRef;
  
  editMode: boolean;
  contactList: ContactPayload[];
  selectedContact: ContactPayload;

  result: {
    name: string,
    mobile?: Mobile,
    email: string
  };
  step: ImportContactStep;

  private searchTerm: string;

  constructor(
    private elementRef: ElementRef,
    private platform: Platform,
    private translate: TranslateService,
    private modalController: ModalController,
    private readContactsService: ReadContactsService,
    private mobileService: MobileService,
    private localityService: LocalityService,
    private functionService: FunctionService,
    private popupService: PopupService,
  ) { }

  ngOnInit() {}

  ngOnDestroy() {
    
  }

  async ionViewWillEnter() {
    await this.platform.ready();
    if (this.platform.is('hybrid')) {
      await this.popupService.presentLoading();
      await this.getContacts();
      this.reset();
      this.loadList();
      this.step = 'contact';
      this.popupService.dismissLoading();
    } else {
      this.dismissModal();
    }
  }

  search(data?: any) {
    this.searchTerm = data?.keyword;
    this.loadList();
  }

  async getContacts() {
    await this.readContactsService.generateContactList();
    this.loadList();
  }

  loadList() {
    this.contactList = this.readContactsService.searchList(this.searchTerm);
    this.setupViewport();
  }

  setupViewport() {
    if (this.cdkVirtualScrollViewport) {
      setTimeout(() => {
        this.cdkVirtualScrollViewport?.checkViewportSize();
      }, 200);
    } else {
      setTimeout(() => {
        this.setupViewport();
      }, 200);
    }
  }

  reset(type?: string) {
    if (type === 'mobile') {
      this.result.mobile = {
        code: 0,
        country: '',
        no: '',
      };
    } else if (type === 'email') {
      this.result.email = '';
    } else {
      this.result = {
        name: '',
        mobile: {
          code: 0,
          country: '',
          no: '',
        },
        email: ''
      };
    }
  }

  back() {
    if (this.step === 'contact') {
      this.dismissModal();
    } else if (this.step === 'mobile') {
      this.reset('mobile');
      this.step = 'contact';
    } else if (this.step === 'email') {
      this.reset('email');
      this.step = 'mobile';
    }
  }

  /**
   * Dismiss modal
   */
  async dismissModal(result?: any) {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { await modal.dismiss(result); }
    }
  }

  getContentHeight(): number {
    return this.contentElement?.nativeElement?.offsetHeight ? this.contentElement.nativeElement.offsetHeight : window?.innerHeight ? window.innerHeight : 0;
  }

  getContactPhoneNumber(phoneNumberList: PhonePayload[]) {
    let result = '';
    phoneNumberList.forEach((value: PhonePayload) => {
      if (value?.number) {
        if (result) {
          result = result + ', ' + value.number;
        } else {
          result = value.number;
        }
      }
    });
    return result;
  }

  getContactEmail(emailList: EmailPayload[]) {
    let result = '';
    emailList.forEach((value: EmailPayload) => {
      if (value?.address) {
        if (result) {
          result = result + ', ' + value.address;
        } else {
          result = value.address;
        }
      }
    });
    return result;
  }

  selectContact(contact: ContactPayload) {
    this.reset();
    this.selectedContact = contact;
    if (this.selectedContact?.name?.display) {
      this.result.name = this.selectedContact.name.display;
    }
    this.checkPhoneNumber();
  }

  async selectPhoneNumber(phoneNumber?: PhonePayload) {
    if (phoneNumber?.number) {
      const mobile: Mobile = this.validatePhoneNumber({ ...phoneNumber });
      if (mobile?.no) {
        this.result.mobile = mobile;
        this.checkEmail();
      } else {
        const msg = this.translate.instant('VALIDATION.invalid_format', { field: this.translate.instant('MOBILE.lbl.no') });
        this.popupService.presentToast(msg, 'danger');
      }
    } else {
      const msg = this.translate.instant('VALIDATION.invalid_format', { field: this.translate.instant('MOBILE.lbl.no') });
      this.popupService.presentToast(msg, 'danger');
    }
  }

  selectEmail(email?: EmailPayload) {
    if (email?.address) {
      if (this.validateEmail(email)) {
        this.result.email = email.address;
        this.checkResult();
      } else {
        const msg = this.translate.instant('VALIDATION.invalid_format', { field: this.translate.instant('LBL.email') });
        this.popupService.presentToast(msg, 'danger');
      }
    } else {
      const msg = this.translate.instant('VALIDATION.invalid_format', { field: this.translate.instant('LBL.email') });
      this.popupService.presentToast(msg, 'danger');
    }
  }

  checkPhoneNumber() {
    if (!this.selectedContact?.phones?.length) {
      this.checkEmail();
    } else if (this.selectedContact?.phones?.length === 1) {
      const mobile: Mobile = this.validatePhoneNumber({ ...this.selectedContact?.phones?.[0] });
      if (mobile?.no) {
        this.result.mobile = mobile;
      } else {
        this.reset('mobile');
      }
      this.checkEmail();
    } else if (this.selectedContact?.phones?.length > 1) {
      this.step = 'mobile';
    }
  }

  checkEmail() {
    if (!this.selectedContact?.emails?.length) {
      this.checkResult();
    } else if (this.selectedContact?.emails?.length === 1) {
      if (this.validateEmail(this.selectedContact?.emails[0])) {
        this.result.email = this.selectedContact?.emails[0]?.address;
      } else {
        this.reset('email');
      }
      this.checkResult();
    } else if (this.selectedContact?.emails?.length > 1) {
      this.step = 'email';
    }
  }

  checkResult() {
    this.dismissModal(this.result);
  }

  validatePhoneNumber(phoneNumber: PhonePayload) {
    if (phoneNumber?.number) {
      let number = phoneNumber.number;
      if (number.charAt(0) !== '+') {
        const country: Country = this.localityService.getDeviceCountry();
        if (country?.phone) {
          number = '+' + country.phone + number;
        }
      }
      const no = phoneUtil.format(phoneUtil.parseAndKeepRawInput(number), PhoneNumberFormat.E164);
      if (no) {
        const numberRaw = phoneUtil.parseAndKeepRawInput(no);
        const numberType = phoneUtil.getNumberType(numberRaw);
        if (numberType === PhoneNumberType.MOBILE || numberType === PhoneNumberType.FIXED_LINE_OR_MOBILE) {
          const result: Mobile = {
            code: numberRaw?.getCountryCode(),
            country: this.mobileService.getCountryByPhoneCode(numberRaw?.getCountryCode()),
            no
          };
          return result;
        } else if (no && no.indexOf('+6010') === 0 && no.slice(3).length === 10) {
          const result: Mobile = {
            code: numberRaw?.getCountryCode(),
            country: 'MY',
            no
          };
          return result;
        }
      }
    }
    return null;
  }

  validateEmail(email: EmailPayload) {
    if (email?.address) {
      return this.functionService.validateEmail(email.address);
    } else {
      return true;
    }
  }

  skip(type: string) {
    if (type === 'mobile') {
      this.reset('mobile');
      this.checkEmail();
    } else if (type === 'email') {
      this.reset('email');
      this.checkResult();
    }
  }

  /**
   * Calculate item height for virtual scroll
   * @param item Item
   */
  itemHeightFn(item: ContactPayload) {
    const regExp = /[^\u0000-\u00ff]/;
    const margin = 20;
    let height = margin + 5;

    if (item?.name?.display) {
      height = height + 17;
      if (regExp.test(item.name.display)) {
        height = height + 3;
      }
    }
    if (item?.organization?.company) {
      height = height + 15;
      if (regExp.test(item.organization.company)) {
        height = height + 3;
      }
    }
    if (item?.organization?.jobTitle) {
      height = height + 13;
      if (regExp.test(item.organization.jobTitle)) {
        height = height + 3;
      }
    }
    if (item?.phones?.length) {
      height = height + 13;
    }
    if (item?.emails?.length) {
      height = height + 13;
    }
    if (item?.image?.base64String) {
      if (height <= 60) {
        height = 60;
      }
    }
    if (height < 50) {
      height = 50;
    }
    return height;
  }

  trackByFn(index: number, item: ContactPayload) {
    if (item?.contactId) {
      return item?.contactId;
    }
    return index;
  }

}
