
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActionSheetController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { AccountInfoService } from 'src/app/services/account/account-info.service';
import { AccountLoginService } from 'src/app/services/account/account-login.service';
import { AccountUserService } from 'src/app/services/account/account-user.service';
import { ActionService } from 'src/app/services/account/privilege/action.service';
import { ModuleService } from 'src/app/services/account/privilege/module.service';
import { PrivilegeService } from 'src/app/services/account/privilege/privilege.service';
import { FunctionService } from 'src/app/services/general/function.service';
import { PopupService } from 'src/app/services/general/popup.service';
import { OnlineService } from 'src/app/services/general/online.service';
import { UserService } from 'src/app/services/user/user.service';
import { AccountPrivilegeType } from 'src/app/types/account';

import { UserRole, AccountUser } from 'src/app/interfaces/account';
import { AccountLogin } from 'src/app/interfaces/login';
import { AccountModule, Privilege } from 'src/app/interfaces/privilege';

import { AccountRoleComponent } from 'src/app/components/account/account-role/account-role.component';
import { AccountShareComponent } from 'src/app/components/account/account-share/account-share.component';
import { ModuleType } from 'src/app/types/general';

@Component({
  selector: 'app-account-privilege',
  templateUrl: './account-privilege.component.html',
  styleUrls: ['./account-privilege.component.scss'],
})
export class AccountPrivilegeComponent implements OnInit, OnDestroy {

  private eventMode: boolean;
  /**
   * Account privilege type
   */
  type: AccountPrivilegeType;
  /**
   * Account User
   */
  accountUser: AccountUser;
  /**
   * Account login info
   */
  accountLogin: AccountLogin;
  /**
   * Edit role flag
   */
  editRole: boolean;
  /**
   * Edit privilege flag
   */
  editPrivilege: boolean;
  /**
   * Own privilege flag
   */
  ownPrivilege: boolean;
  /**
   * AccountModule List
   */
  moduleList: ModuleType[];
  /**
   * Action List
   */
  actionList: Privilege;
  /**
   * Default action list
   */
  defaultActionList: Privilege;
  /**
   * Expanded AccountModule
   */
  expandedModule: AccountModule = {};
  /**
   * Select AccountModule
   */
  selectedModule: AccountModule = {};
  /**
   * Selected Privilege
   */
  selectedPrivilege: Privilege;
  selectedPrivilegeList: {
    [module: string]: {
      [action: string]: boolean;
    };
  };
  /**
   * Selected Role
   */
  selectedRole: UserRole;
  /**
   * Select All
   */
  selectAll: boolean;
  /**
   * Expand All
   */
  expandAll: boolean;

  /**
   * Account login subscription
   */
  private accountLoginSubscription: Subscription;
  /**
   * Account User Subscription
   */
  private accountUserSubscription: Subscription;

  /**
   * Constructor
   * @param actionSheetController Action Sheet Controller
   * @param modalController Modal Controller
   * @param translate Translate
   * @param moduleService AccountModule Service
   * @param actionService Action Service
   * @param privilegeService Privilege Service
   * @param accountLoginService Account ogin Service
   * @param accountUserService Account User Service
   * @param onlineService Online Service
   * @param popupService Popup Service
   * @param functionService Function service
   */
  constructor(
    private actionSheetController: ActionSheetController,
    private modalController: ModalController,
    private translate: TranslateService,
    private moduleService: ModuleService,
    private actionService: ActionService,
    private privilegeService: PrivilegeService,
    private accountInfoService: AccountInfoService,
    private accountLoginService: AccountLoginService,
    private accountUserService: AccountUserService,
    private userService: UserService,
    private onlineService: OnlineService,
    private popupService: PopupService,
    private functionService: FunctionService,
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.unwatch();
  }

  /**
   * Before View Enter
   */
  ionViewWillEnter() {
    this.initialize();
  }

  /**
   * Before view leave
   */
  ionViewWillLeave() {
    this.unwatch();
  }

  /**
   * Initialize
   */
  initialize() {
    this.selectAll = false;
    this.loadModule();

    if (!this.type) {
      if (this.accountUser?.name) {
        this.type = 'user';
      } else if (this.accountLogin?.accountLoginId) {
        this.type = 'edit';
      } else {
        this.type = 'new';
      }
    }

    if (this.type === 'preview' && this.accountLogin?.privilege) {
      this.moduleList = [];
      Object?.keys(this.accountLogin.privilege)?.forEach((module: ModuleType) => {
        if (this.accountLogin.privilege[module]?.length) {
          this.moduleList.push(module);
        }
      });
      this.actionList = this.accountLogin.privilege;
    }

    this.setupSelectedRole();
    this.setupEditMode();
    this.watch();
  }

  /**
   * Setup watch
   */
  watch() {
    if (this.type === 'edit' && this.accountLogin?.accountLoginId) {
      this.watchAccountLogin();
    }
    this.watchAccountUser();
  }

  /**
   * Unwatch
   */
  unwatch() {
    this.unwatchAccountUser();
    this.unwatchAccountLogin();
  }

  /**
   * Watch account login
   */
  async watchAccountLogin() {
    if (!this.accountLoginSubscription) {
      this.accountLoginSubscription = this.accountLoginService.observableLoginList.subscribe((loginList: AccountLogin[]) => {
        if (this.accountLogin?.accountLoginId) {
          const accountLoginIndex = loginList?.findIndex((x: AccountLogin) => x?.accountLoginId === this.accountLogin?.accountLoginId);
          if (accountLoginIndex !== -1) {
            const accountLogin = loginList[accountLoginIndex];
            if (!this.functionService.isEqual(accountLogin, this.accountLogin)) {
              if (this.functionService.isEqual(this.selectedRole, this.accountLogin.role)) {
                this.selectedRole = accountLogin.role;
              }
              if (this.functionService.isEqual(this.selectedPrivilege, this.accountLogin.privilege)) {
                this.selectedPrivilege = { ...accountLogin.privilege };
                this.setupModule();
              }
              this.accountLogin = { ...accountLogin };
            }
          }
        }
      });
    }
  }

  /**
   * Unwatch account login
   */
  async unwatchAccountLogin() {
    if (this.accountLoginSubscription) {
      this.accountLoginSubscription.unsubscribe();
      this.accountLoginSubscription = null;
    }
  }

  /**
   * Watch Account User
   */
  async watchAccountUser() {
    if (!this.accountUserSubscription) {
      this.accountUserSubscription = this.accountUserService.observableAccountUser.subscribe((accountUser: AccountUser[]) => {
        if (this.accountUser?.uid) {
          const userIndex = accountUser?.findIndex((x: AccountUser) => x?.uid === this.accountUser?.uid);
          if (userIndex !== -1) {
            this.accountUser = accountUser[userIndex];
            if (this.accountUser.role) {
              this.selectedRole = this.accountUser.role;
            }
            if (this.accountUser?.privilege) {
              if (this.accountUser?.owner || this.accountUser?.role?.coupleId) {
                this.selectedPrivilege = { ...this.actionService.actionList };
              } else {
                this.selectedPrivilege = { ...this.accountUser.privilege };
              }
            }
            this.setupModule();
          }
          this.checkOwnUserPrivilege();
        }
        this.checkEditModePrivilege();
      });
    }
  }

  /**
   * Unwatch account user
   */
  async unwatchAccountUser() {
    if (this.accountUserSubscription) {
      this.accountUserSubscription.unsubscribe();
      this.accountUserSubscription = null;
    }
  }

  /**
   * Load Module
   */
  loadModule() {
    this.moduleList = this.moduleService.moduleList;
    this.actionList = this.actionService.actionList;
    this.defaultActionList = this.actionService.defaultActionList;
  }

  /**
   * Setup selected role
   */
  setupSelectedRole() {
    if (this.type === 'edit' || this.type === 'preview') {
      if (this.accountLogin?.role) {
        this.selectedRole = this.accountLogin.role;
      }
    } else if (this.type === 'user') {
      if (this.accountUser?.role) {
        this.selectedRole  = this.accountUser.role;
      }
    }
    this.setupPrivilege();
  }

  /**
   * Setup privilege
   */
  setupPrivilege() {
    if (this.type === 'edit' || this.type === 'preview') {
      if (this.accountLogin?.privilege) {
        this.selectedPrivilege = { ...this.accountLogin.privilege };
        this.setupModule();
      }
    } else if (this.type === 'user') {
      if (this.accountUser?.privilege) {
        this.selectedPrivilege = { ...this.accountUser.privilege };
        this.setupModule();
      }
    } else {
      this.setupRolePrivilege();
    }
  }

  setupPrivilegeList() {
    if (!this.selectedPrivilegeList) {
      this.selectedPrivilegeList = {};
    }
    const selectedPrivilegeList = {};
    if (this.selectedPrivilege) {
      this.moduleList.forEach((module: ModuleType) => {
        const selectedPrivilege: { [action: string]: boolean } = {};
        if (this.selectedPrivilege[module]) {
          this.selectedPrivilege[module].forEach((action: string) => {
            selectedPrivilege[action] = true;
          });
        }
        selectedPrivilegeList[module] = selectedPrivilege;
      });
      this.selectedPrivilegeList = selectedPrivilegeList;
    }
  }

  /**
   * Setup edit mode
   */
  setupEditMode() {
    this.editRole = true;
    this.editPrivilege  = true;
    if (this.type === 'preview') {
      this.editRole = false;
      this.editPrivilege = false;
    } else if (this.type === 'edit') {
      if (this.accountLogin?.isOwner || this.accountLogin?.role?.coupleId > 0) {
        this.editPrivilege = false;
      }
    } else if (this.type === 'user') {
      if (this.accountUser?.owner || this.accountUser?.role?.coupleId > 0) {
        this.editPrivilege = false;
      }
      if (this.accountUser?.role?.coupleId > 0) {
        this.editRole = false;
      }
    }

    if (this.selectedRole?.coupleId > 0) {
      this.editRole = false;
      this.editPrivilege = false;
    }
    if (this.editPrivilege && this.accountLogin?.isOwner) {
      this.editPrivilege = false;
    }

    this.checkEditModePrivilege();
  }

  /**
   * Setup role privilege
   */
  setupRolePrivilege() {
    if (this.selectedRole?.type && this.editPrivilege) {
      this.selectedPrivilege = { ...this.privilegeService.getRolePrivilege(this.selectedRole.type) };
      this.defaultActionList = { ...this.privilegeService.getRolePrivilege(this.selectedRole.type) };
      this.setupModule();
    }
  }

  /**
   * Setup Module
   */
  setupModule() {
    if (this.moduleList?.length) {
      const selectedModule: AccountModule = {};
      this.moduleList?.forEach((module: ModuleType) => {
        selectedModule[module] = false;
        if (!selectedModule[module] && this.selectedPrivilege[module]?.length) {
          selectedModule[module] = true;
        }
      });
      this.selectedModule = selectedModule;
      this.checkSelectAll();
      this.setupPrivilegeList();
    }
  }

  /**
   * Present Role Modal
   * @param selected Selected
   */
  async presentRoleModal(selected: UserRole) {
    if (this.editRole) {
      const modal = await this.modalController.create({
        component: AccountRoleComponent,
        componentProps: {
          selected,
          eventMode: this.eventMode,
        }
      });
      modal.present();
      modal.onWillDismiss().then(async (role: any) => {
        if (role?.data?.selected) {
          this.selectedRole = role.data.selected;
          if (this.selectedRole.coupleId) {
            this.editPrivilege = false;
          }
          this.setupRolePrivilege();
        }
      });
    }
  }

  /**
   * Present account share modal
   * @param accountLogin account login
   */
  async presentAccountShareModal(accountLogin: AccountLogin) {
    if (accountLogin) {
      const modal = await this.modalController.create({
        component: AccountShareComponent,
        cssClass: 'modal-full-screen-bk',
        componentProps: {
          accountLogin
        }
      });
      modal.present();
      modal.onDidDismiss().then((result: any) => {
        if (result?.data?.dismiss) {
          this.dismissModal();
        }
      });
    } else {
      this.popupService.presentActionError();
    }
  }

  /**
   * Dimiss Privilege Modal
   */
  async dismissModal() {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { modal.dismiss(); }
    }
  }

  /**
   * Discard
   */
  async discard() {
    if (this.type === 'new' && (this.selectedRole || this.selectedPrivilege)) {
      this.confirmDiscard();
    } else if (this.type === 'edit' &&
    (this.editRole && (!this.functionService.isEqual(this.selectedRole, this.accountLogin?.role)) ||
    (this.editPrivilege && !this.functionService.isEqual(this.selectedPrivilege, this.accountLogin?.privilege)))) {
      this.confirmDiscard();
    } else if (this.type === 'user' &&
    (this.editRole && !this.functionService.isEqual(this.selectedRole, this.accountUser?.role) ||
    (this.editPrivilege && !this.functionService.isEqual(this.selectedPrivilege, this.accountUser?.privilege)))) {
      this.confirmDiscard();
    } else {
      this.dismissModal();
    }
  }

  /**
   * Confirm discard changes action sheet
   */
  async confirmDiscard() {
    const actionSheet = await this.actionSheetController.create({
      header: this.translate.instant('MSG.discard_msg'),
      buttons: [{
        text: this.translate.instant('BTN.confirm'),
        role: 'destructive',
        icon: 'trash',
        handler: () => {
          this.dismissModal();
        }
      }, {
        text: this.translate.instant('BTN.cancel'),
        icon: 'close',
        role: 'cancel',
        handler: () => {
        }
      }]
    });
    actionSheet.present();
  }

  /**
   * Expand All AccountModule
   */
  expandAllModule() {
    this.expandAll = !this.expandAll;
    if (this.moduleList) {
      this.moduleList?.forEach((module: ModuleType) => {
        this.expandModule(module, this.expandAll);
      });
    }
  }

  /**
   * Select All AccountModule
   */
  selectAllModule() {
    if (this.editPrivilege) {
      const selectAll = !this.selectAll;
      if (this.moduleList) {
        this.moduleList?.forEach((module: ModuleType) => {
          this.selectModule(module, selectAll);
        });
      }
      this.selectAll = selectAll;
      this.setupPrivilegeList();
    } else {
      this.popupService.presentAlert(this.translate.instant('ACCOUNT_PRIVILEGE.msg.no_privilege'));
    }
  }

  /**
   * Toggle AccountModule
   * @param module AccountModule
   * @param expanded Expaneded
   */
  expandModule(module: string, expanded?: boolean) {
    if (expanded) {
      this.expandedModule[module] = expanded;
    } else {
      this.expandedModule[module] = !this.expandedModule[module];
    }
    this.setupPrivilegeList();
    this.checkExpandAll();
  }

  /**
   * Select AccountModule
   * @param module AccountModule
   * @param selected Selected
   */
  selectModule(module: string, selected?: boolean) {
    if (this.editPrivilege) {
      if (!this.functionService.isUndefined(selected)) {
        this.selectedModule[module] = selected;
      }
      if (this.selectedModule[module]) {
        this.selectedPrivilege[module] = [ ...this.defaultActionList[module] ];
      } else {
        this.selectedPrivilege[module] = [];
      }

      this.expandModule(module, this.selectedModule[module]);
      this.checkSelectAll();
    } else {
      this.popupService.presentAlert(this.translate.instant('ACCOUNT_PRIVILEGE.msg.no_privilege'));
    }
  }

  /**
   * Select Action
   * @param module AccountModule
   * @param action Action
   */
  selectAction(module: string, action: string) {
    if (this.editPrivilege) {
      if (this.selectedPrivilege?.[module]) {
        const actionIndex = this.selectedPrivilege[module]?.indexOf(action);
        if (actionIndex === -1) {
          this.selectedPrivilege[module].push(action);
        } else {
          this.selectedPrivilege[module]?.splice(actionIndex, 1);
        }
      }
      this.checkAction(module);
      if (this.selectedPrivilege?.[module]?.length) {
        this.selectedModule[module] = true;
      } else {
        this.selectedModule[module] = false;
      }
      this.setupPrivilegeList();
      this.checkSelectAll();
    }
  }

  /**
   * Check account privilege for view action
   * @param module AccountModule
   * @param action Action
   */
  async checkAction(module: string) {
    if (module && this.selectedPrivilege[module]) {
      if (this.selectedPrivilege[module]?.indexOf('view') === -1) {
        this.selectedPrivilege[module].push('view');
      }
    }
  }

  /**
   * Check Select All
   */
  checkSelectAll() {
    let selectAll = true;
    this.moduleList?.forEach((module: ModuleType) => {
      if (selectAll && (!this.selectedModule[module] || this.selectedModule[module] === false)) {
        selectAll = false;
      }
    });
    this.selectAll = selectAll;
  }

  /**
   * Check Expand All
   */
  checkExpandAll() {
    let expandAll = true;
    this.moduleList?.forEach((module: ModuleType) => {
      if (expandAll && (!this.expandedModule[module] || this.expandedModule[module] === false)) {
        expandAll = false;
      }
    });
    this.expandAll = expandAll;
  }

  /**
   * Check Selected Privilege
   * @param module AccountModule
   * @param action Action
   */
  checkSelectedPrivilege(module: string, action: string) {
    if (module) {
      if (this.selectedPrivilege?.[module]) {
        if (action && this.selectedPrivilege[module]?.indexOf(action) !== -1) {
          return true;
        }
      }
    }
    return false;
  }

  /**
   * Check edit account member privilege
   */
  checkEditModePrivilege() {
    if (!this.checkCurrentUserPrivilege('member')) {
      this.editRole = false;
      this.editPrivilege = false;
    }
  }

  /**
   * Check if current user
   */
  checkOwnUserPrivilege() {
    this.ownPrivilege = false;
    if (this.accountUser?.uid === this.userService.uid) {
      this.ownPrivilege = true;
    }
  }

  /**
   * Check user privilege
   * @param action Action
   */
  checkCurrentUserPrivilege(action: string): boolean {
    return this.privilegeService.checkCurrentUserPrivilege('account', action);
  }

  /**
   * Get save data
   * @returns save data
   */
  getSaveData(): any {
    const data: any = {};
    if (this.editRole && this.selectedRole) {
      data.role = this.selectedRole;
    }
    if (this.editPrivilege && this.selectedPrivilege) {
      data.privilege = this.selectedPrivilege;
    }
    return data;
  }

  /**
   * Next
   */
  async next() {
    if (this.editRole || this.editPrivilege) {
      if (this.validateInput()) {
        if (this.type === 'user' && this.accountUser?.uid) {
          await this.popupService.presentLoading();
          const data: any = this.getSaveData();
          if (data) {
            await this.accountUserService.updateAccountUser(this.accountUser.uid, data);
          }
          await this.popupService.dismissLoading();
          this.dismissModal();
        } else if (this.type === 'edit' && this.accountLogin?.accountLoginId) {
          await this.popupService.presentLoading();
          const data: any = this.getSaveData();
          if (data && this.accountLogin?.login) {
            await this.accountLoginService.updateAccountLogin(this.accountLogin.login, data);
            this.popupService.saveSuccessToast();
          }
          await this.popupService.dismissLoading();
          this.dismissModal();
        } else {
          const modal = await this.popupService.presentConfirm(
            this.translate.instant('ACCOUNT.msg.generate_new_access')
          );
          modal.onDidDismiss().then(async (result: any) => {
            if (result?.data?.confirm) {
              if (this.onlineService.isOnline()) {
                await this.popupService.presentLoading(this.translate.instant('LOADING.new_access'));
                const accountLogin = await this.accountLoginService.generateAccountLoginCall(
                  this.selectedRole, this.selectedPrivilege,
                  this.accountInfoService?.accountInfo?.title?.value,
                  this.accountInfoService?.accountInfo?.time,
                  this.accountInfoService?.accountInfo?.timezone,
                );
                await this.popupService.dismissLoading();
                await this.presentAccountShareModal(accountLogin);
              }
            }
          });
        }
      }
    } else {
      this.popupService.presentActionError();
    }
  }

  /**
   * Validate Input
   */
  validateInput(): boolean {
    let selected = false;
    this.moduleList?.forEach((module: ModuleType) => {
      if (!selected && !this.functionService.isEmpty(this.selectedPrivilege[module])) {
        selected = true;
      }
    });

    if (selected && this.selectedRole) {
      return true;
    } else {
      if (!this.selectedRole) {
        this.popupService.presentToast(this.translate.instant('VALIDATION.required', { field: this.translate.instant('LBL.role') }), 'danger');
      } else if (!selected) {
        this.popupService.presentToast(this.translate.instant('VALIDATION.required', { field: this.translate.instant('LBL.privilege') }), 'danger');
      } else {
        this.popupService.presentToast(this.translate.instant('MSG.please_select'), 'danger');
      }
      return false;
    }
  }

}
