<ion-header>
  <ion-toolbar color="light">
    <ion-buttons slot="start">
      <ion-button (click)="dismissModal()">
        <ion-icon color="dark" class="back-icon" src="./assets/wedding/icon/general/chevron-back.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <app-offline-indicator></app-offline-indicator>
      <ng-container *ngIf="newGuestList?.length else titleBlock">{{ 'BTN.preview' | translate }}</ng-container>
      <ng-template #titleBlock>{{ 'SEATING.title.detail' | translate: { seating: seatingTypeName } }}</ng-template>
    </ion-title>
  </ion-toolbar>
  <ion-toolbar class="ion-no-padding">
    <div>
      <ion-card class="box-shadow ion-margin ion-text-center" *ngIf="seating" [color]="color">
        <ion-item class="ion-item-no-padding ion-padding-horizontal background-transparent" lines="none">
          <span class="ion-no-margin" slot="start" (click)="updateFavorite()" *ngIf="seating?.seatingId">
            <ion-icon color="dark" src="./assets/wedding/icon/general/star.svg" *ngIf="seating?.favorite"></ion-icon>
            <ion-icon color="dark" src="./assets/wedding/icon/general/star-outline.svg" *ngIf="!seating?.favorite"></ion-icon>
          </span>
          <span class="ion-no-margin" slot="end" (click)="presentSeatingEditModal()" *ngIf="seating?.seatingId && currentPrivilege?.[module]?.['seating']">
            <ion-icon src="./assets/wedding/icon/general/edit.svg"></ion-icon>
          </span>
        </ion-item>
        <div class="ion-margin-horizontal">
          <fit-line [minFontSize]="10" [maxFontSize]="30" [calcOnResize]="true">
            <div class="text-ellipsis font-bold margin-5">
              <ng-container *ngIf="seating?.name">
                {{ seating.name }}
              </ng-container>
              <ng-container *ngIf="!seating?.name">
                {{ 'SEATING.lbl.not_yet_assigend_guest' | translate }}
              </ng-container>
            </div>
          </fit-line>
          <div class="margin-5" *ngIf="seating.count > 1">
            <span class="ion-text-uppercase font-12 free-seating">
              {{ 'SEATING.lbl.free_seating' | translate }}
            </span>
          </div>
          <div class="margin-5 text-ellipsis">
            <ion-text class="font-12 line-110" color="dark">
              {{ invitedBy }}
            </ion-text>
          </div>
          <div class="margin-5 text-ellipsis">
            <ion-text class="font-12 line-110" color="dark">
              {{ category }}
            </ion-text>
          </div>
        </div>
        <ion-grid *ngIf="module === 'guest' || module === 'checkin'" fixed>
          <ion-row *ngIf="seatingSummary">
            <ion-col size="4">
              <ion-card class="card-status box-shadow vertical-center ion-no-margin" color="light" (click)="promptSettingField('dietary_req', seatingSummary?.dietaryReq, true)" button>
                <ion-icon class="icon-small" src="./assets/wedding/icon/guest/dietary-req.svg"></ion-icon>
                <div class="card-status-div">
                  <ng-container *ngIf="dietaryReq else dietaryBlock">
                    <ion-text class="font-10 line-100 font-med" color="dark" [innerHTML]="dietaryReq"></ion-text>
                  </ng-container>
                  <ng-template #dietaryBlock>
                    <ion-label>-</ion-label>
                  </ng-template>
                </div>
              </ion-card>
            </ion-col>
            <ion-col size="4">
              <ion-card class="card-status box-shadow vertical-center ion-no-margin" color="light" button>
                <ion-icon class="icon-small" src="./assets/wedding/icon/general/persons.svg"></ion-icon>
                <div class="card-status-div">
                  <ion-label class="font-18 font-bold" color="dark">
                    <ng-container *ngIf="seating?.name && seating.seatingId else not_assigned_seat">
                      <ng-container *ngIf="module === 'checkin'">
                        {{ attendedCount ? attendedCount : 0 }} / {{ count }}
                      </ng-container>
                      <ng-container *ngIf="module === 'guest'">
                        {{ count ? count : 0 }} / {{ seating.maxGuest }}
                      </ng-container>
                    </ng-container>
                    <ng-template #not_assigned_seat>
                      <ng-container *ngIf="module === 'checkin'">
                        {{ attendedCount ? attendedCount : 0 }} / {{ count }}
                      </ng-container>
                      <ng-container *ngIf="module === 'guest'">
                        {{ count ? count : 0 }}
                      </ng-container>
                    </ng-template>
                    
                  </ion-label>
                </div>
              </ion-card>
            </ion-col>
            <ion-col size="4">
              <ion-card class="card-status box-shadow vertical-center ion-no-margin" color="light" (click)="promptSettingField('special_req', seatingSummary.specialReq, true)" button>
                <ion-icon class="icon-small" src="./assets/wedding/icon/guest/special-req.svg"></ion-icon>
                <div class="card-status-div">
                  <ng-container *ngIf="specialReq else seatBlock">
                    <ion-text class="font-10 line-100 font-med" color="dark" [innerHTML]="specialReq"></ion-text>
                  </ng-container>
                  <ng-template #seatBlock>
                    <ion-label>-</ion-label>
                  </ng-template>
                </div>
              </ion-card>
            </ion-col>
          </ion-row>
        </ion-grid>
        <div style="margin: 10px">
          <app-gift-summary *ngIf="module === 'gift' && seatingSummary?.giftSummary" [giftSummary]="seatingSummary.giftSummary"></app-gift-summary>
        </div>
      </ion-card>
    </div>
  </ion-toolbar>
  <ion-toolbar class="toolbar-select-all" color="light">
    <app-select-all [total]="count" [selected]="selectedGuestList?.length" [selectAll]="selectAll" [editMode]="editMode" [hideTotal]="true" (toggleSelect)="toggleSelectAll()"></app-select-all>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding-horizontal" color="light" scroll="false" scroll-y="false" #content>
  <div class="full-width-height viewport-div" *ngIf="guestList?.length">
    <cdk-virtual-scroll-viewport class="ion-content-scroll-host" [itemSize]="estimateItemSize" [minBufferPx]="200" [maxBufferPx]="200" >
      <ion-list class="full-width background-transparent" lines="none" #list>
        <app-guest-card *cdkVirtualFor="let guest of guestList | slice : 0 : limit; trackBy: trackByFn; let index = index;" [style.height.px]="itemHeightFn(guest)" [setGuest]="guest" [editMode]="editMode" [selectedGuestList]="selectedGuestList" [selectedGroupList]="selectedGroupList" [index]="index" [disableClick]="assignMode ? true : false" [setHideField]="{ seatingStatus: true }" (setSelectedGuest)="setSelectedGuest($event)" (setSelectedGroup)="setSelectedGroup($event)" (checkSelectedGroup)="checkSelectedGroup($event)"></app-guest-card>
        <ion-infinite-scroll class="" threshold="15%" (ionInfinite)="loadLimit($event)" *ngIf="guestList?.length > limit">
          <ion-infinite-scroll-content
            loadingSpinner="bubbles"
            [loadingText]="'LOADING.loading' | translate">
          </ion-infinite-scroll-content>
        </ion-infinite-scroll>
        <div class="full-width ion-margin ion-padding">&nbsp;</div>
      </ion-list>
    </cdk-virtual-scroll-viewport>
    <!--  -->
  </div>
  <div class="full-width-height vertical-center ion-text-center" *ngIf="!guestList?.length">
    <ion-icon class="center icon-cactus" color="medium" src="./assets/wedding/icon/general/no-record.svg"></ion-icon>
    <div class="ion-padding-vertical"><ion-text class="font-14" color="medium">{{ 'LBL.no_found' | translate }}</ion-text></div>
  </div>
  
  <ion-fab horizontal="end" vertical="bottom" slot="fixed" *ngIf="!swapMode && !assignMode && currentPrivilege?.[module]?.['seating']">
    <ion-fab-button color="primary" (click)="toggleEditMode()">
      <ion-icon src="./assets/wedding/icon/general/chevron-up.svg" *ngIf="!editMode"></ion-icon>
      <ion-icon src="./assets/wedding/icon/general/close.svg" *ngIf="editMode"></ion-icon>
    </ion-fab-button>
  </ion-fab>
</ion-content>
<ion-footer class="ion-no-border" *ngIf="(editMode || swapMode || assignMode) && currentPrivilege?.[module]?.['seating']" #footer>
  <app-bulk-action *ngIf="editMode && !swapMode && !assignMode" [mode]="module === 'checkin' ? 'list' : module === 'gift' ? 'gift' : 'list'" [count]="count" [selectedList]="selectedGuestList" [setReady]="true" (action)="bulkAction($event)"></app-bulk-action>
  <ion-toolbar class="ion-no-padding" *ngIf="assignMode || swapMode">   
    <ion-button class="ion-margin" expand="block" *ngIf="assignMode" (click)="assignSeating()">
      {{ 'BTN.save' | translate }}
    </ion-button>
    <ion-button class="ion-margin" expand="block" *ngIf="swapMode" (click)="previewSwapSeating()" [disabled]="swapMode && (!newGuestList || !newGuestList.length)">
      {{ 'BTN.preview' | translate }}
    </ion-button>
  </ion-toolbar>
</ion-footer>