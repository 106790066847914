import { FunctionService } from './../general/function.service';
import { AnalyticsService } from 'src/app/services/general/analytics.service';
import { NavigationExtras, Router } from '@angular/router';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

import { AccountCoupleService } from 'src/app/services/account/account-couple.service';
import { MenuService } from 'src/app/services/general/menu.service';
import { AccountSetupService } from 'src/app/services/account/account-setup.service';
import { AccountLoginService } from 'src/app/services/account/account-login.service';
import { UpdateByService } from 'src/app/services/user/update-by.service';
import { StorageService } from 'src/app/services/general/storage.service';
import { PopupService } from 'src/app/services/general/popup.service';
import { ErrorService } from 'src/app/services/general/error.service';
import { LinkService } from 'src/app/services/general/link.service';

import { Credit } from 'src/app/interfaces/credit';
import { UrlService } from '../general/url.service';
import { BehaviorSubject } from 'rxjs';

/**
 * account service
 */
@Injectable({
  providedIn: 'root'
})
export class AccountService implements OnInit, OnDestroy {

  /**
   * Account ID
   */
  accountId: string;

  ready: boolean;

  observableReady: any;

  /**
   * Constructor
   */
  constructor(
    private router: Router,
    private menuService: MenuService,
    private linkService: LinkService,
    private fns: AngularFireFunctions,
    private updateByService: UpdateByService,
    private accountCoupleService: AccountCoupleService,
    private accountSetupService: AccountSetupService,
    private accountLoginService: AccountLoginService,
    private analyticsService: AnalyticsService,
    private functionService: FunctionService,
    private storageService: StorageService,
    private urlService: UrlService,
    private popupService: PopupService,
    private errorService: ErrorService,
  ) {
    this.observableReady =  new BehaviorSubject<boolean>(this.ready);
  }

  ngOnInit(): void {
      
  }

  ngOnDestroy(): void {
      
  }

  /**
   * Read Account ID
   */
  async readAccountId() {
    if (!this.accountId) {
      this.accountId = await this.storageService.get('account_id');
    }
    // this.accountId = '2KBuVFMsuvEnvoEPfX05';
    // this.accountId = 'xga8UklZmcQ0iukuSxAE';
    // this.accountId = 'o155v44yrx8Yl2kqN0qR';
    // this.accountId = 'tz2SBG0kLryszEaAbHS2';
    // this.accountId = 'cr8jEKk2VCmd2uYOPH6z';
    // this.accountId = 'PVbl03YZoAUJI9pLIrHs';
    // this.accountId = 'BzVjg17zAjk7mGXvPB6Z';
    // this.accountId = 'Q9gSpkOXL3EBpbOgP0kl';
    // this.accountId = 'SObsEZrFgNldsMdxoDck';
    // this.accountId = 'iHglEDzXwGM3QQIhWisY';
    // this.accountId = '7ktYPk0N8FD4oNrzUTQz';
    // this.accountId = 'wYHocsyADnDvanuSV6mO';
    // this.accountId = 'NohAquvgFP8aSaYlPhvB';
    // this.accountId = 'CtXZBXCKEgxtjamgG9II';
    return this.accountId;
  }

  /**
   * Setup Account ID
   * @param accountId Account ID
   */
  async setupAccount(accountId: string, goUpgrade?: boolean, goCheckinModule?: boolean) {
    if (accountId) {
      
      this.accountId = accountId;
      await this.loginAccount(accountId, goUpgrade);
      await this.analyticsService.accountLoginAuto(accountId);
    } else {
      await this.logoutAccount();
    }
  }

  /**
   * Login account
   */
  async loginAccount(accountId: string, goUpgrade?: boolean, goCheckinModule?: boolean) {
    this.ready = false;
    this.observableReady.next(this.ready);

    if (this.accountId || await this.readAccountId()) {
      if (this.accountId !== accountId) {
        await this.logoutAccount(true);
      }
    }

    if (accountId) {
      const list = [ '/main', '/accounts/login', '/user/login' ];
      const hideLoading = this.functionService.existed(this.urlService.url, list);

      if (!hideLoading && this.urlService.url !== '/') {
        await this.popupService.presentLoading();
      }
      
      await this.functionService.delay(200);
      this.accountId = accountId;
      this.menuService.setupMenu('main');

      await this.storageService.set('account_id', accountId);
      await this.accountSetupService.setupSetting(this.accountId);
      this.errorService.accountId = this.accountId;

      if (goUpgrade) {
        await this.goSubscriptionPage(this.accountId);
      } else if (goCheckinModule) {
        this.router.navigate(['/tabs-checkin/home']);
      } else {
        await this.goAccountHomePage();
      }
      if (!hideLoading && this.urlService.url !== '/') {
        await this.popupService.dismissLoading();
      }
    } else {
      await this.linkService.goMainPage();
    }
    await this.functionService.delay(200);
    this.ready = true;
    this.observableReady.next(this.ready);
  }

  /**
   * Logout account
   */
  async logoutAccount(relogin?: boolean) {
    this.accountId = '';
    await this.storageService.set('account_id', this.accountId);
    this.accountCoupleService.setupCoupleType();

    await this.accountSetupService.setupSetting(this.accountId);
    await this.accountLoginService.setupAccountLogin('', '');
    this.menuService.setupMenu('root');

    this.errorService.accountId = this.accountId;
    if (!relogin) {
      await this.popupService.dismissAllModal();
    }
  }

  /**
   * Go dashboard
   */
  async goAccountHomePage() {
    // this.ngZone.run(() => {
    //   setTimeout(async () => {
        await this.linkService.goAccountHomePage(true);
        await this.popupService.dismissLoading();
    //   }, 500);
    // });
  }

  /**
   * Go product subscription page
   */
  async goSubscriptionPage(accountId: string) {
    await this.functionService.delay(500);
    await this.popupService.dismissLoading();
    const navigationExtras: NavigationExtras = {
      state: {
        accountId,
        upgradeTrial: true,
        backPremiumPage: true
      }
    };
    this.router.navigate(['/subscription/premium'], navigationExtras);
  }

  /**
   * Upgrade wedding account
   * @param credit Credit
   * @param accountId Account ID
   */
  async upgradeAccountCall(credit: Credit, accountId: string, trial: boolean) {
    if (credit?.creditId && accountId) {
      await this.popupService.presentLoading();
      await this.fns.httpsCallable('upgradeAccountCall')({
        credit,
        accountId,
        trial,
        updateBy: this.updateByService.getUpdateBy()
      }).toPromise().then(async (result: boolean) => {
        await this.popupService.dismissLoading();
        if (result) {
          if (this.accountId !== accountId) {
            await this.setupAccount('');
          }
          this.setupAccount(accountId);
          if (trial) {
            this.analyticsService.upgradeTrial(accountId);
          }
        } else {
          this.popupService.presentActionError();
        }
      }).catch((err) => {
        this.errorService.logError(err);
        this.popupService.dismissLoading();
      });
    }
  }

  // getReady(): boolean {
  //   if (this.accountId && !this.ready) {
  //     return false;
  //   }
  //   return true;
  // }

}
