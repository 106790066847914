import { Injectable, OnDestroy, OnInit } from '@angular/core';


import { add, Duration, format, formatISO, fromUnixTime } from 'date-fns';
import { zhCN, zhHK, zhTW } from 'date-fns/locale';

import { LanguageService } from './language.service';

import { formatInTimeZone } from 'date-fns-tz';
import { FunctionService } from './function.service';
import { Timezone } from 'src/app/interfaces/database';

import { LocaleService } from './locale.service';
import { DateFormatList, TimeFormatList } from 'src/app/commons/locale';
import { LocalizationService } from './localization.service';

@Injectable({
  providedIn: 'root'
})
export class DateTimeService implements OnInit, OnDestroy {

  constructor(
    private localizationService: LocalizationService,
    private localeService: LocaleService,
    private languageService: LanguageService,
    private functionService: FunctionService,
  ) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  format(timestamp: number | Date, dateTimeFormat?: string, langCode?: string, timezone?: string, locale?: string, countryCode?: string): string {
    if (timestamp) {
      if (!langCode) {
        langCode = this.languageService.getUserLanguage()?.code;
      }
      
      if (!locale) {
        if (this.localizationService.getLocale()) {
          locale = this.localizationService.getLocale();
        }
      }

      if (!dateTimeFormat) {
        if (this.localizationService.getDateFormat() && this.localizationService.getTimeFormat()) {
          dateTimeFormat = this.localizationService.getDateFormat() + ' ' + this.localizationService.getTimeFormat();
        } else {
          dateTimeFormat = 'PP pp';
        }
      } else if (dateTimeFormat === 'date') {
        if (this.localizationService.getDateFormat()) {
          dateTimeFormat = this.localizationService.getDateFormat();
        } else {
          dateTimeFormat = 'PPPP';
        }
      } else if (dateTimeFormat === 'time') {
        if (this.localizationService.getTimeFormat()) {
          dateTimeFormat = this.localizationService.getTimeFormat();
        } else {
          dateTimeFormat = 'p';
        }
        // else if (this.localizationService.get12HourTimeFormat()) {
        //   dateTimeFormat = 'p';
        // } else {
        //   dateTimeFormat = 'HH:mm';
        // }
      }

      const dateFnsLocale = this.localeService.getDateFnsLocale(langCode, locale, countryCode); 
      if (dateFnsLocale === zhCN || dateFnsLocale === zhTW || dateFnsLocale === zhHK) {
        if (dateTimeFormat.indexOf(' yyyy') !== -1) {
          dateTimeFormat = dateTimeFormat.replace(' yyyy', ' yyyy年');
        } else if (dateTimeFormat.indexOf('yyyy ') !== -1) {
          dateTimeFormat = dateTimeFormat.replace('yyyy ', ' yyyy年 ');
        } else if (dateTimeFormat.indexOf('yyyy') !== -1) {
          dateTimeFormat = dateTimeFormat.replace('yyyy', 'yyyy年');
        }
      }

      const options = { locale: dateFnsLocale };
      if (timezone) {
        return formatInTimeZone(timestamp, timezone, dateTimeFormat, options);
      }
      return format(timestamp, dateTimeFormat, options);
    }
    return '';
  }

  formatISO(date: number | Date) {
    return formatISO(date);
  }

  fromUnixTime(unixTime: number): Date {
    return fromUnixTime(unixTime);
  }

  formatIsoByTimestamp(timestamp: number, timeZone?: string): string {
    if (timestamp) {
      if (timeZone) {
        return (formatInTimeZone(timestamp * 1000, timeZone, 'yyyy-MM-dd\'T\'HH:mm:ss.SSSxxx'));
      }
      return formatISO(fromUnixTime(timestamp));
    }
    return '';
  }

  adjustTime(date: Date | number, duration: Duration) {
    return add(date, duration);
  }

  generatePickerTime(timestamp: string, timezone: Timezone) {
    if (timestamp && timezone?.utc) {
      const newDate = new Date(timestamp);
      const date = this.format(newDate, 'yyyy-MM-dd', 'en', '', 'en-US');
      const time = this.format(newDate, 'HH:mm', 'en', '', 'en-US');
      const dateTime = date + 'T' + time + ':00.000' + timezone.utc.replace('UTC', '');
      return this.functionService.generateTimestamp(dateTime);
    }
    return null;
  }

  generateFormatList(type: string, locale: string, selected?: string, time?: number | Date) {
    const formatList: { text: string, format: string }[] = [];

    if (!time) {
      time = new Date();
    }
    if (type === 'date') {
      DateFormatList.forEach((format: string) => {
        const text = this.format(time, format, '', '', locale);
        const index = formatList.findIndex((x) => {
          if (x.text === text) {
            return true;
          }
          return false;
        });
        
        if (index === -1) {
          formatList.push({ text, format });
        } else if (formatList?.[index]?.format) {
          selected = formatList[index].format;
        }
      });
    } else if (type === 'time') {
      TimeFormatList.forEach((format: string) => {
        const text = this.format(time, format, '', '', locale);
        const index = formatList.findIndex((x) => {
          if (x.text === text) {
            return true;
          }
          return false;
        });
        
        if (index === -1) {
          formatList.push({ text, format });
        } else if (formatList?.[index]?.format) {
          selected = formatList[index].format;
        }
      });
    }

    if (selected) {
      const format = selected;
      const text = this.format(time, format, '', '', locale);
      const index = formatList.findIndex((x) => {
        if (x.text === text) {
          return true;
        }
        return false;
      });
      if (index === -1) {
        formatList.push({ text, format });
      } else if (formatList?.[index]?.format) {
        selected = formatList[index].format;
      }
    }
    return { formatList, selected };
  }
}
