
<div class="full-width-height vertical-center">
  <quill-view [content]="title" *ngIf="title"></quill-view>
  
  <div *ngIf="websiteDetail?.checkin?.enable">
    <div *ngIf="websiteDetail?.checkin?.media?.url">
      <ng-container *ngIf="websiteDetail?.checkin?.media.type === 'youtube'">
        <app-youtube [websiteMode]="true" [setCardHeight]="mediaHeight" [setCalculateSize]="updateTheme" [setSrc]="websiteDetail?.checkin?.media.url"></app-youtube>
      </ng-container>
      <ng-container *ngIf="websiteDetail?.checkin?.media.type === 'base64' || websiteDetail?.checkin?.media.type === 'firebase'">
        <ion-img class="full-width center" [src]="websiteDetail?.checkin?.media.url"></ion-img>
      </ng-container>
    </div>

    <div class="ion-padding-horizontal ion-padding-bottom" *ngIf="msg">
      <quill-view [content]="msg"></quill-view>
    </div>
    
    <ng-container *ngIf="websiteType !== 'home'">
      <div *ngIf="websiteDetail?.checkin?.start?.enable">
        <div class="ion-padding-horizontal" *ngIf="startTime > now">
          <quill-view [content]="startMsg" *ngIf="startMsg"></quill-view>
          <div class="background-transparent ion-text-center ion-padding-horizontal">
            <app-website-time [pageMode]="true" [previewMode]="previewMode ? true : false" [setAccountInfo]="accountInfo" [setStartTime]="startTime" [setWebsiteDetail]="websiteDetail" [saveCalendar]="false" [setLocale]="accountInfo?.locale" [setDateFormat]="accountInfo?.dateFormat" [setTimeFormat]="accountInfo?.timeFormat" [setTimezone]="accountInfo?.timezone" [setFontSize]="websiteDetail?.time?.fontSize" [setDesign]="1"></app-website-time>
          </div>
        </div>
      </div>
    
      <div *ngIf="websiteDetail?.checkin?.end?.enable && (!websiteDetail?.checkin?.start?.enable || now >= startTime)">
        <div *ngIf="websiteDetail?.checkin?.end?.mins">
          <div class="ion-padding-horizontal" *ngIf="endTime">
            <quill-view [content]="endMsg" *ngIf="endMsg"></quill-view>
            <div class="background-transparent ion-text-center ion-padding-horizontal">
              <app-website-time [pageMode]="true" [previewMode]="previewMode ? true : false" [setAccountInfo]="accountInfo" [setStartTime]="endTime" [setWebsiteDetail]="websiteDetail" [saveCalendar]="false" [setLocale]="accountInfo?.locale" [setDateFormat]="accountInfo?.dateFormat" [setTimeFormat]="accountInfo?.timeFormat" [setTimezone]="accountInfo?.timezone" [setFontSize]="websiteDetail?.time?.fontSize" [setDesign]="1"></app-website-time>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <div class="ion-padding" *ngIf="websiteType !== 'home'">      
      <div class="ion-text-center font-20 font-bold ion-padding-horizontal ion-padding-top">
        {{ name }}
      </div>
      <div class="ion-padding-top ion-padding-horizontal">
        <ion-button class="center" [class]="websiteThemeColor?.button?.fill === 'outline' ? 'website-outline-btn' : 'website-solid-btn'" [fill]="websiteThemeColor?.button?.fill" (click)="checkin()" [disabled]="startTime > now || now > endTime" *ngIf="btn">
          {{ btn }}
        </ion-button>
      </div>
      <!-- <ng-container *ngIf="checkinStatus && websiteType !== 'home' then checkinStatusBlock"></ng-container> -->
    </div>
  </div>
  
  <div *ngIf="!websiteDetail?.checkin?.enable && websiteType !== 'home'">
    <div class="background-transparent ion-padding ion-text-center">
      {{ 'WEBSITE.checkin.disabled' | translate }}
    </div>
  </div>

  <ng-container *ngIf="websiteDetail?.qrcode?.enable && websiteType !== 'home'">
    <div class="ion-text-center">
      <ion-text class="font-12 font-underline" (click)="toggleQrcodeMode()">
        {{ qrcodeBtn }}
      </ion-text>
    </div>
  </ng-container>
</div>

<ng-template #checkinStatusBlock>
  <app-website-checkin-success [websiteDetail]="websiteDetail" [setGuest]="guest" [setGroup]="group" [setMemberList]="memberList" [setCheckinStatus]="checkinStatus"></app-website-checkin-success>
</ng-template>