import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AccountInfo } from 'src/app/interfaces/account';
import { WebsiteDetail } from 'src/app/interfaces/website';
import { FunctionService } from 'src/app/services/general/function.service';

@Component({
  selector: 'app-website-host',
  templateUrl: './website-host.component.html',
  styleUrls: ['./website-host.component.scss'],
})
export class WebsiteHostComponent  implements OnInit, OnDestroy {

  @Input() previewMode: boolean;

  @Input() set setWebsiteDetail(websiteDetail: WebsiteDetail) {
    this.websiteDetail = websiteDetail;

    this.title = this.getContent(this.websiteDetail?.hostMsg?.title);
    this.msg = this.getContent(this.websiteDetail?.hostMsg?.msg);
    this.couple1Msg = this.getContent(this.websiteDetail.hostMsg.couple1?.msg);
    this.couple2Msg = this.getContent(this.websiteDetail.hostMsg.couple2?.msg);
    this.organizerMsg = this.getContent(this.websiteDetail.hostMsg.organizer?.msg);
  }

  @Input() accountInfo: AccountInfo;

  @Input() mediaHeight: number;

  @Input() fontColor: string;

  @Input() updateTheme: boolean;

  websiteDetail: WebsiteDetail;

  title: string;
  msg: string;
  
  couple1Msg: string;
  couple2Msg: string;
  organizerMsg: string;

  constructor(
    private functionService: FunctionService,
  ) { 
  }

  ngOnInit() {}

  ngOnDestroy(): void {
      
  }

  getContent(msg: string) {
    if (msg) {
      if (!this.functionService.sanitizeHtml(msg).trim()) {
        return '';
      }
    }
    return msg;
  }

}
