<div class="ion-margin" *ngIf="attachmentType && websiteAttachment?.enable">
  <ion-item class="background-transparent" lines="none">
    <!-- class="website-clear-btn" fill="clear" -->
    
    <ion-button class="attachment-btn ion-no-margin" [class]="websiteThemeColor?.button?.fill === 'outline' ? 'website-outline-btn' : 'website-solid-btn'" [fill]="websiteThemeColor?.button?.fill" *ngIf="websiteAttachment?.attachmentUrl?.url" (click)="openFile(websiteAttachment)" slot="start">
      <ng-container *ngIf="websiteAttachment?.mimeType.indexOf('image/') !== -1">
        <ion-icon class="font-36" src="./assets/wedding/icon/website/attachment/image.svg"></ion-icon>
      </ng-container>
      <ng-container *ngIf="websiteAttachment?.mimeType === 'application/pdf'">
        <ion-icon class="font-36" src="./assets/wedding/icon/website/attachment/pdf.svg"></ion-icon>
      </ng-container>
    </ion-button>
    
    <ion-text>
      <div class="ion-padding-horizontal" *ngIf="websiteAttachment?.title">
        <ion-text class="font-20" [innerHTML]="websiteAttachment?.title"></ion-text>
      </div>
      <div *ngIf="msg">
        <quill-view [content]="msg"></quill-view>
      </div>
    </ion-text>
  </ion-item>
  <!-- <div class="ion-text-center ion-padding-horizontal">
    <ion-text class="font-20" [innerHTML]="websiteAttachment?.title" *ngIf="websiteAttachment?.title"></ion-text>
  </div>
  <div *ngIf="websiteAttachment?.attachmentUrl?.url">
    <ng-container *ngIf="websiteAttachment?.mimeType.indexOf('image/') !== -1">
      <ion-img class="full-width" [src]="websiteAttachment?.attachmentUrl?.url"></ion-img>
    </ng-container>
    <ng-container *ngIf="websiteAttachment?.mimeType === 'application/pdf'">
      <ion-icon class="font-40 center" src="./assets/wedding/icon/general/document-outline.svg"></ion-icon>
    </ng-container>
  </div>
  <div class="ion-padding-horizontal" *ngIf="msg">
    <quill-view [content]="msg"></quill-view>
  </div> -->
</div>