import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, Subscription, map } from 'rxjs';

import { UpdateByService } from 'src/app/services/user/update-by.service';
import { SettingField } from 'src/app/interfaces/database';
import { FunctionService } from 'src/app/services/general/function.service';

import { DefaultEventInvitedByList, DefaultInvitedByList, StdEventInvitedByList, StdInvitedByList } from 'src/app/commons/invitedBy';
import { ErrorService } from 'src/app/services/general/error.service';
import { AccountEventModeService } from '../account/account-event-mode.service';

/**
 * Invited by service
 */
@Injectable({
  providedIn: 'root'
})
export class InvitedByService implements OnInit, OnDestroy {
  reservedField: SettingField = {
    value: 'mutual',
    custom: false,
  };
  /**
   * Standard invited by database
   */
  // stdInvitedbyList: string[] = StdInvitedByList;
  // defaultInvitedbyList: string[] = DefaultInvitedByList;
  /**
   * Invited by list
   */
  invitedByList: SettingField[];
  /**
   * Observable invited by list
   */
  observableInvitedbyList: any;
  /**
   * Invited by list subscription
   */
  private invitedBySubscription: Subscription;
  /**
   * Account ID
   */
  private accountId: string;

  /**
   * Constructor
   * @param afs angualr firestore
   * @param translate translate service
   * @param userService user serivce
   * @param functionService function service
   */
  constructor(
    private afs: AngularFirestore,
    private accountEventModeService: AccountEventModeService,
    private updateByService: UpdateByService,
    private functionService: FunctionService,
    private errorService: ErrorService
  ) {
    this.invitedByList = [];
    this.observableInvitedbyList = new BehaviorSubject<SettingField[]>(this.invitedByList);
  }

  ngOnInit(): void {

  }

  ngOnDestroy() {
    this.unwatchInvitedBy();
    this.invitedByList = [];
  }

  /**
   * Setup Account ID and watch / unwatch invited by
   */
  async setupAccountId(accountId: string) {
    this.accountId = accountId;

    if (this.accountId) {
      await this.watchInvitedBy();
    } else {
      await this.unwatchInvitedBy();
      this.invitedByList = [];
    }
  }

  /**
   * Watch Invited by
   */
  async watchInvitedBy() {
    if (this.accountId && !this.invitedBySubscription) {
      this.invitedBySubscription = this.afs.doc(`accounts/${ this.accountId }/accountSetting/invitedBy`)
      .snapshotChanges().pipe(map(changes => {
        const data: any = changes.payload.data();
        return data?.list;
      }), map((invitedByList: SettingField[]) => {
        if (invitedByList?.length) {
          return invitedByList.map((settingField: SettingField) => {
            if (!settingField?.id) {
              settingField.id = this.functionService.randomId() + '_' + settingField.value;
            }
            // if (settingField?.createBy) {
            //   delete settingField.createBy;
            // }
            // if (settingField?.updateBy) {
            //   delete settingField.updateBy;
            // }
            return settingField;
          });
        } else {
          return [];
          // return this.stdInvitedbyList.map((value: string) => {
          //   return { id: value, custom: false, value };
          // });
        }
      })).subscribe({
        next: (invitedByList: SettingField[]) => {
          this.invitedByList = invitedByList.filter((item: SettingField, index: number, self: SettingField[]) =>
            index === self.findIndex((t) => t.value === item.value && t.custom === item.custom)
          );
          this.observableInvitedbyList.next(this.invitedByList);
        }
      });
    }
  }

  /**
   * Unwatch invited by
   */
  async unwatchInvitedBy() {
    if (this.invitedBySubscription) {
      this.invitedBySubscription.unsubscribe();
      this.invitedBySubscription = null;
    }
  }

  /**
   * Search invited by
   * @param keyword Keyword
   * @param type Type
   * @param filter Filter
   * @returns List of fulfiled invite dby
   */
  searchInvitedByList(eventMode?: boolean, keyword?: string, type?: string, filter?: any, desc?: boolean): SettingField[] {
    let invitedByList: SettingField[] = this.getInvitedByList(eventMode);
    if (invitedByList?.length) {
      if (keyword) {
        invitedByList = invitedByList.filter((invitedBy: SettingField) => {
          return this.searchInvitedByReqBykeyword(invitedBy, keyword);
        });
      }
    }
    return invitedByList;
  }

  /**
   * Search invited by by keyword
   * @param invitedBy Invited by
   * @param keyword Keyword
   */
  searchInvitedByReqBykeyword(invitedBy: SettingField, keyword: string): boolean {
    if (keyword && invitedBy?.value) {
      const value = invitedBy.custom ? invitedBy.value :
        this.functionService.getTranslate('LIST.invited_by.' + invitedBy.value)?.toString().toLowerCase();
      if (this.functionService.search(value, keyword)) {
        return true;
      } else if (this.functionService.chineseMath(value, keyword)) {
        return true;
      }
    }
    return false;
  }

  /**
   * Save invited by list into firestore
   * @param invitedByList Invited by list
   */
  async saveInvitedBy(list: SettingField[]) {
    if (this.accountId && list?.length) {
      const data: any = {
        list,
        updateBy: this.updateByService.updateBy
      };

      const accountsRef = this.afs.firestore.doc(`accounts/${ this.accountId }/accountSetting/invitedBy/`);
      accountsRef.set(data, { merge: true }).then(result => {
      }).catch((err: any) => {
        this.errorService.logError(err);
      });
    }
  }

  getInvitedByList(eventMode?: boolean) {
    if (!eventMode) {
      eventMode = this.accountEventModeService.eventMode;
    }
    return this.invitedByList?.length ? this.invitedByList : this.getStdInvitedByList(eventMode);
  }

  getStdInvitedByList(eventMode?: boolean): SettingField[] {
    const stdInvitedByList: SettingField[] = [];
    if (eventMode) {
      StdEventInvitedByList?.forEach((value) => {
        const settingField: SettingField = {
          id: value,
          value,
          custom: false,
        }
        stdInvitedByList.push(settingField);
      });
    } else {
      StdInvitedByList?.forEach((value) => {
        const settingField: SettingField = {
          id: value,
          value,
          custom: false,
        }
        stdInvitedByList.push(settingField);
      });
    }
    
    return stdInvitedByList;
  }

  getDefaultInvitedByList(eventMode?: boolean): SettingField[] {
    const defaultInvitedByList: SettingField[] = [];
    if (eventMode) {
      DefaultEventInvitedByList?.forEach((value) => {
        const settingField: SettingField = {
          id: value,
          value,
          custom: false,
        }
        defaultInvitedByList.push(settingField);
      });
    } else {
      DefaultInvitedByList?.forEach((value) => {
        const settingField: SettingField = {
          id: value,
          value,
          custom: false,
        }
        defaultInvitedByList.push(settingField);
      });
    }
    return defaultInvitedByList;
  }

}
