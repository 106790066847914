import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { FunctionService } from 'src/app/services/general/function.service';
import { LinkService } from 'src/app/services/general/link.service';
import { UserService } from 'src/app/services/user/user.service';
import { LoadingScreenType } from 'src/app/types/general';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements OnInit, OnDestroy {

  @Input() type: LoadingScreenType;

  @Input() set setReady(ready: boolean) {
    this.ready = ready;
    this.showBtn = false;
    if (ready) {
      this.reloadTimeout();
    }
  }

  // @Input() showReload: boolean;

  ready: boolean;

  showBtn: boolean;
  
  constructor(
    private linkService: LinkService,
    private userService: UserService,
    private functionService: FunctionService,
  ) { }

  ngOnInit() {}

  ngOnDestroy(): void {
    this.ready = false;
  }

  async reloadTimeout() {
    if (this.userService?.user?.uid) {
      await this.functionService.delay(15000);
      if (this.ready) {
        this.showBtn = true;
      } else {
        this.showBtn = false;
      }
    } else {
      setTimeout(() => {
        this.reloadTimeout();
      }, 1000);
    }
  }

  reload() {
    this.linkService.goMainPage(true);
  }

}
