/**
 * System default role
 */
export const StdRoleList: string[] = [
    'admin',
    'wedding_planner',
    'helper',
    'usher',
    'viewer'
];

export const StdEventRoleList: string[] = [
    'organizer',
    'admin',
    'event_planner',
    'coordinator',
    'usher',
    'viewer'
];
