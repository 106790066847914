import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { WebsiteLogo } from 'src/app/interfaces/website';
import { FunctionService } from '../general/function.service';

@Injectable({
  providedIn: 'root'
})
export class WebsiteLogoService {
  
  defaultLogoUrl = './assets/wedding/img/logo/thebigday-logo-round-primary.webp';
  defaultLogoSize = 0.3;

  websiteLogo: WebsiteLogo;

  observable: any;

  constructor(
    private functionService: FunctionService,
  ) {
    this.observable = new BehaviorSubject<WebsiteLogo>(this.websiteLogo);
  }

  setup(websiteLogo: WebsiteLogo) {
    if (!this.functionService.isEqual(this.websiteLogo, websiteLogo)) {
      this.websiteLogo = websiteLogo;
      this.observable.next(this.websiteLogo);
    }
  }

  getWebsiteLogo(websiteLogo?: WebsiteLogo): WebsiteLogo {
    if (!websiteLogo) {
      websiteLogo = this.websiteLogo;
    }
    if (websiteLogo) {
      if (websiteLogo?.url === 'false') {
        websiteLogo.url = '';
      } else if (!websiteLogo?.url) {
        websiteLogo.url = this.defaultLogoUrl;
      }

      if (!websiteLogo?.size) {
        websiteLogo.size = this.defaultLogoSize;
      }
      return websiteLogo;
    } else {
      return {
        url: this.defaultLogoUrl,
        size: this.defaultLogoSize,
      }
    }
  }
}
