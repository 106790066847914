import { Injectable } from '@angular/core';
import { OnlineService } from '../general/online.service';
import { PopupService } from '../general/popup.service';
import { ErrorService } from '../general/error.service';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { WebsiteDetail } from 'src/app/interfaces/website';

@Injectable({
  providedIn: 'root'
})
export class WebsiteService {

  constructor(
    private fns: AngularFireFunctions,
    private onlineService: OnlineService,
    private popupService: PopupService,
    private errorService: ErrorService,
  ) { }

  async retrieveWebsiteDetailCall(accountId: string, linkId: string) {
    if (this.onlineService.online) {
      if (accountId) {
        await this.popupService.presentLoading();
        return await this.fns.httpsCallable('retrieveWebsiteDetailCall')({ accountId, linkId })
        .toPromise().then(async (result: WebsiteDetail) => {
          this.popupService.dismissLoading();
          return result;
        }).catch((err: any) => {
          this.popupService.dismissLoading();
          this.errorService.logError(err);
          return null;
          // this.popupService.presentAlert(this.translate.instant('ACCOUNTS_LOGIN.error'));
        });
      } else {
        // this.popupService.presentAlert(this.translate.instant('ACCOUNTS_LOGIN.error'));
      }
    }
    return null;
  }

  async retrieveWebsiteCall(accountId: string, linkId: string, guestId: string, groupId: string) {
    if (accountId) {
      // await this.popupService.presentLoading();
      return await this.fns.httpsCallable('retrieveWebsiteCall')({ accountId, linkId, guestId, groupId })
      .toPromise().then(async (result: any) => {
        // await this.popupService.dismissLoading();
        return result;
      }).catch(async (err: any) => {
        console.log(err);
        // await this.popupService.dismissLoading();
        this.errorService.logError(err);
        return null;
      });
    }
    return null;
  }
}
