<ion-app>
  <ion-split-pane contentId="main" [disabled]="disableSplitPane">
    <ion-menu side="start" menuId="main" contentId="main" type="reveal" swipeGesture="false">
      <ion-header class="ion-no-border">
        <ion-toolbar class="ion-no-padding" color="white" *ngIf="user?.uid && user?.name">
          <ion-item class="ion-margin-vertical background-transparent" [class.active-item]="selectedPath && (selectedPath === '/user/profile' || selectedPath === '/tabs-accounts/profile' || selectedPath === '/account/detail' || selectedPath.indexOf('/user/') !== -1)" lines="none">
            <ion-avatar class="small-avatar" slot="start" [ngClass]="online ? 'online-border': 'offline-border'" (click)="goProfile()">
              <ng-container *ngIf="user?.avatar?.url">
                <ion-img [src]="user.avatar.url" *ngIf="user.avatar?.type === 'local'"></ion-img>
                <app-cached-image [src]="user.avatar.url" [spinner]="true" *ngIf="user?.avatar?.type !== 'local'"></app-cached-image>
              </ng-container>
              <ion-icon color="medium" src="./assets/wedding/img/avatar/blank.svg" *ngIf="!user?.avatar?.url"></ion-icon>        
            </ion-avatar>
            <ion-label *ngIf="user?.name">
              <div class="text-ellipsis" (click)="goProfile()">
                <ion-text class="font-semi">{{ user.name }}</ion-text>
              </div>
              <div (click)="goPage('/account/detail')">
                <div class="text-ellipsis title-div" *ngIf="currentUser?.role?.type">
                  <ion-text class="font-12 font-italic">
                    <ng-container *ngIf="currentUser?.role?.custom">{{ currentUser.role.type }}</ng-container>
                    <ng-container *ngIf="!currentUser?.role?.custom">{{ 'LIST.role.' + currentUser.role.type | translate }}</ng-container>
                  </ion-text>
                </div>
                <div class="text-ellipsis title-div" *ngIf="accountTitle">
                  <ion-text class="font-10 font-semi">{{ accountTitle }}</ion-text>
                </div>
              </div>
              <div class="font-10 ion-text-wrap" *ngIf="accountInfo?.accountStatus">
                <app-account-status [accountStatus]="accountInfo.accountStatus" [setupTrialData]="accountInfo.trialData" [showSubscribe]="true"></app-account-status>
              </div>
            </ion-label>
          </ion-item>
        </ion-toolbar>
      </ion-header>
      <ion-content color="white">
        <ion-list lines="none" class="background-transparent" *ngIf="user?.uid && user?.name && menuList?.length">
          <ion-menu-toggle auto-hide="false" *ngFor="let menu of menuList; let i= index; trackBy: trackByFn">
            <ion-item class="background-transparent" [class.active-item]="activeItems?.[i]" *ngIf="menu?.url && !menu?.disable && (menu?.module !== 'website' || debugMode)" (click)="goPage(menu.url)" lines="none">
              <div>
                <ng-container *ngIf="menu?.icon?.name">
                  <ion-icon class="menu-icon" [name]="menu.icon.name" ></ion-icon>
                </ng-container>
                <ng-container *ngIf="menu?.icon?.src">
                  <ion-icon class="menu-icon" [src]="menu.icon.src" ></ion-icon>
                </ng-container>
                <ng-container *ngIf="menu?.icon?.off && menu?.icon?.on">
                  <ion-icon class="menu-icon" [src]="menu.icon.on" *ngIf="menu?.url === selectedPath || (menu?.path && selectedPath?.indexOf(menu.path) !== -1) else offIcon"></ion-icon>
                  <ng-template #offIcon>
                    <ion-icon class="menu-icon" [src]="menu.icon.off" ></ion-icon>
                  </ng-template>
                </ng-container>
                <ion-text >{{ 'MENU.' + menu.title | translate }}</ion-text>
                <ion-badge class="badge" color="tertiary" *ngIf="menu?.title === 'accountTask' && pendingTask">{{ pendingTask }}</ion-badge>              
                <ng-container *ngIf="menu?.title === 'inbox'">
                  <app-inbox-count class="badge" [badgeColor]="'danger'" [showBadge]="true" [showNumber]="true"></app-inbox-count>
                </ng-container>
              </div>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
      </ion-content>
      <ion-footer>
        <ion-toolbar color="white">
          <ion-button class="ion-margin" expand="block" color="grey" (click)="goStore()" *ngIf="showLaunchApp">
            <ion-icon name="download-outline"></ion-icon>&nbsp;
            {{ 'APP_DOWNLOAD.launch' | translate }}
          </ion-button>
          <ion-item class="version-item" *ngIf="appVersion" color="white" lines="none">
            <ion-text class="font-12" slot="start" (click)="presentAboutActionSheet()">&copy; {{ 'ABOUT.title' | translate }}</ion-text>
            <ion-text class="font-12" slot="end" (click)="goPage('/about/app-update')">v{{ appVersion }}</ion-text>
          </ion-item>
        </ion-toolbar>
      </ion-footer>
    </ion-menu>
    <ion-router-outlet id="main"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
