import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { AccountRoleService } from 'src/app/services/account/account-role.service';
import { PopupService } from 'src/app/services/general/popup.service';
import { FunctionService } from 'src/app/services/general/function.service';

import { Role, UserRole } from 'src/app/interfaces/account';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { AccountRoleNewComponent } from '../account-role-new/account-role-new.component';

/**
 * Role component
 */
@Component({
  selector: 'app-account-role',
  templateUrl: './account-role.component.html',
  styleUrls: ['./account-role.component.scss'],
})
export class AccountRoleComponent implements OnInit, OnDestroy {

  @ViewChild(CdkVirtualScrollViewport) cdkVirtualScrollViewport: CdkVirtualScrollViewport;
  /**
   * New account flag
   */
  newAccount: boolean;
  /**
   * Search keyword
   */
  searchTerm: string;
  /**
   * Selected user role
   */
  selected: UserRole;
  /**
   * Role List
   */
  roleList: Role[];

  eventMode: boolean;

  /**
   * Role List subscription
   */
  private roleListSubscription: Subscription;

  /**
   * Constructor
   * @param modalController Modal Controller
   * @param translate Translate
   * @param AccountRoleService Role Service
   * @param popupService Popup service
   * @param functionService function service
   */
  constructor(
    private modalController: ModalController,
    private translate: TranslateService,
    private accountRoleService: AccountRoleService,
    private popupService: PopupService,
    private functionService: FunctionService,
  ) { }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    this.unwatchRoleList();
  }

  /**
   * Before view enter
   */
  async ionViewWillEnter() {
    this.watchRoleList();
  }

  /**
   * Before view leave
   */
  ionViewWillLeave() {
    this.unwatchRoleList();
  }

  /**
   * Watch role
   */
  async watchRoleList() {
    if (!this.roleListSubscription) {
      this.roleListSubscription = this.accountRoleService.observableRoleList.subscribe(() => {
        this.loadList();
      });
    }
  }

  /**
   * Unwatch role
   */
  async unwatchRoleList() {
    if (this.roleListSubscription) {
      this.roleListSubscription.unsubscribe();
      this.roleListSubscription = null;
    }
  }

  /**
   * Search category
   */
  async search(data: any) {
    this.searchTerm = data?.keyword;
    this.loadList();
  }

  /**
   * Load category list
   */
  async loadList() {
    this.roleList = this.functionService.cloneDeep(this.accountRoleService.searchRoleList(this.searchTerm, this.eventMode));
    this.setupViewport();
  }

  setupViewport() {
    if (this.cdkVirtualScrollViewport) {
      setTimeout(() => {
        this.cdkVirtualScrollViewport?.checkViewportSize();
      }, 200);
    } else {
      setTimeout(() => {
        this.setupViewport();
      }, 200);
    }
  }

  /**
   * Select Role
   * @param role User role
   */
  selectRole(role: UserRole) {
    this.selected = role;
    this.dismissModal(this.selected);
  }

  /**
   * Dismiss role modal
   * @param selected Selected role
   */
  async dismissModal(selected?: UserRole, roleList?: Role[]) {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { modal.dismiss({ selected, roleList }); }
    }
  }

  async presentSettingFieldNewModal() {
    const modal = await this.modalController.create({
      component: AccountRoleNewComponent,
      componentProps: {
        roleList: this.roleList,
        eventMode: this.eventMode,
      }
    });
    modal.present();
    modal.onWillDismiss().then((result: any) => {
    });
  }

  /**
   * Present role prompt
   * @param index Index
   * @param role Role
   * @param slidingItem Sliding Item
   */
  async presentRolePrompt(index: number, role?: UserRole, slidingItem?: any) {
    let value = '';
    let header = this.translate.instant('CRUD.new');
    if (role?.type) {
      value = role.custom ? role.type : this.translate.instant('LIST.role.' + role.type);
      header = this.translate.instant('CRUD.update');
    }
    header = header + ' ' + this.translate.instant('LBL.role');

    const modal = await this.popupService.presentInput(header, '', '', '', value);
    modal.onDidDismiss().then((result: any) => {
      if (slidingItem) { slidingItem.close(); }
      if (result?.data?.input) {
        this.checkRole(result.data.input?.toString()?.trim(), index);
      }
    });
  }

  /**
   * Delete role
   * @param index index
   */
  async deleteRolePrompt(index: number, slidingItem?: any) {
    const modal = await this.popupService.presentConfirm(
      this.translate.instant('CRUD.confirm_delete')
    );
    modal.onDidDismiss().then((result: any) => {
      if (slidingItem) { slidingItem.close(); }
      if (result?.data?.confirm) {
        this.roleList?.splice(index, 1);
        this.save();
      }
    });
  }

  /**
   * Check role for duplicate before save
   * @param newRole New role
   * @param index index
   */
  async checkRole(newRole: string, index: number) {
    if (newRole) {
      const duplicateIndex = this.roleList?.findIndex((x: Role) => x?.role?.type?.toString()?.toLowerCase() === newRole?.toString()?.toLowerCase());
      if (duplicateIndex !== -1 && duplicateIndex !== index) {
        this.popupService.presentToast(this.translate.instant('VALIDATION.duplicate_field', { field: this.translate.instant('LBL.role') }), 'danger');
      } else {
        if (index === -1) {
          const obj: Role = {
            role: {
              custom: true,
              type: newRole,
              coupleId: 0
            },
            privilege: null
          };
          if (!this.roleList?.length) {
            this.roleList = this.functionService.cloneDeep(this.accountRoleService.searchRoleList('', this.eventMode));;
          }
          this.roleList.push(obj);
        } else {
          if (this.roleList[index]?.role?.custom || newRole?.toLocaleLowerCase() !==
          this.translate.instant('LIST.role.' + this.roleList[index]?.role?.type).toLocaleLowerCase()) {
            this.roleList[index].role.type = newRole;
            this.roleList[index].role.custom = true;
          }
        }
      }
      if (!this.newAccount) {
        await this.save();
      }
    }
  }

  /**
   * Check ion item slider swipe
   * @param roleType Role type
   */
  checkSwipe(roleType: string) {
    if (roleType === 'groom' || roleType === 'bride' || roleType === 'admin' || roleType === 'other') {
      this.popupService.presentToast(this.translate.instant('VALIDATION.reserved', {
        field: this.translate.instant('LBL.role') }), 'warning');
    }
  }

  checkSlideItem(roleType: string): boolean {
    if (roleType === 'groom' || roleType === 'bride' || roleType === 'admin' || roleType === 'other') {
      return false;
    }
    return true;
  }

  /**
   * Save role
   */
  async save() {
    await this.popupService.presentLoading();
    if (this.roleList?.length && !this.functionService.isEqual(this.roleList, this.accountRoleService.roleList)) {
      await this.accountRoleService.updateAccountRole(this.roleList);
    }
    this.popupService.dismissLoading();
    this.popupService.saveSuccessToast();
  }

}
