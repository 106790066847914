import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { GuestListService } from 'src/app/services/guest/guest-list.service';
import { WebsiteThemeColor } from 'src/app/interfaces/website';

/**
 * Confirm component
 */
@Component({
  selector: 'app-website-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
})
export class WebsiteConfirmComponent implements OnInit, OnDestroy {

  font: string;
  
  websiteThemeColor: WebsiteThemeColor;

  /**
   * Title
   */
  title: string;
  /**
   * Subtitle
   */
  subtitle: string;
  /**
   * Message
   */
  msg: string;
  /**
   * type
   */
  type: string;

  /**
   * Constructor
   * @param modalController Modal Controller
   */
  constructor(
    private modalController: ModalController,
    private guestListService: GuestListService,
  ) {}

  ngOnInit() {}

  ngOnDestroy() {}

  /**
   * Confirm
   */
  async confirm() {
    await this.dismissModal(true);
  }

  /**
   * Cancel
   */
  async cancel() {
    await this.dismissModal(false);
  }

  /**
   * Dismiss Confirm modal
   * @param confirm confirm
   */
  async dismissModal(confirm: boolean, cancel?: boolean) {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { await modal.dismiss({ confirm, cancel }); }
    }
  }

}
