import { Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';

import { IonTextarea, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

import { BlastSampleUrl } from 'src/app/commons/blastTemplate';
import { TemplateVariableEvent, TemplateVariableWedding } from 'src/app/commons/template';
import { GuestListComponent } from 'src/app/components/guest/guest-list/guest-list.component';
import { Language, SettingField } from 'src/app/interfaces/database';
import { BlastTemplate } from 'src/app/interfaces/blast';
import { AccountInfoService } from 'src/app/services/account/account-info.service';
import { PrivilegeService } from 'src/app/services/account/privilege/privilege.service';
import { BlastTemplateService } from 'src/app/services/blast/blast-template.service';
import { FunctionService } from 'src/app/services/general/function.service';
import { KeyboardService } from 'src/app/services/general/keyboard.service';
import { LanguageService } from 'src/app/services/general/language.service';
import { PopupService } from 'src/app/services/general/popup.service';
import { LocalityService } from 'src/app/services/location/locality.service';
import { GuestFilterType } from 'src/app/types/guest';
import { TemplateVariableType } from 'src/app/types/template';
import { BlastReviewComponent } from '../../blast-review/blast-review.component';
import { BlastTipsComponent } from '../../blast-tips/blast-tips.component';

import { Clipboard } from '@capacitor/clipboard';
import { BlastType } from 'src/app/types/blast';
import { BlastTemplateLanguageComponent } from '../blast-template-language/blast-template-language.component';
import { BlastTemplateTypeComponent } from '../blast-template-type/blast-template-type.component';

import { SmsTitleMaxLength, StdSmsTemplateList } from 'src/app/commons/smsTemplate';
import { AccountEventModeService } from 'src/app/services/account/account-event-mode.service';
import { UrlService } from 'src/app/services/general/url.service';
import { MobileService } from 'src/app/services/general/mobile.service';
import { SupportAdminService } from 'src/app/services/support/support-admin.service';

@Component({
  selector: 'app-blast-template-manage',
  templateUrl: './blast-template-manage.component.html',
  styleUrls: ['./blast-template-manage.component.scss'],
})
export class BlastTemplateManageComponent  implements OnInit, OnDestroy {

  @ViewChild('msgTitleInput') msgTitleInput: IonTextarea;
  @ViewChild('msgInput') msgInput: IonTextarea;

  newMode: boolean;
  hideBlastType: boolean;
  blastType: BlastType;
  editMode: boolean;
  editField = {
    msgTitle: false,
    msg: false,
  };

  blastCount: number;
  ready: boolean;
  templateTitle: string;

  // templateList: BlastTemplate[];
  // templateTypeList: SettingField[];
  templateType: SettingField;
  templateNo: string;
  templateNoMaxLength: number = 20;
  selectMode: boolean;
  unicode: boolean;
  form: FormGroup;
  validationMsg: any;
  langCode: string;
  phoneCode: number;

  selectedTemplate: BlastTemplate;

  selectedGuestIdList: string[];
  selectedGroupIdList: string[];

  sampleUrl: string = BlastSampleUrl;

  selectedVariableList: TemplateVariableType[] = [];
  titleMaxLength = SmsTitleMaxLength;

  dynamicVariable: boolean;
  /**
   * Language list
   */
  languageList: Language[] = this.languageService.languageList;

  adminMode: boolean;

  msgTitle: any;

  constructor(
    private ngZone: NgZone,
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    private modalController: ModalController,
    private accountInfoService: AccountInfoService,
    private accountEventModeService: AccountEventModeService,
    private privilegeService: PrivilegeService,
    private blastTemplateService: BlastTemplateService,
    private supportAdminService: SupportAdminService,
    private urlService: UrlService,
    private mobileService: MobileService,
    private languageService: LanguageService,
    private localityService: LocalityService,
    private keyboardService: KeyboardService,
    private popupService: PopupService,
    private functionService: FunctionService,
  ) { }

  ngOnInit() {}

  ngOnDestroy(): void {
      this.unwatch();
  }

  ionViewWillEnter(){
    if (!this.blastType) {
      this.blastType = 'sms';
    }
    // if (this.newMode) {
    //   this.templateType = {
    //     value: '',
    //     custom: true
    //   };
    // }
    this.ready = true;
    this.phoneCode = this.localityService.getAccountCountry()?.phone;
    this.setupPageLanguage();
    this.setupValidation();
    this.setupForm();
    
    this.watch();
    this.adminMode = this.getAdminMode();
    if (this.templateType && this.templateNo) {
      this.setupTemplate();
    }
    // this.templateTypeList = this.getTemplateTypeList();
  }

  ionViewWillLeave() {
    this.unwatch();
    this.ready = false;
  }

  watch() {
    // this.watchTemplateList();
    // this.watchTemplateType();
  }

  unwatch() {
    // this.unwatchTemplateList();
    // this.unwatchTemplateType();
  }

  // async watchTemplateList() {
  //   await this.unwatchTemplateList();
  //   this.templateListSubscription = this.blastTemplateService.observableTemplateList.subscribe((templateList: BlastTemplate[]) => {
  //     this.templateList = this.functionService.cloneDeep(this.getTemplateList());
  //     this.setupFormValue();
  //   });
  // }

  // async unwatchTemplateList() {
  //   if (this.templateListSubscription) {
  //     this.templateListSubscription.unsubscribe();
  //     this.templateListSubscription = null;
  //   }
  // }

  // async watchTemplateType() {
  //   await this.unwatchTemplateType();
  //   this.templateTypeSubscription = this.blastTemplateService.observableTemplateTypeList.subscribe((templateTypeList: SettingField[]) => {
  //     this.templateTypeList = this.getTemplateTypeList();
  //   });
  // }

  // async unwatchTemplateType() {
  //   if (this.templateTypeSubscription) {
  //     this.templateTypeSubscription.unsubscribe();
  //     this.templateTypeSubscription = null;
  //   }
  // }

  getTemplateTypeList() {
    return this.blastTemplateService.getTemplateTypeList(this.blastType);
  }

  getTemplateList() {
    return [ ...this.blastTemplateService.getTemplateList(this.blastType) ];
  }

  blastTypeChanged() {
    // this.templateList = this.functionService.cloneDeep(this.getTemplateList());
    
    if (this.blastType === 'sms') {
      this.form.controls.msgTitle.setValidators([ Validators.required ]);
      this.form.controls.msg.setValidators([ Validators.required ]);
    } else if (this.blastType === 'whatsapp') {
      if (this.adminMode) {
        this.form.controls.msgTitle.setValidators([ Validators.required ]);
        this.form.controls.msg.setValidators([ Validators.required ]);
      } else {
        this.form.controls.msgTitle.setValidators([]);
        this.form.controls.msg.setValidators([]);
      }
    }
    this.form.controls.msgTitle.updateValueAndValidity();
    this.form.controls.msg.updateValueAndValidity();

    this.editField = {
      msgTitle: false,
      msg: false,
    };
    this.resetEditField();
    this.setupTemplate();
  }

  setupTemplate() {
    this.setupSelectedTemplate();
    this.setupFormValue();

    this.checkForm();
  }

  setupPageLanguage() {
    if (!this.langCode) {
      const language = this.languageService.getUserLanguage();
      this.langCode = language?.code;
    }
  }

  setupValidation() {
    this.validationMsg = {
      customTemplateNo: [
        { 
          type: 'required', msg: this.translate.instant('VALIDATION.required', {
          field: this.translate.instant('BLAST.template.name') }) 
        },
        { 
          type: 'maxlength', msg: this.translate.instant('VALIDATION.max_length', {
          number: this.templateNoMaxLength }) 
        },
        { 
          type: 'duplicate', msg: this.translate.instant('VALIDATION.duplicate_field', {
          field: this.translate.instant('BLAST.template.name') }) 
        }
      ],
      msgTitle: [
        { 
          type: 'required', msg: this.translate.instant('VALIDATION.required', {
          field: this.translate.instant('LBL.title') })
        },
        { 
          type: 'tel', msg: this.translate.instant('BLAST.template.not_allowed.tel')
        },
        { 
          type: 'url', msg: this.translate.instant('BLAST.template.not_allowed.url')
        },
        { 
          type: 'email', msg: this.translate.instant('BLAST.template.not_allowed.email')
        }
      ],
      msg: [
        { 
          type: 'required', msg: this.translate.instant('VALIDATION.required', {
          field: this.translate.instant('LBL.msg') })
        },
        { 
          type: 'tel', msg: this.translate.instant('BLAST.template.not_allowed.tel')
        },
        { 
          type: 'url', msg: this.translate.instant('BLAST.template.not_allowed.url')
        },
        { 
          type: 'email', msg: this.translate.instant('BLAST.template.not_allowed.email')
        }
      ]
    };
  }

  setupForm() {
    this.form = this.formBuilder.group({
      customTemplateNo: new FormControl('', [ Validators.required, Validators.maxLength(this.templateNoMaxLength) ]),
      msgTitle: new FormControl('[WEDDING_TITLE]', [ this.blastType === 'sms' || this.adminMode ? Validators.required : Validators.nullValidator ]),
      msg: new FormControl('', [ this.blastType === 'sms' || this.adminMode ? Validators.required : Validators.nullValidator ]),
      url: new FormControl(false),
    });
    this.setTemplateName();
  }

  languageChanged() {  
    this.editField = {
      msgTitle: false,
      msg: false,
    };
    this.resetEditField();
    this.setupTemplate();
  }

  setupFormValue() {
    this.setTemplateTitle();
    this.setTemplateValue();
  }

  setupSelectedTemplate() {
    const templateList = this.getTemplateList();
    if (this.langCode && this.templateType?.value && this.templateNo) {
      const index = templateList.findIndex((x: BlastTemplate) => {
        return x?.msg && x?.language?.value === this.langCode && x?.templateType?.value === this.templateType.value && x.templateNo === this.templateNo;
      });
      if (index !== -1) {
        this.selectedTemplate = templateList[index];
      } else {
        this.templateNo = '1';
        const defaultIndex = templateList.findIndex((x: BlastTemplate) => {
          return x?.msg && x?.language?.value === this.langCode && x?.templateType?.value === this.templateType.value && x.templateNo === this.templateNo;
        });
        if (defaultIndex !== -1) {
          this.selectedTemplate = templateList[defaultIndex];
        }
      }
    } else {
      this.selectedTemplate = null;
    }
    // if (this.blastType === 'sms') {
    //   if (this.langCode && this.templateType?.value) {
    //     const index = templateList?.findIndex((x: BlastTemplate) => {
    //       return x?.msg && x?.language?.value === this.langCode && x?.templateType?.value === this.templateType.value;
    //     });
    //     if (index !== -1) {
    //       this.selectedTemplate = templateList[index];
    //     }
    //   }
    // } else if (this.blastType === 'whatsapp') {
    //   if (this.langCode && this.templateType?.value && this.templateNo) {
    //     const index = templateList.findIndex((x: BlastTemplate) => {
    //       return x?.msg && x?.language?.value === this.langCode && x?.templateType?.value === this.templateType.value && x.templateNo === this.templateNo;
    //     });
    //     if (index !== -1) {
    //       this.selectedTemplate = templateList[index];
    //     }
    //   }
    // }
  }

  setEditField(field: string) {
    if ((this.blastType === 'sms' || this.adminMode) && this.editMode && (this.selectedTemplate?.custom || this.newMode)) {
      this.resetEditField();
      if (field && !this.functionService.isUndefined(this.editField[field])) {
        if (!this.editMode) {
          this.editMode = true;
        }
        this.ngZone.run(async () => {
          this.editField[field] = true;
          await this.functionService.delay(50);
          if (field === 'msgTitle') {
            this.msgTitle?.setFocus();
          } else if (field === 'msg') {
            this.msgInput?.setFocus();
          }
        });
      }
    }
  }

  resetEditField() {
    this.editField = {
      msgTitle: false,
      msg: false,
    };
  }

  // getNumber(number: string) {
  //   return Number(number);
  // }

  getHtml(template: string, title?: boolean) {
    const variableList = this.getTemplateVariableList();
    variableList?.forEach((variable: TemplateVariableType) => {
      if (variable === 'wedding_title' || !title) {
        const field = '[' + variable.toUpperCase() + ']';
        const newField = '<span class="variable">' + this.replaceEventType(this.translate.instant('TEMPLATE.variable.' + variable)) + '</span>';
        template = this.functionService.replaceAll(template, field, newField);
      }
    });

    return template;
  }

  getTemplateVariableList() {
    return this.accountEventModeService.eventMode ? TemplateVariableEvent : TemplateVariableWedding;
  }

  getTemplateNoList(templateType: SettingField, langCode: string): string[] {
    return this.blastTemplateService.getTemplateNoList(this.blastType, templateType, langCode);
  }

  getAdminMode() {
    return this.supportAdminService.checkAdmin();
  }

  newTemplate() {
    this.templateNo = '';
    this.form.controls.customTemplateNo.setValue('');
    this.form.controls.msg.setValue('');
    this.newMode = true;
    this.editMode = true;
    this.setupTemplate();
  }

  /**
   * Dismiss modal
   */
  async dismissModal(blastTemplate?: BlastTemplate, blastCount?: number) {
    if (this.modalController) {
      const modal = await this.modalController.getTop();
      if (modal) { modal.dismiss({ blastTemplate, blastCount }); }
    }
  }

  async presentBlastTemplateLanguageModal(selected: string) {
    const modal = await this.modalController.create({
      component: BlastTemplateLanguageComponent,
      backdropDismiss: true,
      cssClass: 'modal-transparent',
      componentProps: {
        selected
      }
    });
    modal.present();
    modal.onWillDismiss().then((result: any) => {
      if (result?.data?.selected) {
        const langCode = result.data.selected;
        if (langCode !== this.langCode) {
          this.langCode = langCode;
          this.setupTemplate();
        }
      }
    })
  }

  async presentBlastTemplateTypeModal(selected: SettingField) {
    const modal = await this.modalController.create({
      component: BlastTemplateTypeComponent,
      backdropDismiss: true,
      cssClass: 'modal-transparent',
      componentProps: {
        selected,
        blastType: this.blastType,
        templateTypeList: this.blastTemplateService.getTemplateTypeList(this.blastType),
      }
    });
    modal.present();
    modal.onWillDismiss().then((result: any) => {
      if (result?.data?.selected) {
        const templateType = result.data.selected;
        if (templateType?.custom !== this.templateType?.custom || templateType?.value !== this.templateType?.value) {
          this.templateType = result.data.selected;
          this.templateNo = '1';
          this.setTemplateName();
          this.setupTemplate();
        }
      }
    });
  }

  async presentBlastTipsModal() {
    const modal = await this.modalController.create({
      component: BlastTipsComponent,
      componentProps: {
      }
    });
    modal.present();
  }

  async presentGuestListModal() {
    // this.setupSelectedTemplate();
    const type: GuestFilterType = {
      mobile: 'with',
      blastSentType: 'not_send',
      msgTemplateId: this.selectedTemplate?.templateId,
      msgTemplateType: this.selectedTemplate?.templateType,
    };
    const modal = await this.modalController.create({
      component: GuestListComponent,
      componentProps: {
        mode: this.blastType,
        selectMode: true,
        type,
        selectedGuestList: this.selectedGuestIdList,
        selectedGroupList: this.selectedGroupIdList,
        blastTemplate: this.selectedTemplate,
      }
    });
    modal.present();
    modal.onWillDismiss().then((result: any) => {
      if (result?.data?.selectedGuestList) {
        this.selectedGuestIdList = result.data.selectedGuestList;
      }
      if (result?.data?.selectedGroupList) {
        this.selectedGroupIdList = result.data.selectedGroupList;
      }
    });
  }

  async presentBlastReviewModal(
    selectedGuestIdList: string[],
    selectedGroupIdList: string[],
    blastTemplate: BlastTemplate
  ) {
    if (this.checkPrivilege('send')) {
      const modal = await this.modalController.create({
        component: BlastReviewComponent,
        componentProps: {
          selectedGuestIdList,
          selectedGroupIdList,
          blastTemplate,
          blastType: this.blastType,
        }
      });
      modal.present();
    } else {
      this.popupService.presentAlert(this.translate.instant('ACCOUNT_PRIVILEGE.msg.no_privilege'));
    }
  }

  async promptDelete(item: any) {
    if (this.templateType?.value && this.templateNo) {
      const modal = await this.popupService.presentConfirm(
        this.translate.instant('BLAST.template.msg.confirm_delete_type')
      );
      modal.onWillDismiss().then(async (result: any) => {
        if (result?.data?.confirm) {
          const templateList = this.getTemplateList();
          const index = templateList.findIndex((x: BlastTemplate) => {
            return x?.templateType?.value === this.templateType.value && 
            x?.templateType?.custom === this.templateType?.custom &&
            x?.templateNo === this.templateNo;
          });
          if (index !== -1) {
            const blastTemplate: BlastTemplate = templateList[index];
            blastTemplate.enable = false;
            await this.save(blastTemplate);
            this.templateNo = '1';
          }
        }
      });
    }

    if (item) {
      item.close();
    }
  }

  setTemplateName() {
    if (this.form && this.templateType?.value) {
      if (this.templateType?.custom) {
        this.form.controls.customTemplateNo.setValue(this.templateType.value);
      } else {
        this.form.controls.customTemplateNo.setValue(this.translate.instant('LIST.blast_type.' + this.templateType.value));
      }
    }
  }

  cancel() {
    this.editMode = false;
    this.newMode = false;
    this.templateNo = '1';
    this.setupTemplate();
  }

  back() {
    if (this.editMode) {
      this.editMode = false;
      if (this.newMode) {
        this.cancel();
      } 
    } else {
      this.dismissModal();
    }
  }

  resetTemplateTitle() {
    this.form.controls.msgTitle.setValue('[WEDDING_TITLE]');
  }

  setTemplateTitle() {
    if (this.templateTitle) {
      this.form.controls.msgTitle.setValue(this.templateTitle);
    } else {
      this.templateTitle = '[WEDDING_TITLE]';;
      this.form.controls.msgTitle.setValue('[WEDDING_TITLE]');
    }
  }

  resetTemplateMsg() {
    const index = StdSmsTemplateList?.findIndex((x: any) => {
      return x?.msg && x?.language?.value === this.langCode && x?.templateType?.value === this.templateType.value;
    });
    if (index !== -1) {
      this.form.controls.msg.setValue(StdSmsTemplateList[index].msg);
    } else {
      this.form.controls.msg.setValue('');
    }
  }

  setTemplateValue() {
    if (this.templateType?.value && this.templateNo) {
      const templateList = this.getTemplateList();
      if (this.blastType === 'sms') {
        const index = templateList?.findIndex((x: BlastTemplate) => {
          return x?.msg && x?.language?.value === this.langCode && x?.templateType?.value === this.templateType.value && x.templateNo === this.templateNo;
        });
        if (templateList?.length && index !== -1) {
          const template = templateList[index];
          if (template.templateTitle) {
            this.templateTitle = template.templateTitle;
          } else {
            this.templateTitle = '[WEDDING_TITLE]';
          }
          this.form.controls.msg.setValue(template.msg);
          this.form.controls.url.setValue(template.url ? true : false);
        } else {
          this.form.controls.msg.setValue('');
          this.form.controls.url.setValue(false);
          this.templateTitle = '[WEDDING_TITLE]';;
        }
        this.setTemplateTitle();
      } else if (this.blastType === 'whatsapp') {
        const index = templateList?.findIndex((x: BlastTemplate) => {
          return x?.msg && x?.language?.value === this.langCode && x?.templateType?.value === this.templateType.value && x.templateNo === this.templateNo;
        });
        if (index !== -1) {
          const template = templateList[index];
          this.form.controls.msg.setValue(template.msg);
        } else {
          this.form.controls.msg.setValue('');
        }
      }
      
      
    }
  }

  filterMsgTilte() {

  }

  filterMsg() {

  }

  titleWordCount(): number {
    let length = 0;
    if (this.form?.value?.msgTitle) {
      if (this.form.value.msgTitle === '[WEDDING_TITLE]') {
        length = this.accountInfoService.accountTitle.length;
      } else {
        length = this.form.value.msgTitle.length;
      }
    } else if (this.selectedTemplate?.templateTitle) {
      if (this.selectedTemplate.templateTitle === '[WEDDING_TITLE]') {
        length = this.accountInfoService.accountTitle.length;
      } else {
        length = this.selectedTemplate.templateTitle.length;
      }
    }
    return length;
  }

  msgWordCount(): number {
    return this.form?.value?.msg?.length ? this.form.value.msg.length : 0;
  }

  setTitleMaxLength(titleMaxLength: number) {
    this.titleMaxLength = titleMaxLength;
  }

  setTotalBlastCount(totalBlastCount: number) {
    this.blastCount = totalBlastCount;
  }

  setDynamicVariable(dynamicVariable: boolean) {
    this.dynamicVariable = dynamicVariable;
  }

  selectWhatsappVariable(variable: TemplateVariableType) {
    if (!this.selectedVariableList?.length) {
      this.selectedVariableList = [];
    }
    const index = this.selectedVariableList.indexOf(variable);
    if (index === -1) {
      this.selectedVariableList.push(variable);
    } else {
      this.selectedVariableList.splice(index, 1);
    }
  }


  checkSelectedVariable(variable: TemplateVariableType): boolean {
    return this.selectedVariableList.indexOf(variable) !== -1 ? true : false;
  }

  checkNewTemplateName() {
    if (!this.templateType?.value) {
      return true;
    }
    return false;
  }

  checkEditTemplateName() {
    if (this.form?.value?.customTemplateNo && this.templateType?.value) {
      if (this.templateType?.custom) {
        if (this.form.value.customTemplateNo !== this.templateType.value) {
          return true;
        }
      } else {
        if (this.form.value.customTemplateNo !== this.translate.instant('LIST.blast_type.' + this.templateType.value)) {
          return true;
        }
      }
    }
    return false;
  }

  checkPrivilege(action: string): boolean {
    return this.privilegeService.checkCurrentUserPrivilege('blast', action);
  }

  checkForm() {
    this.checkMsg(this.form.value.msg);
    this.checkTitle(this.form.value.msgTitle);
  }

  checkDisableSubmit(): boolean {
    if (!this.form?.valid || (!this.newMode && !this.editMode && !this.selectedGuestIdList?.length && !this.selectedGroupIdList?.length)) {
      return true;
    } else if (this.blastType === 'sms') {
      if (this.blastCount > 10 || (this.titleMaxLength && this.titleWordCount() > this.titleMaxLength)) {
        return true;
      }
      if (this.newMode) {
        if (!this.form?.value?.customTemplateNo || this.checkDuplicateTemplateNo()) {
          return true;
        } 
      }
    } else if (this.blastType === 'whatsapp') {
      if (!this.adminMode && (!this.templateNo || !this.templateType?.value)) {
        return true;
      }
    }
    return false;
  }

  checkDuplicateTemplateNo() {
    const templateNoList = this.getTemplateNoList(this.templateType, this.langCode);
    if (templateNoList?.length && this.form.value.customTemplateNo) {
      const index = templateNoList.findIndex((x: string) => {
        return x.toLowerCase() === this.form.value.customTemplateNo.toLowerCase();
      });
      if (index !== -1) {
        this.form.controls.customTemplateNo.setErrors({ duplicate: true });
        return true;
      }
    }
    return false;
  }

  setupNewTemplateNo() {
    

    // if (this.checkNewTemplateName()) {
    //   const index = templateTypeList.findIndex((x: SettingField) => {
    //     return x.value === templateType.value && x.custom === templateType?.custom;
    //   });
    //   if (index === -1) {
    //     templateTypeList.push(templateType);
    //   }
    // } else if (this.checkEditTemplateName()) {
    //   const index = templateTypeList.findIndex((x: SettingField) => {
    //     return x.value === this.templateType.value && x.custom === this.templateType?.custom;
    //   });
    //   if (index !== -1) {
    //     templateTypeList[index] = templateType;
    //   }
    // }
    // return templateTypeList;
  }

  setupTemplateTitle() {
    if (this.form?.value?.msgTitle && this.form.value.msgTitle !== this.templateTitle) {
      this.templateTitle = this.form.value.msgTitle;
    }
  }

  async trimSpace() {
    if (this.form.value?.customTemplateNo) {
      this.form.controls.customTemplateNo.setValue(this.form.value.customTemplateNo.trim());
    }
  }

  async submit(select?: boolean) {
    await this.trimSpace();
    this.form.markAllAsTouched();
    
    this.checkMsg(this.form.value.msg);
    this.checkTitle(this.form.value.msgTitle);

    if (!this.checkDisableSubmit()) {
      if(!this.dynamicVariable) {
        this.dynamicVariable = false;
      }
      this.setupTemplateTitle();
      if (this.newMode) {
        this.selectedTemplate = {
          templateId: this.functionService.generateFirestoreId(),
          enable: true,
          blastType: this.blastType,
          templateTitle: this.templateTitle,
          templateType: this.templateType,
          templateNo: this.form.value.customTemplateNo,
          language: {
            value: this.langCode,
            custom: false,
          },
          custom: true,
          url: this.form.value.url,
          msg: this.form.value.msg,
        };
        if (this.selectedTemplate?.msg) {
          await this.save(this.selectedTemplate, select);
          this.templateNo = this.form.value.customTemplateNo;
        } else {
          this.promptError();
        }
      } else if (this.templateType?.value) {
        const templateList = this.getTemplateList();
        const index = templateList?.findIndex((x: BlastTemplate) => {
          return x?.msg && x?.language?.value === this.langCode && x?.templateType?.value === this.templateType.value && x.templateNo === this.templateNo;
        });
        if (index !== -1) {
          templateList[index].msg = this.form.value.msg;
          templateList[index].url = this.form.value.url;
          templateList[index].templateTitle = this.templateTitle;
          templateList[index].dynamicVariable = this.dynamicVariable;
          this.selectedTemplate = templateList[index];
        }
        if (this.selectedTemplate?.msg) {
          await this.save(this.selectedTemplate, select);
        } else {
          this.promptError();
        }
      } else {
        this.promptError();
      }
      
      // if (this.blastType === 'sms') {
      //   await this.save(this.selectedTemplate, select);
      // } else {
      //   if (select) {
      //     this.presentBlastReviewModal(this.selectedGuestIdList, this.selectedGroupIdList, this.selectedTemplate);
      //   } else {
      //     this.editMode = false;
      //   }
      // }
    }
  }

  async promptError(msg?: string) {
    if (!msg) {
      msg = this.translate.instant('MSG.action_error');
    }
    await this.popupService.presentAlert(msg);
  }

  async save(blastTemplate?: BlastTemplate, select?: boolean) {
    // await this.blastTemplateService.saveSetting(templateTypeList);
    if (this.newMode || this.editMode) {
      await this.blastTemplateService.saveTemplate(blastTemplate);
    }
    if (select) {
      this.presentBlastReviewModal(this.selectedGuestIdList, this.selectedGroupIdList, blastTemplate);
    } else {
      this.editMode = false;
      this.newMode = false;
    }
  }

  async select(blastTemplate: BlastTemplate, blastCount: number) {
    this.dismissModal(blastTemplate, blastCount);
  }

  async copy(field: TemplateVariableType) {
    const variableList = this.getTemplateVariableList();
    if (this.editMode && field && variableList?.indexOf(field) !== -1) {
      try {
        await Clipboard.write({ string: '[' + field.toUpperCase() + ']' });
        this.popupService.presentToast(this.translate.instant('COPY.success'), 'success');
      } catch (err: any) {
        this.popupService.presentToast(this.translate.instant('COPY.fail'), 'danger');
      }
    }
  }

  checkTitle(msg: string) {
    if (this.blastType === 'sms' && msg) {
      const urlExisted = this.urlService.isUrlsExisted(msg);
      const telExisted = this.mobileService.isMobileExisted(msg);
      const emailExisted = this.functionService.isEmailExisted(msg);
      const error: any = {};
      if (urlExisted) {
        error.url = true;
      }
      if (telExisted) {
        error.tel = true;
      }
      if (emailExisted) {
        error.email = true;
      }

      if (!this.functionService.isEmpty(error)) {
        this.form.controls.msgTitle.setErrors(error);
      }
    }
  }

  checkMsg(msg: string) {
    if (this.blastType === 'sms' && msg) {
      const urlExisted = this.urlService.isUrlsExisted(msg);
      const telExisted = this.mobileService.isMobileExisted(msg);
      const emailExisted = this.functionService.isEmailExisted(msg);
      const error: any = {};
      if (urlExisted) {
        error.url = true;
      }
      if (telExisted) {
        error.tel = true;
      }
      if (emailExisted) {
        error.email = true;
      }

      if (!this.functionService.isEmpty(error)) {
        this.form.controls.msg.setErrors(error);
      }
    }
  }

  replaceEventType(text: string) {
    return this.accountEventModeService.replaceEventType(text);
  }

  // getBlastTemplateList() {
  //   return this.blastTemplateService.getTemplateIdList();
  // }
}
