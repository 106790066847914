import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { enUS, zhCN, zhTW, ms } from 'date-fns/locale';

import { CountryList } from 'src/app/commons/country';

import { Country, LocaleType } from 'src/app/interfaces/database';
import { LocalityService } from '../location/locality.service';
import { LanguageService } from './language.service';
import { LocaleList } from 'src/app/commons/locale';

@Injectable({
  providedIn: 'root'
})
export class LocaleService implements OnInit, OnDestroy {

  defaultLocaleType: LocaleType = {
    dateFnsLocale: enUS,
    locale: 'en-US',
    summary: "English (United States)",
    language: "English",
  };

  // private locale: string;

  constructor(
    private localityService: LocalityService,
    private languageService: LanguageService,
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  getDateFnsLocale(langCode?: string, locale?: string, countryCode?: string) {
    let dateFnsLocale: any = enUS;

    if (locale || countryCode) {
      const localeType = this.getLocaleType(locale, countryCode);
      if (localeType?.dateFnsLocale) {
        dateFnsLocale = localeType.dateFnsLocale;
      }
    } else {
      if (!langCode) {
        langCode = this.languageService.getUserLanguage()?.code;
      }
      if (langCode === 'zh') {
        dateFnsLocale = zhCN;
      } else if (langCode === 'zh-TW') {
        dateFnsLocale = zhTW;
      } else if (langCode === 'ms') {
        dateFnsLocale = ms;
      } else if (langCode === 'en') {
        dateFnsLocale = enUS;
      }
    }
    
    return dateFnsLocale;
  }

  getPreferredLocaleList(countryCode?: string) {
    let localeList: LocaleType[] = [];
    if (countryCode) {
      const result = this.getLocaleListByCountry(countryCode);
      if (result?.length) {
        localeList = localeList.concat(result);
      }
    }
    
    const accountCountryCode = this.localityService.getAccountCountry()?.code;
    if (accountCountryCode !== countryCode) {
      const accountResult = this.getLocaleListByCountry(accountCountryCode);
      if (accountResult?.length) {
        localeList = localeList.concat(accountResult);
      }
    }
    

    const userCountryCode = this.localityService.getUserCountry()?.code;
    if (userCountryCode !== accountCountryCode || userCountryCode !== countryCode) {
      const userResult = this.getLocaleListByCountry(userCountryCode);
      if (userResult?.length) {
        localeList = localeList.concat(userResult);
      }
    }

    const deviceCountryCode = this.localityService.getDeviceCountry()?.code;
    if (deviceCountryCode !== userCountryCode || deviceCountryCode !== accountCountryCode || deviceCountryCode !== countryCode) {
      const deviceResult = this.getLocaleListByCountry(deviceCountryCode);
      if (deviceResult?.length) {
        localeList = localeList.concat(deviceResult);
      }
    }

    return localeList;
  }

  getLocaleListByCountry(countryCode: string): LocaleType[] {
    const localeList: LocaleType[] = [];
    if (countryCode) {
      const country = this.localityService.getCountryByCode(countryCode);
      if (country?.locale?.length) {
        country?.locale?.forEach((locale: string) => {
          if (locale) {
            const index = localeList.findIndex((x: LocaleType) => {
              return x?.locale === locale;
            });
            if (index === -1) {
              const localeType = this.getLocaleType(locale);
              if (localeType) {
                localeList.push(localeType);
              }
            }
          }
        });
      }
    }
    return localeList;
  }

  getLocaleType(locale?: string, countryCode?: string): LocaleType {
    if (!locale) {
      if (!countryCode) {
        countryCode =  this.localityService.getAccountCountry()?.code;
      }

      if (countryCode) {
        const countryIndex = CountryList.findIndex((x: Country) => {
          return x?.code === countryCode;
        });
  
        if (countryIndex !== -1 && CountryList?.[countryIndex]?.locale?.length) {
          const userLanguage = this.languageService.getUserLanguage();
          const localeIndex = CountryList[countryIndex].locale.findIndex((x: string) => {
            if (userLanguage?.code) {
              if (x.indexOf(userLanguage.code) !== -1) {
                return true;
              }
              // else if (userLanguage.code === 'zh-TW' && x.indexOf('zh-HK') !== -1) {
              //   return true;
              // }
            }
            return false;
          });
          if (localeIndex !== -1) {
            locale = CountryList[countryIndex].locale[localeIndex];
          } else {
            locale = CountryList[countryIndex].locale[0];
          }
        }
      }
    }

    if (locale) {
      const index = LocaleList.findIndex((x: LocaleType) => {
        return x.locale === locale;
      });

      if (index !== -1 && LocaleList?.[index]?.locale) {
        return LocaleList[index];
      } else {
        const index2 = LocaleList.findIndex((x: LocaleType) => {
          return x.locale.indexOf(locale) !== -1 || x.locale.indexOf(locale.split('-')[0]) !== -1;
        });
        if (index2 !== -1 && LocaleList?.[index2]?.locale) {
          return LocaleList[index2];
        }
      }
    }
    
    return this.defaultLocaleType;
  }

  getLocale(locale?: string, countryCode?: string): string {
    // let result = 'en-US';
    const localeType = this.getLocaleType(locale, countryCode);
    return localeType?.locale ? localeType.locale : 'en-US';
    // if (localeType?.locale) {
    //   if (localeType?.locale?.indexOf('-') === -1) {
    //     if (!countryCode) {
    //       countryCode =  this.localityService.getAccountCountry()?.code;
    //     }
    //     if (countryCode) {
    //       result = localeType.locale + '-' + countryCode.toUpperCase();
    //     } 
    //   }
    // }
    // return result;
  }

  // getLocaleDateFormat(locale: string) {
  //   const localeType = this.getLocaleType(locale);
  //   return localeType?.dateFormat;
  // }

  // getLocaleTimeFormat(locale: string) {
  //   const localeType = this.getLocaleType(locale);
  //   return localeType?.timeFormat;
  // }

  // getLocaleIs24Hr(locale: string) {
  //   const localeType = this.getLocaleType(locale);
  //   return localeType?.is24Hr;
  // }

  // getCurrentLocale() {
  //   return this.locale;
  // }

  // getCurrentLocaleType() {
  //   return this.getLocaleType(this.locale);
  // }

  // setupLocale(locale: string) {
  //   this.locale = locale;
  // }
}
